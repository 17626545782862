import {apiGetCall, apiPostCall} from "./index";

export const load = caretaker => apiGetCall("/calendars/get", {caretaker});

export const saveAppointment = (caretaker, appointment) => apiPostCall("/calendars/saveappointment", {caretaker, ...appointment});
export const saveBirthday    = (caretaker, birthday) => apiPostCall("/calendars/savebirthday", {caretaker, ...birthday});
export const saveReminder    = (caretaker, reminder) => apiPostCall("/calendars/savereminder", {caretaker, ...reminder});
export const saveTvShow      = (caretaker, tvShow) => apiPostCall("/calendars/savetvshow", {caretaker, ...tvShow});

export const skip   = (calendarType, uuid) => apiPostCall("/calendars/skip", {calendarType, uuid});
export const unskip = (calendarType, uuid) => apiPostCall("/calendars/unskip", {calendarType, uuid});
export const remove = (calendarType, uuid) => apiPostCall("/calendars/remove", {calendarType, uuid});