import React from 'react';
import PropTypes from 'prop-types';
import {FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label} from "reactstrap";
import {Icon} from "../../index";

const StackedWithIcon = props => (
    <FormGroup
        className={
            (props.settings.className ? props.settings.className + " " : "") +
            ((props.settings.touched && props.settings.validation) ? (props.settings.valid ? "has-success" : "has-danger") : "")
        }
    >
        {!props.settings.noLabel ? <Label>{props.settings.label}</Label> : null}
        <InputGroup id={props.name} className={
            "form-control-lg " +
            ((props.settings.touched && props.settings.validation) ? (props.settings.valid ? "has-success " : "has-danger ") : "") +
            (props.settings.focused ? "input-group-focus" : "")
        }>
            <InputGroupAddon addonType="prepend">
                <InputGroupText>
                    <Icon name={props.settings.icon}/>
                </InputGroupText>
            </InputGroupAddon>
            {props.children}
        </InputGroup>
    </FormGroup>
);


StackedWithIcon.propTypes = {
    name:     PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired
};

export default StackedWithIcon;