import React, {useState} from 'react';
import PropTypes from 'prop-types';
import i18n from "../../../i18n";
import {Button} from "../../index";
import {Collapse} from "reactstrap";

const CollapseActions = props => {
    const [isOpen, setOpen] = useState(false);

    return (
        <>
            <Button color="primary" style={{width: props.width ? props.width : 110}} onClick={() => setOpen(!isOpen)}>
                {props.title ? props.title : i18n.machinesActions}
                <b className={"caret " + (isOpen ? "expanded" : null)}/>
            </Button>
            <Collapse isOpen={isOpen}>
                {props.children}
            </Collapse>
        </>

    );
};

CollapseActions.propTypes = {
    title: PropTypes.string,
    width: PropTypes.number
};

export default CollapseActions;