// React
// React
import React, {useContext, useEffect, useState} from "react";

// Redux
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../../store/actions";

// Tools
import i18n from "../../../i18n";
import moment from 'moment';
import 'moment-timezone';
import {calculateEventsForDate, timeToNumber} from "../../../tools/calendarTools";

// Components
import {BottomBar, Spinner, Tour} from "../../../components";
import withCaretakers from "../../../hoc/withCaretakers";

import {TitleWrapper} from "../../../contexts/TitleProvider";

import css from "./Overview.module.css";
import {addZeroes} from "../../../variables/tools";
import Item from "./Item";
import WholeDayItem from "./WholeDayItem";
import {getPageHelpSteps} from "../../../help/tools";
import ListItem from "./ListItem";
import {ScrollContext} from "../../../contexts/ScrollProvider";
import {useSwipeable} from "react-swipeable";

const hourHeight = 60;

const OverView = props => {
    const dispatch = useDispatch();
    const accountData = useSelector(state => state.auth.accountData);
    const handlers = useSwipeable({
        onSwipedLeft: e => props.push("/caretakers/overview/" + props.caretakerUuid + "/" + moment(props.match.params.date, "YYYY-MM-DD").add(1, "day").format("YYYY-MM-DD")),
        onSwipedRight: e => props.push("/caretakers/overview/" + props.caretakerUuid + "/" + moment(props.match.params.date, "YYYY-MM-DD").subtract(1, "day").format("YYYY-MM-DD"))
    });

    // Timeline view / table view
    const [showTimeline, setShowTimeline] = useState(accountData.dayDisplay === "timeline");
    const scrollContext                   = useContext(ScrollContext);

    // Help
    const [helpOpen, setHelpOpen] = useState(false);
    const doHelp                  = () => setHelpOpen(true);
    const closeHelp               = () => setHelpOpen(false);


    const loadingCalendars = useSelector(state => state.calendars.loading);
    const calendarsFor     = useSelector(state => state.calendars.calendarsFor);
    const calendars        = useSelector(state => state.calendars.calendars);
    const loadedAt         = useSelector(state => state.calendars.loadedAt);

    useEffect(() => {
        if (
            props.caretaker &&
            !loadingCalendars && (
                calendarsFor !== props.caretaker.uuid ||
                (!loadedAt || loadedAt.isBefore(moment().subtract(5, "minutes")))
            )
        ) {
            dispatch(actions.calendars.load(props.caretaker.uuid));
        }
    }, [props.caretaker, loadingCalendars, calendarsFor, loadedAt, dispatch]);

    useEffect(() => {
        scrollContext.scrollBar.current.scrollTop = 0;
    }, [showTimeline, scrollContext.scrollBar]);

    /*        if (prevProps.loadingCalendars && !this.props.loadingCalendars && this.props.caretaker) {
                this.handleChange({date: this.state.formValues.date});
            }*/

    if (!props.caretaker || !calendars || props.loading || loadingCalendars) {
        return (
            <TitleWrapper title={i18n.caretakersItemCalendars}>
                <div>
                    <Spinner/>
                    <BottomBar backButton/>
                </div>
            </TitleWrapper>
        );
    }

    const events = calculateEventsForDate(moment(props.match.params.date, "YYYY-MM-DD"), calendars);

    const wholeDayFilter           = item => item.itemType === "birthday" || (item.currentDate.start === "00:00" && item.currentDate.end === "23:59");
    const wholeDayEvents           = events.filter(wholeDayFilter);
    const partDayEvents            = events.filter(item => !wholeDayFilter(item));
    const partDayEventsWithOverlap = [];
    partDayEvents.forEach((item, index, self) => {
        const overlapIndices = [];

        // Calculate amount of items this item overlaps with
        const overlapItems = self.filter(otherItem =>
            otherItem.currentDate.startMoment.isSameOrBefore(item.currentDate.endMoment) &&
            otherItem.currentDate.endMoment.isSameOrAfter(item.currentDate.startMoment)
        );

        // Calculate maximum amount of overlapping items
        const maxOverlap = overlapItems.reduce((total, otherItem) => {
            // Skip checking itself
            if (otherItem === item) return total;

            // Calculate overlapping area
            const overlapStart = moment.max(item.currentDate.startMoment, otherItem.currentDate.startMoment);
            const overlapEnd   = moment.min(item.currentDate.endMoment, otherItem.currentDate.endMoment);

            // Check amount of items in calculated area
            const overlappingItems = overlapItems.filter(overlapItem =>
                overlapItem.currentDate.startMoment.isSameOrBefore(overlapEnd) &&
                overlapItem.currentDate.endMoment.isSameOrAfter(overlapStart)
            ).length;

            // Return maximum overlap
            return Math.max(total, overlappingItems);
        }, 1);

        // Build overlap index array
        partDayEventsWithOverlap.filter(otherItem =>
            otherItem.currentDate.startMoment.isSameOrBefore(item.currentDate.endMoment) &&
            otherItem.currentDate.endMoment.isSameOrAfter(item.currentDate.startMoment)
        ).forEach(item => {
            overlapIndices.push(item.overlapIndex)
        });
        overlapIndices.sort((a, b) => a - b);

        partDayEventsWithOverlap.push({
            ...item,
            overlap:      maxOverlap,
            overlapIndex: overlapIndices.reduce((total, current) => total === current ? total + 1 : total, 0),
            top:          (timeToNumber(item.currentDate.start) / 60) * hourHeight,
            height:       (((item.currentDate.duration + 1) / 60) * hourHeight) - 1
        });
    });

    const addItem = hour => props.push("/caretakers/adddate/" + props.caretakerUuid + "/" + props.match.params.date + "/" + hour);

    // Calculate maximum amount of overlap for each item and do this until everything is aligned
    let aligned              = false;
    let partDayEventsAligned = partDayEventsWithOverlap;
    while (!aligned) {
        aligned              = true;
        // eslint-disable-next-line no-loop-func
        partDayEventsAligned = partDayEventsAligned.map((item, index, self) => {
            const maxOverlap = self.filter(otherItem =>
                otherItem.currentDate.startMoment.isSameOrBefore(item.currentDate.endMoment) &&
                otherItem.currentDate.endMoment.isSameOrAfter(item.currentDate.startMoment)
            ).reduce((total, otherItem) => Math.max(total, otherItem.overlap), item.overlap);
            if (maxOverlap !== item.overlap) {
                aligned = false;
            }
            return {...item, overlap: maxOverlap};
        });
    }

    return (
        <TitleWrapper title={
            i18n.calendarDay
                .replace("%1", moment(props.match.params.date, "YYYY-MM-DD").format("LL"))
                .replace("%2", props.caretaker ? props.caretaker.name : i18n.caretaker)
        }>
            <Tour
                isOpen={helpOpen}
                onRequestClose={closeHelp}
                steps={getPageHelpSteps("overview")}
            />
            <div className={css.containerStretcher} {...handlers}>
                <div className="content">
                    {showTimeline ? (
                        <>
                            {wholeDayEvents.length > 0 && (
                                <div className={css.wholeDayContainer}>
                                    {wholeDayEvents.map(item => <WholeDayItem key={item.uuid} {...item} />)}
                                </div>
                            )}
                            <div className={css.timeline}>
                                <div className={css.hourContainer}>
                                    {[...Array(25)].map((v, hour) => (
                                        <div className={css.hour} key={hour} style={{height: hourHeight + "px"}}>
                                            <span className={css.hourSpan}>{addZeroes(hour)}:00</span>
                                        </div>
                                    ))}
                                </div>
                                <div className={css.timelineContent}>
                                    <div className={css.hourLineContainer}>
                                        {[...Array(25)].map((v, hour) => (
                                            <div className={css.hourLine} key={hour} style={{height: hourHeight + "px"}} onClick={() => addItem(hour)}/>
                                        ))}
                                    </div>
                                    <div className={css.itemContainer}>
                                        {partDayEventsAligned.map(item => <Item key={item.uuid} {...item} />)}
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        events.length === 0 ?
                            <p>{i18n.caretakersOverviewNoItems}</p> :
                            <div className={css.listItemsContainer}>
                                {events.map(item => <ListItem key={item.uuid} {...item} />)}
                            </div>
                    )}
                    <BottomBar
                        backButton
                        backUrl={"/caretakers/calendars/" + props.caretakerUuid}
                        funcs={[{title: showTimeline ? i18n.calendarDayOverview : i18n.calendarDayTimeline, func: () => setShowTimeline(!showTimeline)}]}
                        addButton={() => props.push("/caretakers/adddate/" + props.caretakerUuid + "/" + props.match.params.date)}
                        helpOpen={helpOpen} onHelp={doHelp} onCloseHelp={closeHelp}
                    />
                </div>
            </div>
        </TitleWrapper>
    );
}

export default withCaretakers(OverView);
