export default {
    loginTitle:           "Inloggen",
    loginTitleShort:      "Log in",
    loginTitleMark:       "L",
    loginEmail:           "E-mailadres",
    loginError:           "Het inloggen is mislukt. Controleer je logingegevens en internetconnectie en probeer het opnieuw.",
    loginDisabled:        "Je account is uitgeschakeld. Neem contact op met de helpdesk om deze weer te activeren.",
    loginButton:          "Inloggen",
    loginPassword:        "Wachtwoord",
    loginSignUp:          "Account aanmaken",
    loginForgot:          "Wachtwoord vergeten",
    loginAlreadyLoggedIn: "U bent al ingelogged. Als u wilt inloggen onder een ander account, log dan eerst uit.",

    signUpCallToAction:                "Nog geen account?",
    signUpEmail:                       "E-mailadres",
    signUpPassword1:                   "Wachtwoord",
    signUpPassword2:                   "Herhaal wachtwoord",
    signUpSubmitButton:                "Account aanmaken",
    signUpBackToLogin:                 "Terug naar inloggen",
    signUpTitle:                       "Account aanmaken",
    signUpTitleShort:                  "Aanmelden",
    signUpTitleMark:                   "A",
    signUpPasswordDontMatch:           "De wachtwoorden komen niet overeen",
    signUpPasswordTooShort:            "Het wachtwoord is te kort, er is een minimum van 7 karakters vereist",
    signUpPasswordMinimumRequirements: "Het wachtwoord moet minimaal een kleine letter, een grote letter en een nummer bevatten",
    signUpSuccess:                     "U heeft zich succesvol ingeschreven",
    signUpError:                       "Er is een fout opgetreden bij het aanmaken van je account.",
    signUpErrorExists:                 "Er bestaat al een account met dit e-mailadres. Log in met je gegevens.",
    signUpErrorPassword:               "Het wachtwoord is niet sterk genoeg. Volg de instructies voor een veilig wachtwoord.",

    forgotTitle:         "Wachtwoord vergeten",
    forgotBody:          "Vul je e-mailadres in om je wachtwoord te resetten.",
    forgotEmail:         "E-mailadres",
    forgotSubmitButton:  "Wachtwoord resetten",
    forgotSubmitSuccess: "Er is een e-mail gestuurd",
    forgotSubmitFailed:  "Het wachtwoord kon niet gereset worden.",

    verifyTitle:               "E-mailadres bevestigen",
    verifyBodyLoading:         "Je e-mailadres wordt bevestigd...",
    verifyBodyVerified:        "Je e-mailadres is bevestigd. U kunt nu inloggen.",
    verifyBodyAlreadyVerified: "Je e-mailadres was al bevestigd. U kunt nu inloggen.",
    verifyBodyUnknown:         "Deze verificatie sleutel is onbekend.",

    resetPasswordTitle:   "Wachtwoord opnieuw instellen",
    resetPasswordBody:    "Kies een nieuw wachtwoord om te kunnen inloggen.",
    resetPassword1:       "Wachtwoord",
    resetPassword2:       "Herhaal wachtwoord",
    resetPasswordSubmit:  "Wachtwoord instellen",
    resetPasswordSuccess: "Je wachtwoord is succesvol ingesteld. U kunt nu inloggen met dit nieuwe wachtwoord.",
    resetPasswordFailed:  "Er is een fout opgetreden bij het instellen van je wachtwoord.",
    resetPasswordUnknown: "Deze wachtwoord reset sleutel is onbekend.",

    invitationTitle:           "Account maken op uitnodiging",
    invitationTitleExisting:   "Zorgvragers toevoegen op uitnodiging",
    invitationBody:            "U bent uitgenodigd door %invitedBy% om toegang te krijgen tot de Spiqle Agenda van de volgende zorgvrager(s) / zorgvrager groep(en):",
    invitationSubBody:         "Om toegang te krijgen dient U een account aan te maken. Als u al een account heeft, kunt u daar op inloggen.",
    invitationSubBodyExisting: "Om toegang te krijgen, kies 'Accepteren', als U een ander account wil gebruiken voor deze uitnodiging, log eerst uit van dit account.",
    invitationTypeMapping:     {
        caretaker:   "Zorgvrager",
        group:       "Groep",
        affiliate:   "Affiliate",
        distributor: "Distributeur",
        default:     "Onbekend"
    },
    invitationButtonAccept:    "Accepteren",

    invitationErrorUnknownToken: "Deze uitnodigingslink is niet geldig.",
    invitationErrorTokenUsed:    "Deze uitnodigingslink is al gebruikt.",
    invitationErrorTokenExpired: "Deze uitnodigingslink is verlopen, vraag degene die U heeft uitgenodigd om deze opnieuw te versturen.",
    invitationErrorTokenIsYours: "Deze uitnodigingslink is door uzelf verzonden. Om deze te gebruiken, log eerst uit met dit account.",
    invitationErrorGeneric:      "Er is een fout opgetreden bij het gebruik van de uitnodigingslink.",

    accountTitle:     "Wijzig account gegevens",
    accountName:      "Naam",
    accountAddress:   "Adres",
    accountZipcode:   "Postcode",
    accountCity:      "Plaats",
    accountState:     "Provincie",
    accountCountry:   "Land",
    accountLanguage:  "Taal",
    accountLanguages: {
        auto:    "Automatisch",
        nl:      "Nederlands",
        en:      "English",
        da:      "Dansk",
        de:      "Deutsch",
        "de-CH": "Schweiz Deutsch"
    },
    accountFontSize:  "Lettertype grootte",
    accountFontSizes: {
        14: "Klein",
        18: "Middel",
        21: "Groot"
    },
    accountDayDisplay: "Standaard dag weergave",
    accountDayOptions: {
        overview: "Overzicht",
        timeline: "Tijdlijn"
    },

    accountCalendarDisplay: "Standaard kalender weergave",
    accountCalendarOptions: {
        overview: "Overzicht",
        calendar: "Kalender"
    },

    accountAdditionalActions: "Account instellingen",

    changePasswordTitle:              "Account wachtwoord veranderen",
    changePasswordOldPassword:        "Huidig wachtwoord",
    changePasswordNewPassword:        "Nieuw wachtwoord",
    changePasswordRepeatPassword:     "Herhaal nieuw wachtwoord",
    changePasswordAction:             "Wachtwoord veranderen",
    changePasswordInvalidOldPassword: "Het huidige wachtwoord is niet correct. Voer het juiste wachtwoord in en probeer het opnieuw.",
    changePasswordNotStrong:          "Het wachtwoord is niet sterk genoeg, er is een minimum van 7 karakters vereist en moet minimaal een kleine letter, grote letter en een nummer bevatten.",
    changePasswordSuccessful:         "Je wachtwoord is succesvol veranderd.",

    changeEmailTitle: "E-mail veranderen",

    errorAccountLoad:   "Er is een fout opgetreden bij het laden van je account gegevens",
    errorAccountSave:   "Er is een fout opgetreden bij het opslaan van je account gegevens",
    successAccountSave: "Je account gegevens zijn succesvol opgeslagen",
};