import React from "react";
import {Card, CardBody} from "reactstrap";
import i18n from "../../i18n";

const NoCaretakers = props => {
    return (
        <Card>
            <CardBody>
                {i18n.noCaretakers}
            </CardBody>
        </Card>
    );
};

export default NoCaretakers;
