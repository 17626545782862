import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

import * as actions from "../../../../../store/actions";
import i18n from "../../../../../i18n";
import {Button, Form, Spinner} from "../../../../../components";

const modalForm = {
    rightType:   {
        label:        i18n.usersRightsTableType,
        options:      {
            caretaker:   i18n.usersRightsTypeCaretaker,
            group:       i18n.usersRightsTypeGroup,
            distributor: i18n.usersRightsTypeDistributor,
            affiliate:   i18n.usersRightsTypeAffiliate
        },
        defaultValue: "caretaker",
        type:         "select"
    },
    caretaker:   {
        label: i18n.usersRightsTypeCaretaker,
        type:  "caretaker",
        show:  form => form.rightType.value === "caretaker"
    },
    group:       {
        label: i18n.usersRightsTypeGroup,
        type:  "group",
        show:  form => form.rightType.value === "group"
    },
    distributor: {
        label: i18n.usersRightsTypeDistributor,
        type:  "distributor",
        show:  form => form.rightType.value === "distributor"
    },
    affiliate:   {
        label: i18n.usersRightsTypeAffiliate,
        type:  "affiliate",
        show:  form => form.rightType.value === "affiliate"
    },
    calendar:    {
        label:        i18n.usersRightsCalendar,
        options:      i18n.usersRightsCalendarRight,
        defaultValue: "0",
        type:         "select"
    },
    settings:    {
        label:        i18n.usersRightsSettings,
        options:      i18n.usersRightsSettingsRight,
        defaultValue: "0",
        type:         "select"
    },
    messages:    {
        label:        i18n.usersRightsMessages,
        options:      i18n.usersRightsMessagesRight,
        defaultValue: "0",
        type:         "select"
    },
    details:     {
        label:        i18n.usersRightsDetails,
        options:      i18n.usersRightsDetailsRight,
        defaultValue: "0",
        type:         "select"
    },
    preview:     {
        label:        i18n.usersRightsPreview,
        options:      i18n.usersRightsPreviewRight,
        defaultValue: "0",
        type:         "select"
    },
    technical:   {
        label:        i18n.usersRightsTechnical,
        options:      i18n.usersRightsTechnicalRight,
        defaultValue: "0",
        type:         "select"
    },
    invite:      {
        label:        i18n.usersRightsInvite,
        options:      i18n.usersRightsInviteRight,
        defaultValue: "0",
        type:         "select"
    },
    payments:    {
        label:        i18n.usersRightsPayments,
        options:      i18n.usersRightsPaymentsRight,
        defaultValue: "0",
        type:         "select"
    }
};

const AddRightModal = props => {
    const dispatch                          = useDispatch();
    const [formValues, setFormValues]       = useState({});
    const [formValid, setFormValid]         = useState(false);
    const [startedSaving, setStartedSaving] = useState(false);
    const saving                            = useSelector(state => state.users.saving);
    const error                             = useSelector(state => state.users.error);

    // Close dialog when finished with saving
    useEffect(() => {
        if (startedSaving && !saving && !error) {
            setStartedSaving(false);
            props.close();
        }
    }, [startedSaving, saving, error, props]);

    // Clear errors when opening dialog
    useEffect(() => {
        if (props.show) {
            dispatch(actions.users.clearError());
            setFormValues({});
            setStartedSaving(false);
        }
    }, [dispatch, props.show]);

    const handleFormChange   = (formValues, formValid) => {
        setFormValues(formValues);
        setFormValid(formValid);
    };
    const handleSave         = () => {
        setStartedSaving(true);
        dispatch(actions.users.rightAdd(props.userId, formValues));
    };
    const setPresetNoRights  = () => setFormValues({
        ...formValues,
        calendar:  "0",
        settings:  "0",
        messages:  "0",
        details:   "0",
        preview:   "0",
        technical: "0",
        invite:    "0",
        payments:  "0"
    });
    const setPresetCaretaker = () => setFormValues({
        ...formValues,
        calendar:  "2",
        settings:  "2",
        messages:  "2",
        details:   "2",
        preview:   "1",
        technical: "0",
        invite:    "1",
        payments:  "1"
    });
    const setPresetAdmin     = () => setFormValues({
        ...formValues,
        calendar:  "2",
        settings:  "2",
        messages:  "2",
        details:   "2",
        preview:   "1",
        technical: "2",
        invite:    "1",
        payments:  "1"
    });

    return (
        <Modal isOpen={props.show} toggle={props.close} size="xl">
            <ModalHeader className="justify-content-center uppercase title" tag="h4">{i18n.usersRightsAddRightTitle}</ModalHeader>
            <ModalBody>
                <Form formData={modalForm} onChange={handleFormChange} values={formValues}/>
                {saving && <Spinner/>}
            </ModalBody>
            <ModalFooter>
                <div className="float-left">
                    <Button color="warning" round onClick={setPresetNoRights}>{i18n.usersRightsPresetNoRights}</Button>
                    <Button color="warning" round onClick={setPresetCaretaker}>{i18n.usersRightsPresetCaretaker}</Button>
                    <Button color="warning" round onClick={setPresetAdmin}>{i18n.usersRightsPresetAdmin}</Button>
                </div>
                <div className="float-right">
                    <Button color="danger" round onClick={props.close}>{i18n.buttonCancel}</Button>
                    <Button color="info" round onClick={handleSave} disabled={!formValid}>{i18n.usersRightsAddRight}</Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

AddRightModal.propTypes = {
    userId: PropTypes.string.isRequired,
    show:   PropTypes.bool,
    close:  PropTypes.func.isRequired
};

export default AddRightModal;