import React from 'react';
import PropTypes from 'prop-types';

import i18n from "../../../../i18n";

import {Table} from "reactstrap";
import {BoolCheck, Timestamp, CollapseCard, Button} from "../../../../components";
import DetailItem from "./DetailItem";
import css from "./Info.module.css";

const details = ({details, push}) => (
    <CollapseCard title={i18n.usersInfoDataHead.replace("%1", details.name)} defaultOpenDesktop defaultOpenMobile>
        <Table>
            <tbody className={css.tableItems}>
            <DetailItem name={i18n.usersTableName}>
                {details.name}
            </DetailItem>
            <DetailItem name={i18n.usersTableAddress}>
                {details.address}
            </DetailItem>
            <DetailItem name={i18n.usersTableZipcode}>
                {details.zipcode}
            </DetailItem>
            <DetailItem name={i18n.usersTableCity}>
                {details.city}
            </DetailItem>
            <DetailItem name={i18n.usersTableState}>
                {details.state}
            </DetailItem>
            <DetailItem name={i18n.usersTableCountry}>
                {details.country}
            </DetailItem>
            <DetailItem name={i18n.usersTableEmail}>
                {details.email}
            </DetailItem>
            <DetailItem name={i18n.usersTablePhone}>
                {details.phone}
            </DetailItem>
            <DetailItem name={i18n.usersTableAdmin}>
                <BoolCheck value={details.super_admin}/>
            </DetailItem>
            <DetailItem name={i18n.usersTableTwoFactorEnabled}>
                <BoolCheck value={details.two_factor_enabled}/>
            </DetailItem>
            <DetailItem name={i18n.usersTableEmailConfirmed}>
                <BoolCheck value={details.email_confirmed}/>
            </DetailItem>
            <DetailItem name={i18n.usersTableDisabled}>
                <BoolCheck value={details.disabled}/>
            </DetailItem>
            <DetailItem name={i18n.usersTableLogin}>
                <Timestamp timeString={details.last_login} format="YYYY-MM-DD HH:mm:ss Z"/>
            </DetailItem>
            </tbody>
        </Table>
        <Button className="float-right" round color="info" onClick={() => push("/admin/users/edit/" + details.uuid)}>{i18n.usersEditTitle}</Button>
    </CollapseCard>
);

details.propTypes = {
    details: PropTypes.object.isRequired,
    push: PropTypes.func.isRequired
};

export default details;