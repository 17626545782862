import {call, put, select, takeLatest} from "redux-saga/effects";
import {push} from "connected-react-router";
import * as actions from "../actions";
import * as actionTypes from "../actionTypes";
import * as api from "../../api";
import i18n from "../../i18n";

// Watcher
export function* watchGroups() {
    yield takeLatest(actionTypes.GROUPS_LOAD, loadSaga);
    yield takeLatest(actionTypes.GROUPS_LOAD_IF_NEEDED, loadIfNeededSaga);
    yield takeLatest(actionTypes.GROUPS_SAVE, saveSaga);
    yield takeLatest(actionTypes.GROUPS_MANAGE_SAVE, manageSaveSaga);
    yield takeLatest(actionTypes.GROUPS_MANAGE_CARETAKERS, manageCaretakersSaga);
    yield takeLatest(actionTypes.GROUPS_REMOVE, removeSaga);
    yield takeLatest(actionTypes.GROUPS_ADD_CARETAKERS, addCaretakersSaga);
    yield takeLatest(actionTypes.GROUPS_REMOVE_CARETAKER, removeCaretakerSaga);
}

// Sagas
function* loadSaga(action) {
    try {
        const response = yield call(api.groups.load);
        yield put(actions.groups.loadSuccess(response.data.groups));
    } catch (err) {
        yield put(actions.groups.loadFail());
    }
}

function* loadIfNeededSaga(action) {
    let groups = yield select(state => state.groups);
    if (!groups.loading && !groups.groupsLoaded) {
        yield put(actions.groups.load());
    }
}

function* saveSaga(action) {
    try {
        yield call(api.groups.save, action.uuid, action.groupDetails);
        yield put(actions.groups.saveSuccess());
        yield put(actions.groups.load());
        yield put(push("/admin/groups"));
        yield put(actions.messages.successPush(i18n.groupsEditSuccess));
    } catch (err) {
        yield put(actions.groups.saveFail());
        yield put(actions.messages.errorPush(i18n.groupsEditError));
    }
}

function* manageSaveSaga(action) {
    try {
        yield call(api.groups.manageSave, action.uuid, action.groupDetails);
        yield put(actions.groups.manageSaveSuccess());
        yield put(actions.groups.load());
        yield put(actions.messages.successPush(i18n.groupsManageSuccess));
    } catch (err) {
        yield put(actions.groups.manageSaveFail());
        yield put(actions.messages.errorPush(i18n.groupsManageFail));
    }
}

function* manageCaretakersSaga(action) {
    try {
        yield call(api.groups.manageCaretakers, action.uuid, action.caretakers);
        yield put(actions.groups.manageCaretakersSuccess());
        yield put(actions.groups.load());
        yield put(push("/groups/edit/" + action.uuid));
        yield put(actions.messages.successPush(i18n.groupsManageCaretakersSuccess));
    } catch (err) {
        yield put(actions.groups.manageCaretakersFail());
        yield put(actions.messages.errorPush(i18n.groupsManageCaretakersFail));
    }
}

function* removeSaga(action) {
    try {
        yield call(api.groups.remove, action.uuid);
        yield put(actions.groups.removeSuccess());
        yield put(actions.groups.load());
        yield put(push("/admin/groups"));
        yield put(actions.messages.successPush(i18n.groupsRemoveSuccess));
    } catch (err) {
        yield put(actions.groups.removeFail());
        yield put(actions.messages.errorPush(i18n.groupsRemoveError));
    }
}

function* addCaretakersSaga(action) {
    try {
        yield call(api.groups.addCaretakers, action.group, action.caretakers.join(","));
        yield put(actions.groups.addCaretakersSuccess());
        yield put(actions.groups.load());
        yield put(push("/admin/groups/caretakers/" + action.group));
        yield put(actions.messages.successPush(i18n.groupsAddCaretakersSuccess));
    } catch (err) {
        yield put(actions.groups.addCaretakersFail(err));
        yield put(actions.messages.errorPush(i18n.groupsAddCaretakersError));
    }
}

function* removeCaretakerSaga(action) {
    try {
        yield call(api.groups.removeCaretaker, action.group, action.caretaker);
        yield put(actions.groups.removeCaretakerSuccess());
        yield put(actions.groups.load());
        yield put(actions.messages.successPush(i18n.groupsRemoveCaretakerSuccess));
    } catch (err) {
        yield put(actions.groups.removeCaretakerFail());
        yield put(actions.messages.errorPush(i18n.groupsRemoveCaretakerError));
    }
}

