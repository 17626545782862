import axios from 'axios';
import * as variables from "../variables";

import * as auth from "./auth";
import * as affiliates from "./affiliates";
import * as calendars from "./calendars";
import * as caretakers from "./caretakers";
import * as caretakerMessages from "./caretakerMessages";
import * as contact from "./contact";
import * as distributors from "./distributors";
import * as groups from "./groups";
import * as groupCalendars from "./groupCalendars";
import * as groupMessages from "./groupMessages";
import * as help from "./help";
import * as machines from "./machines";
import * as users from "./users";
import moment from "moment";

const instance       = axios.create({baseURL: variables.apiUrl});
let apiAuthenticated = false;
let currentToken     = null;

export const setAuthToken = (token) => {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    currentToken                                      = token;
    apiAuthenticated                                  = true;
};

export const removeAuthToken = (token) => {
    delete instance.defaults.headers.common['Authorization'];
    currentToken     = null;
    apiAuthenticated = false;
};

const checkApiValues = values => {
    let output = {};
    for (let key in values) {
        if (values.hasOwnProperty(key)) {
            if (moment.isMoment(values[key])) {
                output[key] = values[key].format("YYYY-MM-DD HH:mm:ss");
            } else {
                output[key] = values[key];
            }
        }
    }
    return output;
};

const convertToFormData = values => {
    let formData = new FormData();
    for (let key in values) {
        if (values.hasOwnProperty(key)) {
            // Workaround to rebuild attachment array and split off the files
            if (key === "attachments" && Array.isArray(values[key])) {
                let newFiles       = [];
                let newAttachments = values[key].map(item => {
                    if (item.file && item.file instanceof File) {
                        return {...item, file: newFiles.push(item.file) - 1, filename: item.file.name};
                    }
                    return {...item};
                });
                formData.append("attachments", JSON.stringify(newAttachments));
                newFiles.map(file => formData.append("file[]", file));
            } else {
                formData.append(key, values[key]);
            }
        }
    }
    return formData;
};

export const apiGetCall = (url, data = null, authenticated = true) => {
    if (authenticated && !apiAuthenticated) {
        throw new ApiError("NOT_AUTHENTICATED");
    }

    if (data) {
        const getData = checkApiValues(data);
        return instance.get(url, {params: getData});
    } else {
        return instance.get(url);
    }
};

export const apiPostCall = (url, data = {}, authenticated = true, postFiles = false) => {
    if (authenticated && !apiAuthenticated) {
        throw new ApiError("NOT_AUTHENTICATED");
    }

    const postData = checkApiValues(data);
    if (postFiles) {
        // Convert to multipart form data for file uploading
        let formData = convertToFormData(postData);
        return instance.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}});
    } else {
        return instance.post(url, postData);
    }
};

export const apiGetFullPath     = path => variables.apiUrl + path;
export const apiGetBasePlusPath = path => variables.baseUrl + path;
export const apiGetToken        = () => currentToken;

export const ApiError = (error) => {
    this.response = {data: {error}};
    this.toString = () => this.response.data.error;
};

export const parseApiError = err => {
    let errorMsg = "ERROR";
    if (err.response && err.response.data && err.response.data.error) {
        errorMsg = err.response.data.error;
    }
    return errorMsg;
};

export {auth, affiliates, calendars, caretakers, caretakerMessages, contact, distributors, groups, groupCalendars, groupMessages, help, machines, users};