export default {
    loginTitle:           "Log ind",
    loginTitleShort:      "Log ind",
    loginTitleMark:       "L",
    loginEmail:           "E-mailadresse",
    loginError:           "Login mislykkedes. Kontroller dine loginoplysninger og internetforbindelse og prøv igen.",
    loginDisabled:        "Din konto er deaktiveret. Kontakt venligst helpdesk for at aktivere den igen.",
    loginButton:          "Log ind",
    loginPassword:        "Adgangskode",
    loginSignUp:          "Opret konto",
    loginForgot:          "Glemt adgangskode",
    loginAlreadyLoggedIn: "Du er allerede logget ind. Hvis du ønsker at logge ind med en anden konto, skal du først logge ud.",

    signUpCallToAction:                "Har du ikke en konto?",
    signUpEmail:                       "E-mailadresse",
    signUpPassword1:                   "Adgangskode",
    signUpPassword2:                   "Gentag adgangskode",
    signUpSubmitButton:                "Opret konto",
    signUpBackToLogin:                 "Tilbage til login",
    signUpTitle:                       "Opret konto",
    signUpTitleShort:                  "Tilmeld",
    signUpTitleMark:                   "A",
    signUpPasswordDontMatch:           "Adgangskoderne stemmer ikke overens.",
    signUpPasswordTooShort:            "Adgangskoden er for kort, et minimum på 7 tegn er påkrævet.",
    signUpPasswordMinimumRequirements: "Adgangskoden skal indeholde mindst et lille bogstav, et stort bogstav og et tal.",
    signUpSuccess:                     "Du har succesfuldt tilmeldt dig.",
    signUpError:                       "Der opstod en fejl ved oprettelse af din konto.",
    signUpErrorExists:                 "Der findes allerede en konto med denne e-mailadresse. Log ind med dine oplysninger.",
    signUpErrorPassword:               "Adgangskoden er ikke stærk nok. Følg instruktionerne for en sikker adgangskode.",

    forgotTitle:         "Glemt adgangskode",
    forgotBody:          "Indtast din e-mailadresse for at nulstille din adgangskode.",
    forgotEmail:         "E-mailadresse",
    forgotSubmitButton:  "Nulstil adgangskode",
    forgotSubmitSuccess: "En e-mail er blevet sendt",
    forgotSubmitFailed:  "Adgangskoden kunne ikke nulstilles",

    verifyTitle:               "Bekræft e-mailadresse",
    verifyBodyLoading:         "Din e-mailadresse bekræftes...",
    verifyBodyVerified:        "Din e-mailadresse er bekræftet. Du kan nu logge ind.",
    verifyBodyAlreadyVerified: "Din e-mailadresse var allerede bekræftet. Du kan nu logge ind.",
    verifyBodyUnknown:         "Denne verifikationsnøgle er ukendt",

    resetPasswordTitle:   "Nulstil adgangskode",
    resetPasswordBody:    "KVælg en ny adgangskode for at kunne logge ind.",
    resetPassword1:       "Adgangskode",
    resetPassword2:       "Gentag adgangskode",
    resetPasswordSubmit:  "Indstil adgangskode",
    resetPasswordSuccess: "Din adgangskode er blevet sat succesfuldt. Du kan nu logge ind med denne nye adgangskode.",
    resetPasswordFailed:  "Der opstod en fejl ved indstilling af din adgangskode.",
    resetPasswordUnknown: "Denne adgangskode nulstilling nøgle er ukendt.",

    invitationTitle:           "Opret konto på invitation",
    invitationTitleExisting:   "Tilføj plejemodtagere på invitation",
    invitationBody:            "Du er inviteret af %invitedBy% til at få adgang til Spiqle Agendaen for følgende plejemodtagere / plejemodtagergrupper:",
    invitationSubBody:         "For at få adgang, skal du oprette en konto. Hvis du allerede har en konto, kan du logge ind på den.",
    invitationSubBodyExisting: "For at få adgang, vælg 'Accepter'. Hvis du ønsker at bruge en anden konto til denne invitation, skal du først logge ud af denne konto.",
    invitationTypeMapping:     {
        caretaker:   "Plejemodtager",
        group:       "Gruppe",
        affiliate:   "Affiliate",
        distributor: "Distributør",
        default:     "Ukendt"
    },
    invitationButtonAccept:    "Accepter",

    invitationErrorUnknownToken: "Denne invitationslink er ikke gyldig.",
    invitationErrorTokenUsed:    "Denne invitationslink er allerede blevet brugt.",
    invitationErrorTokenExpired: "Denne invitationslink er udløbet, bed personen, der inviterede dig, om at sende det igen.",
    invitationErrorTokenIsYours: "Denne invitationslink blev sendt af dig selv. For at bruge den, log først ud med denne konto.",
    invitationErrorGeneric:      "Der opstod en fejl ved brug af invitationslinket.",

    accountTitle:     "Ændre kontoinformation",
    accountName:      "Navn",
    accountAddress:   "Adresse",
    accountZipcode:   "Postnummer",
    accountCity:      "By",
    accountState:     "Provins",
    accountCountry:   "Land",
    accountLanguage:  "Sprog",
    accountLanguages: {
        auto:    "Automatisk",
        nl:      "Nederlands",
        en:      "English",
        da:      "Dansk",
        de:      "Deutsch",
        "de-CH": "Schweiz Deutsch"
    },
    accountFontSize:  "Lettertype grootte",
    accountFontSizes: {
        14: "Lille",
        18: "Medium",
        21: "Stor"
    },
    accountDayDisplay: "Standard dagvisning",
    accountDayOptions: {
        overview: "Oversigt",
        timeline: "Tidslinje"
    },

    accountCalendarDisplay: "Standard kalendervisning",
    accountCalendarOptions: {
        overview: "Oversigt",
        calendar: "Kalender"
    },

    accountAdditionalActions: "Kontoinstillinger",

    changePasswordTitle:              "Skift kontoadgangskode",
    changePasswordOldPassword:        "Nuværende adgangskoded",
    changePasswordNewPassword:        "Ny adgangskode",
    changePasswordRepeatPassword:     "Gentag ny adgangskode",
    changePasswordAction:             "Skift adgangskode",
    changePasswordInvalidOldPassword: "Den nuværende adgangskode er ikke korrekt. Indtast den korrekte adgangskode og prøv igen.",
    changePasswordNotStrong:          "Adgangskoden er ikke stærk nok, et minimum på 7 tegn er påkrævet og den skal indeholde mindst et lille bogstav, stort bogstav og et tal.",
    changePasswordSuccessful:         "Din adgangskode er blevet ændret succesfuldt.",

    changeEmailTitle: "Ændre e-mail",

    errorAccountLoad:   "Der opstod en fejl under indlæsning af dine kontoinformationer.",
    errorAccountSave:   "Der opstod en fejl under lagring af dine kontoinformationer.",
    successAccountSave: "Dine kontoinformationer er blevet gemt succesfuldt.",
};