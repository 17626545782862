import * as actionTypes from "../actionTypes";
import {runActionMap, load, loadDone, loadFail, resetError} from "../reducerFunctions";

const initialState = {
    token:            null,
    language:         "auto",
    accountData:      null,
    authenticated:    false,
    authenticating:   true,
    verified:         false,
    rights:           null,
    caretakerRights:  null,
    loading:          false,
    invitedBy:        null,
    invitee:          null,
    invitedRightList: [],
    error:            null,
    redirect:         null,
    loggedInAt:       null
};

const login                  = (state, action) => ({...state, loading: true, loggedInAt: Date.now()});
const startAuth              = (state, action) => ({...state, loading: true, authenticating: true});
const success                = (state, action) => ({
    ...state,
    token:            action.token.idToken,
    language:         action.token.language,
    authenticated:    true,
    loading:          false,
    error:            null,
    invitedBy:        null,
    invitee:          null,
    invitedRightList: []
});
const fail                   = (state, action) => ({...state, token: null, authenticated: false, loading: false, authenticating: false, error: action.error});
const logout                 = (state, action) => ({...state, token: null, authenticated: false, loading: false, authenticating: false, error: null});
const loadAccountDataSuccess = (state, action) => ({
    ...state,
    loading:         false,
    authenticating:  false,
    accountData:     action.accountData,
    rights:          action.rights,
    caretakerRights: action.caretakerRights
});
const saveAccountData        = (state, action) => ({...state, loading: true, accountData: action.accountData});
const loadInviteSuccess      = (state, action) => ({
    ...state,
    loading:          false,
    invitedBy:        action.invitedBy,
    invitee:          action.invitee,
    invitedRightList: action.rightList
});

const actionMap   = {
    [actionTypes.AUTH_START]:                        startAuth,
    [actionTypes.AUTH_SUCCESS]:                      success,
    [actionTypes.AUTH_FAIL]:                         fail,
    [actionTypes.AUTH_LOGIN]:                        login,
    [actionTypes.AUTH_SIGNUP]:                       load,
    [actionTypes.AUTH_LOGOUT]:                       logout,
    [actionTypes.AUTH_FORGOT_PASSWORD]:              load,
    [actionTypes.AUTH_FORGOT_PASSWORD_SUCCESS]:      loadDone,
    [actionTypes.AUTH_FORGOT_PASSWORD_FAIL]:         loadFail,
    [actionTypes.AUTH_RESET_PASSWORD]:               load,
    [actionTypes.AUTH_RESET_PASSWORD_SUCCESS]:       loadDone,
    [actionTypes.AUTH_RESET_PASSWORD_FAIL]:          loadFail,
    [actionTypes.AUTH_CHECK_PASSWORD_TOKEN]:         load,
    [actionTypes.AUTH_CHECK_PASSWORD_TOKEN_SUCCESS]: loadDone,
    [actionTypes.AUTH_CHECK_PASSWORD_TOKEN_FAIL]:    loadFail,
    [actionTypes.AUTH_VERIFY_EMAIL]:                 load,
    [actionTypes.AUTH_VERIFY_EMAIL_SUCCESS]:         loadDone,
    [actionTypes.AUTH_VERIFY_EMAIL_FAIL]:            loadFail,
    [actionTypes.AUTH_SAVE_ACCOUNTDATA]:             saveAccountData,
    [actionTypes.AUTH_SAVE_ACCOUNTDATA_SUCCESS]:     loadDone,
    [actionTypes.AUTH_LOAD_ACCOUNTDATA_SUCCESS]:     loadAccountDataSuccess,
    [actionTypes.AUTH_LOAD_ACCOUNTDATA_FAIL]:        loadFail,
    [actionTypes.AUTH_SAVE_ACCOUNTDATA_FAIL]:        loadFail,
    [actionTypes.AUTH_LOAD_INVITE]:                  load,
    [actionTypes.AUTH_LOAD_INVITE_SUCCESS]:          loadInviteSuccess,
    [actionTypes.AUTH_LOAD_INVITE_FAIL]:             loadFail,
    [actionTypes.AUTH_ACCEPT_INVITE]:                load,
    [actionTypes.AUTH_ACCEPT_INVITE_SUCCESS]:        loadDone,
    [actionTypes.AUTH_ACCEPT_INVITE_FAIL]:           loadFail,
    [actionTypes.AUTH_CHANGE_PASSWORD]:              load,
    [actionTypes.AUTH_CHANGE_PASSWORD_SUCCESS]:      loadDone,
    [actionTypes.AUTH_CHANGE_PASSWORD_FAIL]:         loadFail,
    [actionTypes.AUTH_RESET_ERROR]:                  resetError
};
const authReducer = (state = initialState, action) => runActionMap(state, action, actionMap);

export default authReducer;