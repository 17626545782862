import React from 'react';
import PropTypes from 'prop-types';

const responsiveText = props => (
    (props.inline ? (
        <>
            <span className="full-inline-block">{props.long}</span>
            <span className="mobile-inline-block">{props.short}</span>
        </>
    ) : (
        <>
            <div className="full-block">{props.long}</div>
            <div className="mobile-block">{props.short}</div>
        </>
     ))
);

responsiveText.propTypes = {
    long:   PropTypes.string.isRequired,
    short:  PropTypes.string.isRequired,
    inline: PropTypes.bool
};

export default responsiveText;