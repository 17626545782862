export const RESET_STATE = "RESET_STATE";

export const AUTH_START                        = "AUTH_START";
export const AUTH_LOGIN                        = "AUTH_LOGIN";
export const AUTH_SIGNUP                       = "AUTH_SIGNUP";
export const AUTH_INITIATE_LOGOUT              = "AUTH_INITIATE_LOGOUT";
export const AUTH_LOGOUT                       = "AUTH_LOGOUT";
export const AUTH_FORGOT_PASSWORD              = "AUTH_FORGOT_PASSWORD";
export const AUTH_FORGOT_PASSWORD_SUCCESS      = "AUTH_FORGOT_PASSWORD_SUCCESS";
export const AUTH_FORGOT_PASSWORD_FAIL         = "AUTH_FORGOT_PASSWORD_FAIL";
export const AUTH_RESET_PASSWORD               = "AUTH_RESET_PASSWORD";
export const AUTH_RESET_PASSWORD_SUCCESS       = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAIL          = "AUTH_RESET_PASSWORD_FAIL";
export const AUTH_CHECK_PASSWORD_TOKEN         = "AUTH_CHECK_PASSWORD_TOKEN";
export const AUTH_CHECK_PASSWORD_TOKEN_SUCCESS = "AUTH_CHECK_PASSWORD_TOKEN_SUCCESS";
export const AUTH_CHECK_PASSWORD_TOKEN_FAIL    = "AUTH_CHECK_PASSWORD_TOKEN_FAIL";
export const AUTH_VERIFY_EMAIL                 = "AUTH_VERIFY_EMAIL";
export const AUTH_VERIFY_EMAIL_SUCCESS         = "AUTH_VERIFY_EMAIL_SUCCESS";
export const AUTH_VERIFY_EMAIL_FAIL            = "AUTH_VERIFY_EMAIL_FAIL";
export const AUTH_SUCCESS                      = "AUTH_SUCCESS";
export const AUTH_FAIL                         = "AUTH_FAIL";
export const AUTH_CHECK_LOCALSTORAGE           = "AUTH_CHECK_LOCALSTORAGE";
export const AUTH_CHECK_TIMEOUT                = "AUTH_CHECK_TIMEOUT";
export const AUTH_RESET_ERROR                  = "AUTH_RESET_ERROR";
export const AUTH_LOAD_ACCOUNTDATA             = "AUTH_LOAD_ACCOUNTDATA";
export const AUTH_LOAD_ACCOUNTDATA_SUCCESS     = "AUTH_LOAD_ACCOUNTDATA_SUCCESS";
export const AUTH_LOAD_ACCOUNTDATA_FAIL        = "AUTH_LOAD_ACCOUNTDATA_FAIL";
export const AUTH_SAVE_ACCOUNTDATA             = "AUTH_SAVE_ACCOUNTDATA";
export const AUTH_SAVE_ACCOUNTDATA_SUCCESS     = "AUTH_SAVE_ACCOUNTDATA_SUCCESS";
export const AUTH_SAVE_ACCOUNTDATA_FAIL        = "AUTH_SAVE_ACCOUNTDATA_FAIL";
export const AUTH_SET_LANGUAGE                 = "AUTH_SET_LANGUAGE";
export const AUTH_SET_FONT_SIZE                = "AUTH_SET_FONT_SIZE";
export const AUTH_LOAD_INVITE                  = "AUTH_LOAD_INVITE";
export const AUTH_LOAD_INVITE_SUCCESS          = "AUTH_LOAD_INVITE_SUCCESS";
export const AUTH_LOAD_INVITE_FAIL             = "AUTH_LOAD_INVITE_FAIL";
export const AUTH_ACCEPT_INVITE                = "AUTH_ACCEPT_INVITE";
export const AUTH_ACCEPT_INVITE_SUCCESS        = "AUTH_ACCEPT_INVITE_SUCCESS";
export const AUTH_ACCEPT_INVITE_FAIL           = "AUTH_ACCEPT_INVITE_FAIL";
export const AUTH_CHANGE_PASSWORD              = "AUTH_CHANGE_PASSWORD";
export const AUTH_CHANGE_PASSWORD_SUCCESS      = "AUTH_CHANGE_PASSWORD_SUCCESS";
export const AUTH_CHANGE_PASSWORD_FAIL         = "AUTH_CHANGE_PASSWORD_FAIL";

export const APP_RECEIVED_UNIVERSAL_LINK = "APP_RECEIVED_UNIVERSAL_LINK";
export const APP_RECEIVED_PAUSE          = "APP_RECEIVED_PAUSE";
export const APP_RECEIVED_RESUME         = "APP_RECEIVED_RESUME";
export const APP_RECEIVED_BACK_BUTTON    = "APP_RECEIVED_BACK_BUTTON";
export const APP_RECEIVED_MENU_BUTTON    = "APP_RECEIVED_MENU_BUTTON";
export const APP_RECEIVED_SEARCH_BUTTON  = "APP_RECEIVED_SEARCH_BUTTON";
export const APP_UNLOAD_EVERYTHING       = "APP_UNLOAD_EVERYTHING";

export const CARETAKERS_LOAD                         = "CARETAKERS_LOAD";
export const CARETAKERS_LOAD_IF_NEEDED               = "CARETAKERS_LOAD_IF_NEEDED";
export const CARETAKERS_LOAD_SUCCESS                 = "CARETAKERS_LOAD_SUCCESS";
export const CARETAKERS_LOAD_FAIL                    = "CARETAKERS_LOAD_FAIL";
export const CARETAKERS_LOAD_MACHINE_DETAILS         = "CARETAKERS_LOAD_MACHINE_DETAILS";
export const CARETAKERS_LOAD_MACHINE_DETAILS_SUCCESS = "CARETAKERS_LOAD_MACHINE_DETAILS_SUCCESS";
export const CARETAKERS_LOAD_MACHINE_DETAILS_FAIL    = "CARETAKERS_LOAD_MACHINE_DETAILS_FAIL";
export const CARETAKERS_LOAD_INVITED_USERS           = "CARETAKERS_LOAD_INVITED_USERS";
export const CARETAKERS_LOAD_INVITED_USERS_SUCCESS   = "CARETAKERS_LOAD_INVITED_USERS_SUCCESS";
export const CARETAKERS_LOAD_INVITED_USERS_FAIL      = "CARETAKERS_LOAD_INVITED_USERS_FAIL";
export const CARETAKERS_UNLOAD                       = "CARETAKERS_UNLOAD";
export const CARETAKERS_SAVE                         = "CARETAKERS_SAVE";
export const CARETAKERS_SAVE_SUCCESS                 = "CARETAKERS_SAVE_SUCCESS";
export const CARETAKERS_SAVE_FAIL                    = "CARETAKERS_SAVE_FAIL";
export const CARETAKERS_SAVE_PHOTO                   = "CARETAKERS_SAVE_PHOTO";
export const CARETAKERS_SAVE_PHOTO_SUCCESS           = "CARETAKERS_SAVE_PHOTO_SUCCESS";
export const CARETAKERS_SAVE_PHOTO_FAIL              = "CARETAKERS_SAVE_PHOTO_FAIL";
export const CARETAKERS_REMOVE_PHOTO                 = "CARETAKERS_REMOVE_PHOTO";
export const CARETAKERS_REMOVE_PHOTO_SUCCESS         = "CARETAKERS_REMOVE_PHOTO_SUCCESS";
export const CARETAKERS_REMOVE_PHOTO_FAIL            = "CARETAKERS_REMOVE_PHOTO_FAIL";

export const CARETAKERS_SAVESETTINGS         = "CARETAKERS_SAVESETTINGS";
export const CARETAKERS_SAVESETTINGS_SUCCESS = "CARETAKERS_SAVESETTINGS_SUCCESS";
export const CARETAKERS_SAVESETTINGS_FAIL    = "CARETAKERS_SAVESETTINGS_FAIL";
export const CARETAKERS_SAVEDEFAULTS         = "CARETAKERS_SAVEDEFAULTS";
export const CARETAKERS_SAVEDEFAULTS_SUCCESS = "CARETAKERS_SAVEDEFAULTS_SUCCESS";
export const CARETAKERS_SAVEDEFAULTS_FAIL    = "CARETAKERS_SAVEDEFAULTS_FAIL";
export const CARETAKERS_REMOVE               = "CARETAKERS_REMOVE";
export const CARETAKERS_REMOVE_SUCCESS       = "CARETAKERS_REMOVE_SUCCESS";
export const CARETAKERS_REMOVE_FAIL          = "CARETAKERS_REMOVE_FAIL";

export const CARETAKERS_ADD         = "CARETAKERS_ADD";
export const CARETAKERS_ADD_SUCCESS = "CARETAKERS_ADD_SUCCESS";
export const CARETAKERS_ADD_FAIL    = "CARETAKERS_ADD_FAIL";

export const CARETAKERS_CHECK_SCREEN_CODE             = "CARETAKERS_CHECK_SCREEN_CODE";
export const CARETAKERS_CHECK_SCREEN_CODE_SUCCESS     = "CARETAKERS_CHECK_SCREEN_CODE_SUCCESS";
export const CARETAKERS_CHECK_SCREEN_CODE_FAIL        = "CARETAKERS_CHECK_SCREEN_CODE_FAIL";
export const CARETAKERS_RESET_CHECK_SCREEN_CODE_VALID = "CARETAKERS_RESET_CHECK_SCREEN_CODE_VALID";

export const CARETAKERS_SAVE_MACHINENAME         = "CARETAKERS_SAVE_MACHINENAME";
export const CARETAKERS_SAVE_MACHINENAME_SUCCESS = "CARETAKERS_SAVE_MACHINENAME_SUCCESS";
export const CARETAKERS_SAVE_MACHINENAME_FAIL    = "CARETAKERS_SAVE_MACHINENAME_FAIL";

export const CARETAKERS_RESET_ERROR = "CARETAKERS_RESET_ERROR";

export const CARETAKERS_INVITE_USER         = "CARETAKERS_INVITE_USER";
export const CARETAKERS_INVITE_USER_SUCCESS = "CARETAKERS_INVITE_USER_SUCCESS";
export const CARETAKERS_INVITE_USER_FAIL    = "CARETAKERS_INVITE_USER_FAIL";

export const GROUPS_LOAD                      = "GROUPS_LOAD";
export const GROUPS_LOAD_IF_NEEDED            = "GROUPS_LOAD_IF_NEEDED";
export const GROUPS_LOAD_SUCCESS              = "GROUPS_LOAD_SUCCESS";
export const GROUPS_LOAD_FAIL                 = "GROUPS_LOAD_FAIL";
export const GROUPS_UNLOAD                    = "GROUPS_UNLOAD";
export const GROUPS_SAVE                      = "GROUPS_SAVE";
export const GROUPS_SAVE_SUCCESS              = "GROUPS_SAVE_SUCCESS";
export const GROUPS_SAVE_FAIL                 = "GROUPS_SAVE_FAIL";
export const GROUPS_MANAGE_SAVE               = "GROUPS_MANAGE_SAVE";
export const GROUPS_MANAGE_SAVE_SUCCESS       = "GROUPS_MANAGE_SAVE_SUCCESS";
export const GROUPS_MANAGE_SAVE_FAIL          = "GROUPS_MANAGE_SAVE_FAIL";
export const GROUPS_MANAGE_CARETAKERS         = "GROUPS_MANAGE_CARETAKERS";
export const GROUPS_MANAGE_CARETAKERS_SUCCESS = "GROUPS_MANAGE_CARETAKERS_SUCCESS";
export const GROUPS_MANAGE_CARETAKERS_FAIL    = "GROUPS_MANAGE_CARETAKERS_FAIL";
export const GROUPS_REMOVE                    = "GROUPS_REMOVE";
export const GROUPS_REMOVE_SUCCESS            = "GROUPS_REMOVE_SUCCESS";
export const GROUPS_REMOVE_FAIL               = "GROUPS_REMOVE_FAIL";
export const GROUPS_ADD_CARETAKERS            = "GROUPS_ADD_CARETAKERS";
export const GROUPS_ADD_CARETAKERS_SUCCESS    = "GROUPS_ADD_CARETAKERS_SUCCESS";
export const GROUPS_ADD_CARETAKERS_FAIL       = "GROUPS_ADD_CARETAKERS_FAIL";
export const GROUPS_REMOVE_CARETAKER          = "GROUPS_REMOVE_CARETAKER";
export const GROUPS_REMOVE_CARETAKER_SUCCESS  = "GROUPS_REMOVE_CARETAKER_SUCCESS";
export const GROUPS_REMOVE_CARETAKER_FAIL     = "GROUPS_REMOVE_CARETAKER_FAIL";
export const GROUPS_RESET_ERROR               = "GROUPS_RESET_ERROR";

export const DISTRIBUTORS_LOAD           = "DISTRIBUTORS_LOAD";
export const DISTRIBUTORS_LOAD_IF_NEEDED = "DISTRIBUTORS_LOAD_IF_NEEDED";
export const DISTRIBUTORS_LOAD_SUCCESS   = "DISTRIBUTORS_LOAD_SUCCESS";
export const DISTRIBUTORS_LOAD_FAIL      = "DISTRIBUTORS_LOAD_FAIL";
export const DISTRIBUTORS_SAVE           = "DISTRIBUTORS_SAVE";
export const DISTRIBUTORS_SAVE_SUCCESS   = "DISTRIBUTORS_SAVE_SUCCESS";
export const DISTRIBUTORS_SAVE_FAIL      = "DISTRIBUTORS_SAVE_FAIL";
export const DISTRIBUTORS_REMOVE         = "DISTRIBUTORS_REMOVE";
export const DISTRIBUTORS_REMOVE_SUCCESS = "DISTRIBUTORS_REMOVE_SUCCESS";
export const DISTRIBUTORS_REMOVE_FAIL    = "DISTRIBUTORS_REMOVE_FAIL";
export const DISTRIBUTORS_RESET_ERROR    = "DISTRIBUTORS_RESET_ERROR";

export const AFFILIATES_LOAD           = "AFFILIATES_LOAD";
export const AFFILIATES_LOAD_IF_NEEDED = "AFFILIATES_LOAD_IF_NEEDED";
export const AFFILIATES_LOAD_SUCCESS   = "AFFILIATES_LOAD_SUCCESS";
export const AFFILIATES_LOAD_FAIL      = "AFFILIATES_LOAD_FAIL";
export const AFFILIATES_SAVE           = "AFFILIATES_SAVE";
export const AFFILIATES_SAVE_SUCCESS   = "AFFILIATES_SAVE_SUCCESS";
export const AFFILIATES_SAVE_FAIL      = "AFFILIATES_SAVE_FAIL";
export const AFFILIATES_REMOVE         = "AFFILIATES_REMOVE";
export const AFFILIATES_REMOVE_SUCCESS = "AFFILIATES_REMOVE_SUCCESS";
export const AFFILIATES_REMOVE_FAIL    = "AFFILIATES_REMOVE_FAIL";
export const AFFILIATES_RESET_ERROR    = "AFFILIATES_RESET_ERROR";

export const MESSAGES_LOAD          = "MESSAGES_LOAD";
export const MESSAGES_LOAD_SUCCESS  = "MESSAGES_LOAD_SUCCESS";
export const MESSAGES_LOAD_FAIL     = "MESSAGES_LOAD_FAIL";
export const MESSAGES_UNLOAD        = "MESSAGES_UNLOAD";
export const MESSAGE_SEND           = "MESSAGE_SEND";
export const MESSAGE_SEND_SUCCESS   = "MESSAGE_SEND_SUCCESS";
export const MESSAGE_SEND_FAIL      = "MESSAGE_SEND_FAIL";
export const MESSAGE_SEND_CLEAR     = "MESSAGE_SEND_CLEAR";
export const MESSAGE_CANCEL         = "MESSAGE_CANCEL";
export const MESSAGE_CANCEL_SUCCESS = "MESSAGE_CANCEL_SUCCESS";
export const MESSAGE_CANCEL_FAIL    = "MESSAGE_CANCEL_FAIL";
export const MESSAGE_LIST_CLEAR     = "MESSAGE_LIST_CLEAR";

export const GROUP_MESSAGES_LOAD          = "GROUP_MESSAGES_LOAD";
export const GROUP_MESSAGES_LOAD_SUCCESS  = "GROUP_MESSAGES_LOAD_SUCCESS";
export const GROUP_MESSAGES_LOAD_FAIL     = "GROUP_MESSAGES_LOAD_FAIL";
export const GROUP_MESSAGES_UNLOAD        = "GROUP_MESSAGES_UNLOAD";
export const GROUP_MESSAGE_SEND           = "GROUP_MESSAGE_SEND";
export const GROUP_MESSAGE_SEND_SUCCESS   = "GROUP_MESSAGE_SEND_SUCCESS";
export const GROUP_MESSAGE_SEND_FAIL      = "GROUP_MESSAGE_SEND_FAIL";
export const GROUP_MESSAGE_SEND_CLEAR     = "GROUP_MESSAGE_SEND_CLEAR";
export const GROUP_MESSAGE_CANCEL         = "GROUP_MESSAGE_CANCEL";
export const GROUP_MESSAGE_CANCEL_SUCCESS = "GROUP_MESSAGE_CANCEL_SUCCESS";
export const GROUP_MESSAGE_CANCEL_FAIL    = "GROUP_MESSAGE_CANCEL_FAIL";
export const GROUP_MESSAGE_LIST_CLEAR     = "GROUP_MESSAGE_LIST_CLEAR";

export const CALENDARS_LOAD            = "CALENDARS_LOAD";
export const CALENDARS_LOAD_SUCCESS    = "CALENDARS_LOAD_SUCCESS";
export const CALENDARS_LOAD_FAIL       = "CALENDARS_LOAD_FAIL";
export const CALENDARS_UNLOAD          = "CALENDARS_UNLOAD";
export const CALENDAR_LIST_CLEAR       = "CALENDAR_LIST_CLEAR";
export const CALENDAR_SAVE_APPOINTMENT = "CALENDAR_SAVE_APPOINTMENT";
export const CALENDAR_SAVE_BIRTHDAY    = "CALENDAR_SAVE_BIRTHDAY";
export const CALENDAR_SAVE_REMINDER    = "CALENDAR_SAVE_REMINDER";
export const CALENDAR_SAVE_TVSHOW      = "CALENDAR_SAVE_TVSHOW";
export const CALENDAR_SAVE_SUCCESS     = "CALENDAR_SAVE_SUCCESS";
export const CALENDAR_SAVE_FAIL        = "CALENDAR_SAVE_FAIL";
export const CALENDAR_SAVE_CLEAR       = "CALENDAR_SAVE_CLEAR";
export const CALENDAR_SKIP             = "CALENDAR_SKIP";
export const CALENDAR_UNSKIP           = "CALENDAR_UNSKIP";
export const CALENDAR_SKIP_SUCCESS     = "CALENDAR_SKIP_SUCCESS";
export const CALENDAR_SKIP_FAIL        = "CALENDAR_SKIP_FAIL";
export const CALENDAR_SKIP_CLEAR       = "CALENDAR_SKIP_CLEAR";
export const CALENDAR_REMOVE           = "CALENDAR_REMOVE";
export const CALENDAR_REMOVE_SUCCESS   = "CALENDAR_REMOVE_SUCCESS";
export const CALENDAR_REMOVE_FAIL      = "CALENDAR_REMOVE_FAIL";
export const CALENDAR_REMOVE_CLEAR     = "CALENDAR_REMOVE_CLEAR";

export const GROUP_CALENDARS_LOAD            = "GROUP_CALENDARS_LOAD";
export const GROUP_CALENDARS_LOAD_SUCCESS    = "GROUP_CALENDARS_LOAD_SUCCESS";
export const GROUP_CALENDARS_LOAD_FAIL       = "GROUP_CALENDARS_LOAD_FAIL";
export const GROUP_CALENDARS_UNLOAD          = "GROUP_CALENDARS_UNLOAD";
export const GROUP_CALENDAR_LIST_CLEAR       = "GROUP_CALENDAR_LIST_CLEAR";
export const GROUP_CALENDAR_SAVE_APPOINTMENT = "GROUP_CALENDAR_SAVE_APPOINTMENT";
export const GROUP_CALENDAR_SAVE_BIRTHDAY    = "GROUP_CALENDAR_SAVE_BIRTHDAY";
export const GROUP_CALENDAR_SAVE_REMINDER    = "GROUP_CALENDAR_SAVE_REMINDER";
export const GROUP_CALENDAR_SAVE_TVSHOW      = "GROUP_CALENDAR_SAVE_TVSHOW";
export const GROUP_CALENDAR_SAVE_SUCCESS     = "GROUP_CALENDAR_SAVE_SUCCESS";
export const GROUP_CALENDAR_SAVE_FAIL        = "GROUP_CALENDAR_SAVE_FAIL";
export const GROUP_CALENDAR_SAVE_CLEAR       = "GROUP_CALENDAR_SAVE_CLEAR";
export const GROUP_CALENDAR_SKIP             = "GROUP_CALENDAR_SKIP";
export const GROUP_CALENDAR_UNSKIP           = "GROUP_CALENDAR_UNSKIP";
export const GROUP_CALENDAR_SKIP_SUCCESS     = "GROUP_CALENDAR_SKIP_SUCCESS";
export const GROUP_CALENDAR_SKIP_FAIL        = "GROUP_CALENDAR_SKIP_FAIL";
export const GROUP_CALENDAR_SKIP_CLEAR       = "GROUP_CALENDAR_SKIP_CLEAR";
export const GROUP_CALENDAR_REMOVE           = "GROUP_CALENDAR_REMOVE";
export const GROUP_CALENDAR_REMOVE_SUCCESS   = "GROUP_CALENDAR_REMOVE_SUCCESS";
export const GROUP_CALENDAR_REMOVE_FAIL      = "GROUP_CALENDAR_REMOVE_FAIL";
export const GROUP_CALENDAR_REMOVE_CLEAR     = "GROUP_CALENDAR_REMOVE_CLEAR";

export const USERS_LOAD                 = "USERS_LOAD";
export const USERS_LOAD_IF_NEEDED       = "USERS_LOAD_IF_NEEDED";
export const USERS_LOAD_SUCCESS         = "USERS_LOAD_SUCCESS";
export const USERS_LOAD_FAIL            = "USERS_LOAD_FAIL";
export const USERS_SAVE                 = "USERS_SAVE";
export const USERS_SAVE_SUCCESS         = "USERS_SAVE_SUCCESS";
export const USERS_SAVE_FAIL            = "USERS_SAVE_FAIL";
export const USERS_REMOVE               = "USERS_REMOVE";
export const USERS_REMOVE_SUCCESS       = "USERS_REMOVE_SUCCESS";
export const USERS_REMOVE_FAIL          = "USERS_REMOVE_FAIL";
export const USERS_RESET_ERROR          = "USERS_RESET_ERROR";
export const USERS_INFO_LOAD            = "USERS_INFO_LOAD";
export const USERS_INFO_LOAD_SUCCESS    = "USERS_INFO_LOAD_SUCCESS";
export const USERS_INFO_LOAD_FAIL       = "USERS_INFO_LOAD_FAIL";
export const USERS_RIGHT_ADD            = "USERS_RIGHT_ADD";
export const USERS_RIGHT_ADD_SUCCESS    = "USERS_RIGHT_ADD_SUCCESS";
export const USERS_RIGHT_ADD_FAIL       = "USERS_RIGHT_ADD_FAIL";
export const USERS_RIGHT_REMOVE         = "USERS_RIGHT_REMOVE";
export const USERS_RIGHT_REMOVE_SUCCESS = "USERS_RIGHT_REMOVE_SUCCESS";
export const USERS_RIGHT_REMOVE_FAIL    = "USERS_RIGHT_REMOVE_FAIL";
export const USERS_DUPLICATE            = "USERS_DUPLICATE";
export const USERS_DUPLICATE_SUCCESS    = "USERS_DUPLICATE_SUCCESS";
export const USERS_DUPLICATE_FAIL       = "USERS_DUPLICATE_FAIL";
export const USERS_COPY_RIGHTS          = "USERS_COPY_RIGHTS";
export const USERS_COPY_RIGHTS_SUCCESS  = "USERS_COPY_RIGHTS_SUCCESS";
export const USERS_COPY_RIGHTS_FAIL     = "USERS_COPY_RIGHTS_FAIL";
export const USERS_DISABLE_2FA          = "USERS_DISABLE_2FA";
export const USERS_DISABLE_2FA_SUCCESS  = "USERS_DISABLE_2FA_SUCCESS";
export const USERS_DISABLE_2FA_FAIL     = "USERS_DISABLE_2FA_FAIL";
export const USERS_RESET_DONE_STATES    = "USERS_RESET_DONE_STATES";
export const USERS_LOGIN_AS             = "USERS_LOGIN_AS";
export const USERS_LOGIN_AS_FAIL        = "USERS_LOGIN_AS_FAIL";

export const MACHINES_LOAD                             = "MACHINES_LOAD";
export const MACHINES_LOAD_FOR_RIGHT                   = "MACHINES_LOAD_FOR_RIGHT";
export const MACHINES_LOAD_IF_NEEDED                   = "MACHINES_LOAD_IF_NEEDED";
export const MACHINES_LOAD_SUCCESS                     = "MACHINES_LOAD_SUCCESS";
export const MACHINES_LOAD_FAIL                        = "MACHINES_LOAD_FAIL";
export const MACHINES_STATUS_LOAD                      = "MACHINES_STATUS_LOAD";
export const MACHINES_STATUS_LOAD_SUCCESS              = "MACHINES_STATUS_LOAD_SUCCESS";
export const MACHINES_STATUS_LOAD_FAIL                 = "MACHINES_STATUS_LOAD_FAIL";
export const MACHINES_INFO_LOAD                        = "MACHINES_INFO_LOAD";
export const MACHINES_INFO_LOAD_SUCCESS                = "MACHINES_INFO_LOAD_SUCCESS";
export const MACHINES_INFO_LOAD_FAIL                   = "MACHINES_INFO_LOAD_FAIL";
export const MACHINES_CANCEL_UPDATE                    = "MACHINES_CANCEL_UPDATE";
export const MACHINES_CANCEL_UPDATE_SUCCESS            = "MACHINES_CANCEL_UPDATE_SUCCESS";
export const MACHINES_CANCEL_UPDATE_FAIL               = "MACHINES_CANCEL_UPDATE_FAIL";
export const MACHINES_SWAP                             = "MACHINES_SWAP";
export const MACHINES_SWAP_SUCCESS                     = "MACHINES_SWAP_SUCCESS";
export const MACHINES_SWAP_FAIL                        = "MACHINES_SWAP_FAIL";
export const MACHINES_COUPLE                           = "MACHINES_COUPLE";
export const MACHINES_COUPLE_SUCCESS                   = "MACHINES_COUPLE_SUCCESS";
export const MACHINES_COUPLE_FAIL                      = "MACHINES_COUPLE_FAIL";
export const MACHINES_UNCOUPLE                         = "MACHINES_UNCOUPLE";
export const MACHINES_UNCOUPLE_SUCCESS                 = "MACHINES_UNCOUPLE_SUCCESS";
export const MACHINES_UNCOUPLE_FAIL                    = "MACHINES_UNCOUPLE_FAIL";
export const MACHINES_MANUAL_UPDATE                    = "MACHINES_MANUAL_UPDATE";
export const MACHINES_MANUAL_UPDATE_SUCCESS            = "MACHINES_MANUAL_UPDATE_SUCCESS";
export const MACHINES_MANUAL_UPDATE_FAIL               = "MACHINES_MANUAL_UPDATE_FAIL";
export const MACHINES_CLEAR_UPDATES                    = "MACHINES_CLEAR_UPDATES";
export const MACHINES_CLEAR_UPDATES_SUCCESS            = "MACHINES_CLEAR_UPDATES_SUCCESS";
export const MACHINES_CLEAR_UPDATES_FAIL               = "MACHINES_CLEAR_UPDATES_FAIL";
export const MACHINES_CLEAR_UPDATES_AND_RESEND         = "MACHINES_CLEAR_UPDATES_AND_RESEND";
export const MACHINES_CLEAR_UPDATES_AND_RESEND_SUCCESS = "MACHINES_CLEAR_UPDATES_AND_RESEND_SUCCESS";
export const MACHINES_CLEAR_UPDATES_AND_RESEND_FAIL    = "MACHINES_CLEAR_UPDATES_AND_RESEND_FAIL";

export const HELP_LOAD           = "HELP_LOAD";
export const HELP_LOAD_ALL       = "HELP_LOAD_ALL";
export const HELP_LOAD_IF_NEEDED = "HELP_LOAD_IF_NEEDED";
export const HELP_LOAD_SUCCESS   = "HELP_LOAD_SUCCESS";
export const HELP_LOAD_FAIL      = "HELP_LOAD_FAIL";
export const HELP_UNLOAD         = "HELP_UNLOAD";
export const HELP_SAVE           = "HELP_SAVE";
export const HELP_SAVE_SUCCESS   = "HELP_SAVE_SUCCESS";
export const HELP_SAVE_FAIL      = "HELP_SAVE_FAIL";
export const HELP_REMOVE         = "HELP_REMOVE";
export const HELP_REMOVE_SUCCESS = "HELP_REMOVE_SUCCESS";
export const HELP_REMOVE_FAIL    = "HELP_REMOVE_FAIL";
export const HELP_RESET_ERROR    = "HELP_RESET_ERROR";

export const CONTACT_SEND         = "CONTACT_SEND";
export const CONTACT_SEND_SUCCESS = "CONTACT_SEND_SUCCESS";
export const CONTACT_SEND_FAIL    = "CONTACT_SEND_FAIL";
export const CONTACT_RESET_ERROR  = "CONTACT_RESET_ERROR";

export const ERROR_PUSH     = "ERROR_PUSH";
export const SUCCESS_PUSH   = "SUCCESS_PUSH";
export const INFO_PUSH      = "INFO_PUSH";
export const MESSAGE_REMOVE = "MESSAGE_REMOVE";