import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";

import {Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container} from "reactstrap";
import {Button, Form, Spinner} from "../../index";

import i18n from "../../../i18n";
import {appRootPath} from "../../../variables/multiplatform";

import spiqleLogo from "../../../assets/img/logo-big.svg";
import * as actions from "../../../store/actions";

const FrontPageForm = props => {
    const loading = useSelector(state => state.auth.loading);
    const dispatch = useDispatch();

    const [formValues, setFormValues] = useState({});
    const [formValid, setFormValid]   = useState(false);
    const handleChange                = (formValues, formValid) => {
        setFormValues(formValues);
        setFormValid(formValid);
    };
    const onSubmit                    = () => {
        if (!formValid) {
            dispatch(actions.messages.errorPush(i18n.errorForm));
            return;
        }
        props.onSubmit(formValues);
    }

    return (
        <div>
            {loading ? (<Spinner dark/>) : null}
            <div className="full-page-content">
                <div className="login-page">
                    <Container>
                        <Col xs={12} md={8} lg={6} className="ml-auto mr-auto">
                            <Card className="card-login card-plain">
                                <CardHeader>
                                    {props.showLogo && <div className="logo-container"><img src={appRootPath + spiqleLogo} alt="now-logo"/></div>}
                                    {props.title && <CardTitle tag="h4">{props.title}</CardTitle>}
                                </CardHeader>
                                <CardBody>
                                    {props.body && <p className="icon-color-white">{props.body}</p>}
                                    <Form
                                        formData={props.formData}
                                        values={formValues}
                                        onSubmit={props.onSubmit}
                                        onChange={handleChange}
                                        stacked
                                        autoFocus
                                        handleEnter
                                    />
                                </CardBody>
                                <CardFooter className="align-horizontal-center">
                                    <Button block round color="info" size="lg" className="mb-3" onClick={onSubmit}>
                                        {props.submitButton}
                                    </Button>
                                </CardFooter>
                                {!(props.leftLink || props.leftLink === "login") && (
                                    <div className="login-bottom">
                                        <Link to="/login" className="link footer-link">{i18n.signUpBackToLogin}</Link>
                                    </div>
                                )}
                            </Card>
                        </Col>
                    </Container>
                </div>
            </div>
            <div
                className="full-page-background"
                style={{background: "linear-gradient(135deg, rgba(121,146,141,1) 0%, rgba(71,96,100,1) 100%)"}}
            />
        </div>
    );
};

FrontPageForm.propTypes = {
    loading:      PropTypes.bool,
    title:        PropTypes.string,
    body:         PropTypes.string,
    submitButton: PropTypes.string.isRequired,
    onSubmit:     PropTypes.func.isRequired,
    formData:     PropTypes.object.isRequired,
    bgImage:      PropTypes.string,
    showLogo:     PropTypes.bool,
    leftLink:     PropTypes.oneOf(["login", "register", "forgot"]),
    rightLink:    PropTypes.oneOf(["login", "register", "forgot"])
};

export default FrontPageForm;