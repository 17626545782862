import React from "react";
import i18n from "../../../i18n";
import css from "./StatusItem.module.css";

const NoMachines = props => {
    return (
        <div className={css.noItems}>
            <div className={css.text}>
                <div className={css.title}>
                    {i18n.noMachines}
                </div>
            </div>
        </div>
    );
};

export default NoMachines;
