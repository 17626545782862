import i18n from "../i18n";
import icons from "../variables/icons";

import LoginPage from "../views/Pages/LoginPage";
import RegisterPage from "../views/Pages/RegisterPage";
import ForgotPage from "../views/Pages/ForgotPage";
import ResetPasswordPage from "../views/Pages/ResetPasswordPage";
import VerifyPage from "../views/Pages/VerifyPage";
import Account from "../views/Account/Account";
import InvitationPage from "../views/Pages/InvitationPage";

const unauthenticatedRoutes = [
    {
        path:      "/register",
        name:      i18n.signUpTitle,
        short:     i18n.signUpTitleShort,
        mini:      i18n.signUpTitleMark,
        icon:      icons["add-29"],
        component: RegisterPage
    },
    {
        path:      "/login",
        name:      i18n.loginTitle,
        short:     i18n.loginTitleShort,
        mini:      i18n.loginTitleMark,
        icon:      icons["circle-10"],
        component: LoginPage
    },
    {
        path:      "/invitation/:token",
        name:      i18n.invitationTitle,
        hidden:    true,
        component: InvitationPage
    },
    {
        path:      "/resetpassword/:token",
        name:      i18n.resetPasswordTitle,
        hidden:    true,
        component: ResetPasswordPage
    },
    {
        path:      "/verifymail/:token",
        name:      i18n.verifyTitle,
        hidden:    true,
        component: VerifyPage
    },
    {
        path:      '/forgot',
        name:      i18n.forgotTitle,
        hidden:    true,
        component: ForgotPage
    },
    {
        path:      '/account',
        name:      i18n.routeAccount,
        hidden:    true,
        component: Account
    },
    {
        redirect: true,
        path:     "/",
        pathTo:   "/login",
        name:     i18n.loginTitle
    }
];

export default unauthenticatedRoutes;
