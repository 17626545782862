// React
import React, {useEffect, useState} from "react";
// Redux
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../../store/actions";
// Tools
import i18n from "../../../i18n";
// Components
import {Spinner, BottomBar, Tour, Form, ButtonCrusher, Button} from "../../../components";
import withCaretakers from "../../../hoc/withCaretakers";
import {TitleWrapper} from "../../../contexts/TitleProvider";
import css from "./CalendarDefaultSettings.module.css";
import {getPageHelpSteps} from "../../../help/tools";

const defaultsForm = {
    label1: {
        type: "label",
        label: i18n.calendarDefaultSettingsForAppointments
    },
    showAbout:   {
        className:  css.tourShowAbout,
        label:      i18n.calendarDefaultSettingsShowAbout,
        labelValue: [i18n.calendarDefaultSettingsShowAboutYes, i18n.calendarDefaultSettingsShowAboutNo],
        noLabel:    true,
        type:       "switch"
    },
    dontShowEnd: {
        className:   css.tourShowEnd,
        label:       i18n.calendarEditFormDontShowEnd,
        labelValue:  [i18n.calendarEditFormDontShowEndYes, i18n.calendarEditFormDontShowEndNo],
        noLabel:     true,
        invertValue: true,
        type:        "switch"
    },
    showBefore:  {
        className:    css.tourShowBefore,
        label:        i18n.calendarEditFormShowBefore,
        defaultValue: "1 day",
        type:         "select",
        options:      i18n.calendarRecurrences.showBefore
    },
    notifyBefore:  {
        className:    css.tourNotifyBefore,
        label:        i18n.calendarEditFormNotifyBefore,
        defaultValue: "none",
        type:         "select",
        options:      i18n.calendarRecurrences.notifyBefore
    },
    label2: {
        type: "label",
        label: i18n.calendarDefaultSettingsForReminders
    },
    notification: {
        label:        i18n.calendarEditFormNotification,
        labelValue:   [i18n.calendarEditFormNotificationYes, i18n.calendarEditFormNotificationNo],
        className:    css.tourNotification,
        noLabel:      true,
        type:         "switch",
        defaultValue: true
    },
    label3: {
        type: "label",
        label: i18n.calendarDefaultSettingsForBirthdays
    },
    showAge:    {
        label:      i18n.calendarEditBirthdayShowAge,
        labelValue: [i18n.calendarEditBirthdayShowAgeYes, i18n.calendarEditBirthdayShowAgeNo],
        className:  css.tourShowAge,
        type:       "switch",
        noLabel:    true
    },
    showPhone:  {
        label:      i18n.calendarEditBirthdayShowPhone,
        labelValue: [i18n.calendarEditBirthdayShowPhoneYes, i18n.calendarEditBirthdayShowPhoneNo],
        className:  css.tourShowPhone,
        type:       "switch",
        noLabel:    true
    },
    birthdayShowBefore: {
        label:        i18n.calendarEditBirthdayShowBefore,
        className:    css.tourShowBefore,
        defaultValue: "6 days",
        type:         "select",
        options:      i18n.calendarRecurrences.showBirthdayBefore
    }
};

const CalendarDefaultSettings = props => {
    const dispatch                    = useDispatch();
    const loading                     = useSelector(state => state.caretakers.loading);
    const settingsSaving              = useSelector(state => state.caretakers.settingsSaving);
    const [helpOpen, setHelpOpen]     = useState(false);
    const doHelp                      = () => setHelpOpen(true);
    const closeHelp                   = () => setHelpOpen(false);
    const [formValues, setFormValues] = useState(props.caretaker ? props.caretaker.settings : {});
    const [formValid, setFormValid]   = useState(false);

    const handleChange = (newFormValues, newFormValid) => {
        setFormValues(newFormValues);
        setFormValid(newFormValid);
    }
    const handleSubmit = () => {
        if (!formValid) {
            dispatch(actions.messages.errorPush(i18n.errorForm));
            return;
        }
        dispatch(actions.caretakers.saveDefaults(props.caretaker.uuid, formValues));
    }

    const lcfirst = str => str.charAt(0).toLowerCase() + str.slice(1);

    useEffect(() => {
        if (props.caretaker && props.caretaker.settings) {
            let defaultSettings = Object.keys(props.caretaker.settings)
                .filter(key => key.startsWith("default"))
                .reduce((cur, key) => Object.assign(cur, {[lcfirst(key.substring(7))]: props.caretaker.settings[key]}), {});
            setFormValues(defaultSettings);
        }
    }, [props.caretaker]);

    return (
        <TitleWrapper title={props.caretaker ? props.caretaker.name : i18n.caretaker}>
            <Tour
                isOpen={helpOpen}
                onRequestClose={closeHelp}
                steps={getPageHelpSteps("caretakerScreenSettings")}
            />
            <div>
                <div className="content-form">
                    <Form inlineSelect formData={defaultsForm} onChange={handleChange} onValid={setFormValid} values={formValues}/>
                    <ButtonCrusher width={74} topSpacer={20}>
                        <Button block upper round color="info" onClick={handleSubmit} className={css.tourSave}>{i18n.buttonSave}</Button>
                    </ButtonCrusher>
                    <BottomBar
                        backButton
                        onHelp={doHelp}
                        onCloseHelp={closeHelp}
                        helpOpen={helpOpen}
                    />
                    {(loading || settingsSaving) && <Spinner/>}
                </div>
            </div>
        </TitleWrapper>
    );
}

export default withCaretakers(CalendarDefaultSettings);
