import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import * as actions from "../../store/actions";

import {Button, Form, Spinner} from "../../components";
import {Card, CardBody, CardFooter, CardHeader, Col, Container} from "reactstrap";
import {appRootPath} from "../../variables/multiplatform";
import spiqleLogo from "../../assets/img/logo-big.svg";
import i18n from "../../i18n";
import {Link} from "react-router-dom";
import loginForm from "./Forms/loginForm";

const LoginPage = props => {
    const dispatch = useDispatch();

    const loading                     = useSelector(state => state.auth.loading);
    const [formValues, setFormValues] = useState({});
    const [formValid, setFormValid]   = useState(false);
    const formRef                     = useRef();
    const handleChange                = (formValues, formValid) => {
        setFormValues(formValues);
        setFormValid(formValid);
    };
    const doLogin                     = () => {
        if(!formValid) {
            dispatch(actions.messages.errorPush(i18n.errorForm));
            return;
        }
        dispatch(actions.auth.login(formValues.email, formValues.password));
    }
    useEffect(() => {
        if (props.presetEmail) {
            setFormValues(values => ({values, email: props.presetEmail}));
        }
    }, [props.presetEmail]);
    if (props.formRef) props.formRef(formRef);


    return (
        <div>
            {loading ? (<Spinner dark/>) : null}
            <div className="full-page-content">
                <div className="login-page">
                    <Container>
                        <Col xs={12} md={8} lg={6} className="ml-auto mr-auto">
                            <Card className="card-login card-plain">
                                <CardHeader className="text-center">
                                    <div className="logo-container">
                                        <img src={appRootPath + spiqleLogo} alt="Spiqle logo"/>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Form
                                        formData={loginForm}
                                        onSubmit={doLogin}
                                        onChange={handleChange}
                                        values={formValues}
                                        ref={ref => formRef.current = ref}
                                        autoFocus
                                        autoComplete
                                        handleEnter
                                        stacked
                                    />
                                </CardBody>
                                <CardFooter className="align-horizontal-center">
                                    <Button block color="info" size="lg" className="mb-3" round onClick={doLogin}>
                                        {i18n.loginButton}
                                    </Button>
                                </CardFooter>
                                <div className="login-bottom">
                                    <div className="call-to-action">{i18n.signUpCallToAction}</div>
                                    <Link to="/register" className="link footer-link">{i18n.loginSignUp}</Link>
                                </div>
                                {loading ? (<Spinner/>) : null}
                            </Card>
                        </Col>
                    </Container>
                </div>
            </div>
            <div
                className="full-page-background"
                style={{background: "linear-gradient(135deg, rgba(121,146,141,1) 0%, rgba(71,96,100,1) 100%)"}}
            />
        </div>
    );
    //style={{backgroundImage: "url(" + (props.bgImage ? props.bgImage : appRootPath + bgImage) + ")"}}
};

export default LoginPage;
