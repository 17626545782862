// React
import React from "react";

// Redux
import {connect} from "react-redux";
import {push} from 'connected-react-router'
import * as actions from "../../../../store/actions";

// Components
import {Row, Col} from "reactstrap";
import {BottomBar, Spinner} from "../../../../components";
import Actions from "./Actions";
import Details from "./Details";
import Rights from "./Rights";
import {TitleWrapper} from "../../../../contexts/TitleProvider";
import i18n from "../../../../i18n";

class Info extends React.Component {
    constructor(props) {
        super(props);

        let userId = props.match.params.userId;
        if (userId.length !== 32) {
            props.push("/admin/users");
            return;
        }
        props.loadIfNeeded();
        props.load(userId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.key !== prevProps.location.key) {
            this.props.load(this.props.match.params.userId);
        }
    }

    render() {
        return (
            <TitleWrapper title={i18n.usersInfoDataHead.replace("%1", this.props.userInfo ? this.props.userInfo.details.name : i18n.user)}>
                <div>
                    <div className="content">
                        {this.props.userInfo && !this.props.loading && !this.props.saving ? (
                            <>
                                <Row className="justify-content-center">
                                    <Col xl={4} lg={6} xs={12}>
                                        <Details details={this.props.userInfo.details} push={this.props.push}/>
                                    </Col>
                                    <Col xl={8} lg={6} xs={12}>
                                        <Rights
                                            userId={this.props.match.params.userId}
                                            isSuperAdmin={this.props.userInfo.details.super_admin}
                                            rights={this.props.userInfo.rights}
                                        />
                                    </Col>
                                    <Col xl={4} lg={6} xs={12}>
                                        <Actions
                                            userId={this.props.match.params.userId}
                                            userName={this.props.userInfo.details.name}
                                            twoFactorEnabled={this.props.userInfo.details.two_factor_enabled}
                                            actionRedirect={"/admin/users/info/" + this.props.match.params.userId}
                                        />
                                    </Col>
                                </Row>
                            </>
                        ) : <Spinner/>}
                        {this.props.reloading && <Spinner/>}
                        <BottomBar backButton/>
                    </div>
                </div>
            </TitleWrapper>
        );
    }
}

const mapStateToProps = state => ({
    loading:   state.users.loading,
    saving:    state.users.saving,
    reloading: state.users.reloading,
    loadedAt:  state.users.loadedAt,
    loadedFor: state.users.loadedFor,
    userInfo:  state.users.userInfo
});

const mapDispatchToProps = dispatch => ({
    push:         pathname => dispatch(push(pathname)),
    load:         userId => dispatch(actions.users.loadInfo(userId)),
    loadIfNeeded: () => dispatch(actions.users.loadIfNeeded())
});

export default connect(mapStateToProps, mapDispatchToProps)(Info);
