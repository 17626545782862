import React from 'react';
import PropTypes from 'prop-types';
import css from "./Label.module.css";

const Label = props => (
    <span className={css.label}>
        <strong>{props.settings.label}</strong>
    </span>
);

Label.propTypes = {
    name:     PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    handlers: PropTypes.object.isRequired,
    innerRef: PropTypes.object,
    stacked:  PropTypes.bool
};

export default React.memo(Label, (prevProps, nextProps) => {
    return prevProps.settings.value === nextProps.settings.value &&
        prevProps.settings.focused === nextProps.settings.focused &&
        prevProps.settings.valid === nextProps.settings.valid &&
        prevProps.disabled === nextProps.disabled
});