import React, {Component} from 'react';
import {connect} from "react-redux";

import * as actions from "../../../store/actions";
import i18n from "../../../i18n";
import tableColumns from "../../../variables/tableColumns";

import {Button, Spinner, ColumnSelector, ReactTable, BottomBar} from "../../../components";
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {TitleWrapper} from "../../../contexts/TitleProvider";

class Users extends Component {

    state = {
        modalDelete: false,
        columns:     null,
        deleteUUID:  "",
        deleteName:  ""
    };

    infoUser   = uuid => this.props.history.push("/admin/users/info/" + uuid);
    deleteUser = (uuid, name) => {
        this.setState({
            modalDelete: true,
            deleteUUID:  uuid,
            deleteName:  name
        })
    };

    tableColumns = tableColumns.userColumnsDefault([
        {
            color:  "info",
            icon:   "c-info",
            action: this.infoUser,
            text:   i18n.usersActionsInfo
        },
        {
            color:  "danger",
            icon:   "e-remove",
            action: this.deleteUser,
            text:   i18n.usersActionsDelete
        }
    ]);

    constructor(props) {
        super(props);
        this.state.columns = this.tableColumns;
        props.load();
    }

    handleDelete      = () => {
        this.props.remove(this.state.deleteUUID);
        this.setState({modalDelete: false});
    };
    toggleModalDelete = () => this.setState(state => ({modalDelete: !state.modalDelete}));

    render = () => (
        <TitleWrapper title={i18n.routeAdminUsersFull}>
            <div>
                <div className="content">
                    {this.props.users && !this.props.loading && !this.props.saving ? (
                        <>
                            <ColumnSelector cookieId="adminUsers" columns={this.state.columns} changed={columns => this.setState({columns})}/>
                            <ReactTable
                                filterable
                                data={this.props.users}
                                columns={this.state.columns}
                                defaultSorted={[{id: "name", desc: false}]}
                                noDataText={i18n.noUsers}
                            />
                            <Button round color="info" block onClick={() => this.props.history.push("/admin/users/add")}>
                                {i18n.usersAddTitle}
                            </Button>
                        </>
                    ) : <Spinner/>}
                    <BottomBar backButton/>
                </div>
                <Modal
                    isOpen={this.state.modalDelete}
                    toggle={this.toggleModalDelete}
                    className="text-center">
                    <ModalHeader
                        className="justify-content-center uppercase title"
                        tag="h4"> {i18n.usersRemoveTitle}
                    </ModalHeader>
                    <ModalBody><p>{i18n.usersRemoveBody.replace('%1', this.state.deleteName)}</p></ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.handleDelete}>{i18n.usersRemoveSure}</Button>
                        <Button color="info" onClick={this.toggleModalDelete}>{i18n.buttonCancel}</Button>
                    </ModalFooter>
                </Modal>
            </div>
        </TitleWrapper>
    );
}

const mapStateToProps    = state => ({
    loading:  state.users.loading,
    saving:   state.users.saving,
    users:    state.users.users,
    loadedAt: state.users.usersLoaded
});
const mapDispatchToProps = dispatch => ({
    load:   () => dispatch(actions.users.load()),
    remove: uuid => dispatch(actions.users.remove(uuid))
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
