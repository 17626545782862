import React from "react";
import moment from "moment-timezone";

import {Icon} from "../../components";
import {standardColumn, makeYesNoColumn, defaultColumns, accessorColumn, fillerColumn} from "./funcs";
import {isMobile} from "../tools";

import i18n from "../../i18n";
import icons from "../../variables/icons";

export const reduxColumns = {
    caretaker: getCaretakers => accessorColumn("caretaker", i18n.machinesCoupledTo, d => {
        if (!d.coupled) {
            return i18n.machinesNotCoupled;
        }
        const caretakers = getCaretakers();
        if (!caretakers) {
            return i18n.unknown;
        }
        let caretaker = caretakers.find(caretaker => caretaker.uuid === d.caretaker);
        return caretaker ? caretaker.name + " - " + caretaker.city + " - " + caretaker.country : i18n.unknown;
    }, {minWidth: 155}),

    distributor: getDistributors => accessorColumn("distributor", i18n.machinesDistributor, d => {
        if (!d.distributor) {
            return i18n.machinesNoDistributor;
        }
        const distributors = getDistributors();
        if (!distributors) {
            return i18n.unknown;
        }
        let distributor = distributors.find(distributor => distributor.uuid === d.distributor);
        return distributor ? distributor.name : i18n.unknown;
    }, {minWidth: 155, show: false}),

    affiliate: getAffiliates => accessorColumn("affiliate", i18n.machinesAffiliate, d => {
        if (!d.affiliate) {
            return i18n.machinesNoAffiliate;
        }
        const affiliates = getAffiliates();
        if (!affiliates) {
            return i18n.unknown;
        }
        let affiliate = affiliates.find(affiliate => affiliate.uuid === d.affiliate);
        return affiliate ? affiliate.name : i18n.unknown;
    }, {minWidth: 155, show: false}),
};

export const machineColumns = {
    serial_no:         standardColumn("serial_no", i18n.machinesSerialNumber, {width: 105, togglable: false}),
    serial_check:      standardColumn("serial_check", i18n.machinesSerialCheck, {width: 100, show: false}),
    mac_address:       standardColumn("mac_address", i18n.machinesMacAddress, {width: 140}),
    coupled:           makeYesNoColumn("coupled", i18n.machinesCoupled, icons["single-05"]),
    os:                standardColumn("os", i18n.machinesOS, {show: false}),
    last_update:       accessorColumn(
        "last_update",
        i18n.machinesLastUpdate,
        d => moment.utc(d["last_update"] * 1000).format("YYYY-MM-DD HH:mm:ss"),
        {width: 175}
    ),
    last_boot:         accessorColumn(
        "last_boot",
        i18n.machinesLastBoot,
        d => moment.utc(d["last_boot"] * 1000).format("YYYY-MM-DD HH:mm:ss"),
        {width: 175, show: false}
    ),
    created_at:        accessorColumn(
        "created_at",
        i18n.machinesCreatedAt,
        d => moment.utc(d["created_at"] * 1000).format("YYYY-MM-DD HH:mm:ss"),
        {width: 175, show: false}
    ),
    network:           {
        id:           "network",
        Header:       i18n.machinesNetwork,
        Cell:         props => {
            let network;
            try {
                network = JSON.parse(props.value);
            } catch (e) {
                return <span>Unknown</span>;
            }

            if (!network || !network.type) {
                return <span>Unknown</span>;
            }

            switch (network.type) {
                case "Ethernet":
                    return (
                        <span>
                            <Icon name="audio-jack" title={i18n.machinesNetworkFilterEthernet}/>
                            {" " + i18n.machinesNetworkFilterEthernet + " - " + network.ip}
                        </span>
                    );

                case "WiFi":
                    return (
                        <span>
                            <Icon name="smart-house" title={i18n.machinesNetworkFilterWiFi}/>
                            {" " + i18n.machinesNetworkFilterWiFi + " - " + network.ip}
                        </span>
                    );

                case "Mobile":
                    let signalPercent;
                    if (!network.signal) {
                        return <span>Unknown</span>;
                    }
                    switch (network.signal.mode) {
                        case "LTE":
                            signalPercent = Math.round(((+network.signal.RSRQ + 20) / 12) * 100);
                            break;

                        case "GSM":
                            signalPercent = Math.round(((+network.signal.rxLevel + 100) / 35) * 100);
                            break;

                        default:
                            signalPercent = 0;

                    }
                    if (signalPercent > 100) {
                        signalPercent = 100;
                    }
                    if (signalPercent < 0) {
                        signalPercent = 0;
                    }

                    return (
                        <span>
                            <Icon name="signal" title={i18n.machinesNetworkFilterMobile}/>
                            {" " + network.signal.mode + " - " + signalPercent + "%"}
                        </span>
                    );

                default:
                    return <span>Unknown</span>;
            }
        },
        accessor:     "network",
        width:        220,
        filterMethod: (filter, row) => {
            if (filter.value === "All") {
                return true;
            }
            try {
                let network = JSON.parse(row.network);
                switch (filter.value) {
                    case "Ethernet":
                    case "WiFi":
                    case "Mobile":
                        return network.type === filter.value;

                    case "Mobile_LTE":
                    case "Mobile_GSM":
                        if (network.type !== "Mobile" || !network.signal) {
                            return false;
                        }
                        return network.signal.mode === filter.value.substr(7);

                    default:
                        return false;
                }
            } catch (e) {
                return false;
            }
        },
        Filter:       ({filter, onChange}) => (
            <select onChange={event => onChange(event.target.value)} style={{width: "100%"}} value={filter ? filter.value : "All"}>
                <option value="All">{i18n.machinesNetworkFilterAll}</option>
                <option value="Ethernet">{i18n.machinesNetworkFilterEthernet}</option>
                <option value="WiFi">{i18n.machinesNetworkFilterWiFi}</option>
                <option value="Mobile">{i18n.machinesNetworkFilterMobile}</option>
                <option value="Mobile_LTE">{i18n.machinesNetworkFilterMobileLTE}</option>
                <option value="Mobile_GSM">{i18n.machinesNetworkFilterMobileGSM}</option>
            </select>
        ),
        filterable:   true,
        togglable:    true,
        show:         !isMobile()
    },
    imsi:              accessorColumn("imsi", i18n.machinesIMSI, d => {
        try {
            let network = JSON.parse(d.network);
            if (network.imsi) {
                return network.imsi;
            }
        } catch (e) {
        }
        return i18n.unknown;
    }, {width: 160, show: false}),
    imei:              accessorColumn("imei", i18n.machinesIMEI, d => {
        try {
            let network = JSON.parse(d.network);
            if (network.imei) {
                return network.imei;
            }
        } catch (e) {
        }
        return i18n.unknown;
    }, {width: 160, show: false}),
    iccid:             accessorColumn("iccid", "ICCID", d => {
        try {
            let network = JSON.parse(d.network);
            if (network.iccid) {
                return network.iccid;
            }
        } catch (e) {
        }
        return i18n.unknown;
    }, {width: 160, show: false}),
    last_ip:           standardColumn("last_ip", i18n.machinesLastIP, {width: 160, show: !isMobile()}),
    temperature:       accessorColumn("temperature", i18n.machinesTemperature, d => d.temperature + "°C", {width: 100, show: false}),
    caretaker_name:    accessorColumn("caretaker_name", i18n.caretakersTableName, d => d.coupled ? d.caretaker_name : i18n.machinesUncoupled, {
        width:         150,
        selectorTitle: i18n.caretakersFullTableName,
        show:          false
    }),
    caretaker_address: standardColumn("caretaker_address", i18n.caretakersTableAddress, {
        selectorTitle: i18n.caretakersFullTableAddress,
        width:         150,
        show:          false
    }),
    caretaker_zipcode: standardColumn("caretaker_zipcode", i18n.caretakersTableZipCode, {
        selectorTitle: i18n.caretakersFullTableZipCode,
        width:         100,
        show:          false
    }),
    caretaker_city:    standardColumn("caretaker_city", i18n.caretakersTableCity, {
        selectorTitle: i18n.caretakersFullTableCity,
        width:         150,
        show:          false
    }),
    caretaker_state:   standardColumn("caretaker_state", i18n.caretakersTableState, {
        selectorTitle: i18n.caretakersFullTableState,
        width:         120,
        show:          false
    }),
    caretaker_country: standardColumn("caretaker_country", i18n.caretakersTableCountry, {
        selectorTitle: i18n.caretakersFullTableCountry,
        width:         100,
        show:          false
    })

};

export const machineColumnsDefaultRedux = (caretakerState, distributorState, affiliateState, actions = null, selector = null) => defaultColumns(
    [
        machineColumns.serial_no,
        machineColumns.serial_check,
        machineColumns.mac_address,
        machineColumns.coupled,
        reduxColumns.caretaker(caretakerState),
        reduxColumns.distributor(distributorState),
        reduxColumns.affiliate(affiliateState),
        machineColumns.os,
        machineColumns.last_update,
        machineColumns.last_boot,
        machineColumns.created_at,
        machineColumns.network,
        machineColumns.imsi,
        machineColumns.imei,
        machineColumns.iccid,
        machineColumns.last_ip,
        machineColumns.temperature
    ], actions, "mac_address", selector
);

export const machineColumnsDefaultCaretaker = (actions = null, selector = null) => defaultColumns(
    [
        machineColumns.coupled,
        machineColumns.serial_no,
        machineColumns.serial_check,
        machineColumns.mac_address,
        machineColumns.caretaker_name,
        machineColumns.caretaker_address,
        machineColumns.caretaker_zipcode,
        machineColumns.caretaker_city,
        machineColumns.caretaker_state,
        machineColumns.caretaker_country,
        machineColumns.os,
        machineColumns.last_update,
        machineColumns.last_boot,
        machineColumns.created_at,
        machineColumns.network,
        machineColumns.last_ip,
        fillerColumn
    ], actions, "uuid", selector
);