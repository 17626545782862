export default {
    appTitle: "Spiqle Agenda",

    loading:     "Indlæser...",
    loadedAt:    "Sidst opdateret kl ",
    pageText:    "Side",
    ofText:      "af",
    rowsText:    "linjer",
    showColumns: "Vælg kolonner",

    image:     "Billede",
    all:       "Alle",
    yes:       "Ja",
    no:        "Nej",
    unknown:   "Ukendt",
    noOptions: "Ingen valgmuligheder tilgængelige",

    successTitle: "Succes",
    errorTitle:   "Fejl",

    buttonEdit:     "Rediger",
    buttonSave:     "Gem",
    buttonSend:     "Send",
    buttonNext:     "Næste",
    buttonPrevious: "Forrige",
    buttonBack:     "Tilbage",
    buttonFinish:   "Fuldfør",
    buttonCancel:   "Annuller",
    buttonInvite:   "Inviter",
    buttonAdd:      "Tilføj",

    searchPlaceholder: "Søg...",

    contactTitle:       "Kontakt",
    contactSubtitle:    "Har du et spørgsmål eller forslag? Kontakt os gennem denne formular.",
    contactFormTitle:   "Titel",
    contactFormBody:    "Spørgsmål eller forslag",
    successContactSend: "Kontaktformularen er sendt succesfuldt",
    errorContactSend:   "Der opstod en fejl under afsendelse af kontaktformularen",

    validationErrors: {
        required:  "Dette felt er obligatorisk",
        minLength: "Minimumslængden for dette felt er %1",
        maxLength: "Maksimumslængden for dette felt er %1",
        isEmail:   "Dette er ikke en gyldig e-mailadresse",
        regex:     "Dette er ikke en gyldig værdi for dette felt",
        func:      "Dette er ikke en gyldig værdi for dette felt"
    },

    errorForm: "Ikke alle felter er udfyldte",

    errors: {
        CONNECTION:              "Forbindelsesproblem, kontroller din internetforbindelse",
        DEFAULT:                 "Generisk fejl, prøv igen senere",
        CALL_DISABLED:           "Denne funktion er i øjeblikket deaktiveret",
        LOCAL_ONLY:              "",
        INVALID_REQUEST:         "Fejl ved hentning eller gemning af data. Bruger du den nyeste version af appen?",
        INVALID_EMAIL:           "Fejl ved hentning eller gemning af data, e-mailadressen er ugyldig",
        INVALID_GUID:            "Fejl ved hentning eller gemning af data, GUID er ugyldig",
        INVALID_MAC:             "Fejl ved hentning eller gemning af data, MAC-adressen er ugyldig",
        INVALID_FILE:            "Fejl ved hentning eller gemning af data, den valgte fil er ugyldig",
        INVALID_TOKEN:           "Fejl ved hentning eller gemning af data, ugyldig login token",
        INVALID_LIST:            "Fejl ved hentning eller gemning af data, listen er ugyldig",
        INVALID_INTEGER:         "Fejl ved hentning eller gemning af data, nummeret er ugyldigt",
        INVALID_MONGOID:         "Fejl ved hentning eller gemning af data, database-id er ugyldigt",
        INVALID_FLOAT:           "Fejl ved hentning eller gemning af data, nummeret er ugyldigt",
        INVALID_ARRAY:           "Fejl ved hentning eller gemning af data, listen er ugyldig",
        INVALID_JSON:            "Fejl ved hentning eller gemning af data, en parameter er ugyldig",
        NO_AUTH:                 "Fejl ved hentning eller gemning af data, du er ikke logget ind",
        UNKNOWN_CREDENTIALS:     "Ukendt e-mail eller adgangskode",
        SIGNUP_ERROR_CONNECTION: "Oprettelse af en konto mislykkedes. Kontroller din internetforbindelse og prøv igen.",
        SIGNUP_ERROR_EXISTS:     "Oprettelse af en konto mislykkedes. Der findes allerede en konto med denne e-mailadresse.",
        addNewDisplay:           {
            INVALID_DISPLAY:         "Fejl under skærmkobling, ukendt skærmkode, skriv denne nøjagtigt som den vises på skærmen",
            DISPLAY_ALREADY_COUPLED: "Fejl under skærmkobling, denne skærm er allerede koblet",
            INVALID_CARETAKER:       "Fejl under skærmkobling, denne plejepatient er ukendt",
            INVALID_REQUEST:         "Fejl under skærmkobling, udfyld alle oplysninger"
        }
    },

    countries: {
        Andorra:          "Andorra",
        "België":         "Belgien",
        "Bŭlgarija":      "Bulgarien",
        "Kýpros":         "Cypern",
        Danmark:          "Danmark",
        Deutschland:      "Tyskland",
        Eesti:            "Estland",
        Suomi:            "Finland",
        France:           "Frankrig",
        "Elláda":         "Grækenland",
        "Magyarország":   "Ungarn",
        Hrvatska:         "Kroatien",
        Ireland:          "Irland",
        Italia:           "Italien",
        Latvija:          "Letland",
        Lietuva:          "Litauen",
        Luxembourg:       "Luxembourg",
        Malta:            "Malta",
        Nederland:        "Nederland",
        Norge:            "Norge",
        "Österreich":     "Østrig",
        Polska:           "Polen",
        Portugal:         "Portugal",
        "România":        "Rumænien",
        Slovenija:        "Slovenien",
        Slovensko:        "Slovakiet",
        "España":         "Spanien",
        "Česko":          "Tjekkiet",
        "United Kingdom": "Storbritannien",
        Sverige:          "Sverige",
        Schweiz:          "Schweiz"
    },

    dateFormat: "dd D MMM HH:mm",
    dateTime:   {
        months: {
            long:  [
                "januar",
                "februar",
                "marts",
                "april",
                "maj",
                "juni",
                "juli",
                "august",
                "september",
                "oktober",
                "november",
                "december"
            ],
            short: [
                "jan",
                "feb",
                "mar",
                "apr",
                "maj",
                "jun",
                "jul",
                "aug",
                "sep",
                "okt",
                "nov",
                "dec"
            ]
        },
        days:   {
            long:  [
                "søndag",
                "mandag",
                "tirsdag",
                "onsdag",
                "torsdag",
                "fredag",
                "lørdag"
            ],
            short: [
                "sø",
                "ma",
                "ti",
                "on",
                "to",
                "fr",
                "lø"
            ],
            count: [
                "",
                "1ste",
                "2nd",
                "3rd",
                "4th",
                "5th",
                "6th",
                "7th",
                "8th",
                "9th",
                "10th",
                "11th",
                "12th",
                "13th",
                "14th",
                "15th",
                "16th",
                "17th",
                "18th",
                "19th",
                "20th",
                "21st",
                "22nd",
                "23rd",
                "24th",
                "25th",
                "26th",
                "27th",
                "28th",
                "29th",
                "30th",
                "31st"
            ]
        }
    }
};