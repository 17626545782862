import {call, put, select, takeLatest} from "redux-saga/effects";
import {push} from "connected-react-router";
import * as actions from "../actions";
import * as actionTypes from "../actionTypes";
import * as api from "../../api";
import i18n from "../../i18n";

// Watcher
export function* watchHelp() {
    yield takeLatest(actionTypes.HELP_LOAD, loadSaga);
    yield takeLatest(actionTypes.HELP_LOAD_ALL, loadAllSaga);
    yield takeLatest(actionTypes.HELP_LOAD_IF_NEEDED, loadIfNeededSaga);
    yield takeLatest(actionTypes.HELP_SAVE, saveSaga);
    yield takeLatest(actionTypes.HELP_REMOVE, removeSaga);
}

// Sagas
function* loadSaga(action) {
    try {
        const response = yield call(api.help.load, action.language);
        yield put(actions.help.loadSuccess(response.data.items));
    }
    catch (err) {
        yield put(actions.help.loadFail());
    }
}

function* loadAllSaga(action) {
    try {
        const response = yield call(api.help.load, "all");
        yield put(actions.help.loadSuccess(response.data.items));
    }
    catch (err) {
        yield put(actions.help.loadFail());
    }
}

function* loadIfNeededSaga(action) {
    let help = yield select(state => state.help);
    if(!help.loading && !help.itemsLoaded) {
        yield put(actions.help.load());
    }
}

function* saveSaga(action) {
    try {
        yield call(api.help.save, action.uuid, action.itemDetails);
        yield put(actions.help.saveSuccess());
        yield put(push("/help"));
        yield put(actions.messages.successPush(i18n.helpEditSuccess));
    }
    catch (err) {
        yield put(actions.help.saveFail());
        if (err.response && err.response.data && err.response.data.error && err.response.data.error === "HELP_PAGE_BINDING_EXISTS") {
            yield put(actions.messages.errorPush(i18n.helpEditErrorPageBinding));
        } else {
            yield put(actions.messages.errorPush(i18n.helpEditError));
        }
    }
}

function* removeSaga(action) {
    try {
        yield call(api.help.remove, action.uuid);
        yield put(actions.help.removeSuccess());
        yield put(actions.help.load(action.language));
        yield put(push("/help"));
        yield put(actions.messages.successPush(i18n.helpRemoveSuccess));
    }
    catch (err) {
        yield put(actions.help.removeFail());
        yield put(actions.messages.errorPush(i18n.helpRemoveError));
    }
}