import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {BottomBar, Button, Form, Spinner, Tour} from "../../../components";
import * as actions from "../../../store/actions";
import i18n from "../../../i18n";
import {formHandleValueSetters} from "../../../variables/tools";
import css from "./Password.module.css";
import {TitleWrapper} from "../../../contexts/TitleProvider";
import {getPageHelpSteps} from "../../../help/tools";

const changePasswordForm = {
    oldPassword:    {
        label:         i18n.changePasswordOldPassword,
        className:     css.tourCurrentPassword,
        elementConfig: {type: "password"},
        validation:    {required: true, minLength: 7, func: value => (/[A-Z]/.test(value) && /[a-z]/.test(value) && /[0-9]/.test(value))}
    },
    password:       {
        label:                   i18n.changePasswordNewPassword,
        className:               css.tourNewPassword,
        elementConfig:           {type: "password"},
        validation:              {required: true, minLength: 7, func: value => (/[A-Z]/.test(value) && /[a-z]/.test(value) && /[0-9]/.test(value))},
        validationErrors:        {minLength: i18n.signUpPasswordTooShort, func: i18n.signUpPasswordMinimumRequirements},
        fullRevalidation:        true,
        showValidationErrors:    true,
        validationErrorLocation: "auto"
    },
    repeatPassword: {
        label:                   i18n.changePasswordRepeatPassword,
        className:               css.tourRepeatPassword,
        elementConfig:           {type: "password"},
        validation:              {required: true, func: (value, form) => (form && form.password.value === value)},
        validationErrors:        {func: i18n.signUpPasswordDontMatch},
        fullRevalidation:        true,
        showValidationErrors:    true,
        validationErrorLocation: "auto"
    }
};

const Password = props => {
    const dispatch = useDispatch();

    const [helpOpen, setHelpOpen] = useState(false);
    const doHelp                  = () => setHelpOpen(true);
    const closeHelp               = () => setHelpOpen(false);

    const [formValues, setFormValues] = useState({});
    const [formValid, setFormValid]   = useState(false);
    const handleSubmit                = () => dispatch(actions.auth.changePassword(formValues.oldPassword, formValues.password));
    const loading                     = useSelector(state => state.auth.loading);

    return (
        <TitleWrapper title={i18n.changePasswordTitle}>
            <Tour
                isOpen={helpOpen}
                onRequestClose={closeHelp}
                steps={getPageHelpSteps("accountPassword")}
            />
            <div>
                <div className="content">
                    <Form
                        formData={changePasswordForm}
                        values={formValues}
                        onChange={formHandleValueSetters(setFormValues, setFormValid)}
                        onValid={valid => setFormValid(valid)}
                        stacked
                    />
                    <Button
                        block
                        round
                        color="info"
                        disabled={!formValid}
                        onClick={handleSubmit}>{i18n.changePasswordAction}</Button>
                    <BottomBar backButton onHelp={doHelp} onCloseHelp={closeHelp} helpOpen={helpOpen}/>
                </div>
                {loading && <Spinner/>}
            </div>
        </TitleWrapper>
    );
};

export default Password;