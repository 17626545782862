import React from "react";
import PropTypes from 'prop-types';

import {CollapseCard} from "../../components";
import HelpItemContents from "./HelpItemContents";

const HelpItem = props => (
    <CollapseCard title={props.title}>
        <HelpItemContents {...props} />
    </CollapseCard>
);


HelpItem.propTypes = {
    uuid: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    image: PropTypes.bool,
    edit: PropTypes.bool
};

export default HelpItem;
