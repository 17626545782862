import React from 'react';
import classes from "./Spinner.module.css";

const Spinner = props => {
    return (
        <div className={classes.SpinnerContainer + " " + (props.dark ? classes.Dark : classes.Light)}>
            <div className={classes.Spinner}>
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    );
};

export default Spinner;