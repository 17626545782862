// React
import React, {useState} from 'react';

// Tools
import i18n from '../../../i18n';

// Components
import {Spinner, BottomBar, Tour} from '../../../components';
import NoMachines from "./NoMachines";
import {TitleWrapper} from "../../../contexts/TitleProvider";
import {getPageHelpSteps} from "../../../help/tools";
import withCaretakers from "../../../hoc/withCaretakers";
import StatusItem from "./StatusItem";
import css from "./StatusItem.module.css";

const SettingsButton = props => {
    return (
        <div className={[css.button, props.className].join(" ")} onClick={props.onClick}><span>{props.text}</span></div>
    );
}

const Status = props => {
    const [helpOpen, setHelpOpen] = useState(false);
    const doHelp                  = () => setHelpOpen(true);
    const closeHelp               = () => setHelpOpen(false);

    if (!props.caretaker || props.loading) {
        return <Spinner/>;
    }
    return (
        <TitleWrapper title={i18n.caretakerScreenStatusTitle.replace("%1", props.caretaker ? props.caretaker.name : i18n.caretaker)}>
            <Tour
                isOpen={helpOpen}
                onRequestClose={closeHelp}
                steps={getPageHelpSteps("caretakerStatus")}
            />
            <div>
                <div className="content-list">
                    <SettingsButton
                        className={css.tourSettings}
                        text={i18n.caretakerScreenSettings}
                        onClick={() => props.history.push('/caretakers/screensettings/' + props.caretakerUuid)}
                    />
                    {props.caretaker.machines.length === 0 && <NoMachines/>}
                    {props.caretaker.machines.map(machine => <StatusItem
                        key={machine.serial_no}
                        avatar={props.caretaker.avatar}
                        uuid={props.caretakerUuid}
                        onClick={() => props.history.push('/caretakers/screenstatus/' + props.caretakerUuid + '/' + machine.serial_no)}
                        {...machine} />)}
                    <BottomBar
                        backButton
                        backUrl={"/caretakers/settings/" + props.caretakerUuid}
                        helpOpen={helpOpen} onHelp={doHelp} onCloseHelp={closeHelp}
                        links={[{title: i18n.caretakersAddMachine, link: "/caretakers/addmachine/" + props.caretakerUuid}]}
                    />
                </div>
            </div>
        </TitleWrapper>
    );
};

export default withCaretakers(React.memo(Status));
