import React, {useState} from 'react';
import {BottomBar, Button, Spinner, ButtonCrusher, Tour} from "../../../components";
import i18n from "../../../i18n";
import withCaretakers from "../../../hoc/withCaretakers";
import {apiGetBasePlusPath, apiGetToken} from "../../../api";

import css from "./Preview.module.css";
import {TitleWrapper} from "../../../contexts/TitleProvider";
import {getPageHelpSteps} from "../../../help/tools";

const Preview = props => {
    const [helpOpen, setHelpOpen] = useState(false);
    const doHelp                  = () => setHelpOpen(true);
    const closeHelp               = () => setHelpOpen(false);

    return (
        <TitleWrapper title={i18n.caretakersItemPreviewFor.replace("%1", props.caretaker ? props.caretaker.name : i18n.caretaker)}>
            <Tour
                isOpen={helpOpen}
                onRequestClose={closeHelp}
                steps={getPageHelpSteps("caretakerPreview")}
            />
            <div>
                <div className="content">
                    {
                        props.caretaker ? (
                            <div className={css.previewContainer}>
                                <iframe
                                    className={css.previewIframe}
                                    title={i18n.caretakersItemPreview}
                                    src={apiGetBasePlusPath("/preview/index.php?uuid=" + props.caretaker.uuid + "&token=" + apiGetToken())}
                                />
                            </div>
                        ) : <Spinner/>
                    }
                    <ButtonCrusher width={77} topSpacer={25}>
                        <Button
                            round
                            upper
                            block
                            marginTop
                            className={css.tourSentMessages}
                            color="info"
                            onClick={() => props.history.push("/caretakers/message/list/" + props.caretakerUuid)}>
                            {i18n.caretakersSentMessages}
                        </Button>
                    </ButtonCrusher>
                    <BottomBar backButton onHelp={doHelp} onCloseHelp={closeHelp} helpOpen={helpOpen}/>
                </div>
            </div>
        </TitleWrapper>
    );
};

export default withCaretakers(Preview);