import React from 'react';
import PropTypes from 'prop-types';
import {Table} from "reactstrap";
import {Icon, CollapseCard} from "../../../../components";
import i18n from "../../../../i18n";
import css from "./Info.module.css";
import DetailItem from "./DetailItem";

const showMobileDetails = {
    mode:          "%1",
    operation:     "%1",
    network:       "%1",
    LAC:           "%1",
    cellId:        "%1",
    sCellId:       "%1",
    pCellId:       "%1",
    rfChannel:     "%1",
    rxLevel:       "%1",
    earFcn:        "%1",
    trackLoAdjust: "%1",
    C1C2:          "%1",
    dlBandwidth:   "%1",
    ulBandwidth:   "%1",
    RSRQ:          "%1 dB",
    RSRP:          "%1 dBm",
    RSSI:          "%1 dBm",
    RSSNR:         "%1 dB"
};

const NetworkInfoTable = ({networkString}) => {
    try {
        let networkObj = JSON.parse(networkString);
        switch (networkObj.type) {
            case "Ethernet":
                return (
                    <h3 className="text-center">
                        <Icon name="audio-jack" title={i18n.machinesNetworkFilterEthernet}/>
                        {" " + i18n.machinesNetworkFilterEthernet + " - " + networkObj.ip}
                    </h3>
                );

            case "WiFi":
                return (
                    <h3 className="text-center">
                        <Icon name="smart-house" title={i18n.machinesNetworkFilterWiFi}/>
                        {" " + i18n.machinesNetworkFilterWiFi + " - " + networkObj.ip}
                    </h3>
                );

            case "Mobile":
                let signalPercent;
                if (!networkObj.signal) {
                    return (
                        <h3 className="text-center">
                            <Icon name="signal" title={i18n.machinesNetworkFilterMobile}/>
                            {" - Unknown signal"}
                        </h3>
                    );
                }
                switch (networkObj.signal.mode) {
                    case "LTE":
                        signalPercent = Math.round(((+networkObj.signal.RSRQ + 20) / 12) * 100);
                        break;

                    case "GSM":
                        signalPercent = Math.round(((+networkObj.signal.rxLevel + 100) / 35) * 100);
                        break;

                    default:
                        signalPercent = 0;

                }
                if (signalPercent > 100) {signalPercent = 100;}
                if (signalPercent < 0) {signalPercent = 0;}

                return (
                    <>
                        <h3 className="text-center">
                            <Icon name="signal" title={i18n.machinesNetworkFilterMobile}/>
                            {" " + networkObj.signal.mode + " - " + signalPercent + "%"}
                        </h3>
                        <Table>
                            <tbody className={css.tableItems}>
                            <DetailItem name={i18n.machinesIMSI}>{networkObj.imsi}</DetailItem>
                            <DetailItem name={i18n.machinesIMEI}>{networkObj.imei}</DetailItem>
                            <DetailItem name="ICCID">{networkObj.iccid ? networkObj.iccid : i18n.unknown}</DetailItem>
                            <DetailItem name={i18n.machinesIP}>{networkObj.ip}</DetailItem>
                            {
                                Object.keys(networkObj.signal).map(key => {
                                    if (!showMobileDetails.hasOwnProperty(key)) {
                                        return null;
                                    }
                                    return <DetailItem key={key} name={key}>{showMobileDetails[key].replace("%1", networkObj.signal[key])}</DetailItem>;
                                })
                            }
                            </tbody>
                        </Table>
                    </>
                );

            default:
                return <>Unknown</>;
        }
    }
    catch (e) {
        return <>Unknown</>;
    }
};

const networkInfo = ({network}) => (
    <CollapseCard title={i18n.machinesNetwork} defaultOpenDesktop>
        <NetworkInfoTable networkString={network}/>
    </CollapseCard>
);

networkInfo.propTypes = {
    network: PropTypes.string.isRequired
};

export default networkInfo;