import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Card, CardBody, CardHeader, Collapse} from "reactstrap";
import {isMobile} from "../../../variables/tools";
import {CardActions} from "../../index";

const CollapseCard = props => {
    const [isOpen, setIsOpen] = useState(isMobile() ? props.defaultOpenMobile : props.defaultOpenDesktop);
    return (
        <Card>
            <CardHeader>
                <h5 className="title expander" onClick={() => setIsOpen(!isOpen)}>
                    {props.title}
                    <b className={"caret " + (isOpen ? "expanded" : null)}/>
                </h5>
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <CardBody className={props.allowHorizontalScroll ? "card-horizontal-scroll" : ""}>
                    {props.children}
                </CardBody>
            </Collapse>
            {props.actions && <CardActions>{props.actions}</CardActions>}
        </Card>
    );
};

CollapseCard.propTypes = {
    defaultOpenDesktop:    PropTypes.bool,
    defaultOpenMobile:     PropTypes.bool,
    allowHorizontalScroll: PropTypes.bool,
    showBack:              PropTypes.bool,
    actions:               PropTypes.array
};

export default CollapseCard;