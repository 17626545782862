// React
import React from "react";

// Redux
import {connect} from "react-redux";
import {push} from 'connected-react-router'
import * as actions from "../../../../store/actions";

// Components
import {Row, Col} from "reactstrap";
import {BottomBar,  Spinner} from "../../../../components";
import Actions from "./Actions";
import Details from "./Details";
import NetworkInfo from "./NetworkInfo";
import Updates from "./Updates";
import CoupleInfo from "./CoupleInfo";
import {TitleWrapper} from "../../../../contexts/TitleProvider";
import i18n from "../../../../i18n";

class Info extends React.Component {
    constructor(props) {
        super(props);

        let macAddress = props.match.params.macAddress;
        if (macAddress.length !== 12) {
            props.push("/admin/machines");
            return;
        }
        props.load(macAddress);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.key !== prevProps.location.key) {
            this.props.load(this.props.match.params.macAddress);
        }
    }

    render() {
        return (
            <TitleWrapper title={i18n.machineInfoDataHead.replace("%1", this.props.match.params.macAddress)}>
                <div>
                    <div className="content">
                        {this.props.machineInfo && !this.props.loading ? (
                            <>
                                <Row className="justify-content-center">
                                    <Col xl={4} lg={6} xs={12}>
                                        <Details details={this.props.machineInfo.details}/>
                                    </Col>
                                    <Col xl={4} lg={6} xs={12}>
                                        <NetworkInfo network={this.props.machineInfo.details.network}/>
                                    </Col>
                                    <Col xl={4} lg={6} xs={12}>
                                        <CoupleInfo details={this.props.machineInfo.details}/>
                                    </Col>
                                    <Col xl={4} lg={6} xs={12}>
                                        <Actions
                                            mac={this.props.machineInfo.details["mac_address"]}
                                            actionRedirect={"/admin/machines/info/" + this.props.match.params.macAddress}
                                        />
                                    </Col>
                                    <Col xl={8} lg={12} xs={12}>
                                        <Updates updates={this.props.machineInfo.updates}/>
                                    </Col>
                                </Row>
                            </>
                        ) : <Spinner/>}
                        {this.props.reloading && <Spinner/>}
                        <BottomBar backButton/>
                    </div>
                </div>
            </TitleWrapper>
        );
    }
}

const mapStateToProps = state => ({
    loading:     state.machines.loading,
    reloading:   state.machines.reloading,
    loadedAt:    state.machines.loadedAt,
    loadedFor:   state.machines.loadedFor,
    machineInfo: state.machines.machineInfo
});

const mapDispatchToProps = dispatch => ({
    push: pathname => dispatch(push(pathname)),
    load: macAddress => dispatch(actions.machines.loadInfo(macAddress))
});

export default connect(mapStateToProps, mapDispatchToProps)(Info);
