import * as actionTypes from "../actionTypes";
import moment from 'moment-timezone';
import {runActionMap, load as loadStatus, loadFail} from "../reducerFunctions";

const initialState = {
    loading:          false,
    reloading:        false,
    swapping:         false,
    uncoupling:       false,
    updating:         false,
    cancellingUpdate: false,
    loadedAt:         null,
    loadedFor:        null,
    machineInfo:      null,
    error:            null,

    loadedForRight:      null,
    loadedForRightLevel: null,
    machines:            null,
    machinesLoaded:      null,

    machinesStatus:       null,
    machinesStatusLoaded: null
};

// Load reducers
const load              = (state, action) => ({...state, loading: true, loadedForRight: null, loadedForRightLevel: null});
const loadForRight      = (state, action) => ({...state, loading: true, loadedForRight: action.right, loadedForRightLevel: action.rightLevel});
const loadSuccess       = (state, action) => ({...state, loading: false, error: null, machines: action.machines, machinesLoaded: moment()});
const loadStatusSuccess = (state, action) => ({...state, loading: false, error: null, machinesStatus: action.machineStatus, machinesStatusLoaded: moment()});

// Load reducers
const loadInfo        = (state, action) => {
    if (state.loadedFor === action.macAddress) {
        return {...state, reloading: true};
    }
    else {
        return {...state, loading: true};
    }
};
const loadInfoSuccess = (state, action) => ({
    ...state,
    loading:     false,
    reloading:   false,
    updating:    false,
    error:       null,
    machineInfo: action.machineInfo,
    loadedFor:   action.macAddress,
    loadedAt:    moment()

});
const loadInfoFail    = (state, action) => ({...state, loading: false, reloading: false, error: action.error});

const cancelUpdate        = (state, action) => ({...state, cancellingUpdate: true});
const cancelUpdateSuccess = (state, action) => ({...state, cancellingUpdate: false});
const cancelUpdateFail    = (state, action) => ({...state, cancellingUpdate: false});

const swap        = (state, action) => ({...state, swapping: true});
const swapSuccess = (state, action) => ({...state, swapping: false});
const swapFail    = (state, action) => ({...state, swapping: false});

const uncouple        = (state, action) => ({...state, uncoupling: true});
const uncoupleSuccess = (state, action) => ({...state, uncoupling: false});
const uncoupleFail    = (state, action) => ({...state, uncoupling: false});

const clearUpdatesAndResend        = (state, action) => ({...state, cancellingUpdate: true});
const clearUpdatesAndResendSuccess = (state, action) => ({...state, cancellingUpdate: false});
const clearUpdatesAndResendFail    = (state, action) => ({...state, cancellingUpdate: false});

const manualUpdate        = (state, action) => ({...state, updating: true});
const manualUpdateSuccess = (state, action) => ({...state, updating: false});
const manualUpdateFail    = (state, action) => ({...state, updating: false});

const actionMap       = {
    [actionTypes.MACHINES_LOAD]:                             load,
    [actionTypes.MACHINES_LOAD_FOR_RIGHT]:                   loadForRight,
    [actionTypes.MACHINES_LOAD_SUCCESS]:                     loadSuccess,
    [actionTypes.MACHINES_LOAD_FAIL]:                        loadFail,
    [actionTypes.MACHINES_STATUS_LOAD]:                      loadStatus,
    [actionTypes.MACHINES_STATUS_LOAD_SUCCESS]:              loadStatusSuccess,
    [actionTypes.MACHINES_STATUS_LOAD_FAIL]:                 loadFail,
    [actionTypes.MACHINES_INFO_LOAD]:                        loadInfo,
    [actionTypes.MACHINES_INFO_LOAD_SUCCESS]:                loadInfoSuccess,
    [actionTypes.MACHINES_INFO_LOAD_FAIL]:                   loadInfoFail,
    [actionTypes.MACHINES_CANCEL_UPDATE]:                    cancelUpdate,
    [actionTypes.MACHINES_CANCEL_UPDATE_SUCCESS]:            cancelUpdateSuccess,
    [actionTypes.MACHINES_CANCEL_UPDATE_FAIL]:               cancelUpdateFail,
    [actionTypes.MACHINES_SWAP]:                             swap,
    [actionTypes.MACHINES_SWAP_SUCCESS]:                     swapSuccess,
    [actionTypes.MACHINES_SWAP_FAIL]:                        swapFail,
    [actionTypes.MACHINES_UNCOUPLE]:                         uncouple,
    [actionTypes.MACHINES_UNCOUPLE_SUCCESS]:                 uncoupleSuccess,
    [actionTypes.MACHINES_UNCOUPLE_FAIL]:                    uncoupleFail,
    [actionTypes.MACHINES_CLEAR_UPDATES]:                    cancelUpdate,
    [actionTypes.MACHINES_CLEAR_UPDATES_SUCCESS]:            cancelUpdateSuccess,
    [actionTypes.MACHINES_CLEAR_UPDATES_FAIL]:               cancelUpdateFail,
    [actionTypes.MACHINES_CLEAR_UPDATES_AND_RESEND]:         clearUpdatesAndResend,
    [actionTypes.MACHINES_CLEAR_UPDATES_AND_RESEND_SUCCESS]: clearUpdatesAndResendSuccess,
    [actionTypes.MACHINES_CLEAR_UPDATES_AND_RESEND_FAIL]:    clearUpdatesAndResendFail,
    [actionTypes.MACHINES_MANUAL_UPDATE]:                    manualUpdate,
    [actionTypes.MACHINES_MANUAL_UPDATE_SUCCESS]:            manualUpdateSuccess,
    [actionTypes.MACHINES_MANUAL_UPDATE_FAIL]:               manualUpdateFail
};
const machinesReducer = (state = initialState, action) => runActionMap(state, action, actionMap);

export default machinesReducer;
