import React from "react";
import PropTypes from "prop-types";

import {apiGetFullPath, apiGetToken} from "../../../api";
import {appRootPath} from "../../../variables/multiplatform";

import defaultAvatar from "../../../assets/img/placeholder.jpg";
import css from "./StatusItem.module.css";
import {convertMachineToSignal} from "../../../variables/tools";

const StatusItem = props => {

    const convertSignalToClass = signal => {
        switch (signal) {
            case -1:
                return css.machineOffline;
            case 0:
                return css.machineBad;
            case 1:
                return css.machineBelowAverage;
            case 2:
                return css.machineAverage;
            case 3:
                return css.machineGood;
            case 4:
                return css.machineExcellent;
            default:
                return css.machineNone;
        }
    };
    const signal    = convertMachineToSignal(props);
    const linkStyle = convertSignalToClass(signal);

    return (
        <div className={css.item} onClick={props.onClick}>
            <div className={css.avatar}>
                <div className={[css.imgWrapper, css.machineIndicator, linkStyle].join(" ")}>
                    <img src={
                        props.avatar ?
                            apiGetFullPath("caretakers/getavatar?uuid=" + props.uuid + "&token=" + apiGetToken()) :
                            appRootPath + defaultAvatar
                    } alt="..."/>
                </div>
            </div>
            <div className={css.text}>
                <div className={css.title}>
                    {props.name}
                </div>
            </div>
        </div>
    );
};

StatusItem.propTypes = {
    id:     PropTypes.number,
    name:   PropTypes.string,
    avatar: PropTypes.bool
};

export default StatusItem;
