import React, {Component} from 'react';
import PropTypes from 'prop-types';
import css from "./Help.module.css";
import HelpItem from "./HelpItem";

function recursiveMap(children, fn) {
    return React.Children.map(children, child => {
        if (!React.isValidElement(child)) {
            return child;
        }

        if (child.props.children) {
            child = React.cloneElement(child, {
                children: recursiveMap(child.props.children, fn)
            });
        }

        return fn(child);
    });
}

const helpItemType = (<HelpItem/>).type;

class Help extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemCount: this.countHelpItems(props.children),
            itemIndex: 0
        }

        document.body.classList.add(props.isOpen ? css.bodyOpen : css.bodyClosed);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.isOpen && this.props.isOpen) {
            document.body.classList.remove(css.bodyClosed);
            document.body.classList.add(css.bodyOpen);
            this.setState({itemIndex: 0});
        }
        if (prevProps.isOpen && !this.props.isOpen) {
            document.body.classList.remove(css.bodyOpen);
            document.body.classList.add(css.bodyClosed);
        }

        let helpItemCount = this.countHelpItems(this.props.children);
        if (this.state.itemCount !== helpItemCount) {
            this.setState({itemCount: helpItemCount});
        }

    }

    componentWillUnmount() {
        document.body.classList.remove(css.bodyOpen);
        document.body.classList.remove(css.bodyClosed);
    }

    countHelpItems(children) {
        let helpItemCount = 0;
        recursiveMap(children, child => {
            if (child.type === helpItemType) {
                helpItemCount++;
            } else if (child.props.hasHelpItems) {
                helpItemCount += child.props.hasHelpItems;
            }
        });
        return helpItemCount;
    }

    doClose = () => this.props.onClose && this.props.onClose();
    doNext  = () => {
        if (this.state.itemIndex >= this.state.itemCount - 1) {
            return this.doClose();
        }
        this.setState({itemIndex: this.state.itemIndex + 1});
    }

    render() {
        // Clone children with altered "show" prop
        let helpIndex           = 0;
        const childrenWithProps = recursiveMap(this.props.children, child => {
            if (child.type === helpItemType) {
                let newElement = React.cloneElement(child, {show: this.props.isOpen && this.state.itemIndex === helpIndex});
                helpIndex++;
                return newElement;
            } else if (child.props.hasHelpItems) {
                let itemCount     = child.props.hasHelpItems;
                let showHelpItem  = false;
                let showHelpIndex = 0;
                if (this.props.isOpen && this.state.itemIndex >= helpIndex && this.state.itemIndex < helpIndex + itemCount) {
                    showHelpItem  = true;
                    showHelpIndex = this.state.itemIndex - helpIndex;
                }
                helpIndex += itemCount;
                return React.cloneElement(child, {showHelpItem, showHelpIndex});
            }
            return child;
        });

        return (
            <div>
                {childrenWithProps}
                {this.props.isOpen && <div onClick={this.doNext} className={css.overlay}/>}
            </div>
        );
    }
}

Help.propTypes = {
    isOpen:  PropTypes.bool,
    onClose: PropTypes.func,
    steps:   PropTypes.arrayOf(
        PropTypes.shape({
            selector: PropTypes.string,
            content:  PropTypes.oneOfType([
                PropTypes.node,
                PropTypes.element,
                PropTypes.func,
            ]).isRequired
        })
    ),
};

export default Help;