// React
import React, {useEffect, useState} from "react";
// Redux
import {useDispatch, useSelector} from "react-redux";
// Tools
import i18n from "../../../i18n";
import * as actions from "../../../store/actions";
// Components

import {Button, Spinner, Form, BottomBar, ButtonCrusher, Tour} from "../../../components";
import withCaretakers from "../../../hoc/withCaretakers";
import settingsForm from "./form";
import {TitleWrapper} from "../../../contexts/TitleProvider";
import {getPageHelpSteps} from "../../../help/tools";
import css from "./ScreenSettings.module.css";

const ScreenSettings = props => {
    const dispatch                    = useDispatch();
    const loading                     = useSelector(state => state.caretakers.loading);
    const settingsSaving              = useSelector(state => state.caretakers.settingsSaving);
    const [helpOpen, setHelpOpen]     = useState(false);
    const doHelp                      = () => setHelpOpen(true);
    const closeHelp                   = () => setHelpOpen(false);
    const [formValues, setFormValues] = useState(props.caretaker ? props.caretaker.settings : {});
    const [formValid, setFormValid]   = useState(false);

    const handleChange = (newFormValues, newFormValid) => {
        setFormValues(newFormValues);
        setFormValid(newFormValid);
    }
    const handleSubmit = () => {
        if (!formValid) {
            dispatch(actions.messages.errorPush(i18n.errorForm));
            return;
        }
        dispatch(actions.caretakers.saveSettings(props.caretaker.uuid, formValues));
    }

    useEffect(() => {
        if (props.caretaker && props.caretaker.settings) setFormValues(props.caretaker.settings);
    }, [props.caretaker]);

    // <TitleWrapper title={i18n.caretakerSettingsFor.replace("%1", props.caretaker ? props.caretaker.name : i18n.caretaker)}>
    return (
        <TitleWrapper title={props.caretaker ? props.caretaker.name : i18n.caretaker}>
            <Tour
                isOpen={helpOpen}
                onRequestClose={closeHelp}
                steps={getPageHelpSteps("caretakerScreenSettings")}
            />
            <div>
                <div className="content-form">
                    <Form inlineSelect formData={settingsForm} onChange={handleChange} onValid={setFormValid} values={formValues}/>
                    <ButtonCrusher width={74} topSpacer={20}>
                        <Button block upper round color="info" onClick={handleSubmit} className={css.tourSave}>{i18n.buttonSave}</Button>
                    </ButtonCrusher>
                    <BottomBar
                        backButton
                        backUrl={"/caretakers/status/" + props.caretakerUuid}
                        onHelp={doHelp}
                        onCloseHelp={closeHelp}
                        helpOpen={helpOpen}
                    />
                    {(loading || settingsSaving) && <Spinner/>}
                </div>
            </div>
        </TitleWrapper>
    );
};

export default withCaretakers(ScreenSettings);
