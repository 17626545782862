import React from "react";
import {BoolCheck, Button, Icon, UncontrolledTooltip} from "../../components";
import css from "./tableColumns.module.css";
import i18n from "../../i18n";

export const accessorColumn = (id, header, accessor, settings = {}) => ({
    id,
    accessor:  accessor,
    Header:    header,
    togglable: true,
    show:      true,
    ...settings
});
export const standardColumn = (id, header, settings = {}) => accessorColumn(id, header, id, settings);

const filterMethod = (filter, row) => filter.value === "All" || (filter.value === "Yes" && row[filter.id]) || (filter.value === "No" && !row[filter.id]);
const filterYesNo  = ({filter, onChange}) => (
    <select onChange={event => onChange(event.target.value)} style={{width: "100%"}} className={css.filterSelect} value={filter ? filter.value : "All"}>
        <option value={filter ? filter.value : "All"} style={{display: "none"}}>{
            filter ? (
                filter.value === "All" ? i18n.all.substr(0, 1) : (
                    filter.value === "Yes" ? i18n.yes.substr(0, 1) : i18n.no.substr(0, 1)
                )
            ) : i18n.all.substr(0, 1)
        }</option>
        <option value="All">{i18n.all}</option>
        <option value="Yes">{i18n.yes}</option>
        <option value="No">{i18n.no}</option>
    </select>
);

export const makeYesNoColumn = (id, header, headerIcon, settings = {}, iconColor = null, yes = null, no = null) => {
    return {
        id,
        Header:        <Icon name={headerIcon} title={header} color={iconColor} size={1.5}/>,
        Cell:          ({value}) => <BoolCheck value={value} noText title={header + ": " + (value ? (yes || i18n.yes) : (no || i18n.no))}/>,
        selectorTitle: header,
        accessor:      id,
        width:         32,
        filterMethod:  filterMethod,
        Filter:        filterYesNo,
        sortable:      false,
        togglable:     true,
        show:          true,
        ...settings
    };
};

export const actionColumn = (actions, accessor = "uuid") => {
    return {
        id:              "actions",
        Header:          "",
        accessor:        accessor,
        minWidth:        34,
        maxWidth:        actions.length * 34,
        headerClassName: css.actions,
        className:       css.actions,
        sortable:        false,
        filterable:      false,
        Cell:            props => (
            <span>
                {actions.map((action, key) => (
                    <React.Fragment key={"action" + key}>
                        <Button id={"action" + key + props.value} className={css.actionButton} icon color={action.color} size="sm"
                                onClick={() => action.action(props.value, props.original.name)}>
                            <Icon name={action.icon}/>
                        </Button>
                        <UncontrolledTooltip boundariesElement="window" placement="top" target={"action" + key + props.value} delay={0}>
                            {action.text}
                        </UncontrolledTooltip>
                    </React.Fragment>
                ))}
            </span>
        )
    };
};

export const selectorColumn = (accessor, onChange, isChecked) => ({
    id:         "selector",
    accessor:   accessor,
    Header:     "",
    Cell:       ({value}) => <input type="checkbox" checked={!!isChecked(value)} onChange={e => onChange(value, e.target.checked)}/>,
    width:      40,
    sortable:   false,
    filterable: false
});

export const defaultColumns = (columns, actions, actionAccessor, selector) => {
    const selectorArray = [];
    if (selector) {
        selectorArray.push(selectorColumn(selector.accessor, selector.onChange, selector.isChecked));
    }
    const actionArray = [];
    if (actions) {
        actionArray.push(actionColumn(actions, actionAccessor));
    }
    return [
        ...selectorArray,
        ...columns,
        ...actionArray
    ];
};

export const fillerColumn = {
    id:         "filler",
    minWidth:   10,
    Header:     "",
    accessor:   d => "",
    sortable:   false,
    filterable: false
};