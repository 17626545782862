import * as actionTypes from "../actionTypes";
import moment from 'moment-timezone';
import {runActionMap} from "../reducerFunctions";

const initialState = {
    lastUniversalLink: null,
    lastLinkReceived: null,
    appPaused: null,
    appResumed: null
};

// Load reducers
const receivedUniversalLink = (state, action) => ({
    ...state,
    lastUniversalLink: action.data,
    lastLinkReceived: moment()
});

const receivedPause = (state) => ({
    ...state,
    appPaused: moment()
});

const receivedResume = (state) => ({
    ...state,
    appResumed: moment()
});

const actionMap = {
    [actionTypes.APP_RECEIVED_UNIVERSAL_LINK]: receivedUniversalLink,
    [actionTypes.APP_RECEIVED_PAUSE]: receivedPause,
    [actionTypes.APP_RECEIVED_RESUME]: receivedResume
};
const appReducer = (state = initialState, action) => runActionMap(state, action, actionMap);

export default appReducer;
