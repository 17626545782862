import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import i18n from "../../../i18n";
import * as actions from "../../../store/actions";

import {BottomBar, Button, ButtonCrusher, Form, Spinner, Tour} from "../../../components";
import {formHandleValueSetters} from "../../../variables/tools";
import {TitleWrapper} from "../../../contexts/TitleProvider";
import {getPageHelpSteps} from "../../../help/tools";
import css from "./InviteNewUser.module.css";
import CaretakerSelector from "./CaretakerSelector";

const inviteForm = {
    name:   {
        label:     i18n.caretakersAddNewUserName,
        className: css.tourName
    },
    email:  {
        label:      i18n.caretakersAddNewUserEmail,
        className:  css.tourEmail,
        validation: {required: true, isEmail: true}
    },
    rights: {
        label:        i18n.caretakersAddNewUserRights,
        className:    css.tourRights,
        type:         "select",
        defaultValue: "content",
        options:      i18n.caretakersAddNewUserRightsList
    }
};

const InviteNewUser = props => {
    const dispatch                                    = useDispatch();
    const inviting                                    = useSelector(state => state.caretakers.inviting);
    const [formValues, setFormValues]                 = useState({});
    const [formValid, setFormValid]                   = useState(false);
    const [selectedCaretakers, setSelectedCaretakers] = useState([]);
    const [helpOpen, setHelpOpen]                     = useState(false);

    const doHelp    = () => setHelpOpen(true);
    const closeHelp = () => setHelpOpen(false);
    const onSubmit  = () => {
        if (!formValid) {
            dispatch(actions.messages.errorPush(i18n.errorForm));
            return;
        }
        if (selectedCaretakers.length === 0) {
            dispatch(actions.messages.errorPush(i18n.errorForm));
            return;
        }
        dispatch(actions.caretakers.inviteUser({...formValues, caretakers: selectedCaretakers}));
    }

    return (
        <TitleWrapper title={i18n.caretakersAddNewUser}>
            <Tour
                isOpen={helpOpen}
                onRequestClose={closeHelp}
                steps={getPageHelpSteps("inviteNewUser")}
            />
            <div>
                <div className="content-form">
                    <Form
                        inlineSelect
                        formData={inviteForm}
                        values={formValues}
                        onChange={formHandleValueSetters(setFormValues, setFormValid)}
                        onValid={valid => setFormValid(valid)}
                        stacked
                    />
                    <CaretakerSelector selected={selectedCaretakers} onChange={setSelectedCaretakers}/>
                    <ButtonCrusher width={77} topSpacer={25}>
                        <Button
                            block
                            upper
                            round
                            color="info"
                            onClick={onSubmit}
                        >{i18n.buttonAdd}</Button>
                    </ButtonCrusher>
                    <BottomBar backButton onHelp={doHelp} onCloseHelp={closeHelp} helpOpen={helpOpen}/>
                    {inviting && <Spinner/>}
                </div>
            </div>
        </TitleWrapper>
    );
};

export default InviteNewUser;