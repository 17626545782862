import React from 'react';
import PropTypes from 'prop-types';
import {FormGroup, Label} from "reactstrap";
import {Link} from "react-router-dom";

const Stacked = props => (
    <FormGroup
        id={props.name}
        className={
            (props.settings.className ? props.settings.className + " " : "") +
            ((props.settings.touched && props.settings.validation) ? (props.settings.valid ? "has-success" : "has-danger") : "")
        }
    >
        {!props.settings.noLabel ? <Label>{props.settings.label}</Label> : null}
        {props.settings.labelLink ? <Link tabIndex={-1} to={props.settings.labelLink.to}>{props.settings.labelLink.label}</Link> : null}
        {props.children}
    </FormGroup>
);

Stacked.propTypes = {
    name:     PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    noRow:    PropTypes.bool
};

export default Stacked;