import account from "./account";
import affiliates from "./affiliates";
import calendars from "./calendars";
import caretakers from "./caretakers";
import distributors from "./distributors";
import generic from "./generic";
import groups from "./groups";
import help from "./help";
import machines from "./machines";
import pages from "./pages";
import routes from "./routes";
import users from "./users";

export default {
    ...account,
    ...affiliates,
    ...calendars,
    ...caretakers,
    ...distributors,
    ...generic,
    ...groups,
    ...help,
    ...machines,
    ...pages,
    ...routes,
    ...users
};