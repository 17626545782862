import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const timestamp = ({time, timeString, format}) => {
    let outputMoment = null;
    if (time) {
        outputMoment = moment.utc(time < 10000000000 ? time * 1000 : time).local();
    }
    else {
        outputMoment = moment.utc(timeString).local();
    }
    return <span>{outputMoment.format(format ? format : "YYYY-MM-DD HH:mm:ss")}</span>;
};

timestamp.propTypes = {
    time:       PropTypes.number,
    timeString: PropTypes.string,
    format:     PropTypes.string
};

export default timestamp;