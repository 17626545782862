// React
import React, {useEffect} from "react";
import {Redirect} from "react-router";

// Redux
import {useSelector, useDispatch} from "react-redux";

// Components
import {PanelHeader, Spinner} from "../../components";
import {Card, CardBody} from "reactstrap";
import i18n from "../../i18n";
import * as actions from "../../store/actions";

const CheckNewAccount = props => {
    const loading    = useSelector(state => state.auth.loading);
    const caretakers = useSelector(state => state.caretakers.caretakers);
    const dispatch   = useDispatch();
    useEffect(() => {dispatch(actions.caretakers.loadIfNeeded())}, [dispatch]);
    return (
        <div>
            <PanelHeader size="sm"/>
            <Spinner/>
            <div className="content">
                <Card>
                    <CardBody>{i18n.loading}</CardBody>
                </Card>
            </div>
            {!loading && caretakers && (caretakers.length === 0 ? <Redirect to="/caretakers/addnewmachine"/> : <Redirect to="/caretakers"/>)}
        </div>
    );
};

export default CheckNewAccount;
