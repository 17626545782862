import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";

import * as actions from "../../../../../store/actions";
import {Button, Icon, UncontrolledTooltip} from "../../../../../components";
import i18n from "../../../../../i18n";
import css from "../../Users.module.css";

const calendarIcons  = [{name: "e-remove", color: "danger"}, {name: "eye", color: "warning"}, {name: "pen", color: "success"}];
const settingsIcons  = [{name: "e-remove", color: "danger"}, {name: "eye", color: "warning"}, {name: "pen", color: "success"}];
const messagesIcons  = [{name: "e-remove", color: "danger"}, {name: "send-message", color: "warning"}, {name: "cursor-menu", color: "success"}];
const detailsIcons   = [{name: "e-remove", color: "danger"}, {name: "eye", color: "warning"}, {name: "pen", color: "success"}];
const previewIcons   = [{name: "e-remove", color: "danger"}, {name: "eye", color: "success"}];
const technicalIcons = [{name: "e-remove", color: "danger"}, {name: "eye", color: "warning"}, {name: "settings", color: "success"}];
const inviteIcons    = [{name: "e-remove", color: "danger"}, {name: "check", color: "success"}];
const paymentsIcons  = [{name: "e-remove", color: "danger"}, {name: "check", color: "success"}];

const right = ({userId, data, deleteRight}) => {
    let icon = null;
    let name = "";
    switch (data.right_type) {
        case "caretaker":
            icon = <Icon name="single-05" title={i18n.usersRightsTypeCaretaker}/>;
            name = data.caretaker.name;
            break;

        case "group":
            icon = <Icon name="multiple-11" title={i18n.usersRightsTypeGroup}/>;
            name = data.group.name;
            break;

        case "affiliate":
            icon = <Icon name="tag" title={i18n.usersRightsTypeAffiliate}/>;
            name = data.affiliate.name;
            break;

        case "distributor":
            icon = <Icon name="office" title={i18n.usersRightsTypeDistributor}/>;
            name = data.distributor.name;
            break;

        default:
            icon = <Icon name="e-remove" title={i18n.unknown}/>;
    }

    return (
        <tr>
            <td>{icon}</td>
            <td>{name}</td>
            <td><Icon {...calendarIcons[data.rights.calendar]} size={1.45} title={i18n.usersRightsCalendarRight[data.rights.calendar]}/></td>
            <td><Icon {...settingsIcons[data.rights.settings]} size={1.45} title={i18n.usersRightsSettingsRight[data.rights.settings]}/></td>
            <td><Icon {...messagesIcons[data.rights.messages]} size={1.45} title={i18n.usersRightsMessagesRight[data.rights.messages]}/></td>
            <td><Icon {...detailsIcons[data.rights.details]} size={1.45} title={i18n.usersRightsDetailsRight[data.rights.details]}/></td>
            <td><Icon {...previewIcons[data.rights.preview]} size={1.45} title={i18n.usersRightsPreviewRight[data.rights.preview]}/></td>
            <td><Icon {...technicalIcons[data.rights.technical]} size={1.45} title={i18n.usersRightsTechnicalRight[data.rights.technical]}/></td>
            <td><Icon {...inviteIcons[data.rights.invite]} size={1.45} title={i18n.usersRightsInviteRight[data.rights.invite]}/></td>
            <td><Icon {...paymentsIcons[data.rights.payments]} size={1.45} title={i18n.usersRightsPaymentsRight[data.rights.payments]}/></td>
            <td>
                <Button
                    id={"rightDelete" + data.uuid}
                    className={css.actionButton}
                    icon
                    color="danger"
                    size="sm"
                    onClick={() => deleteRight(userId, data.uuid)}
                >
                    <Icon name="e-remove"/>
                </Button>
                <UncontrolledTooltip
                    boundariesElement="window"
                    placement="top"
                    target={"rightDelete" + data.uuid}
                    delay={0}
                >
                    {i18n.usersRightsTableDelete}
                </UncontrolledTooltip>
            </td>
        </tr>
    )
};

right.propTypes = {
    userId: PropTypes.string.isRequired,
    data:   PropTypes.object.isRequired
};

const mapStateToProps    = state => ({});
const mapDispatchToProps = dispatch => ({
    deleteRight: (userId, rightId) => dispatch(actions.users.rightRemove(userId, rightId))
});

export default connect(mapStateToProps, mapDispatchToProps)(right);