import {call, put, takeLatest} from "redux-saga/effects";
import {push} from "connected-react-router";
import * as actions from "../actions";
import * as actionTypes from "../actionTypes";
import * as api from "../../api";
import i18n from "../../i18n";
import moment from 'moment-timezone';

// Watcher
export function* watchCalendars() {
    yield takeLatest(actionTypes.CALENDARS_LOAD, loadSaga);
    yield takeLatest(actionTypes.CALENDAR_SAVE_APPOINTMENT, saveAppointmentSaga);
    yield takeLatest(actionTypes.CALENDAR_SAVE_BIRTHDAY, saveBirthdaySaga);
    yield takeLatest(actionTypes.CALENDAR_SAVE_REMINDER, saveReminderSaga);
    yield takeLatest(actionTypes.CALENDAR_SAVE_TVSHOW, saveTvShowSaga);
    yield takeLatest(actionTypes.CALENDAR_SKIP, skipSaga);
    yield takeLatest(actionTypes.CALENDAR_UNSKIP, unskipSaga);
    yield takeLatest(actionTypes.CALENDAR_REMOVE, removeSaga);
}

// Tools
function fillInMoments(calendarItem) {
    let newCalendarItem = {...calendarItem, placed: moment.utc(calendarItem.placed, "YYYY-MM-DD HH:mm:ss")};
    if (newCalendarItem.hasOwnProperty("start")) {
        newCalendarItem.start = moment(newCalendarItem.start, "YYYY-MM-DD HH:mm:ss");
    }
    if (newCalendarItem.hasOwnProperty("end")) {
        newCalendarItem.end = moment(newCalendarItem.end, "YYYY-MM-DD HH:mm:ss");
    }
    if (newCalendarItem.hasOwnProperty("startDay")) {
        newCalendarItem.startDay = moment(newCalendarItem.startDay, "YYYY-MM-DD HH:mm:ss");
    }
    if (newCalendarItem.hasOwnProperty("endDay")) {
        newCalendarItem.endDay = moment(newCalendarItem.endDay, "YYYY-MM-DD HH:mm:ss");
    }
    if (newCalendarItem.hasOwnProperty("endInterval")) {
        newCalendarItem.endInterval = moment(newCalendarItem.endInterval, "YYYY-MM-DD HH:mm:ss");
    }
    if (newCalendarItem.hasOwnProperty("nextTime")) {
        newCalendarItem.nextTime = moment(newCalendarItem.nextTime, "YYYY-MM-DD HH:mm:ss");
    }
    if (newCalendarItem.hasOwnProperty("birthday")) {
        newCalendarItem.birthday = moment(newCalendarItem.birthday + " 12:00:00", "YYYY-MM-DD HH:mm:ss");
    }
    return newCalendarItem;
}

// Sagas
function* loadSaga(action) {
    try {
        const response     = yield call(api.calendars.load, action.forCaretaker);
        const newCalendars = {};
        for (let calendarType in response.data.calendars) {
            if (response.data.calendars.hasOwnProperty(calendarType)) {
                newCalendars[calendarType] = response.data.calendars[calendarType].map(fillInMoments);
            }
        }
        yield put(actions.calendars.loadSuccess(action.forCaretaker, newCalendars));
    }
    catch (err) {
        yield put(actions.calendars.loadFail());
        yield put(actions.messages.errorPush(i18n.calendarLoadError))
    }
}

function* saveAppointmentSaga(action) {
    try {
        yield call(api.calendars.saveAppointment, action.forCaretaker, action.appointment);
        yield put(actions.calendars.saveSuccess());
        yield put(actions.messages.successPush(i18n.calendarSaveSuccess.appointments));
        yield put(actions.calendars.load(action.forCaretaker));
        yield put(push("/caretakers/calendar/appointments/" + action.forCaretaker));
    }
    catch (err) {
        yield put(actions.calendars.saveFail());
        yield put(actions.messages.errorPush(i18n.calendarSaveFail.appointments))
    }
}

function* saveBirthdaySaga(action) {
    try {
        yield call(api.calendars.saveBirthday, action.forCaretaker, action.birthday);
        yield put(actions.calendars.saveSuccess());
        yield put(actions.messages.successPush(i18n.calendarSaveSuccess.birthdays));
        yield put(actions.calendars.load(action.forCaretaker));
        yield put(push("/caretakers/calendar/birthdays/" + action.forCaretaker));
    }
    catch (err) {
        yield put(actions.calendars.saveFail());
        yield put(actions.messages.errorPush(i18n.calendarSaveFail.birthdays))
    }
}

function* saveReminderSaga(action) {
    try {
        yield call(api.calendars.saveReminder, action.forCaretaker, action.reminder);
        yield put(actions.calendars.saveSuccess());
        yield put(actions.messages.successPush(i18n.calendarSaveSuccess.reminders));
        yield put(actions.calendars.load(action.forCaretaker));
        yield put(push("/caretakers/calendar/reminders/" + action.forCaretaker));
    }
    catch (err) {
        yield put(actions.calendars.saveFail());
        yield put(actions.messages.errorPush(i18n.calendarSaveFail.reminders))
    }
}

function* saveTvShowSaga(action) {
    try {
        yield call(api.calendars.saveTvShow, action.forCaretaker, action.tvShow);
        yield put(actions.calendars.saveSuccess());
        yield put(actions.messages.successPush(i18n.calendarSaveSuccess.tvshows));
        yield put(actions.calendars.load(action.forCaretaker));
        yield put(push("/caretakers/calendar/tvshows/" + action.forCaretaker));
    }
    catch (err) {
        yield put(actions.calendars.saveFail());
        yield put(actions.messages.errorPush(i18n.calendarSaveFail.tvshows))
    }
}

function* skipSaga(action) {
    try {
        yield call(api.calendars.skip, action.calendarType, action.uuid);
        yield put(actions.calendars.skipSuccess());
        yield put(actions.messages.successPush(i18n.calendarSkipSuccess));
        yield put(actions.calendars.load(action.forCaretaker));
    }
    catch (err) {
        yield put(actions.calendars.skipFail());
        yield put(actions.messages.errorPush(i18n.calendarSkipFail));
    }
}

function* unskipSaga(action) {
    try {
        yield call(api.calendars.unskip, action.calendarType, action.uuid);
        yield put(actions.calendars.skipSuccess());
        yield put(actions.messages.successPush(i18n.calendarResetSkipSuccess));
        yield put(actions.calendars.load(action.forCaretaker));
    }
    catch (err) {
        yield put(actions.calendars.skipFail());
        yield put(actions.messages.errorPush(i18n.calendarResetSkipFail));
    }
}

function* removeSaga(action) {
    try {
        yield call(api.calendars.remove, action.calendarType, action.uuid);
        yield put(actions.calendars.removeSuccess());
        yield put(actions.messages.successPush(i18n.calendarDeleteSuccess));
        yield put(actions.calendars.load(action.forCaretaker));
    }
    catch (err) {
        yield put(actions.calendars.removeFail());
        yield put(actions.messages.errorPush(i18n.calendarDeleteError));
    }
}