import React from 'react';
import PropTypes from 'prop-types';
import {Input} from "reactstrap";
import AutoWrapInput from '../InputContainers/AutoWrapInput';
import {iosInputFix} from "../../../tools/ios-inputfix";

const TextArea = props => {

    const changeHandler = e => {
        let newValue = e.target.value;
        if (props.settings.disableNewLine) {
            newValue = newValue.replace(/(\r\n|\n|\r)/gm, "");
        }
        props.handlers.change(newValue, props.name);
    };

    const blurHandler = e => {
        iosInputFix();
        props.handlers.focus(props.name, false);
    };

    return (
        <AutoWrapInput name={props.name} settings={props.settings} stacked={props.stacked} disabled={props.disabled}>
            <Input
                rows={4}
                {...props.settings.elementConfig}
                type="textarea"
                name={props.name}
                disabled={props.disabled}
                onChange={changeHandler}
                onFocus={() => props.handlers.focus(props.name, true)}
                onBlur={blurHandler}
                className={props.settings.touched && props.settings.validation ? (props.settings.valid ? "has-success" : "has-danger") : ""}
                value={props.settings.value}
                innerRef={props.innerRef}
                style={props.settings.height ? {height: props.settings.height + "px"} : {}}
            />
        </AutoWrapInput>
    );
};

TextArea.propTypes = {
    name:     PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    handlers: PropTypes.object.isRequired,
    innerRef: PropTypes.object,
    stacked:  PropTypes.bool
};

export default React.memo(TextArea, (prevProps, nextProps) => {
    return prevProps.settings.value === nextProps.settings.value &&
        prevProps.settings.focused === nextProps.settings.focused &&
        prevProps.settings.valid === nextProps.settings.valid &&
        prevProps.disabled === nextProps.disabled;
});