export default {
    helpSubtitle: "Wie können wir Ihnen behilflich sein?",

    helpAddTitle:  "Neuen Hilfeartikel erstellen",
    helpEditTitle: "Hilfeartikel bearbeiten",

    helpLanguages: {
        da: "Dänisch",
        de: "Deutsch",
        en: "Englisch",
        nl: "Niederländisch"
    },

    helpSelectLanguage: "Wählen Sie die Hilfesprache",
    helpAddItem:        "Fügen Sie einen neuen Hilfeartikel hinzu",
    helpViewItem:       "Hilfeartikel anzeigen",
    helpEditItem:       "Hilfeartikel bearbeiten",
    helpEditItems:      "Hilfeartikel bearbeiten",
    helpDeleteItem:     "Hilfeartikel löschen",

    helpItemLanguage:    "Sprache",
    helpItemTitle:       "Titel",
    helpItemBody:        "Inhalt",
    helpItemTags:        "Tags",
    helpItemPageBinding: "Seitenbindung",
    helpItemSave:        "Hilfeartikel speichern",
    helpNoPageBinding:   "Keine Seitenbindung",

    helpEditSuccess:          "Hilfeartikel erfolgreich gespeichert",
    helpEditError:            "Beim Speichern des Hilfeartikels ist ein Fehler aufgetreten.",
    helpEditErrorPageBinding: "Ein Hilfeartijkel für diese Seitenbindung und Sprache ist bereits vorhanden!",
    helpRemoveSuccess:        "Hilfeartikel erfolgreich gelöscht",
    helpRemoveError:          "Beim Löschen des Hilfeartikels ist ein Fehler aufgetreten.",
}
