import * as actionTypes from "../actionTypes";

export const load         = () => ({type: actionTypes.GROUPS_LOAD});
export const loadIfNeeded = () => ({type: actionTypes.GROUPS_LOAD_IF_NEEDED});
export const loadSuccess  = groups => ({type: actionTypes.GROUPS_LOAD_SUCCESS, groups});
export const loadFail     = error => ({type: actionTypes.GROUPS_LOAD_FAIL, error});

export const unload = () => ({type: actionTypes.GROUPS_UNLOAD});

export const save        = (uuid, groupDetails) => ({type: actionTypes.GROUPS_SAVE, uuid, groupDetails});
export const saveSuccess = () => ({type: actionTypes.GROUPS_SAVE_SUCCESS});
export const saveFail    = error => ({type: actionTypes.GROUPS_SAVE_FAIL, error});

export const remove        = uuid => ({type: actionTypes.GROUPS_REMOVE, uuid});
export const removeSuccess = () => ({type: actionTypes.GROUPS_REMOVE_SUCCESS});
export const removeFail    = error => ({type: actionTypes.GROUPS_REMOVE_FAIL, error});

export const addCaretakers        = (group, caretakers) => ({type: actionTypes.GROUPS_ADD_CARETAKERS, group, caretakers});
export const addCaretakersSuccess = () => ({type: actionTypes.GROUPS_ADD_CARETAKERS_SUCCESS});
export const addCaretakersFail    = error => ({type: actionTypes.GROUPS_ADD_CARETAKERS_FAIL, error});

export const removeCaretaker        = (group, caretaker) => ({type: actionTypes.GROUPS_REMOVE_CARETAKER, group, caretaker});
export const removeCaretakerSuccess = () => ({type: actionTypes.GROUPS_REMOVE_CARETAKER_SUCCESS});
export const removeCaretakerFail    = error => ({type: actionTypes.GROUPS_REMOVE_CARETAKER_FAIL, error});

export const manageSave = (uuid, groupDetails) => ({type: actionTypes.GROUPS_MANAGE_SAVE, uuid, groupDetails});
export const manageSaveSuccess = () => ({type: actionTypes.GROUPS_MANAGE_SAVE_SUCCESS});
export const manageSaveFail    = error => ({type: actionTypes.GROUPS_MANAGE_SAVE_FAIL, error});

export const manageCaretakers = (uuid, caretakers) => ({type: actionTypes.GROUPS_MANAGE_CARETAKERS, uuid, caretakers});
export const manageCaretakersSuccess = () => ({type: actionTypes.GROUPS_MANAGE_CARETAKERS_SUCCESS});
export const manageCaretakersFail    = error => ({type: actionTypes.GROUPS_MANAGE_CARETAKERS_FAIL, error});

export const clearError = () => ({type: actionTypes.GROUPS_RESET_ERROR});
