import React        from 'react';
import {connect}    from "react-redux";
import {push}       from "connected-react-router";
import {omit}       from "reactstrap/es/utils";
import * as actions from "../store/actions";
import {Spinner}    from '../components';

const mapStateToProps    = state => ({
    pathname:         state.router.location.pathname,
    loading:          state.caretakers.loading,
    caretakers:       state.caretakers.caretakers,
    caretakersLoaded: state.caretakers.caretakersLoaded
});
const mapDispatchToProps = dispatch => ({
    push:           pathname => dispatch(push(pathname)),
    loadCaretakers: () => dispatch(actions.caretakers.load())
});

const withCaretakers = (WrappedComponent, failRedirect = "/caretakers") => {
    return connect(mapStateToProps, mapDispatchToProps)(class withCaretakers extends React.PureComponent {
        state = {
            uuid: null,
            caretaker: null
        };

        constructor(props) {
            super(props);

            let uuid = props.match.params.caretaker;
            if (uuid.length !== 32) {
                props.push(failRedirect);
                return;
            }

            if (props.caretakersLoaded) {
                this.state = {uuid, caretaker: this.props.caretakers.find(obj => obj.uuid === uuid)};
            } else {
                if (!props.loading) {
                    props.loadCaretakers();
                }
                this.state.uuid = uuid;
            }
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            if (this.props === prevProps) {
                return;
            }

            if(!this.props.caretakersLoaded && !this.props.loading) {
                this.props.loadCaretakers();
            }

            // If the caretaker store suddenly changed, update the details
            if (this.props.caretakers && this.props.caretakers !== prevProps.caretakers && this.props.caretakers.length > 0) {
                let uuid      = this.props.match.params.caretaker;
                let caretaker = this.props.caretakers.find(obj => obj.uuid === uuid);
                if (caretaker) {
                    this.setState({caretaker});
                } else {
                    // Unknown caretaker, go back to caretaker overview
                    this.props.push(failRedirect);
                }
            }
        }

        render() {
            return (
                <>
                    <WrappedComponent
                        caretakerUuid={this.state.uuid}
                        caretaker={this.state.caretaker}
                        loading={this.props.loading}
                        loaded={this.props.caretakersLoaded}
                        {...omit(this.props, ["caretakers", "caretakersLoaded", "loading", "pathname", "loadCaretakers"])} />
                    {!this.props.caretakersLoaded && <Spinner/>}
                </>
            );
        }
    });
};

export default withCaretakers;