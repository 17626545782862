import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Input} from "reactstrap";
import AutoWrapInput from "../InputContainers/AutoWrapInput";
import {deepCompare} from "../../../variables/tools";
import css from "./Select.module.css";

const Select = props => {
    const settings          = props.settings;
    let options             = settings.options;
    let originalMasterValue = null;
    if (settings.selectType && settings.selectType === "slave") {
        options = settings.options[settings.defaultSlave];
        if (
            props.values &&
            props.values[settings.slaveTo]
        ) {
            originalMasterValue = props.values[settings.slaveTo];
            if (settings.options.hasOwnProperty(props.values[settings.slaveTo])) {
                options = settings.options[props.values[settings.slaveTo]];
            }
        }
    }
    const [masterValue, setMasterValue] = useState(originalMasterValue);
    useEffect(() => {
        if(props.values && props.values[settings.slaveTo] !== masterValue) {
            setMasterValue(props.values[settings.slaveTo]);
            props.handlers.change(props.innerRef.current.value, props.name);
        }
        // eslint-disable-next-line
    }, [props.values, settings, masterValue]);

    return (
        <AutoWrapInput name={props.name} settings={props.settings} stacked={props.stacked} inline={props.inlineSelect} disabled={props.disabled}>
            <Input
                {...props.settings.elementConfig}
                type="select"
                name={props.name}
                disabled={props.disabled}
                onChange={event => props.handlers.change(event.target.value, props.name)}
                onFocus={() => props.handlers.focus(props.name, true)}
                onBlur={() => props.handlers.focus(props.name, false)}
                className={
                    css.select + " " +
                    (props.inlineSelect ? css.inlineSelect + " " : "") +
                    ((props.settings.touched && props.settings.validation) ? (props.settings.valid ? "has-success" : "has-danger") : "")
                }
                value={props.settings.value}
                innerRef={props.innerRef}
            >
                {Object.entries(options).map(([data, text]) => (
                    <option key={data} value={data}>{text}</option>
                ))}
            </Input>
        </AutoWrapInput>
    );
};

Select.propTypes = {
    name:         PropTypes.string.isRequired,
    settings:     PropTypes.object.isRequired,
    handlers:     PropTypes.object.isRequired,
    innerRef:     PropTypes.object,
    stacked:      PropTypes.bool,
    inlineSelect: PropTypes.bool
};

export default React.memo(Select, (prevProps, nextProps) => (
    prevProps.settings.value === nextProps.settings.value &&
    prevProps.settings.focused === nextProps.settings.focused &&
    prevProps.settings.valid === nextProps.settings.valid &&
    prevProps.disabled === nextProps.disabled &&
    (
        nextProps.settings.selectType !== "slave" ||
        (
            (prevProps.values[prevProps.settings.slaveTo] === nextProps.values[nextProps.settings.slaveTo])
        )
    ) &&
    deepCompare(prevProps.settings.options, nextProps.settings.options)
));
