import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import i18n from "../../../../../i18n";
import Form from "../../../../../components/Form/Form";
import {Button} from "../../../../../components";
import * as actions from "../../../../../store/actions";
import {useDispatch, useSelector} from "react-redux";

const CopyRights = props => {
    const dispatch                            = useDispatch();
    const [showCopy, setShowCopy]             = useState(false);
    const [formCopyValues, setFormCopyValues] = useState({});
    const [formCopyValid, setFormCopyValid]   = useState(false);
    const copyingRightsDone                   = useSelector(state => state.users.copyingRightsDone);

    useEffect(() => {
        if (copyingRightsDone) {
            setShowCopy(false);
            dispatch(actions.users.resetDoneStates());
        }
    }, [copyingRightsDone, dispatch]);

    const copyForm = {
        toUser:  {label: i18n.user, type: "user", hideUser: props.userId},
        replace: {label: i18n.usersCopyReplace, type: "switch", labelExtended: i18n.usersCopyReplaceExpanded}
    };

    return (
        <>
            <Button color="info" block round simple onClick={() => setShowCopy(true)}>
                {i18n.usersActionsCopyRightsButton}
            </Button>
            <Modal isOpen={showCopy} toggle={() => setShowCopy(!showCopy)}>
                <ModalHeader className="justify-content-center uppercase title" tag="h4">{i18n.usersCopyTitle}</ModalHeader>
                <ModalBody>
                    <p>{i18n.usersCopyBody.replace('%1', props.userName)}</p>
                    <Form
                        stacked
                        formData={copyForm}
                        onChange={(formValues, formValid) => {
                            setFormCopyValues(formValues);
                            setFormCopyValid(formValid);
                        }}
                        onValid={formValid => setFormCopyValid(formValid)}
                        values={formCopyValues}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="danger"
                        disabled={!formCopyValid}
                        onClick={() => dispatch(actions.users.copyRights(props.userId, formCopyValues.toUser, formCopyValues.replace))}
                    >{i18n.usersCopySure}</Button>
                    <Button color="info" onClick={() => setShowCopy(false)}>{i18n.buttonCancel}</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

CopyRights.propTypes = {
    userId:   PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired
};

export default CopyRights;