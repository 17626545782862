import * as actionTypes from "../actionTypes";
import {runActionMap}   from "../reducerFunctions";

const initialState = {
    messageList: []
};

const errorPush   = (state, action) => ({
    ...state,
    messageList: state.messageList.concat({id: Date.now(), message: action.message, type: "danger"})
});
const successPush = (state, action) => ({
    ...state,
    messageList: state.messageList.concat({id: Date.now(), message: action.message, type: "success"})
});
const infoPush    = (state, action) => ({
    ...state,
    messageList: state.messageList.concat({id: Date.now(), message: action.message, type: "info"})
});

const messageRemove = (state, action) => ({
    ...state,
    messageList: state.messageList.filter(item => item.id !== action.id)
});

const actionMap       = {
    [actionTypes.ERROR_PUSH]:     errorPush,
    [actionTypes.SUCCESS_PUSH]:   successPush,
    [actionTypes.INFO_PUSH]:      infoPush,
    [actionTypes.MESSAGE_REMOVE]: messageRemove
};
const messagesReducer = (state = initialState, action) => runActionMap(state, action, actionMap);

export default messagesReducer;
