import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import AutoWrapInput from "../../InputContainers/AutoWrapInput";
import {deepCompare} from "../../../../variables/tools";
import i18n from "../../../../i18n";
import * as actions from "../../../../store/actions";
import {useDispatch, useSelector} from "react-redux";

const Affiliate = props => {
    const dispatch = useDispatch();
    const affiliates    = useSelector(state => state.affiliates.affiliates);
    const loading  = useSelector(state => state.affiliates.loading);
    useEffect(() => {dispatch(actions.affiliates.loadIfNeeded())}, [dispatch]);

    let affiliateOptions = [];
    if (!loading && affiliates) {
        affiliateOptions = affiliates.map(affiliate => ({value: affiliate.uuid, label: affiliate.name}));
        if (props.settings.hideAffiliate) {
            affiliateOptions = affiliateOptions.filter(affiliate => affiliate.value !== props.settings.hideAffiliate);
        }
    }

    return (
        <AutoWrapInput name={props.name} settings={props.settings} stacked={props.stacked}>
            {!loading && affiliates ? (
                <Select
                    {...props.settings.elementConfig}
                    name={props.name}
                    onChange={option => props.handlers.change(option.value, props.name)}
                    onFocus={() => props.handlers.focus(props.name, true)}
                    onBlur={() => props.handlers.focus(props.name, false)}
                    noOptionsMessage={() => i18n.noAffiliates}
                    classNamePrefix="react-select"
                    className="react-select"
                    value={affiliateOptions.filter(affiliate => affiliate.value === props.settings.value)}
                    options={affiliateOptions}
                    ref={props.innerRef}
                />
            ) : <p>{i18n.loading}</p>}
        </AutoWrapInput>
    );
};

Affiliate.propTypes = {
    name:     PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    handlers: PropTypes.object.isRequired,
    innerRef: PropTypes.object,
    stacked:  PropTypes.bool
};

export default React.memo(Affiliate, (prevProps, nextProps) => (
    prevProps.settings.value === nextProps.settings.value &&
    prevProps.settings.focused === nextProps.settings.focused &&
    prevProps.settings.valid === nextProps.settings.valid &&
    deepCompare(prevProps.settings.options, nextProps.settings.options)
));
