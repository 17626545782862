import React                                        from 'react';
import PropTypes                                    from 'prop-types';
import {useDispatch}                                from "react-redux";

import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Button}                                     from "../../../components";

import i18n                                         from "../../../i18n";
import * as actions                                 from "../../../store/actions";

const DeleteSure = props => {
    const dispatch      = useDispatch();
    const deleteHandler = () => {
        dispatch(actions.calendars.remove(props.caretaker, props.itemType, props.item.uuid));
        props.hide();
    };

    if(!props.item) {
        return null;
    }

    let itemName = props.item.message;
    switch(props.itemType) {
        case "birthdays":
            itemName = props.item.name;
            break;
        case "tvshows":
            itemName = props.item.showName;
            break;
        default:
    }

    return (
        <Modal isOpen={props.open} toggle={props.hide} className="text-center">
            <ModalHeader className="justify-content-center uppercase title" tag="h4">{i18n.calendarDeleteTitle}</ModalHeader>
            <ModalBody><p>{(props.body ? props.body : i18n.calendarDeleteBody).replace('%1', itemName)}</p></ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={deleteHandler}>{i18n.calendarDeleteItem}</Button>
                <Button color="info" onClick={props.hide}>{i18n.buttonCancel}</Button>
            </ModalFooter>
        </Modal>
    );
};

DeleteSure.propTypes = {
    open:      PropTypes.bool,
    hide:      PropTypes.func.isRequired,
    body:      PropTypes.string,
    caretaker: PropTypes.string,
    item:      PropTypes.object,
    itemType:  PropTypes.string
};

export default DeleteSure;