// React
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

// Tools
import i18n from "../../../../../i18n";
import * as actions from "../../../../../store/actions";

// Components
import {Form, Button, Spinner, BottomBar} from "../../../../../components";
import {TitleWrapper} from "../../../../../contexts/TitleProvider";

const AddCaretakersToGroup = props => {
    const dispatch = useDispatch();
    const groups   = useSelector(state => state.groups.groups);
    const saving   = useSelector(state => state.groups.saving);

    const [formValues, setFormValues] = useState({});
    const [formValid, setFormValid]   = useState(false);
    const handleChange                = (formValues, formValid) => {
        setFormValues(formValues);
        setFormValid(formValid);
    };
    const handleValid                 = formValid => setFormValid(formValid);

    useEffect(() => {
        dispatch(actions.groups.loadIfNeeded());
    }, [dispatch]);

    let groupId = props.match.params.group;
    if (groupId.length !== 32) {
        props.push("/admin/groups");
        return null;
    }
    let group = null;
    if (groups) {
        group = groups.find(obj => obj.uuid === groupId);
    }

    const addCaretakersToGroupForm = {
        caretakers: {
            label:   i18n.caretakers,
            type:    "caretakers",
            exclude: group ? group.caretakers : []
        }
    };

    return (
        <TitleWrapper title={i18n.groupsCaretakersAddTitle.replace("%1", group ? group.name : i18n.unknown)}>
            <div>
                <div className="content">
                    <Form
                        formData={addCaretakersToGroupForm}
                        values={formValues}
                        onChange={handleChange}
                        onValid={handleValid}
                        stacked
                    />
                    <Button color="info" round block disabled={!formValid}
                            onClick={() => dispatch(actions.groups.addCaretakers(groupId, formValues.caretakers))}>
                        {i18n.groupsCaretakersAdd}
                    </Button>
                    {saving && <Spinner/>}
                    <BottomBar backButton/>
                </div>
            </div>
        </TitleWrapper>
    );
};

export default AddCaretakersToGroup;
