// React
import React, {useEffect, useState} from "react";

// Redux
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../../store/actions";

// Tools
import i18n from "../../../i18n";

// Components
import {Button, Form, BottomBar, Spinner, Tour, ButtonCrusher} from "../../../components";
import {TitleWrapper} from "../../../contexts/TitleProvider";
import {getPageHelpSteps} from "../../../help/tools";
import css from "./ScreenStatus.module.css";
import withCaretakers from "../../../hoc/withCaretakers";

const accountForm = {
    name: {
        label:     i18n.caretakerName,
        className: css.tourName
    }
};

const ScreenStatus = props => {
    const dispatch = useDispatch();
    const loading  = useSelector(state => state.auth.loading);

    const [helpOpen, setHelpOpen] = useState(false);
    const doHelp                  = () => setHelpOpen(true);
    const closeHelp               = () => setHelpOpen(false);

    const [formValues, setFormValues] = useState({});
    const [formValid, setFormValid]   = useState(false);
    const handleChange                = (formValues, formValid) => {
        setFormValues(formValues);
        setFormValid(formValid);
    };
    const handleSubmit                = () => formValid && dispatch(actions.caretakers.saveMachineName(props.caretakerUuid, props.match.params.screen, formValues));
    useEffect(() => {
        if (props.caretaker && props.caretaker.machines) {
            const machine = props.caretaker.machines.find(machine => machine.serial_no === props.match.params.screen);
            setFormValues({name: machine.name});
        }
    }, [props.caretaker, props.match]);

    if (!props.caretaker || props.loading) {
        return <Spinner/>;
    }

    if (
        !props.caretaker.machines ||
        props.caretaker.machines.length === 0 ||
        !props.caretaker.machines.find(machine => machine.serial_no === props.match.params.screen)
    ) {
        props.history.push("/caretakers/status/" + props.caretakerUuid);
        return <Spinner/>;
    }
    const machine = props.caretaker.machines.find(machine => machine.serial_no === props.match.params.screen);

    return (
        <TitleWrapper title={props.caretaker.name + " - " + machine.name}>
            <Tour
                isOpen={helpOpen}
                onRequestClose={closeHelp}
                steps={getPageHelpSteps("caretakerScreenStatus")}
            />
            <div>
                <div className="content-form">
                    <Form
                        inlineSelect
                        formData={accountForm}
                        values={formValues}
                        onChange={handleChange}
                        onValid={setFormValid}
                        stacked
                    />
                    <ButtonCrusher width={74} topSpacer={25}>
                        <Button
                            marginTop
                            upper
                            block
                            round
                            color="info"
                            disabled={!formValid}
                            onClick={handleSubmit}>{i18n.buttonSave}</Button>
                    </ButtonCrusher>
                    <BottomBar
                        backButton
                        backUrl={"/caretakers/status/" + props.caretakerUuid}
                        onHelp={doHelp}
                        onCloseHelp={closeHelp}
                        helpOpen={helpOpen}
                    />
                </div>
                {loading && <Spinner/>}
            </div>
        </TitleWrapper>
    );
}

export default withCaretakers(React.memo(ScreenStatus));
