import React from 'react';
import PropTypes from 'prop-types';

const CardActions = props => (
    <div className={(props.dontMoveToBottom ? "nomove-bottom-bar " : "bottom-bar ") + "clearfix"}>
        {props.children}
    </div>
);

CardActions.propTypes = {
    dontMoveToBottom: PropTypes.bool
};

export default CardActions;