import React from "react";

const PanelHeader = props => (
    <div className={"panel-header" + (props.size !== undefined ? " panel-header-" + props.size : "")}>
        {props.children}
        {(!props.children || props.children.length === 0) && <div className="panel-overlay" />}
    </div>
);

export default React.memo(PanelHeader);
