// React
import React from "react";
import PropTypes from "prop-types";
// Tools
import i18n from "../../../i18n";
import moment from "moment";
// Components
import {Form, Spinner} from "../../../components";
import css from "./EditBirthday.module.css";

const EditBirthday = props => {
    if (!props.caretaker) {
        return <Spinner/>;
    }

    const today    = moment();
    const editForm = {
        name:       {
            label:     i18n.calendarEditBirthdayName,
            className: css.tourName
        },
        birthday:   {
            label:        i18n.calendarEditBirthdayBirthday,
            className:    css.tourBirthday,
            type:         "date",
            defaultValue: props.newDate ? moment(props.newDate, "YYYY-MM-DD").subtract(1, "years") : moment(),
            validDates:   date => date.isSameOrBefore(today, "day")
        },
        showAge:    {
            label:      i18n.calendarEditBirthdayShowAge,
            labelValue: [i18n.calendarEditBirthdayShowAgeYes, i18n.calendarEditBirthdayShowAgeNo],
            className:  css.tourShowAge,
            type:       "switch",
            defaultValue: props.caretaker.settings.defaultShowAge,
            noLabel:    true
        },
        showPhone:  {
            label:      i18n.calendarEditBirthdayShowPhone,
            labelValue: [i18n.calendarEditBirthdayShowPhoneYes, i18n.calendarEditBirthdayShowPhoneNo],
            className:  css.tourShowPhone,
            type:       "switch",
            defaultValue: props.caretaker.settings.defaultShowPhone,
            noLabel:    true
        },
        phone:      {
            label:     i18n.calendarEditBirthdayPhoneNumber,
            className: css.tourPhoneNumber,
            disabled:  form => !form.showPhone.value
            /*show:      form => form.showPhone.value*/
        },
        showBefore: {
            label:        i18n.calendarEditBirthdayShowBefore,
            className:    css.tourShowBefore,
            defaultValue: props.caretaker.settings.defaultBirthdayShowBefore,
            type:         "select",
            options:      i18n.calendarRecurrences.showBirthdayBefore
        }
    };

    return <Form inlineSelect formData={editForm} values={props.item} onChange={props.setValues} onValid={props.setValid}/>;
}

EditBirthday.propTypes = {
    item:      PropTypes.object,
    caretaker: PropTypes.object,
    setValues: PropTypes.func.isRequired,
    setValid:  PropTypes.func.isRequired
};

export default EditBirthday;
