import moment from 'moment-timezone';
import * as actionTypes from "../actionTypes";
import {runActionMap} from "../reducerFunctions";

const initialState = {
    loading:       false,
    calendarsFor:  null,
    calendars:     null,
    loadedAt:      null,
    saving:        false,
    saveSuccess:   false,
    skipping:      false,
    skipSuccess:   false,
    removing:      false,
    removeSuccess: false
};

const load        = (state, action) => ({...state, loading: true, calendarsFor: action.forGroup, calendars: null, loadedAt: null});
const loadSuccess = (state, action) => ({...state, loading: false, calendarsFor: action.forGroup, calendars: action.calendars, loadedAt: moment()});
const loadFail    = (state, action) => ({...state, loading: false});

const saveAppointment = (state, action) => ({...state, saving: true, saveSuccess: false});
const saveBirthday    = (state, action) => ({...state, saving: true, saveSuccess: false});
const saveReminder    = (state, action) => ({...state, saving: true, saveSuccess: false});
const saveTvShow      = (state, action) => ({...state, saving: true, saveSuccess: false});
const saveSuccess     = (state, action) => ({...state, saving: false, saveSuccess: true});
const saveFail        = (state, action) => ({...state, saving: false, saveSuccess: false});
const clearSave       = (state, action) => ({...state, saving: false, saveSuccess: false});

const skip        = (state, action) => ({...state, skipping: true, skipSuccess: false});
const unskip      = (state, action) => ({...state, skipping: true, skipSuccess: false});
const skipSuccess = (state, action) => ({...state, skipping: false, skipSuccess: true});
const skipFail    = (state, action) => ({...state, skipping: false, skipSuccess: false});
const clearSkip   = (state, action) => ({...state, skipping: false, skipSuccess: false});

const remove        = (state, action) => ({...state, removing: true, removeSuccess: false});
const removeSuccess = (state, action) => ({...state, removing: false, removeSuccess: true});
const removeFail    = (state, action) => ({...state, removing: false, removeSuccess: false});
const clearRemove   = (state, action) => ({...state, removing: false, removeSuccess: false});

const clear = (state, action) => ({...state, loading: false, calendarsFor: null, calendars: null});

const actionMap        = {
    [actionTypes.GROUP_CALENDARS_LOAD]:            load,
    [actionTypes.GROUP_CALENDARS_LOAD_SUCCESS]:    loadSuccess,
    [actionTypes.GROUP_CALENDARS_LOAD_FAIL]:       loadFail,
    [actionTypes.GROUP_CALENDARS_UNLOAD]:          clear,
    [actionTypes.GROUP_CALENDAR_SAVE_APPOINTMENT]: saveAppointment,
    [actionTypes.GROUP_CALENDAR_SAVE_BIRTHDAY]:    saveBirthday,
    [actionTypes.GROUP_CALENDAR_SAVE_REMINDER]:    saveReminder,
    [actionTypes.GROUP_CALENDAR_SAVE_TVSHOW]:      saveTvShow,
    [actionTypes.GROUP_CALENDAR_SAVE_SUCCESS]:     saveSuccess,
    [actionTypes.GROUP_CALENDAR_SAVE_FAIL]:        saveFail,
    [actionTypes.GROUP_CALENDAR_SAVE_CLEAR]:       clearSave,
    [actionTypes.GROUP_CALENDAR_SKIP]:             skip,
    [actionTypes.GROUP_CALENDAR_UNSKIP]:           unskip,
    [actionTypes.GROUP_CALENDAR_SKIP_SUCCESS]:     skipSuccess,
    [actionTypes.GROUP_CALENDAR_SKIP_FAIL]:        skipFail,
    [actionTypes.GROUP_CALENDAR_SKIP_CLEAR]:       clearSkip,
    [actionTypes.GROUP_CALENDAR_REMOVE]:           remove,
    [actionTypes.GROUP_CALENDAR_REMOVE_SUCCESS]:   removeSuccess,
    [actionTypes.GROUP_CALENDAR_REMOVE_FAIL]:      removeFail,
    [actionTypes.GROUP_CALENDAR_REMOVE_CLEAR]:     clearRemove,
    [actionTypes.GROUP_CALENDAR_LIST_CLEAR]:       clear
};
const calendarsReducer = (state = initialState, action) => runActionMap(state, action, actionMap);

export default calendarsReducer;
