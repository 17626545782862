import * as actionTypes from "../actionTypes";
import moment from 'moment-timezone';
import {runActionMap, load, loadFail, save, saveDone, saveFail, resetError} from "../reducerFunctions";

const initialState = {
    loading:            false,
    saving:             false,
    error:              null,
    distributors:       null,
    distributorsLoaded: null
};

// Load reducers
const loadSuccess = (state, action) => ({...state, loading: false, error: null, distributors: action.distributors, distributorsLoaded: moment()});

const actionMap           = {
    [actionTypes.DISTRIBUTORS_LOAD]:           load,
    [actionTypes.DISTRIBUTORS_LOAD_SUCCESS]:   loadSuccess,
    [actionTypes.DISTRIBUTORS_LOAD_FAIL]:      loadFail,
    [actionTypes.DISTRIBUTORS_SAVE]:           save,
    [actionTypes.DISTRIBUTORS_SAVE_SUCCESS]:   saveDone,
    [actionTypes.DISTRIBUTORS_SAVE_FAIL]:      saveFail,
    [actionTypes.DISTRIBUTORS_REMOVE]:         save,
    [actionTypes.DISTRIBUTORS_REMOVE_SUCCESS]: saveDone,
    [actionTypes.DISTRIBUTORS_REMOVE_FAIL]:    saveFail,
    [actionTypes.DISTRIBUTORS_RESET_ERROR]:    resetError
};
const distributorsReducer = (state = initialState, action) => runActionMap(state, action, actionMap);

export default distributorsReducer;
