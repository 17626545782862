import React from "react";
import i18n from "../../i18n";
import css from "./tableColumns.module.css";
import {Link} from "react-router-dom";
import {ResponsiveText} from "../../components";
import {standardColumn, defaultColumns, accessorColumn} from "./funcs";

export const groupColumns = {
    name:       standardColumn("name", i18n.groupsTableName, {className: css.name, togglable: false}),
    caretakers: accessorColumn(
        "caretakers",
        <ResponsiveText long={i18n.groupsTableCaretakersLong} short={i18n.groupsTableCaretakers} inline/>,
        d => <Link to={"/admin/groups/caretakers/" + d.uuid}>{d.caretakers.length}</Link>,
        {
            className:       css.counter,
            headerClassName: css.counterHeader,
            minWidth:        40,
            maxWidth:        100
        }
    )
};

export const groupColumnsDefault = (actions = null, selector = null) => defaultColumns(
    [
        groupColumns.name,
        groupColumns.caretakers
    ], actions, "uuid", selector
);
