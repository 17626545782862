export default {
    helpSubtitle: "How may we help you?",

    helpAddTitle:  "Create new help item",
    helpEditTitle: "Edit help item",

    helpLanguages: {
        nl: "Dutch",
        da: "Danish",
        de: "German",
        en: "English"
    },

    helpSelectLanguage: "Choose help language",
    helpAddItem:        "Add a new help item",
    helpViewItem:       "View help item",
    helpEditItem:       "Edit help item",
    helpEditItems:      "Edit help items",
    helpDeleteItem:     "Remove help item",

    helpItemLanguage:    "Language",
    helpItemTitle:       "Title",
    helpItemBody:        "Body",
    helpItemTags:        "Tags",
    helpItemPageBinding: "Page binding",
    helpItemSave:        "Save help item",
    helpNoPageBinding:   "No page binding",

    helpEditSuccess:          "Help item successfully saved",
    helpEditError:            "An error occurred while saving the help item.",
    helpEditErrorPageBinding: "There's already a help item with this page binding for this language.",
    helpRemoveSuccess:        "Help item successfully removed",
    helpRemoveError:          "An error occurred while removing the help item."
}
