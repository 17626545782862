import React, {useState} from "react";
import {useDispatch} from "react-redux";
import i18n from "../../i18n";
import {Button, Form, BottomBar, Tour, ButtonCrusher} from "../../components";
import * as actions from "../../store/actions";
import css from "./Contact.module.css";
import {TitleWrapper} from "../../contexts/TitleProvider";
import {getPageHelpSteps} from "../../help/tools";

const contactForm = {
    title: {
        label:     i18n.contactFormTitle,
        className: css.tourTitle
    },
    body:  {
        label:         i18n.contactFormBody,
        className:     css.tourBody,
        type:          "textarea",
        elementConfig: {
            rows: 8
        }
    }
};
const Contact     = () => {
    const dispatch = useDispatch();

    const [helpOpen, setHelpOpen] = useState(false);
    const doHelp                  = () => setHelpOpen(true);
    const closeHelp               = () => setHelpOpen(false);

    const [formValues, setFormValues] = useState({});
    const [formValid, setFormValid]   = useState(false);

    const handleChange = (formValues, formValid) => {
        setFormValues(formValues);
        setFormValid(formValid);
    };
    const handleSubmit = () => {
        if(!formValid) {
            dispatch(actions.messages.errorPush(i18n.errorForm));
            return;
        }

        dispatch(actions.contact.send(formValues));
    }

    return (
        <TitleWrapper title={i18n.contactTitle}>
            <Tour
                isOpen={helpOpen}
                onRequestClose={closeHelp}
                steps={getPageHelpSteps("contact")}
            />

            <div>
                <div className="content-form">
                    <Form formData={contactForm} values={formValues} onChange={handleChange} stacked/>
                    <ButtonCrusher width={77} topSpacer={25}>
                        <Button upper block round color="info" onClick={handleSubmit}>
                            {i18n.buttonSend}
                        </Button>
                    </ButtonCrusher>
                    <BottomBar backButton onHelp={doHelp} onCloseHelp={closeHelp} helpOpen={helpOpen}/>
                </div>
            </div>
        </TitleWrapper>
    );
};

export default Contact;
