import React from "react";
import {useDispatch} from 'react-redux';
import {Container, Col} from "reactstrap";
import * as actions from "../../store/actions";
import RegisterForm from "./Forms/RegisterForm";

const RegisterPage = props => {
    const dispatch = useDispatch();
    const doSignUp = (name, email, password) => dispatch(actions.auth.signup(name, email, password));

    return (
        <div>
            <div className="full-page-content">
                <div className="login-page">
                    <Container>
                        <Col lg={6} md={8} xs={12} className="ml-auto mr-auto">
                            <RegisterForm signUp={doSignUp} showLinks />
                        </Col>
                    </Container>
                </div>
            </div>
            <div
                className="full-page-background"
                style={{background: "linear-gradient(135deg, rgba(121,146,141,1) 0%, rgba(71,96,100,1) 100%)"}}
            />
        </div>
    );
};

export default RegisterPage;
