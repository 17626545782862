import React, {useContext, useEffect} from "react";
import PropTypes from "prop-types";

export const TitleContext  = React.createContext({
    title:    "",
    setTitle: () => {
    }
});
export const TitleProvider = TitleContext.Provider;
export const TitleConsumer = TitleContext.Consumer;

export const TitleWrapper = props => {
    const titleContext = useContext(TitleContext);
    useEffect(() => {
        if (titleContext.title !== props.title) {
            titleContext.setTitle(props.title);
        }
    }, [props.title, titleContext]);
    return props.children;
};

TitleWrapper.props = {
    title: PropTypes.string.isRequired
}