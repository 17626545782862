import React from 'react';
import {connect} from "react-redux";
import {push} from "connected-react-router";
import {omit} from "reactstrap/es/utils";
import * as actions from "../store/actions";
import {Spinner} from '../components';

const mapStateToProps    = state => ({
    pathname:     state.router.location.pathname,
    loading:      state.groups.loading,
    groups:       state.groups.groups,
    groupsLoaded: state.groups.groupsLoaded
});
const mapDispatchToProps = dispatch => ({
    push:       pathname => dispatch(push(pathname)),
    loadGroups: () => dispatch(actions.groups.load())
});

const withGroups = (WrappedComponent, failRedirect = "/groups") => {
    return connect(mapStateToProps, mapDispatchToProps)(class withGroups extends React.PureComponent {
        state = {
            uuid:  null,
            group: null
        };

        constructor(props) {
            super(props);

            let uuid = props.match.params.group;
            if (uuid.length !== 32) {
                props.push(failRedirect);
                return;
            }

            if (props.groupsLoaded) {
                this.state = {uuid, group: this.props.groups.find(obj => obj.uuid === uuid)};
            } else {
                if (!props.loading) {
                    props.loadGroups();
                }
                this.state.uuid = uuid;
            }
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            if (this.props === prevProps) {
                return;
            }

            // If the group store suddenly changed, update the details
            if (this.props.groups && this.props.groups !== prevProps.groups && this.props.groups.length > 0) {
                let uuid  = this.props.match.params.group;
                let group = this.props.groups.find(obj => obj.uuid === uuid);
                if (group) {
                    this.setState({group});
                } else {
                    // Unknown group, go back to group overview
                    this.props.push(failRedirect);
                }
            }
        }

        render() {
            return (
                <>
                    <WrappedComponent
                        groupUuid={this.state.uuid}
                        group={this.state.group}
                        loading={this.props.loading}
                        loaded={this.props.groupsLoaded}
                        {...omit(this.props, ["groups", "groupsLoaded", "loading", "pathname", "loadGroups"])} />
                    {!this.props.groupsLoaded && <Spinner/>}
                </>
            );
        }
    });
};

export default withGroups;