export default {
    loginTitle:           "Anmelden",
    loginTitleShort:      "Anmelden",
    loginTitleMark:       "A",
    loginEmail:           "E-Mail",
    loginError:           "Anmeldung fehlgeschlagen. Überprüfen Sie Ihre Anmeldedaten und die Internetverbindung und versuchen Sie es erneut.",
    loginDisabled:        "Ihr Konto wurde deaktiviert. Wenden Sie sich an das Helpdesk, um es erneut zu aktivieren.",
    loginButton:          "Anmelden",
    loginPassword:        "Passwort",
    loginSignUp:          "Konto erstellen",
    loginForgot:          "Passwort vergessen",
    loginAlreadyLoggedIn: "Sie sind bereits eingeloggt. Wenn Sie sich unter einem anderen Konto anmelden möchten, melden Sie sich zuerst ab.",

    signUpEmail:                       "E-Mail",
    signUpPassword1:                   "Passwort",
    signUpPassword2:                   "Passwort wiederholen",
    signUpSubmitButton:                "Konto erstellen",
    signUpBackToLogin:                 "Zurück zum Login",
    signUpTitle:                       "Konto erstellen",
    signUpTitleShort:                  "Anmelden",
    signUpTitleMark:                   "A",
    signUpPasswordDontMatch:           "Die Passwörter stimmen nicht überein",
    signUpPasswordTooShort:            "Das Passwort ist zu kurz, es sind mindestens 7 Zeichen erforderlich",
    signUpPasswordMinimumRequirements: "Das Passwort muss mindestens einen Kleinbuchstaben, einen Großbuchstaben und eine Zahl enthalten",
    signUpSuccess:                     "Sie haben sich erfolgreich registriert",
    signUpError:                       "Beim Erstellen Ihres Kontos ist ein Fehler aufgetreten.",
    signUpErrorExists:                 "Erstellen eines Kontos fehlgeschlagen. Ein Konto mit dieser E-Mail-Adresse ist bereits vorhanden.",
    signUpErrorPassword:               "Das Passwort ist nicht stark genug. Befolgen Sie die Anweisungen für ein sicheres Passwort.",
    signUpCallToAction:                "Noch keinen Account?",

    forgotTitle:         "Passwort vergessen",
    forgotBody:          "Geben Sie Ihre E-Mail-adresse ein, um Ihr Passwort zurückzusetzen.",
    forgotEmail:         "E-mail",
    forgotSubmitButton:  "Passwort zurücksetzen",
    forgotSubmitFailed:  "Das Passwort konnte nicht zurückgesetzt werden.",
    forgotSubmitSuccess: "Eine E-Mail wurde gesendet",

    verifyTitle:               "Bestätigen Sie die E-Mail-Adresse",
    verifyBodyLoading:         "Ihre E-Mail-Adresse wird bestätigt...",
    verifyBodyVerified:        "Ihre E-Mail-Adresse wurde bestätigt. Sie können sich jetzt anmelden.",
    verifyBodyAlreadyVerified: "Ihre E-Mail-Adresse wurde bereits bestätigt. Sie können sich jetzt anmelden.",
    verifyBodyUnknown:         "Dieser Bestätigungsschlüssel ist unbekannt.",

    resetPasswordTitle:   "Passwort zurücksetzen",
    resetPasswordBody:    "Wählen Sie ein neues Passwort, um sich anzumelden.",
    resetPassword1:       "Passwort",
    resetPassword2:       "Passwort wiederholen",
    resetPasswordSubmit:  "Passwort festlegen",
    resetPasswordSuccess: "Ihr Passwort wurde erfolgreich festgelegt. Sie können sich jetzt mit diesem neuen Passwort anmelden.",
    resetPasswordFailed:  "Beim Festlegen Ihres Passworts ist ein Fehler aufgetreten.",
    resetPasswordUnknown: "Dieser Schlüssel zum Zurücksetzen des Passworts ist unbekannt.",

    invitationTitle:           "Konto auf Einladung erstellen",
    invitationTitleExisting:   "Pflegebedürftige per Einladung hinzufügen",
    invitationBody:            "Sie wurden von %inviteBy% eingeladen, auf die Spiqle Agenda der folgenden Leistungsempfänger / Leistungsempfängergruppen zuzugreifen:",
    invitationSubBody:         "Sie müssen ein Konto erstellen, um Zugriff zu erhalten. Wenn Sie bereits ein Konto haben, können Sie sich anmelden.",
    invitationSubBodyExisting: "Um Zugriff zu erhalten, wählen Sie 'Akzeptieren'. Wenn Sie für diese Einladung ein anderes Konto verwenden möchten, melden Sie sich zuerst von diesem Konto ab.",
    invitationTypeMapping:     {
        caretaker:   "Pflegeempfänger",
        group:       "Gruppe",
        affiliate:   "Partner",
        distributor: "Distributor",
        default:     "Unbekannt"
    },
    invitationButtonAccept:    "Akzeptieren",

    invitationErrorUnknownToken: "Deze uitnodigingslink is niet geldig.",
    invitationErrorTokenUsed:    "Deze uitnodigingslink is al gebruikt.",
    invitationErrorTokenExpired: "Deze uitnodigingslink is verlopen, vraag degene die U heeft uitgenodigd om deze opnieuw te versturen.",
    invitationErrorTokenIsYours: "Deze uitnodigingslink is door uzelf verzonden. Om deze te gebruiken, log eerst uit met dit account.",
    invitationErrorGeneric:      "Er is een fout opgetreden bij het gebruik van de uitnodigingslink.",

    accountTitle:     "Kontodetails ändern",
    accountName:      "Name",
    accountAddress:   "Adresse",
    accountZipcode:   "Postleitzahl",
    accountCity:      "Wohnort",
    accountState:     "Provinz",
    accountCountry:   "Land",
    accountLanguage:  "Sprache",
    accountLanguages: {
        auto:    "Automatisch",
        nl:      "Nederlands",
        en:      "English",
        da:      "Dansk",
        de:      "Deutsch",
        "de-CH": "Schweiz Deutsch"
    },
    accountFontSize:  "Schriftgröße",
    accountFontSizes: {
        14: "Klein",
        18: "Mittel",
        21: "Groß"
    },

    accountDayDisplay: "Standard Tagesansicht",
    accountDayOptions: {
        overview: "Übersicht",
        timeline: "Zeitleiste"
    },

    accountCalendarDisplay: "Standard Kalenderansicht",
    accountCalendarOptions: {
        overview: "Übersicht",
        calendar: "Kalender"
    },

    accountAdditionalActions: "Account Einstellungen",

    changePasswordTitle:              "Passwort ändern",
    changePasswordOldPassword:        "Aktuelles Passwort",
    changePasswordNewPassword:        "Neues Passwort",
    changePasswordRepeatPassword:     "Wiederholen Sie das neue Passwort",
    changePasswordAction:             "Passwort ändern",
    changePasswordInvalidOldPassword: "Das aktuelle Passwort ist nicht korrekt. Geben Sie das richtige Passwort ein und versuchen Sie es erneut.",
    changePasswordNotStrong:          "Das Passwort ist nicht sicher genug. Es muss mindestens 7 Zeichen lang sein und mindestens einen Kleinbuchstaben, einen Großbuchstaben und eine Zahl enthalten.",
    changePasswordSuccessful:         "Ihr Passwort wurde erfolgreich geändert.",

    changeEmailTitle: "E-mail ändern",

    errorAccountLoad:   "Beim Laden Ihrer Kontodaten ist ein Fehler aufgetreten",
    errorAccountSave:   "Beim Speichern Ihrer Kontoinformationen ist ein Fehler aufgetreten",
    successAccountSave: "Ihre Kontodaten wurden erfolgreich gespeichert"
};