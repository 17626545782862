import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import i18n from "../../i18n";
import * as actions from "../../store/actions";
import FrontPageForm from "../../components/Layout/FrontPageForm/FrontPageForm";
//import {isMobile} from "../../variables/tools";

const resetForm = {
    password:       {
        label:                   i18n.resetPassword1,
        elementConfig:           {type: "password", placeholder: ""},
        validation:              {required: true, minLength: 7, func: value => (/[A-Z]/.test(value) && /[a-z]/.test(value) && /[0-9]/.test(value))},
        validationErrors:        {minLength: i18n.signUpPasswordTooShort, func: i18n.signUpPasswordMinimumRequirements},
        showValidationErrors:    true,
//        validationErrorLocation: isMobile ? "bottom" : "left",
        fullRevalidation:        true
    },
    repeatPassword: {
        label:                   i18n.resetPassword2,
        elementConfig:           {type: "password", placeholder: ""},
        validation:              {required: true, func: (value, form) => (form && form.password.value === value)},
        validationErrors:        {func: i18n.signUpPasswordDontMatch},
        showValidationErrors:    true,
//        validationErrorLocation: isMobile ? "bottom" : "left",
        fullRevalidation:        true
    }
};

const ResetPasswordPage = props => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.auth.checkPasswordToken(props.match.params.token));
    }, [dispatch, props.match.params.token]);

    const doReset = values => dispatch(actions.auth.resetPassword(props.match.params.token, values.password));

    return <FrontPageForm
        body={i18n.resetPasswordBody}
        submitButton={i18n.resetPasswordSubmit}
        onSubmit={doReset}
        formData={resetForm}
    />;
};

export default ResetPasswordPage;
