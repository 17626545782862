import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import AutoWrapInput from "../../InputContainers/AutoWrapInput";
import {deepCompare} from "../../../../variables/tools";
import i18n from "../../../../i18n";
import * as actions from "../../../../store/actions";
import {useDispatch, useSelector} from "react-redux";

const User = props => {
    const dispatch = useDispatch();
    const users    = useSelector(state => state.users.users);
    const loading  = useSelector(state => state.users.loading);
    useEffect(() => {dispatch(actions.users.loadIfNeeded())}, [dispatch]);

    let userOptions = [];
    if (!loading && users) {
        userOptions = users.map(user => ({value: user.uuid, label: user.name}));
        if (props.settings.hideUser) {
            userOptions = userOptions.filter(user => user.value !== props.settings.hideUser);
        }
    }

    return (
        <AutoWrapInput name={props.name} settings={props.settings} stacked={props.stacked}>
            {!loading && users ? (
                <Select
                    {...props.settings.elementConfig}
                    name={props.name}
                    onChange={option => props.handlers.change(option.value, props.name)}
                    onFocus={() => props.handlers.focus(props.name, true)}
                    onBlur={() => props.handlers.focus(props.name, false)}
                    noOptionsMessage={() => i18n.noUsers}
                    classNamePrefix="react-select"
                    className="react-select"
                    value={userOptions.filter(user => user.value === props.settings.value)}
                    options={userOptions}
                    ref={props.innerRef}
                />
            ) : <p>{i18n.loading}</p>}
        </AutoWrapInput>
    );
};

User.propTypes = {
    name:     PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    handlers: PropTypes.object.isRequired,
    innerRef: PropTypes.object,
    stacked:  PropTypes.bool
};

export default React.memo(User, (prevProps, nextProps) => (
    prevProps.settings.value === nextProps.settings.value &&
    prevProps.settings.focused === nextProps.settings.focused &&
    prevProps.settings.valid === nextProps.settings.valid &&
    deepCompare(prevProps.settings.options, nextProps.settings.options)
));
