import React from 'react';
import i18n from "../../../i18n";
import {Form, Button} from "../../../components";


const Link = props => {
    const handleChange = formValues => props.onChange({uuid: props.uuid, type: "link", url: formValues.url});
    return (
        <div>
            <h6>Link</h6>
            <Form formData={{url: {label: "URL"}}} values={{url: props.url}} onChange={handleChange} stacked/>
            <Button round color="danger" onClick={props.onRemove}>{i18n.photoDelete}</Button>
        </div>
    );
};

export default Link;