import React from 'react';
import PropTypes from 'prop-types';
import {ImageUpload} from "../../../components";
import {apiGetFullPath, apiGetToken} from "../../../api";

const Image = props => {
    const handleFile = file => props.onChange({uuid: props.uuid, type: "image", file});
    const handleRemove = () => props.onRemove();

    return (
        <div>
            <h6>Image</h6>
            <ImageUpload
                onSelect={handleFile}
                onRemove={handleRemove}
                existingImage={props.url ? apiGetFullPath("help/getimage?uuid=" + props.url + "&token=" + apiGetToken()) : null}
            />
        </div>
    );
};

Image.propTypes = {
    id: PropTypes.string.isRequired,
    file: PropTypes.object
};

export default Image;