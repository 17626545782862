import LocalizedStrings from 'react-localization';

import moment from 'moment-timezone';
import 'moment/locale/nl';
import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/en-gb';

import checkI18N from "./check_i18n";

import en from "./en";
import nl from "./nl";
import da from "./da";
import de from "./de";

const language        = new LocalizedStrings({en, nl, da, de});
const currentLanguage = localStorage.getItem("language");
if (currentLanguage && currentLanguage !== "auto") {
    language.setLanguage(currentLanguage);
}

// In debug mode, check the languages for availability of all strings
if (process && process.env && process.env.NODE_ENV && process.env.NODE_ENV === "development") {
    checkI18N({en, nl, de, da});
}

moment.defaultFormat = language.dateFormat;
moment.locale(language.getLanguage());

export default language;
export const setLanguage = lang => {
    const currentLang = language.getLanguage();
    if (lang === "auto") {
        language.setLanguage(language.getInterfaceLanguage());
    }
    else {
        language.setLanguage(lang);
    }
    moment.defaultFormat = language.dateFormat;
    moment.locale(lang);
    return currentLang !== language.getLanguage();
};

