import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import PropTypes from 'prop-types';

import i18n from "../../../../../i18n";
import * as reduxActions from "../../../../../store/actions";

import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Button} from "../../../../../components";

const Delete = props => {
    const dispatch                    = useDispatch();
    const [showDelete, setShowDelete] = useState(false);

    return (
        <>
            <Button color="danger" block round simple onClick={() => setShowDelete(true)}>
                {i18n.usersActionsDeleteButton}
            </Button>
            <Modal isOpen={showDelete} toggle={() => setShowDelete(!showDelete)} className="text-center">
                <ModalHeader className="justify-content-center uppercase title" tag="h4">{i18n.usersRemoveTitle}</ModalHeader>
                <ModalBody><p>{i18n.usersRemoveBody.replace('%1', props.userName)}</p></ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={() => dispatch(reduxActions.users.remove(props.userId))}>{i18n.usersRemoveSure}</Button>
                    <Button color="info" onClick={() => setShowDelete(false)}>{i18n.buttonCancel}</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

Delete.propTypes = {
    userId:   PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired
};

export default Delete;