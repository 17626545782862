import i18n from "../../i18n";
import css from "./tableColumns.module.css";
import {accessorColumn, defaultColumns} from "./funcs";

export const calendarOverviewColumns = {
    startTime: accessorColumn("start", i18n.caretakersOverviewTime, d => {
        if (d.birthday || (d.currentDate.start === "00:00" && d.currentDate.end === "23:59")) {
            return i18n.calendarWholeDay;
        }
        return d.currentDate.start + " - " + d.currentDate.end;
    }, {width: 180, sortable: false}),
    name:      accessorColumn("name", i18n.calendarEditBirthdayName, d => {
        switch(d.itemType) {
            case "birthday":
                return i18n.calendarTypeSingular.birthday + ": " + d.name;
            case "appointment":
                return i18n.calendarTypeSingular.appointment + ": " + d.message;
            case "reminder":
                return i18n.calendarTypeSingular.reminder + ": " + d.message;
            case "tvshow":
                return i18n.calendarTypeSingular.tvshow + ": " + d.showName;
            default:
                return "";

        }
    }, {className: css.name, sortable: false}),
};

export const calendarOverviewColumnsDefault = (actions = null, selector = null) => defaultColumns(
    [
        calendarOverviewColumns.startTime,
        calendarOverviewColumns.name
    ], actions, "uuid", selector
);