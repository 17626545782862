import {apiGetCall, apiPostCall} from "./index";
import i18n from "../i18n";

export const login              = (email, password, inviteToken) => apiPostCall("/auth/login", {email, password, inviteToken}, false);
export const signup             = (name, email, password, inviteToken) => apiPostCall(
    "/auth/signup",
    {name, email, password, inviteToken, language: i18n.getLanguage()},
    false
);
export const getAccountData     = () => apiGetCall("/auth/account");
export const saveAccountData    = userData => apiPostCall("/auth/account", {...userData});
export const forgotPassword     = email => apiPostCall("/auth/forgot", {email, language: i18n.getLanguage()}, false);
export const resetPassword      = (token, password) => apiPostCall("/auth/resetpassword", {token, password, language: i18n.getLanguage()}, false);
export const checkPasswordToken = token => apiPostCall("/auth/checkpasswordtoken", {token}, false);
export const verifyEmail        = token => apiPostCall("/auth/verify", {token}, false);
export const loadInviteData     = token => apiGetCall("/auth/invitation", {token}, false);
export const acceptInvite       = token => apiPostCall("/caretakers/acceptinvitation", {token});
export const changePassword     = (oldPassword, newPassword) => apiPostCall("/auth/changepassword", {oldPassword, newPassword});