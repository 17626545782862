import React from 'react';
import PropTypes from 'prop-types';

const Icon = props => {

    let classNames = ["now-ui-icons"];
    if (props.noDefault) {
        classNames.push(props.name);
    }
    else {
        classNames.push("icon-" + props.name);
    }

    if (props.color) {
        classNames.push("icon-color-" + props.color);
    }

    if (props.inLine) {
        classNames.push("icon-inline");
    }

    if (props.big) {
        let style = {
            display:      props.block ? "block" : "inline-block",
            fontSize:     "5em",
            marginBottom: props.block ? ".2em" : "inherit"
        };
        if (props.style) {
            style = {...style, ...props.style};
        }
        return (
            <div
                style={style}
                id={props.id}
                className={props.className}
            >
                <i className={classNames.join(" ")} title={props.title}/>
            </div>
        );
    }

    let style = {};
    if (props.style) {
        style = {...props.style}
    }
    if (props.size) {
        style["fontSize"] = props.size + "em"
    }

    return <i id={props.id} className={classNames.join(" ")} style={style} title={props.title}/>;
};

Icon.propTypes = {
    name:      PropTypes.oneOf([
        "tv",
        "media-player",
        "email-2",
        "calendar-2",
        "trash",
        "e-remove",
        "check",
        "world-pin",
        "home-3",
        "circle-10",
        "map",
        "e-add",
        "add-29",
        "office",
        "point-a",
        "reload",
        "bell",
        "c-info",
        "single-05",
        "a-edit",
        "settings",
        "magnifier",
        "settings-gear",
        "time-alarm",
        "left-arrow",
        "up-arrow",
        "right-arrow",
        "down-arrow",
        "button-pause",
        "button-play",
        "volume",
        "volume-mute",
        "slide-left",
        "menu-8",
        "agenda-bookmark",
        "pin-3",
        "multiple-11",
        "undo-25",
        "redo-26",
        "key",
        "voice-recognition",
        "pen",
        "telephone",
        "smart-house",
        "audio-jack",
        "signal",
        "server-rack",
        "2x-tap",
        "a-delete",
        "man-up",
        "a-add",
        "tag",
        "eye",
        "send-message",
        "cursor-menu",
        "c-question",
        "translation",
        "caps-small",
        "leave",
        "round-euro",
        "c-question-2",
        "c-remove",
        "hide",
        "arrow",
        "dropdown"
    ]).isRequired,
    color:     PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "primary-darker",
        "info-darker",
        "success-darker",
        "warning-darker",
        "danger-darker",
        "white",
        "black"
    ]),
    size:      PropTypes.number,
    title:     PropTypes.string,
    id:        PropTypes.string,
    noDefault: PropTypes.bool,
    inLine:    PropTypes.bool,
    block:     PropTypes.bool,
    big:       PropTypes.bool,
    style:     PropTypes.object
};

export default Icon;
