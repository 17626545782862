export default {
    machines:   "Machines",
    noMachines: "Geen machines",

    machinesSerialNumber: "Serie#",
    machinesSerialCheck:  "Check#",
    machinesMacAddress:   "MAC adres",
    machinesName:         "Naam",
    machinesNetwork:      "Netwerk",
    machinesIMSI:         "IMSI",
    machinesIMEI:         "IMEI",
    machinesOS:           "OS",
    machinesVersion:      "Versie",
    machinesLastUpdate:   "Laatste update",
    machinesLastBoot:     "Laatste boot",
    machinesLastIP:       "Laatste IP",
    machinesCreatedAt:    "Aangemaakt",
    machinesTemperature:  "Temperatuur",
    machinesLoadError:    "Er is een fout opgetreden bij het laden van de machine info",
    machinesCoupled:      "Gekoppeld",
    machinesCoupledTo:    "Gekoppeld aan",
    machinesUncoupled:    "Ontkoppeld",
    machinesDistributor:  "Distributeur",
    machinesAffiliate:    "Affiliate",
    machinesVirtual:      "Virtuele machine",
    machinesServer:       "Via CDS",
    machinesIP:           "Netwerk IP",

    machinesNotCoupled:    "Niet gekoppeld",
    machinesNoDistributor: "Geen distributeur",
    machinesNoAffiliate:   "Geen affiliate",

    machinesActions:                      "Acties",
    machinesActionsTitle:                 "Acties voor deze machine",
    machinesActionsInfo:                  "Info",
    machinesActionsSwap:                  "Swappen",
    machinesActionsUncouple:              "Ontkoppelen",
    machinesActionsClearUpdates:          "Alle updates verwijderen",
    machinesActionsClearUpdatesAndResend: "Alle updates verwijderen en inhoud opnieuw sturen",

    machinesNetworkFilterAll:       "Toon alle",
    machinesNetworkFilterEthernet:  "Ethernet",
    machinesNetworkFilterWiFi:      "WiFi",
    machinesNetworkFilterMobile:    "Mobiel",
    machinesNetworkFilterMobileLTE: "Mobiel - Alleen LTE",
    machinesNetworkFilterMobileGSM: "Mobiel - Alleen GSM",

    machineCoupleTitle:   "Nieuwe machine koppelen",
    machineCoupleBody:    "Selecteer de machine die u aan de zorgvrager met de naam '%1' wilt koppelen. Als u een machine kiest die al gekoppeld is, dan wordt deze ontkoppeld van de huidige zorgvrager en gekoppeld aan deze zorgvrager.",
    machineCoupleSure:    "Machine koppelen",
    machineCoupleSuccess: "De machine is succesvol gekoppeld",
    machineCoupleError:   "Er is een fout opgetreden bij het koppelen van de machine",

    machineSwapTitle:   "Machine swappen",
    machineSwapBody:    "Selecteer de machine die u wilt swappen met de machine met MAC adres '%1'. Als u een machine kiest die al gekoppeld is, dan wordt de inhoud van beide machines omgewisseld.",
    machineSwapSure:    "Machines swappen",
    machineSwapSuccess: "De machines zijn succesvol geswapped",
    machineSwapError:   "Er is een fout opgetreden bij het swappen van de machines",

    machineUncoupleTitle:   "Weet u zeker dat u deze machine wilt ontkoppelen?",
    machineUncoupleBody:    "Als u de machine met MAC adres '%1' ontkoppelt, wordt deze machine gereset en verschijnen er nieuwe koppelwoorden op het scherm. Dit is niet ongedaan te maken.",
    machineUncoupleSure:    "Machine ontkoppelen",
    machineUncoupleSuccess: "De machine is succesvol ontkoppeld",

    machineInfoTitle:               "Machine info",
    machineInfoDataHead:            "Machine gegevens voor %1",
    machineInfoCoupledHead:         "Koppel info",
    machineInfoCoupleEditCaretaker: "Bewerk deze zorgvrager",
    machineInfoCoupleShowMachines:  "Toon alle machines van deze zorgvrager",
    machineInfoUpdatesHead:         "Updates",

    machineUpdatesId:           "ID",
    machineUpdatesCommand:      "Commando",
    machineUpdatesDone:         "Uitgevoerd",
    machineUpdatesDoneYes:      "Update uitgevoerd",
    machineUpdatesDoneNo:       "Update niet uitgevoerd",
    machineUpdatesError:        "Fout tijdens uitvoeren",
    machineUpdatesErrorYes:     "Fout tijdens uitvoeren",
    machineUpdatesErrorNo:      "Geen fout tijdens uitvoeren",
    machineUpdatesFilename:     "Bestandsnaam",
    machineUpdatesDateUpdated:  "Geupdate op",
    machineUpdatesNotUpdated:   "Nog niet geupdate",
    machineUpdatesDatePlaced:   "Geplaatst op",
    machineUpdatesReturnLog:    "Commando log",
    machineUpdatesOrigin:       "Geplaatst door",
    machineUpdatesSentToServer: "Naar CDS verzonden",
    machineNoUpdates:           "Geen updates",

    machineUpdatesCancelError:         "Er is een fout opgetreden tijdens het annuleren van de update",
    machineUncoupleError:              "Er is een fout opgetreden bij het ontkoppelen van de machine",
    machineClearUpdatesError:          "Er is een fout opgetreden bij het verwijderen van de updates",
    machineClearUpdatesAndResendError: "Er is een fout opgetreden bij het verwijderen van de updates en opnieuw sturen van de inhoud",

    machineCommands:                    {
        "download":            "Download",
        "zip_download":        "Download ZIP",
        "command":             "Commando",
        "delete":              "Verwijderen",
        "send_file":           "Upload bestand",
        "apk_install":         "APK installeren",
        "apk_install_restart": "APK installeren & herstarten",
        "apk_uninstall":       "APK deïnstalleren",
        "setting":             "Stel instelling in",
        "get_setting":         "Haal instelling op",
        "system_setting":      "Stel systeem instelling in",
        "get_system_setting":  "Haal systeem instelling op",
        "send_logs":           "Haal logs op",
        "reboot":              "Herstart systeem",
        "restart":             "Herstart player"
    },
    machineManualUpdatesMachines:       "Machines",
    machineManualUpdatesCommand:        "Commando",
    machineManualUpdatesPath:           "Pad",
    machineManualUpdatesFile:           "Bestand",
    machineManualUpdatesSend:           "Verstuur commando",
    machineManualUpdatesSetting:        "Instelling",
    machineManualUpdatesSettingSection: "Instelling categorie",
    machineManualUpdatesSettingName:    "Instelling naam",
    machineManualUpdatesSettingValue:   "Instelling waarde",
    machineManualUpdatesSettingType:    "Instelling type",
    machineManualUpdatesSuccess:        "Handmatige update is succesvol verzonden.",
    machineManualUpdatesError:          "Er is een fout opgetreden bij het versturen van de handmatige update"
};