import React, {useEffect, useRef, useState} from "react";
//import {useSelector}                                                     from "react-redux";
import {Switch, useHistory, useLocation} from "react-router-dom";
import {Header,  NotificationReceiver, PerfectScrollbar} from "../../components";
import useBodyClass from "../../hooks/useBodyClass";
import {TitleProvider} from "../../contexts/TitleProvider";
import {ScrollProvider} from "../../contexts/ScrollProvider";

import css from "./Dashboard.module.css";
import useHtmlClass from "../../hooks/useHtmlClass";
import useDocumentTitle from "../../hooks/useDocumentTitle";

const Dashboard = props => {
    useHtmlClass("disableScroll");
    useBodyClass(css.dashboard);
    const scrollBar = useRef(null);
    const history   = useHistory();
    const location  = useLocation();

    const [title, setTitle] = useState("");

    // Scroll to top when going to a new page
    useEffect(() => {
        document.documentElement.scrollTop  = 0;
        document.scrollingElement.scrollTop = 0;
        scrollBar.current.scrollTop         = 0;
    }, [history.action, location.pathname]);

    // Set document title
    useDocumentTitle("Spiqle - " + title);

    return (
        <div className="wrapper">
            <NotificationReceiver/>
            {/*<Sidebar/>*/}
            <TitleProvider value={{title, setTitle}}>
                <ScrollProvider value={{scrollBar}}>
                    <PerfectScrollbar containerRef={ref => scrollBar.current = ref} options={{suppressScrollX: true}} className="main-panel">
                        <Header/>
                        <Switch>
                            {props.children}
                        </Switch>
                    </PerfectScrollbar>
                </ScrollProvider>
            </TitleProvider>
        </div>
    );
};

export default Dashboard;
