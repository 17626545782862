import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment-timezone";
import css from "./Calendars.module.css";

const DayName = props => {
    return (
        <div className={css.dayName}>
            {moment.weekdaysMin(props.day)}
        </div>
    );
};

DayName.propTypes = {
    day: PropTypes.number.isRequired
};

export default DayName;