export default {
    groups:   "Grupper",
    group:    "Gruppe",
    noGroups: "Ingen grupper",

    groupsTitle:               "Grupper",
    groupsTableName:           "Navn",
    groupsTableCaretakers:     "Z#",
    groupsTableCaretakersLong: "Plejekrævende",
    groupsActionsEdit:         "Rediger",
    groupsActionsDelete:       "Fjern",

    groupsCaretakersTitle:        "Administrer plejekrævende for %1",
    groupsCaretakersDelete:       "Fjern plejekrævende fra gruppen",
    groupsCaretakersAdd:          "Tilføj plejekrævende til gruppen",
    groupsCaretakersAddTitle:     "Tilføj plejekrævende til gruppe %1",
    groupsCaretakersNoCaretakers: "Ingen plejekrævende endnu",

    groupsAddCaretakersSuccess:   "Plejekrævende er blevet tilføjet til gruppen",
    groupsAddCaretakersError:     "Der opstod en fejl ved tilføjelse af plejekrævende til gruppen",
    groupsRemoveCaretakerSuccess: "Plejekrævende er blevet fjernet fra gruppen",
    groupsRemoveCaretakerError:   "Der opstod en fejl ved fjernelse af plejekrævende fra gruppen",

    groupsAddTitle:      "Tilføj gruppe",
    groupsEditTitle:     "Rediger gruppe",
    groupsEditSuccess:   "Gruppens data er blevet gemt",
    groupsEditError:     "Der opstod en fejl ved lagring af gruppens data",
    groupsRemoveTitle:   "Er du sikker på, at du vil slette denne gruppe?",
    groupsRemoveBody:    "Hvis du sletter gruppen med navnet '%1', vil alle rettigheder for denne gruppe blive fjernet. Dette kan ikke gøres om.",
    groupsRemoveSure:    "Fjern gruppe",
    groupsRemoveSuccess: "Gruppen er blevet slettet",
    groupsRemoveError:   "Der opstod en fejl ved sletning af gruppen",

    groupsCalendarsNoItems:     "Der er ingen kalenderpunkter for denne gruppe endnu",
    groupsMessage:              "Direkte besked",
    groupsMessageTo:            "Direkte besked til %1",
    groupsMessageSentTo:        "Send en besked til %1",
    groupsMessageMessage:       "Besked",
    groupsMessageDuration:      "Synlig for:",
    groupsMessageDurations:     {
        300:   "5 minutter",
        900:   "15 minutter",
        1800:  "30 minutter",
        3600:  "1 time",
        10800: "3 timer",
        21600: "6 timer",
        43200: "12 timer",
        86400: "24 timer"
    },
    groupsMessageUrgent:        "Urgent besked",
    groupsMessageSuccess:       "Direkte besked er sendt",
    groupsMessageError:         "Der opstod en fejl ved afsendelse af direkte besked",
    groupsMessageCancelSuccess: "Direkte besked er blevet fjernet fra skærmene",
    groupsMessageCancelError:   "Der opstod en fejl ved fjernelse af direkte besked",
    groupsMessageNoMachines:    "Denne gruppe har ingen skærme endnu",

    groupsSentMessages:          "Besked oversigt",
    groupsSentMessagesMessage:   "Besked",
    groupsSentMessagesSentLong:  "Sendt",
    groupsSentMessagesSentShort: "Sendt",
    groupsSentMessagesSentBy:    "Sendt af",
    groupsSentMessagesDelivered: "Vist på skærm",
    groupsSentMessagesCancelled: "Besked annulleret",
    groupsNoSentMessages:        "Ingen beskeder sendt endnu",

    groupsSettings:        "Indstillinger",
    groupsDetails:         "Data %1",
    groupsCaretakers:      "Administrer plejekrævende",
    groupsCaretakersError: "Ingen plejekrævende er valgt",

    groupsManageSuccess:           "Gruppens navn er gemt",
    groupsManageFail:              "Der opstod en fejl ved lagring af gruppens navn",
    groupsManageCaretakersSuccess: "Plejekrævende for gruppen er gemt",
    groupsManageCaretakersFail:    "Plejekrævende for gruppen kunne ikke gemmes",

    groupName: "Navn",
    groupList: "Plejekrævende i denne gruppe"
};