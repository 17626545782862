import React from 'react';
import PropTypes from 'prop-types';
import css from "./HelpItem.module.css";

const HelpItem = props => (
    <div className={css.container}>
        {props.children}
        {props.show && (
            <div className={props.isTitle ? css.overlayTitle : css.overlay}>
                {props.helpText}
                <div
                    className={css.overlayArrowContainer}
                    style={{transform: "translate3d(" + (props.arrowLeft ? props.arrowLeft : 20) + "px, -13px, 0)"}}
                >
                    <div className={css.overlayArrowLeft}/>
                    <div className={css.overlayArrowRight}/>
                </div>
            </div>
        )}
    </div>
);


HelpItem.propTypes = {
    helpText:  PropTypes.string,
    isTitle:   PropTypes.bool,
    arrowLeft: PropTypes.number,
    show:      PropTypes.bool
};

export default HelpItem;