import React from 'react';
import PropTypes from 'prop-types';
import Datetime from "react-datetime";
import AutoWrapInput from '../InputContainers/AutoWrapInput';
import i18n from "../../../i18n";
import css from "./Date.module.css";
import moment from "moment-timezone";
import {ucFirst} from "../../../variables/tools";

const cumulativeOffset = element => {
    let top = 0, left = 0;
    do {
        top += element.offsetTop || 0;
        left += element.offsetLeft || 0;
        element = element.offsetParent;
    } while (element);

    return {top, left};
};

const DateTime = props => {
    const onOpen  = () => {
        let container = document.getElementsByClassName("input-selectdate-sp-" + props.name)[0];
        let input     = container.querySelector("input");
        let picker    = container.querySelector(".rdtPicker");
        let offset    = cumulativeOffset(input);
        if (offset.left > 10) {
            picker.setAttribute("style", "left: " + -(offset.left - 10) + "px");
        }
        props.handlers.focus(props.name, true);
    }
    const onClose = () => {
        props.handlers.focus(props.name, false);
    }

    const renderDay   = (props, currentDate, selectedDate) => (
        <td key={props.key}>
            <div {...props}>{currentDate.date()}</div>
        </td>);
    const renderMonth = (props, month, year, selectedDate) => (
        <td key={props.key}>
            <div {...props}>{ucFirst(moment.localeData().monthsShort(moment([year, month])).substring(0, 3))}</div>
        </td>);
    const renderYear  = (props, year, selectedDate) => (
        <td key={props.key}>
            <div {...props}>{year}</div>
        </td>);

    return (
        <AutoWrapInput name={props.name} settings={props.settings} stacked={props.stacked} inline={props.inlineSelect} disabled={props.disabled}>
            <Datetime
                name={props.name + "-date"}
                disabled={props.disabled}
                className={"calendar-selectdate input-selectdate-sp-" + props.name + " " + (props.inlineSelect ? css.inlineDate : "")}
                timeFormat={false}
                closeOnSelect={true}
                onChange={value => props.handlers.change(value, props.name)}
                {...{onOpen, onClose, renderDay, renderMonth, renderYear}}
                inputProps={{
                    readOnly:    true,
                    placeholder: i18n.calendarDate
                }}
                value={props.settings.value}
                locale={i18n.getLanguage()}
                ref={props.innerRef}
                isValidDate={props.settings.validDates}
            />
        </AutoWrapInput>
    );
};

DateTime.propTypes = {
    name:         PropTypes.string.isRequired,
    settings:     PropTypes.object.isRequired,
    handlers:     PropTypes.object.isRequired,
    innerRef:     PropTypes.object,
    stacked:      PropTypes.bool,
    inlineSelect: PropTypes.bool
};

export default React.memo(DateTime, (prevProps, nextProps) => {
    return prevProps.settings.value === nextProps.settings.value &&
        prevProps.settings.focused === nextProps.settings.focused &&
        prevProps.settings.valid === nextProps.settings.valid &&
        prevProps.disabled === nextProps.disabled;
});