import * as actionTypes from "../actionTypes";

export const load         = () => ({type: actionTypes.MACHINES_LOAD});
export const loadForRight = (right, rightLevel) => ({type: actionTypes.MACHINES_LOAD_FOR_RIGHT, right, rightLevel});
export const loadIfNeeded = () => ({type: actionTypes.MACHINES_LOAD_IF_NEEDED});
export const loadSuccess  = machines => ({type: actionTypes.MACHINES_LOAD_SUCCESS, machines});
export const loadFail     = error => ({type: actionTypes.MACHINES_LOAD_FAIL, error});

export const loadStatus        = () => ({type: actionTypes.MACHINES_STATUS_LOAD});
export const loadStatusSuccess = machineStatus => ({type: actionTypes.MACHINES_STATUS_LOAD_SUCCESS, machineStatus});
export const loadStatusFail    = error => ({type: actionTypes.MACHINES_STATUS_LOAD_FAIL});

export const loadInfo        = macAddress => ({type: actionTypes.MACHINES_INFO_LOAD, macAddress});
export const loadInfoSuccess = (macAddress, machineInfo) => ({type: actionTypes.MACHINES_INFO_LOAD_SUCCESS, macAddress, machineInfo});
export const loadInfoFail    = error => ({type: actionTypes.MACHINES_INFO_LOAD_FAIL});

export const cancelUpdate        = id => ({type: actionTypes.MACHINES_CANCEL_UPDATE, id});
export const cancelUpdateSuccess = id => ({type: actionTypes.MACHINES_CANCEL_UPDATE_SUCCESS, id});
export const cancelUpdateFail    = error => ({type: actionTypes.MACHINES_CANCEL_UPDATE_FAIL});

export const swap        = (macAddressFrom, macAddressTo, redirect = "/admin/machines") => ({
    type: actionTypes.MACHINES_SWAP,
    macAddressFrom,
    macAddressTo,
    redirect
});
export const swapSuccess = (macAddressFrom, macAddressTo) => ({type: actionTypes.MACHINES_SWAP_SUCCESS, macAddressFrom, macAddressTo});
export const swapFail    = error => ({type: actionTypes.MACHINES_SWAP_FAIL});

export const couple        = (macAddress, caretaker, redirect = "/admin/machines") => ({type: actionTypes.MACHINES_COUPLE, macAddress, caretaker, redirect});
export const coupleSuccess = (macAddress, caretaker) => ({type: actionTypes.MACHINES_COUPLE_SUCCESS, macAddress, caretaker});
export const coupleFail    = error => ({type: actionTypes.MACHINES_COUPLE_FAIL, error});

export const uncouple        = (macAddress, redirect = "/admin/machines") => ({type: actionTypes.MACHINES_UNCOUPLE, macAddress, redirect});
export const uncoupleSuccess = macAddress => ({type: actionTypes.MACHINES_UNCOUPLE_SUCCESS, macAddress});
export const uncoupleFail    = error => ({type: actionTypes.MACHINES_UNCOUPLE_FAIL, error});

export const clearUpdates        = macAddress => ({type: actionTypes.MACHINES_CLEAR_UPDATES, macAddress});
export const clearUpdatesSuccess = macAddress => ({type: actionTypes.MACHINES_CLEAR_UPDATES_SUCCESS, macAddress});
export const clearUpdatesFail    = error => ({type: actionTypes.MACHINES_CLEAR_UPDATES_FAIL, error});

export const clearUpdatesAndResend        = macAddress => ({type: actionTypes.MACHINES_CLEAR_UPDATES_AND_RESEND, macAddress});
export const clearUpdatesAndResendSuccess = macAddress => ({type: actionTypes.MACHINES_CLEAR_UPDATES_AND_RESEND_SUCCESS, macAddress});
export const clearUpdatesAndResendFail    = error => ({
    type:  actionTypes.MACHINES_CLEAR_UPDATES_AND_RESEND_FAIL,
    error: "MACHINES_CLEAR_UPDATES_AND_RESEND_FAILED"
});

export const manualUpdate        = update => ({type: actionTypes.MACHINES_MANUAL_UPDATE, update});
export const manualUpdateSuccess = () => ({type: actionTypes.MACHINES_MANUAL_UPDATE_SUCCESS});
export const manualUpdateFail    = error => ({type: actionTypes.MACHINES_MANUAL_UPDATE_FAIL, error: "MACHINES_MANUAL_UPDATE_FAILED"});
