import * as actionTypes from "../actionTypes";

export const start                     = () => ({type: actionTypes.AUTH_START});
export const success                   = token => ({type: actionTypes.AUTH_SUCCESS, token});
export const fail                      = error => ({type: actionTypes.AUTH_FAIL, error});
export const clearError                = () => ({type: actionTypes.AUTH_RESET_ERROR});
export const login                     = (email, password, inviteToken) => ({type: actionTypes.AUTH_LOGIN, email, password, inviteToken});
export const signup                    = (name, email, password, inviteToken) => ({type: actionTypes.AUTH_SIGNUP, name, email, password, inviteToken});
export const checkTimeout              = expirationTime => ({type: actionTypes.AUTH_CHECK_TIMEOUT, expirationTime: expirationTime});
export const logout                    = redirect => ({type: actionTypes.AUTH_INITIATE_LOGOUT, redirect});
export const logoutDone                = () => ({type: actionTypes.AUTH_LOGOUT});
export const forgotPassword            = email => ({type: actionTypes.AUTH_FORGOT_PASSWORD, email});
export const forgotPasswordSuccess     = () => ({type: actionTypes.AUTH_FORGOT_PASSWORD_SUCCESS});
export const forgotPasswordFail        = error => ({type: actionTypes.AUTH_FORGOT_PASSWORD_FAIL, error});
export const resetPassword             = (token, password) => ({type: actionTypes.AUTH_RESET_PASSWORD, token, password});
export const resetPasswordSuccess      = () => ({type: actionTypes.AUTH_RESET_PASSWORD_SUCCESS});
export const resetPasswordFail         = error => ({type: actionTypes.AUTH_RESET_PASSWORD_FAIL, error});
export const checkPasswordToken        = token => ({type: actionTypes.AUTH_CHECK_PASSWORD_TOKEN, token});
export const checkPasswordTokenSuccess = () => ({type: actionTypes.AUTH_CHECK_PASSWORD_TOKEN_SUCCESS});
export const checkPasswordTokenFail    = error => ({type: actionTypes.AUTH_CHECK_PASSWORD_TOKEN_FAIL, error});
export const verifyEmail               = token => ({type: actionTypes.AUTH_VERIFY_EMAIL, token});
export const verifyEmailSuccess        = () => ({type: actionTypes.AUTH_VERIFY_EMAIL_SUCCESS});
export const verifyEmailFail           = error => ({type: actionTypes.AUTH_VERIFY_EMAIL_FAIL, error});
export const checkLocalStorage         = () => ({type: actionTypes.AUTH_CHECK_LOCALSTORAGE});
export const loadAccountData           = () => ({type: actionTypes.AUTH_LOAD_ACCOUNTDATA});
export const loadAccountDataSuccess    = (accountData, rights, caretakerRights) => ({
    type: actionTypes.AUTH_LOAD_ACCOUNTDATA_SUCCESS,
    accountData,
    rights,
    caretakerRights
});
export const loadAccountDataFail       = () => ({type: actionTypes.AUTH_LOAD_ACCOUNTDATA_FAIL});
export const saveAccountData           = accountData => ({type: actionTypes.AUTH_SAVE_ACCOUNTDATA, accountData});
export const saveAccountDataSuccess    = () => ({type: actionTypes.AUTH_SAVE_ACCOUNTDATA_SUCCESS});
export const saveAccountDataFail       = () => ({type: actionTypes.AUTH_SAVE_ACCOUNTDATA_FAIL});
export const setLanguage               = language => ({type: actionTypes.AUTH_SET_LANGUAGE, language});
export const loadInvite                = inviteToken => ({type: actionTypes.AUTH_LOAD_INVITE, inviteToken});
export const loadInviteSuccess         = (invitedBy, invitee, rightList) => ({type: actionTypes.AUTH_LOAD_INVITE_SUCCESS, invitedBy, invitee, rightList});
export const loadInviteFail            = error => ({type: actionTypes.AUTH_LOAD_INVITE_FAIL, error});
export const acceptInvite              = inviteToken => ({type: actionTypes.AUTH_ACCEPT_INVITE, inviteToken});
export const acceptInviteSuccess       = () => ({type: actionTypes.AUTH_ACCEPT_INVITE_SUCCESS});
export const acceptInviteFail          = error => ({type: actionTypes.AUTH_ACCEPT_INVITE_FAIL, error});
export const changePassword            = (oldPassword, newPassword) => ({type: actionTypes.AUTH_CHANGE_PASSWORD, oldPassword, newPassword});
export const changePasswordSuccess     = () => ({type: actionTypes.AUTH_CHANGE_PASSWORD_SUCCESS});
export const changePasswordFail        = error => ({type: actionTypes.AUTH_CHANGE_PASSWORD_FAIL, error});