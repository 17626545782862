import React, {useEffect} from 'react';
import i18n from "../../../i18n";
import css from "./InviteNewUser.module.css";
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../../store/actions";
import {Form, Spinner} from "../../../components";
import SingleCaretaker from "./SingleCaretaker";

const caretakerForm = {
    caretakers: {
        label:                   i18n.caretakersAddNewUserCaretakers,
        type:                    "caretakers",
        requiredRight:           "invite",
        requiredRightLevel:      1,
        simpleColumns:           true,
        defaultSelectSingleItem: true
    }
};

const CaretakerSelector = props => {
    const loading    = useSelector(state => state.auth.loading);
    const caretakers = useSelector(state => state.caretakers.caretakers);
    const dispatch   = useDispatch();
    useEffect(() => {
        dispatch(actions.caretakers.loadIfNeeded())
    }, [dispatch]);

    if (!caretakers || loading) {
        return <Spinner/>;
    }

    if (caretakers.length <= 5) {
        return (<div className={css.tourCaretakerList}>
            <div>{i18n.caretakersAddNewUserCaretakers}</div>
            {caretakers.map(caretaker => {
                const selected = props.selected.includes(caretaker.uuid);
                const onClick = () => {
                    if(selected) {
                        props.onChange(props.selected.filter(item => item !== caretaker.uuid));
                    } else {
                        props.onChange([...props.selected, caretaker.uuid]);
                    }
                }
                return <SingleCaretaker
                    selected={selected}
                    onClick={onClick}
                    key={caretaker.uuid}
                    {...caretaker}
                />;
            })}
        </div>);
    } else {
        return <Form
            inlineSelect
            formData={caretakerForm}
            values={{caretakers: props.selected}}
            onChange={formValues => props.onChange(formValues.caretakers)}
            stacked
        />
    }
};

CaretakerSelector.propTypes = {};

export default CaretakerSelector;