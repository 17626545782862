import React from 'react';
import PropTypes from 'prop-types';
import {FormGroup, Input, Label} from "reactstrap";
import AutoWrapInput from "../InputContainers/AutoWrapInput";

const Checkbox = props => (
    <AutoWrapInput name={props.name} settings={props.settings} disabled={props.disabled}>
        <FormGroup check>
            <Label className="form-inline-check-label">
                <Input
                    {...props.settings.elementConfig}
                    type="checkbox"
                    name={props.name}
                    disabled={props.disabled}
                    onChange={event => props.handlers.change(event.target.checked, props.name)}
                    onFocus={() => props.handlers.focus(props.name, true)}
                    onBlur={() => props.handlers.focus(props.name, false)}
                    className={props.settings.touched && props.settings.validation ? (props.settings.valid ? "has-success" : "has-danger") : ""}
                    checked={props.settings.value}
                    innerRef={props.innerRef}
                />
                <span className="form-check-sign"/>
                {props.settings.labelExtended && props.settings.labelExtended}
            </Label>
        </FormGroup>
    </AutoWrapInput>
);

Checkbox.propTypes = {
    name:     PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    handlers: PropTypes.object.isRequired,
    innerRef: PropTypes.object,
    stacked:  PropTypes.bool
};

export default React.memo(Checkbox, (prevProps, nextProps) => {
    return prevProps.settings.value === nextProps.settings.value &&
        prevProps.settings.valid === nextProps.settings.valid &&
        prevProps.disabled === nextProps.disabled;
});