import {call, put, takeLatest} from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../actionTypes";
import * as api from "../../api";
import i18n from "../../i18n";
import moment from 'moment-timezone';
import nl from "../../i18n/nl";

moment.locale('nl');
moment.defaultFormat = nl.dateFormat;

// Watcher
export function* watchGroupMessages() {
    yield takeLatest(actionTypes.GROUP_MESSAGES_LOAD, loadSaga);
    yield takeLatest(actionTypes.GROUP_MESSAGE_SEND, sendSaga);
    yield takeLatest(actionTypes.GROUP_MESSAGE_CANCEL, cancelSaga);
}

// Sagas
function* loadSaga(action) {
    try {
        const response = yield call(api.groupMessages.load, action.forGroup);
        const messages = [];
        for (let i = 0; i < response.data.messages.length; i++) {
            messages.push({
                ...response.data.messages[i],
                sentAt:      moment.utc(response.data.messages[i].sentAt, "YYYY-MM-DD HH:mm:ss"),
                deliveredAt: moment.utc(response.data.messages[i].deliveredAt, "YYYY-MM-DD HH:mm:ss"),
                cancelledAt: moment.utc(response.data.messages[i].cancelledAt, "YYYY-MM-DD HH:mm:ss")
            })
        }
        yield put(actions.groupMessages.loadSuccess(action.forGroup, messages));
    }
    catch (err) {
        yield put(actions.groupMessages.loadFail());
    }
}
function* sendSaga(action) {
    try {
        yield call(api.groupMessages.send, action.destination, action.message, action.duration, action.urgent);
        yield put(actions.groupMessages.sendSuccess());
        yield put(actions.messages.successPush(i18n.groupsMessageSuccess));
        yield put(actions.groupMessages.load(action.destination));
//        yield put(push("/caretakers"));
    }
    catch (err) {
        yield put(actions.groupMessages.sendFail());
        yield put(actions.messages.errorPush(i18n.groupsMessageError))
    }
}

function* cancelSaga(action) {
    try {
        yield call(api.groupMessages.cancel, action.uuid);
        yield put(actions.groupMessages.cancelSuccess());
        yield put(actions.messages.successPush(i18n.groupsMessageCancelSuccess));
        yield put(actions.groupMessages.load(action.forGroup));
    }
    catch (err) {
        yield put(actions.groupMessages.cancelFail());
        yield put(actions.messages.errorPush(i18n.groupsMessageCancelError));
    }
}