import moment from 'moment';
import {matchPath} from "react-router";

export const addZeroes = number => {
    if (number < 10) return "0" + number;
    return number;
};
export const ucFirst   = str => {
    if (str.length === 0) {
        return "";
    }
    return str.charAt(0).toLocaleUpperCase() + str.slice(1);
};
export const niceJoin  = arr => {
    if (arr.length === 0) {
        return "";
    }
    if (arr.length === 1) {
        return arr[0];
    }
    if (arr.length === 2) {
        return arr[0] + " & " + arr[1];
    }

    return arr.slice(0, -1).join(", ") + " & " + arr.slice(-1);
};

export const convertArrayToOptions = (arr, value, label) => {
    if (!arr) {
        return [];
    }

    return arr.map(item => ({label: item[label], value: item[value]}));
};

export const deepCompare = function (obj1, obj2) {
    for (let p in obj1) {
        if (!obj1.hasOwnProperty(p)) {
            continue;
        }
        if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) {
            return false;
        }

        switch (typeof (obj1[p])) {
            case 'object':
                if (moment.isMoment(obj1[p])) {
                    if (!obj1[p].isSame(obj2[p])) {
                        return false;
                    }
                } else {
                    if (!deepCompare(obj1[p], obj2[p])) {
                        return false;
                    }
                }
                break;
            case 'function':
                if (typeof (obj2[p]) == 'undefined' || (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())) {
                    return false;
                }
                break;
            default:
                if (obj1[p] !== obj2[p]) {
                    return false;
                }
        }
    }

    for (let p in obj2) {
        if (!obj2.hasOwnProperty(p)) {
            continue;
        }
        if (typeof (obj1[p]) == 'undefined') {
            return false;
        }
    }
    return true;
};

export const isMobile = () => {
    return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
    ) <= 768;
};

export const calculatePagination = (data, maxRows, minimumRows = 1) => {
    return {
        pageSize:        data.length > maxRows ? maxRows : (data.length < minimumRows ? minimumRows : data.length),
        defaultPageSize: data.length > maxRows ? maxRows : (data.length < minimumRows ? minimumRows : data.length),
        showPagination:  data.length > maxRows
    };
};

export const compareRights = (required, rights, caretakerRights) => {
    if (!required) {
        return true;
    }
    if (required && !rights) {
        return false;
    }

    if (required.superAdmin && !rights.superAdmin) {
        return false;
    }
    if (required.caretakerCount) {
        //let count = caretakerRights.reduce()
        //caretakerRights.forEach(caretaker => caretaker[required.caretaker);
    }
    let {superAdmin, ...compareRights} = required;
    for (let right in compareRights) {
        if (compareRights.hasOwnProperty(right) && (!rights[right] || compareRights[right] > rights[right])) {
            return false;
        }
    }

    return true;
};

export const filterRoutes = (routes, rights, caretakerRights) => {
    if (!rights) {
        return routes;
    }

    const filteredRoutes = [];
    routes.forEach(route => {
        if (!compareRights(route.requires, rights, caretakerRights)) {
            return;
        }
        if (!route.collapse || !route.views) {
            filteredRoutes.push(route);
            return;
        }
        filteredRoutes.push({
            ...route,
            views: route.views.filter(view => compareRights(view.requires, rights, caretakerRights))
        });
    });
    return filteredRoutes;
};

export const formHandleValueSetters = (valueSetter, validSetter) => {
    return (values, valid) => {
        valueSetter(values);
        validSetter(valid);
    }
};

export const mapStringToKeys = (key, mapping) => {
    if (mapping.hasOwnProperty(key)) {
        return mapping[key];
    }
    if (mapping.default) {
        return mapping.default;
    }
    return null;
};

export const mapErrorResponseCodes = (error, errorCodeMapping) => {
    if (error.response && error.response.data && error.response.data.error) {
        return mapStringToKeys(error.response.data.error, errorCodeMapping);
    } else {
        return mapStringToKeys("default", errorCodeMapping);
    }
};

export const exifOrientationToRotation = imgData => {
    if (imgData && imgData.exif) {
        switch (imgData.exif.get("Orientation")) {
            case 8:
                return 270;
            case 3:
                return 180;
            case 6:
                return 90;
            default:
                return 0;
        }
    }
    return 0;
};

export const getOffsetTop = element => {
    let offsetTop = 0;
    while (element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
    }
    return offsetTop;
};

export const scrollTo = element => {
    window.scrollTo(0, getOffsetTop(element));
};

export const findRoute = (routes, currentPath) => {
    for (let i = 0; i < routes.length; i++) {
        if (routes[i].collapse) {
            for (let j = 0; j < routes[i].views.length; j++) {
                if (matchPath(currentPath, {path: routes[i].views[j].path})) {
                    return routes[i].views[j];
                }
            }
        }
        if (matchPath(currentPath, {path: routes[i].path})) {
            return routes[i];
        }
    }
    return null;
};

export const convertMachineToSignal = machine => {
    if (machine.last_update < (moment.utc().valueOf() / 1000) - 3600) {
        return -1;
    } else {
        try {
            let networkInfo = JSON.parse(machine.network);
            if (!networkInfo) {
                return -1;
            }
            switch (networkInfo.type) {
                case "Ethernet":
                    return 4;

                case "WiFi":
                    if (networkInfo.signal > -65) {
                        return 4;
                    }
                    if (networkInfo.signal > -70) {
                        return 3;
                    }
                    if (networkInfo.signal > -80) {
                        return 2;
                    }
                    if (networkInfo.signal > -90) {
                        return 1;
                    }
                    return 0;

                case "Mobile":
                    let signal = 0;
                    switch (networkInfo.signal.mode) {
                        case "GSM":
                        case "UMTS":
                        case "HSDPA":
                            signal = Math.round((parseInt(networkInfo.signal.rxLevel) + 100) / 12);
                            break;
                        case "LTE":
                            signal = Math.round((parseInt(networkInfo.signal.RSRQ) + 20) / 3);
                            break;
                        default:
                            return 0;
                    }

                    return signal < 0 ? 0 : (signal > 4 ? 4 : signal);

                default:
                    return -1;
            }
        } catch (e) {
            return 2;
        }
    }
};