import i18n from "../../i18n";
import {defaultColumns, accessorColumn} from "./funcs";
import moment from "moment-timezone";

export const inviteColumns = {
    type:        accessorColumn("type", i18n.rightType, d => i18n.usersRightsTypeMapping[d.type], {width: 110, togglable: false}),
    name:        accessorColumn("name", i18n.caretakersTableName, d => d.info.name, {togglable: false}),
    invitedName: accessorColumn("name", i18n.caretakersTableName, d => d.name, {togglable: false}),
    email:       accessorColumn("email", i18n.caretakersAddNewUserEmail, d => d.email, {show: false}),
    invitedOn:   accessorColumn("invited_on", i18n.caretakersInvitedUsersInvitedOn, d => moment(d.created, "YYYY-MM-DD HH:mm:ss").format("LL"), {width: 180, show: false}),
    status:      accessorColumn("status", i18n.caretakersInvitedUsersStatus, d => d.used ? i18n.caretakersInvitedUsersStatusUsed : (d.expired ? i18n.caretakersInvitedUsersStatusExpired : i18n.caretakersInvitedUsersStatusNotUsed), {width: 180, togglable: false}),
};

export const inviteColumnsDefault = (actions = null, selector = null) => defaultColumns(
    [
        inviteColumns.type,
        inviteColumns.name
    ], actions, "uuid", selector
);

export const invitedColumnsDefault = (actions = null, selector = null) => defaultColumns(
    [
        inviteColumns.invitedName,
        inviteColumns.email,
        inviteColumns.invitedOn,
        inviteColumns.status
    ], actions, "id", selector
);