import i18n from "../../../i18n";

export default {
    email:    {
        label:         i18n.loginEmail,
        validation:    {required: true, isEmail: true},
        elementConfig: {placeholder: ""}
    },
    password: {
        label:         i18n.loginPassword,
        elementConfig: {type: "password"},
        labelLink:     {to: "/forgot", label: "vergeten?"}
    }
};
