import React, {useEffect} from "react";
import {useDispatch} from "react-redux";

import i18n from "../../i18n";
import * as actions from "../../store/actions";
import {appRootPath} from "../../variables/multiplatform";

import {Spinner} from "../../components";
import {Card, CardBody, CardHeader, CardTitle, Col, Container} from "reactstrap";
import spiqleLogo from "../../assets/img/logo-big.svg";

const VerifyPage = props => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.auth.verifyEmail(props.match.params.token));
    }, [dispatch, props.match.params.token]);

    return (
        <div>
            <Spinner dark/>
            <div className="full-page-content">
                <div className="login-page">
                    <Container>
                        <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
                            <Card className="card-login card-plain">
                                <CardHeader>
                                    <div className="logo-container">
                                        <img src={appRootPath + spiqleLogo} alt="now-logo"/>
                                    </div>
                                    <CardTitle tag="h4">{i18n.verifyBodyLoading}</CardTitle>
                                </CardHeader>
                                <CardBody/>
                            </Card>
                        </Col>
                    </Container>
                </div>
            </div>
            <div
                className="full-page-background"
                style={{background: "linear-gradient(135deg, rgba(121,146,141,1) 0%, rgba(71,96,100,1) 100%)"}}
            />
        </div>
    );
};

export default VerifyPage;
