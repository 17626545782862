import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

import i18n from "../../../../../i18n";
import * as actions from "../../../../../store/actions";
import icons from "../../../../../variables/icons";

import {Button, Form} from "../../../../../components";

const duplicateUserForm = {
    name: {label: i18n.userName, icon: icons["single-05"]},
    row1: {
        type:  "row",
        items: {
            address: {label: i18n.userAddress, icon: icons["home-3"], size: 8},
            zipcode: {label: i18n.userZipcode, icon: icons["c-info"], size: 4, validation: {required: true, minLength: 4}},
        }
    },
    row2: {
        type:  "row",
        items: {
            city:    {label: i18n.userCity, icon: icons.office, size: 4},
            state:   {label: i18n.userState, icon: icons.map, size: 4},
            country: {label: i18n.userCountry, icon: icons["world-pin"], size: 4}
        }
    },
    row3: {
        type:  "row",
        items: {
            phone: {label: i18n.userPhone, icon: icons.telephone, size: 6},
            email: {label: i18n.userEmail, icon: icons["email-2"], size: 6, validation: {required: true, isEmail: true}}
        }
    }
};

const Duplicate = props => {
    const dispatch                                      = useDispatch();
    const [showDuplicate, setShowDuplicate]             = useState(false);
    const [formDuplicateValues, setFormDuplicateValues] = useState({});
    const [formDuplicateValid, setFormDuplicateValid]   = useState(false);
    const duplicateDone                                 = useSelector(state => state.users.duplicateDone);

    useEffect(() => {
        if (duplicateDone) {
            setShowDuplicate(false);
            dispatch(actions.users.resetDoneStates());
        }
    }, [duplicateDone, dispatch]);

    return (
        <>
            <Button color="info" block round simple onClick={() => setShowDuplicate(true)}>
                {i18n.usersActionsDuplicateButton}
            </Button>
            <Modal isOpen={showDuplicate} toggle={() => setShowDuplicate(!showDuplicate)} size="xl">
                <ModalHeader className="justify-content-center uppercase title" tag="h4">{i18n.usersDuplicateTitle}</ModalHeader>
                <ModalBody>
                    <p>{i18n.usersDuplicateBody.replace('%1', props.userName)}</p>
                    <Form
                        stacked
                        formData={duplicateUserForm}
                        onChange={(formValues, formValid) => {
                            setFormDuplicateValues(formValues);
                            setFormDuplicateValid(formValid);
                        }}
                        onValid={formValid => setFormDuplicateValid(formValid)}
                        values={formDuplicateValues}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="danger"
                        disabled={!formDuplicateValid}
                        onClick={() => dispatch(actions.users.duplicate(props.userId, formDuplicateValues))}
                    >{i18n.usersDuplicateSure}</Button>
                    <Button color="info" onClick={() => setShowDuplicate(false)}>{i18n.buttonCancel}</Button>
                </ModalFooter>
            </Modal>

        </>
    );
};

Duplicate.propTypes = {
    userId:   PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired
};

export default Duplicate;