// React
import React, {useRef, useState} from "react";

// Redux
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../../store/actions";

// Tools
import i18n from "../../../i18n";

// Components
import {Button, Spinner, BottomBar, Tour, ButtonCrusher} from "../../../components";
import Avatar from "./Avatar";
import withCaretakers from "../../../hoc/withCaretakers";
import {apiGetFullPath, apiGetToken} from "../../../api";
import {TitleWrapper} from "../../../contexts/TitleProvider";
import {getPageHelpSteps} from "../../../help/tools";
import css from "./EditAvatar.module.css";

const EditAvatar = props => {
    const dispatch  = useDispatch();
    const loading   = useSelector(state => state.caretakers.loading);
    const saving    = useSelector(state => state.caretakers.saving);
    const fileInput = useRef();

    const [changed, setChanged] = useState(false);
    const [newFile, setNewFile] = useState(null);

    const [helpOpen, setHelpOpen] = useState(false);
    const doHelp                  = () => setHelpOpen(true);
    const closeHelp               = () => setHelpOpen(false);

    const handleFile   = e => {
        e.preventDefault();
        setNewFile(e.target.files[0]);
        setChanged(true);
    }
    const handleRemove = () => {
        setNewFile(null);
        setChanged(true);
    }
    const handleSubmit = () => {
        if (!changed) {
            dispatch(actions.messages.errorPush(i18n.errorForm));
            return;
        }
        if (newFile === null) {
            dispatch(actions.caretakers.removePhoto(props.caretakerUuid));
        } else {
            dispatch(actions.caretakers.savePhoto(props.caretakerUuid, newFile))
        }
    }

    return (
        <TitleWrapper title={"Foto " + (props.caretaker ? props.caretaker.name : i18n.caretaker)}>
            <Tour
                isOpen={helpOpen}
                onRequestClose={closeHelp}
                steps={getPageHelpSteps("caretakerEditAvatar")}
            />
            <div>
                <div className="content">
                    {props.caretaker && (
                        <div>
                            <Avatar
                                changed={changed}
                                newAvatar={newFile}
                                existingAvatar={
                                    props.caretaker.avatar ?
                                        apiGetFullPath("caretakers/getavatar?uuid=" + props.caretakerUuid + "&token=" + apiGetToken()) :
                                        null
                                }
                            />
                        </div>
                    )}
                    <ButtonCrusher width={75}>
                        {(!props.caretaker || !props.caretaker.avatar || (changed && newFile === null)) ? (
                            <input
                                type="file"
                                accept="image/*;capture=camera"
                                className={css.avatarFile + " " + css[i18n.getLanguage()]}
                                onChange={handleFile}
                                ref={fileInput}
                            />
                        ) : (
                            <>
                                <input
                                    type="file"
                                    accept="image/*;capture=camera"
                                    className={css.avatarFile + " " + css[i18n.getLanguage() + "Edit"]}
                                    onChange={handleFile}
                                    ref={fileInput}
                                />
                                <Button
                                    block
                                    round
                                    upper
                                    color="greyed"
                                    className={css.tourRemove}
                                    onClick={handleRemove}
                                >{i18n.caretakerPhotoRemove}</Button>
                            </>
                        )}
                        <Button
                            block
                            round
                            upper
                            color="info"
                            className={css.tourSave}
                            onClick={handleSubmit}
                        >{i18n.buttonSave}</Button>
                    </ButtonCrusher>
                    <BottomBar
                        backButton
                        backUrl={"/caretakers/edit/" + props.caretakerUuid}
                        onHelp={doHelp}
                        onCloseHelp={closeHelp}
                        helpOpen={helpOpen}
                    />
                    {!props.caretaker || loading || saving ? <Spinner/> : null}
                </div>
            </div>
        </TitleWrapper>
    );
}

export default withCaretakers(EditAvatar);
