export default {
    loginTitle:           "Log in",
    loginTitleShort:      "Log in",
    loginTitleMark:       "L",
    loginEmail:           "E-mail address",
    loginError:           "Logging in failed. Check your login details and internet connection and try again.",
    loginDisabled:        "Your account has been disabled. Please contact us to reactivate your account.",
    loginButton:          "Log in",
    loginPassword:        "Password",
    loginSignUp:          "Create account",
    loginForgot:          "Forgot password",
    loginAlreadyLoggedIn: "You're already logged in. If you want to log in using another account, please log off first.",

    signUpEmail:                       "E-mail address",
    signUpPassword1:                   "Password",
    signUpPassword2:                   "Repeat password",
    signUpSubmitButton:                "Create account",
    signUpBackToLogin:                 "Back to log in",
    signUpTitle:                       "Create account",
    signUpTitleShort:                  "Sign up",
    signUpTitleMark:                   "S",
    signUpPasswordDontMatch:           "The passwords don't match",
    signUpPasswordTooShort:            "The password is too short, there's a requirement of at least 7 characters.",
    signUpPasswordMinimumRequirements: "The password has to contain at least a lower case letter, an upper case letter and a number.",
    signUpSuccess:                     "Signed up successfully",
    signUpError:                       "An error occurred during sign-up.",
    signUpErrorExists:                 "Creating the account failed. There's already an account using this e-mail address.",
    signUpErrorPassword:               "The password is not strong enough. Follow the instructions for a safe password.",
    signUpCallToAction:                "No account yet?",

    forgotTitle:         "Forgot password",
    forgotBody:          "Enter your e-mail address to reset your password.",
    forgotEmail:         "E-mail address",
    forgotSubmitButton:  "Reset password",
    forgotSubmitSuccess: "We've sent you an e-mail, please follow the instructions to reset your password.",
    forgotSubmitFailed:  "The password couldn't be reset.",

    verifyTitle:               "Confirm e-mail address",
    verifyBodyLoading:         "Your e-mail address is being confirmed...",
    verifyBodyVerified:        "Your e-mail address is confirmed. You can now log in.",
    verifyBodyAlreadyVerified: "Your e-mail was already verified. You can now log in.",
    verifyBodyUnknown:         "This verification key is unknown.",

    resetPasswordTitle:   "Reset password",
    resetPasswordBody:    "Please choose a new password for your account.",
    resetPassword1:       "Password",
    resetPassword2:       "Repeat password",
    resetPasswordSubmit:  "Set password",
    resetPasswordSuccess: "Your password has been reset successfully. You can now log in with this new password.",
    resetPasswordFailed:  "An error occurred setting your new password.",
    resetPasswordUnknown: "This password reset key is unknown.",

    invitationTitle:           "Create account by invitation",
    invitationTitleExisting:   "Add caretakers by invitation",
    invitationBody:            "You are invited by %invitedBy% to get access to the Spiqle Calendar of the following caretaker(s) / caretaker group(s):",
    invitationSubBody:         "To get access, you need to create an account. If you already have an account, you can login to that.",
    invitationSubBodyExisting: "To get access, click 'Accept', if you want to use a different account for this invitation, log out of this account first.",
    invitationTypeMapping:     {
        caretaker:   "Caretaker",
        group:       "Group",
        affiliate:   "Affiliate",
        distributor: "Distributor",
        default:     "Unknown"
    },
    invitationButtonAccept:    "Accept",

    invitationErrorUnknownToken: "This invitation link is not valid.",
    invitationErrorTokenUsed:    "This invitation link has already been used.",
    invitationErrorTokenExpired: "This invitation link has expired, ask the person who invited you to send it again.",
    invitationErrorTokenIsYours: "This invitation link has been sent by yourself. To use it, please log out with this account first.",
    invitationErrorGeneric:      "An error has occurred while using the invitation link.",

    accountTitle:     "Change account details",
    accountName:      "Name",
    accountAddress:   "Address",
    accountZipcode:   "Zip code",
    accountCity:      "City",
    accountState:     "Province",
    accountCountry:   "Country",
    accountLanguage:  "Language",
    accountLanguages: {
        auto:    "Automatisch",
        nl:      "Nederlands",
        en:      "English",
        da:      "Dansk",
        de:      "Deutsch",
        "de-CH": "Schweiz Deutsch"
    },
    accountFontSize:  "Font size",
    accountFontSizes: {
        14: "Small",
        18: "Medium",
        21: "Large"
    },
    accountDayDisplay: "Default day view",
    accountDayOptions: {
        overview: "Overview",
        timeline: "Timeline"
    },

    accountCalendarDisplay: "Default calendar view",
    accountCalendarOptions: {
        overview: "Overview",
        calendar: "Calendar"
    },

    accountAdditionalActions: "Account settings",

    changePasswordTitle:              "Change account password",
    changePasswordOldPassword:        "Current password",
    changePasswordNewPassword:        "New password",
    changePasswordRepeatPassword:     "Repeat new password",
    changePasswordAction:             "Change password",
    changePasswordInvalidOldPassword: "The current password is incorrect. Enter the correct password and try again.",
    changePasswordNotStrong:          "The password isn't strong enough, it requires at least 7 characters and has to contain at least a lower case letter, an upper case letter and a number.",
    changePasswordSuccessful:         "The password has been changed successfully.",

    changeEmailTitle: "Change e-mail address",

    errorAccountLoad:   "An error occurred loading your account details",
    errorAccountSave:   "An error occurred saving your account details",
    successAccountSave: "Your account details have been saved successfully",
};