import React, {useState} from "react";
import {CollapseCard, Form} from "../../components";

import i18n from "../../i18n";

const selectLanguageForm = {
    language: {
        label:        i18n.helpSelectLanguage,
        type:         "select",
        defaultValue: i18n.getLanguage(),
        options:      i18n.helpLanguages
    }
};

const SelectLanguage = props => {
    const [formValues, setFormValues] = useState({});
    const changeForm                  = formValues => {
        props.onChange(formValues.language);
        setFormValues(formValues);
    };

    return (
        <CollapseCard title={i18n.helpSelectLanguage} defaultOpenDesktop defaultOpenMobile>
            <Form formData={selectLanguageForm} values={formValues} onChange={changeForm}/>
        </CollapseCard>
    );
};

export default SelectLanguage;
