// React
import React, {useEffect, useState} from "react";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {push} from "connected-react-router";
import * as actions from "../../../store/actions";

// Tools
import i18n from "../../../i18n";

// Components
import {Button, Form, Spinner, BottomBar, ButtonCrusher, Tour} from "../../../components";
import Avatar from "./Avatar";
import withCaretakers from "../../../hoc/withCaretakers";
import {apiGetFullPath, apiGetToken} from "../../../api";
import {TitleWrapper} from "../../../contexts/TitleProvider";
import provinces from "../../../i18n/provinces";
import {getPageHelpSteps} from "../../../help/tools";
import css from "./Edit.module.css";
import moment from "moment";

const caretakerForm = {
    name:         {label: i18n.caretakerName, className: css.tourName},
    birthday:     {
        label:        i18n.caretakerBirthday,
        className:    css.tourBirthday,
        type:         "date",
        defaultValue: moment(),
        validDates:   date => date.isSameOrBefore(moment(), "day")
    },
    showBirthday: {
        label:      i18n.caretakerShowBirthday,
        className:  css.tourShowBirthday,
        labelValue: [i18n.caretakerShowBirthdayYes, i18n.caretakerShowBirthdayNo],
        type:       "switch",
        noLabel:    true
    },
    address:      {label: i18n.caretakerAddress, className: css.tourAddress, size: 8},
    zipcode:      {label: i18n.caretakerZipcode, className: css.tourZipCode, size: 4, validation: {required: true, minLength: 4}},
    city:         {label: i18n.caretakerCity, className: css.tourCity, size: 4},
    country:      {label: i18n.caretakerCountry, className: css.tourCountry, size: 4, defaultValue: "Nederland", type: "select", options: i18n.countries},
    state:        {
        label:        i18n.caretakerState,
        className:    css.tourProvince,
        size:         4,
        type:         "select",
        selectType:   "slave",
        slaveTo:      "country",
        defaultSlave: "default",
        options:      provinces,
        passValues:   true
    }
};

const Edit = props => {
    const dispatch = useDispatch();
    const loading  = useSelector(state => state.caretakers.loading);
    const saving   = useSelector(state => state.caretakers.saving);

    const [formValues, setFormValues] = useState(props.caretaker ? props.caretaker : {});
    const [formValid, setFormValid]   = useState(false);
    const [helpOpen, setHelpOpen]     = useState(false);
    const doHelp                      = () => setHelpOpen(true);
    const closeHelp                   = () => setHelpOpen(false);

    const handleChange = (newFormValues, newFormValid) => {
        setFormValues(newFormValues);
        setFormValid(newFormValid);
    }
    const handleSubmit = () => {
        if (!formValid) {
            dispatch(actions.messages.errorPush(i18n.errorForm));
            return;
        }
        dispatch(actions.caretakers.save(props.caretakerUuid, formValues));
    }
    const editAvatar   = () => dispatch(push("/caretakers/edit/avatar/" + props.caretakerUuid));

    useEffect(() => {
        if (props.caretaker) setFormValues(props.caretaker)
    }, [props.caretaker]);

    return (
        <TitleWrapper title={"Gegevens " + (props.caretaker ? props.caretaker.name : i18n.caretaker)}>
            <Tour
                isOpen={helpOpen}
                onRequestClose={closeHelp}
                steps={getPageHelpSteps("caretakerEdit")}
            />
            <div>
                <div className="content-form">
                    {props.caretaker && (
                        <div>
                            <Avatar
                                onClick={editAvatar}
                                existingAvatar={
                                    props.caretaker.avatar ?
                                        apiGetFullPath("caretakers/getavatar?uuid=" + props.caretakerUuid + "&token=" + apiGetToken()) :
                                        null
                                }
                            />
                            <Form
                                inlineSelect
                                formData={caretakerForm}
                                values={formValues}
                                onChange={handleChange}
                                onValid={setFormValid}
                                stacked
                            />
                        </div>
                    )}
                    <ButtonCrusher width={78}>
                        <Button
                            block
                            round
                            upper
                            color="info"
                            onClick={handleSubmit}
                            className={css.tourSave}
                        >{i18n.buttonSave}</Button>
                    </ButtonCrusher>
                    <BottomBar
                        backButton
                        backUrl={"/caretakers/settings/" + props.caretakerUuid}
                        onHelp={doHelp}
                        onCloseHelp={closeHelp}
                        helpOpen={helpOpen}
                    />
                    {!props.caretaker || loading || saving ? <Spinner/> : null}
                </div>
            </div>
        </TitleWrapper>
    );
}

export default withCaretakers(Edit);
