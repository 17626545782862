import React, {useState} from 'react';
import {goBack} from "connected-react-router";
import PropTypes from 'prop-types';

import {connect} from "react-redux";
import * as reduxActions from "../../../../store/actions";

import {Button, CollapseCard} from "../../../../components";
import Uncoupler from "../Tools/Uncoupler";
import Swapper from "../Tools/Swapper";
import i18n from "../../../../i18n";

const Actions = props => {
    const [showUncouple, setShowUncouple] = useState(false);
    const [showSwap, setShowSwap]         = useState(false);
    return (
        <>
            <CollapseCard title={i18n.machinesActionsTitle} defaultOpenDesktop actions={[
                <Button color="info" block round simple onClick={props.goBack}>{i18n.buttonBack}</Button>
            ]}>
                <Button color="warning" block round simple onClick={() => props.clearUpdates(props.mac)}>
                    {i18n.machinesActionsClearUpdates}
                </Button>
                <Button color="warning" block round simple onClick={() => props.clearUpdatesAndResend(props.mac)}>
                    {i18n.machinesActionsClearUpdatesAndResend}
                </Button>
                <Button block round simple onClick={() => setShowSwap(true)}>
                    {i18n.machinesActionsSwap}
                </Button>
                <Button color="danger" block round simple onClick={() => setShowUncouple(true)}>
                    {i18n.machinesActionsUncouple}
                </Button>
            </CollapseCard>
            <Uncoupler isOpen={showUncouple} close={() => setShowUncouple(false)} mac={props.mac} redirect={props.actionRedirect}/>
            <Swapper isOpen={showSwap} close={() => setShowSwap(false)} mac={props.mac} redirect={props.actionRedirect}/>
        </>
    );
};

Actions.propTypes = {
    mac:            PropTypes.string.isRequired,
    actionRedirect: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
    clearUpdates:          macAddress => dispatch(reduxActions.machines.clearUpdates(macAddress)),
    clearUpdatesAndResend: macAddress => dispatch(reduxActions.machines.clearUpdatesAndResend(macAddress)),
    goBack:                () => dispatch(goBack())
});

export default connect(null, mapDispatchToProps)(Actions);