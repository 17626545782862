// React
import React, {useEffect, useState} from "react";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {push} from 'connected-react-router'
import * as actions from "../../../store/actions";

// Tools
import i18n from "../../../i18n";

// Components
import {Button, Form, Spinner} from "../../../components";
import {filterFormValues} from "../../../components/Form/formTools";
import Attachments from "../Attachments/Attachments";
import {getPageHelpBindings} from "../../../help/tools";
import {TitleWrapper} from "../../../contexts/TitleProvider";

const Edit = props => {
    const dispatch                    = useDispatch();
    const [formValues, setFormValues] = useState({});
    const [formValid, setFormValid]   = useState(false);

    const loading = useSelector(state => state.help.loading);
    const saving  = useSelector(state => state.help.saving);
    const items   = useSelector(state => state.help.items);

    const helpForm = {
        language:    {
            label:        i18n.helpItemLanguage,
            type:         "select",
            defaultValue: "nl",
            options:      i18n.helpLanguages
        },
        title:       {
            label: i18n.helpItemTitle
        },
        body:        {
            label:         i18n.helpItemBody,
            type:          "textarea",
            elementConfig: {
                rows: 10
            }
        },
        tags:        {
            label:      i18n.helpItemTags,
            type:       "tags",
            validation: false
        },
        pageBinding: {
            label:        i18n.helpItemPageBinding,
            type:         "select",
            defaultValue: "",
            options:      getPageHelpBindings()
        },
        attachments: {label: "", validation: false, type: "hidden", defaultValue: []}
    };

    let item = null;
    if (items) {
        item = items.find(obj => obj.uuid === props.match.params.item);
        if (!item) {
            dispatch(push("/help"));
        }
    }

    useEffect(() => {
        if (props.match.params.item.length !== 32) {
            dispatch(push("/help"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!loading && !saving && !items) {
            dispatch(actions.help.loadAll());
        }
        if (!loading && !saving && items) {
            setFormValues(filterFormValues(helpForm, item));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, saving, items]);


    const handleChange      = (formValues, formValid) => {
        setFormValues(formValues);
        setFormValid(formValid);
    };
    const handleValid       = formValid => setFormValid(formValid);
    const handleSubmit      = () => formValid && dispatch(actions.help.save(props.match.params.item, formValues));
    const handleAttachments = attachments => setFormValues({...formValues, attachments})

    return (
        <TitleWrapper title={i18n.helpEditTitle}>
            <div>
                <div className="content">
                    {item && !loading && !saving ? (
                        <div>
                            <Form formData={helpForm} values={formValues} onChange={handleChange} onValid={handleValid} stacked/>
                            <Attachments onChange={handleAttachments} value={formValues.attachments ? formValues.attachments : []}/>
                            <div className="clearfix">
                                <Button round color="info" className="float-right" disabled={!formValid} onClick={handleSubmit}>{i18n.buttonSave}</Button>
                                <Button round color="info" className="float-left" onClick={() => dispatch(push("/help"))}>{i18n.buttonBack}</Button>
                            </div>
                        </div>
                    ) : <Spinner/>}
                </div>
            </div>
        </TitleWrapper>
    );
};

export default Edit;
