import React from 'react';
import PropTypes from 'prop-types';
import {FormGroup, Label} from "reactstrap";
import i18n from "../../../i18n";
import css from "./WeekSelector.module.css";

const WeekSelector = props => {
    const weekDays = [];
    for (let i = 0; i < 7; i++) {
        let dayNumber = (i + 1) % 7;
        weekDays.push({
            long:  i18n.dateTime.days.long[dayNumber],
            short: i18n.dateTime.days.short[dayNumber]
        });
    }
    const daysChanged = (selected, day) => {
        let newValue = props.settings.value;
        if (selected) {
            newValue = newValue | (1 << day);
        } else {
            newValue = newValue & (127 - (1 << day));
        }
        props.handlers.change(newValue, props.name);
    };

    return (
        <FormGroup key={props.name}>
            {!props.settings.noLabel && <Label disabled={props.disabled}>{props.settings.label}</Label>}
            <div className={css.row}>
                {weekDays.map((day, index) => {
                    let selected = ((props.settings.value >> index) & 1) === 1;
                    return (<div
                        key={index}
                        className={css.day + (selected ? " " + css.daySelected : "")}
                        onClick={() => daysChanged(!selected, index)}
                    >
                        {day.short}
                    </div>);
                })}
            </div>
        </FormGroup>
    );
    /*
                    <Col md={1} key={index}>
                    <FormGroup check>
                        <Label className="form-inline-check-label">
                            <Input
                                type="checkbox"
                                name={props.name + "-" + index}
                                onChange={event => daysChanged(event, index)}
                                checked={((props.settings.value >> index) & 1) === 1}
                                className="form-check-input"
                            />
                            <span className="form-check-sign"/>
                            <span className="full-inline-block">{ucFirst(day.short)}</span>
                            <span className="mobile-inline-block">{ucFirst(day.long)}</span>
                        </Label>
                    </FormGroup>
                </Col>

     */
};

WeekSelector.propTypes = {
    name:     PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    handlers: PropTypes.object.isRequired,
    innerRef: PropTypes.object,
    stacked:  PropTypes.bool
};

//export default WeekSelector;
export default React.memo(WeekSelector, (prevProps, nextProps) => {
    return prevProps.settings.value === nextProps.settings.value &&
        prevProps.settings.valid === nextProps.settings.valid &&
        prevProps.disabled === nextProps.disabled;
});