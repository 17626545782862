import React from 'react';
import PropTypes from 'prop-types';

import i18n from "../../../../i18n";

import {Button, CollapseCard} from "../../../../components";
import CopyRights from "./ActionButtons/CopyRights";
import Duplicate from "./ActionButtons/Duplicate";
import TwoFactor from "./ActionButtons/TwoFactor";
import Delete from "./ActionButtons/Delete";
import LogInAs from "./ActionButtons/LogInAs";

const Actions = props => (
    <>
        <CollapseCard title={i18n.usersActionsTitle} defaultOpenDesktop>
            <Duplicate userId={props.userId} userName={props.userName}/>
            <CopyRights userId={props.userId} userName={props.userName}/>

            <Button color="info" block round simple>
                {i18n.usersActionsViewActivityButton}
            </Button>
            <LogInAs userId={props.userId} userName={props.userName}/>
            {props.twoFactorEnabled && (
                <TwoFactor userId={props.userId} userName={props.userName}/>
            )}
            <Delete userId={props.userId} userName={props.userName}/>
        </CollapseCard>
    </>
);


Actions.propTypes = {
    userId:           PropTypes.string.isRequired,
    userName:         PropTypes.string.isRequired,
    twoFactorEnabled: PropTypes.bool,
    actionRedirect:   PropTypes.string
};

export default Actions;