import React from 'react';
import PropTypes from 'prop-types';
import css from "./Calendars.module.css";
import {useHistory, useParams} from "react-router-dom";

const Day = props => {
    const params  = useParams();
    const history = useHistory();
    const classes = [css.day];
    if (props.isToday) classes.push(css.dayIsToday);
    return (
        <div className={classes.join(" ")}
             onClick={() => history.push("/caretakers/overview/" + params.caretaker + "/" + props.date.format("YYYY-MM-DD"))}>
            {props.date.date()}
            {props.hasItems && <div className={css.dayHasItems} />}
        </div>
    );
};

Day.propTypes = {
    isToday:  PropTypes.bool,
    hasItems: PropTypes.bool,
    date:     PropTypes.object.isRequired
};

export default Day;