// React
import React, {useEffect, useState} from "react";
// Redux
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../../store/actions";
import moment from 'moment-timezone';
// Tools
import i18n from "../../../i18n";
// Components
import {Spinner, BottomBar, Tour} from "../../../components";
import withCaretakers from "../../../hoc/withCaretakers";
import {TitleWrapper} from "../../../contexts/TitleProvider";
import css from "./Calendars.module.css";
import Month from "./Month";
import {calculateAllEventsForRange} from "../../../tools/calendarTools";
import {getPageHelpSteps} from "../../../help/tools";
import {Row} from "reactstrap";

const Calendars = props => {
    const dispatch         = useDispatch();
    const loadingCalendars = useSelector(state => state.calendars.loading);
    const calendarsFor     = useSelector(state => state.calendars.calendarsFor);
    const calendars        = useSelector(state => state.calendars.calendars);
    const loadedAt         = useSelector(state => state.calendars.loadedAt);
    const accountData      = useSelector(state => state.auth.accountData);

    const [helpOpen, setHelpOpen] = useState(false);
    const doHelp                  = () => setHelpOpen(true);
    const closeHelp               = () => setHelpOpen(false);


    useEffect(() => {
        if (props.caretaker && !loadingCalendars && (
            calendarsFor !== props.caretaker.uuid ||
            (!loadedAt || loadedAt.isBefore(moment().subtract(5, "minutes")))
        )) {
            dispatch(actions.calendars.load(props.caretaker.uuid));
        }
    }, [props.caretaker, loadingCalendars, calendarsFor, loadedAt, dispatch])

    if (!props.caretaker || !calendars || props.loading || loadingCalendars) {
        return (
            <TitleWrapper title={i18n.caretakersItemCalendars}>
                <div>
                    <Spinner/>
                    <BottomBar backButton/>
                </div>
            </TitleWrapper>
        );
    }

    /* TODO */
    /* i18n.caretakersItemCalendarsFor.replace("%1", this.props.caretaker ? this.props.caretaker.name : i18n.caretaker) */
    const workMonth = moment();
    workMonth.date(1).hours(12).minutes(0).seconds(0);

    const start  = workMonth.clone();
    const end    = workMonth.clone().add(4, 'months').subtract(1, 'day');
    const events = calculateAllEventsForRange(calendars, start, end);
    const months = [];
    for (let i = 0; i < 4; i++) {
        months.push(<Month date={workMonth.clone()} key={workMonth.month()} events={events} firstMonth={i === 0}/>);
        workMonth.add(1, 'months');
    }

    return (
        <TitleWrapper title={i18n.calendarOverview.replace("%1", props.caretaker.name)}>
            <Tour
                isOpen={helpOpen}
                onRequestClose={closeHelp}
                steps={getPageHelpSteps("calendars")}
            />
            <div>
                <div className={css.messagesContent}>
                    <div className={css.calendar}>
                        <Row>
                            {months}
                        </Row>
                    </div>
                    <BottomBar
                        backButton
                        backUrl={(accountData.calendarDisplay === "overview" ? "/caretakers/calendars/allitems/" + props.caretaker.uuid : "/caretakers/")}
                        helpOpen={helpOpen} onHelp={doHelp} onCloseHelp={closeHelp}
                        links={[
                            {title: i18n.calendarAllItems, link: "/caretakers/calendars/allitems/" + props.caretaker.uuid}
                        ]}
                    />
                </div>
            </div>
        </TitleWrapper>
    );
}

export default withCaretakers(Calendars);
