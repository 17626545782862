import React, {useState} from "react";
import PropTypes from "prop-types";
import {withRouter, Link, NavLink} from "react-router-dom";
import {Collapse, Navbar, NavbarToggler, Nav, NavItem, Container} from "reactstrap";
import {Icon} from "../../../components";

import unauthenticatedRoutes from '../../../routes/unauthenticated';
import i18n from "../../../i18n";

const PagesHeader = props => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle              = () => setIsOpen(!isOpen);

    return (
        <Navbar
            expand="lg"
            className={isOpen ? "bg-white navbar-absolute" : "navbar-transparent navbar-absolute"}
            style={props.stickToTop ? {top: 0} : {}}
        >
            <Container fluid>
                <div className="navbar-wrapper">
                    <div className="navbar-toggle">
                        <NavbarToggler onClick={toggle}>
                            <span className="navbar-toggler-bar bar1"/>
                            <span className="navbar-toggler-bar bar2"/>
                            <span className="navbar-toggler-bar bar3"/>
                        </NavbarToggler>
                    </div>
                    <Link to="/" className="navbar-brand">{i18n.appTitle}</Link>
                </div>
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        {unauthenticatedRoutes.map((prop, key) => {
                            if (prop.redirect || prop.hidden) {
                                return null;
                            }
                            return (
                                <NavItem key={key}>
                                    <NavLink to={prop.path} className="nav-link">
                                        <Icon name={prop.icon} /> {prop.short}
                                    </NavLink>
                                </NavItem>
                            );
                        })}
                    </Nav>
                </Collapse>
            </Container>
        </Navbar>
    );
};

PagesHeader.propTypes = {
    stickToTop: PropTypes.bool
};
export default withRouter(PagesHeader);
