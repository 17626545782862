import React, {Component} from 'react';
import {connect} from "react-redux";

import * as actions from "../../../store/actions";
import i18n from "../../../i18n";
import tableColumns from "../../../variables/tableColumns";

import {Button, Spinner, ColumnSelector, ReactTable, BottomBar} from "../../../components";
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {TitleWrapper} from "../../../contexts/TitleProvider";

class Caretakers extends Component {
    editCaretaker   = uuid => this.props.history.push("/admin/caretakers/edit/" + uuid);
    deleteCaretaker = (uuid, name) => {
        this.setState({
            modalDelete: true,
            deleteUUID:  uuid,
            deleteName:  name
        })
    };

    state = {
        modalDelete: false,
        deleteUUID:  "",
        deleteName:  "",
        columns:     null
    };

    tableColumns = tableColumns.caretakerColumnsDefault([
        {
            color:  "info",
            icon:   "pen",
            action: this.editCaretaker,
            text:   i18n.caretakersActionsEdit
        },
        {
            color:  "danger",
            icon:   "e-remove",
            action: this.deleteCaretaker,
            text:   i18n.caretakersActionsDelete
        }
    ]);

    constructor(props) {
        super(props);
        this.state.columns = this.tableColumns;
        props.load();
    }

    handleDelete      = () => {
        this.props.remove(this.state.deleteUUID);
        this.setState({modalDelete: false});
    };
    toggleModalDelete = () => this.setState(state => ({modalDelete: !state.modalDelete}));

    render = () => (
        <TitleWrapper title={i18n.caretakersTitle}>
            <div>
                <div className="content">
                    {this.props.caretakers && this.props.loadedAt && !this.props.loading && !this.props.saving ? (
                        <>
                            <ColumnSelector cookieId="adminCaretakers" columns={this.state.columns} changed={columns => this.setState({columns})}/>
                            <ReactTable
                                filterable
                                data={this.props.caretakers}
                                columns={this.state.columns}
                                defaultSorted={[{id: "country", desc: false}, {id: "city", desc: false}]}
                                noDataText={i18n.noCaretakers}
                            />
                            <Button round color="info" block onClick={() => this.props.history.push("/admin/caretakers/add")}>
                                {i18n.caretakersItemAdd}
                            </Button>
                        </>
                    ) : <Spinner/>}
                    <BottomBar backButton/>
                </div>
                <Modal isOpen={this.state.modalDelete} toggle={this.toggleModalDelete} className="text-center">
                    <ModalHeader className="justify-content-center uppercase title" tag="h4">{i18n.caretakersRemoveTitle}</ModalHeader>
                    <ModalBody><p>{i18n.caretakersRemoveBody.replace('%1', this.state.deleteName)}</p></ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.handleDelete}>{i18n.caretakersRemoveSure}</Button>
                        <Button color="info" onClick={this.toggleModalDelete}>{i18n.buttonCancel}</Button>
                    </ModalFooter>
                </Modal>
            </div>
        </TitleWrapper>
    );
}

const mapStateToProps    = state => ({
    loading:    state.caretakers.loading,
    saving:     state.caretakers.saving,
    caretakers: state.caretakers.caretakers,
    loadedAt:   state.caretakers.caretakersLoaded
});
const mapDispatchToProps = dispatch => ({
    load:   () => dispatch(actions.caretakers.load()),
    remove: uuid => dispatch(actions.caretakers.remove(uuid))
});

export default connect(mapStateToProps, mapDispatchToProps)(Caretakers);
