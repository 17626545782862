import React, {useRef} from "react";
import PropTypes from "prop-types";

import cssTour from "./AddCaretaker.module.css";
import css from "./Avatar.module.css";
import loadImage from "blueimp-load-image";
import {exifOrientationToRotation} from "../../../variables/tools";

const Avatar = props => {
    const fileInput         = useRef();
    const handleImageChange = e => {
        e.preventDefault();
        let file = e.target.files[0];
        loadImage(file, (img, data) => {
            props.setRotation(exifOrientationToRotation(data));
            let reader       = new FileReader();
            reader.onloadend = () => {
                props.setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }, {meta: true});
        props.onSelect(file);
    };

    return (
        <div className={css.avatarCenter}>
            <div className={css.avatarContainer + " " + cssTour.tourAvatar}>
                <div
                    className={css.avatar}
                    style={{
                        backgroundImage: "url(" + props.previewUrl + ")",
                        transform:       "rotate(" + props.rotation + "deg)"
                    }}
                />
            </div>
            <input
                type="file"
                accept="image/*;capture=camera"
                className={css.avatarFile}
                onChange={handleImageChange}
                ref={fileInput}
            />
        </div>
    );
};

Avatar.propTypes = {
    rotation:      PropTypes.number.isRequired,
    setRotation:   PropTypes.func.isRequired,
    previewUrl:    PropTypes.string,
    setPreviewUrl: PropTypes.func.isRequired,
    onSelect:      PropTypes.func.isRequired
};

export default Avatar;
