import {call, put, takeLatest} from "redux-saga/effects";
import {push} from "connected-react-router";
import * as actions from "../actions";
import * as actionTypes from "../actionTypes";
import * as api from "../../api";
import i18n from "../../i18n";
import moment from 'moment-timezone';

// Watcher
export function* watchGroupCalendars() {
    yield takeLatest(actionTypes.GROUP_CALENDARS_LOAD, loadSaga);
    yield takeLatest(actionTypes.GROUP_CALENDAR_SAVE_APPOINTMENT, saveAppointmentSaga);
    yield takeLatest(actionTypes.GROUP_CALENDAR_SAVE_BIRTHDAY, saveBirthdaySaga);
    yield takeLatest(actionTypes.GROUP_CALENDAR_SAVE_REMINDER, saveReminderSaga);
    yield takeLatest(actionTypes.GROUP_CALENDAR_SAVE_TVSHOW, saveTvShowSaga);
    yield takeLatest(actionTypes.GROUP_CALENDAR_SKIP, skipSaga);
    yield takeLatest(actionTypes.GROUP_CALENDAR_UNSKIP, unskipSaga);
    yield takeLatest(actionTypes.GROUP_CALENDAR_REMOVE, removeSaga);
}

// Tools
function fillInMoments(calendarItem) {
    let newCalendarItem = {...calendarItem, placed: moment.utc(calendarItem.placed, "YYYY-MM-DD HH:mm:ss")};
    if (newCalendarItem.hasOwnProperty("start")) {
        newCalendarItem.start = moment(newCalendarItem.start, "YYYY-MM-DD HH:mm:ss");
    }
    if (newCalendarItem.hasOwnProperty("end")) {
        newCalendarItem.end = moment(newCalendarItem.end, "YYYY-MM-DD HH:mm:ss");
    }
    if (newCalendarItem.hasOwnProperty("startDay")) {
        newCalendarItem.startDay = moment(newCalendarItem.startDay, "YYYY-MM-DD HH:mm:ss");
    }
    if (newCalendarItem.hasOwnProperty("endDay")) {
        newCalendarItem.endDay = moment(newCalendarItem.endDay, "YYYY-MM-DD HH:mm:ss");
    }
    if (newCalendarItem.hasOwnProperty("endInterval")) {
        newCalendarItem.endInterval = moment(newCalendarItem.endInterval, "YYYY-MM-DD HH:mm:ss");
    }
    if (newCalendarItem.hasOwnProperty("nextTime")) {
        newCalendarItem.nextTime = moment(newCalendarItem.nextTime, "YYYY-MM-DD HH:mm:ss");
    }
    if (newCalendarItem.hasOwnProperty("birthday")) {
        newCalendarItem.birthday = moment(newCalendarItem.birthday + " 12:00:00", "YYYY-MM-DD HH:mm:ss");
    }
    return newCalendarItem;
}

// Sagas
function* loadSaga(action) {
    try {
        const response     = yield call(api.groupCalendars.load, action.forGroup);
        const newCalendars = {};
        for (let calendarType in response.data.calendars) {
            if (response.data.calendars.hasOwnProperty(calendarType)) {
                newCalendars[calendarType] = response.data.calendars[calendarType].map(fillInMoments);
            }
        }
        yield put(actions.groupCalendars.loadSuccess(action.forGroup, newCalendars));
    }
    catch (err) {
        yield put(actions.groupCalendars.loadFail());
        yield put(actions.messages.errorPush(i18n.calendarLoadError))
    }
}

function* saveAppointmentSaga(action) {
    try {
        yield call(api.groupCalendars.saveAppointment, action.forGroup, action.appointment);
        yield put(actions.groupCalendars.saveSuccess());
        yield put(actions.messages.successPush(i18n.calendarSaveSuccess.appointments));
        yield put(actions.groupCalendars.load(action.forGroup));
        yield put(push("/groups/calendars/" + action.forGroup));
    }
    catch (err) {
        yield put(actions.groupCalendars.saveFail());
        yield put(actions.messages.errorPush(i18n.calendarSaveFail.appointments))
    }
}

function* saveBirthdaySaga(action) {
    try {
        yield call(api.groupCalendars.saveBirthday, action.forGroup, action.birthday);
        yield put(actions.groupCalendars.saveSuccess());
        yield put(actions.messages.successPush(i18n.calendarSaveSuccess.birthdays));
        yield put(actions.groupCalendars.load(action.forGroup));
        yield put(push("/groups/calendars/" + action.forGroup));
    }
    catch (err) {
        yield put(actions.groupCalendars.saveFail());
        yield put(actions.messages.errorPush(i18n.calendarSaveFail.birthdays))
    }
}

function* saveReminderSaga(action) {
    try {
        yield call(api.groupCalendars.saveReminder, action.forGroup, action.reminder);
        yield put(actions.groupCalendars.saveSuccess());
        yield put(actions.messages.successPush(i18n.calendarSaveSuccess.reminders));
        yield put(actions.groupCalendars.load(action.forGroup));
        yield put(push("/groups/calendars/" + action.forGroup));
    }
    catch (err) {
        yield put(actions.groupCalendars.saveFail());
        yield put(actions.messages.errorPush(i18n.calendarSaveFail.reminders))
    }
}

function* saveTvShowSaga(action) {
    try {
        yield call(api.groupCalendars.saveTvShow, action.forGroup, action.tvShow);
        yield put(actions.groupCalendars.saveSuccess());
        yield put(actions.messages.successPush(i18n.calendarSaveSuccess.tvshows));
        yield put(actions.groupCalendars.load(action.forGroup));
        yield put(push("/groups/calendars/" + action.forGroup));
    }
    catch (err) {
        yield put(actions.groupCalendars.saveFail());
        yield put(actions.messages.errorPush(i18n.calendarSaveFail.tvshows))
    }
}

function* skipSaga(action) {
    try {
        yield call(api.groupCalendars.skip, action.calendarType, action.uuid);
        yield put(actions.groupCalendars.skipSuccess());
        yield put(actions.messages.successPush(i18n.calendarSkipSuccess));
        yield put(actions.groupCalendars.load(action.forGroup));
    }
    catch (err) {
        yield put(actions.groupCalendars.skipFail());
        yield put(actions.messages.errorPush(i18n.calendarSkipFail));
    }
}

function* unskipSaga(action) {
    try {
        yield call(api.groupCalendars.unskip, action.calendarType, action.uuid);
        yield put(actions.groupCalendars.skipSuccess());
        yield put(actions.messages.successPush(i18n.calendarResetSkipSuccess));
        yield put(actions.groupCalendars.load(action.forGroup));
    }
    catch (err) {
        yield put(actions.groupCalendars.skipFail());
        yield put(actions.messages.errorPush(i18n.calendarResetSkipFail));
    }
}

function* removeSaga(action) {
    try {
        yield call(api.groupCalendars.remove, action.calendarType, action.uuid);
        yield put(actions.groupCalendars.removeSuccess());
        yield put(actions.messages.successPush(i18n.calendarDeleteSuccess));
        yield put(actions.groupCalendars.load(action.forGroup));
        yield put(push("/groups/calendars/" + action.forGroup));
    }
    catch (err) {
        yield put(actions.groupCalendars.removeFail());
        yield put(actions.messages.errorPush(i18n.calendarDeleteError));
    }
}