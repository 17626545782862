import * as actionTypes from "../actionTypes";

export const load         = () => ({type: actionTypes.USERS_LOAD});
export const loadIfNeeded = () => ({type: actionTypes.USERS_LOAD_IF_NEEDED});
export const loadSuccess  = users => ({type: actionTypes.USERS_LOAD_SUCCESS, users});
export const loadFail     = error => ({type: actionTypes.USERS_LOAD_FAIL, error});

export const loadInfo        = userId => ({type: actionTypes.USERS_INFO_LOAD, userId});
export const loadInfoSuccess = (userId, userInfo) => ({type: actionTypes.USERS_INFO_LOAD_SUCCESS, userId, userInfo});
export const loadInfoFail    = error => ({type: actionTypes.USERS_INFO_LOAD_FAIL});

export const rightAdd        = (userId, right) => ({type: actionTypes.USERS_RIGHT_ADD, userId, right});
export const rightAddSuccess = () => ({type: actionTypes.USERS_RIGHT_ADD_SUCCESS});
export const rightAddFail    = error => ({type: actionTypes.USERS_RIGHT_ADD_FAIL, error});

export const rightRemove        = (userId, rightId) => ({type: actionTypes.USERS_RIGHT_REMOVE, userId, rightId});
export const rightRemoveSuccess = () => ({type: actionTypes.USERS_RIGHT_REMOVE_SUCCESS});
export const rightRemoveFail    = error => ({type: actionTypes.USERS_RIGHT_REMOVE_FAIL, error});

export const save        = (uuid, userDetails, redirect = "/admin/users") => ({type: actionTypes.USERS_SAVE, uuid, userDetails, redirect});
export const saveSuccess = () => ({type: actionTypes.USERS_SAVE_SUCCESS});
export const saveFail    = error => ({type: actionTypes.USERS_SAVE_FAIL, error});

export const remove        = uuid => ({type: actionTypes.USERS_REMOVE, uuid});
export const removeSuccess = () => ({type: actionTypes.USERS_REMOVE_SUCCESS});
export const removeFail    = error => ({type: actionTypes.USERS_REMOVE_FAIL, error});

export const duplicate        = (uuid, userInfo) => ({type: actionTypes.USERS_DUPLICATE, uuid, userInfo});
export const duplicateSuccess = () => ({type: actionTypes.USERS_DUPLICATE_SUCCESS});
export const duplicateFail    = error => ({type: actionTypes.USERS_DUPLICATE_FAIL, error});

export const disable2fa        = uuid => ({type: actionTypes.USERS_DISABLE_2FA, uuid});
export const disable2faSuccess = () => ({type: actionTypes.USERS_DISABLE_2FA_SUCCESS});
export const disable2faFail    = error => ({type: actionTypes.USERS_DISABLE_2FA_FAIL, error});

export const copyRights        = (fromUser, toUser, replace) => ({type: actionTypes.USERS_COPY_RIGHTS, fromUser, toUser, replace});
export const copyRightsSuccess = () => ({type: actionTypes.USERS_COPY_RIGHTS_SUCCESS});
export const copyRightsFail    = error => ({type: actionTypes.USERS_COPY_RIGHTS_FAIL, error});

export const resetDoneStates = () => ({type: actionTypes.USERS_RESET_DONE_STATES});

export const loginAs     = uuid => ({type: actionTypes.USERS_LOGIN_AS, uuid});
export const logInAsFail = error => ({type: actionTypes.USERS_LOGIN_AS_FAIL, error});

export const clearError = () => ({type: actionTypes.USERS_RESET_ERROR});
