import React from 'react';
import PropTypes from 'prop-types';
import {Col, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label} from "reactstrap";
import {Icon} from "../../index";

const WithIcon = props => {
    let className = "";
    if (props.settings.touched && props.settings.validation) {
        className = props.settings.valid ? "has-success" : "has-danger";
    }

    return (
        <FormGroup row>
            {!props.settings.noLabel ? <Label md={2}>{props.settings.label}</Label> : null}
            <Col md={!props.settings.noLabel ? 10 : 12}>
                <InputGroup id={props.name} className={"form-control-lg " + className + (props.settings.focused ? " input-group-focus" : "")}>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <Icon name={props.settings.icon}/>
                        </InputGroupText>
                    </InputGroupAddon>
                    {props.children}
                </InputGroup>
            </Col>
        </FormGroup>
    );
};

WithIcon.propTypes = {
    name:     PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired
};

export default WithIcon;