import React from 'react';
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import css from "./BottomBar.module.css";
import i18n from "../../../i18n";
import {Icon} from "../../index";

const BottomBar = props => {
    const doHelp      = () => props.onHelp && props.onHelp();
    const doCloseHelp = () => props.onCloseHelp && props.onCloseHelp();
    const linkCount   = (props.backButton ? 1 : 0) + (props.links ? props.links.length : 0) + (props.funcs ? props.funcs.length : 0);
    let linkIndex     = 0;
    return (
        <>
            <div className={(props.dontMoveToBottom ? css.nomoveBottomBar : css.bottomBar) + " clearfix"}>
                <div className={css.barGradient} />
                <div className={css.barContent}>
                    {props.links && props.links.map(link => (
                        <button
                            key={link.link}
                            onClick={() => props.history.push(link.link)}
                            className={[css.link, "bottomLink" + linkIndex++].join(" ")}
                        >{link.title}</button>
                    ))}
                    {props.funcs && props.funcs.map(func => (
                        <button
                            key={linkIndex}
                            onClick={func.func}
                            className={[css.link, "bottomLink" + linkIndex++].join(" ")}
                        >{func.title}</button>
                    ))}
                    {props.backButton && (<button onClick={() => {
                        if (props.backUrl) {
                            props.history.push(props.backUrl);
                        } else {
                            props.history.goBack();
                        }
                    }} className={css.backButton}>{i18n.buttonBack}</button>)}
                    {props.addButton && (<button onClick={props.addButton} className={css.addButton}><Icon name="e-add"/></button>)}
                    {props.onHelp && (
                        !props.helpOpen ? (
                            <div className={css.helpButton} onClick={doHelp}>
                                <Icon color="info" name="c-question-2"/>
                            </div>
                        ) : (
                            <div className={css.helpButton} onClick={doCloseHelp}>
                                <Icon color="info" name="c-remove"/>
                            </div>
                        )
                    )}
                </div>
            </div>
            <div className={css.bottomPadding} style={{height: (80 + (linkCount * 30)) + "px"}}/>
        </>
    );
};

BottomBar.propTypes = {
    backButton:       PropTypes.bool,
    backUrl:          PropTypes.string,
    dontMoveToBottom: PropTypes.bool,
    onHelp:           PropTypes.func,
    onCloseHelp:      PropTypes.func,
    addButton:        PropTypes.func,
    helpOpen:         PropTypes.bool,
    links:            PropTypes.array,
    funcs:            PropTypes.array
};

export default withRouter(BottomBar);