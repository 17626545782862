// React
import React, {useEffect, useState} from "react";

// Redux
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../store/actions";

// Tools
import i18n from "../../i18n";

// Components
import {Button, Form, BottomBar, Spinner, Tour, ButtonCrusher} from "../../components";
import {TitleWrapper} from "../../contexts/TitleProvider";
import {getPageHelpSteps} from "../../help/tools";
import css from "./Account.module.css";

const accountForm = {
    name:                   {
        label:     i18n.caretakerName,
        className: css.tourName
    },
    language:               {
        label:     i18n.accountLanguage,
        className: css.tourLanguage,
        type:      "select",
        options:   i18n.accountLanguages
    },
    dayDisplay:      {
        label:     i18n.accountDayDisplay,
        className: css.tourDayDisplay,
        type:      "select",
        options:   i18n.accountDayOptions
    },
    calendarDisplay: {
        label:     i18n.accountCalendarDisplay,
        className: css.tourCalendarDisplay,
        type:      "select",
        options:   i18n.accountCalendarOptions
    }
};

const Account = props => {
    const dispatch    = useDispatch();
    const accountData = useSelector(state => state.auth.accountData);
    const loading     = useSelector(state => state.auth.loading);

    const [helpOpen, setHelpOpen] = useState(false);
    const doHelp                  = () => setHelpOpen(true);
    const closeHelp               = () => setHelpOpen(false);

    const [formValues, setFormValues] = useState({});
    const [formValid, setFormValid]   = useState(false);
    const handleChange                = (formValues, formValid) => {
        setFormValues(formValues);
        setFormValid(formValid);
    };
    const handleSubmit                = () => formValid && dispatch(actions.auth.saveAccountData(formValues));
    useEffect(() => {
        setFormValues(accountData);
    }, [accountData]);

    return (
        <TitleWrapper title={i18n.routeAccount}>
            <Tour
                isOpen={helpOpen}
                onRequestClose={closeHelp}
                steps={getPageHelpSteps("account")}
            />
            <div>
                <div className="content-form">
                    <Form
                        inlineSelect
                        formData={accountForm}
                        values={formValues}
                        onChange={handleChange}
                        onValid={setFormValid}
                        stacked
                    />
                    <ButtonCrusher width={74} topSpacer={25}>
                        <Button
                            marginTop
                            upper
                            block
                            round
                            color="info"
                            disabled={!formValid}
                            onClick={handleSubmit}>{i18n.buttonSave}</Button>
                    </ButtonCrusher>
                    <BottomBar
                        backButton onHelp={doHelp} onCloseHelp={closeHelp} helpOpen={helpOpen}
                        links={[
                            {link: "/account/password", title: i18n.changePasswordTitle}
                        ]}/>
                </div>
                {loading && <Spinner/>}
            </div>
        </TitleWrapper>
    );
}

export default Account;
