export default {
    user:    "User",
    users:   "Users",
    noUsers: "No users",

    usersTitle:        "Users",
    usersTableName:    "Name",
    usersTableAddress: "Address",
    usersTableZipcode: "Zipcode",
    usersTableCity:    "City",
    usersTableState:   "Province",
    usersTableCountry: "Country",
    usersTablePhone:   "Phone",
    usersTableEmail:   "E-mail",
    usersTableLogin:   "Last login",

    usersTableAdmin:            "Super admin",
    usersTableDisabled:         "Disabled",
    usersTableEmailConfirmed:   "E-mail address confirmed",
    usersTableTwoFactorEnabled: "Two-factor enabled",
    usersActionsTitle:          "Actions for this user",
    usersActionsInfo:           "User info",
    usersActionsDelete:         "Delete",

    usersActionsDeleteButton:       "Delete user",
    usersActionsDuplicateButton:    "Duplicate user",
    usersActionsCopyRightsButton:   "Copy user rights to different user",
    usersActionsViewActivityButton: "Show user activity log",
    usersActionsLogInAsButton:      "Log in as this user",
    usersActionsDisableTwoFactor:   "Turn two-factor authentication off",

    usersAddTitle:    "Add user",
    usersEditTitle:   "Edit user",
    usersAddSuccess:  "The new account has been created, the user has received a temporary password via e-mail.",
    usersEditSuccess: "The details of this user have been saved successfully",
    usersEditError:   "An error occurred while saving the user details",

    usersLogInAsTitle: "Are you sure you want to change to this user?",
    usersLogInAsBody:  "Are you sure you want to log in as '%1'? To return to your own account, you'll have to log out and log back in again.",
    usersLogInAsSure:  "Log in as this user",
    usersLogInAsFail:  "An error occurred while logging in as this user",

    usersDuplicateTitle:   "Duplicate user",
    usersDuplicateBody:    "Create a new user with the same rights as '%1'.",
    usersDuplicateSure:    "Duplicate user",
    usersDuplicateSuccess: "The user has been successfully duplicated. The new user received a temporary password via e-mail.",
    usersDuplicateError:   "The user could not be duplicated.",

    usersCopyTitle:           "Copy user rights to a different user",
    usersCopyBody:            "Copy the rights for '%1' to a different user.",
    usersCopyToUser:          "To user",
    usersCopyReplace:         "Replace rights",
    usersCopyReplaceExpanded: "Replace the rights, instead of adding them",
    usersCopySure:            "Copy rights",
    usersCopySuccess:         "The rights of the user have been successfully copied.",
    usersCopyError:           "The rights of the user could not be copied.",

    usersTwoFactorDisableTitle:   "Are you sure you want to disable two-factor authentication for this user?",
    usersTwoFactorDisableBody:    "'%1' can login without two-factor authentication and re-enable two-factor authentication themselves, after this",
    usersTwoFactorDisableSure:    "Turn two-factor authentication off",
    usersTwoFactorDisableSuccess: "Two-factor authentication for this user has been successfully disabled.",
    usersTwoFactorDisableError:   "Two-factor authentication for this user could not be disabled.",

    usersRemoveTitle:   "Are you sure you want to delete this user?",
    usersRemoveBody:    "If you remove the user named '%1', all machines currently coupled to this user will be inaccessible if no-one else has rights to them. This can not be undone.",
    usersRemoveSure:    "Delete user",
    usersRemoveSuccess: "The user has been deleted successfully",
    usersRemoveError:   "An error occurred while deleting the user",

    usersInfoTitle:     "User info",
    usersInfoLoadError: "An error occurred while loading the user info",
    usersInfoDataHead:  "User details for %1",

    usersRightsHead:          "User rights",
    usersRightsTableType:     "Right type",
    usersRightsTableName:     "Name",
    usersRightsTableDelete:   "Remove right",
    usersRightsAddRightTitle: "Add rights to this user",
    usersRightsAddRight:      "Add right",
    usersRightsCalendar:      "Calendar rights",
    usersRightsSettings:      "Screen setting rights",
    usersRightsMessages:      "Message rights",
    usersRightsDetails:       "Caretaker details rights",
    usersRightsPreview:       "Preview rights",
    usersRightsTechnical:     "Technical rights",
    usersRightsInvite:        "Uitnodig rechten",
    usersRightsPayments:      "Betalings rechten",
    usersRightsNoRights:      "This user has no rights yet",
    usersRightsHasSuperAdmin: "This user is a super admin",

    usersRightsPresetNoRights:  "Preset no rights",
    usersRightsPresetCaretaker: "Preset for a caretaker",
    usersRightsPresetAdmin:     "Preset for an administrator",

    usersRightsCalendarRight:  [
        "No calendar rights",
        "View calendars",
        "Edit calendars"
    ],
    usersRightsSettingsRight:  [
        "No screen setting rights",
        "Show screen settings",
        "Edit screen settings"
    ],
    usersRightsMessagesRight:  [
        "No direct message rights",
        "Send direct messages",
        "Send direct messages and view history"
    ],
    usersRightsDetailsRight:   [
        "No caretaker detail rights",
        "Show caretaker details",
        "Edit caretaker details"
    ],
    usersRightsPreviewRight:   [
        "No preview rights",
        "Enable screen preview"
    ],
    usersRightsTechnicalRight: [
        "No technical rights",
        "Show technical machine details",
        "Technical admin rights"
    ],
    usersRightsInviteRight:    [
        "Geen uitnodigings rechten",
        "Rechten om nieuwe gebruikers uit te nodigen"
    ],
    usersRightsPaymentsRight:  [
        "Geen betalings rechten",
        "Rechten om de betalingen te beheren"
    ],

    usersRightsTypeCaretaker:   "Caretaker",
    usersRightsTypeGroup:       "Group",
    usersRightsTypeDistributor: "Distributor",
    usersRightsTypeAffiliate:   "Affiliate",
    usersRightsTypeMapping:     {
        caretaker:   "Caretaker",
        group:       "Group",
        distributor: "Distributor",
        affiliate:   "Affiliate"
    },

    userName:    "Name",
    userAddress: "Address",
    userZipcode: "Zipcode",
    userCity:    "City",
    userState:   "Province",
    userCountry: "Country",
    userPhone:   "Phone number",
    userEmail:   "E-mail address",
};