import React from "react";
import PropTypes from "prop-types";
import {useHistory} from 'react-router-dom'

import {apiGetFullPath, apiGetToken} from "../../api";
import i18n from "../../i18n";
import {appRootPath} from "../../variables/multiplatform";

import {Button, Icon, UncontrolledTooltip} from "../../components";
import defaultAvatar from "../../assets/img/placeholder.jpg";
import css from "./CaretakerItem.module.css";

const GroupItem = props => {
    const history         = useHistory();
    return (
        <div className={css.item}>
            <div className={css.avatar}>
                <div className={[css.imgWrapper, css.machineIndicator, css.machineNone].join(" ")}>
                    <img src={
                        props.avatar ?
                            apiGetFullPath("groups/getavatar?uuid=" + props.uuid + "&token=" + apiGetToken()) :
                            appRootPath + defaultAvatar
                    } alt="..."/>
                </div>
            </div>
            <div className={css.text}>
                <div className={css.title}>
                    {props.name}
                </div>
                <div className={css.icons}>
                    <Button className={css.tourSettings} id={"edit" + props.uuid} onClick={() => history.push('/groups/edit/' + props.uuid)} round icon
                            neutral color="info">
                        <Icon name="settings-gear"/>
                    </Button>
                    <UncontrolledTooltip boundariesElement="window" placement="top" target={"edit" + props.uuid} delay={0}>
                        {i18n.caretakersItemEdit}
                    </UncontrolledTooltip>

                    <Button className={css.tourCalendars} id={"calendar" + props.uuid} onClick={() => history.push('/groups/calendars/' + props.uuid)} round
                            icon neutral
                            color="info">
                        <Icon name="calendar-2"/>
                    </Button>
                    <UncontrolledTooltip boundariesElement="window" placement="top" target={"calendar" + props.uuid} delay={0}>
                        {i18n.caretakersItemCalendars}
                    </UncontrolledTooltip>

                    <Button className={css.tourList} id={"list" + props.uuid} onClick={() => history.push('/groups/list/' + props.uuid)} round
                            icon neutral
                            color="info">
                        <Icon name="multiple-11"/>
                    </Button>
                    <UncontrolledTooltip boundariesElement="window" placement="top" target={"calendar" + props.uuid} delay={0}>
                        {i18n.caretakersItemCalendars}
                    </UncontrolledTooltip>
                </div>
            </div>
            <div className={css.add} onClick={() => history.push('/groups/add/' + props.uuid)}>
                <div>+</div>
            </div>
        </div>
    );
};

GroupItem.propTypes = {
    id:            PropTypes.number,
    name:          PropTypes.string,
    avatar:        PropTypes.bool,
    showHelpItem:  PropTypes.bool,
    showHelpIndex: PropTypes.number
};

export default GroupItem;
