import React from 'react';
import PropTypes from 'prop-types';
import {FormGroup, Label} from "reactstrap";
import SwitchBS from "react-bootstrap-switch";
import AutoWrapInput from "../InputContainers/AutoWrapInput";
import css from "./Switch.module.css";

/*
                    onText={<Icon name="check" color="success-darker"/>}
                    offText={<Icon name="e-remove" color="danger-darker"/>}
 */
const Switch = props => {
    let value = props.settings.value;
    if(props.settings.invertValue) {
        value = !value;
    }
    const onChange = (e,state) => {
        let newValue = state;
        if(props.settings.invertValue) {
            newValue = !newValue;
        }
        props.handlers.change(newValue, props.name);
    }
    const params      = {
        name:     props.name,
        settings: props.settings,
        stacked:  props.stacked,
        disabled: props.disabled
    };
    return (
        <AutoWrapInput {...params}>
            <FormGroup>
                <Label className="switch-label">
                    <div className={css.container}>
                        <SwitchBS
                            {...props.settings.elementConfig}
                            onText={null}
                            offText={null}
                            disabled={props.disabled}
                            name={props.name}
                            onColor={"#FF0000"}
                            onChange={onChange}
                            onFocus={() => props.handlers.focus(props.name, true)}
                            onBlur={() => props.handlers.focus(props.name, false)}
                            className={css.switch}
                            value={value}
                            ref={props.innerRef}
                        />
                        <div className={css.extendedLabel}>
                            {" "}
                            {props.settings.labelExtended && props.settings.labelExtended}
                            {props.settings.labelValue && props.settings.labelValue[value ? 0 : 1]}
                        </div>
                    </div>
                </Label>
            </FormGroup>
        </AutoWrapInput>
    );
}

Switch.propTypes = {
    name:     PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    handlers: PropTypes.object.isRequired,
    innerRef: PropTypes.object,
    stacked:  PropTypes.bool
};

export default React.memo(Switch, (prevProps, nextProps) => {
    return prevProps.settings.value === nextProps.settings.value &&
        prevProps.settings.valid === nextProps.settings.valid &&
        prevProps.disabled === nextProps.disabled;
});