import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';

import {deepCompare} from "../../../../variables/tools";
import * as actions from "../../../../store/actions";
import i18n from "../../../../i18n";
import tableColumns from "../../../../variables/tableColumns";

import {Card, CardBody} from 'reactstrap';
import AutoWrapInput from "../../InputContainers/AutoWrapInput";
import {ColumnSelector, ReactTable} from "../../.."
import {push} from "connected-react-router";

class Caretakers extends Component {

    setCaretaker = (caretaker, value) => this.setState(oldState => ({selectedCaretakers: {...oldState.selectedCaretakers, [caretaker]: value}}));
    selector     = {
        accessor:  "uuid",
        onChange:  this.setCaretaker,
        isChecked: caretaker => this.state.selectedCaretakers[caretaker]
    };

    state = {
        selectedCaretakers: {},
        filteredCaretakers: []
    };

    constructor(props) {
        super(props);
        props.load();
        if (props.caretakers) {
            this.state.filteredCaretakers = this.filterCaretakers();
        }
        this.state.columns = this.getColumns();
        if(props.settings && props.settings.value) {
            this.state.selectedCaretakers = props.settings.value.reduce((obj, item) => {obj[item] = true; return obj}, {});
        }
    }

    filterCaretakers = () => {
        let caretakerList = [...this.props.caretakers];

        if (this.props.settings.requiredRight && this.props.settings.requiredRightLevel) {
            caretakerList = caretakerList.filter(caretaker => {
                if (
                    !this.props.caretakerRights.hasOwnProperty(caretaker.uuid) ||
                    !this.props.caretakerRights[caretaker.uuid][this.props.settings.requiredRight]
                ) {
                    return false;
                }
                return this.props.caretakerRights[caretaker.uuid][this.props.settings.requiredRight] >= this.props.settings.requiredRightLevel;
            });
        }

        if (!this.props.settings.exclude) {
            return caretakerList;
        }
        return caretakerList.filter(caretaker => !this.props.settings.exclude.includes(caretaker.uuid));
    };

    getColumns = () => this.props.settings.simpleColumns ?
                       tableColumns.caretakerColumnsSimple(null, this.selector) :
                       tableColumns.caretakerColumnsDefault(null, this.selector);

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.settings.value !== this.props.settings.value) {
            this.setState({
                selectedCaretakers: this.props.settings.value.reduce((obj, item) => {
                    obj[item] = true;
                    return obj
                }, {})
            });
        }

        if (prevProps.caretakers !== this.props.caretakers) {
            this.setState({filteredCaretakers: this.filterCaretakers()});
        }

        if (prevState.selectedCaretakers !== this.state.selectedCaretakers) {
            // Only fire change handler if the output array is different from settings.value
            let outputValue = Object.entries(this.state.selectedCaretakers).filter(item => item[1]).map(item => item[0]);
            let same        = true;
            if (outputValue.length !== this.props.settings.value.length) {
                same = false;
            }
            if (same) {
                for (let i of outputValue) {
                    if (this.props.settings.value.indexOf(i) === -1) {
                        same = false;
                        break;
                    }
                }
            }
            if (!same) {
                this.props.handlers.change(outputValue, this.props.name);
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state.selectedCaretakers !== nextState.selectedCaretakers ||
               this.state.filteredCaretakers !== nextState.filteredCaretakers ||
               this.state.columns !== nextState.columns ||
               this.props.caretakers !== nextProps.caretakers ||
               this.props.loading !== nextProps.loading ||
               this.props.loadedAt !== nextProps.loadedAt ||
               this.props.settings.value !== nextProps.settings.value ||
               this.props.settings.focused !== nextProps.settings.focused ||
               this.props.settings.valid !== nextProps.settings.valid ||
               !deepCompare(this.props.settings.options, nextProps.settings.options);
    }

    render() {
        return (
            <AutoWrapInput name={this.props.name} settings={this.props.settings} stacked={this.props.stacked}>
                {!this.props.loading && this.props.caretakers ? (
                    <Card>
                        <CardBody>
                            {this.props.settings.simpleColumns || (
                                <ColumnSelector cookieId="inputCaretakers" columns={this.state.columns} changed={columns => this.setState({columns})}/>
                            )}
                            <ReactTable
                                filterable
                                indexKey="id"
                                data={this.state.filteredCaretakers}
                                columns={this.state.columns}
                                noDataText={i18n.noCaretakers}
                            />
                        </CardBody>
                    </Card>
                ) : <p>{i18n.loading}</p>}
            </AutoWrapInput>
        );
    }
}

const mapStateToProps = state => ({
    caretakers:      state.caretakers.caretakers,
    loading:         state.caretakers.loading,
    loadedAt:        state.caretakers.caretakersLoaded,
    caretakerRights: state.auth.caretakerRights
});

const mapDispatchToProps = dispatch => ({
    push: pathname => dispatch(push(pathname)),
    load: () => dispatch(actions.caretakers.loadIfNeeded())
});

Caretakers.propTypes = {
    name:     PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    handlers: PropTypes.object.isRequired,
    innerRef: PropTypes.object,
    stacked:  PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(Caretakers);
