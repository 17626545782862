export default {
    appTitle: "Spiqle Agenda",

    loading:     "Laden...",
    loadedAt:    "Zuletzt aktualisiert um:",
    pageText:    "Seite",
    ofText:      "von der",
    rowsText:    "Regeln",
    showColumns: "Spalten auswählen",

    image:     "Bild",
    all:       "Alle",
    yes:       "Ja",
    no:        "Nein",
    unknown:   "Unbekannt",
    noOptions: "Keine Optionen verfügbar",

    successTitle: "Bestanden",
    errorTitle:   "Fehler",

    buttonEdit:     "Bearbeiten",
    buttonSave:     "Speichern",
    buttonSend:     "Senden",
    buttonNext:     "Nächste",
    buttonPrevious: "Vorherige",
    buttonBack:     "Zurück",
    buttonFinish:   "Fertig",
    buttonCancel:   "Abbrechen",
    buttonInvite:   "Einladen",
    buttonAdd:      "Hinzufügen",

    searchPlaceholder: "Suchen...",

    contactTitle:       "Kontakt",
    contactSubtitle:    "Haben Sie eine Frage oder eine Anregung? Dann kontaktieren Sie uns über dieses Formular.",
    contactFormTitle:   "Titel",
    contactFormBody:    "Frage oder Vorschlag",
    successContactSend: "Das Kontaktformular wurde erfolgreich versendet",
    errorContactSend:   "Beim Senden des Kontaktformulars ist ein Fehler aufgetreten",

    validationErrors: {
        required:  "Dieses Feld ist erforderlich",
        minLength: "Die Mindestlänge für dieses Feld beträgt %1",
        maxLength: "Die maximale Länge für dieses Feld beträgt %1",
        isEmail:   "Dies ist keine gültige E-Mail-Adresse",
        regex:     "Dies ist kein gültiger Wert für dieses Feld",
        func:      "Dies ist kein gültiger Wert für dieses Feld"
    },

    errorForm: "Es werden nicht alle Felder eingegeben",

    errors: {
        CONNECTION:              "Verbindungsproblem, überprüfen Sie Ihre Internetverbindung",
        DEFAULT:                 "Allgemeiner Fehler, versuchen Sie es später erneut",
        CALL_DISABLED:           "Diese Funktion ist derzeit deaktiviert",
        LOCAL_ONLY:              "",
        INVALID_REQUEST:         "Fehler beim Abrufen oder Speichern von Daten. Verwenden Sie die neueste Version der App?",
        INVALID_EMAIL:           "Fehler beim Abrufen oder Speichern der Daten, die E-Mail ist ungültig",
        INVALID_GUID:            "Fehler beim Abrufen oder Speichern von Daten, die GUID ist ungültig",
        INVALID_MAC:             "Fehler beim Abrufen oder Speichern der Daten, die MAC-Adresse ist ungültig",
        INVALID_FILE:            "Fehler beim Abrufen oder Speichern der Daten, die ausgewählte Datei ist ungültig",
        INVALID_TOKEN:           "Fehler beim Abrufen oder Speichern von Daten, ungültiges Anmeldetoken",
        INVALID_LIST:            "Fehler beim Abrufen oder Speichern der Daten, die Liste ist ungültig",
        INVALID_INTEGER:         "Fehler beim Abrufen oder Speichern der Daten, die Nummer ist ungültig",
        INVALID_MONGOID:         "Fehler beim Abrufen oder Speichern von Daten, die Datenbank-ID ist ungültig",
        INVALID_FLOAT:           "Fehler beim Abrufen oder Speichern der Daten, die Nummer ist ungültig",
        INVALID_ARRAY:           "Fehler beim Abrufen oder Speichern der Daten, die Liste ist ungültig",
        INVALID_JSON:            "Fehler beim Abrufen oder Speichern von Daten, ein Parameter ist ungültig",
        NO_AUTH:                 "Fehler beim Abrufen oder Speichern von Daten, Sie sind nicht angemeldet",
        UNKNOWN_CREDENTIALS:     "Unbekannte E-Mail oder Passwort",
        SIGNUP_ERROR_CONNECTION: "Erstellen eines Kontos ist fehlgeschlagen. Überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.",
        SIGNUP_ERROR_EXISTS:     "Erstellen eines Kontos ist fehlgeschlagen. Ein Konto mit dieser E-Mail ist bereits vorhanden.",
        addNewDisplay:           {
            INVALID_DISPLAY:         "Fehler beim Verknüpfen des Bildschirms, unbekannter Bildschirmcode. Geben Sie den Code genau so ein, wie er auf dem Bildschirm angezeigt wird",
            DISPLAY_ALREADY_COUPLED: "Fehler beim Verknüpfen des Bildschirms, dieser Bildschirm ist bereits verknüpft",
            INVALID_CARETAKER:       "Fehler beim Verknüpfen des Bildschirms, dieser Pflegeempfänger ist unbekannt",
            INVALID_REQUEST:         "Fehler beim Verknüpfen des Bildschirms, geben Sie alle Daten ein"
        }
    },

    countries: {
        Andorra:          "Andorra",
        "België":         "Belgien",
        "Bŭlgarija":      "Bulgarien",
        "Kýpros":         "Zypern",
        Danmark:          "Dänemark",
        Deutschland:      "Deutschland",
        Eesti:            "Estland",
        Suomi:            "Finnland",
        France:           "Frankreich",
        "Elláda":         "Griechenland",
        "Magyarország":   "Ungarn",
        Hrvatska:         "Kroatien",
        Ireland:          "Irland",
        Italia:           "Italien",
        Latvija:          "Lettland",
        Lietuva:          "Litauen",
        Luxembourg:       "Luxemburg",
        Malta:            "Malta",
        Nederland:        "Niederlande",
        Norge:            "Norwegen",
        "Österreich":     "Österreich",
        Polska:           "Polen",
        Portugal:         "Portugal",
        "România":        "Rumänien",
        Slovenija:        "Slowenien",
        Slovensko:        "Slowakei",
        "España":         "Spanien",
        "Česko":          "Die Tschechische Republik",
        "United Kingdom": "Vereinigtes Königreich",
        Sverige:          "Schweden",
        Schweiz:          "Schweiz"
    },

    dateFormat: "dd D MMM HH:mm",
    dateTime:   {
        months: {
            long:  [
                "Januar",
                "Februar",
                "März",
                "April",
                "Mai",
                "Juni",
                "Juli",
                "August",
                "september",
                "Oktober",
                "November",
                "Dezember"
            ],
            short: [
                "Jan",
                "Feb",
                "März",
                "Apr",
                "Mai",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Okt",
                "Nov",
                "Dez"
            ]
        },
        days:   {
            long:  [
                "Sonntag",
                "Montag",
                "Dienstag",
                "Mittwoch",
                "Donnerstag",
                "Freitag",
                "Samstag"
            ],
            short: [
                "So",
                "Mo",
                "Di",
                "Mw",
                "Do",
                "Fr",
                "Sa"
            ],
            count: [
                "",
                "1.",
                "2.",
                "3.",
                "4.",
                "5.",
                "6.",
                "7.",
                "8.",
                "9.",
                "10.",
                "11.",
                "12.",
                "13.",
                "14.",
                "15.",
                "16.",
                "17.",
                "18.",
                "19.",
                "20.",
                "21.",
                "22.",
                "23.",
                "24.",
                "25.",
                "26.",
                "27.",
                "28.",
                "29.",
                "30.",
                "31."
            ]
        }
    }
};