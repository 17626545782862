import React from "react";
import i18n from "../../i18n";
import css from "./tableColumns.module.css";
import {ResponsiveText} from "../../components";
import {standardColumn, defaultColumns} from "./funcs";

export const distributorColumns = {
    name:     standardColumn("name", i18n.distributorsTableName, {className: css.name, togglable: false}),
    city:     standardColumn("city", i18n.distributorsTableCity, {show: false}),
    country:  standardColumn("country", i18n.distributorsTableCountry, {show: false}),
    phone:    standardColumn("phone", i18n.distributorsTablePhone, {show: false}),
    email:    standardColumn("email", i18n.distributorsTableEmail, {show: false}),
    machines: {
        id:              "machines",
        togglable:       false,
        Header:          i18n.distributorsTableMachines,
        headerClassName: css.machineHeader,
        minWidth:        80,
        maxWidth:        200,
        columns:         [
            {
                id:              "sent",
                Header:          <ResponsiveText long={i18n.distributorsTableSent} short={i18n.distributorsTableSentShort}
                                                 inline/>,
                accessor:        d => d.machines.sent,
                className:       css.counter,
                headerClassName: css.counterHeader,
                minWidth:        40,
                maxWidth:        100
            },
            {
                id:              "coupled",
                Header:          <ResponsiveText long={i18n.distributorsTableCoupled} short={i18n.distributorsTableCoupledShort} inline/>,
                accessor:        d => d.machines.coupled,
                className:       css.counter,
                headerClassName: css.counterHeader,
                minWidth:        40,
                maxWidth:        100
            }
        ]
    }
};

export const distributorColumnsDefault = (actions = null, selector = null) => defaultColumns(
    [
        distributorColumns.name,
        distributorColumns.city,
        distributorColumns.country,
        distributorColumns.machines
    ], actions, "uuid", selector
);
