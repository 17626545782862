// React
import React from "react";

// Redux
import {connect} from "react-redux";
import {push} from 'connected-react-router'
import * as actions from "../../../store/actions";

// Tools
import i18n from "../../../i18n";
import tableColumns from "../../../variables/tableColumns";

// Components
import {Spinner, ColumnSelector, ReactTable} from "../../../components";

import {TitleWrapper} from "../../../contexts/TitleProvider";

class Status extends React.Component {
    state = {columns: null};

    tableColumns = tableColumns.machineColumnsDefaultRedux(
        () => this.props.caretakers,
        () => this.props.distributors,
        () => this.props.affiliates,
        [
            {
                color:  "info",
                icon:   "c-info",
                action: value => this.props.push("/admin/machines/info/" + value),
                text:   i18n.machineInfoTitle
            }
        ]
    );

    constructor(props) {
        super(props);
        this.state.columns = this.tableColumns;
        props.loadCaretakers();

        if (!props.loading) {
            props.load();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props === prevProps) {
            return;
        }

        // If the location reloaded (path is the same, but key is different), reload machine details
        if (this.props.location.key !== prevProps.location.key) {
            this.props.load();
        }
    }

    render() {
        return (
            <TitleWrapper title={i18n.routeAdminMachinesFull}>
                <div>
                    <div className="content">
                        {!this.props.loading && this.props.caretakers && this.props.machines ? (
                            <>
                                <ColumnSelector cookieId="technicalMachines" columns={this.state.columns}
                                                changed={columns => this.setState({columns})}/>
                                <ReactTable
                                    filterable
                                    indexKey="id"
                                    data={this.props.machines}
                                    columns={this.state.columns}
                                    noDataText={i18n.noMachines}
                                    defaultSorted={[{id: "last_update", desc: true}]}
                                />
                            </>
                        ) : <Spinner/>}
                    </div>
                </div>
            </TitleWrapper>
        );
    }
}

const mapStateToProps = state => ({
    caretakers: state.caretakers.caretakers,
    loading:    state.machines.loading,
    machines:   state.machines.machinesStatus,
    loadedAt:   state.machines.machinesStatusLoaded
});

const mapDispatchToProps = dispatch => ({
    push:           pathname => dispatch(push(pathname)),
    load:           () => dispatch(actions.machines.loadStatus()),
    loadCaretakers: () => dispatch(actions.caretakers.loadIfNeeded()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Status);
