import React, {useEffect} from "react";
import {useDispatch} from "react-redux";

import i18n from "../../i18n";
import * as actions from "../../store/actions";

import {PanelHeader, Spinner} from "../../components";
import {Card, CardBody, CardHeader} from "reactstrap";

const VerifyPage = props => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.auth.verifyEmail(props.match.params.token));
    }, [dispatch, props.match.params.token]);

    return (
        <div>
            <PanelHeader size="sm"/>
            <div className="content">
                <Card>
                    <CardHeader>
                        <h5 className="title">{i18n.verifyBodyLoading}</h5>
                    </CardHeader>
                    <CardBody>
                        {i18n.verifyBodyLoading}
                    </CardBody>
                </Card>
            </div>
            <Spinner/>
        </div>
    );
};

export default VerifyPage;
