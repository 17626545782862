import React, {useState} from 'react';
import PropTypes from 'prop-types';

import i18n from "../../../../../i18n";

import {Table} from "reactstrap";
import {Button, CollapseCard, Icon} from "../../../../../components";
import Right from "./Right";
import AddRightModal from "./AddRightModal";
import css from "../Info.module.css";

const Rights = ({userId, isSuperAdmin, rights}) => {
    const [showAdd, setShowAdd] = useState(false);

    if(isSuperAdmin) {
        return (
            <CollapseCard title={i18n.usersRightsHead} defaultOpenDesktop>
                <h4>{i18n.usersRightsHasSuperAdmin}</h4>
            </CollapseCard>
        );
    }

    return (
        <CollapseCard title={i18n.usersRightsHead} defaultOpenDesktop allowHorizontalScroll>
            {
                rights.length === 0 ? <h4>{i18n.usersRightsNoRights}</h4> : (
                    <Table>
                        <thead className={css.tableHead}>
                        <tr>
                            <th>&nbsp;</th>
                            <th>{i18n.usersRightsTableName}</th>
                            <th><Icon name="calendar-2" title={i18n.usersRightsCalendar}/></th>
                            <th><Icon name="settings-gear" title={i18n.usersRightsSettings}/></th>
                            <th><Icon name="email-2" title={i18n.usersRightsMessages}/></th>
                            <th><Icon name="a-edit" title={i18n.usersRightsDetails}/></th>
                            <th><Icon name="media-player" title={i18n.usersRightsPreview}/></th>
                            <th><Icon name="settings" title={i18n.usersRightsTechnical}/></th>
                            <th><Icon name="multiple-11" title={i18n.usersRightsInvite}/></th>
                            <th><Icon name="round-euro" title={i18n.usersRightsPayments}/></th>
                            <th>&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody className={css.tableItems}>
                        {rights.map(right => <Right key={right.uuid} userId={userId} data={right}/>)}
                        </tbody>
                    </Table>
                )
            }
            <div className="clearfix">
                <Button round color="info" className="float-right" onClick={() => setShowAdd(true)}>{i18n.usersRightsAddRight}</Button>
            </div>
            <AddRightModal userId={userId} show={showAdd} close={() => setShowAdd(false)}/>
        </CollapseCard>
    );
};

Rights.propTypes = {
    userId:       PropTypes.string.isRequired,
    isSuperAdmin: PropTypes.bool,
    rights:       PropTypes.array.isRequired
};

export default Rights;