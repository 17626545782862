import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon/Icon';
import i18n from "../../../i18n";

const boolCheck = ({value, noText, title}) => (
    <span>
        <Icon name={value ? "check" : "e-remove"} color={value ? "success" : "danger"} title={title} inLine/>
        {!noText ? " " + (value ? i18n.yes : i18n.no) : null}
    </span>
);

boolCheck.propTypes = {
    value:  PropTypes.bool,
    noText: PropTypes.bool,
    title:  PropTypes.string
};

export default boolCheck;