export default {
    groups:   "Gruppen",
    group:    "Gruppe",
    noGroups: "Keine Gruppe",

    groupsTitle:               "Gruppen",
    groupsTableName:           "Name",
    groupsTableCaretakers:     "P#",
    groupsTableCaretakersLong: "Pflegeempfänger",
    groupsActionsEdit:         "Bearbeiten",
    groupsActionsDelete:       "Löschen",

    groupsCaretakersTitle:        "Pflegeempfänger verwalten für %1",
    groupsCaretakersDelete:       "Nehmen Sie den Pflegeempfänger aus der Gruppe",
    groupsCaretakersAdd:          "Fügen Sie einen Pflegeempfänger hinzu",
    groupsCaretakersAddTitle:     "Fügen Sie einen Pflegeempfänger hinzu für %1",
    groupsCaretakersNoCaretakers: "Keine Pflegeempfänger",

    groupsAddCaretakersSuccess:   "Pflegeempfänger erfolgreich in die Gruppe aufgenommen",
    groupsAddCaretakersError:     "Beim Hinzufügen der Pflegeempfänger zur Gruppe ist ein Fehler aufgetreten",
    groupsRemoveCaretakerSuccess: "Pflegeempfänger erfolgreich aus der Gruppe entfernt",
    groupsRemoveCaretakerError:   "Beim Entfernen des Pflegeempfänger aus der Gruppe ist ein Fehler aufgetreten",

    groupsAddTitle:      "Gruppe hinzufügen",
    groupsEditTitle:     "Gruppe bearbeiten",
    groupsEditSuccess:   "Die Daten der Gruppe wurden erfolgreich gespeichert",
    groupsEditError:     "Beim Speichern der Gruppendaten ist ein Fehler aufgetreten",
    groupsRemoveTitle:   "Möchten Sie diese Gruppe wirklich löschen?",
    groupsRemoveBody:    "Wenn Sie die Gruppe mit dem Namen '% 1' löschen, werden alle Rechte in dieser Gruppe gelöscht. Dies kann nicht rückgängig gemacht werden.",
    groupsRemoveSure:    "Gruppe löschen",
    groupsRemoveSuccess: "Die Gruppe wurde erfolgreich gelöscht",
    groupsRemoveError:   "Beim Löschen der Gruppe ist ein Fehler aufgetreten",

    groupsCalendarsNoItems: "Es gibt keine Kalenderelemente für diese Gruppe",

    groupsMessage:              "Directe Nachricht",
    groupsMessageTo:            "Directe Nachricht an %1",
    groupsMessageSentTo:        "Senden Sie eine Nachricht an %1",
    groupsMessageMessage:       "Nachricht",
    groupsMessageDuration:      "Sichtbar für",
    groupsMessageDurations:     {
        300:   "5 Minuten",
        900:   "15 Minuten",
        1800:  "30 Minuten",
        3600:  "1 Stunde",
        10800: "3 Stunden",
        21600: "6 Stunden",
        43200: "12 Stunden",
        86400: "24 Stunden"
    },
    groupsMessageUrgent:        "Dringende Nachricht",
    groupsMessageSuccess:       "Direktnachricht erfolgreich gesendet",
    groupsMessageError:         "Beim Senden der Direktnachricht ist ein Fehler aufgetreten",
    groupsMessageCancelSuccess: "Direktnachricht erfolgreich vom Bildschirm entfernt",
    groupsMessageCancelError:   "Beim Löschen der Direktnachricht ist ein Fehler aufgetreten",
    groupsMessageNoMachines:    "Dieser Pflege empfänger hat noch keine Bildschirmen",
    buttonCancelMessage:        "Nachricht abbrechen (vom Bildschirm entfernen)",

    groupsSentMessages:          "Gesendete Nachrichten",
    groupsSentMessagesMessage:   "Nachricht",
    groupsSentMessagesSentLong:  "Gesendet",
    groupsSentMessagesSentShort: "Ges.",
    groupsSentMessagesSentBy:    "Gesendet von",
    groupsSentMessagesDelivered: "Auf dem Bildschirm angezeigt",
    groupsSentMessagesCancelled: "Nachricht abgebrochen",
    groupsNoSentMessages:        "Noch keine Nachrichten gesendet",

    groupsSettings:        "Einstellungen",
    groupsDetails:         "Angaben %1",
    groupsCaretakers:      "Pflege empfänger verwalten",
    groupsCaretakersError: "Keine Pflege empfänger ausgewählt",

    groupsManageSuccess:           "Der Name der Gruppe wurde erfolgreich gespeichert",
    groupsManageFail:              "Beim Speichern des Gruppennamens ist ein Fehler aufgetreten",
    groupsManageCaretakersSuccess: "Die Pflege empfänger für diese Gruppe wurden gerettet",
    groupsManageCaretakersFail:    "Beim Speichern der Pflege empfänger dieser Gruppe ist ein Fehler aufgetreten",

    groupName: "Name",
    groupList: "Pflegeempfänger in dieser Gruppe"
};