export default {
    appTitle: "Spiqle Agenda",

    loading:     "Loading...",
    loadedAt:    "Last updated at: ",
    pageText:    "Page",
    ofText:      "of",
    rowsText:    "rows",
    showColumns: "Select columns",

    image:     "Image",
    all:       "All",
    yes:       "Yes",
    no:        "No",
    unknown:   "Unknown",
    noOptions: "No options available",

    successTitle: "Success",
    errorTitle:   "Error",

    buttonEdit:     "Edit",
    buttonSave:     "Save",
    buttonSend:     "Send",
    buttonNext:     "Next",
    buttonPrevious: "Previous",
    buttonBack:     "Back",
    buttonFinish:   "Finish",
    buttonCancel:   "Cancel",
    buttonInvite:   "Invite",
    buttonAdd:      "Add",

    searchPlaceholder: "Search...",

    contactTitle:       "Contact",
    contactSubtitle:    "Do you have a question or suggestion? Please contact us using this form.",
    contactFormTitle:   "Title",
    contactFormBody:    "Question or suggestion",
    successContactSend: "The contact form has been sent successfully",
    errorContactSend:   "An error occurred sending the contact form",

    validationErrors: {
        required:  "This field is required",
        minLength: "De minimum length for this field is %1",
        maxLength: "De maximum length for this field is %1",
        isEmail:   "This is not a valid e-mail address",
        regex:     "This is not a valid value for this field",
        func:      "This is not a valid value for this field"
    },

    errorForm: "Not all fields are entered",

    errors: {
        CONNECTION:              "Connection problem, check your internet connection",
        DEFAULT:                 "Generic error, try again later",
        CALL_DISABLED:           "This function is currently disabled",
        LOCAL_ONLY:              "",
        INVALID_REQUEST:         "An error occurred while loading data. Are you using the latest version of the app?",
        INVALID_EMAIL:           "An error occurred while loading data, the e-mail address is invalid",
        INVALID_GUID:            "An error occurred while loading data, the GUID is invalid",
        INVALID_MAC:             "An error occurred while loading data, the MAC address is invalid",
        INVALID_FILE:            "An error occurred while loading data, the chosen file is invalid",
        INVALID_TOKEN:           "An error occurred while loading data, invalid login token",
        INVALID_LIST:            "An error occurred while loading data, the list is invalid",
        INVALID_INTEGER:         "An error occurred while loading data, the number is invalid",
        INVALID_MONGOID:         "An error occurred while loading data, the database ID is invalid",
        INVALID_FLOAT:           "An error occurred while loading data, the number is invalid",
        INVALID_ARRAY:           "An error occurred while loading data, the list is invalid",
        INVALID_JSON:            "An error occurred while loading data, a parameter is invalid",
        NO_AUTH:                 "An error occurred while loading data, you are not logged in",
        UNKNOWN_CREDENTIALS:     "Unknown e-mail or password",
        SIGNUP_ERROR_CONNECTION: "Creating an account failed, please check your internet connection and try again.",
        SIGNUP_ERROR_EXISTS:     "Creating an account failed, there's already an account with this e-mail address.",
        addNewDisplay:           {
            INVALID_DISPLAY:         "Error while coupling the Spiqle Calendar, invalid screen code, enter the code exactly like it shows on the screen",
            DISPLAY_ALREADY_COUPLED: "Error while coupling the Spiqle Calendar, this screen is already coupled",
            INVALID_CARETAKER:       "Error while coupling the Spiqle Calendar, this caretaker is unknown",
            INVALID_REQUEST:         "Error while coupling the Spiqle Calendar, fill in all the fields"
        }
    },

    countries: {
        Andorra:          "Andorra",
        "Österreich":     "Austria",
        "België":         "Belgium",
        "Bŭlgarija":      "Bulgaria",
        Hrvatska:         "Croatia",
        "Kýpros":         "Cyprus",
        "Česko":          "Czech Republic",
        Danmark:          "Denmark",
        Eesti:            "Estonia",
        Suomi:            "Finland",
        France:           "France",
        Deutschland:      "Germany",
        "Elláda":         "Greece",
        "Magyarország":   "Hungary",
        Ireland:          "Ireland",
        Italia:           "Italy",
        Latvija:          "Latvia",
        Lietuva:          "Lithuania",
        Luxembourg:       "Luxembourg",
        Malta:            "Malta",
        Nederland:        "The Netherlands",
        Norge:            "Norway",
        Polska:           "Poland",
        Portugal:         "Portugal",
        "România":        "Romania",
        Slovensko:        "Slovakia",
        Slovenija:        "Slovenia",
        "España":         "Spain",
        Sverige:          "Sweden",
        Schweiz:          "Switzerland",
        "United Kingdom": "United Kingdom"
    },

    dateFormat: "dd D MMM HH:mm",
    dateTime:   {
        months: {
            long:  [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
            ],
            short: [
                "jan",
                "feb",
                "mar",
                "apr",
                "may",
                "jun",
                "jul",
                "aug",
                "sep",
                "oct",
                "nov",
                "dec"
            ]
        },
        days:   {
            long:  [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday"
            ],
            short: [
                "su",
                "mo",
                "tu",
                "we",
                "th",
                "fr",
                "sa"
            ],
            count: [
                "",
                "1st",
                "2nd",
                "3rd",
                "4th",
                "5th",
                "6th",
                "7th",
                "8th",
                "9th",
                "10th",
                "11th",
                "12th",
                "13th",
                "14th",
                "15th",
                "16th",
                "17th",
                "18th",
                "19th",
                "20th",
                "21st",
                "22nd",
                "23rd",
                "24th",
                "25th",
                "26th",
                "27th",
                "28th",
                "29th",
                "30th",
                "31st"
            ]
        }
    }
};