import React from "react";
import {useDispatch} from "react-redux";

import i18n from "../../i18n";
import * as actions from "../../store/actions";

import FrontPageForm from "../../components/Layout/FrontPageForm/FrontPageForm";

const forgotForm = {
    email: {
        label:                i18n.forgotEmail,
        validation:           {required: true, isEmail: true},
        elementConfig:        {placeholder: true},
        showValidationErrors: true
    }
};

const ForgotPage = props => {
    const dispatch = useDispatch();
    const doLogin  = values => dispatch(actions.auth.forgotPassword(values.email));
    return <FrontPageForm body={i18n.forgotBody} submitButton={i18n.forgotSubmitButton} onSubmit={doLogin} formData={forgotForm}/>;
};

export default ForgotPage;
