// React
import React, {useState} from 'react';

// Redux
import {useSelector, useDispatch} from "react-redux";
import * as actions from "../../../../store/actions";

// Tools
import i18n from "../../../../i18n";

// Components
import {Button, Form, Spinner, BottomBar} from "../../../../components";
import {TitleWrapper} from "../../../../contexts/TitleProvider";

const userForm = {
    name:        {label: i18n.userName},
    row1:        {
        type:  "row",
        items: {
            address: {label: i18n.userAddress, size: 8},
            zipcode: {label: i18n.userZipcode, size: 4, validation: {required: true, minLength: 4}},
        }
    },
    row2:        {
        type:  "row",
        items: {
            city:    {label: i18n.userCity, size: 4},
            state:   {label: i18n.userState, size: 4},
            country: {label: i18n.userCountry, size: 4}
        }
    },
    row3:        {
        type:  "row",
        items: {
            phone: {label: i18n.userPhone, size: 6},
            email: {label: i18n.userEmail, size: 6, validation: {required: true, isEmail: true}}
        }
    },
    super_admin: {
        label: i18n.usersTableAdmin,
        type:  "switch",
    },
    disabled:    {
        label: i18n.usersTableDisabled,
        type:  "switch",
    }
};

const Add = () => {
    const dispatch                    = useDispatch();
    const [formValues, setFormValues] = useState({});
    const [formValid, setFormValid]   = useState(false);
    const loading                     = useSelector(state => state.users.loading);
    const saving                      = useSelector(state => state.users.saving);

    const handleChange = (formValues, formValid) => {
        setFormValues(formValues);
        setFormValid(formValid);
    };
    const handleValid  = formValid => setFormValid(formValid);
    const handleSubmit = () => formValid && dispatch(actions.users.save("new", formValues, "/admin/users"));

    return (
        <TitleWrapper title={i18n.usersAddTitle}>
            <div>
                <div className="content">
                    {!loading && !saving ? (
                        <>
                            <Form formData={userForm} values={formValues} onChange={handleChange} onValid={handleValid} stacked/>
                            <Button round color="info" block disabled={!formValid} onClick={handleSubmit}>
                                {i18n.buttonSave}
                            </Button>
                        </>
                    ) : <Spinner/>}
                    <BottomBar backButton/>
                </div>
            </div>
        </TitleWrapper>
    );
};

export default Add;