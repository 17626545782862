import {apiGetCall, apiPostCall} from "./index";

export const load         = () => apiGetCall("/machines/getall");
export const loadForRight = (right, rightLevel) => apiGetCall("/machines/getall", {right, rightLevel});
export const loadInfo     = macAddress => apiGetCall("/machines/info", {macAddress});
export const loadStatus   = () => apiGetCall("/machines/getstatus");
export const swap         = (macAddressFrom, macAddressTo) => apiPostCall("/machines/swap", {macAddressFrom, macAddressTo});
export const couple       = (macAddress, caretaker) => apiPostCall("/machines/couple", {macAddress, caretaker});
export const uncouple     = macAddress => apiPostCall("/machines/uncouple", {macAddress});

export const manualUpdate          = update => apiPostCall("/machines/manualupdate", {...update}, true, true);
export const clearUpdates          = macAddress => apiPostCall("/machines/clearupdates", {macAddress});
export const clearUpdatesAndResend = macAddress => apiPostCall("/machines/clearupdatesandresend", {macAddress, resend: true});
export const cancelUpdate          = id => apiPostCall("/machines/cancelupdate", {id});
