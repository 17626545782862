import {call, put, select, takeLatest} from "redux-saga/effects";
import {push} from "connected-react-router";
import * as actions from "../actions";
import * as actionTypes from "../actionTypes";
import * as api from "../../api";
import i18n from "../../i18n";
import {mapErrorResponseCodes} from "../../variables/tools";
import moment from "moment-timezone";

// Watcher
export function* watchCaretakers() {
    yield takeLatest(actionTypes.CARETAKERS_LOAD, loadSaga);
    yield takeLatest(actionTypes.CARETAKERS_LOAD_IF_NEEDED, loadIfNeededSaga);
    yield takeLatest(actionTypes.CARETAKERS_LOAD_MACHINE_DETAILS, loadMachineDetailsSaga);
    yield takeLatest(actionTypes.CARETAKERS_LOAD_INVITED_USERS, loadInvitedUsersSaga);
    yield takeLatest(actionTypes.CARETAKERS_ADD, addSaga);
    yield takeLatest(actionTypes.CARETAKERS_CHECK_SCREEN_CODE, checkScreenCodeSaga);
    yield takeLatest(actionTypes.CARETAKERS_SAVE, saveSaga);
    yield takeLatest(actionTypes.CARETAKERS_SAVE_PHOTO, savePhotoSaga);
    yield takeLatest(actionTypes.CARETAKERS_REMOVE_PHOTO, removePhotoSaga);
    yield takeLatest(actionTypes.CARETAKERS_SAVESETTINGS, saveSettingsSaga);
    yield takeLatest(actionTypes.CARETAKERS_SAVEDEFAULTS, saveDefaultsSaga);
    yield takeLatest(actionTypes.CARETAKERS_SAVE_MACHINENAME, saveMachineNameSaga);
    yield takeLatest(actionTypes.CARETAKERS_REMOVE, removeSaga);
    yield takeLatest(actionTypes.CARETAKERS_INVITE_USER, inviteSaga);
}

// Sagas
function* loadSaga(action) {
    try {
        const response = yield call(api.caretakers.load);
        const newCaretakers = response.data.caretakers.map(caretaker => ({
            ...caretaker,
            birthday: moment(caretaker.birthday + " 12:00:00", "YYYY-MM-DD HH:mm:ss")
        }));
        yield put(actions.caretakers.loadSuccess(newCaretakers, response.data.groups));
    }
    catch (err) {
        yield put(actions.caretakers.loadFail());
    }
}

function* loadIfNeededSaga(action) {
    let caretakers = yield select(state => state.caretakers);
    if (!caretakers.loading && !caretakers.caretakersLoaded) {
        yield put(actions.caretakers.load());
    }
}

function* loadMachineDetailsSaga(action) {
    try {
        const response = yield call(api.caretakers.loadMachineDetails, action.uuid);
        yield put(actions.caretakers.loadMachineDetailsSuccess(action.uuid, response.data.machineDetails));
    }
    catch (err) {
        yield put(actions.caretakers.loadMachineDetailsFail());
        yield put(actions.messages.errorPush(i18n.caretakersLoadMachinesError));
    }
}

function* loadInvitedUsersSaga(action) {
    try {
        const response = yield call(api.caretakers.loadInvitedUsers);
        yield put(actions.caretakers.loadInvitedUsersSuccess(response.data.invites));
    }
    catch (err) {
        console.log(err);
        yield put(actions.caretakers.loadInvitedUsersFail());
        yield put(actions.messages.errorPush(i18n.caretakersLoadInvitedUsersError));
    }
}

function* addSaga(action) {
    try {
        yield call(api.caretakers.add, action.caretakerDetails);
        yield put(actions.caretakers.addSuccess());
        yield put(actions.caretakers.load());
        yield put(push(action.redirect));
        yield put(actions.messages.successPush(i18n.caretakersAddNewMachineSuccess));
    }
    catch (err) {
        yield put(actions.caretakers.addFail());

        if (err.response && err.response.data && err.response.data.error && i18n.errors.addNewDisplay.hasOwnProperty(err.response.data.error)) {
            yield put(actions.messages.errorPush(i18n.errors.addNewDisplay[err.response.data.error]));
        }
        else {
            yield put(actions.messages.errorPush(i18n.caretakersAddNewMachineError));
        }
    }
}

function* checkScreenCodeSaga(action) {
    try {
        yield call(api.caretakers.checkScreenCode, action.screenCode);
        yield put(actions.caretakers.checkScreenCodeSuccess());
    }
    catch (err) {
        yield put(actions.caretakers.checkScreenCodeFail());
        yield put(actions.messages.errorPush(i18n.caretakersAddNewMachineInvalidCode));
    }
}

function* saveSaga(action) {
    try {
        yield call(api.caretakers.save, action.uuid, action.caretakerDetails);
        yield put(actions.caretakers.saveSuccess());
        yield put(actions.caretakers.load());
        yield put(push(action.redirect));
        yield put(actions.messages.successPush(i18n.caretakersItemEditSuccess));
    }
    catch (err) {
        yield put(actions.caretakers.saveFail());
        yield put(actions.messages.errorPush(i18n.caretakersItemEditError));
    }
}

function* savePhotoSaga(action) {
    try {
        yield call(api.caretakers.savePhoto, action.uuid, action.file);
        yield put(actions.caretakers.savePhotoSuccess());
        yield put(actions.caretakers.load());
        yield put(push("/caretakers/edit/" + action.uuid));
        yield put(actions.messages.successPush(i18n.caretakersItemEditPhotoSuccess));
    }
    catch (err) {
        yield put(actions.caretakers.savePhotoFail());
        yield put(actions.messages.errorPush(i18n.caretakersItemEditPhotoError));
    }
}

function* removePhotoSaga(action) {
    try {
        yield call(api.caretakers.removePhoto, action.uuid);
        yield put(actions.caretakers.removePhotoSuccess());
        yield put(actions.caretakers.load());
        yield put(push("/caretakers/edit/" + action.uuid));
        yield put(actions.messages.successPush(i18n.caretakersItemRemovePhotoSuccess));
    }
    catch (err) {
        yield put(actions.caretakers.removePhotoFail());
        yield put(actions.messages.errorPush(i18n.caretakersItemRemovePhotoError));
    }
}

function* saveSettingsSaga(action) {
    try {
        yield call(api.caretakers.saveSettings, action.uuid, action.settings);
        yield put(actions.caretakers.saveSettingsSuccess());
        yield put(actions.caretakers.load());
        yield put(actions.messages.successPush(i18n.caretakerSettingsSuccessSave));
    }
    catch (err) {
        yield put(actions.caretakers.saveSettingsFail());
        yield put(actions.messages.errorPush(i18n.caretakerSettingsErrorSave));
    }
}

function* saveDefaultsSaga(action) {
    try {
        yield call(api.caretakers.saveDefaults, action.uuid, action.defaults);
        yield put(actions.caretakers.saveDefaultsSuccess());
        yield put(actions.caretakers.load());
        yield put(actions.messages.successPush(i18n.caretakerDefaultsSuccessSave));
    }
    catch (err) {
        yield put(actions.caretakers.saveDefaultsFail());
        yield put(actions.messages.errorPush(i18n.caretakerDefaultsErrorSave));
    }
}

function* saveMachineNameSaga(action) {
    try {
        yield call(api.caretakers.saveMachineName, action.uuid, action.serial, action.machineDetails);
        yield put(actions.caretakers.saveMachineNameSuccess());
        yield put(actions.caretakers.load());
        yield put(actions.messages.successPush(i18n.caretakerMachineNameSuccessSave));
        yield put(push("/caretakers/status/" + action.uuid));
    }
    catch (err) {
        yield put(actions.caretakers.saveMachineNameFail());
        yield put(actions.messages.errorPush(i18n.caretakerMachineNameErrorSave));
    }
}

function* removeSaga(action) {
    try {
        yield call(api.caretakers.remove, action.uuid);
        yield put(actions.caretakers.removeSuccess());
        yield put(actions.caretakers.load());
        yield put(push("/admin/caretakers"));
        yield put(actions.messages.successPush(i18n.caretakersRemoveSuccess));
    }
    catch (err) {
        yield put(actions.caretakers.removeFail());
        yield put(actions.messages.errorPush(i18n.caretakersRemoveError));
    }
}

function* inviteSaga(action) {
    try {
        yield call(api.caretakers.invite, action.userDetails);
        yield put(actions.caretakers.inviteUserSuccess());
        yield put(push("/"));
        yield put(actions.messages.successPush(i18n.caretakersInviteSuccess));
    }
    catch (err) {
        yield put(actions.caretakers.inviteUserFail());
        yield put(actions.messages.errorPush(mapErrorResponseCodes(err, {
            UNKNOWN_RIGHT:     i18n.caretakersInviteErrorUnknownRight,
            INVITED_YOURSELF:  i18n.caretakersInviteErrorInvitedYourself,
            INVALID_CARETAKER: i18n.caretakersInviteErrorInvalidCaretaker,
            default:           i18n.caretakersInviteError
        })));
    }
}