// React
import React from "react";
import PropTypes from "prop-types";
// Tools
import i18n from "../../../i18n";
import moment from "moment";
// Components
import {Form} from "../../../components";
import {useDispatch} from "react-redux";
import * as actions from "../../../store/actions";
import css from "./EditTvShow.module.css";

const EditTvShow = props => {
    const dispatch     = useDispatch();
    const editForm     = {
        showName:  {
            label:     i18n.calendarEditFormShowName,
            className: css.tourShowName,
        },
        channel:   {
            label:     i18n.calendarEditFormChannel,
            className: css.tourChannel,
        },
        start:     {
            label:         i18n.calendarEditFormStart + ":",
            classNameDate: css.tourDateStart,
            classNameTime: css.tourTimeStart,
            type:          "datetime",
            defaultValue:  props.newDate ? moment(props.newDate, "YYYY-MM-DD").hours(12).minutes(0) : moment()
        },
        end:       {
            label:         i18n.calendarEditFormEnd + ":",
            classNameDate: css.tourDateEnd,
            classNameTime: css.tourTimeEnd,
            type:          "datetime",
            defaultValue:  props.newDate ? moment(props.newDate, "YYYY-MM-DD").hours(13).minutes(0) : moment().add(1, "hour")
        },
        recurring: {
            label:        i18n.calendarEditFormRecurrence,
            className:    css.tourRecurring,
            defaultValue: "none",
            type:         "select",
            options:      i18n.calendarRecurrences.options
        },
        interval:  {
            label:        i18n.calendarEditFormInterval,
            className:    css.tourRecurringInterval,
            defaultValue: "1 week",
            type:         "select",
            options:      i18n.calendarRecurrences.interval,
            show:         form => form.recurring.value === "interval"
        },
        weekDays:  {
            label:     i18n.calendarEditFormDaysOfWeek,
            className: css.tourRecurringWeekdays,
            type:      "weekselector",
            show:      form => form.recurring.value === "weekdays"
        },
        hasEndInterval: {
            className:   css.tourRecurringHasEndDate,
            label:       i18n.calendarEditFormIntervalHasEndDate,
            labelValue:  [i18n.calendarEditFormIntervalHasEndDateYes, i18n.calendarEditFormIntervalHasEndDateNo],
            noLabel:     true,
            type:        "switch",
            show:        form => form.recurring.value !== "none"
        },
        endInterval: {
            className: css.tourRecurringEndDate,
            label: i18n.calendarEditFormIntervalEndDate,
            type: "date",
            defaultValue: (props.newDate ? moment(props.newDate, "YYYY-MM-DD") : moment()).hours(13).minutes(0),
            show:         form => form.recurring.value !== "none" && form.hasEndInterval.value
        }
    };
    const handleChange = (formValues, formValid, itemName) => {
        switch (itemName) {
            case "start":
                if (formValues.start.isAfter(formValues.end)) {
                    formValues.end = moment(formValues.start).add(1, "hours");
                }
                break;

            case "end":
                if (formValues.end.isBefore(formValues.start)) {
                    formValues.start = moment(formValues.end).subtract(1, "hours");
                    dispatch(actions.messages.infoPush(i18n.calendarStartTimeChanged));
                }
                break;

            default:
        }
        props.setValues(formValues, formValid);
    };
    return (
        <Form inlineSelect formData={editForm} values={props.item} onChange={handleChange} onValid={props.setValid}/>
    );
};

EditTvShow.propTypes = {
    item:      PropTypes.object,
    caretaker: PropTypes.object,
    setValues: PropTypes.func.isRequired,
    setValid:  PropTypes.func.isRequired
};

export default EditTvShow;
