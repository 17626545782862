export default {
    routeCaretakers:            "Pflege​empfänger",
    routeLocations:             "Alle Standorte",
    routeAdmin:                 "Verwaltung",
    routeAdminCaretakersFull:   "Pflege​empfänger",
    routeAdminCaretakersMini:   "Pf",
    routeAdminAffiliatesFull:   "Partner",
    routeAdminAffiliatesMini:   "Pa",
    routeAdminDistributorsFull: "Distributor",
    routeAdminDistributorsMini: "D",
    routeAdminGroupsFull:       "Gruppen",
    routeAdminGroupsMini:       "G",
    routeAdminUsersFull:        "Benutzer",
    routeAdminUsersMini:        "BG",
    routeAdminMachinesFull:     "Maschinen",
    routeAdminMachinesMini:     "M",
    routeTechnical:             "Technisch",
    routeTechnicalStatusFull:   "Maschine Status",
    routeTechnicalStatusMini:   "MS",
    routeTechnicalManualFull:   "Manuelles Update",
    routeTechnicalManualMini:   "MU",
    routeTechnicalUpdatesFull:  "Firmware updates",
    routeTechnicalUpdatesMini:  "FU",
    routeHelp:                  "Hilfe",
    routeAccount:               "Konto",
    routeContact:               "Kontakt",
    routeAbout:                 "Über uns",
    routeLogout:                "Abmelden"
};