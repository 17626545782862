export default {
    user:    "Bruger",
    users:   "Brugere",
    noUsers: "Ingen brugere",

    usersTitle:        "Brugere",
    usersTableName:    "Navn",
    usersTableAddress: "Adresse",
    usersTableZipcode: "Postnummer",
    usersTableCity:    "By",
    usersTableState:   "Provins",
    usersTableCountry: "Land",
    usersTablePhone:   "Telefon",
    usersTableEmail:   "E-mail",
    usersTableLogin:   "Seneste login",

    usersTableAdmin:            "Super admin",
    usersTableDisabled:         "Deaktiveret",
    usersTableEmailConfirmed:   "E-mail adresse bekræftet",
    usersTableTwoFactorEnabled: "2-faktor tændt",
    usersActionsTitle:          "Handlinger for disse brugere",
    usersActionsInfo:           "Brugerinfo",
    usersActionsDelete:         "Slet",

    usersActionsDeleteButton:       "Fjern bruger",
    usersActionsDuplicateButton:    "Duplicer bruger",
    usersActionsCopyRightsButton:   "Kopier brugerrettigheder til en anden bruger",
    usersActionsViewActivityButton: "Se brugers aktivitetslog",
    usersActionsLogInAsButton:      "Log ind som denne bruger",
    usersActionsDisableTwoFactor:   "Sluk for 2-faktor autentifikation",

    usersAddTitle:    "Tilføj bruger",
    usersEditTitle:   "Rediger bruger",
    usersAddSuccess:  "Den nye konto er oprettet, brugeren har modtaget en midlertidig adgangskode via e-mail.",
    usersEditSuccess: "Brugerens data er gemt succesfuldt",
    usersEditError:   "Der opstod en fejl ved gemning af brugerens data",

    usersLogInAsTitle: "Er du sikker på at du vil skifte bruger?",
    usersLogInAsBody:  "Er du sikker på at du vil logge ind som '%1'? For at komme tilbage til din egen bruger skal du logge ud og logge ind igen.",
    usersLogInAsSure:  "Log ind som denne bruger",
    usersLogInAsFail:  "Der opstod en fejl ved login som denne bruger",

    usersDuplicateTitle:   "Duplicer bruger",
    usersDuplicateBody:    "Opret en ny bruger med samme rettigheder som '%1'.",
    usersDuplicateSure:    "Duplicer bruger",
    usersDuplicateSuccess: "Brugeren er blevet duplikeret succesfuldt. Brugeren har modtaget en midlertidig adgangskode via e-mail.",
    usersDuplicateError:   "Brugeren kunne ikke duplikeres.",

    usersCopyTitle:           "Kopier brugerrettigheder til en anden bruger",
    usersCopyBody:            "Kopier rettighederne fra '%1' til en anden bruger.",
    usersCopyToUser:          "Til bruger",
    usersCopyReplace:         "Erstat rettigheder",
    usersCopyReplaceExpanded: "Erstat rettighederne i stedet for at tilføje dem",
    usersCopySure:            "Kopier rettigheder",
    usersCopySuccess:         "Brugerens rettigheder er blevet kopieret succesfuldt.",
    usersCopyError:           "Brugerens rettigheder kunne ikke kopieres.",

    usersTwoFactorDisableTitle:   "Er du sikker på, at du vil slukke for denne brugers 2-faktor autentifikation?",
    usersTwoFactorDisableBody:    "'%1' kan derefter logge ind uden 2-faktor autentifikation og kan selv tænde det igen.",
    usersTwoFactorDisableSure:    "Sluk 2-faktor autentifikation",
    usersTwoFactorDisableSuccess: "2-faktor autentifikationen for brugeren er blevet slukket succesfuldt.",
    usersTwoFactorDisableError:   "2-faktor autentifikationen for brugeren kunne ikke slukkes.",

    usersRemoveTitle:   "Er du sikker på at du vil fjerne denne bruger?",
    usersRemoveBody:    "Hvis du fjerner brugeren med navnet '%1', bliver alle maskiner, der i øjeblikket er tilknyttet denne bruger, tilknyttet Spiqle. Dette kan ikke laves om.",
    usersRemoveSure:    "Fjern bruger",
    usersRemoveSuccess: "Brugeren er fjernet succesfuldt",
    usersRemoveError:   "Der opstod en fejl ved fjernelse af brugeren",

    usersInfoTitle:     "Brugerinfo",
    usersInfoLoadError: "Fejl ved indlæsning af brugerinfo",
    usersInfoDataHead:  "Brugerdata for %1",

    usersRightsHead:          "Bruger rettigheder",
    usersRightsTableType:     "Rettighedstype",
    usersRightsTableName:     "Navn",
    usersRightsTableDelete:   "Fjern ret",
    usersRightsAddRightTitle: "Tilføj rettigheder til bruger",
    usersRightsAddRight:      "Tilføj ret",
    usersRightsCalendar:      "Kalender rettigheder",
    usersRightsSettings:      "Skærmindstilling rettigheder",
    usersRightsMessages:      "Besked rettigheder",
    usersRightsDetails:       "Plejekrævende data rettigheder",
    usersRightsPreview:       "Forhåndsvisning rettigheder",
    usersRightsTechnical:     "Tekniske rettigheder",
    usersRightsInvite:        "Invitation rettigheder",
    usersRightsPayments:      "Betalings rettigheder",
    usersRightsNoRights:      "Denne bruger har ingen rettigheder",
    usersRightsHasSuperAdmin: "Denne bruger er super admin",

    usersRightsPresetNoRights:  "Forudindstillet ingen rettigheder",
    usersRightsPresetCaretaker: "Forudindstillet for plejekrævende",
    usersRightsPresetAdmin:     "Forudindstillet for administrator",

    usersRightsCalendarRight:  [
        "Ingen kalender rettigheder",
        "Vis kalendere",
        "Rediger kalendere"
    ],
    usersRightsSettingsRight:  [
        "Ingen skærmindstillinger rettigheder",
        "Vis skærmindstillinger",
        "Rediger skærmindstillinger"
    ],
    usersRightsMessagesRight:  [
        "Ingen besked rettigheder",
        "Send direkte beskeder",
        "Send beskeder og vis historie"
    ],
    usersRightsDetailsRight:   [
        "Ingen plejekrævende data rettigheder",
        "Vis plejekrævende data",
        "Rediger plejekrævende data"
    ],
    usersRightsPreviewRight:   [
        "Ingen forhåndsvisning rettigheder",
        "Vis skærmforhåndsvisning"
    ],
    usersRightsTechnicalRight: [
        "Ingen tekniske rettigheder",
        "Vis tekniske maskindata",
        "Tekniske admin rettigheder"
    ],
    usersRightsInviteRight:    [
        "Ingen invitations rettigheder",
        "Rettigheder til at invitere nye brugere"
    ],
    usersRightsPaymentsRight:  [
        "Ingen betalings rettigheder",
        "Rettigheder til at administrere betalinger"
    ],

    usersRightsTypeCaretaker:   "Plejekrævende",
    usersRightsTypeGroup:       "Gruppe",
    usersRightsTypeDistributor: "Distributør",
    usersRightsTypeAffiliate:   "Affiliate",
    usersRightsTypeMapping:     {
        caretaker:   "Plejekrævende",
        group:       "Gruppe",
        distributor: "Distributør",
        affiliate:   "Affiliate"
    },

    userName:    "Navn",
    userAddress: "Adresse",
    userZipcode: "Postnummer",
    userCity:    "By",
    userState:   "Provins",
    userCountry: "Land",
    userPhone:   "Telefonnummer",
    userEmail:   "E-mail adresse",
};