import React from 'react';
import PropTypes from 'prop-types';

import {connect} from "react-redux";
import * as actions from "../../../../store/actions";

import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import i18n from "../../../../i18n";
import {Button} from "../../../../components";

const Uncoupler = props => {
    const handleDelete = () => {
        if(props.redirect) {
            props.uncouple(props.mac, props.redirect);
        } else {
            props.uncouple(props.mac);
        }
        props.close();
    };
    return (
        <Modal isOpen={props.isOpen} toggle={props.close} className="text-center">
            <ModalHeader className="justify-content-center uppercase title" tag="h4">{i18n.machineUncoupleTitle}</ModalHeader>
            <ModalBody><p>{i18n.machineUncoupleBody.replace('%1', props.mac)}</p></ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={handleDelete}>{i18n.machineUncoupleSure}</Button>
                <Button color="info" onClick={props.close}>{i18n.buttonCancel}</Button>
            </ModalFooter>
        </Modal>
    );
};

Uncoupler.propTypes = {
    isOpen:   PropTypes.bool,
    close:    PropTypes.func.isRequired,
    mac:      PropTypes.string.isRequired,
    redirect: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
    uncouple: (macAddress, redirect) => dispatch(actions.machines.uncouple(macAddress, redirect))
});

export default connect(null, mapDispatchToProps)(Uncoupler);