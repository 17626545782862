import * as actionTypes from "../actionTypes";
import moment from 'moment-timezone';
import {runActionMap, load, loadFail, save, saveDone, saveFail, resetError} from "../reducerFunctions";

const initialState = {
    loadedFor: null,
    loadedAt:  null,
    reloading: false,

    loading: false,
    saving:  false,
    error:   null,

    users:       null,
    usersLoaded: null,
    userInfo:    null,

    duplicating:    false,
    duplicateDone:  false,
    duplicateError: null,

    copyingRights:      false,
    copyingRightsDone:  false,
    copyingRightsError: null,

    disabling2fa:      false,
    disabling2faDone:  false,
    disabling2faError: null
};

// Load reducers
const loadSuccess = (state, action) => ({...state, loading: false, error: null, users: action.users, usersLoaded: moment()});
const loadInfo        = (state, action) => {
    if (state.loadedFor === action.userId) {
        return {...state, reloading: true};
    }
    else {
        return {...state, loading: true};
    }
};
const loadInfoSuccess = (state, action) => ({
    ...state,
    loading:   false,
    reloading: false,
    error:     null,
    userInfo:  action.userInfo,
    loadedFor: action.userId,
    loadedAt:  moment()
});
const loadInfoFail    = (state, action) => ({...state, loading: false, reloading: false, error: action.error});

// Duplicate user reducers
const duplicate        = (state, action) => ({...state, duplicating: true});
const duplicateSuccess = (state, action) => ({...state, duplicating: false, duplicateDone: true});
const duplicateFail    = (state, action) => ({...state, duplicating: false, duplicateError: action.error});

// Duplicate user reducers
const copyRights        = (state, action) => ({...state, copyingRights: true});
const copyRightsSuccess = (state, action) => ({...state, copyingRights: false, copyingRightsDone: true});
const copyRightsFail    = (state, action) => ({...state, copyingRights: false, copyingRightsError: action.error});

// Duplicate user reducers
const disable2fa        = (state, action) => ({...state, disabling2fa: true});
const disable2faSuccess = (state, action) => ({...state, disabling2fa: false, disabling2faDone: true});
const disable2faFail    = (state, action) => ({...state, disabling2fa: false, disabling2faError: action.error});

const resetDoneStates = (state, action) => ({...state, duplicateDone: false, copyingRightsDone: false, disabling2faDone: false});

const actionMap    = {
    [actionTypes.USERS_LOAD]:                 load,
    [actionTypes.USERS_LOAD_SUCCESS]:         loadSuccess,
    [actionTypes.USERS_LOAD_FAIL]:            loadFail,
    [actionTypes.USERS_INFO_LOAD]:            loadInfo,
    [actionTypes.USERS_INFO_LOAD_SUCCESS]:    loadInfoSuccess,
    [actionTypes.USERS_INFO_LOAD_FAIL]:       loadInfoFail,
    [actionTypes.USERS_RIGHT_ADD]:            save,
    [actionTypes.USERS_RIGHT_ADD_SUCCESS]:    saveDone,
    [actionTypes.USERS_RIGHT_ADD_FAIL]:       saveFail,
    [actionTypes.USERS_RIGHT_REMOVE]:         save,
    [actionTypes.USERS_RIGHT_REMOVE_SUCCESS]: saveDone,
    [actionTypes.USERS_RIGHT_REMOVE_FAIL]:    saveFail,
    [actionTypes.USERS_SAVE]:                 save,
    [actionTypes.USERS_SAVE_SUCCESS]:         saveDone,
    [actionTypes.USERS_SAVE_FAIL]:            saveFail,
    [actionTypes.USERS_REMOVE]:               save,
    [actionTypes.USERS_REMOVE_SUCCESS]:       saveDone,
    [actionTypes.USERS_REMOVE_FAIL]:          saveFail,
    [actionTypes.USERS_RESET_ERROR]:          resetError,
    [actionTypes.USERS_DUPLICATE]:            duplicate,
    [actionTypes.USERS_DUPLICATE_SUCCESS]:    duplicateSuccess,
    [actionTypes.USERS_DUPLICATE_FAIL]:       duplicateFail,
    [actionTypes.USERS_COPY_RIGHTS]:          copyRights,
    [actionTypes.USERS_COPY_RIGHTS_SUCCESS]:  copyRightsSuccess,
    [actionTypes.USERS_COPY_RIGHTS_FAIL]:     copyRightsFail,
    [actionTypes.USERS_DISABLE_2FA]:          disable2fa,
    [actionTypes.USERS_DISABLE_2FA_SUCCESS]:  disable2faSuccess,
    [actionTypes.USERS_DISABLE_2FA_FAIL]:     disable2faFail,
    [actionTypes.USERS_RESET_DONE_STATES]:    resetDoneStates

};
const usersReducer = (state = initialState, action) => runActionMap(state, action, actionMap);

export default usersReducer;
