// React
import React from "react";

// Redux
import {connect} from "react-redux";
import {push} from 'connected-react-router'
import * as actions from "../../../../store/actions";

// Tools
import i18n from "../../../../i18n";

// Components
import {Button, Form, Spinner, BottomBar} from "../../../../components";
import {filterFormValues} from "../../../../components/Form/formTools";
import {TitleWrapper} from "../../../../contexts/TitleProvider";

const groupForm = {name: {label: i18n.groupName}};

class Edit extends React.Component {
    state = {
        uuid:       null,
        group:      null,
        formValues: {},
        formValid:  false
    };

    constructor(props) {
        super(props);
        this.state.uuid = props.match.params.group;
        if (this.state.uuid.length !== 32) {
            props.push("/admin/groups");
            return;
        }

        if (props.groups) {
            this.state.group      = this.props.groups.find(obj => obj.uuid === this.state.uuid);
            this.state.formValues = filterFormValues(groupForm, this.state.group);
        } else {
            if (!props.loading) {
                props.load();
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props === prevProps) {
            return;
        }

        // If the group store suddenly changed, update the details
        if (this.props.groups && this.props.groups !== prevProps.groups && this.props.groups.length > 0) {
            let uuid  = this.props.match.params.group;
            let group = this.props.groups.find(obj => obj.uuid === uuid);
            if (group) {
                this.setState({group, formValues: filterFormValues(groupForm, group)});
            } else {
                // Unknown group, go back to caretaker overview
                this.props.push("/admin/groups");
            }
        }
    }

    handleChange = (formValues, formValid) => this.setState({formValues, formValid});
    handleValid  = formValid => this.setState({formValid});
    handleSubmit = () => this.state.formValid && this.props.save(this.state.uuid, this.state.formValues);

    render() {
        return (
            <TitleWrapper title={i18n.groupsEditTitle}>
                <div>
                    <div className="content">
                        {this.state.group && !this.props.loading && !this.props.saving ? (
                            <>
                                <Form
                                    formData={groupForm}
                                    values={this.state.formValues}
                                    onChange={this.handleChange}
                                    onValid={this.handleValid}
                                    stacked
                                />
                                <Button round color="info" block disabled={!this.state.formValid} onClick={this.handleSubmit}>
                                    {i18n.buttonSave}
                                </Button>
                            </>
                        ) : <Spinner/>}
                        <BottomBar backButton/>
                    </div>
                </div>
            </TitleWrapper>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.groups.loading,
    saving:  state.groups.saving,
    groups:  state.groups.groups
});

const mapDispatchToProps = dispatch => ({
    push: pathname => dispatch(push(pathname)),
    load: () => dispatch(actions.groups.load()),
    save: (uuid, details) => dispatch(actions.groups.save(uuid, details))
});

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
