import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from 'reactstrap';
import Simple from './Simple';
import Inline from './Inline';
import Stacked from './Stacked';
import StackedWithIcon from './StackedWithIcon';
import WithIcon from './WithIcon';

const AutoWrapInput = props => {
    const params = {
        disabled: props.disabled,
        name:     props.name,
        settings: props.settings
    };
    let componentToRender;
    if (props.settings.icon) {
        if (props.stacked) {
            componentToRender = <StackedWithIcon {...params}>{props.children}</StackedWithIcon>
        } else {
            componentToRender = <WithIcon {...params}>{props.children}</WithIcon>
        }
    } else {
        if (props.inline) {
            componentToRender = <Inline {...params}>{props.children}</Inline>
        } else if (props.stacked) {
            componentToRender = <Stacked {...params}>{props.children}</Stacked>
        } else {
            componentToRender = <Simple {...params}>{props.children}</Simple>
        }
    }

    let showError = props.settings.showValidationErrors &&
        props.settings.touched &&
        !props.settings.valid &&
        !props.settings.focused &&
        props.settings.validErrors &&
        props.settings.validErrors.length > 0;
    return (<>
        {componentToRender}
        <Tooltip placement={props.settings.validationErrorLocation} isOpen={showError} target={props.name}>
            {showError ? props.settings.validErrors.map((item, index) => <React.Fragment key={index}>{item}<br/><br/></React.Fragment>) : null}
        </Tooltip>
    </>);

};

AutoWrapInput.propTypes = {
    name:     PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    stacked:  PropTypes.bool,
    inline:   PropTypes.bool,
    disabled: PropTypes.bool
};

export default AutoWrapInput;