import {all} from "redux-saga/effects";
import {watchApp} from "./app/sagas";
import {watchAuth} from "./auth/sagas";
import {watchAffiliates} from "./affiliates/sagas";
import {watchCalendars} from "./calendars/sagas";
import {watchCaretakers} from "./caretakers/sagas";
import {watchCaretakerMessages} from "./caretakerMessages/sagas";
import {watchContact} from "./contact/sagas";
import {watchDistributors} from "./distributors/sagas";
import {watchGroups} from "./groups/sagas";
import {watchGroupCalendars} from "./groupCalendars/sagas";
import {watchGroupMessages} from "./groupMessages/sagas";
import {watchHelp} from "./help/sagas";
import {watchMachines} from "./machines/sagas";
import {watchUsers} from "./users/sagas";

export default function* watchSagas() {
    yield all([
        watchApp(),
        watchAuth(),
        watchAffiliates(),
        watchCalendars(),
        watchCaretakers(),
        watchCaretakerMessages(),
        watchContact(),
        watchDistributors(),
        watchGroups(),
        watchGroupCalendars(),
        watchGroupMessages(),
        watchHelp(),
        watchMachines(),
        watchUsers()
    ]);
}