import * as actionTypes from "../actionTypes";

export const load         = () => ({type: actionTypes.CARETAKERS_LOAD});
export const loadIfNeeded = () => ({type: actionTypes.CARETAKERS_LOAD_IF_NEEDED});
export const loadSuccess  = (caretakers, groups) => ({type: actionTypes.CARETAKERS_LOAD_SUCCESS, caretakers, groups});
export const loadFail     = error => ({type: actionTypes.CARETAKERS_LOAD_FAIL, error: "CARETAKER_LOAD_FAILED"});

export const loadMachineDetails        = uuid => ({type: actionTypes.CARETAKERS_LOAD_MACHINE_DETAILS, uuid});
export const loadMachineDetailsSuccess = (uuid, machineDetails) => ({type: actionTypes.CARETAKERS_LOAD_MACHINE_DETAILS_SUCCESS, uuid, machineDetails});
export const loadMachineDetailsFail    = error => ({type: actionTypes.CARETAKERS_LOAD_MACHINE_DETAILS_FAIL, error: "CARETAKER_LOAD_MACHINE_DETAILS_FAILED"});

export const loadInvitedUsers        = () => ({type: actionTypes.CARETAKERS_LOAD_INVITED_USERS});
export const loadInvitedUsersSuccess = invites => ({type: actionTypes.CARETAKERS_LOAD_INVITED_USERS_SUCCESS, invites});
export const loadInvitedUsersFail    = error => ({type: actionTypes.CARETAKERS_LOAD_INVITED_USERS_FAIL, error: "CARETAKERS_LOAD_INVITED_USERS_FAIL"});

export const unload = () => ({type: actionTypes.CARETAKERS_UNLOAD});

export const add        = (caretakerDetails, redirect = "/caretakers") => ({type: actionTypes.CARETAKERS_ADD, caretakerDetails, redirect});
export const addSuccess = () => ({type: actionTypes.CARETAKERS_ADD_SUCCESS});
export const addFail    = error => ({type: actionTypes.CARETAKERS_ADD_FAIL, error});

export const checkScreenCode        = screenCode => ({type: actionTypes.CARETAKERS_CHECK_SCREEN_CODE, screenCode});
export const checkScreenCodeSuccess = () => ({type: actionTypes.CARETAKERS_CHECK_SCREEN_CODE_SUCCESS});
export const checkScreenCodeFail    = () => ({type: actionTypes.CARETAKERS_CHECK_SCREEN_CODE_FAIL});
export const resetScreenCodeValid   = () => ({type: actionTypes.CARETAKERS_RESET_CHECK_SCREEN_CODE_VALID})

export const save        = (uuid, caretakerDetails, redirect = "/caretakers") => ({type: actionTypes.CARETAKERS_SAVE, uuid, caretakerDetails, redirect});
export const saveSuccess = () => ({type: actionTypes.CARETAKERS_SAVE_SUCCESS});
export const saveFail    = error => ({type: actionTypes.CARETAKERS_SAVE_FAIL, error});

export const savePhoto        = (uuid, file) => ({type: actionTypes.CARETAKERS_SAVE_PHOTO, uuid, file});
export const savePhotoSuccess = () => ({type: actionTypes.CARETAKERS_SAVE_PHOTO_SUCCESS});
export const savePhotoFail    = () => ({type: actionTypes.CARETAKERS_SAVE_PHOTO_FAIL});

export const saveMachineName        = (uuid, serial, machineDetails) => ({type: actionTypes.CARETAKERS_SAVE_MACHINENAME, uuid, serial, machineDetails});
export const saveMachineNameSuccess = () => ({type: actionTypes.CARETAKERS_SAVE_MACHINENAME_SUCCESS});
export const saveMachineNameFail    = () => ({type: actionTypes.CARETAKERS_SAVE_MACHINENAME_FAIL});

export const removePhoto        = uuid => ({type: actionTypes.CARETAKERS_REMOVE_PHOTO, uuid});
export const removePhotoSuccess = () => ({type: actionTypes.CARETAKERS_REMOVE_PHOTO_SUCCESS});
export const removePhotoFail    = () => ({type: actionTypes.CARETAKERS_REMOVE_PHOTO_FAIL});

export const remove        = uuid => ({type: actionTypes.CARETAKERS_REMOVE, uuid});
export const removeSuccess = () => ({type: actionTypes.CARETAKERS_REMOVE_SUCCESS});
export const removeFail    = error => ({type: actionTypes.CARETAKERS_REMOVE_FAIL, error});

export const saveDefaults        = (uuid, defaults) => ({type: actionTypes.CARETAKERS_SAVEDEFAULTS, uuid, defaults});
export const saveDefaultsSuccess = () => ({type: actionTypes.CARETAKERS_SAVEDEFAULTS_SUCCESS});
export const saveDefaultsFail    = error => ({type: actionTypes.CARETAKERS_SAVEDEFAULTS_FAIL, error});

export const saveSettings        = (uuid, settings) => ({type: actionTypes.CARETAKERS_SAVESETTINGS, uuid, settings});
export const saveSettingsSuccess = () => ({type: actionTypes.CARETAKERS_SAVESETTINGS_SUCCESS});
export const saveSettingsFail    = error => ({type: actionTypes.CARETAKERS_SAVESETTINGS_FAIL, error});

export const inviteUser        = userDetails => ({type: actionTypes.CARETAKERS_INVITE_USER, userDetails});
export const inviteUserSuccess = () => ({type: actionTypes.CARETAKERS_INVITE_USER_SUCCESS});
export const inviteUserFail    = error => ({type: actionTypes.CARETAKERS_INVITE_USER_FAIL, error});

export const clearError = () => ({type: actionTypes.CARETAKERS_RESET_ERROR});
