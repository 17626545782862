import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import i18n from "../../../i18n";
import * as actions from "../../../store/actions";

import {Button, Spinner, Form, BottomBar, Tour, ButtonCrusher} from "../../../components";
import withCaretakers from "../../../hoc/withCaretakers";
import {TitleWrapper} from "../../../contexts/TitleProvider";
import {getPageHelpSteps} from "../../../help/tools";
import css from "./SendMessage.module.css";

const messageForm = {
    message: {label: i18n.caretakersMessageMessage, className: css.tourMessage, type: "textarea", height: 124},
    row1:    {
        type:  "smallrow",
        items: {
            duration: {
                label:        i18n.caretakersMessageDuration,
                className:    css.tourDuration,
                size:         7,
                colStyle:     {flex: "100 0 auto", maxWidth: "none", width: "auto"},
                defaultValue: 900,
                type:         "select",
                options:      i18n.caretakersMessageDurations
            },
            urgent:   {
                label:     i18n.caretakersMessageUrgent,
                className: css.tourUrgent,
                size:      5,
                colStyle:  {flex: "none", maxWidth: "none", width: "auto"},
                type:      "switch"
            }
        }
    }
};

const SendMessage = props => {
    const dispatch    = useDispatch();
    const sending     = useSelector(state => state.caretakerMessages.sending);
    const sendSuccess = useSelector(state => state.caretakerMessages.sendSuccess);

    const [formValues, setFormValues] = useState(props.caretaker ? props.caretaker : {});
    const [formValid, setFormValid]   = useState(false);
    const [helpOpen, setHelpOpen]     = useState(false);
    const doHelp                      = () => setHelpOpen(true);
    const closeHelp                   = () => setHelpOpen(false);

    const handleChange = (newFormValues, newFormValid) => {
        setFormValues(newFormValues);
        setFormValid(newFormValid);
    }
    const handleSubmit = () => {
        if (!formValid) {
            dispatch(actions.messages.errorPush(i18n.errorForm));
            return;
        }
        dispatch(actions.caretakerMessages.send(props.caretaker.uuid, formValues.message, +formValues.duration, formValues.urgent));
    }

    useEffect(() => {
        if (sendSuccess) {
            dispatch(actions.caretakerMessages.clearSend());
            setFormValues({});
            setFormValid(false);
        }
    }, [sendSuccess, dispatch])

    return (
        <TitleWrapper title={i18n.caretakersMessageTo.replace('%1', props.caretaker ? props.caretaker.name : i18n.caretaker)}>
            <Tour
                isOpen={helpOpen}
                onRequestClose={closeHelp}
                steps={getPageHelpSteps("caretakerMessageSend")}
            />
            <div>
                <div className="content-form">
                    <Form
                        formData={messageForm}
                        values={formValues}
                        onChange={handleChange}
                        onValid={setFormValid}
                        stacked
                    />
                    <ButtonCrusher width={75}>
                        <Button round upper marginTop color="info" block onClick={handleSubmit}>{i18n.buttonSend}</Button>
                    </ButtonCrusher>
                    <BottomBar
                        backButton
                        links={[{link: "/caretakers/message/list/" + props.caretakerUuid, title: i18n.caretakersSentMessages}]}
                        onHelp={doHelp}
                        onCloseHelp={closeHelp}
                        helpOpen={helpOpen}
                    />
                    {(sending || props.loading) && <Spinner/>}
                </div>
            </div>
        </TitleWrapper>
    );
}

export default withCaretakers(SendMessage);