// React
import React from "react";

// Redux
import {connect} from "react-redux";
import {push} from 'connected-react-router'
import * as actions from "../../../../store/actions";

// Tools
import i18n from "../../../../i18n";
import {calculatePagination} from "../../../../variables/tools";
import tableColumns from "../../../../variables/tableColumns";

// Components
import {Spinner, ColumnSelector, Button, ReactTable, BottomBar} from "../../../../components";
import {TitleWrapper} from "../../../../contexts/TitleProvider";

class Caretakers extends React.Component {
    state = {
        group:      null,
        caretakers: null,
        columns:    null
    };

    tableColumns = tableColumns.caretakerColumnsDefault([
        {
            color:  "danger",
            icon:   "e-remove",
            action: uuid => this.props.removeCaretaker(this.props.match.params.group, uuid),
            text:   i18n.groupsCaretakersDelete
        }
    ]);

    constructor(props) {
        super(props);
        this.state.columns = this.tableColumns;

        let uuid = props.match.params.group;
        if (uuid.length !== 32) {
            props.push("/admin/groups");
            return;
        }

        if (props.groups && props.caretakers && !props.groupsLoading && !props.caretakersLoading) {
            this.state.group = props.groups.find(obj => obj.uuid === uuid);
            if (!this.state.group) {
                props.push("/admin/groups");
                return;
            }

            this.state.caretakers = props.caretakers.filter(obj => this.state.group.caretakers.includes(obj.uuid));
        }
        if (!props.groups && !props.groupsLoading) {
            props.loadGroups();
        }
        if (!props.caretakers && !props.caretakersLoading) {
            props.loadCaretakers();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props === prevProps) {
            return;
        }

        // If the location reloaded (path is the same, but key is different), reload machine details
        if (this.props.location.key !== prevProps.location.key) {
            //this.props.loadMachineDetails(this.props.match.params.caretaker);
        }

        // If the store changed, update the details
        if (this.props.groups && this.props.caretakers && !this.props.groupsLoading && !this.props.caretakersLoading) {
            let uuid  = this.props.match.params.group;
            let group = this.props.groups.find(obj => obj.uuid === uuid);
            if (!group) {
                this.props.push("/admin/groups");
                return;
            }

            let caretakers = this.props.caretakers.filter(obj => group.caretakers.includes(obj.uuid));
            this.setState({group, caretakers});
        }
    }

    render() {
        return (
            <TitleWrapper title={i18n.groupsCaretakersTitle.replace("%1", this.state.group ? this.state.group.name : i18n.group)}>
                <div>
                    <div className="content">
                        {this.state.caretakers && !this.props.groupsLoading && !this.props.caretakersLoading ? (
                            <>
                                <ColumnSelector cookieId="adminGroupCaretakers" columns={this.state.columns} changed={columns => this.setState({columns})}/>
                                <ReactTable
                                    filterable
                                    indexKey="id"
                                    {...calculatePagination(this.state.caretakers, 5)}
                                    data={this.state.caretakers}
                                    columns={this.state.columns}
                                    noDataText={i18n.groupsCaretakersNoCaretakers}
                                    defaultSorted={[{id: "serial", desc: false}]}
                                />
                                <Button
                                    round
                                    block
                                    color="info"
                                    onClick={() => this.props.push("/admin/groups/caretakers/add/" + this.props.match.params.group)}
                                >{i18n.groupsCaretakersAdd}</Button>
                            </>
                        ) : <Spinner/>}
                        <BottomBar backButton/>
                    </div>
                </div>
            </TitleWrapper>
        );
    }
}

const mapStateToProps = state => ({
    saving: state.caretakers.saving,

    caretakersLoading:  state.caretakers.loading,
    caretakersLoadedAt: state.caretakers.caretakersLoaded,
    caretakers:         state.caretakers.caretakers,

    groupsLoading:  state.groups.loading,
    groupsLoadedAt: state.groups.groupsLoaded,
    groups:         state.groups.groups
});

const mapDispatchToProps = dispatch => ({
    push:            pathname => dispatch(push(pathname)),
    loadCaretakers:  () => dispatch(actions.caretakers.load()),
    loadGroups:      () => dispatch(actions.groups.load()),
    removeCaretaker: (group, caretaker) => dispatch(actions.groups.removeCaretaker(group, caretaker))
});

export default connect(mapStateToProps, mapDispatchToProps)(Caretakers);
