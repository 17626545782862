import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import i18n from "../../i18n";
import * as actions from "../../store/actions";

import {Button, PanelHeader, Spinner, ReactTable} from "../../components";
import {Card, CardBody, CardHeader, Row, Col} from "reactstrap";
import {inviteColumnsDefault} from "../../variables/tableColumns/invites";
import {calculatePagination} from "../../variables/tools";

const InvitationPage = props => {
    const dispatch         = useDispatch();
    const loading          = useSelector(state => state.auth.loading);
    const invitedBy        = useSelector(state => state.auth.invitedBy);
    const invitedRightList = useSelector(state => state.auth.invitedRightList);

    const acceptInvite = () => dispatch(actions.auth.acceptInvite(props.match.params.token));
    const logOutInvite = () => dispatch(actions.auth.logout("/invitation/" + props.match.params.token));
    useEffect(() => {dispatch(actions.auth.loadInvite(props.match.params.token))}, [dispatch, props.match.params.token]);

    return (
        <div>
            <PanelHeader size="sm"/>
            <div className="content">
                <Card>
                    <CardHeader>
                        <h5 className="title">
                            {i18n.invitationTitleExisting}
                        </h5>
                    </CardHeader>
                    <CardBody>
                        {!loading && invitedBy && (
                            <>
                                <p>{i18n.invitationBody.replace("%invitedBy%", invitedBy)}</p>
                                <ReactTable
                                    data={invitedRightList}
                                    columns={inviteColumnsDefault()}
                                    noDataText={i18n.noRights}
                                    {...calculatePagination(invitedRightList, 10)}
                                />
                                <p/>
                                <p>{i18n.invitationSubBodyExisting}</p>
                                <Row>
                                    <Col xs={12} md={6} lg={6}>
                                        <Button block round color="success" size="lg" className="mb-3" onClick={acceptInvite}>
                                            {i18n.invitationButtonAccept}
                                        </Button>
                                    </Col>
                                    <Col xs={12} md={6} lg={6}>
                                        <Button block round color="danger" size="lg" className="mb-3" onClick={logOutInvite}>
                                            {i18n.routeLogout}
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </CardBody>
                </Card>
                {loading && <Spinner/>}
            </div>
        </div>
    );
};

export default InvitationPage;
