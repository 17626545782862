import i18n from "../../../i18n";

export default [
    {
        label:           i18n.caretakerSettingsThemeValues.modernblue,
        value:           "modernblue",
        backgroundcolor: "#09069f",
        textcolor:       "#ffffff"
    },
    {
        label:           i18n.caretakerSettingsThemeValues.modernbrown,
        value:           "modernbrown",
        backgroundcolor: "#60391d",
        textcolor:       "#ffffff"
    },
    {
        label:           i18n.caretakerSettingsThemeValues.moderngreen,
        value:           "moderngreen",
        backgroundcolor: "#064503",
        textcolor:       "#ffffff"
    },
    {
        label:           i18n.caretakerSettingsThemeValues.moderndeepsea,
        value:           "moderndeepsea",
        backgroundcolor: "#1d6049",
        textcolor:       "#ffffff"
    },
    {
        label:           i18n.caretakerSettingsThemeValues.modernblack,
        value:           "modernblack",
        backgroundcolor: "#000000",
        textcolor:       "#ffffff"
    },
    {
        label:           i18n.caretakerSettingsThemeValues.black,
        value:           "black",
        backgroundcolor: "#000000",
        textcolor:       "#ffffff"
    },
    {
        label:           i18n.caretakerSettingsThemeValues.blackboard,
        value:           "blackboard",
        backgroundcolor: "#383838",
        textcolor:       "#ffffff"
    },
    {
        label:           i18n.caretakerSettingsThemeValues.steelblue,
        value:           "steelblue",
        backgroundcolor: "#3c6579",
        textcolor:       "#ffffff"
    },
    {
        label:           i18n.caretakerSettingsThemeValues.nightblue,
        value:           "nightblue",
        backgroundcolor: "#072e4f",
        textcolor:       "#ffffff"
    },
    {
        label:           i18n.caretakerSettingsThemeValues.emeraldgreen,
        value:           "emeraldgreen",
        backgroundcolor: "#1e392a",
        textcolor:       "#ffffff"
    },
    {
        label:           i18n.caretakerSettingsThemeValues.lemongrass,
        value:           "lemongrass",
        backgroundcolor: "#a3b86d",
        textcolor:       "#1e392a"
    },
    {
        label:           i18n.caretakerSettingsThemeValues.papaver,
        value:           "papaver",
        backgroundcolor: "#984b43",
        textcolor:       "#ffffff"
    },
    {
        label:           i18n.caretakerSettingsThemeValues.pumpkin,
        value:           "pumpkin",
        backgroundcolor: "#df744a",
        textcolor:       "#ffffff"
    },
    {
        label:           i18n.caretakerSettingsThemeValues.yellow,
        value:           "yellow",
        backgroundcolor: "#ffcc33",
        textcolor:       "#000"
    },
    {
        label:           i18n.caretakerSettingsThemeValues.sockgreenolive,
        value:           "sockgreenolive",
        backgroundcolor: "#6f6a3e",
        textcolor:       "#fff"
    },
    {
        label:           i18n.caretakerSettingsThemeValues.sockgreenkhaki,
        value:           "sockgreenkhaki",
        backgroundcolor: "#a19667",
        textcolor:       "#393232"
    },
    {
        label:           i18n.caretakerSettingsThemeValues.sockdewcorn,
        value:           "sockdewcorn",
        backgroundcolor: "#a1a08b",
        textcolor:       "#393232"
    },
    {
        label:           i18n.caretakerSettingsThemeValues.sockdewwheat,
        value:           "sockdewwheat",
        backgroundcolor: "#868471",
        textcolor:       "#393232"
    },
    {
        label:           i18n.caretakerSettingsThemeValues.sockperuyellowbrown,
        value:           "sockperuyellowbrown",
        backgroundcolor: "#b98154",
        textcolor:       "#393232"
    },
    {
        label:           i18n.caretakerSettingsThemeValues.sockperusienna,
        value:           "sockperusienna",
        backgroundcolor: "#9e6534",
        textcolor:       "#fff"
    }
];