import React from 'react';
import PropTypes from 'prop-types';
import {Col, FormGroup, Label} from "reactstrap";

const Simple = props => (
    <FormGroup id={props.name} row={!props.noRow} className={
        (props.settings.className ? props.settings.className + " " : "") +
        (props.settings.touched && props.settings.validation ? (props.settings.valid ? "has-success" : "has-danger") : "")
    }>
        {!props.settings.noLabel ? <Label md={2} disabled={props.disabled}>{props.settings.label}</Label> : null}
        <Col md={!props.settings.noLabel ? 10 : 12}>
            {props.children}
        </Col>
    </FormGroup>
);

Simple.propTypes = {
    name:     PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    noRow:    PropTypes.bool
};

export default Simple;