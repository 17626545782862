import React from 'react';
import Select, {components} from "react-select";

import i18n from "../../../i18n";
import themeOptions from "./themes";
//import icons from "../../../variables/icons";
import css from "./ScreenSettings.module.css";

const ThemeOption = (props) => {
    // style={props.isFocused ? {backgroundColor: "rgba(255, 255, 255, 0.5)", color: "#222"} : {background: "transparant"}}
    let themeProps = {
        ...props,
        children: (
                      <div className="react-select-theme-background" style={{
                          backgroundColor: props.data.backgroundcolor,
                          color:           props.data.textcolor
                      }}>
                          <div className="react-select-theme-selector">{props.data.label}</div>
                      </div>
                  )
    };
    return (
        <components.Option className="react-select-outfilled-option" {...themeProps} />
    );
};
const ThemeValue  = props => <components.ValueContainer {...props} />;

export default {
    greeting:                 {
        className: css.tourGreeting,
        label:     i18n.caretakerSettingsGreeting,
    },
    theme:                    {
        className:     css.tourTheme,
        label:         i18n.caretakerSettingsTheme,
        validation:    false,
        defaultValue:  "black",
        type:          "select",
        customControl: (name, value, changeHandler) => (
            <Select
                className={css.themeSelect}
                classNamePrefix="themeSelectPrefix"
                placeholder={i18n.caretakerSettingsTheme}
                name="theme"
                value={themeOptions.find(obj => obj.value === value)}
                isSearchable={false}
                components={{Option: ThemeOption, ValueContainer: ThemeValue}}
                options={themeOptions}
                onChange={v => changeHandler(v.value, name)}
            />
        ),
        inline:        true
    },
    clockType:                {
        className:    css.tourClockType,
        label:        i18n.caretakerSettingsClock,
        defaultValue: "digital",
        type:         "select",
        options:      i18n.caretakerSettingsClockValues
    },
    hoursType:                {
        className:    css.tourHoursType,
        label:        i18n.caretakerSettingsHours,
        defaultValue: "24",
        type:         "select",
        options:      i18n.caretakerSettingsHoursValues
    },
    font:                     {
        className:    css.tourFont,
        label:        i18n.caretakerSettingsFont,
        defaultValue: "calibri",
        type:         "select",
        options:      i18n.caretakerSettingsFontValues
    },
    dayAfterTomorrow:         {
        className:    css.tourDAT,
        label:        i18n.caretakerSettingsDAT,
        defaultValue: "dat",
        type:         "select",
        options:      i18n.caretakerSettingsDATValues
    },
    language:                 {
        className:    css.tourLanguage,
        label:        i18n.caretakerSettingsLanguage,
        defaultValue: "nl",
        type:         "select",
        options:      i18n.caretakerSettingsLanguageValues
    },
    fadeSpeed:                {
        className:    css.tourFadeSpeed,
        label:        i18n.caretakerSettingsSpeed,
        defaultValue: 900,
        type:         "select",
        options:      i18n.caretakerSettingsSpeedValues
    },
    alertSound:               {
        className:  css.tourAlertSound,
        label:      i18n.caretakerSettingsNotificationSound,
        labelValue: [i18n.caretakerSettingsNotificationYes, i18n.caretakerSettingsNotificationNo],
        type:       "switch",
        noLabel:    true
    },
    tomorrowDay:              {
        className:   css.tourTomorrowDay,
        label:       i18n.caretakerSettingsTomorrowDay,
        labelValue:  [i18n.caretakerSettingsTomorrowDayYes, i18n.caretakerSettingsTomorrowDayNo],
        type:        "switch",
        invertValue: true,
        noLabel:     true
    },
    itIsToday:                {
        className:  css.tourItIsToday,
        label:      i18n.caretakerSettingsItIsToday,
        labelValue: [i18n.caretakerSettingsItIsTodayYes, i18n.caretakerSettingsItIsTodayNo],
        type:       "switch",
        noLabel:    true
    },
    showHour:                {
        className:  css.tourShowHour,
        label:      i18n.caretakerSettingsShowHourToday,
        labelValue: [i18n.caretakerSettingsShowHourTodayYes, i18n.caretakerSettingsShowHourTodayNo],
        type:       "switch",
        noLabel:    true
    },
    showCountdown:                {
        className:  css.tourShowCountdown,
        label:      i18n.caretakerSettingsShowCountdown,
        labelValue: [i18n.caretakerSettingsShowCountdownYes, i18n.caretakerSettingsShowCountdownNo],
        type:       "switch",
        noLabel:    true
    },
    showWeather:              {
        className:  css.tourShowWeather,
        label:      i18n.caretakerSettingsShowWeather,
        labelValue: [i18n.caretakerSettingsShowWeatherYes, i18n.caretakerSettingsShowWeatherNo],
        type:       "switch",
        noLabel:    true
    },
    tomorrowWeather:          {
        className:  css.tourTomorrowWeather,
        label:      i18n.caretakerSettingsTomorrowWeather,
        labelValue: [i18n.caretakerSettingsTomorrowWeatherYes, i18n.caretakerSettingsTomorrowWeatherNo],
        type:       "switch",
        noLabel:    true
    },
    publicHolidaysShow:       {
        className:  css.tourPublicHolidaysShow,
        label:      i18n.caretakerSettingsPublicHolidays,
        labelValue: [i18n.caretakerSettingsPublicHolidaysYes, i18n.caretakerSettingsPublicHolidaysNo],
        type:       "switch",
        noLabel:    true
    },
    publicHolidaysShowBefore: {
        className:    css.tourPublicHolidaysShowBefore,
        label:        i18n.caretakerSettingsPublicHolidaysShowBefore,
        defaultValue: "6 days",
        type:         "select",
        options:      i18n.caretakerSettingsPublicHolidaysShowBeforeValues
    },
    publicHolidaysCountry:    {
        className:    css.tourPublicHolidaysCountry,
        label:        i18n.caretakerSettingsPublicHolidaysCountry,
        defaultValue: "nl",
        type:         "select",
        options:      i18n.caretakerSettingsPublicHolidaysCountryValues
    }
};