export default {
    distributors:   "Distributors",
    noDistributors: "No distributors",

    distributorsTitle:             "Distributors",
    distributorsTableName:         "Name",
    distributorsTableCity:         "City",
    distributorsTableCountry:      "Country",
    distributorsTablePhone:        "Phone",
    distributorsTableEmail:        "E-mail",
    distributorsTableMachines:     "Machines",
    distributorsTableSent:         "Sent",
    distributorsTableSentShort:    "S",
    distributorsTableCoupled:      "Coupled",
    distributorsTableCoupledShort: "C",
    distributorsActionsEdit:       "Edit",
    distributorsActionsDelete:     "Remove",

    distributorsAddTitle:      "Add distributor",
    distributorsEditTitle:     "Edit distributor",
    distributorsEditSuccess:   "The distributor details have been successfully saved",
    distributorsEditError:     "An error occurred while saving the distributor details",
    distributorsRemoveTitle:   "Are you sure you want to delete this distributor?",
    distributorsRemoveBody:    "If you delete the distributor named '%1', all machines currently coupled to this distributor will be coupled to Spiqle. This can not be undone.",
    distributorsRemoveSure:    "Delete distributor",
    distributorsRemoveSuccess: "The distributor has been deleted successfully",
    distributorsRemoveError:   "An error occurred while deleting the distributor",

    distributorName:    "Name",
    distributorAddress: "Address",
    distributorZipcode: "Zipcode",
    distributorCity:    "City",
    distributorState:   "Province",
    distributorCountry: "Country",
    distributorPhone:   "Phone number",
    distributorEmail:   "E-mail address",
};