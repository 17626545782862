// React
import React from "react";

// Redux
import {connect} from "react-redux";
import {push} from 'connected-react-router'
import * as actions from "../../../store/actions";

// Tools
import i18n from "../../../i18n";
import tableColumns from "../../../variables/tableColumns";

// Components
import {Spinner, ColumnSelector, ReactTable, BottomBar} from "../../../components";
import {TitleWrapper} from "../../../contexts/TitleProvider";

class Machines extends React.Component {
    state = {columns: null};

    machineTableColumns = tableColumns.machineColumnsDefaultRedux(
        () => this.props.caretakers,
        () => this.props.distributors,
        () => this.props.affiliates,
        [
            {
                color:  "info",
                icon:   "c-info",
                action: value => this.props.push("/admin/machines/info/" + value),
                text:   i18n.machineInfoTitle
            }
        ]
    );

    constructor(props) {
        super(props);
        this.state.columns = this.machineTableColumns;
        props.loadCaretakers();
        props.loadDistributors();
        props.loadAffiliates();

        if (!props.loading) {
            props.load();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props === prevProps) {
            return;
        }

        // If the location reloaded (path is the same, but key is different), reload machine details
        if (this.props.location.key !== prevProps.location.key) {
            this.props.load();
        }
    }

    render() {
        return (
            <TitleWrapper title={i18n.routeAdminMachinesFull}>
                <div>
                    <div className="content">
                        {!this.props.loading && this.props.caretakers && this.props.distributors && this.props.affiliates && this.props.machines ? (
                            <>
                                <ColumnSelector cookieId="adminMachines" columns={this.state.columns} changed={columns => this.setState({columns})}/>
                                <ReactTable
                                    filterable
                                    indexKey="id"
                                    data={this.props.machines}
                                    columns={this.state.columns}
                                    defaultSorted={[{id: "serial", desc: false}]}
                                    noDataText={i18n.noMachines}
                                />
                            </>
                        ) : <Spinner/>}
                        <BottomBar backButton/>
                    </div>
                </div>
            </TitleWrapper>
        );
    }
}

const mapStateToProps = state => ({
    caretakers:   state.caretakers.caretakers,
    distributors: state.distributors.distributors,
    affiliates:   state.affiliates.affiliates,
    loading:      state.machines.loading,
    machines:     state.machines.machines,
    loadedAt:     state.machines.machinesLoaded
});

const mapDispatchToProps = dispatch => ({
    push:             pathname => dispatch(push(pathname)),
    load:             () => dispatch(actions.machines.load()),
    loadCaretakers:   () => dispatch(actions.caretakers.loadIfNeeded()),
    loadDistributors: () => dispatch(actions.distributors.loadIfNeeded()),
    loadAffiliates:   () => dispatch(actions.affiliates.loadIfNeeded())
});

export default connect(mapStateToProps, mapDispatchToProps)(Machines);
