import React from 'react';
import PropTypes from 'prop-types';
import {Input, Label, FormGroup} from "reactstrap";
import AutoWrapInput from "../InputContainers/AutoWrapInput";
import {deepCompare} from "../../../variables/tools";

const Select = props => (
    <AutoWrapInput name={props.name} settings={props.settings} stacked={props.stacked} disabled={props.disabled}>
        {Object.entries(props.settings.options).map(([data, text]) => (
            <FormGroup check key={data}>
                <Label check>
                    <Input
                        {...props.settings.elementConfig}
                        type="radio"
                        name={props.name}
                        disabled={props.disabled}
                        onChange={event => props.handlers.change(data, props.name)}
                        onFocus={() => props.handlers.focus(props.name, true)}
                        onBlur={() => props.handlers.focus(props.name, false)}
                        value={data}
                        innerRef={props.innerRef}
                        {...(String(data) === String(props.settings.value) ? {checked: true} : {})}
                    />
                    {' '}
                    {text}
                </Label>
            </FormGroup>
        ))}
    </AutoWrapInput>
);

Select.propTypes = {
    name:     PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    handlers: PropTypes.object.isRequired,
    innerRef: PropTypes.object,
    stacked:  PropTypes.bool
};

export default React.memo(Select, (prevProps, nextProps) => (
    prevProps.settings.value === nextProps.settings.value &&
    prevProps.settings.focused === nextProps.settings.focused &&
    prevProps.settings.valid === nextProps.settings.valid &&
    prevProps.disabled === nextProps.disabled &&
    deepCompare(prevProps.settings.options, nextProps.settings.options)
));
