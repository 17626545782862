import React from 'react';
import PropTypes from 'prop-types';
import {FormGroup} from "reactstrap";
import {Link} from "react-router-dom";
import css from "./Inline.module.css";

const Inline = props => (
    <FormGroup
        id={props.name}
        className={
            (props.settings.className ? props.settings.className + " " : "") +
            ((props.settings.touched && props.settings.validation) ? (props.settings.valid ? "has-success" : "has-danger") : "")
        }
    >
        {!props.settings.noLabel ? <label className={css.label}>{props.settings.label}</label> : null}
        {props.settings.labelLink ? <Link tabIndex={-1} to={props.settings.labelLink.to}>{props.settings.labelLink.label}</Link> : null}
        {props.children}
    </FormGroup>
);

Inline.propTypes = {
    name:     PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    noRow:    PropTypes.bool
};

export default Inline;