export default {
    machines:   "Maschinen",
    noMachines: "Keine Maschinen",

    machinesSerialNumber: "Serien#",
    machinesSerialCheck:  "Check#",
    machinesName:         "Name",
    machinesMacAddress:   "MAC-Adresse",
    machinesNetwork:      "Netzwerk",
    machinesIMSI:         "IMSI",
    machinesIMEI:         "IMEI",
    machinesOS:           "OS",
    machinesVersion:      "Version",
    machinesLastUpdate:   "Letztes Update",
    machinesLastBoot:     "Letzter Boot",
    machinesLastIP:       "Letzter IP",
    machinesCreatedAt:    "Erstellt",
    machinesTemperature:  "Temperatur",
    machinesLoadError:    "Beim Laden der Maschineninformationen ist ein Fehler aufgetreten",
    machinesCoupled:      "Verknüpft",
    machinesCoupledTo:    "Verbunden mit",
    machinesUncoupled:    "Nicht verbunden",
    machinesDistributor:  "Distributor",
    machinesAffiliate:    "Partner",
    machinesVirtual:      "Virtuelle Maschine",
    machinesServer:       "Über CDS",
    machinesIP:           "Netzwerk IP",

    machinesNotCoupled:    "Nicht verknüpft",
    machinesNoDistributor: "Kein distributor",
    machinesNoAffiliate:   "Kein Partner",

    machinesActions:                      "Aktionen",
    machinesActionsTitle:                 "Aktionen für diese Maschine",
    machinesActionsInfo:                  "Info",
    machinesActionsSwap:                  "Tauschen",
    machinesActionsUncouple:              "abkuppeln",
    machinesActionsClearUpdates:          "Löschen Sie alle Updates",
    machinesActionsClearUpdatesAndResend: "Löschen Sie alle Updates und senden Sie den Inhalt erneut",

    machinesNetworkFilterAll:       "Zeige alles",
    machinesNetworkFilterEthernet:  "Ethernet",
    machinesNetworkFilterWiFi:      "WiFi",
    machinesNetworkFilterMobile:    "Mobil",
    machinesNetworkFilterMobileLTE: "Mobil - Nur LTE",
    machinesNetworkFilterMobileGSM: "Mobil - Nur GSM",

    machineCoupleTitle:   "Neue Maschine verbinden",
    machineCoupleBody:    "Wählen Sie den Computer mit dem Namen '% 1' aus, den Sie dem Pflegeempfänger zuordnen möchten. Wenn Sie einen Computer auswählen, der bereits verknüpft ist, wird er vom aktuellen Benutzer getrennt und mit dieser Person verknüpft.",
    machineCoupleSure:    "Maschine verbinden",
    machineCoupleSuccess: "Die Machine wurde erfolgreich verknüpft",
    machineCoupleError:   "Beim Anschließen der Machine ist ein Fehler aufgetreten",

    machineSwapTitle:   "Maschine tauschen",
    machineSwapBody:    "Wählen Sie das Gerät aus, das Sie mit dem Gerät mit der MAC-Adresse '% 1' austauschen möchten. Wenn Sie eine Maschine auswählen, die bereits verknüpft ist, wird der Inhalt beider Maschinen ausgetauscht.",
    machineSwapSure:    "Maschines tauschen",
    machineSwapSuccess: "Die Maschinen wurden erfolgreich getauscht",
    machineSwapError:   "Beim Austauschen der Maschinen ist ein Fehler aufgetreten",

    machineUncoupleTitle:   "Möchten Sie dieser Maschine wirklich entkuppeln?",
    machineUncoupleBody:    "Wenn Sie die Machine mit der MAC-Adresse '% 1' trennen, wird dieser Machine zurückgesetzt und neue Übereinstimmungswörter werden auf dem Bildschirm angezeigt. Dies kann nicht rückgängig gemacht werden.",
    machineUncoupleSure:    "Maschine entkuppeln",
    machineUncoupleSuccess: "Die Maschine wurde erfolgreich entkuppelt",

    machineInfoTitle:               "Maschine info",
    machineInfoDataHead:            "Maschinendaten für %1",
    machineInfoCoupledHead:         "Kuppel info",
    machineInfoCoupleEditCaretaker: "Bearbeiten Sie diesen Pflegeempfänger",
    machineInfoCoupleShowMachines:  "Zeige alle Maschinen dieses Pflegeempfängers",
    machineInfoUpdatesHead:         "Updates",

    machineUpdatesId:           "ID",
    machineUpdatesCommand:      "Befehl",
    machineUpdatesDone:         "Durchgeführt",
    machineUpdatesDoneYes:      "Update durchgeführt",
    machineUpdatesDoneNo:       "Update nicht durchgeführt",
    machineUpdatesError:        "Fehler beim Ausführen",
    machineUpdatesErrorYes:     "Fehler beim Ausführen",
    machineUpdatesErrorNo:      "Kein Fehler beim Ausführen",
    machineUpdatesFilename:     "Dateiname",
    machineUpdatesDateUpdated:  "Aktualisiert am",
    machineUpdatesNotUpdated:   "Noch nicht aktualisiert",
    machineUpdatesDatePlaced:   "Gepostet am",
    machineUpdatesReturnLog:    "Befehlsprotokoll",
    machineUpdatesOrigin:       "Gepostet von",
    machineUpdatesSentToServer: "An CDS gesendet",
    machineNoUpdates:           "Kein updates",

    machineUpdatesCancelError:         "Beim Abbrechen des Updates ist ein Fehler aufgetreten",
    machineUncoupleError:              "Beim Entkuppeln der Maschine ist ein Fehler aufgetreten",
    machineClearUpdatesError:          "Beim Entfernen der Updates ist ein Fehler aufgetreten",
    machineClearUpdatesAndResendError: "Beim Entfernen der Updates und erneuten Senden des Inhalts ist ein Fehler aufgetreten",

    machineCommands:                    {
        "download":            "Download",
        "zip_download":        "Download ZIP",
        "command":             "Befehl",
        "delete":              "Löschen",
        "send_file":           "Datei hochladen",
        "apk_install":         "APK installieren",
        "apk_install_restart": "APK installieren und neu starten",
        "apk_uninstall":       "APK deinstallieren",
        "setting":             "Einstellung festlegen",
        "get_setting":         "Einstellungen abrufen",
        "system_setting":      "Stellen Sie die Systemeinstellungen ein",
        "get_system_setting":  "Systemeinstellungen abrufen",
        "send_logs":           "Protokolle abrufen",
        "reboot":              "Starten Sie das System neu",
        "restart":             "Starten Sie die Machine neu",
    },
    machineManualUpdatesMachines:       "Maschinen",
    machineManualUpdatesCommand:        "Befehl",
    machineManualUpdatesPath:           "Pfad",
    machineManualUpdatesFile:           "Datei",
    machineManualUpdatesSend:           "Befehl senden",
    machineManualUpdatesSetting:        "Einstellung",
    machineManualUpdatesSettingSection: "Einstellungskategorie",
    machineManualUpdatesSettingName:    "Einstellungsname",
    machineManualUpdatesSettingValue:   "Einstellungswert",
    machineManualUpdatesSettingType:    "Einstellungstyp",
    machineManualUpdatesSuccess:        "Das manuelle Update wurde erfolgreich gesendet.",
    machineManualUpdatesError:          "Beim Senden des manuellen Updates ist ein Fehler aufgetreten",
};