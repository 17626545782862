export default {
    machines:   "Machines",
    noMachines: "No machines",

    machinesSerialNumber: "Serial#",
    machinesSerialCheck:  "Check#",
    machinesName:         "Name",
    machinesMacAddress:   "MAC address",
    machinesNetwork:      "Network",
    machinesIMSI:         "IMSI",
    machinesIMEI:         "IMEI",
    machinesOS:           "OS",
    machinesVersion:      "Version",
    machinesLastUpdate:   "Last update",
    machinesLastBoot:     "Last boot",
    machinesLastIP:       "Last IP",
    machinesCreatedAt:    "Created",
    machinesTemperature:  "Temperature",
    machinesLoadError:    "An error occurred while loading the machine info",
    machinesCoupled:      "Coupled",
    machinesCoupledTo:    "Coupled to",
    machinesUncoupled:    "Uncoupled",
    machinesDistributor:  "Distributor",
    machinesAffiliate:    "Affiliate",
    machinesVirtual:      "Virtual machine",
    machinesServer:       "Via CDS",
    machinesIP:           "Network IP",

    machinesNotCoupled:    "Not coupled",
    machinesNoDistributor: "No distributor",
    machinesNoAffiliate:   "No affiliate",

    machinesActions:                      "Actions",
    machinesActionsTitle:                 "Actions for this machine",
    machinesActionsInfo:                  "Info",
    machinesActionsSwap:                  "Swap",
    machinesActionsUncouple:              "Uncouple",
    machinesActionsClearUpdates:          "Remove all updates",
    machinesActionsClearUpdatesAndResend: "Remove all updates and resend content",

    machinesNetworkFilterAll:       "Show all",
    machinesNetworkFilterEthernet:  "Ethernet",
    machinesNetworkFilterWiFi:      "WiFi",
    machinesNetworkFilterMobile:    "Mobile",
    machinesNetworkFilterMobileLTE: "Mobile - Only LTE",
    machinesNetworkFilterMobileGSM: "Mobile - Only GSM",

    machineCoupleTitle:   "Couple new machine",
    machineCoupleBody:    "Select the machine you want to couple to the caretaker named '%1'. If you select a machine that's already coupled, it will be uncoupled from the current caretaker and coupled to this caretaker.",
    machineCoupleSure:    "Couple machine",
    machineCoupleSuccess: "The machine has been coupled successfully",
    machineCoupleError:   "An error occurred while coupling the machine",

    machineSwapTitle:   "Swap machine",
    machineSwapBody:    "Select the machine you want to swap with the machine with MAC address '%1'. If you select a machine that's already coupled, the contents of both machines will be swapped.",
    machineSwapSure:    "Swap machines",
    machineSwapSuccess: "The machines have been successfully swapped",
    machineSwapError:   "An error occurred while swapping the machines",

    machineUncoupleTitle:   "Are you sure you want to uncouple this machine?",
    machineUncoupleBody:    "If you uncouple the machine with the MAC address '%1', the machine will be reset and a new couple code will appear on screen. This can not be undone.",
    machineUncoupleSure:    "Uncouple machine",
    machineUncoupleSuccess: "The machine has been uncoupled successfully",

    machineInfoTitle:               "Machine info",
    machineInfoDataHead:            "Machine details for %1",
    machineInfoCoupledHead:         "Couple info",
    machineInfoCoupleEditCaretaker: "Edit this caretaker",
    machineInfoCoupleShowMachines:  "Show all machines of this caretaker",
    machineInfoUpdatesHead:         "Updates",

    machineUpdatesId:           "ID",
    machineUpdatesCommand:      "Command",
    machineUpdatesDone:         "Done",
    machineUpdatesDoneYes:      "Update done",
    machineUpdatesDoneNo:       "Update not done",
    machineUpdatesError:        "Error while updating",
    machineUpdatesErrorYes:     "Error while updating",
    machineUpdatesErrorNo:      "No errors",
    machineUpdatesFilename:     "Filename",
    machineUpdatesDateUpdated:  "Updated at",
    machineUpdatesNotUpdated:   "Not updated yet",
    machineUpdatesDatePlaced:   "Placed at",
    machineUpdatesReturnLog:    "Command log",
    machineUpdatesOrigin:       "Placed by",
    machineUpdatesSentToServer: "Sent to CDS",
    machineNoUpdates:           "No updates",

    machineUpdatesCancelError:         "An error occurred while canceling the update",
    machineUncoupleError:              "An error occurred while uncoupling the machine",
    machineClearUpdatesError:          "An error occurred while removing the updates",
    machineClearUpdatesAndResendError: "An error occurred while removing the updates and resending the contents",

    machineCommands:                    {
        "download":            "Download",
        "zip_download":        "Download ZIP",
        "command":             "Command",
        "delete":              "Delete",
        "send_file":           "Upload file",
        "apk_install":         "Install APK",
        "apk_install_restart": "Install APK & restart",
        "apk_uninstall":       "Uninstall APK",
        "setting":             "Set setting",
        "get_setting":         "Get setting",
        "system_setting":      "Set system setting",
        "get_system_setting":  "Get system setting",
        "send_logs":           "Get logs",
        "reboot":              "Restart system",
        "restart":             "Restart player"
    },
    machineManualUpdatesMachines:       "Machines",
    machineManualUpdatesCommand:        "Command",
    machineManualUpdatesPath:           "Path",
    machineManualUpdatesFile:           "File",
    machineManualUpdatesSetting:        "Setting",
    machineManualUpdatesSettingSection: "Setting category",
    machineManualUpdatesSettingName:    "Setting name",
    machineManualUpdatesSettingValue:   "Setting value",
    machineManualUpdatesSettingType:    "Setting type",
    machineManualUpdatesSend:           "Send command",
    machineManualUpdatesSuccess:        "Manual update successfully sent.",
    machineManualUpdatesError:          "An error occurred while sending the manual update"
};