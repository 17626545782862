import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import * as actions from "../../store/actions";

const LogoutPage = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.auth.logout("/"))
    }, [dispatch]);
    return <div />;
};

export default LogoutPage;
