import React from 'react';
import {Button} from "../../../components";
import {Row, Col} from "reactstrap";
import Image from "./Image";
import Youtube from "./Youtube";
import Link from "./Link";

import css from "./Attachments.module.css";

const Attachments = props => {
//      // <ImageUpload onSelect={handleFile} onRemove={handleRemove} existingImage={null}/>
    const addImage = () => props.onChange([...props.value, {uuid: "new", type: "image", file: null, url: null}]);
    const addYoutube = () => props.onChange([...props.value, {uuid: "new", type: "youtube", url: ""}]);
    const addLink = () => props.onChange([...props.value, {uuid: "new", type: "link", url: ""}]);
    const updateItem = (index, data) => {
        const newArray = [...props.value];
        newArray[index] = data;
        props.onChange(newArray);
    };
    const removeItem = index => props.onChange(props.value.filter((item, id) => id !== index));

    return (
        <div>
            <h5>Attachments</h5>
            <div className={css.attachmentList}>
            {props.value && props.value.map((item,index) => {
                switch(item.type) {
                    case "image":
                        return <Image
                            key={index}
                            uuid={item.uuid}
                            file={item.file}
                            url={item.url}
                            onChange={data => updateItem(index, data)}
                            onRemove={() => removeItem(index)}
                        />;

                    case "youtube":
                        return <Youtube
                            key={index}
                            uuid={item.uuid}
                            url={item.url}
                            onChange={data => updateItem(index, data)}
                            onRemove={() => removeItem(index)}
                        />;

                    case "link":
                        return <Link
                            key={index}
                            uuid={item.uuid}
                            url={item.url}
                            onChange={data => updateItem(index, data)}
                            onRemove={() => removeItem(index)}
                        />;

                    default:
                        return null;
                }
            })}
            </div>
            <Row>
                <Col xs={12} md={4}>
                    <Button round block color="info" onClick={addImage}>Add image</Button>
                </Col>
                <Col xs={12} md={4}>
                    <Button round block color="info" onClick={addYoutube}>Add Youtube video</Button>
                </Col>
                <Col xs={12} md={4}>
                    <Button round block color="info" onClick={addLink}>Add link</Button>
                </Col>
            </Row>
        </div>
    );
};

export default Attachments;