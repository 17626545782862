import React from "react";
import PropTypes from 'prop-types';

import {FormattedBreaks} from "../../components";
import {apiGetFullPath, apiGetToken} from "../../api";

import css from "./Help.module.css";
import {thisIsAnApp} from "../../variables/multiplatform";

const HelpItemCard = props => {
    return (
        <div className={css.helpPieces}>
            {props.attachments.map(attachment => attachment.type === "image" ? (
                <div className="help-image-container" key={attachment.uuid}>
                    <img
                        src={apiGetFullPath("help/getimage?uuid=" + attachment.uuid + "&token=" + apiGetToken())}
                        className="help-image"
                        alt="Help"
                    />
                </div>
            ) : null)}
            <FormattedBreaks text={props.body}/>
            {props.attachments.map(attachment => attachment.type === "link" ? (
                <div className="help-url" key={attachment.uuid}>
                    {thisIsAnApp ? (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a className={css.helpLink} onClick={() => {window.open(attachment.url, '_system'); return false;}}>{attachment.url}</a>
                    ) : (
                        <a className={css.helpLink} href={attachment.url} target="_blank" rel="noopener noreferrer">{attachment.url}</a>
                    )}
                </div>
            ) : null)}
            {props.attachments.map(attachment => {
                if (attachment.type !== "youtube") return null;
                let youtubeRegex = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/gi;
                let youtubeId = youtubeRegex.exec(attachment.url);
                if (youtubeId) {
                    return (
                        <div className={css.helpYoutube} key={attachment.uuid}>
                            <iframe
                                className={css.helpYoutubeIframe}
                                title={"Video - " + youtubeId}
                                src={"https://www.youtube.com/embed/" + youtubeId[1]}
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </div>
                    );
                }
                return null;
            })}
        </div>
    );
};

HelpItemCard.propTypes = {
    uuid: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    image: PropTypes.bool,
    attachments: PropTypes.array
};

export default HelpItemCard;
