import css from "./tableColumns.module.css";
import i18n from "../../i18n";
import icons from "../../variables/icons";
import {standardColumn, makeYesNoColumn, defaultColumns} from "./funcs";

export const userColumns = {
    name:               standardColumn("name", i18n.usersTableName, {className: css.name, togglable: false}),
    zipcode:            standardColumn("zipcode", i18n.usersTableZipcode, {show: false}),
    city:               standardColumn("city", i18n.usersTableCity, {show: false}),
    country:            standardColumn("country", i18n.usersTableCountry, {show: false}),
    phone:              standardColumn("phone", i18n.usersTablePhone, {show: false}),
    email:              standardColumn("email", i18n.usersTableEmail),
    disabled:           makeYesNoColumn("disabled", i18n.usersTableDisabled, icons["a-delete"]),
    super_admin:        makeYesNoColumn("super_admin", i18n.usersTableAdmin, icons["man-up"]),
    two_factor_enabled: makeYesNoColumn("two_factor_enabled", i18n.usersTableTwoFactorEnabled, icons["2x-tap"], {show: false}),
    email_confirmed:    makeYesNoColumn("email_confirmed", i18n.usersTableEmailConfirmed, icons["email-2"], {show: false})
};

export const userColumnsDefault = (actions = null, selector = null) => defaultColumns(
    [
        userColumns.name,
        userColumns.zipcode,
        userColumns.city,
        userColumns.country,
        userColumns.phone,
        userColumns.email,
        userColumns.disabled,
        userColumns.super_admin,
        userColumns.two_factor_enabled,
        userColumns.email_confirmed
    ], actions, "uuid", selector
);