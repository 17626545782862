import React, {Component} from 'react';
import {connect} from "react-redux";

import * as actions from "../../../store/actions";
import i18n from "../../../i18n";
import tableColumns from "../../../variables/tableColumns";

import {Button, Spinner, ReactTable, BottomBar} from "../../../components";
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {TitleWrapper} from "../../../contexts/TitleProvider";

class Groups extends Component {
    state = {
        modalDelete: false,
        deleteUUID:  "",
        deleteName:  ""
    };

    editGroup   = uuid => this.props.history.push("/admin/groups/edit/" + uuid);
    deleteGroup = (uuid, name) => {
        this.setState({
            modalDelete: true,
            deleteUUID:  uuid,
            deleteName:  name
        })
    };

    tableColumns = tableColumns.groupColumnsDefault([
        {
            color:  "info",
            icon:   "pen",
            action: this.editGroup,
            text:   i18n.groupsActionsEdit
        },
        {
            color:  "danger",
            icon:   "e-remove",
            action: this.deleteGroup,
            text:   i18n.groupsActionsDelete
        }
    ]);

    constructor(props) {
        super(props);
        props.load();
    }

    handleDelete      = () => {
        this.props.remove(this.state.deleteUUID);
        this.setState({modalDelete: false});
    };
    toggleModalDelete = () => this.setState(state => ({modalDelete: !state.modalDelete}));

    render = () => (
        <TitleWrapper title={i18n.routeAdminGroupsFull}>
            <div>
                <div className="content">
                    {this.props.groups && !this.props.loading && !this.props.saving ? (
                        <ReactTable
                            filterable
                            data={this.props.groups}
                            columns={this.tableColumns}
                            defaultSorted={[{id: "name", desc: false}]}
                            noDataText={i18n.noGroups}
                        />
                    ) : <Spinner/>}
                    <BottomBar backButton links={[{title: i18n.groupsAddTitle, link: "/admin/groups/add"}]}/>
                </div>
                <Modal isOpen={this.state.modalDelete} toggle={this.toggleModalDelete} className="text-center">
                    <ModalHeader className="justify-content-center uppercase title" tag="h4">{i18n.groupsRemoveTitle}</ModalHeader>
                    <ModalBody><p>{i18n.groupsRemoveBody.replace('%1', this.state.deleteName)}</p></ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.handleDelete}>{i18n.groupsRemoveSure}</Button>
                        <Button color="info" onClick={this.toggleModalDelete}>{i18n.buttonCancel}</Button>
                    </ModalFooter>
                </Modal>
            </div>
        </TitleWrapper>
    );
}

const mapStateToProps    = state => ({
    loading:  state.groups.loading,
    saving:   state.groups.saving,
    groups:   state.groups.groups,
    loadedAt: state.groups.groupsLoaded
});
const mapDispatchToProps = dispatch => ({
    load:   () => dispatch(actions.groups.load()),
    remove: uuid => dispatch(actions.groups.remove(uuid))
});

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
