import React from "react";
import bindings from "./bindings";
import {store} from "../store";
import HelpItemCard from "../views/Help/HelpItemCard";
import i18n from "../i18n";

export const getPageHelpSteps = page => {
    if (!bindings.hasOwnProperty(page)) {
        return [];
    }

    const state = store.getState();
    if (!state.help.itemsLoaded) {
        return [];
    }

    const pageBindings = bindings[page];
    return pageBindings.map(binding => {
        for (const item of state.help.items) {
            if (item.pageBinding === page + "::" + binding.name) {
                return {
                    selector: "." + binding.className,
                    content:  <HelpItemCard {...item} />,
                    position: binding.position ? binding.position : "bottom"
                };
            }
        }
        return {
            selector: "." + binding.className,
            content:  binding.description,
            position: binding.position ? binding.position : "bottom"
        };
    });
}

export const getPageHelpBindings = () => {
    const pageBindings = {"": i18n.helpNoPageBinding};
    const pages = Object.keys(bindings);
    for(const page of pages) {
        for(const binding of bindings[page]) {
            pageBindings[page + "::" + binding.name] = page + "::" + binding.name + " -> " + binding.description;
        }
    }
    return pageBindings;
}