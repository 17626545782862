import cssBottomBar from "../components/UI/BottomBar/BottomBar.module.css";
import cssAccount from "../views/Account/Account.module.css";
import cssAccountPassword from "../views/Account/Password/Password.module.css";
import cssContact from "../views/Contact/Contact.module.css";
import cssCaretakers from "../views/Caretakers/Caretakers.module.css";
import cssCaretaker from "../views/Caretakers/CaretakerItem.module.css";
import cssCaretakerAddItem from "../views/Caretakers/AddItem/AddItem.module.css";
import cssGroupAddItem from "../views/Groups/AddItem/AddItem.module.css";
import cssCaretakerEdit from "../views/Caretakers/Edit/Edit.module.css";
import cssCaretakerAddNewMachine from "../views/Caretakers/AddNewMachine/AddNewMachine.module.css";
import cssCaretakerAddCaretaker from "../views/Caretakers/AddCaretaker/AddCaretaker.module.css";
import cssCaretakerInviteNewUser from "../views/Caretakers/InviteNewUser/InviteNewUser.module.css";
import cssCaretakerEditAvatar from "../views/Caretakers/EditAvatar/EditAvatar.module.css";
import cssCaretakerSettings from "../views/Caretakers/Settings/Settings.module.css";
import cssCaretakerScreenSettings from "../views/Caretakers/ScreenSettings/ScreenSettings.module.css";
import cssCaretakerPreview from "../views/Caretakers/Preview/Preview.module.css";
import cssCaretakerPreviousMessages from "../views/Caretakers/Message/PreviousMessages.module.css";
import cssCaretakerSendMessage from "../views/Caretakers/Message/SendMessage.module.css";
import cssCaretakerEditAppointment from "../views/Caretakers/CalendarEdit/EditAppointment.module.css";
import cssCaretakerEditBirthday from "../views/Caretakers/CalendarEdit/EditBirthday.module.css";
import cssCaretakerEditReminder from "../views/Caretakers/CalendarEdit/EditReminder.module.css";
import cssCaretakerEditTvShow from "../views/Caretakers/CalendarEdit/EditTvShow.module.css";
import cssCalendars from "../views/Caretakers/Calendars/Calendars.module.css";
import cssCaretakerStatus from "../views/Caretakers/Status/StatusItem.module.css";
import cssCaretakerScreenStatus from "../views/Caretakers/ScreenStatus/ScreenStatus.module.css";
import cssAllItems from "../views/Caretakers/AllItems/AllItems.module.css";
import cssGroupsEdit from "../views/Groups/Edit/Edit.module.css";
import cssGroupsAllItems from "../views/Groups/Calendars/Calendars.module.css";
import cssGroupsCaretakers from "../views/Groups/Caretakers/Caretakers.module.css";
import cssGroupsSingleCaretakers from "../views/Groups/Caretakers/SingleCaretaker.module.css";
import cssOverview from "../views/Caretakers/Overview/Overview.module.css";

export default {
    caretakers:               [
        {
            name:        "title",
            className:   "title",
            description: "The title of the page"
        },
        {
            name:        "country",
            className:   cssCaretakers.tourCountry,
            description: "The country dropdown"
        },
        {
            name:        "city",
            className:   cssCaretakers.tourCity,
            description: "The city dropdown",
        },
        {
            name:        "avatar",
            className:   cssCaretaker.machineIndicator,
            description: "The 'avatar' status indicator on a caretaker"
        },
        {
            name:        "settings",
            className:   cssCaretaker.tourSettings,
            description: "The 'settings' icon on a caretaker",
        },
        {
            name:        "calendars",
            className:   cssCaretaker.tourCalendars,
            description: "The 'calendar overview' icon on the caretaker"
        },
        {
            name:        "preview",
            className:   cssCaretaker.tourPreview,
            description: "The 'preview' icon on the caretaker"
        },
        {
            name:        "list",
            className:   cssCaretaker.tourList,
            description: "The 'list caretakers' icon on a group"
        },
        {
            name:        "add",
            className:   cssCaretaker.add,
            description: "The add button on the caretaker"
        },
        {
            name:        "addNewCaretaker",
            className:   "bottomLink0",
            description: "The 'add a new caretaker' button",
            position:    "top"
        },
        {
            name:        "addNewUser",
            className:   "bottomLink1",
            description: "The 'invite a new user' button",
            position:    "top"
        }
    ],
    caretakerAddItem:         [
        {
            name:        "title",
            className:   "title",
            description: "The title of the page"
        },
        {
            name:        "message",
            className:   cssCaretakerAddItem.tourMessage,
            description: "The 'direct message' button"
        },
        {
            name:        "appointment",
            className:   cssCaretakerAddItem.tourAppointment,
            description: "The 'appointment' button"
        },
        {
            name:        "reminder",
            className:   cssCaretakerAddItem.tourReminder,
            description: "The 'reminder' button"
        },
        {
            name:        "tvProgram",
            className:   cssCaretakerAddItem.tourTvProgram,
            description: "The 'TV program' button"
        },
        {
            name:        "birthday",
            className:   cssCaretakerAddItem.tourBirthday,
            description: "The 'birthday' button"
        }
    ],
    groupAddItem:             [
        {
            name:        "title",
            className:   "title",
            description: "The title of the page"
        },
        {
            name:        "message",
            className:   cssGroupAddItem.tourMessage,
            description: "The 'direct message' button"
        },
        {
            name:        "appointment",
            className:   cssGroupAddItem.tourAppointment,
            description: "The 'appointment' button"
        },
        {
            name:        "reminder",
            className:   cssGroupAddItem.tourReminder,
            description: "The 'reminder' button"
        },
        {
            name:        "tvProgram",
            className:   cssGroupAddItem.tourTvProgram,
            description: "The 'TV program' button"
        },
        {
            name:        "birthday",
            className:   cssGroupAddItem.tourBirthday,
            description: "The 'birthday' button"
        }
    ],
    caretakerEditAppointment: [
        {
            name:        "message",
            className:   cssCaretakerEditAppointment.tourMessage,
            description: "The 'message' input"
        },
        {
            name:        "wholeDay",
            className:   cssCaretakerEditAppointment.tourWholeDay,
            description: "The 'whole day' switch"
        },
        {
            name:        "dateStart",
            className:   cssCaretakerEditAppointment.tourDateStart,
            description: "The 'date start' input"
        },
        {
            name:        "timeStart",
            className:   cssCaretakerEditAppointment.tourTimeStart,
            description: "The 'time start' input"
        },
        {
            name:        "showAbout",
            className:   cssCaretakerEditAppointment.tourShowAbout,
            description: "The 'show starting time about' switch"
        },
        {
            name:        "dateEnd",
            className:   cssCaretakerEditAppointment.tourDateEnd,
            description: "The 'date end' input"
        },
        {
            name:        "timeEnd",
            className:   cssCaretakerEditAppointment.tourTimeEnd,
            description: "The 'time end' input"
        },
        {
            name:        "showEnd",
            className:   cssCaretakerEditAppointment.tourShowEnd,
            description: "The 'show ending time' switch"
        },
        {
            name:        "showBefore",
            className:   cssCaretakerEditAppointment.tourShowBefore,
            description: "The 'show appointment from x time before' input"
        },
        {
            name: "notifyBefore",
            className: cssCaretakerEditAppointment.tourNotifyBefore,
            description: "The 'notify about appointment from x time before' input"
        },
        {
            name:        "recurring",
            className:   cssCaretakerEditAppointment.tourRecurring,
            description: "The 'recurring' switch"
        },
        {
            name:        "recurringInterval",
            className:   cssCaretakerEditAppointment.tourRecurringInterval,
            description: "The 'recurring interval' input"
        },
        {
            name:        "recurringWeekdays",
            className:   cssCaretakerEditAppointment.tourRecurringWeekdays,
            description: "The 'recurring weekdays' input"
        }
    ],
    caretakerEditBirthday:    [
        {
            name:        "name",
            className:   cssCaretakerEditBirthday.tourName,
            description: "The 'name' input"
        },
        {
            name:        "birthday",
            className:   cssCaretakerEditBirthday.tourBirthday,
            description: "The 'birthday' input"
        },
        {
            name:        "showAge",
            className:   cssCaretakerEditBirthday.tourShowAge,
            description: "The 'show age' switch"
        },
        {
            name:        "showPhone",
            className:   cssCaretakerEditBirthday.tourShowPhone,
            description: "The 'show phone number' switch"
        },
        {
            name:        "phoneNumber",
            className:   cssCaretakerEditBirthday.tourPhoneNumber,
            description: "The 'phone number' input"
        },
        {
            name:        "showBefore",
            className:   cssCaretakerEditBirthday.tourShowBefore,
            description: "The 'show birthday from x time before' input"
        }
    ],
    caretakerEditTvProgram:   [
        {
            name:        "showName",
            className:   cssCaretakerEditTvShow.tourShowName,
            description: "The 'show name' input"
        },
        {
            name:        "channel",
            className:   cssCaretakerEditTvShow.tourChannel,
            description: "The 'channel' input"
        },
        {
            name:        "dateStart",
            className:   cssCaretakerEditTvShow.tourDateStart,
            description: "The 'date start' input"
        },
        {
            name:        "timeStart",
            className:   cssCaretakerEditTvShow.tourTimeStart,
            description: "The 'time start' input"
        },
        {
            name:        "dateEnd",
            className:   cssCaretakerEditTvShow.tourDateEnd,
            description: "The 'date end' input"
        },
        {
            name:        "timeEnd",
            className:   cssCaretakerEditTvShow.tourTimeEnd,
            description: "The 'time end' input"
        },
        {
            name:        "recurring",
            className:   cssCaretakerEditTvShow.tourRecurring,
            description: "The 'recurring' switch"
        },
        {
            name:        "recurringInterval",
            className:   cssCaretakerEditTvShow.tourRecurringInterval,
            description: "The 'recurring interval' input"
        },
        {
            name:        "recurringWeekdays",
            className:   cssCaretakerEditTvShow.tourRecurringWeekdays,
            description: "The 'recurring weekdays' input"
        }
    ],
    caretakerEditReminder:    [
        {
            name:        "message",
            className:   cssCaretakerEditReminder.tourMessage,
            description: "The 'message' input"
        },
        {
            name:        "wholeDay",
            className:   cssCaretakerEditReminder.tourWholeDay,
            description: "The 'whole day' switch"
        },
        {
            name:        "dateStart",
            className:   cssCaretakerEditReminder.tourDateStart,
            description: "The 'date start' input"
        },
        {
            name:        "timeStart",
            className:   cssCaretakerEditReminder.tourTimeStart,
            description: "The 'time start' input"
        },
        {
            name:        "dateEnd",
            className:   cssCaretakerEditReminder.tourDateEnd,
            description: "The 'date end' input"
        },
        {
            name:        "timeEnd",
            className:   cssCaretakerEditReminder.tourTimeEnd,
            description: "The 'time end' input"
        },
        {
            name:        "notification",
            className:   cssCaretakerEditReminder.tourNotification,
            description: "The 'notification' switch"
        },
        {
            name:        "recurring",
            className:   cssCaretakerEditReminder.tourRecurring,
            description: "The 'recurring' switch"
        },
        {
            name:        "recurringInterval",
            className:   cssCaretakerEditReminder.tourRecurringInterval,
            description: "The 'recurring interval' input"
        },
        {
            name:        "recurringWeekdays",
            className:   cssCaretakerEditReminder.tourRecurringWeekdays,
            description: "The 'recurring weekdays' input"
        },
        {
            name:        "overrideLimit",
            className:   cssCaretakerEditReminder.tourOverride,
            description: "The 'override limit' switch"
        }
    ],
    caretakerSettings:        [
        {
            name:        "edit",
            className:   cssCaretakerSettings.tourEdit,
            description: "The 'manage caretaker' button"
        },
        {
            name:        "screenSettings",
            className:   cssCaretakerSettings.tourSettings,
            description: "The 'screen settings' button"
        },
        {
            name:        "screenStatus",
            className:   cssCaretakerSettings.tourStatus,
            description: "The 'screen status' button"
        }
    ],
    caretakerScreenSettings:  [
        {
            name:        "greeting",
            className:   cssCaretakerScreenSettings.tourGreeting,
            description: "The 'greeting' setting"
        },
        {
            name:        "theme",
            className:   cssCaretakerScreenSettings.tourTheme,
            description: "The 'theme' setting"
        },
        {
            name:        "clockType",
            className:   cssCaretakerScreenSettings.tourClockType,
            description: "The 'clock type (digital/analog)' setting"
        },
        {
            name:        "hoursType",
            className:   cssCaretakerScreenSettings.tourHoursType,
            description: "The '24/12 hour' setting"
        },
        {
            name:        "font",
            className:   cssCaretakerScreenSettings.tourFont,
            description: "The 'font' setting"
        },
        {
            name:        "DAT",
            className:   cssCaretakerScreenSettings.tourDAT,
            description: "The 'day after tomorrow' setting"
        },
        {
            name:        "language",
            className:   cssCaretakerScreenSettings.tourLanguage,
            description: "The 'language' setting"
        },
        {
            name:        "fadeSpeed",
            className:   cssCaretakerScreenSettings.tourFadeSpeed,
            description: "The 'fade speed' setting"
        },
        {
            name:        "alertSound",
            className:   cssCaretakerScreenSettings.tourAlertSound,
            description: "The 'alert sound' setting"
        },
        {
            name:        "tomorrowDay",
            className:   cssCaretakerScreenSettings.tourTomorrowDay,
            description: "The 'show day if appointment is today' setting"
        },
        {
            name:        "itIsToday",
            className:   cssCaretakerScreenSettings.tourItIsToday,
            description: "The 'show it is today' setting"
        },
        {
            name:        "showWeather",
            className:   cssCaretakerScreenSettings.tourShowWeather,
            description: "The 'show current weather' setting"
        },
        {
            name:        "tomorrowWeather",
            className:   cssCaretakerScreenSettings.tourTomorrowWeather,
            description: "The 'show tomorrow weather' setting"
        },
        {
            name:        "publicHolidaysShow",
            className:   cssCaretakerScreenSettings.tourPublicHolidaysShow,
            description: "The 'show public holidays' setting",
            position:    "top"
        },
        {
            name:        "publicHolidaysShowBefore",
            className:   cssCaretakerScreenSettings.tourPublicHolidaysShowBefore,
            description: "The 'show public holidays from' setting",
            position:    "top"
        },
        {
            name:        "publicHolidaysCountry",
            className:   cssCaretakerScreenSettings.tourPublicHolidaysCountry,
            description: "The 'show public holidays for country' setting",
            position:    "top"
        },
        {
            name:        "save",
            className:   cssCaretakerScreenSettings.tourSave,
            description: "The 'save' button",
            position:    "top"
        },
    ],
    caretakerScreenStatus:    [
        {
            name:        "name",
            className:   cssCaretakerScreenStatus.tourName,
            description: "The 'name' setting"
        },
    ],
    groupsEdit:               [
        {
            name:        "name",
            className:   cssGroupsEdit.tourName,
            description: "The 'name' setting"
        },
        {
            name:        "caretakers",
            className:   cssGroupsEdit.tourCaretakers,
            description: "The 'manage caretakers' button"
        },
    ],
    caretakerEdit:            [
        {
            name:        "avatar",
            className:   cssCaretakerEdit.tourAvatar,
            description: "The 'avatar'"
        },
        {
            name:        "name",
            className:   cssCaretakerEdit.tourName,
            description: "The 'name' setting"
        },
        {
            name:        "birthday",
            className:   cssCaretakerEdit.tourBirthday,
            description: "The 'birthday' setting"
        },
        {
            name:        "showBirthday",
            className:   cssCaretakerEdit.tourShowBirthday,
            description: "The 'show birthday' setting"
        },
        {
            name:        "address",
            className:   cssCaretakerEdit.tourAddress,
            description: "The 'address' setting"
        },
        {
            name:        "zipCode",
            className:   cssCaretakerEdit.tourZipCode,
            description: "The 'zip code' setting"
        },
        {
            name:        "city",
            className:   cssCaretakerEdit.tourCity,
            description: "The 'city' setting"
        },
        {
            name:        "country",
            className:   cssCaretakerEdit.tourCountry,
            description: "The 'country' setting",
            position:    "top"
        },
        {
            name:        "province",
            className:   cssCaretakerEdit.tourProvince,
            description: "The 'province' setting",
            position:    "top"
        },
        {
            name:        "save",
            className:   cssCaretakerEdit.tourSave,
            description: "The 'save' button",
            position:    "top"
        },
    ],
    caretakerStatus:          [
        {
            name:        "title",
            className:   "title",
            description: "The title of the page"
        },
        {
            name:        "settings",
            className:   cssCaretakerStatus.tourSettings,
            description: "The 'avatar' status indicator of a machine"
        },
        {
            name:        "avatar",
            className:   cssCaretakerStatus.machineIndicator,
            description: "The 'avatar' status indicator of a machine"
        },
        {
            name:        "settings",
            className:   cssCaretakerStatus.title,
            description: "The 'name' of the machine",
        },
        {
            name:        "addNewSpiqle",
            className:   "bottomLink0",
            description: "The 'add a Spiqle' button",
            position:    "top"
        },
    ],
    addNewMachine:            [
        {
            name:        "title",
            className:   "title",
            description: "The title of the page"
        },
        {
            name:        "screenCode",
            className:   cssCaretakerAddNewMachine.tourScreenCode,
            description: "The 'screen code' input"
        },
        {
            name:        "screenCode",
            className:   cssCaretakerAddNewMachine.tourScreenName,
            description: "The 'screen name' input",
            position:    "top"
        },
    ],
    addCaretaker:             [
        {
            name:        "title",
            className:   "title",
            description: "The title of the page"
        },
        {
            name:        "screenCode",
            className:   cssCaretakerAddCaretaker.tourScreenCode,
            description: "The 'screen code' input"
        },
        {
            name:        "screenCode",
            className:   cssCaretakerAddCaretaker.tourScreenName,
            description: "The 'screen name' input",
            position:    "top"
        },
        {
            name:        "existing",
            className:   cssCaretakerAddCaretaker.tourExisting,
            description: "The 'new or existing' selector"
        },
        {
            name:        "avatar",
            className:   cssCaretakerAddCaretaker.tourAvatar,
            description: "The 'avatar'"
        },
        {
            name:        "name",
            className:   cssCaretakerAddCaretaker.tourName,
            description: "The 'name' setting"
        },
        {
            name:        "birthday",
            className:   cssCaretakerAddCaretaker.tourBirthday,
            description: "The 'birthday' setting"
        },
        {
            name:        "showBirthday",
            className:   cssCaretakerAddCaretaker.tourShowBirthday,
            description: "The 'show birthday' setting"
        },
        {
            name:        "address",
            className:   cssCaretakerAddCaretaker.tourAddress,
            description: "The 'address' setting"
        },
        {
            name:        "zipCode",
            className:   cssCaretakerAddCaretaker.tourZipCode,
            description: "The 'zip code' setting",
            position:    "top"
        },
        {
            name:        "city",
            className:   cssCaretakerAddCaretaker.tourCity,
            description: "The 'city' setting",
            position:    "top"
        },
        {
            name:        "country",
            className:   cssCaretakerAddCaretaker.tourCountry,
            description: "The 'country' setting",
            position:    "top"
        },
        {
            name:        "province",
            className:   cssCaretakerAddCaretaker.tourProvince,
            description: "The 'province' setting",
            position:    "top"
        },
    ],
    inviteNewUser:            [
        {
            name:        "title",
            className:   "title",
            description: "The title of the page"
        },
        {
            name:        "name",
            className:   cssCaretakerInviteNewUser.tourName,
            description: "The 'name' input"
        },
        {
            name:        "email",
            className:   cssCaretakerInviteNewUser.tourEmail,
            description: "The 'email' input"
        },
        {
            name:        "rights",
            className:   cssCaretakerInviteNewUser.tourRights,
            description: "The 'rights' selector"
        },
        {
            name:        "list",
            className:   cssCaretakerInviteNewUser.tourCaretakerList,
            description: "The list of caretakers to set rights for"
        },
    ],
    caretakerPreview:         [
        {
            name:        "preview",
            className:   cssCaretakerPreview.previewContainer,
            description: "The 'preview'"
        },
        {
            name:        "sentMessages",
            className:   cssCaretakerPreview.tourSentMessages,
            description: "The 'sent messages' button"
        },
    ],
    caretakerEditAvatar:      [
        {
            name:        "change",
            className:   cssCaretakerEditAvatar.avatarFile,
            description: "The 'choose picture' button"
        },
        {
            name:        "delete",
            className:   cssCaretakerEditAvatar.tourRemove,
            description: "The 'delete picture' button"
        },
        {
            name:        "save",
            className:   cssCaretakerEditAvatar.tourSave,
            description: "The 'save' button"
        }
    ],
    caretakerMessageSend:     [
        {
            name:        "message",
            className:   cssCaretakerSendMessage.tourMessage,
            description: "The message input"
        },
        {
            name:        "duration",
            className:   cssCaretakerSendMessage.tourDuration,
            description: "The duration input"
        },
        {
            name:        "urgent",
            className:   cssCaretakerSendMessage.tourUrgent,
            description: "The urgent input"
        }
    ],
    caretakerMessageList:     [
        {
            name:        "title",
            className:   "title",
            description: "The title of the page"
        },
        {
            name:        "user",
            className:   cssCaretakerPreviousMessages.sender,
            description: "The name of the message sender"
        },
        {
            name:        "message",
            className:   cssCaretakerPreviousMessages.messageText,
            description: "A message text"
        },
        {
            name:        "delete",
            className:   cssCaretakerPreviousMessages.messageDelete,
            description: "The 'delete message' button"
        },
        {
            name:        "sentAt",
            className:   cssCaretakerPreviousMessages.date,
            description: "The date/time a message was sent"
        }
    ],
    allItems:                 [
        {
            name:        "title",
            className:   "title",
            description: "The title of the page"
        },
        {
            name:        "item",
            className:   cssAllItems.listItem,
            description: "An item in the list"
        },
        {
            name:        "calendarLink",
            className:   "bottomLink0",
            description: "The 'show calendar' link",
            position:    "top"
        },
        {
            name:        "addItem",
            className:   cssBottomBar.addButton,
            description: "The button to add an item",
            position:    "top"
        }
    ],
    groupAllItems:            [
        {
            name:        "title",
            className:   "title",
            description: "The title of the page"
        },
        {
            name:        "item",
            className:   cssGroupsAllItems.listItem,
            description: "An item in the list"
        },
        {
            name:        "addItem",
            className:   cssBottomBar.addButton,
            description: "The button to add an item",
            position:    "top"
        }
    ],
    groupsCaretakers:         [
        {
            name:        "title",
            className:   "title",
            description: "The title of the page"
        },
        {
            name:        "list",
            className:   cssGroupsCaretakers.tourCaretakerList,
            description: "The list if the user has more than 5 caretakers"
        },
        {
            name:        "item",
            className:   cssGroupsSingleCaretakers.item,
            description: "An item if the user has less than 5 caretakers"
        }
    ],
    calendars:                [
        {
            name:        "title",
            className:   "title",
            description: "The title of the page"
        },
        {
            name:        "dayIsToday",
            className:   cssCalendars.dayIsToday,
            description: "The day that is 'today'"
        },
        {
            name:        "dayWithItems",
            className:   cssCalendars.dayHasItems,
            description: "A day that has items"
        },
        {
            name:        "allItemsLink",
            className:   "bottomLink0",
            description: "The 'show all planned items' link",
            position:    "top"
        }
    ],
    overview:                 [
        {
            name:        "title",
            className:   "title",
            description: "The title of the page"
        },
        {
            name:        "itemWholeDay",
            className:   cssOverview.wholeDayItem,
            description: "A timeline item that lasts the whole day (birthday, etc...)"
        },
        {
            name:        "itemPartDay",
            className:   cssOverview.item,
            description: "A timeline item thats lasts less than a day (appointment, reminder, etc...)"
        },
        {
            name:        "listItem",
            className:   cssOverview.listItem,
            description: "An item in the list view"
        },
        {
            name:        "listToggle",
            className:   "bottomLink0",
            description: "Toggle between list and timeline view",
            position:    "top"
        },
        {
            name:        "addItem",
            className:   cssBottomBar.addButton,
            description: "The button to add an item for this day",
            position:    "top"
        },
    ],
    account:                  [
        {
            name:        "name",
            className:   cssAccount.tourName,
            description: "The 'name' input"
        },
        {
            name:        "language",
            className:   cssAccount.tourLanguage,
            description: "The 'language' selector"
        },
        {
            name:        "changePassword",
            className:   "bottomLink0",
            description: "The 'change password' link",
            position:    "top"
        }
    ],
    accountPassword:          [
        {
            name:        "currentPassword",
            className:   cssAccountPassword.tourCurrentPassword,
            description: "The 'current password' input"
        },
        {
            name:        "newPassword",
            className:   cssAccountPassword.tourNewPassword,
            description: "The 'new password' input"
        },
        {
            name:        "repeatPassword",
            className:   cssAccountPassword.tourRepeatPassword,
            description: "The 'repeat password' input"
        },
    ],
    contact:                  [
        {
            name:        "title",
            className:   cssContact.tourTitle,
            description: "The 'title' input"
        },
        {
            name:        "body",
            className:   cssContact.tourBody,
            description: "The 'body of the question' input"
        }
    ]
};
