export default {
    distributors:   "Distributører",
    noDistributors: "Ingen distributører",

    distributorsTitle:             "Distributører",
    distributorsTableName:         "Navn",
    distributorsTableCity:         "By",
    distributorsTableCountry:      "Land",
    distributorsTablePhone:        "Telefon",
    distributorsTableEmail:        "E-mail",
    distributorsTableMachines:     "Maskiner",
    distributorsTableSent:         "Sendt",
    distributorsTableSentShort:    "V",
    distributorsTableCoupled:      "Koblet",
    distributorsTableCoupledShort: "G",
    distributorsActionsEdit:       "Redigere",
    distributorsActionsDelete:     "Fjerne",

    distributorsAddTitle:      "Tilføj distributør",
    distributorsEditTitle:     "Rediger distributør",
    distributorsEditSuccess:   "Oplysningerne om distributøren er gemt med succes",
    distributorsEditError:     "Der opstod en fejl ved gemning af distributørens oplysninger",
    distributorsRemoveTitle:   "Er du sikker på, at du vil fjerne denne distributør?",
    distributorsRemoveBody:    "Hvis du fjerner distributøren med navnet '%1', vil alle maskiner, der i øjeblikket er tilknyttet denne distributør, blive tilknyttet Spiqle. Dette kan ikke fortrydes.",
    distributorsRemoveSure:    "Fjern distributør",
    distributorsRemoveSuccess: "Distributøren er fjernet med succes",
    distributorsRemoveError:   "Der opstod en fejl ved fjernelsen af distributøren",

    distributorName:    "Navn",
    distributorAddress: "Adresse",
    distributorZipcode: "Postnummer",
    distributorCity:    "By",
    distributorState:   "Provins",
    distributorCountry: "Land",
    distributorPhone:   "Telefonnummer",
    distributorEmail:   "E-mailadresse",
};