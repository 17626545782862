import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from 'reactstrap';
import {omit} from "reactstrap/es/utils";

const omitKeys = ['defaultOpen'];

export default class UncontrolledTooltip extends Component {
    constructor(props) {
        super(props);

        this.state  = {isOpen: props.defaultOpen || false};
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggle() {
        if (this._isMounted) {
            this.setState({isOpen: !this.state.isOpen});
        }
    }

    render() {
        return <Tooltip isOpen={this.state.isOpen} toggle={this.toggle} {...omit(this.props, omitKeys)} />;
    }
}

UncontrolledTooltip.propTypes = {
    defaultOpen: PropTypes.bool,
    ...Tooltip.propTypes
};
