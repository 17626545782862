// React
import React from "react";
// Redux
import {connect} from "react-redux";
import * as actions from "../../store/actions";
// Components
import NotificationAlert from "../UI/NotificationAlert";

class NotificationReceiver extends React.Component {
    constructor(props) {
        super(props);
        this.alertRef    = React.createRef();
        this.lastMessage = null;
    }

    componentDidMount() {this.checkMessages()}

    componentDidUpdate(prevProps, prevState, snapshot) {this.checkMessages()}

    checkMessages = () => {
        if (this.props.messages && this.props.messages.length > 0) {
            this.showMessages(this.props.messages);
        }
    };

    showMessages = messages => {
        let message = messages[0];

        // Prevent messages to be shown multiple times
        if (this.lastMessage === message.id) {
            return;
        }
        this.lastMessage = message.id;
        this.alertRef.current.notificationAlert({
            place:       "tl", // isMobile() ? "tl": "tr",
            message:     <div>
                             <div>{message.message}</div>
                         </div>,
            type:        message.type,
            autoDismiss: 7
        });
        this.props.removeMessage(message.id);
    };

    render() {
        return (
            <NotificationAlert ref={this.alertRef}/>
        );
    }
}

const mapStateToProps    = state => ({
    messages: state.messages.messageList
});
const mapDispatchToProps = dispatch => ({
    removeMessage: id => dispatch(actions.messages.messageRemove(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationReceiver);
