import createSagaMiddleware from "redux-saga";
import {composeWithDevTools} from "redux-devtools-extension";
import {applyMiddleware, createStore} from "redux";
import {createBrowserHistory, createHashHistory} from 'history';
import {routerMiddleware} from "connected-react-router";
import createRootReducer from "./store/reducers";
import watchSagas from "./store/sagas";
import * as actions from "./store/actions";
import {thisIsAnApp} from "./variables/multiplatform";

// Build history
let history;
if (thisIsAnApp) {
    history = createHashHistory();
} else {
    history = createBrowserHistory();
}

const sagaMiddleware   = createSagaMiddleware();
const composeEnhancers = composeWithDevTools({trace: true, traceLimit: 25});
const middleware       = composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)));
const store            = createStore(createRootReducer(history), middleware);
sagaMiddleware.run(watchSagas);
store.dispatch(actions.auth.checkLocalStorage());

export {history, store};