export default {
    calendarTypes:        {
        appointments: "Afspraken",
        birthdays:    "Verjaardagen",
        reminders:    "Herinneringen",
        tvshows:      "TV programma's"
    },
    calendarTypeSingular: {
        appointment: "Afspraak",
        birthday:    "Verjaardag",
        reminder:    "Herinnering",
        tvshow:      "TV programma"
    },
    calendarManageTitle:  {
        appointments: "Beheer de afspraken voor %1",
        birthdays:    "Beheer de verjaardagen voor %1",
        reminders:    "Beheer de herinneringen voor %1",
        tvshows:      "Beheer de TV programma's voor %1"
    },
    calendarAddTitle:     {
        /*        appointments: "Voeg afspraak toe voor %1",
                birthdays:    "Voeg verjaardag toe voor %1",
                reminders:    "Voeg herinnering toe voor %1",
                tvshows:      "Voeg TV programma toe voor %1"*/
        appointments: "Afspraak",
        birthdays:    "Verjaardag",
        reminders:    "Herinnering",
        tvshows:      "TV programma"
    },
    calendarEditTitle:    {
        /*        appointments: "Wijzig afspraak voor %1",
                birthdays:    "Wijzig verjaardag voor %1",
                reminders:    "Wijzig herinnering voor %1",
                tvshows:      "Wijzig TV programma voor %1"*/
        appointments: "Afspraak",
        birthdays:    "Verjaardag",
        reminders:    "Herinnering",
        tvshows:      "TV programma"
    },

    calendarOverview:           "Kalenderoverzicht %1",
    calendarDay:                "%1",
    calendarAllItems:           "Bekijk alle geplande items",
    calendarLoadError:          "Er is een fout opgetreden bij het laden van de kalender",
    calendarAddItem:            "Voeg nieuw item toe",
    calendarAddItemFor:         "Voeg nieuw item toe voor %1",
    calendarDeleteItem:         "Verwijder item",
    calendarDeleteTitle:        "Weet u zeker dat u dit item wil verwijderen?",
    calendarDeleteBody:         "Weet u zeker dat u het item '%1' wil verwijderen?",
    calendarDeleteOverviewBody: "Weet u zeker dat u het item '%1' wil verwijderen? Dit zal het complete item verwijderen, niet alleen deze voor deze dag!",
    calendarDeleteSuccess:      "Item succesvol verwijderd",
    calendarDeleteError:        "Er is een fout opgetreden bij het verwijderen van het item",
    calendarDefaultSettings:    "Standaard instellingen",

    calendarDayOverview: "Overzicht",
    calendarDayTimeline: "Tijdlijn",

    calendarSaveSuccess: {
        appointments: "Afspraak succesvol opgeslagen",
        birthdays:    "Verjaardag succesvol opgeslagen",
        reminders:    "Herinnering succesvol opgeslagen",
        tvshows:      "TV programma succesvol opgeslagen"
    },
    calendarSaveFail:    {
        appointments: "Afspraak kan niet opgeslagen worden",
        birthdays:    "Verjaardag kan niet opgeslagen worden",
        reminders:    "Herinnering kan niet opgeslagen worden",
        tvshows:      "TV programma kan niet opgeslagen worden"
    },

    calendarEditFormTitle:                 "Bericht",
    calendarEditFormShowName:              "Titel programma",
    calendarEditFormChannel:               "Kanaal",
    calendarEditWholeDay:                  "De hele dag",
    calendarEditWholeDayYes:               "Duurt de hele dag",
    calendarEditWholeDayNo:                "Duurt niet de hele dag",
    calendarEditFormDate:                  "Datum",
    calendarEditFormStart:                 "Van",
    calendarEditFormEnd:                   "Tot",
    calendarEditFormEndDate:               "Tot en met",
    calendarEditFormShowBefore:            "Toon afspraak",
    calendarEditFormNotifyBefore:          "Geef signaal vooraf",
    calendarEditFormRecurrence:            "Herhalen",
    calendarEditFormRecurrenceDetailed:    "Herhaal dit item",
    calendarEditFormInterval:              "Herhaal",
    calendarEditFormIntervalEndDate:       "Eind datum van de herhaling",
    calendarEditFormIntervalHasEndDate:    "Heeft eind datum",
    calendarEditFormIntervalHasEndDateYes: "Stop herhaling op bepaalde datum",
    calendarEditFormIntervalHasEndDateNo:  "Herhaling heeft geen eind datum",
    calendarEditFormDaysOfWeek:            "Dagen van de week",
    calendarEditFormDontShowEnd:           "Eind tijd niet tonen",
    calendarEditFormDontShowEndYes:        "Toon eindtijd",
    calendarEditFormDontShowEndNo:         "Verberg eindtijd",
    calendarEditFormShowAbout:             "Toon ongeveer",
    calendarEditFormShowAboutYes:          "Toon 'ongeveer'",
    calendarEditFormShowAboutNo:           "Toon geen 'ongeveer'",
    calendarEditFormNotification:          "Notificatie",
    calendarEditFormNotificationYes:       "Geluidssignaal en knipperen actief",
    calendarEditFormNotificationNo:        "Geen notificatie (geluid)",
    calendarEditFormOverrideLimit:         "Herinnering beperken",
    calendarEditFormOverrideLimitNo:       "Herinnering beperken tot 3 tegelijkertijd",
    calendarEditFormOverrideLimitYes:      "Herinnering altijd tonen",

    calendarEditBirthdayName:                "Naam",
    calendarEditBirthdayIsCaretaker:         "Zorgvrager zelf",
    calendarEditBirthdayIsCaretakerExtended: "Dit is de zorgvrager zelf",
    calendarEditBirthdayIsCaretakerDisabled: "Een andere verjaardag is al gemarkeerd als de zorgvrager zelf",
    calendarEditBirthdayBirthday:            "Geboortedatum",
    calendarEditBirthdayShowAge:             "Leeftijd",
    calendarEditBirthdayShowAgeYes:          "Toon leeftijd",
    calendarEditBirthdayShowAgeNo:           "Toon geen leeftijd",
    calendarEditBirthdayShowPhone:           "Toon telefoonnummer",
    calendarEditBirthdayShowPhoneYes:        "Toon telefoonnummer",
    calendarEditBirthdayShowPhoneNo:         "Toon geen telefoonnummer",
    calendarEditBirthdayPhoneNumber:         "Telefoonnummer",
    calendarEditBirthdayShowBefore:          "Toon verjaardag",

    calendarDefaultSettingsTitle:           "Standaard instellingen voor nieuwe items",
    calendarDefaultSettingsForAppointments: "Standaard instellingen voor nieuwe afspraken",
    calendarDefaultSettingsForReminders:    "Standaard instellingen voor nieuwe herinneringen",
    calendarDefaultSettingsForBirthdays:    "Standaard instellingen voor nieuwe verjaardagen",
    calendarDefaultSettingsShowAbout:       "Toon 'ongeveer'",
    calendarDefaultSettingsShowAboutYes:    "Toon 'ongeveer' bij afspraken",
    calendarDefaultSettingsShowAboutNo:     "Toon geen 'ongeveer' bij afspraken",
    calendarDefaultSettingsDontShowEnd:     "Eind tijd niet tonen",
    calendarDefaultSettingsDontShowEndYes:  "Toon eindtijd",
    calendarDefaultSettingsDontShowEndNo:   "Verberg eindtijd",

    calendarNextTime:             "Volgende keer",
    calendarSkipNextTime:         "Volgende keer overslaan",
    calendarSkipNextTimeMessage:  "Sla de volgende keer over. Dit zorgt ervoor dat de afspraak de volgende keer niet op het schermen wordt getoond.",
    calendarSkipSuccess:          "De afspraak wordt 1x overgeslagen",
    calendarSkipFail:             "De afspraak kan niet worden overgeslagen",
    calendarResetSkip:            "Afspraak niet meer overslaan",
    calendarResetSkipMessage:     "Sla de volgende keer niet meer over. Dit zorgt ervoor dat de afspraak weer op de normale dag getoond wordt en niet meer wordt overgeslagen.",
    calendarResetSkipSuccess:     "De afspraak wordt weer getoond zoals origineel geplanned",
    calendarResetSkipFail:        "De afspraak kan niet worden hersteld",
    calendarDifferentDaysTitle:   "Verschillende dagen",
    calendarDifferentDaysWarning: "De afspraak start en eindigt op verschillende dagen. Dit is niet nodig voor herhalende afspraken. Weet u zeker dat u dit wilt?",
    calendarStartTimeChanged:     "Let op: de start tijd is aangepast omdat deze voor de eind tijd lag.",

    calendarOnChannel: " op ",
    calendarDate:      "Datum",
    calendarTime:      "Tijd",
    calendarWholeDay:  "Hele dag",

    calendarRecurrences: {
        options:            {
            "none":     "Nee",
            "interval": "Periodiek (iedere x dagen)",
            "weekdays": "Dagen van de week"
        },
        days:               {
            1: "1 dag",
            2: "2 dagen",
            3: "3 dagen",
            4: "4 dagen",
            5: "5 dagen",
            6: "6 dagen"
        },
        weeks:              {
            1: "1 week",
            2: "2 weken",
            3: "3 weken",
            4: "4 weken",
            6: "6 weken",
            8: "8 weken"
        },
        months:             {
            1: "1 maand",
            2: "2 maanden",
            3: "3 maanden",
            4: "4 maanden",
            5: "5 maanden",
            6: "6 maanden",
            9: "9 maanden"
        },
        years:              {
            1:  "1 jaar",
            2:  "2 jaar",
            3:  "3 jaar",
            4:  "4 jaar",
            5:  "5 jaar",
            10: "10 jaar"
        },
        every:              {
            day:           "Iedere dag",
            week:          "Iedere week",
            month:         "Iedere maand",
            year:          "Ieder jaar",
            multipleDay:   "Iedere %1 dagen",
            multipleWeek:  "Iedere %1 weken",
            multipleMonth: "Iedere %1 maanden",
            multipleYear:  "Iedere %1 jaar",
            weekday:       "Iedere"
        },
        at:                 {
            time:     " om ",
            day:      " op ",
            dayMonth: " op de ",
            starting: " vanaf "
        },
        showBefore:         {
            "15 minutes": "15 minuten vooraf",
            "30 minutes": "30 minuten vooraf",
            "1 hour":     "1 uur vooraf",
            "2 hours":    "2 uur vooraf",
            "3 hours":    "3 uur vooraf",
            "6 hours":    "6 uur vooraf",
            "12 hours":   "12 uur vooraf",
            "1 day":      "1 dag vooraf",
            "2 days":     "2 dagen vooraf",
            "3 days":     "3 dagen vooraf",
            "4 days":     "4 dagen vooraf",
            "5 days":     "5 dagen vooraf",
            "6 days":     "6 dagen vooraf",
            "1 week":     "1 week vooraf"
        },
        notifyBefore:       {
            "none":       "Geen notificatie vooraf",
            "15 minutes": "15 minuten vooraf",
            "30 minutes": "30 minuten vooraf",
            "45 minutes": "45 minuten vooraf",
            "1 hour":     "1 uur vooraf",
            "2 hours":    "2 uur vooraf",
        },
        showBirthdayBefore: {
            "15 minutes": "Op de dag zelf",
            "1 day":      "1 dag vooraf",
            "2 days":     "2 dagen vooraf",
            "3 days":     "3 dagen vooraf",
            "4 days":     "4 dagen vooraf",
            "5 days":     "5 dagen vooraf",
            "6 days":     "6 dagen vooraf"
        },
        interval:           {
            "1 day":    "Iedere dag",
            "2 days":   "Iedere 2 dagen",
            "3 days":   "Iedere 3 dagen",
            "4 days":   "Iedere 4 dagen",
            "5 days":   "Iedere 5 dagen",
            "6 days":   "Iedere 6 dagen",
            "1 week":   "Iedere week",
            "2 weeks":  "Iedere 2 weken",
            "3 weeks":  "Iedere 3 weken",
            "4 weeks":  "Iedere 4 weken",
            "6 weeks":  "Iedere 6 weken",
            "8 weeks":  "Iedere 8 weken",
            "1 month":  "Iedere maand",
            "2 months": "Iedere 2 maanden",
            "3 months": "Iedere 3 maanden",
            "4 months": "Iedere 4 maanden",
            "5 months": "Iedere 5 maanden",
            "6 months": "Iedere 6 maanden",
            "9 months": "Iedere 9 maanden",
            "1 year":   "Iedere jaar",
            "2 years":  "Iedere 2 jaar",
            "3 years":  "Iedere 3 jaar",
            "4 years":  "Iedere 4 jaar",
            "5 years":  "Iedere 5 jaar",
            "10 years": "Iedere 10 jaar"
        },
        noReoccurance:      "Niet herhalend"
    }
};