export default {
    groups:   "Groepen",
    group:    "Groep",
    noGroups: "Geen groepen",

    groupsTitle:               "Groepen",
    groupsTableName:           "Naam",
    groupsTableCaretakers:     "Z#",
    groupsTableCaretakersLong: "Zorgvragers",
    groupsActionsEdit:         "Bewerken",
    groupsActionsDelete:       "Verwijderen",

    groupsCaretakersTitle:        "Beheer zorgvragers voor %1",
    groupsCaretakersDelete:       "Verwijder zorgvrager uit groep",
    groupsCaretakersAdd:          "Voeg zorgvragers toe aan groep",
    groupsCaretakersAddTitle:     "Voeg zorgvrager toe aan groep %1",
    groupsCaretakersNoCaretakers: "Nog geen zorgvragers",

    groupsAddCaretakersSuccess:   "Zorgvragers succesvol toegevoegd aan de groep",
    groupsAddCaretakersError:     "Er is een fout opgetreden bij het toevoegen van de zorgvragers aan de groep",
    groupsRemoveCaretakerSuccess: "Zorgvrager succesvol verwijderd uit de groep",
    groupsRemoveCaretakerError:   "Er is een fout opgetreden bij het verwijderen van de zorgvrager uit de groep",

    groupsAddTitle:      "Groep toevoegen",
    groupsEditTitle:     "Groep bewerken",
    groupsEditSuccess:   "De gegevens van de groep zijn succesvol opgeslagen",
    groupsEditError:     "Er is een fout opgetreden bij het opslaan van de gegevens van de groep",
    groupsRemoveTitle:   "Weet u zeker dat u deze groep wil verwijderen?",
    groupsRemoveBody:    "Als u de groep met de naam '%1' verwijdert, worden alle rechten van deze groep verwijderd. Dit is niet ongedaan te maken.",
    groupsRemoveSure:    "Groep verwijderen",
    groupsRemoveSuccess: "De groep is succesvol verwijderd",
    groupsRemoveError:   "Er is een fout opgetreden bij het verwijderen van de groep",

    groupsCalendarsNoItems: "Er zijn nog geen agenda items voor deze groep",

    groupsMessage:              "Direct bericht",
    groupsMessageTo:            "Direct bericht naar %1",
    groupsMessageSentTo:        "Stuur een bericht naar %1",
    groupsMessageMessage:       "Bericht",
    groupsMessageDuration:      "Zichtbaar voor:",
    groupsMessageDurations:     {
        300:   "5 minuten",
        900:   "15 minuten",
        1800:  "30 minuten",
        3600:  "1 uur",
        10800: "3 uur",
        21600: "6 uur",
        43200: "12 uur",
        86400: "24 uur"
    },
    groupsMessageUrgent:        "Urgent bericht",
    groupsMessageSuccess:       "Direct bericht succesvol verzonden",
    groupsMessageError:         "Er is een fout opgetreden bij het verzenden van het direct bericht",
    groupsMessageCancelSuccess: "Direct bericht succesvol verwijderd van de schermen",
    groupsMessageCancelError:   "Er is een fout opgetreden bij het verwijderen van het direct bericht",
    groupsMessageNoMachines:    "Deze groep heeft nog geen schermen",

    groupsSentMessages:          "Berichtenoverzicht",
    groupsSentMessagesMessage:   "Bericht",
    groupsSentMessagesSentLong:  "Verzonden",
    groupsSentMessagesSentShort: "Verz.",
    groupsSentMessagesSentBy:    "Verzonden door",
    groupsSentMessagesDelivered: "Getoond op scherm",
    groupsSentMessagesCancelled: "Bericht geannuleerd",
    groupsNoSentMessages:        "Nog geen berichten verstuurd",

    groupsSettings:   "Instellingen",
    groupsDetails:    "Gegevens %1",
    groupsCaretakers: "Beheer zorgvragers",
    groupsCaretakersError: "Er zijn geen zorgvragers geselecteerd",

    groupsManageSuccess: "De naam van de groep is succesvol opgeslagen",
    groupsManageFail: "Er is een fout opgetreden bij het opslaan van de groeps naam",
    groupsManageCaretakersSuccess: "De zorgvragers voor de groep zijn opgeslagen",
    groupsManageCaretakersFail: "De zorgvragers voor de groep konden niet opgeslagen worden",

    groupName: "Naam",
    groupList: "Zorgvragers in deze groep"
};