import {call, put, select, takeLatest} from "redux-saga/effects";
import {push} from "connected-react-router";
import * as actions from "../actions";
import * as actionTypes from "../actionTypes";
import * as api from "../../api";
import i18n from "../../i18n";

// Watcher
export function* watchDistributors() {
    yield takeLatest(actionTypes.DISTRIBUTORS_LOAD, loadSaga);
    yield takeLatest(actionTypes.DISTRIBUTORS_LOAD_IF_NEEDED, loadIfNeededSaga);
    yield takeLatest(actionTypes.DISTRIBUTORS_SAVE, saveSaga);
    yield takeLatest(actionTypes.DISTRIBUTORS_REMOVE, removeSaga);
}

// Sagas
function* loadSaga(action) {
    try {
        const response = yield call(api.distributors.load);
        yield put(actions.distributors.loadSuccess(response.data.distributors));
    }
    catch (err) {
        yield put(actions.distributors.loadFail());
    }
}

function* loadIfNeededSaga(action) {
    let distributors = yield select(state => state.distributors);
    if(!distributors.loading && !distributors.distributorsLoaded) {
        yield put(actions.distributors.load());
    }
}

function* saveSaga(action) {
    try {
        yield call(api.distributors.save, action.uuid, action.distributorDetails);
        yield put(actions.distributors.saveSuccess());
        yield put(actions.distributors.load());
        yield put(push("/admin/distributors"));
        yield put(actions.messages.successPush(i18n.distributorsEditSuccess));
    }
    catch (err) {
        yield put(actions.distributors.saveFail());
        yield put(actions.messages.errorPush(i18n.distributorsEditError));
    }
}

function* removeSaga(action) {
    try {
        yield call(api.distributors.remove, action.uuid);
        yield put(actions.distributors.removeSuccess());
        yield put(actions.distributors.load());
        yield put(push("/admin/distributors"));
        yield put(actions.messages.successPush(i18n.distributorsRemoveSuccess));
    }
    catch (err) {
        yield put(actions.distributors.removeFail());
        yield put(actions.messages.errorPush(i18n.distributorsRemoveError));
    }
}