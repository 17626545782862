export default {
    affiliates:   "Partner",
    noAffiliates: "Keine Partner",

    affiliatesTitle:             "Partner",
    affiliatesTableName:         "Name",
    affiliatesTableCity:         "Wohnort",
    affiliatesTableCountry:      "Land",
    affiliatesTablePhone:        "Telefon",
    affiliatesTableEmail:        "E-Mail",
    affiliatesTableMachines:     "Maschinen",
    affiliatesTableSent:         "Gesendet",
    affiliatesTableSentShort:    "G",
    affiliatesTableCoupled:      "Verknüpft",
    affiliatesTableCoupledShort: "V",
    affiliatesActionsEdit:       "Bearbeiten",
    affiliatesActionsDelete:     "Löschen",
    affiliatesLoadError:         "Beim Laden der Partner ist ein Fehler aufgetreten",

    affiliatesAddTitle:      "Partner hinzufügen",
    affiliatesEditTitle:     "Partner bearbeiten",
    affiliatesEditSuccess:   "Die Details des Partners wurden erfolgreich gespeichert",
    affiliatesEditError:     "Beim Speichern der Daten des Partners ist ein Fehler aufgetreten",
    affiliatesRemoveTitle:   "Möchten Sie diesen Partner wirklich löschen?",
    affiliatesRemoveBody:    "Wenn Sie den Partner mit dem Namen '% 1' löschen, werden alle Computer, die derzeit mit diesem Partner verbunden sind, mit Spiqle verknüpft. Dies kann nicht rückgängig gemacht werden.",
    affiliatesRemoveSure:    "Partner Löschen",
    affiliatesRemoveSuccess: "Der Partner wurde erfolgreich gelöscht",
    affiliatesRemoveError:   "Beim Löschen des Partners ist ein Fehler aufgetreten",

    affiliateName:    "Name",
    affiliateAddress: "Adresse",
    affiliateZipcode: "Postleitzahl",
    affiliateCity:    "WohnOrt",
    affiliateState:   "Provinz",
    affiliateCountry: "Land",
    affiliatePhone:   "Telefonnummer",
    affiliateEmail:   "E-mail",
};