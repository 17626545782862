export default {
    distributors:   "Distributoren",
    noDistributors: "keine Distributoren",

    distributorsTitle:             "Distributoren",
    distributorsTableName:         "Name",
    distributorsTableCity:         "Ort",
    distributorsTableCountry:      "Land",
    distributorsTablePhone:        "Telefon",
    distributorsTableEmail:        "E-Mail",
    distributorsTableMachines:     "Maschinen",
    distributorsTableSent:         "Gesendet",
    distributorsTableSentShort:    "G",
    distributorsTableCoupled:      "Verknüpft",
    distributorsTableCoupledShort: "V",
    distributorsActionsEdit:       "Bearbeiten",
    distributorsActionsDelete:     "Löschen",

    distributorsAddTitle:      "Distributor hinzufügen",
    distributorsEditTitle:     "Distributor bearbeiten",
    distributorsEditSuccess:   "Die Distributorinformationen wurden erfolgreich gespeichert",
    distributorsEditError:     "Beim Speichern der Distributorinformationen ist ein Fehler aufgetreten",
    distributorsRemoveTitle:   "Möchten Sie diesen Distributor wirklich löschen?",
    distributorsRemoveBody:    "Wenn Sie den Distributor mit dem Namen '% 1' löschen, werden alle Computer, die derzeit mit diesem Distributor verbunden sind, mit Spiqle verknüpft. Dies kann nicht rückgängig gemacht werden.",
    distributorsRemoveSure:    "Distributor Löschen",
    distributorsRemoveSuccess: "Der Distributor wurde erfolgreich gelöscht",
    distributorsRemoveError:   "Beim Löschen des Distributors ist ein Fehler aufgetreten",

    distributorName:    "Name",
    distributorAddress: "Adresse",
    distributorZipcode: "Postleitzahl",
    distributorCity:    "Ort",
    distributorState:   "Provinz",
    distributorCountry: "Land",
    distributorPhone:   "Telefonnummer",
    distributorEmail:   "E-Mail"
};