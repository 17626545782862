import React from 'react';
import css from "./Overview.module.css";
import {useHistory, useParams} from "react-router-dom";

const Item = props => {
    const params      = useParams();
    const history     = useHistory();
    let title         = "";
    let link          = "";
    let selectedClass = "";
    let isBeforeNext  = false;
    const styles      = {
        top:    props.top + "px",
        height: props.height + "px",
        width:  (100 / props.overlap) + "%",
        left:   ((100 / props.overlap) * props.overlapIndex) + "%"
    };
    switch (props.itemType) {
        case "appointment":
            selectedClass = css.appointment;
            title         = props.message;
            link          = "/caretakers/calendar/appointments/" + params.caretaker + "/edit/" + props.uuid;
            isBeforeNext  = props.currentDate.startMoment.isBefore(props.nextTime);
            break;
        case "reminder":
            selectedClass = css.reminder;
            title         = props.message;
            link          = "/caretakers/calendar/reminders/" + params.caretaker + "/edit/" + props.uuid;
            isBeforeNext  = props.currentDate.startMoment.isBefore(props.nextTime);
            break;
        case "tvshow":
            selectedClass = css.tvshow;
            title         = props.showName;
            link          = "/caretakers/calendar/tvshows/" + params.caretaker + "/edit/" + props.uuid;
            isBeforeNext  = props.currentDate.startMoment.isBefore(props.nextTime);
            break;
        default:
    }

    return (
        <div className={css.item + " " + (isBeforeNext ? css.isBeforeNow : "")} style={styles}>
            <div className={css.itemContent + " " + selectedClass} onClick={() => history.push(link)}>
            <span className={css.itemName}>
                {title}
            </span>
            </div>
        </div>
    );
};

export default Item;