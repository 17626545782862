import React, {useState} from 'react';
import withGroups from "../../../hoc/withGroups";
import css from "./AddItem.module.css";
import {BottomBar, Tour} from "../../../components";
import {TitleWrapper} from "../../../contexts/TitleProvider";
import i18n from "../../../i18n";
import {getPageHelpSteps} from "../../../help/tools";

const AddItemButton = props => {
    return (
        <div className={css.button + " " + props.className} onClick={props.onClick}>{props.text}</div>
    );
}

const GroupAddItem = props => {
    const [helpOpen, setHelpOpen] = useState(false);
    const doHelp                  = () => setHelpOpen(true);
    const closeHelp               = () => setHelpOpen(false);

    return (
        <TitleWrapper title={props.group ? props.group.name : i18n.groupsItemAdd}>
            <Tour
                isOpen={helpOpen}
                onRequestClose={closeHelp}
                steps={getPageHelpSteps("groupAddItem")}
            />
            <div>
                <div className="content-list">
                    <AddItemButton
                        className={css.tourMessage}
                        text={i18n.caretakersMessage}
                        onClick={() => props.history.push('/groups/message/send/' + props.groupUuid)}
                    />
                    <AddItemButton
                        className={css.tourAppointment}
                        text={i18n.calendarTypeSingular.appointment}
                        onClick={() => props.history.push('/groups/calendar/appointments/' + props.groupUuid + '/edit/new')}
                    />
                    <AddItemButton
                        className={css.tourReminder}
                        text={i18n.calendarTypeSingular.reminder}
                        onClick={() => props.history.push('/groups/calendar/reminders/' + props.groupUuid + '/edit/new')}
                    />
                    <AddItemButton
                        className={css.tourTvProgram}
                        text={i18n.calendarTypeSingular.tvshow}
                        onClick={() => props.history.push('/groups/calendar/tvshows/' + props.groupUuid + '/edit/new')}
                    />
                    <AddItemButton
                        className={css.tourBirthday}
                        text={i18n.calendarTypeSingular.birthday}
                        onClick={() => props.history.push('/groups/calendar/birthdays/' + props.groupUuid + '/edit/new')}
                    />
                    <BottomBar backButton onHelp={doHelp} onCloseHelp={closeHelp} helpOpen={helpOpen}/>
                </div>
            </div>
        </TitleWrapper>
    );
}


export default withGroups(GroupAddItem);