import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {connect} from "react-redux";
import * as actions from "../../../../store/actions";

import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Button, ColumnSelector, Spinner, ReactTable} from "../../../../components";

import i18n from "../../../../i18n";
import {apiGetCall} from "../../../../api";
import tableColumns from "../../../../variables/tableColumns";

class Swapper extends Component {
    state = {
        machines:        [],
        columns:         [],
        selectedMachine: null,
        loading:         true
    };

    tableColumns = tableColumns.machineColumnsDefaultCaretaker();

    constructor(props) {
        super(props);
        this.state.columns = this.tableColumns;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isOpen && !prevProps.isOpen) {
            this.setState({loading: true, selectedMachine: null});
            apiGetCall("/machines/getmachinesforcoupling", {ignoreMac: this.props.mac})
                .then(res => {
                    this.setState({loading: false, machines: res.data.machines});
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }

    handleSwap = () => {
        let redirect = "/admin/machines";
        if (this.props.redirect) {
            redirect = this.props.redirect;
        }
        this.props.swap(this.props.mac, this.state.selectedMachine, redirect);
        this.props.close();
    };

    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.close} size="xl">
                <ModalHeader className="justify-content-center uppercase title" tag="h4">{i18n.machineSwapTitle}</ModalHeader>
                <ModalBody>
                    <p>{i18n.machineSwapBody.replace('%1', this.props.mac)}</p>
                    <ColumnSelector cookieId="adminMachineSwapper" columns={this.state.columns} changed={columns => this.setState({columns})}/>
                    <ReactTable
                        filterable
                        defaultPageSize={5}
                        indexKey="id"
                        data={this.state.machines}
                        columns={this.state.columns}
                        noDataText={i18n.noMachines}
                        defaultSorted={[{id: "serial", desc: false}]}
                        defaultFiltered={[{id: "coupled", value: "No"}]}
                        getTrProps={(state, rowInfo) => {
                            if (rowInfo && rowInfo.row) {
                                let rowProps = {
                                    onClick: () => this.setState({selectedMachine: rowInfo.original.mac_address})
                                };
                                if (rowInfo.original.mac_address === this.state.selectedMachine) {
                                    rowProps.style = {
                                        background: rowInfo.original.mac_address === this.state.selectedMachine ? '#2CA8FF' : 'white',
                                        color:      rowInfo.original.mac_address === this.state.selectedMachine ? 'white' : 'black'
                                    }
                                }
                                return rowProps;
                            }
                            else {
                                return {}
                            }
                        }}
                    />
                    {this.state.loading && <Spinner/>}
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" disabled={this.state.selectedMachine === null} onClick={this.handleSwap}>{i18n.machineSwapSure}</Button>
                    <Button color="info" onClick={this.props.close}>{i18n.buttonCancel}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

Swapper.propTypes = {
    isOpen:            PropTypes.bool,
    close:             PropTypes.func.isRequired,
    mac:               PropTypes.string.isRequired,
    redirect:          PropTypes.string
};

const mapDispatchToProps = dispatch => ({
    swap:                        (macAddressFrom, macAddressTo, redirect) => dispatch(actions.machines.swap(macAddressFrom, macAddressTo, redirect))
});

export default connect(null, mapDispatchToProps)(Swapper);