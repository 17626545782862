export default {
    user:    "Benutzer",
    users:   "Benutzer",
    noUsers: "Keine Benutzer",

    usersTitle:        "Benutzer",
    usersTableName:    "Name",
    usersTableAddress: "Adresse",
    usersTableZipcode: "Postleitzahl",
    usersTableCity:    "Ort",
    usersTableState:   "Provinz",
    usersTableCountry: "Land",
    usersTablePhone:   "Telefon",
    usersTableEmail:   "E-mail",
    usersTableLogin:   "Letzter Login",

    usersTableAdmin:            "Super admin",
    usersTableDisabled:         "Deaktiviert",
    usersTableEmailConfirmed:   "E-Mail-Adresse bestätigt",
    usersTableTwoFactorEnabled: "2-Faktor eingeschaltet",
    usersActionsTitle:          "Aktionen für diese Benutzer",
    usersActionsInfo:           "Benutzerinfo",
    usersActionsDelete:         "Löschen",

    usersActionsDeleteButton:       "Benutzer löschen",
    usersActionsDuplicateButton:    "Benutzer duplizieren",
    usersActionsCopyRightsButton:   "Kopieren Sie Benutzerrechte auf einen anderen Benutzer",
    usersActionsViewActivityButton: "Benutzeraktivitätsprotokoll anzeigen",
    usersActionsLogInAsButton:      "Melden Sie sich als dieser Benutzer an",
    usersActionsDisableTwoFactor:   "Deaktivieren Sie die 2-Faktor-Authentifizierung",

    usersAddTitle:    "Benutzer hinzufügen",
    usersEditTitle:   "Benutzer bearbeiten",
    usersAddSuccess:  "Das neue Konto wurde erstellt, der Benutzer hat ein temporäres Passwort per E-Mail erhalten.",
    usersEditSuccess: "Die Daten des Benutzers wurden erfolgreich gespeichert",
    usersEditError:   "Beim Speichern der Benutzerdaten ist ein Fehler aufgetreten",

    usersLogInAsTitle: "Möchten Sie den Benutzer wirklich wechseln??",
    usersLogInAsBody:  "Möchten Sie sich wirklich als '%1' anmelden?? Um zu Ihrem eigenen Benutzer zurückzukehren, müssen Sie sich abmelden und erneut anmelden.",
    usersLogInAsSure:  "Melden Sie sich als dieser Benutzer an",
    usersLogInAsFail:  "Beim Anmelden als dieser Benutzer ist ein Fehler aufgetreten",

    usersDuplicateTitle:   "Benutzer duplizieren",
    usersDuplicateBody:    "Erstelle einen neuen Benutzer mit den gleichen Rechten wie '%1'.",
    usersDuplicateSure:    "Benutzer duplizieren",
    usersDuplicateSuccess: "Der Benutzer wurde erfolgreich dupliziert. Der Benutzer hat ein temporäres Passwort per E-Mail erhalten.",
    usersDuplicateError:   "Der Benutzer konnte nicht dupliziert werden.",

    usersCopyTitle:           "Kopieren Sie Benutzerrechte auf einen anderen Benutzer",
    usersCopyBody:            "Kopieren Sie die Rechte von '%1' auf einen anderen Benutzer.",
    usersCopyToUser:          "An den Benutzer",
    usersCopyReplace:         "Rechte ersetzten",
    usersCopyReplaceExpanded: "Ersetzen Sie die Rechte, anstatt sie hinzuzufügen",
    usersCopySure:            "Kopiere Rechte",
    usersCopySuccess:         "Die Benutzerrechte wurden erfolgreich kopiert.",
    usersCopyError:           "Die Rechte des Benutzers konnten nicht kopiert werden.",

    usersTwoFactorDisableTitle:   "Möchten Sie die 2-Faktor-Authentifizierung dieses Benutzers wirklich deaktivieren?",
    usersTwoFactorDisableBody:    "'%1' kann sich dann ohne 2-Faktor-Authentifizierung anmelden und wieder einschalten.",
    usersTwoFactorDisableSure:    "Deaktivieren Sie die 2-Faktor-Authentifizierung",
    usersTwoFactorDisableSuccess: "Die 2-Faktor-Authentifizierung für den Benutzer wurde erfolgreich deaktiviert.",
    usersTwoFactorDisableError:   "Die 2-Faktor-Authentifizierung für den Benutzer konnte nicht deaktiviert werden.",

    usersRemoveTitle:   "Möchten Sie diesen Benutzer wirklich löschen??",
    usersRemoveBody:    "Wenn Sie den Benutzer '%1' löschen, werden alle Computer, die diesem Benutzer derzeit zugeordnet sind, Spiqle zugeordnet. Dies kann nicht rückgängig gemacht werden.",
    usersRemoveSure:    "Benutzer löschen",
    usersRemoveSuccess: "Der Benutzer wurde erfolgreich gelöscht",
    usersRemoveError:   "Beim Löschen des Benutzers ist ein Fehler aufgetreten",

    usersInfoTitle:     "Benutzerinfo",
    usersInfoLoadError: "Fehler beim Laden der Benutzerinformationen",
    usersInfoDataHead:  "Benutzerdaten für %1",

    usersRightsHead:          "Benutzerrechte",
    usersRightsTableType:     "Art der Rechte",
    usersRightsTableName:     "Name",
    usersRightsTableDelete:   "Rechte entfernen",
    usersRightsAddRightTitle: "Fügen Sie dem Benutzer Rechte hinzu",
    usersRightsAddRight:      "Füge Rechte hinzu",
    usersRightsCalendar:      "Kalenderrechte",
    usersRightsSettings:      "Bildschirmeinstellungsrechte",
    usersRightsMessages:      "Nachrichtenrechte",
    usersRightsDetails:       "Datenrechte für Pflegeempfänger",
    usersRightsPreview:       "Beispielrechte",
    usersRightsTechnical:     "Technische Rechte",
    usersRightsInvite:        "Einladungsrechte",
    usersRightsPayments:      "Zahlungsrechte",
    usersRightsNoRights:      "Dieser Benutzer hat noch keine Rechte",
    usersRightsHasSuperAdmin: "Dieser Benutzer ist Superadministrator",

    usersRightsPresetNoRights:  "Voreingestellt keine Rechte",
    usersRightsPresetCaretaker: "Voreinstellung für Pflegeempfänger",
    usersRightsPresetAdmin:     "Voreinstellung für den Administrator",

    usersRightsCalendarRight:  [
        "Keine Kalenderrechte",
        "Kalender anzeigen",
        "Kalender bearbeiten"
    ],
    usersRightsSettingsRight:  [
        "Keine Bildschirmeinstellungsrechte",
        "Bildschirmeinstellungen anzeigen",
        "Bildschirmeinstellungen bearbeiten"
    ],
    usersRightsMessagesRight:  [
        "Keine Nachrichtenrechte",
        "Sende direkte Nachrichten",
        "Nachrichten senden und Verlauf anzeigen"
    ],
    usersRightsDetailsRight:   [
        "Keine Datenrechte des Pflegeempfängers",
        "Zeigen Sie die Pflegeempfängerdaten an",
        "Pflegeempfängerdaten bearbeiten"
    ],
    usersRightsPreviewRight:   [
        "Keine Vorschau-Rechte",
        "Bildschirmvorschau anzeigen"
    ],
    usersRightsTechnicalRight: [
        "Keine technischen Rechte",
        "Technische Maschinendaten anzeigen",
        "Technische Administratorrechte"
    ],
    usersRightsInviteRight:    [
        "Keine Einladungsrechte",
        "Rechte zum Einladen neuer Benutzer"
    ],
    usersRightsPaymentsRight:  [
        "Keine Zahlungsrechte",
        "Rechte zum Verwalten von Zahlungen"
    ],

    usersRightsTypeCaretaker:   "Pflegeempfänger",
    usersRightsTypeGroup:       "Gruppe",
    usersRightsTypeDistributor: "Distributor",
    usersRightsTypeAffiliate:   "Partner",
    usersRightsTypeMapping:     {
        caretaker:   "Pflegeempfänger",
        group:       "Gruppe",
        distributor: "Distributor",
        affiliate:   "Partner"
    },

    userName:    "Name",
    userAddress: "Adresse",
    userZipcode: "Postleitzahl",
    userCity:    "Ort",
    userState:   "Provinz",
    userCountry: "Land",
    userPhone:   "Telefonnummer",
    userEmail:   "E-mail",
};