// React
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

// Tools
import i18n from "../../../i18n";
import * as actions from "../../../store/actions";

// Components
import {Form, Button, Spinner, BottomBar} from "../../../components";
import {TitleWrapper} from "../../../contexts/TitleProvider";

const manualUpdateForm = {
    machines:        {
        label: "Machines",
        type:  "machines"
    },
    command:         {
        label:        i18n.machineManualUpdatesCommand,
        type:         "select",
        defaultValue: "download",
        options:      i18n.machineCommands
    },
    path:            {
        label:        i18n.machineManualUpdatesPath,
        type:         "input",
        defaultValue: "",
        show:         f => [
            "apk_install",
            "apk_install_restart",
            "apk_uninstall",
            "command",
            "delete",
            "download",
            "zip_download",
            "send_file"
        ].indexOf(f.command.value) > -1
    },
    file:            {
        label: i18n.machineManualUpdatesFile,
        type:  "file",
        show:  f => ["apk_install", "apk_install_restart", "download", "zip_download"].indexOf(f.command.value) > -1
    },
    setting:         {
        label:        i18n.machineManualUpdatesSetting,
        type:         "input",
        defaultValue: "",
        show:         f => ["system_setting", "get_system_setting"].indexOf(f.command.value) > -1
    },
    setting_section: {
        label:        i18n.machineManualUpdatesSettingSection,
        type:         "input",
        defaultValue: "",
        show:         f => ["get_setting", "setting"].indexOf(f.command.value) > -1
    },
    setting_name:    {
        label:        i18n.machineManualUpdatesSettingName,
        type:         "input",
        defaultValue: "",
        show:         f => ["get_setting", "setting"].indexOf(f.command.value) > -1
    },
    setting_type:    {
        label:        i18n.machineManualUpdatesSettingType,
        type:         "select",
        defaultValue: "string",
        options:      {
            string: "String",
            bool:   "Boolean",
            int:    "Integer",
            long:   "Long",
            float:  "Float"
        },
        show:         f => ["get_setting", "setting"].indexOf(f.command.value) > -1
    },
    setting_value:   {
        label:        i18n.machineManualUpdatesSettingValue,
        type:         "input",
        defaultValue: "",
        show:         f => ["setting", "system_setting"].indexOf(f.command.value) > -1
    }
};

const ManualUpdate = props => {
    const dispatch                    = useDispatch();
    const updating                    = useSelector(state => state.machines.updating);
    const [formValues, setFormValues] = useState({});
    const [formValid, setFormValid]   = useState(false);
    const handleChange                = (formValues, formValid) => {
        setFormValues(formValues);
        setFormValid(formValid);
    };
    const handleValid                 = formValid => setFormValid(formValid);

    return (
        <TitleWrapper title={i18n.routeTechnicalManualFull}>
            <div>
                <div className="content">
                    <Form
                        formData={manualUpdateForm}
                        values={formValues}
                        onChange={handleChange}
                        onValid={handleValid}
                        stacked
                    />
                    <Button round color="info" block disabled={!formValid} onClick={() => dispatch(actions.machines.manualUpdate(formValues))}>
                        {i18n.machineManualUpdatesSend}
                    </Button>
                    {updating && <Spinner/>}
                    <BottomBar backButton/>
                </div>
            </div>
        </TitleWrapper>
    );
};

export default ManualUpdate;
