import React, {useEffect, useState} from 'react';
import withGroups from "../../../hoc/withGroups";
import {BottomBar, Button, ButtonCrusher, Form, Spinner, Tour} from "../../../components";
import {TitleWrapper} from "../../../contexts/TitleProvider";
import i18n from "../../../i18n";
import css from "./Edit.module.css";
import {getPageHelpSteps} from "../../../help/tools";
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../../store/actions";

const groupForm = {
    name: {label: i18n.groupName, className: css.tourName}
};
const GroupEdit = props => {
    const dispatch = useDispatch();
    const loading  = useSelector(state => state.groups.loading);
    const saving   = useSelector(state => state.groups.saving);

    const [formValues, setFormValues] = useState(props.group ? props.group : {});
    const [formValid, setFormValid]   = useState(false);
    const [helpOpen, setHelpOpen]     = useState(false);
    const doHelp                      = () => setHelpOpen(true);
    const closeHelp                   = () => setHelpOpen(false);

    const handleChange = (newFormValues, newFormValid) => {
        setFormValues(newFormValues);
        setFormValid(newFormValid);
    }
    const handleSubmit = () => {
        if (!formValid) {
            dispatch(actions.messages.errorPush(i18n.errorForm));
            return;
        }
        dispatch(actions.groups.manageSave(props.groupUuid, formValues));
    }
    useEffect(() => {
        if (props.group) setFormValues(props.group)
    }, [props.group]);


    return (
        <TitleWrapper title={i18n.groupsSettings}>
            <Tour
                isOpen={helpOpen}
                onRequestClose={closeHelp}
                steps={getPageHelpSteps("groupsEdit")}
            />
            <div>
                <div className="content-form">
                    {props.group && (
                        <>
                            <Form
                                inlineSelect
                                formData={groupForm}
                                values={formValues}
                                onChange={handleChange}
                                onValid={setFormValid}
                                stacked
                            />
                            <ButtonCrusher width={78}>
                                <Button
                                    block
                                    round
                                    upper
                                    color="info"
                                    onClick={handleSubmit}
                                    className={css.tourSave}
                                >{i18n.buttonSave}</Button>
                                <Button
                                    block
                                    round
                                    upper
                                    color="warning"
                                    onClick={() => props.history.push('/groups/caretakers/' + props.groupUuid)}
                                    className={css.tourCaretakers}>{i18n.groupsCaretakers}</Button>
                            </ButtonCrusher>
                        </>
                    )}
                    <BottomBar
                        backButton
                        backUrl="/"
                        onHelp={doHelp}
                        onCloseHelp={closeHelp}
                        helpOpen={helpOpen}
                    />
                    {!props.group || loading || saving ? <Spinner/> : null}
                </div>
            </div>
        </TitleWrapper>
    );
};


export default withGroups(GroupEdit);