import * as actionTypes from "../actionTypes";

export const load         = language => ({type: actionTypes.HELP_LOAD, language});
export const loadAll      = () => ({type: actionTypes.HELP_LOAD_ALL});
export const loadIfNeeded = () => ({type: actionTypes.HELP_LOAD_IF_NEEDED});
export const loadSuccess  = items => ({type: actionTypes.HELP_LOAD_SUCCESS, items});
export const loadFail     = error => ({type: actionTypes.HELP_LOAD_FAIL, error: "HELP_LOAD_FAILED"});

export const unload = () => ({type: actionTypes.HELP_UNLOAD});

export const save        = (uuid, itemDetails) => ({type: actionTypes.HELP_SAVE, uuid, itemDetails});
export const saveSuccess = () => ({type: actionTypes.HELP_SAVE_SUCCESS});
export const saveFail    = error => ({type: actionTypes.HELP_SAVE_FAIL, error});

export const remove        = (uuid, language) => ({type: actionTypes.HELP_REMOVE, uuid, language});
export const removeSuccess = () => ({type: actionTypes.HELP_REMOVE_SUCCESS});
export const removeFail    = error => ({type: actionTypes.HELP_REMOVE_FAIL, error});

export const clearError = () => ({type: actionTypes.HELP_RESET_ERROR});
