import AdminCaretakers from "../views/Admin/Caretakers/Caretakers";
import AdminCaretakersAdd from "../views/Admin/Caretakers/Add/Add";
import AdminCaretakersEdit from "../views/Admin/Caretakers/Edit/Edit";
import AdminCaretakersMachines from "../views/Admin/Caretakers/Machines/Machines";
import AdminAffiliates from "../views/Admin/Affiliates/Affiliates";
import AdminAffiliatesAdd from "../views/Admin/Affiliates/Add/Add";
import AdminAffiliatesEdit from "../views/Admin/Affiliates/Edit/Edit";
import AdminDistributors from "../views/Admin/Distributors/Distributors";
import AdminDistributorAdd from "../views/Admin/Distributors/Add/Add";
import AdminDistributorEdit from "../views/Admin/Distributors/Edit/Edit";
import AdminGroups from "../views/Admin/Groups/Groups";
import AdminGroupAdd from "../views/Admin/Groups/Add/Add";
import AdminGroupEdit from "../views/Admin/Groups/Edit/Edit";
import AdminGroupCaretakers from "../views/Admin/Groups/Caretakers/Caretakers";
import AdminGroupCaretakersAdd from "../views/Admin/Groups/Caretakers/Add/Add";
import AdminMachines from "../views/Admin/Machines/Machines";
import AdminMachinesInfo from "../views/Admin/Machines/Info/Info";
import AdminUsersInfo from "../views/Admin/Users/Info/Info";
import AdminUsersAdd from "../views/Admin/Users/Add/Add";
import AdminUsersEdit from "../views/Admin/Users/Edit/Edit";
import AdminUsers from "../views/Admin/Users/Users";
import Caretakers from "../views/Caretakers/Caretakers";
import TechnicalStatus from "../views/Technical/Status/Status";
import TechnicalManualUpdate from "../views/Technical/ManualUpdate/ManualUpdate";
import Help from "../views/Help/Help";
import HelpAdd from "../views/Help/Add/Add";
import HelpEdit from "../views/Help/Edit/Edit";
import Account from "../views/Account/Account";
import Contact from "../views/Contact/Contact";

import AddNewMachine from "../views/Caretakers/AddNewMachine/AddNewMachine";
import EditAvatar from "../views/Caretakers/EditAvatar/EditAvatar";
import Edit from "../views/Caretakers/Edit/Edit";
import Calendars from "../views/Caretakers/Calendars/Calendars";
import Settings from "../views/Caretakers/Settings/Settings";
import ScreenSettings from "../views/Caretakers/ScreenSettings/ScreenSettings";
import Preview from "../views/Caretakers/Preview/Preview";

import i18n from "../i18n";
import icons from "../variables/icons";
import LogoutPage from "../views/Pages/LogoutPage";
import VerifyPage from "../views/Account/VerifyPage";
import Overview from "../views/Caretakers/Overview/Overview";
import InviteNewUser from "../views/Caretakers/InviteNewUser/InviteNewUser";
import InvitationPage from "../views/Account/InvitationPage";
import Password from "../views/Account/Password/Password";
import CheckNewAccount from "../views/Account/CheckNewAccount";
import AddItem from "../views/Caretakers/AddItem/AddItem";
import CalendarEditAppointments from "../views/Caretakers/CalendarEdit/CalendarEditAppointments";
import CalendarEditBirthdays from "../views/Caretakers/CalendarEdit/CalendarEditBirthdays";
import CalendarEditReminders from "../views/Caretakers/CalendarEdit/CalendarEditReminders";
import CalendarEditTvShows from "../views/Caretakers/CalendarEdit/CalendarEditTvShows";
import SendMessage from "../views/Caretakers/Message/SendMessage";
import PreviousMessages from "../views/Caretakers/Message/PreviousMessages";
import AddItemDate from "../views/Caretakers/AddItem/AddItemDate";
import AllItems from "../views/Caretakers/AllItems/AllItems";
import AddCaretaker from "../views/Caretakers/AddCaretaker/AddCaretaker";
import Status from "../views/Caretakers/Status/Status";
import ScreenStatus from "../views/Caretakers/ScreenStatus/ScreenStatus";

import GroupEdit from "../views/Groups/Edit/Edit";
import GroupCaretakers from "../views/Groups/Caretakers/Caretakers";
import GroupAddItem from "../views/Groups/AddItem/AddItem";
import GroupSendMessage from "../views/Groups/Message/SendMessage";
import GroupPreviousMessages from "../views/Groups/Message/PreviousMessages";
import GroupCalendars from "../views/Groups/Calendars/Calendars";
import GroupList from "../views/Groups/List/List";
import GroupCalendarEditAppointments from "../views/Groups/CalendarEdit/CalendarEditAppointments";
import GroupCalendarEditBirthdays from "../views/Groups/CalendarEdit/CalendarEditBirthdays";
import GroupCalendarEditReminders from "../views/Groups/CalendarEdit/CalendarEditReminders";
import GroupCalendarEditTvShows from "../views/Groups/CalendarEdit/CalendarEditTvShows";
import CalendarDefaultSettings from "../views/Caretakers/CalendarDefaultSettings/CalendarDefaultSettings";
import InvitedUsers from "../views/Caretakers/InvitedUsers/InvitedUsers";


/*
        component: GroupSendMessage,
        component: GroupPreviousMessages,
        component: GroupAllItems,
        component: GroupCalendars,
        component: GroupCalendarEditAppointments,
        component: GroupCalendarEditBirthdays,
        component: GroupCalendarEditReminders,
        component: GroupCalendarEditTvShows,
        component: GroupOverview,

 */


let authenticatedRoutes = [
    {
        hidden:    true,
        path:      "/checknewaccount",
        component: CheckNewAccount,
        name:      i18n.routeAccount
    },
    {
        hidden:    true,
        path:      "/caretakers/addnewcaretaker",
        component: AddCaretaker,
        name:      i18n.caretakersItemAdd
    },
    {
        hidden:    true,
        path:      "/caretakers/invitenewuser",
        component: InviteNewUser,
        name:      i18n.caretakersAddNewUser
    },
    {
        hidden:    true,
        path:      "/caretakers/invitedusers",
        component: InvitedUsers,
        name:      i18n.caretakersInvitedUsers
    },
    {
        hidden:    true,
        path:      "/caretakers/edit/avatar/:caretaker",
        component: EditAvatar,
        name:      i18n.caretakersItemEdit,
        requires:  {details: 2}

    },
    {
        hidden:    true,
        path:      "/caretakers/addmachine/:caretaker",
        component: AddNewMachine,
        name:      i18n.caretakersAddNewMachineTitle,
        requires:  {details: 2}

    },
    {
        hidden:    true,
        path:      "/caretakers/screenstatus/:caretaker/:screen",
        component: ScreenStatus,
        name:      i18n.caretakerScreenStatus,
        requires:  {details: 2}

    },
    {
        hidden:    true,
        path:      "/caretakers/status/:caretaker",
        component: Status,
        name:      i18n.caretakerScreenStatus,
        requires:  {details: 2}

    },
    {
        hidden:    true,
        path:      "/caretakers/edit/:caretaker",
        component: Edit,
        name:      i18n.caretakersItemEdit,
        requires:  {details: 2}

    },
    {
        hidden:    true,
        path:      "/caretakers/add/:caretaker",
        component: AddItem,
        name:      i18n.calendarAddItem,
        requires:  {details: 2}

    },
    {
        hidden:    true,
        path:      "/caretakers/adddate/:caretaker/:date/:hour?",
        component: AddItemDate,
        name:      i18n.calendarAddItem,
        requires:  {details: 2}

    },
    {
        hidden:    true,
        path:      "/caretakers/message/send/:caretaker",
        component: SendMessage,
        name:      i18n.caretakersMessage,
        requires:  {messages: 1},
        hideBrand: true
    },
    {
        hidden:    true,
        path:      "/caretakers/message/list/:caretaker",
        component: PreviousMessages,
        name:      i18n.caretakersMessage,
        requires:  {messages: 1},
        hideBrand: true
    },
    {
        hidden:    true,
        path:      "/caretakers/calendars/allitems/:caretaker",
        component: AllItems,
        name:      i18n.caretakersItemCalendars,
        requires:  {calendar: 1},
        hideBrand: true
    },
    {
        hidden:    true,
        path:      "/caretakers/calendars/:caretaker",
        component: Calendars,
        name:      i18n.caretakersItemCalendars,
        requires:  {calendar: 1},
        hideBrand: true
    },
    {
        hidden:    true,
        path:      "/caretakers/calendar/appointments/:caretaker/edit/:item/:date?/:hour?",
        component: CalendarEditAppointments,
        name:      i18n.caretakersItemCalendarsEditItemAppointments,
        requires:  {calendar: 2}
    },
    {
        hidden:    true,
        path:      "/caretakers/calendar/birthdays/:caretaker/edit/:item/:date?/:hour?",
        component: CalendarEditBirthdays,
        name:      i18n.caretakersItemCalendarsEditItemBirthdays,
        requires:  {calendar: 2}
    },
    {
        hidden:    true,
        path:      "/caretakers/calendar/reminders/:caretaker/edit/:item/:date?/:hour?",
        component: CalendarEditReminders,
        name:      i18n.caretakersItemCalendarsEditItemReminders,
        requires:  {calendar: 2}
    },
    {
        hidden:    true,
        path:      "/caretakers/calendar/tvshows/:caretaker/edit/:item/:date?/:hour?",
        component: CalendarEditTvShows,
        name:      i18n.caretakersItemCalendarsEditItemTvShows,
        requires:  {calendar: 2}
    },
    {
        hidden:    true,
        path:      "/caretakers/calendar/defaultsettings/:caretaker",
        component: CalendarDefaultSettings,
        name:      i18n.caretakersItemCalendarsDefaultSettings,
        requires:  {calendar: 2}
    },
    {
        hidden:    true,
        path:      "/caretakers/overview/:caretaker/:date",
        component: Overview,
        name:      i18n.caretakersDayOverview,
        requires:  {calendar: 1},
        hideBrand: true
    },
    {
        hidden:    true,
        path:      "/caretakers/preview/:caretaker",
        component: Preview,
        name:      i18n.caretakersItemPreview,
        requires:  {preview: 1}
    },
    {
        hidden:    true,
        path:      "/caretakers/settings/:caretaker",
        component: Settings,
        name:      i18n.caretakerSettings,
        requires:  {settings: 2},
        hideBrand: true
    },
    {
        hidden:    true,
        path:      "/caretakers/screensettings/:caretaker",
        component: ScreenSettings,
        name:      i18n.caretakerSettings,
        requires:  {settings: 2},
        hideBrand: true
    },





    {
        hidden:    true,
        path:      "/groups/edit/:group",
        component: GroupEdit,
        name:      i18n.groupsEditTitle,
        requires:  {details: 2}
    },
    {
        hidden:    true,
        path:      "/groups/caretakers/:group",
        component: GroupCaretakers,
        name:      i18n.groupsCaretakers,
        requires:  {details: 2}
    },
    {
        hidden:    true,
        path:      "/groups/list/:group",
        component: GroupList,
        name:      i18n.groupsList,
        requires:  {details: 1}
    },
    {
        hidden:    true,
        path:      "/groups/add/:group",
        component: GroupAddItem,
        name:      i18n.calendarAddItem,
        requires:  {details: 2}
    },
    {
        hidden:    true,
        path:      "/groups/message/send/:group",
        component: GroupSendMessage,
        name:      i18n.caretakersMessage,
        requires:  {messages: 1},
        hideBrand: true
    },
    {
        hidden:    true,
        path:      "/groups/message/list/:group",
        component: GroupPreviousMessages,
        name:      i18n.caretakersMessage,
        requires:  {messages: 1},
        hideBrand: true
    },
    {
        hidden:    true,
        path:      "/groups/calendars/:group",
        component: GroupCalendars,
        name:      i18n.caretakersItemCalendars,
        requires:  {calendar: 1},
        hideBrand: true
    },
    {
        hidden:    true,
        path:      "/groups/calendar/appointments/:group/edit/:item/:date?",
        component: GroupCalendarEditAppointments,
        name:      i18n.caretakersItemCalendarsEditItemAppointments,
        requires:  {calendar: 2}
    },
    {
        hidden:    true,
        path:      "/groups/calendar/birthdays/:group/edit/:item/:date?",
        component: GroupCalendarEditBirthdays,
        name:      i18n.caretakersItemCalendarsEditItemBirthdays,
        requires:  {calendar: 2}
    },
    {
        hidden:    true,
        path:      "/groups/calendar/reminders/:group/edit/:item/:date?",
        component: GroupCalendarEditReminders,
        name:      i18n.caretakersItemCalendarsEditItemReminders,
        requires:  {calendar: 2}
    },
    {
        hidden:    true,
        path:      "/groups/calendar/tvshows/:group/edit/:item/:date?",
        component: GroupCalendarEditTvShows,
        name:      i18n.caretakersItemCalendarsEditItemTvShows,
        requires:  {calendar: 2}
    },
    {
        path:      "/caretakers",
        name:      i18n.routeCaretakers,
        icon:      icons["multiple-11"],
        component: Caretakers,
        hideBrand: true
    },
    /*    {
            path:      "/locations",
            name:      i18n.routeLocations,
            icon:      "pin-3",
            component: Locations
        },*/
    {
        hidden:    true,
        path:      "/admin/affiliates/edit/:affiliate",
        component: AdminAffiliatesEdit,
        name:      i18n.affiliatesEditTitle,
        requires:  {superAdmin: true}

    },
    {
        hidden:    true,
        path:      "/admin/affiliates/add",
        component: AdminAffiliatesAdd,
        name:      i18n.affiliatesAddTitle,
        requires:  {superAdmin: true}

    },
    {
        hidden:    true,
        path:      "/admin/distributors/edit/:distributor",
        component: AdminDistributorEdit,
        name:      i18n.distributorsEditTitle,
        requires:  {superAdmin: true}

    },
    {
        hidden:    true,
        path:      "/admin/distributors/add",
        component: AdminDistributorAdd,
        name:      i18n.distributorsAddTitle,
        requires:  {superAdmin: true}

    },
    {
        hidden:    true,
        path:      "/admin/groups/edit/:group",
        component: AdminGroupEdit,
        name:      i18n.groupsEditTitle,
        requires:  {superAdmin: true}

    },
    {
        hidden:    true,
        path:      "/admin/groups/caretakers/add/:group",
        component: AdminGroupCaretakersAdd,
        name:      i18n.groupsCaretakersAdd,
        requires:  {superAdmin: true}

    },
    {
        hidden:    true,
        path:      "/admin/groups/caretakers/:group",
        component: AdminGroupCaretakers,
        name:      i18n.groupsEditTitle,
        requires:  {superAdmin: true}

    },
    {
        hidden:    true,
        path:      "/admin/groups/add",
        component: AdminGroupAdd,
        name:      i18n.groupsAddTitle,
        requires:  {superAdmin: true}

    },
    {
        hidden:    true,
        path:      "/admin/caretakers/edit/:caretaker",
        component: AdminCaretakersEdit,
        name:      i18n.caretakersItemEdit,
        requires:  {superAdmin: true}

    },
    {
        hidden:    true,
        path:      "/admin/caretakers/machines/:caretaker",
        component: AdminCaretakersMachines,
        name:      i18n.caretakersItemMachinesTitle,
        requires:  {superAdmin: true}

    },
    {
        hidden:    true,
        path:      "/admin/caretakers/add",
        component: AdminCaretakersAdd,
        name:      i18n.caretakersItemAdd,
        requires:  {superAdmin: true}
    },
    {
        hidden:    true,
        path:      "/admin/machines/info/:macAddress",
        component: AdminMachinesInfo,
        name:      i18n.machineInfoTitle,
        requires:  {superAdmin: true}
    },
    {
        hidden:    true,
        path:      "/admin/users/info/:userId",
        component: AdminUsersInfo,
        name:      i18n.usersInfoTitle,
        requires:  {superAdmin: true}
    },
    {
        hidden:    true,
        path:      "/admin/users/edit/:userId",
        component: AdminUsersEdit,
        name:      i18n.usersEditTitle,
        requires:  {superAdmin: true}
    },
    {
        hidden:    true,
        path:      "/admin/users/add",
        component: AdminUsersAdd,
        name:      i18n.usersAddTitle,
        requires:  {superAdmin: true}
    },
    {
        collapse: true,
        rights:   "admin",
        path:     "/admin",
        name:     i18n.routeAdmin,
        state:    "openAdmin",
        icon:     icons["agenda-bookmark"],
        requires: {superAdmin: true},
        views:    [
            {
                path:      "/admin/affiliates",
                name:      i18n.routeAdminAffiliatesFull,
                mini:      i18n.routeAdminAffiliatesMini,
                component: AdminAffiliates,
                requires:  {superAdmin: true}
            },
            {
                path:      "/admin/distributors",
                name:      i18n.routeAdminDistributorsFull,
                mini:      i18n.routeAdminDistributorsMini,
                component: AdminDistributors,
                requires:  {superAdmin: true}
            },
            {
                path:      "/admin/groups",
                name:      i18n.routeAdminGroupsFull,
                mini:      i18n.routeAdminGroupsMini,
                component: AdminGroups,
                requires:  {superAdmin: true}
            },
            {
                path:      "/admin/caretakers",
                name:      i18n.routeAdminCaretakersFull,
                mini:      i18n.routeAdminCaretakersMini,
                component: AdminCaretakers,
                requires:  {superAdmin: true}
            },
            {
                path:      "/admin/users",
                name:      i18n.routeAdminUsersFull,
                mini:      i18n.routeAdminUsersMini,
                component: AdminUsers,
                requires:  {superAdmin: true}
            },
            {
                path:      "/admin/machines",
                name:      i18n.routeAdminMachinesFull,
                mini:      i18n.routeAdminMachinesMini,
                component: AdminMachines,
                requires:  {superAdmin: true}
            }
        ]
    },
    {
        collapse: true,
        rights:   "technical",
        path:     "/technical",
        name:     i18n.routeTechnical,
        state:    "openTechnical",
        icon:     icons["settings-gear"],
        requires: {technical: 1},
        views:    [
            {
                path:      "/technical/status",
                name:      i18n.routeTechnicalStatusFull,
                mini:      i18n.routeTechnicalStatusMini,
                component: TechnicalStatus,
                requires:  {technical: 1}
            },
            {
                path:      "/technical/manual",
                name:      i18n.routeTechnicalManualFull,
                mini:      i18n.routeTechnicalManualMini,
                component: TechnicalManualUpdate,
                requires:  {technical: 2}
            },
            /*            {
                            path:      "/technical/updates",
                            name:      i18n.routeTechnicalUpdatesFull,
                            mini:      i18n.routeTechnicalUpdatesMini,
                            component: Technical,
                            requires:  {technical: 2}
                        }*/
        ]
    },
    {
        path:      "/help/edit/:item",
        name:      i18n.helpEditTitle,
        hidden:    true,
        component: HelpEdit,
        requires:  {superAdmin: true}
    },
    {
        path:      "/help/add",
        name:      i18n.helpAddTitle,
        hidden:    true,
        component: HelpAdd,
        requires:  {superAdmin: true}
    },
    {
        path:      "/account/password",
        name:      i18n.changePasswordTitle,
        hidden:    true,
        component: Password
    },
    {
        path:      "/account/email",
        name:      i18n.changeEmailTitle,
        hidden:    true,
        component: Account
    },
    {
        path:      "/account",
        name:      i18n.routeAccount,
        icon:      icons["circle-10"],
        component: Account
    },
    {
        path:      "/help",
        name:      i18n.routeHelp,
        icon:      icons["c-info"],
        component: Help,
        requires:  {superAdmin: true}
    },
    {
        path:      "/contact",
        name:      i18n.routeContact,
        icon:      icons["email-2"],
        component: Contact
    },
    {
        path:      "/logout",
        name:      i18n.routeLogout,
        icon:      icons.leave,
        component: LogoutPage,
        color:     "#2E3C3F"
    },
    {
        hidden:    true,
        path:      "/verifymail/:token",
        component: VerifyPage
    },
    {
        path:      "/invitation/:token",
        name:      i18n.invitationTitle,
        hidden:    true,
        component: InvitationPage
    },
    {
        redirect: true,
        path:     "/login",
        pathTo:   "/caretakers"
    },
    {
        redirect: true,
        path:     "/register",
        pathTo:   "/checknewaccount",
    },
    {
        redirect: true,
        path:     "/",
        pathTo:   "/caretakers",
    }
];

export default authenticatedRoutes;
