export const runActionMap = (state, action, actionMap) => {
    if (actionMap.hasOwnProperty(action.type)) {
        return actionMap[action.type](state, action);
    }
    return state;
};

export const load       = (state, action) => ({...state, loading: true});
export const loadDone   = (state, action) => ({...state, loading: false, error: null});
export const loadFail   = (state, action) => ({...state, loading: false, error: action.error});
export const save       = (state, action) => ({...state, saving: true});
export const saveDone   = (state, action) => ({...state, saving: false, error: null});
export const saveFail   = (state, action) => ({...state, saving: false, error: action.error});
export const send       = (state, action) => ({...state, sending: true});
export const sendDone   = (state, action) => ({...state, sending: false, error: null});
export const sendFail   = (state, action) => ({...state, sending: false, error: action.error});
export const resetError = (state, action) => ({...state, error: null});
