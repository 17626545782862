import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import i18n from "../../../i18n";
import * as actions from "../../../store/actions";

import {Spinner, Icon, BottomBar, Tour} from "../../../components";
import {ucFirst} from "../../../variables/tools";
import withCaretakers from "../../../hoc/withCaretakers";

import css from "./PreviousMessages.module.css";
import {TitleWrapper} from "../../../contexts/TitleProvider";
import {getPageHelpSteps} from "../../../help/tools";

// Table cell definitions
const SingleMessage = props => (
    <div className={css.message}>
        <div className={css.sender}>{props.sentBy}:</div>
        <div className={css.messageContainer}>
            <span className={css.messageText + " " + (props.active ? css.active : null)}>{props.message}</span>
            {props.active ?
                <button className={css.messageDelete} onClick={() => props.onCancel(props.uuid)}><Icon name="hide"/></button> :
                <button className={css.messageResend} onClick={() => props.onResend(props.message, props.duration, props.urgent)}><Icon name="reload"/></button>
            }
        </div>
        <div className={css.date}>{ucFirst(props.sentAt.local().format("dddd D MMMM YYYY, HH:mm"))}</div>
    </div>
);

const PreviousMessages = props => {
    const dispatch = useDispatch();
    const messages = useSelector(state => state.caretakerMessages.messages);

    const [helpOpen, setHelpOpen]     = useState(false);
    const doHelp                      = () => setHelpOpen(true);
    const closeHelp                   = () => setHelpOpen(false);
    const [intervalId, setIntervalId] = useState(null);

    const startInterval  = () => setIntervalId(setInterval(reloadMessages, 30000));
    const stopInterval   = () => intervalId && clearInterval(intervalId);
    const startMessages  = () => {
        reloadMessages();
        stopInterval();
        startInterval();
    };
    const reloadMessages = () => {
        if (props.caretaker) {
            dispatch(actions.caretakerMessages.load(props.caretaker.uuid));
        }
    };
    const resendMessage  = (message, duration, urgent) => {
        dispatch(actions.caretakerMessages.send(props.caretaker.uuid, message, duration, urgent));
    };
    const cancelMessage  = uuid => {
        dispatch(actions.caretakerMessages.cancel(props.caretaker.uuid, uuid));
    };
    useEffect(() => {
        startMessages();
        return stopInterval;
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        startMessages();
        // eslint-disable-next-line
    }, [props.caretaker])

    return (
        <TitleWrapper title={i18n.caretakersSentMessages.replace('%1', props.caretaker ? props.caretaker.name : i18n.caretaker)}>
            <Tour
                isOpen={helpOpen}
                onRequestClose={closeHelp}
                steps={getPageHelpSteps("caretakerMessageList")}
            />
            <div>
                <div className={css.messagesContent}>
                    {messages ? messages.map(message => <SingleMessage key={message.uuid} {...message} onResend={resendMessage} onCancel={cancelMessage}/>) :
                        <Spinner/>}
                    <BottomBar backButton onHelp={doHelp} onCloseHelp={closeHelp} helpOpen={helpOpen}/>
                </div>
            </div>
        </TitleWrapper>
    );

};

export default withCaretakers(React.memo(PreviousMessages));