export default {
    locationsTitle: "Alle zorgvrager locaties",

    caretakers:   "Zorgvragers",
    noCaretakers: "Geen zorgvragers",

    caretaker:                    "zorgvrager",
    caretakersTitle:              "Zorgvragers",
    caretakersTableName:          "Naam",
    caretakersTableLocation:      "Locatie",
    caretakersTableAddress:       "Adres",
    caretakersTableZipCode:       "Postcode",
    caretakersTableCity:          "Plaats",
    caretakersTableState:         "Provincie",
    caretakersTableCountry:       "Land",
    caretakersTableTimeZone:      "Tijdzone",
    caretakersTableMachines:      "Machines",
    caretakersTableMachinesShort: "M",
    caretakersActionsEdit:        "Bewerken",
    caretakersActionsDelete:      "Verwijderen",

    caretakersFullTableName:     "Zorgvrager naam",
    caretakersFullTableLocation: "Zorgvrager locatie",
    caretakersFullTableAddress:  "Zorgvrager adres",
    caretakersFullTableZipCode:  "Zorgvrager postcode",
    caretakersFullTableCity:     "Zorgvrager plaats",
    caretakersFullTableState:    "Zorgvrager provincie",
    caretakersFullTableCountry:  "Zorgvrager land",

    caretakersAddNewMachine:                "Voeg een nieuwe Spiqle toe",
    caretakersAddNewMachineTitle:           "Spiqle toevoegen",
    caretakersAddNewMachineScreenCodeLabel: "Schermcode",
    caretakersAddNewMachineScreenName:      "Naam scherm",
    caretakersAddNewMachineStep2:           "Zorgvrager",
    caretakersAddNewMachineStep3:           "Samenvatting",
    caretakersAddNewMachineScreenCode:      "Voer de code in die op het scherm wordt weergegeven",
    caretakersAddNewMachineCaretaker:       "Kies een bestaande zorgvrager of voeg een nieuwe zorgvrager toe",
    caretakersAddNewMachineSuccess:         "De nieuwe Spiqle Agenda is succesvol toegevoegd. U kunt nu de inhoud in gaan stellen.",
    caretakersAddNewMachineError:           "Er is een fout opgetreden bij het koppelen van het scherm",
    caretakersAddNewMachineInvalidCode:     "Onbekende scherm code, controleer de scherm code en probeer het opnieuw",
    caretakersAddNewMachineToExisting:      "Voeg nieuwe Spiqle toe aan deze zorgvrager",


    caretakersAddMenu: "Voeg toe...",

    caretakersAddNewUser:                  "Gebruiker toevoegen",
    caretakersAddNewUserExplain:           "Met deze functie kunt u andere gebruikers uitnodigen om ook toegang te krijgen tot uw zorgvragers. Zo kunt u bijvoorbeeld de zorg delen met een familielid of collega.",
    caretakersAddNewUserName:              "Naam",
    caretakersAddNewUserEmail:             "E-mail",
    caretakersAddNewUserCaretakers:        "Toegang tot:",
    caretakersAddNewUserRights:            "Gebruikersrechten",
    caretakersAddNewUserRightsList:        {
        content: "Agenda",
        users:   "Agenda en gebruikers",
        payment: "Agenda en administratie"
    },
    caretakersInviteSuccess:               "De nieuwe gebruiker is succesvol uitgenodigd",
    caretakersInviteError:                 "Er is een fout opgetreden bij het uitnodigen van de nieuwe gebruiker",
    caretakersInviteErrorInvitedYourself:  "U kunt uzelf niet uitnodigen",
    caretakersInviteErrorUnknownRight:     "Onbekende gebruikers rechten",
    caretakersInviteErrorInvalidCaretaker: "Onbekende zorvrager",

    caretakersInvitedUsers:              "Uitgenodigde gebruikers",
    caretakersInvitedUsersInvitedOn:     "Uitgenodigd op",
    caretakersInvitedUsersStatus:        "Status",
    caretakersInvitedUsersStatusUsed:    "Geaccepteerd",
    caretakersInvitedUsersStatusExpired: "Uitnodiging verlopen",
    caretakersInvitedUsersStatusNotUsed: "Nog niet geaccepteerd",
    caretakersInvitedNoInvites:          "Er zijn geen uitnodigingen verstuurd",

    caretakersItemAdd:         "Zorgvrager toevoegen",
    caretakersItemEdit:        "Wijzig zorgvrager gegevens",
    caretakersItemEditSuccess: "De gegevens van de zorgvrager zijn succesvol opgeslagen",
    caretakersItemEditError:   "Er is een fout opgetreden bij het opslaan van de gegevens van de zorgvrager",

    caretakersAddMachine: "Spiqle scherm toevoegen",

    caretakersMessage:              "Direct bericht",
    caretakersMessageTo:            "Direct bericht naar %1",
    caretakersMessageSentTo:        "Stuur een bericht naar %1",
    caretakersMessageMessage:       "Bericht",
    caretakersMessageDuration:      "Zichtbaar voor:",
    caretakersMessageDurations:     {
        300:   "5 minuten",
        900:   "15 minuten",
        1800:  "30 minuten",
        3600:  "1 uur",
        10800: "3 uur",
        21600: "6 uur",
        43200: "12 uur",
        86400: "24 uur"
    },
    caretakersMessageUrgent:        "Urgent bericht",
    caretakersMessageSuccess:       "Direct bericht succesvol verzonden",
    caretakersMessageError:         "Er is een fout opgetreden bij het verzenden van het direct bericht",
    caretakersMessageCancelSuccess: "Direct bericht succesvol verwijderd van het scherm",
    caretakersMessageCancelError:   "Er is een fout opgetreden bij het verwijderen van het direct bericht",
    caretakersMessageNoMachines:    "Deze zorgvrager heeft nog geen schermen",
    buttonCancelMessage:            "Bericht annuleren (verwijderen van scherm)",

    caretakersSentMessages:          "Berichtenoverzicht",
    caretakersSentMessagesMessage:   "Bericht",
    caretakersSentMessagesSentLong:  "Verzonden",
    caretakersSentMessagesSentShort: "Verz.",
    caretakersSentMessagesSentBy:    "Verzonden door",
    caretakersSentMessagesDelivered: "Getoond op scherm",
    caretakersSentMessagesCancelled: "Bericht geannuleerd",
    caretakersNoSentMessages:        "Nog geen berichten verstuurd",

    caretakersItemCalendar:                      "Kalender",
    caretakersItemCalendars:                     "Kalenders",
    caretakersItemCalendarsDefaultSettings:      "Standaard instellingen voor kalenders",
    caretakersItemCalendarsEditAppointments:     "Beheer afspraken",
    caretakersItemCalendarsEditBirthdays:        "Beheer verjaardagen",
    caretakersItemCalendarsEditTvShows:          "Beheer TV programma's",
    caretakersItemCalendarsEditReminders:        "Beheer herinneringen",
    caretakersItemCalendarsEditItemAppointments: "Bewerk afspraak",
    caretakersItemCalendarsEditItemBirthdays:    "Bewerk verjaardag",
    caretakersItemCalendarsEditItemTvShows:      "Bewerk TV programma",
    caretakersItemCalendarsEditItemReminders:    "Bewerk herinnering",
    caretakersItemCalendarsFor:                  "Beheer kalenders voor %1",
    caretakersItemPreview:                       "Schermvoorbeeld",
    caretakersItemPreviewFor:                    "Schermvoorbeeld voor %1",
    caretakersItemMachines:                      "Beheer machines voor %1",
    caretakersItemMachinesTitle:                 "Beheer machines",
    caretakersItemMachinesAdd:                   "Koppel machine aan deze zorgvrager",
    caretakersSelectCaretaker:                   "Selecteer een zorgvrager",
    caretakerChooseAddOrExisting:                "Selecteer een bestaande zorgvrager of voeg er een toe",
    caretakerCreateNewTitle:                     "Voer de gegevens van de nieuwe zorgvrager toe",
    caretakerCreateNewCaretaker:                 "Voeg nieuwe zorgvrager toe",

    caretakersRemoveTitle:   "Zorgvrager verwijderen",
    caretakersRemoveBody:    "Als u de zorgvrager met de naam '%1' verwijdert, worden alle machines die momenteel aan deze zorgvrager gekoppeld zijn ontkoppeld. Dit is niet ongedaan te maken.",
    caretakersRemoveSure:    "Zorgvrager verwijderen",
    caretakersRemoveSuccess: "De zorgvrager is succesvol verwijderd",
    caretakersRemoveError:   "Er is een fout opgetreden tijdens het verwijderen van de zorgvrager",

    caretakersDayOverview:      "Dagoverzicht",
    caretakersDayOverviewFor:   "Dagoverzicht voor %1",
    caretakersWeekOverviewFor:  "Weekoverzicht voor %1",
    caretakersMonthOverviewFor: "Maandoverzicht voor %1",
    caretakersOverviewTime:     "Tijd",
    caretakersOverviewNoItems:  "Er zijn deze dag geen agenda items",

    caretakerSettings:    "Instellingen",
    caretakerSettingsFor: "Beheer de instellingen voor %1",

    caretakerDetails:           "Gegevens %1",
    caretakerScreenSettings:    "Scherminstellingen",
    caretakerScreenStatus:      "Spiqle Schermen",
    caretakerScreenStatusTitle: "Schermen %1",
    caretakerScreenName:        "Naam scherm:",
    caretakerScreenStatusHead:  "Status scherm:",

    caretakerSettingsTheme:                          "Thema",
    caretakerSettingsThemeValues:                    {
        black:               "zwart",
        blackboard:          "schoolbord",
        emeraldgreen:        "donkergroen",
        lemongrass:          "limoengras",
        nightblue:           "donkerblauw",
        papaver:             "papaver",
        pumpkin:             "pompoen",
        steelblue:           "staalblauw",
        yellow:              "contrast geel",
        sockgreenolive:      "groene sok: Olijf",
        sockgreenkhaki:      "groene sok: Khaki",
        sockdewcorn:         "dauw sok: Maïszijde",
        sockdewwheat:        "dauw sok: Tarwe",
        sockperuyellowbrown: "peru sok: Geelbruin",
        sockperusienna:      "peru sok: Sienna",
        modernblue:          "modern - Blauw",
        modernbrown:         "modern - Bruin",
        moderngreen:         "modern - Groen",
        moderndeepsea:       "modern - Diepzee",
        modernblack:         "modern - Zwart"
    },
    caretakerSettingsFont:                           "Lettertype",
    caretakerSettingsFontValues:                     {
        helvetica: "Helvetica",
        calibri:   "Calibri",
        gillsans:  "Gill sans"
    },
    caretakerSettingsLanguage:                       "Taal",
    caretakerSettingsLanguageValues:                 {
        nl: "Nederlands",
        da: "Deens",
        de: "Duits",
        en: "Engels"
    },
    caretakerSettingsGreeting:                       "Begroeting",
    caretakerSettingsClock:                          "Klok",
    caretakerSettingsClockValues:                    {
        digital:    "digitaal  11:00",
        digitalext: "digitaal  11:00 Ochtend",
        analog:     "analoog  🕘",
        written:    "uitgeschreven (Vijf over elf)"
    },
    caretakerSettingsDAT:                            "Toon overmorgen / over 2 dagen",
    caretakerSettingsDATValues:                      {
        dat:     "overmorgen",
        "2days": "over 2 dagen"
    },
    caretakerSettingsHours:                          "24 / 12 uur",
    caretakerSettingsHoursValues:                    {
        12: "12 uurs klok",
        24: "24 uurs klok"
        /*        12: "12 uurs klok (kwart over 2)",
                24: "24 uurs klok (14:15)"*/
    },
    caretakerSettingsSpeed:                          "Duur berichten",
    caretakerSettingsSpeedValues:                    {
        1: "snel (10 seconden)",
        2: "normaal (20 seconden)",
        3: "traag (40 seconden)",
        4: "zeer traag (60 seconden)",
        5: "nog trager (120 seconden)"
    },
    caretakerSettingsTomorrowDay:                    "Morgen",
    caretakerSettingsTomorrowDayYes:                 "Toon de dag als een afspraak morgen is",
    caretakerSettingsTomorrowDayNo:                  "Toon niet dag als een afspraak morgen is",
    caretakerSettingsItIsToday:                      "Toon vandaag",
    caretakerSettingsItIsTodayYes:                   "Toon 'vandaag' in de begroeting",
    caretakerSettingsItIsTodayNo:                    "Toon niet 'vandaag' in de begroeting",
    caretakerSettingsShowHourToday:                  "Toon 'uur' achter tijd",
    caretakerSettingsShowHourTodayYes:               "Toon 'uur' achter tijd",
    caretakerSettingsShowHourTodayNo:                "Toon geen 'uur' achter tijd",
    caretakerSettingsShowCountdown:                  "Tel af naar afspraak (nog x uur)",
    caretakerSettingsShowCountdownYes:               "Tel af naar afspraak (nog x uur)",
    caretakerSettingsShowCountdownNo:                "Tel niet af naar afspraak",
    caretakerSettingsShowWeather:                    "Weerbericht voor nu",
    caretakerSettingsShowWeatherYes:                 "Toon het huidige weerbericht",
    caretakerSettingsShowWeatherNo:                  "Toon niet het huidige weerbericht",
    caretakerSettingsTomorrowWeather:                "Weerbericht voor morgen",
    caretakerSettingsTomorrowWeatherYes:             "Toon het weer voor morgen",
    caretakerSettingsTomorrowWeatherNo:              "Toon niet het weer voor morgen",
    caretakerSettingsPublicHolidaysShowBefore:       "Toon feestdagen vanaf",
    caretakerSettingsPublicHolidaysShowBeforeValues: {
        "15 minutes": "De dag zelf",
        "1 day":      "1 dag vooraf",
        "2 days":     "2 dagen vooraf",
        "3 days":     "3 dagen vooraf",
        "4 days":     "4 dagen vooraf",
        "5 days":     "5 dagen vooraf",
        "6 days":     "6 dagen vooraf",
        "1 week":     "1 week vooraf"
    },
    caretakerSettingsPublicHolidaysCountry:          "Toon feestdagen voor",
    caretakerSettingsPublicHolidaysCountryValues:    {
        nl: "Nederland",
        be: "België",
        da: "Denemarken",
        de: "Duitsland",
        ch: "Zwitserland",
        uk: "Groot Britanië"
    },

    caretakerSettingsPublicHolidays:    "Feestdagen",
    caretakerSettingsPublicHolidaysYes: "Toon feestdagen",
    caretakerSettingsPublicHolidaysNo:  "Toon geen feestdagen",

    caretakerSettingsNotificationSound: "Notificatie geluid",
    caretakerSettingsNotificationYes:   "Geluid bij meldingen",
    caretakerSettingsNotificationNo:    "Geen geluid bij meldingen",
    caretakerSettingsErrorLoad:         "Er is een fout opgetreden bij het laden van de instellingen van de zorgvrager",
    caretakerSettingsErrorSave:         "Er is een fout opgetreden bij het opslaan van de instellingen van de zorgvrager",
    caretakerSettingsSuccessSave:       "De instellingen van de zorgvrager zijn succesvol opgeslagen",

    caretakerDefaultsErrorLoad:   "Er is een fout opgetreden bij het laden van de standaard instellingen van de zorgvrager",
    caretakerDefaultsErrorSave:   "Er is een fout opgetreden bij het opslaan van de standaard instellingen van de zorgvrager",
    caretakerDefaultsSuccessSave: "De standaard instellingen van de zorgvrager zijn succesvol opgeslagen",

    caretakerMachineNameErrorSave:   "Er is een fout opgetreden bij het opslaan van de naam van het scherm",
    caretakerMachineNameSuccessSave: "De naam van het scherm is succesvol opgeslagen",

    caretakerName:               "Naam",
    caretakerAddress:            "Adres",
    caretakerZipcode:            "Postcode",
    caretakerCity:               "Plaats",
    caretakerState:              "Provincie",
    caretakerCountry:            "Land",
    caretakerPhone:              "Telefoon nummer",
    caretakerPhoto:              "Foto",
    caretakerPhotoPick:          "Andere foto kiezen",
    caretakerPhotoRemove:        "Foto verwijderen",
    caretakerBirthday:           "Geboortedatum",
    caretakerShowBirthday:       "Toon geboortedatum in kalender",
    caretakerShowBirthdayYes:    "Toon in kalenderoverzicht",
    caretakerShowBirthdayNo:     "Toon niet in kalenderoverzicht",
    errorCaretakersLoad:         "Er is een fout opgetreden bij het laden van de zorgvrager gegevens",
    caretakersLoadMachinesError: "Er is een fout opgetreden bij het laden van de machines van de zorgvrager",

    photoAdd:    "Toevoegen",
    photoEdit:   "Vervangen",
    photoDelete: "Verwijderen",
};