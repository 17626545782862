export default {
    affiliates:   "Affiliates",
    noAffiliates: "Geen affiliates",

    affiliatesTitle:             "Affiliates",
    affiliatesTableName:         "Naam",
    affiliatesTableCity:         "Stad",
    affiliatesTableCountry:      "Land",
    affiliatesTablePhone:        "Telefoon",
    affiliatesTableEmail:        "E-mail",
    affiliatesTableMachines:     "Machines",
    affiliatesTableSent:         "Verzonden",
    affiliatesTableSentShort:    "V",
    affiliatesTableCoupled:      "Gekoppeld",
    affiliatesTableCoupledShort: "G",
    affiliatesActionsEdit:       "Bewerken",
    affiliatesActionsDelete:     "Verwijderen",
    affiliatesLoadError:         "Er is een fout opgetreden bij het laden van de affiliates",

    affiliatesAddTitle:      "Affiliate toevoegen",
    affiliatesEditTitle:     "Affiliate bewerken",
    affiliatesEditSuccess:   "De gegevens van de affiliate zijn succesvol opgeslagen",
    affiliatesEditError:     "Er is een fout opgetreden bij het opslaan van de gegevens van de affiliate",
    affiliatesRemoveTitle:   "Weet u zeker dat u deze affiliate wil verwijderen?",
    affiliatesRemoveBody:    "Als u de affiliate met de naam '%1' verwijdert, worden alle machines die momenteel aan deze affiliate gekoppeld zijn, aan Spiqle gekoppeld. Dit is niet ongedaan te maken.",
    affiliatesRemoveSure:    "Affiliate verwijderen",
    affiliatesRemoveSuccess: "De affiliate is succesvol verwijderd",
    affiliatesRemoveError:   "Er is een fout opgetreden bij het verwijderen van de affiliate",

    affiliateName:    "Naam",
    affiliateAddress: "Adres",
    affiliateZipcode: "Postcode",
    affiliateCity:    "Plaats",
    affiliateState:   "Provincie",
    affiliateCountry: "Land",
    affiliatePhone:   "Telefoon nummer",
    affiliateEmail:   "E-mail adres",
};