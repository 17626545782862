// React
// React
import React, {useEffect, useState} from "react";

// Redux
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../../store/actions";

// Tools
import i18n from "../../../i18n";
import moment from 'moment';
import 'moment-timezone';

// Components
import {BottomBar, Spinner, Tour} from "../../../components";
import withCaretakers from "../../../hoc/withCaretakers";

import {TitleWrapper} from "../../../contexts/TitleProvider";

import css from "./AllItems.module.css";
import {getPageHelpSteps} from "../../../help/tools";
import ListItem from "./ListItem";

const AllItems = props => {
    const dispatch = useDispatch();

    // Help
    const [helpOpen, setHelpOpen] = useState(false);
    const doHelp                  = () => setHelpOpen(true);
    const closeHelp               = () => setHelpOpen(false);

    const accountData      = useSelector(state => state.auth.accountData);
    const loadingCalendars = useSelector(state => state.calendars.loading);
    const calendarsFor     = useSelector(state => state.calendars.calendarsFor);
    const calendars        = useSelector(state => state.calendars.calendars);
    const loadedAt         = useSelector(state => state.calendars.loadedAt);

    useEffect(() => {
        if (
            props.caretaker &&
            !loadingCalendars && (
                calendarsFor !== props.caretaker.uuid ||
                (!loadedAt || loadedAt.isBefore(moment().subtract(5, "minutes")))
            )
        ) {
            dispatch(actions.calendars.load(props.caretaker.uuid));
        }
    }, [props.caretaker, loadingCalendars, calendarsFor, loadedAt, dispatch]);

    if (!props.caretaker || !calendars || props.loading || loadingCalendars) {
        return (
            <TitleWrapper title={i18n.caretakersItemCalendars}>
                <div>
                    <Spinner/>
                    <BottomBar backButton/>
                </div>
            </TitleWrapper>
        );
    }

    const events = [
        ...calendars.appointments.map(item => ({...item, type: "appointment"})),
        ...calendars.birthdays.map(item => ({...item, type: "birthday"})),
        ...calendars.reminders.map(item => ({...item, type: "reminder"})),
        ...calendars.tvshows.map(item => ({...item, type: "tvshow"}))
    ];

    return (
        <TitleWrapper title={
            i18n.calendarOverview
                .replace("%1", props.caretaker ? props.caretaker.name : i18n.caretaker)
        }>
            <Tour
                isOpen={helpOpen}
                onRequestClose={closeHelp}
                steps={getPageHelpSteps("allItems")}
            />
            <div>
                <div className="content">
                    {events.length === 0 ?
                        <p>{i18n.caretakersOverviewNoItems}</p> : (
                            <div className={css.listItemsContainer}>
                                {calendars.appointments.length > 0 && (<>
                                    <span className={css.listItemCategory}>{i18n.calendarTypes.appointments}:</span>
                                    {calendars.appointments.map(item => <ListItem key={item.uuid} type="appointment" {...item} />)}
                                </>)}
                                {calendars.reminders.length > 0 && (<>
                                    <span className={css.listItemCategory}>{i18n.calendarTypes.reminders}:</span>
                                    {calendars.reminders.map(item => <ListItem key={item.uuid} type="reminder" {...item} />)}
                                </>)}
                                {calendars.tvshows.length > 0 && (<>
                                    <span className={css.listItemCategory}>{i18n.calendarTypes.tvshows}:</span>
                                    {calendars.tvshows.map(item => <ListItem key={item.uuid} type="tvshow" {...item} />)}
                                </>)}
                                {calendars.birthdays.length > 0 && (<>
                                    <span className={css.listItemCategory}>{i18n.calendarTypes.birthdays}:</span>
                                    {calendars.birthdays.map(item => <ListItem key={item.uuid} type="birthday" {...item} />)}
                                </>)}
                            </div>
                        )
                    }
                    <BottomBar
                        backButton
                        backUrl={(accountData.calendarDisplay === "overview" ? "/caretakers/": "/caretakers/calendars/" + props.caretakerUuid)}
                        addButton={() => props.push("/caretakers/add/" + props.caretakerUuid)}
                        links={[
                            {title: i18n.caretakersItemCalendar, link: "/caretakers/calendars/" + props.caretaker.uuid}
                        ]}
                        helpOpen={helpOpen} onHelp={doHelp} onCloseHelp={closeHelp}
                    />
                </div>
            </div>
        </TitleWrapper>
    );
}

export default withCaretakers(AllItems);
