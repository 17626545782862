import React from "react";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import {Button, CollapseCard} from "../../components";

import i18n from "../../i18n";

const AddItem = props => {
    const dispatch = useDispatch();

    return (
        <CollapseCard title={i18n.helpEditItems} defaultOpenDesktop defaultOpenMobile>
            <Button round color="info" onClick={() => dispatch(push("/help/add"))}>{i18n.helpAddItem}</Button>
        </CollapseCard>
    );
};

export default AddItem;
