import {apiGetCall, apiPostCall} from "./index";

export const load = group => apiGetCall("/groupcalendars/get", {group});

export const saveAppointment = (group, appointment) => apiPostCall("/groupcalendars/saveappointment", {group, ...appointment});
export const saveBirthday    = (group, birthday) => apiPostCall("/groupcalendars/savebirthday", {group, ...birthday});
export const saveReminder    = (group, reminder) => apiPostCall("/groupcalendars/savereminder", {group, ...reminder});
export const saveTvShow      = (group, tvShow) => apiPostCall("/groupcalendars/savetvshow", {group, ...tvShow});

export const skip   = (calendarType, uuid) => apiPostCall("/groupcalendars/skip", {calendarType, uuid});
export const unskip = (calendarType, uuid) => apiPostCall("/groupcalendars/unskip", {calendarType, uuid});
export const remove = (calendarType, uuid) => apiPostCall("/groupcalendars/remove", {calendarType, uuid});