import React from 'react';
import moment from "moment/moment";
import i18n from '../../i18n';
import _ from "underscore";

export const validateInput = (value, item, form) => {
    if (!item.validation) {
        return [true, []];
    }

    const validationErrors = [];
    let isValid            = true;
    if (item.validation.required) {
        let stepValid = false;
        switch (item.type) {
            case "checkbox":
            case "switch":
            case "label":
                stepValid = value;
                break;

            case "weekselector":
                stepValid = value > 0;
                break;

            case "machines":
            case "caretakers":
            case "tags":
                stepValid = value.length > 0;
                break;

            case "date":
            case "datetime":
            case "select":
            case "radio":
            case "file":
                stepValid = value !== null;
                break;

            default:
                stepValid = value.trim() !== '';
        }
        if (!stepValid) {
            validationErrors.push(item.validationErrors.required)
        }
        isValid = isValid && stepValid;
    }
    if (item.validation.isEmail) {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let stepValid = pattern.test(value);
        if (!stepValid) {
            validationErrors.push(item.validationErrors.isEmail)
        }
        isValid = isValid && stepValid;
    }
    if (item.validation.regex) {
        let stepValid = item.validation.regex.test(value);
        if (!stepValid) {
            validationErrors.push(item.validationErrors.regex)
        }
        isValid = isValid && stepValid;
    }
    if (item.validation.minLength) {
        let stepValid = value && value.length >= item.validation.minLength;
        if (!stepValid) {
            validationErrors.push(item.validationErrors.minLength.replace("%1", item.validation.minLength))
        }
        isValid = isValid && stepValid;
    }
    if (item.validation.maxLength) {
        let stepValid = value && value.length <= item.validation.maxLength;
        if (!stepValid) {
            validationErrors.push(item.validationErrors.maxLength.replace("%1", item.validation.maxLength))
        }
        isValid = isValid && stepValid;
    }
    if (item.validation.func) {
        let stepValid = item.validation.func(value, form);
        if (!stepValid) {
            validationErrors.push(item.validationErrors.func)
        }
        isValid = isValid && stepValid;
    }
    return [isValid, validationErrors];
};

export const validateForm = form => {
    const validatedForm = {};
    let formValid       = true;
    for (let key in form) {
        if (form.hasOwnProperty(key)) {
            //if (form[key].type === "label") {
//                continue;
//            }
            if (form[key].type === "row" || form[key].type === "smallrow") {
                // Check items in row
                let validationRow = {type: form[key].type, items: {}, show: form[key].show, disabled: form[key].disabled};
                let haveToValidateRow = form[key].show(form) && !form[key].disabled(form);

                for (let subKey in form[key].items) {
                    if (form[key].items.hasOwnProperty(subKey)) {
                        if(form[key].items[subKey].type === "label") {
                            continue;
                        }
                        const [isValid, currentValidationErrors] = validateInput(form[key].items[subKey].value, form[key].items[subKey], form);
                        validationRow.items[subKey]              = {
                            ...form[key].items[subKey],
                            valid:       isValid,
                            validErrors: currentValidationErrors
                        };
                        if (haveToValidateRow && form[key].items[subKey].show(form) && !form[key].disabled(form)) {
                            formValid = formValid && isValid;
                        }
                    }
                }
                validatedForm[key] = validationRow;
            } else {
                // Check loose items
                const [isValid, currentValidationErrors] = validateInput(form[key].value, form[key], form);
                validatedForm[key]                       = {
                    ...form[key],
                    valid:       isValid,
                    validErrors: currentValidationErrors
                };
                if (form[key].show(form) && !form[key].disabled(form)) {
                    formValid = formValid && isValid;
                }
            }
        }
    }
    return {form: validatedForm, formValid};
};

export const isFormValid = form => {
    let formValid = true;
    for (let key in form) {
        if (form.hasOwnProperty(key)) {
            if (form[key].type === "label") {
                continue;
            }
            if (form[key].type === "row" || form[key].type === "smallrow") {
                if (form[key].show(form) && !form[key].disabled(form)) {
                    for (let subKey in form[key].items) {
                        if (form[key].items.hasOwnProperty(subKey) && form[key].items[subKey].show(form) && !form[key].items[subKey].disabled(form)) {
                            if(form[key].items[subKey].type === "label") {
                                continue;
                            }
                            formValid = formValid && form[key].items[subKey].valid;
                        }
                    }
                }
            } else {
                if (form[key].show(form) && !form[key].disabled(form)) {
                    formValid = formValid && form[key].valid;
                }
            }
        }
    }
    return formValid;
};

export const updateItem = (form, itemName, validate, changeObj) => {
    const newForm                = {...form};
    let requiresFullRevalidation = false;
    if (newForm.hasOwnProperty(itemName)) {
        if (newForm[itemName].type === "label") {
            return [newForm, requiresFullRevalidation];
        }
        newForm[itemName] = {
            ...newForm[itemName],
            ...changeObj
        };
        if (validate) {
            const [isValid, currentValidationErrors] = validateInput(newForm[itemName].value, newForm[itemName], newForm);
            newForm[itemName]                        = {
                ...newForm[itemName],
                valid:       isValid,
                validErrors: currentValidationErrors
            };
            if (newForm[itemName].fullRevalidation) {
                requiresFullRevalidation = true;
            }
        }
    } else {
        for (let key in newForm) {
            if (newForm.hasOwnProperty(key) && newForm[key].items && newForm[key].items.hasOwnProperty(itemName)) {
                if (newForm[key].items[itemName].type === "label") {
                    continue;
                }
                newForm[key].items[itemName] = {
                    ...newForm[key].items[itemName],
                    ...changeObj
                };
                if (validate) {
                    const [isValid, currentValidationErrors] = validateInput(newForm[key].items[itemName].value, newForm[key].items[itemName], newForm);
                    newForm[key].items[itemName]             = {
                        ...newForm[key].items[itemName],
                        valid:       isValid,
                        validErrors: currentValidationErrors
                    };
                    if (newForm[key].items[itemName]) {
                        requiresFullRevalidation = true;
                    }
                }
            }
        }
    }
    return [newForm, requiresFullRevalidation];
};

const parseFormItem = (item, key, values) => {
    if (!item.hasOwnProperty("label")) {
        throw new Error(`Form item ${key} is missing label`);
    }

    let elementType = "input";
    if (item.hasOwnProperty("type")) {
        elementType = item.type;
    }

    let elementConfig = {type: "text", placeholder: "", autoComplete: "abcdefghijklmnopq3242345"};
    if (item.hasOwnProperty("elementConfig")) {
        elementConfig = {...item.elementConfig};
    }

    let validation = {required: true};
    if (elementType === "label" || elementType === "switch" || elementType === "checkbox") {
        validation = false;
    }
    if (item.hasOwnProperty("validation")) {
        if (!item.validation) {
            validation = false;
        } else {
            validation = {...item.validation};
        }
    }

    let validationErrors = {...i18n.validationErrors};
    if (item.hasOwnProperty("validationErrors")) {
        validationErrors = {
            ...validationErrors,
            ...item.validationErrors
        };
    }

    let showValidationErrors = false;
    if (item.hasOwnProperty("showValidationErrors")) {
        showValidationErrors = item.showValidationErrors;
    }

    let validationErrorLocation = "auto";
    if (item.hasOwnProperty("validationErrorLocation")) {
        validationErrorLocation = item.validationErrorLocation;
    }

    let fullRevalidation = false;
    if (item.hasOwnProperty("fullRevalidation")) {
        fullRevalidation = item.fullRevalidation;
    }

    if (elementType === "checkbox" || elementType === "switch" || elementType === "select" || elementType === "radio") {
        validation = false;
    }

    let show = () => true;
    if (item.hasOwnProperty("show")) {
        show = item.show;
    }

    let disabled = () => false;
    if (item.hasOwnProperty("disabled")) {
        if (_.isFunction(item.disabled)) {
            disabled = item.disabled;
        } else {
            disabled = () => item.disabled;
        }
    }

    let size = 12;
    if (item.hasOwnProperty("size")) {
        size = item.size;
    }

    // Parse values
    let value = "";
    if (elementType === "datetime" || elementType === "date") {
        value = moment();
    }
    if (elementType === "checkbox" || elementType === "switch") {
        value = false;
    }
    if (elementType === "weekselector") {
        value = 0;
    }
    if (elementType === "file") {
        value = null;
    }
    if (elementType === "machines" || elementType === "tags") {
        value = [];
    }
    let touched = false;
    if (item.hasOwnProperty("defaultValue")) {
        value = item.defaultValue;
    }
    if (values && values.hasOwnProperty(key)) {
        if (values[key] != null) {
            value   = values[key];
            touched = true;
        }
    }

    let currentItem = {
        ...item,
        type:    elementType,
        focused: false,
        elementConfig, validation, validationErrors, showValidationErrors, validationErrorLocation, fullRevalidation, value, touched, show, size, disabled
    };

    let [itemIsValid, currentValidationErrors] = validateInput(value, currentItem);
    return {...currentItem, valid: itemIsValid, validErrors: currentValidationErrors};
};

export const initializeForm = (formSpecs, values) => {
    const form = {};
    for (let key in formSpecs) {
        if (formSpecs.hasOwnProperty(key)) {
            let item = formSpecs[key];
            if (item.type === "row" || item.type === "smallrow") {
                let subItems = {};
                // This is a row, parse columns
                for (let subKey in item.items) {
                    if (item.items.hasOwnProperty(subKey)) {
                        subItems[subKey] = parseFormItem(item.items[subKey], subKey, values);
                    }
                }

                form[key] = {
                    type:     item.type,
                    items:    subItems,
                    show:     item.hasOwnProperty("show") ? item.show : () => true,
                    disabled: item.hasOwnProperty("disabled") ? item.disabled : () => false
                };
            } else {
                // This is a loose item, parse item
                form[key] = parseFormItem(item, key, values);
            }
        }
    }
    return validateForm(form);
};

export const getFormValues = form => {
    const returnValues = {};
    for (let key in form) {
        if (form.hasOwnProperty(key)) {
            if(form[key].type === "label") {
                continue;
            }
            if (form[key].type === "row" || form[key].type === "smallrow") {
                for (let subKey in form[key].items) {
                    if (form[key].items.hasOwnProperty(subKey)) {
                        if(form[key].items[subKey].type === "label") {
                            continue;
                        }
                        returnValues[subKey] = form[key].items[subKey].value;
                    }
                }
            } else {
                returnValues[key] = form[key].value;
            }
        }
    }
    return returnValues;
};

export const getRefArray = form => {
    const refs     = {};
    const refOrder = [];
    for (let key in form) {
        if (form.hasOwnProperty(key)) {
            if(form[key].type === "label") {
                continue;
            }
            if (form[key].type === "row" || form[key].type === "smallrow") {
                for (let subKey in form[key].items) {
                    if (form[key].items.hasOwnProperty(subKey)) {
                        if(form[key].items[subKey].type === "label") {
                            continue;
                        }
                        refs[subKey] = React.createRef();
                        refOrder.push(subKey);
                    }
                }
            } else {
                refs[key] = React.createRef();
                refOrder.push(key);
            }
        }
    }
    return [refs, refOrder];
};

export const filterFormValues = (form, values) => {
    const returnValues = {};
    if (!values) {
        return returnValues;
    }
    for (let key in form) {
        if (form.hasOwnProperty(key)) {
            if(form[key].type === "label") {
                continue;
            }
            if (form[key].type === "row" || form[key].type === "smallrow") {
                for (let subKey in form[key].items) {
                    if (form[key].items.hasOwnProperty(subKey) && values.hasOwnProperty(subKey)) {
                        if(form[key].items[subKey].type === "label") {
                            continue;
                        }
                        returnValues[subKey] = values[subKey];
                    }
                }
            } else {
                if (values.hasOwnProperty(key)) {
                    returnValues[key] = values[key];
                }
            }
        }
    }
    return returnValues;
};