import {call, put, delay, select, takeEvery, takeLatest} from "redux-saga/effects";
import {push} from 'connected-react-router';
import * as actions from "../actions";
import * as actionTypes from "../actionTypes";
import * as api from "../../api";
import i18n, {setLanguage} from "../../i18n";
import {mapErrorResponseCodes} from "../../variables/tools";

// Watcher
export function* watchAuth() {
    yield takeLatest(actionTypes.AUTH_LOGIN, loginSaga);
    yield takeLatest(actionTypes.AUTH_SIGNUP, signUpSaga);
    yield takeEvery(actionTypes.AUTH_INITIATE_LOGOUT, logoutSaga);
    yield takeEvery(actionTypes.AUTH_CHECK_LOCALSTORAGE, checkLocalStorage);
    yield takeEvery(actionTypes.AUTH_SUCCESS, successSaga);
    yield takeLatest(actionTypes.AUTH_FORGOT_PASSWORD, forgotPasswordSaga);
    yield takeLatest(actionTypes.AUTH_CHECK_PASSWORD_TOKEN, checkPasswordTokenSaga);
    yield takeLatest(actionTypes.AUTH_RESET_PASSWORD, resetPasswordSaga);
    yield takeLatest(actionTypes.AUTH_VERIFY_EMAIL, verifyEmailSaga);
    yield takeLatest(actionTypes.AUTH_LOAD_ACCOUNTDATA, loadAccountDataSaga);
    yield takeLatest(actionTypes.AUTH_SAVE_ACCOUNTDATA, saveAccountDataSaga);
    yield takeLatest(actionTypes.AUTH_CHECK_TIMEOUT, checkAuthTimeout);
    yield takeLatest(actionTypes.AUTH_SET_LANGUAGE, setLanguageSaga);
    yield takeLatest(actionTypes.AUTH_LOAD_INVITE, loadInvite);
    yield takeLatest(actionTypes.AUTH_ACCEPT_INVITE, acceptInvite);
    yield takeLatest(actionTypes.AUTH_CHANGE_PASSWORD, changePasswordSaga);
}

// LocalStorage helpers
function localStorageSaveToken(token) {
    const expirationTimestamp = Date.now() + (token.expiresIn * 1000);
    localStorage.setItem('idToken', token.idToken);
    localStorage.setItem('refreshToken', token.refreshToken);
    localStorage.setItem('expirationDate', expirationTimestamp);
    localStorage.setItem("language", token.language);
}

function localStorageClearToken() {
    localStorage.removeItem('idToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('language');
}

function localStorageGetToken() {
    const idToken = localStorage.getItem("idToken");
    if (!idToken) {
        return null;
    }
    const refreshToken        = localStorage.getItem("refreshToken");
    const expirationTimestamp = localStorage.getItem("expirationDate");
    const language            = localStorage.getItem("language");
    return {idToken, refreshToken, expirationTimestamp, language};
}

// Sagas
function* loginSaga(action) {
    yield put(actions.auth.start());
    try {
        const response = yield call(api.auth.login, action.email, action.password, action.inviteToken);
        yield call(localStorageSaveToken, response.data);
        yield put(actions.auth.success(response.data));
        yield put(actions.auth.checkTimeout(response.data.expiresIn));
    }
    catch (err) {
        yield put(actions.messages.errorPush(mapErrorResponseCodes(err, {
            UNKNOWN_CREDENTIALS:     i18n.loginError,
            ACCOUNT_DISABLED:        i18n.loginDisabled,
            INVALID_TOKEN:           i18n.invitationErrorUnknownToken,
            TOKEN_USED:              i18n.invitationErrorTokenUsed,
            TOKEN_EXPIRED:           i18n.invitationErrorTokenExpired,
            INVITED_BY_SAME_ACCOUNT: i18n.invitationErrorTokenIsYours,
            default:                 i18n.loginError
        })));
        yield put(actions.auth.fail());
    }
}

function* signUpSaga(action) {
    try {
        const response = yield call(api.auth.signup, action.name, action.email, action.password, action.inviteToken);
        yield put(actions.auth.start());
        yield call(localStorageSaveToken, response.data);
        yield put(actions.auth.success(response.data));
        yield put(actions.auth.checkTimeout(response.data.expiresIn));
        yield put(actions.messages.successPush(i18n.signUpSuccess));
    }
    catch (err) {
        yield put(actions.messages.errorPush(mapErrorResponseCodes(err, {
            PASSWORD_NOT_STRONG: i18n.signUpErrorPassword,
            SIGNUP_ERROR_EXISTS: i18n.signUpErrorExists,
            INVALID_TOKEN:       i18n.invitationErrorUnknownToken,
            TOKEN_USED:          i18n.invitationErrorTokenUsed,
            TOKEN_EXPIRED:       i18n.invitationErrorTokenExpired,
            default:             i18n.signUpError
        })));
        if (err.response && err.response.data && err.response.data.error) {
            yield put(actions.auth.fail(err.response.data.error));
        } else {
            yield put(actions.auth.fail());
        }
    }
}

function* logoutSaga(action) {
    yield call(localStorageClearToken);
    yield call(api.removeAuthToken);
    yield put(actions.auth.logoutDone());
    yield put(actions.resetState(true));
    if (action.redirect) {
        yield put(push(action.redirect));
    }
}

function* checkAuthTimeout(action) {
    yield delay(action.expirationTime * 1000);
    yield put(actions.auth.logoutDone());
}

function* successSaga(action) {
    yield call(api.setAuthToken, action.token.idToken);
    yield updateLanguageStrings(action.token.language);
    yield put(actions.auth.loadAccountData());
}

function* forgotPasswordSaga(action) {
    try {
        yield call(api.auth.forgotPassword, action.email);
        yield put(actions.auth.forgotPasswordSuccess());
        yield put(push("/"));
        yield put(actions.messages.successPush(i18n.forgotSubmitSuccess));
    }
    catch (err) {
        yield put(actions.auth.forgotPasswordFail(err));
        yield put(actions.messages.errorPush(i18n.forgotSubmitFailed));
    }
}

function* verifyEmailSaga(action) {
    try {
        yield call(api.auth.verifyEmail, action.token);
        yield put(actions.auth.verifyEmailSuccess());
        yield put(actions.messages.successPush(i18n.verifyBodyVerified));
        yield put(push("/"));
    }
    catch (err) {
        yield put(actions.messages.errorPush(mapErrorResponseCodes(err, {
            VERIFIED: i18n.verifyBodyAlreadyVerified,
            default:  i18n.verifyBodyUnknown
        })));
        yield put(push("/"));
    }
}

function* resetPasswordSaga(action) {
    try {
        yield call(api.auth.resetPassword, action.token, action.password);
        yield put(actions.auth.resetPasswordSuccess());
        yield put(push("/"));
        yield put(actions.messages.successPush(i18n.resetPasswordSuccess));
    }
    catch (err) {
        yield put(actions.messages.errorPush(mapErrorResponseCodes(err, {
            PASSWORD_NOT_STRONG: i18n.signUpErrorPassword,
            INVALID_TOKEN:       i18n.resetPasswordUnknown,
            default:             i18n.resetPasswordFailed
        })));
        yield put(actions.auth.resetPasswordFail(err));
    }
}

function* checkPasswordTokenSaga(action) {
    try {
        yield call(api.auth.checkPasswordToken, action.token);
        yield put(actions.auth.checkPasswordTokenSuccess());
    }
    catch (err) {
        yield put(actions.auth.checkPasswordTokenFail(err));
        yield put(actions.messages.errorPush(i18n.resetPasswordUnknown));
        yield put(push("/"));
    }
}

function* loadAccountDataSaga(action) {
    try {
        const accountData = yield call(api.auth.getAccountData);
        yield put(actions.auth.loadAccountDataSuccess(accountData.data.accountData, accountData.data.rights, accountData.data.caretakerRights));
        const loading  = yield select(state => state.help.loading);
        const language = yield select(state => state.help.language);
        if (!loading && language !== "all") {
            yield put(actions.help.load(i18n.getLanguage()));
        }
    }
    catch (err) {
        yield put(actions.auth.loadAccountDataFail());
        yield put(actions.auth.fail());
    }
}

function* saveAccountDataSaga(action) {
    try {
        yield call(api.auth.saveAccountData, action.accountData);
        yield put(actions.auth.saveAccountDataSuccess());
        yield put(push("/caretakers"));
        yield put(actions.messages.successPush(i18n.successAccountSave));
        yield put(actions.auth.setLanguage(action.accountData.language));
    }
    catch (err) {
        yield put(actions.auth.saveAccountDataFail());
        yield put(actions.messages.errorPush(i18n.errorAccountSave));
    }
}

function* checkLocalStorage(action) {
    const token = yield call(localStorageGetToken);
    if (!token) {
        yield put(actions.auth.logout());
        return;
    }

    if (Date.now() > token.expirationTimestamp) {
        yield put(actions.auth.logout());
        return;
    }

    yield put(actions.auth.success(token));
    yield put(actions.auth.checkTimeout((token.expirationTimestamp - Date.now()) / 1000));
}

function* setLanguageSaga(action) {
    yield localStorage.setItem('language', action.language);
    yield updateLanguageStrings(action.language);
}

function* loadInvite(action) {
    try {
        const inviteData = yield call(api.auth.loadInviteData, action.inviteToken);
        yield put(actions.auth.loadInviteSuccess(inviteData.data.invitedBy, inviteData.data.invitee, inviteData.data.caretakers));
    }
    catch (err) {
        yield put(push("/"));

        // Dirty workaround to eliminate the error dialog when logging in
        const loggedInAt = yield select(state => state.auth.loggedInAt);
        if(!loggedInAt || Date.now() > loggedInAt + 2000) {
            yield put(actions.messages.errorPush(mapErrorResponseCodes(err, {
                INVALID_TOKEN:           i18n.invitationErrorUnknownToken,
                TOKEN_USED:              i18n.invitationErrorTokenUsed,
                TOKEN_EXPIRED:           i18n.invitationErrorTokenExpired,
                INVITED_BY_SAME_ACCOUNT: i18n.invitationErrorTokenIsYours,
                default:                 i18n.invitationErrorGeneric
            })));
        }
        yield put(actions.auth.loadInviteFail());
    }
}

function* acceptInvite(action) {
    try {
        yield call(api.auth.acceptInvite, action.inviteToken);
        yield put(actions.auth.acceptInviteSuccess());
        yield put(actions.auth.loadAccountData());
        yield put(push("/"));
    }
    catch (err) {
        yield put(actions.messages.errorPush(mapErrorResponseCodes(err, {
            INVALID_TOKEN:           i18n.invitationErrorUnknownToken,
            TOKEN_USED:              i18n.invitationErrorTokenUsed,
            TOKEN_EXPIRED:           i18n.invitationErrorTokenExpired,
            INVITED_BY_SAME_ACCOUNT: i18n.invitationErrorTokenIsYours,
            default:                 i18n.invitationErrorGeneric
        })));
        yield put(actions.auth.acceptInviteFail());
    }
}

function* changePasswordSaga(action) {
    try {
        yield call(api.auth.changePassword, action.oldPassword, action.newPassword);
        yield put(actions.messages.successPush(i18n.changePasswordSuccessful));
        yield put(actions.auth.changePasswordSuccess());
        yield put(push("/"));
    }
    catch (err) {
        yield put(actions.messages.errorPush(mapErrorResponseCodes(err, {
            PASSWORD_NOT_STRONG: i18n.changePasswordNotStrong,
            INVALID_PASSWORD:    i18n.changePasswordInvalidOldPassword,
            default:             i18n.errorTitle
        })));
        yield put(actions.auth.changePasswordFail());
    }
}

function updateLanguageStrings(language) {
    if (setLanguage(language)) {
        window.location.reload();
    }
}