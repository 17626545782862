import React from 'react';
import PropTypes from 'prop-types';

const detailItem = ({name, children}) => (
    <tr>
        <td><strong>{name}</strong></td>
        <td>{children}</td>
    </tr>
);

detailItem.propTypes = {
    name: PropTypes.string.isRequired
};

export default detailItem;