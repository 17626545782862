const checkI18N = languages => {
    const keys  = {};
    let allKeys = [];

    // Get all keys from languages
    Object
        .keys(languages)
        .forEach(language => {
            keys[language] = Object.keys(languages[language]);
            allKeys        = allKeys.concat(keys[language]);
        });

    // Get unique keys
    allKeys = allKeys.filter((value, index, self) => self.indexOf(value) === index);

    console.log("Checking i18n strings...");

    // Check if languages have all keys
    let missingSomething = false;
    Object
        .keys(languages)
        .forEach(language => {
            let missing = allKeys.filter(value => keys[language].indexOf(value) < 0);
            if (missing.length > 0) {
                missingSomething = true;
                console.log("Missing in " + language + ":", missing);
            }
        });

    if(!missingSomething) {
        console.log("All languages check out okay!")
    }
};

export default checkI18N;