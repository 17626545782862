import React, {useRef, useState} from "react";
import PropTypes from "prop-types";

import {Button} from "../../../components";
import i18n from "../../../i18n";
import {appRootPath} from "../../../variables/multiplatform";

import defaultImage from "../../../assets/img/image_placeholder.jpg";
import css from "./ImageUpload.module.css";
import loadImage from "blueimp-load-image";
import {exifOrientationToRotation} from "../../../variables/tools";

const ImageUpload = props => {
    const [selected, setSelected] = useState(!!props.existingImage);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(props.existingImage ? props.existingImage : appRootPath + defaultImage);
    const [rotation, setRotation] = useState(0);
    const fileInput = useRef();

    const handleImageChange = e => {
        e.preventDefault();
        let file = e.target.files[0];
        loadImage(file, (img, data) => {
            setRotation(exifOrientationToRotation(data));
            let reader = new FileReader();
            reader.onloadend = () => {
                setSelected(true);
                setImagePreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }, {meta: true});
        props.onSelect(file);
    };

    return (
        <>
            <label className="caretaker-edit-avatar-label">{i18n.image}</label>
            <div className="fileinput text-center">
                <div className="thumbnail">
                    <div className="caretaker-edit-avatar"
                         style={{
                             backgroundImage: "url(" + imagePreviewUrl + ")",
                             transform: "rotate(" + rotation + "deg)"
                         }}/>
                </div>
                <div className="caretaker-edit-avatar-buttons">
                    {!selected ? (
                        <input
                            type="file"
                            accept="image/*;capture=camera"
                            className={css.imageUpload + " " + css[i18n.getLanguage()]}
                            onChange={handleImageChange}
                            ref={fileInput}
                        />
                    ) : (
                        <>
                            <input
                                type="file"
                                accept="image/*;capture=camera"
                                className={css.imageUpload + " " + css[i18n.getLanguage() + "Edit"]}
                                onChange={handleImageChange}
                                ref={fileInput}
                                style={{display: "none"}}
                            />
                            <Button color="danger" round onClick={props.onRemove}>
                                <i className="fa fa-times"/> {i18n.photoDelete}
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

ImageUpload.propTypes = {
    existingImage: PropTypes.string,
    onSelect: PropTypes.func,
    onRemove: PropTypes.func
};

export default ImageUpload;

/*
return (
        <>
            <label className="caretaker-edit-avatar-label">{i18n.image}</label>
            <div className="fileinput text-center">
                <input type="file" onChange={this.handleImageChange} ref={this.fileInput}/>
                <div className="thumbnail">
                    <div className="caretaker-edit-avatar"
                         style={{backgroundImage: "url(" + this.state.imagePreviewUrl + ")"}}/>
                </div>
                <div className="caretaker-edit-avatar-buttons">
                    {!this.state.selected ? (
                        <Button round onClick={this.handleClick}>
                            {i18n.photoAdd}
                        </Button>
                    ) : (
                        <>
                            <Button round onClick={this.handleClick}>{i18n.photoEdit}</Button>
                            <br/>
                            <Button color="danger" round onClick={this.handleRemove}>
                                <i className="fa fa-times"/> {i18n.photoDelete}
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </>
    );
*/