import React from "react";
import {Switch, useLocation} from "react-router-dom";
import {NotificationReceiver} from "../../components";

import css from "./Pages.module.css";
import useBodyClass from "../../hooks/useBodyClass";
import useHtmlClass from "../../hooks/useHtmlClass";
import {findRoute} from "../../variables/tools";
import unauthenticatedRoutes from "../../routes/unauthenticated";
import useDocumentTitle from "../../hooks/useDocumentTitle";

const Pages = props => {
    useHtmlClass(css.htmlPage);
    useBodyClass(css.bodyPage);

    let title   = "Spiqle";
    const location = useLocation();
    const route = findRoute(unauthenticatedRoutes, location.pathname);
    if (route) {
        title = "Spiqle - " + route.name;
    }
    useDocumentTitle(title);

    //             <PagesHeader stickToTop/>
    return (
        <div className={css.pages}>
            <NotificationReceiver/>
            <div className={css.pageWrapper + " full-page"}>
                <Switch>
                    {props.children}
                </Switch>
            </div>
        </div>
    );
};

export default Pages;
