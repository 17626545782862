import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';

import {Button, Form, ButtonCrusher} from "../../../components";
import i18n from "../../../i18n";
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../../store/actions";
import css from "./AddCaretaker.module.css";
import {appRootPath} from "../../../variables/multiplatform";
import addScreenLogo from "../../../assets/img/add-screen.png";

const step1Form = {
    screenCode: {
        label:      i18n.caretakersAddNewMachineScreenCodeLabel,
        className:  css.tourScreenCode,
        validation: {
            isRequired: true,
            regex:      /^([a-z]+)\s([a-z]+)\s([a-z]+)\s([a-z]+)\s([a-z]+)$/i
        }
    },
    screenName: {
        label:     i18n.caretakersAddNewMachineScreenName,
        className: css.tourScreenName
    }
};

const ScreenCode = props => {
    const [formValues, setFormValues] = useState({});
    const [formValid, setFormValid]   = useState(false);

    const dispatch           = useDispatch();
    const screenCodeChecking = useSelector(state => state.caretakers.screenCodeChecking);
    const screenCodeValid    = useSelector(state => state.caretakers.screenCodeValid);
    useEffect(() => {
        if (!screenCodeChecking && screenCodeValid) {
            props.codeValid(formValues.screenCode, formValues.screenName);
            dispatch(actions.caretakers.resetScreenCodeValid());
        }
    }, [screenCodeChecking, screenCodeValid, dispatch, formValues.screenCode, formValues.screenName, props]);

    const handleChange = (newFormValues, newFormValid) => {
        setFormValues(newFormValues);
        setFormValid(newFormValid);
    };
    const checkCode    = () => {
        if (!formValid) {
            dispatch(actions.messages.errorPush(i18n.errorForm));
            return;
        }
        dispatch(actions.caretakers.checkScreenCode(formValues.screenCode));
    }

    return (
        <div>
            <div className={css.imgContainer}>
                <img src={appRootPath + addScreenLogo} alt={i18n.caretakersAddNewMachineTitle}/>
            </div>
            <div className={css.messageContainer}>{i18n.caretakersAddNewMachineScreenCode}</div>
            <Form formData={step1Form} values={formValues} onChange={handleChange} onSubmit={checkCode} handleEnter stacked/>
            <ButtonCrusher width={74} topSpacer={25}>
                <Button upper block round color="info" onClick={checkCode}>{i18n.buttonAdd}</Button>
            </ButtonCrusher>
        </div>
    );
};

ScreenCode.propTypes = {codeValid: PropTypes.func.isRequired};

export default ScreenCode;
