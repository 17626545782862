import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import AutoWrapInput from "../InputContainers/AutoWrapInput";
import {deepCompare} from "../../../variables/tools";
import i18n from "../../../i18n";

const Select2 = props => (
    <AutoWrapInput name={props.name} settings={props.settings} stacked={props.stacked} disabled={props.disabled}>
        <Select
            {...props.settings.elementConfig}
            name={props.name}
            disabled={props.disabled}
            onChange={option => props.handlers.change(option.value, props.name)}
            onFocus={() => props.handlers.focus(props.name, true)}
            onBlur={() => props.handlers.focus(props.name, false)}
            noOptionsMessage={() => i18n.noOptions}
            classNamePrefix="react-select"
            className="react-select"
            value={props.settings.options.filter(item => item.value === props.settings.value)}
            options={props.settings.options}
            ref={props.innerRef}
        />
    </AutoWrapInput>
);

Select2.propTypes = {
    name:     PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    handlers: PropTypes.object.isRequired,
    innerRef: PropTypes.object,
    stacked:  PropTypes.bool
};

export default React.memo(Select2, (prevProps, nextProps) => (
    prevProps.settings.value === nextProps.settings.value &&
    prevProps.settings.focused === nextProps.settings.focused &&
    prevProps.settings.valid === nextProps.settings.valid &&
    prevProps.disabled === nextProps.disabled &&
    deepCompare(prevProps.settings.options, nextProps.settings.options)
));
