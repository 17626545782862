import React from 'react';
import PropTypes from 'prop-types';
import css from "./ButtonCrusher.module.css";

const ButtonCrusher = props => (
    <div className={css.outside}>
        <div className={css.inside} style={{
            width:     (props.width ? props.width : 60) + "%",
            marginTop: (props.topSpacer ? props.topSpacer : 0) + "px"
        }}>
            {props.children}
        </div>
    </div>
);

ButtonCrusher.propTypes = {
    width:     PropTypes.number,
    topSpacer: PropTypes.number
};

export default ButtonCrusher;