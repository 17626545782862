// React
import React, {useContext, useState} from "react";

// Redux
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../../store/actions";

// Tools
import i18n from "../../../i18n";

// Components
import {BottomBar, Button, Form, Spinner, ButtonCrusher, Tour} from "../../../components";
import {TitleWrapper} from "../../../contexts/TitleProvider";
import ScreenCode from "./ScreenCode";
import {getPageHelpSteps} from "../../../help/tools";
import {ScrollContext} from "../../../contexts/ScrollProvider";
import Avatar from "./Avatar";
import caretakerForm from "./caretakerForm";
import defaultAvatar from "../../../assets/img/avatar-add.png";

const AddCaretaker = props => {
    const dispatch = useDispatch();
    const saving   = useSelector(state => state.caretakers.saving);

    const scrollContext                           = useContext(ScrollContext);
    const [avatar, setAvatar]                     = useState(null);
    const [avatarRotation, setAvatarRotation]     = useState(0);
    const [avatarPreviewUrl, setAvatarPreviewUrl] = useState(defaultAvatar);

    const [caretakerValues, setCaretakerValues] = useState({});
    const [caretakerValid, setCaretakerValid]   = useState(false);
    const [step, setStep]                       = useState(1);
    const [helpOpen, setHelpOpen]               = useState(false);
    const doHelp                                = () => setHelpOpen(true);
    const closeHelp                             = () => setHelpOpen(false);

    //dispatch(actions.caretakers.add(details))
    if (saving) {
        return <TitleWrapper title={i18n.caretakersItemAdd}><Spinner/></TitleWrapper>
    }

    const handleCaretakerChange = (formValues, formValid) => {
        setCaretakerValues(formValues);
        setCaretakerValid(formValid);
    };
    const caretakerEntered      = () => {
        if (!caretakerValid) {
            dispatch(actions.messages.errorPush(i18n.errorForm));
            return;
        }
        setStep(2);
        scrollContext.scrollBar.current.scrollTop = 0;
    };
    const codeValid             = (screenCode, screenName) => {
        dispatch(actions.caretakers.add({existing: "new", screenCode, screenName, avatar, ...caretakerValues}));
    };

    return (
        <TitleWrapper title={i18n.caretakersItemAdd}>
            <Tour
                isOpen={helpOpen}
                onRequestClose={closeHelp}
                steps={getPageHelpSteps("addCaretaker")}
            />
            <div>
                <div className="content-form">
                    {step === 1 && <div>
                        <Avatar
                            rotation={avatarRotation}
                            setRotation={setAvatarRotation}
                            previewUrl={avatarPreviewUrl}
                            setPreviewUrl={setAvatarPreviewUrl}
                            onSelect={setAvatar}
                        />
                        <Form
                            inlineSelect
                            formData={caretakerForm}
                            values={caretakerValues}
                            onChange={handleCaretakerChange}
                            onSubmit={caretakerEntered}
                            defaultFocus="name"
                            handleEnter
                            stacked
                        />
                        <ButtonCrusher width={77} topSpacer={20}>
                            <Button block upper round color="info" onClick={caretakerEntered}>
                                {i18n.buttonNext}
                            </Button>
                        </ButtonCrusher>
                    </div>
                    }
                    {step === 2 && <ScreenCode codeValid={codeValid}/>}
                    <BottomBar
                        funcs={[
                            {
                                title: i18n.buttonBack, func: () => {
                                    if (step === 1) {
                                        props.history.push("/");
                                    } else {
                                        setStep(1);
                                    }
                                }
                            }
                        ]}
                        onHelp={doHelp}
                        onCloseHelp={closeHelp}
                        helpOpen={helpOpen}
                    />
                </div>
            </div>
        </TitleWrapper>
    );
};

export default AddCaretaker;
