import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from "react-redux";
import {Collapse} from "reactstrap";
import css from "./List.module.css";
import {Icon} from "../../index";
import i18n from "../../../i18n";
import * as actions from "../../../store/actions";

const GroupList = props => {
    const groupsLoading       = useSelector(state => state.groups.loading);
    const caretakersLoading   = useSelector(state => state.caretakers.loading);
    const groups              = useSelector(state => state.groups.groups);
    const caretakers          = useSelector(state => state.caretakers.caretakers);
    const [isOpen, setIsOpen] = useState(false);
    const dispatch            = useDispatch();

    useEffect(() => {
        dispatch(actions.caretakers.loadIfNeeded());
        dispatch(actions.groups.loadIfNeeded())
    }, [dispatch]);

    if (groupsLoading || caretakersLoading || !groups || !caretakers) {
        return null;
    }

    let group = groups.find(group => group.uuid === props.groupUUID);
    if (group === undefined) {
        return null;
    }

    return (
        <div className={css.groupContainer}>
            <div className={css.button} onClick={() => setIsOpen(!isOpen)} data-toggle="collapse" aria-expanded={isOpen}>
                <span className={css.groupName}>
                    <span>{i18n.groupList}</span>
                    <Icon name="arrow"/>
                </span>
            </div>
            <Collapse isOpen={isOpen}>
                <div className={css.groupCollapse}>
                    {group.caretakers.map(caretakerUUID => {
                        let caretaker = caretakers.find(caretaker => caretaker.uuid === caretakerUUID);
                        if (!caretaker) return null;
                        return <div className={css.caretaker}>{caretaker.name}</div>;
                    })}
                </div>
            </Collapse>
        </div>
    );
};

GroupList.propTypes = {
    groupUUID: PropTypes.string.isRequired
};

export default GroupList;