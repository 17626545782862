import * as actionTypes from "../actionTypes";
import moment from 'moment-timezone';
import {runActionMap, load, loadFail, save, saveDone, saveFail, resetError} from "../reducerFunctions";

const initialState = {
    loading:          false,
    saving:           false,
    error:            null,
    affiliates:       null,
    affiliatesLoaded: null
};

// Load reducers
const loadSuccess = (state, action) => ({...state, loading: false, error: null, affiliates: action.affiliates, affiliatesLoaded: moment()});

const actionMap         = {
    [actionTypes.AFFILIATES_LOAD]:           load,
    [actionTypes.AFFILIATES_LOAD_SUCCESS]:   loadSuccess,
    [actionTypes.AFFILIATES_LOAD_FAIL]:      loadFail,
    [actionTypes.AFFILIATES_SAVE]:           save,
    [actionTypes.AFFILIATES_SAVE_SUCCESS]:   saveDone,
    [actionTypes.AFFILIATES_SAVE_FAIL]:      saveFail,
    [actionTypes.AFFILIATES_REMOVE]:         save,
    [actionTypes.AFFILIATES_REMOVE_SUCCESS]: saveDone,
    [actionTypes.AFFILIATES_REMOVE_FAIL]:    saveFail,
    [actionTypes.AFFILIATES_RESET_ERROR]:    resetError
};
const affiliatesReducer = (state = initialState, action) => runActionMap(state, action, actionMap);

export default affiliatesReducer;
