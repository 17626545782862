import React, {useRef, useState} from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from 'react-redux';
import {Card, CardHeader, CardBody, CardFooter} from "reactstrap";

import i18n from "../../../i18n";
import {Button, Spinner, Form} from "../../../components";
import {Link} from "react-router-dom";
import {isMobile} from "../../../variables/tools";
import spiqleLogo from "../../../assets/img/logo-big.svg";
import {appRootPath} from "../../../variables/multiplatform";
import * as actions from "../../../store/actions";

const signUpForm = {
    name:           {
        label:         i18n.caretakerName,
        elementConfig: {placeholder: ""}
    },
    email:          {
        label:         i18n.signUpEmail,
        elementConfig: {placeholder: ""},
        validation:    {required: true, isEmail: true}
    },
    password:       {
        label:                   i18n.signUpPassword1,
        elementConfig:           {type: "password", placeholder: ""},
        validation:              {required: true, minLength: 7, func: value => (/[A-Z]/.test(value) && /[a-z]/.test(value) && /[0-9]/.test(value))},
        validationErrors:        {minLength: i18n.signUpPasswordTooShort, func: i18n.signUpPasswordMinimumRequirements},
        showValidationErrors:    true,
        validationErrorLocation: isMobile ? "bottom" : "left",
        fullRevalidation:        true
    },
    repeatPassword: {
        label:                   i18n.signUpPassword2,
        elementConfig:           {type: "password", placeholder: ""},
        validation:              {required: true, func: (value, form) => (form && form.password.value === value)},
        validationErrors:        {func: i18n.signUpPasswordDontMatch},
        showValidationErrors:    true,
        validationErrorLocation: isMobile ? "bottom" : "left",
        fullRevalidation:        true
    }
};

const RegisterForm = props => {
    const dispatch                    = useDispatch();
    const loading                     = useSelector(state => state.auth.loading);
    const [formValues, setFormValues] = useState({});
    const [formValid, setFormValid]   = useState(false);
    const formRef                     = useRef();
    const handleChange                = (formValues, formValid) => {
        setFormValues(formValues);
        setFormValid(formValid);
    };
    const doSignUp                    = () => {
        if (!formValid) {
            dispatch(actions.messages.errorPush(i18n.errorForm));
            return;
        }
        props.signUp(formValues.name, formValues.email, formValues.password);
    }
    if (props.formRef) props.formRef(formRef);

    return (
        <Card className="card-login card-plain">
            <CardHeader className="text-center">
                {props.showLogo && <div className="logo-container"><img src={appRootPath + spiqleLogo} alt="now-logo"/></div>}
            </CardHeader>
            <CardBody>
                <Form
                    formData={signUpForm}
                    onSubmit={doSignUp}
                    onChange={handleChange}
                    values={formValues}
                    ref={ref => formRef.current = ref}
                    stacked
                    autoFocus
                    autoComplete
                    handleEnter
                />
            </CardBody>
            <CardFooter className="align-horizontal-center">
                <Button block color="info" size="lg" className="mb-3" round onClick={doSignUp}>
                    {i18n.signUpSubmitButton}
                </Button>
            </CardFooter>
            {props.showLinks && (
                <div className="login-bottom">
                    <Link to="/login" className="link footer-link">{i18n.signUpBackToLogin}</Link>
                </div>
            )}
            {loading ? (<Spinner/>) : null}
        </Card>
    );
};

RegisterForm.propTypes = {
    showLogo:  PropTypes.bool,
    showLinks: PropTypes.bool,
    signUp:    PropTypes.func.isRequired,
    formRef:   PropTypes.func
};

export default RegisterForm;
