export default {
    affiliates:   "Affiliates",
    noAffiliates: "Ingen affiliates",

    affiliatesTitle:             "Affiliates",
    affiliatesTableName:         "Navn",
    affiliatesTableCity:         "By",
    affiliatesTableCountry:      "Land",
    affiliatesTablePhone:        "Telefon",
    affiliatesTableEmail:        "E-mail",
    affiliatesTableMachines:     "Maskiner",
    affiliatesTableSent:         "Sendt",
    affiliatesTableSentShort:    "V",
    affiliatesTableCoupled:      "Koblet",
    affiliatesTableCoupledShort: "G",
    affiliatesActionsEdit:       "Rediger",
    affiliatesActionsDelete:     "Slet",
    affiliatesLoadError:         "Der opstod en fejl ved indlæsning af affiliates",

    affiliatesAddTitle:      "Tilføj affiliate",
    affiliatesEditTitle:     "Rediger affiliate",
    affiliatesEditSuccess:   "Affiliatens oplysninger er gemt korrekt",
    affiliatesEditError:     "Der opstod en fejl ved gemning af affiliatens oplysninger",
    affiliatesRemoveTitle:   "Er du sikker på, at du vil slette denne affiliate??",
    affiliatesRemoveBody:    "Hvis du sletter affiliate med navnet '%1', bliver alle maskiner, der i øjeblikket er koblet til denne affiliate, koblet til Spiqle. Dette kan ikke gøres om.",
    affiliatesRemoveSure:    "Slet affiliat",
    affiliatesRemoveSuccess: "Affiliaten er blevet slettet korrekt",
    affiliatesRemoveError:   "Der opstod en fejl ved sletning af affiliaten",

    affiliateName:    "Navn",
    affiliateAddress: "Adresse",
    affiliateZipcode: "Postnummer",
    affiliateCity:    "By",
    affiliateState:   "Provins",
    affiliateCountry: "Land",
    affiliatePhone:   "Telefonnummer",
    affiliateEmail:   "E-mail adresse",
};