import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Button, CardActions, Spinner} from "../../components";
import SelectLanguage from "./SelectLanguage";
import HelpItem from "./HelpItem";
import AddItem from "./AddItem";
import i18n from "../../i18n";
import * as actions from "../../store/actions";

const Help = props => {
    const [language, setLanguage] = useState(i18n.getLanguage());
    const dispatch                = useDispatch();
    const rights                  = useSelector(state => state.auth.rights);
    const helpItems               = useSelector(state => state.help.items);
    const loading                 = useSelector(state => state.help.loading);
    const saving                  = useSelector(state => state.help.saving);
    const enableEdit              = rights && rights.superAdmin;

    useEffect(() => {dispatch(actions.help.load(language))}, [dispatch, language]);

    return (
        <div>
            <div className="content">
                {enableEdit && <SelectLanguage onChange={language => setLanguage(language)}/>}
                {
                    !loading && !saving && helpItems ?
                    helpItems.map(helpItem => <HelpItem key={helpItem.uuid + "-" + helpItem.language} edit={enableEdit} {...helpItem} />) :
                    <Spinner/>
                }
                {enableEdit && <AddItem/>}
                <CardActions>
                    <Button round color="info" className="float-left" onClick={() => props.history.goBack()}>{i18n.buttonBack}</Button>
                </CardActions>
            </div>
        </div>
    );
};

export default Help;
