export default {
    routeCaretakers:            "Plejekrævende",
    routeLocations:             "Alle lokationer",
    routeAdmin:                 "Administration",
    routeAdminCaretakersFull:   "Plejekrævende",
    routeAdminCaretakersMini:   "Z",
    routeAdminAffiliatesFull:   "Affiliates",
    routeAdminAffiliatesMini:   "A",
    routeAdminDistributorsFull: "Distributører",
    routeAdminDistributorsMini: "D",
    routeAdminGroupsFull:       "Grupper",
    routeAdminGroupsMini:       "G",
    routeAdminUsersFull:        "Brugere",
    routeAdminUsersMini:        "G",
    routeAdminMachinesFull:     "Maskiner",
    routeAdminMachinesMini:     "M",
    routeTechnical:             "Teknisk",
    routeTechnicalStatusFull:   "Maskin status",
    routeTechnicalStatusMini:   "MS",
    routeTechnicalManualFull:   "HManuel opdatering",
    routeTechnicalManualMini:   "HU",
    routeTechnicalUpdatesFull:  "Firmware opdateringer",
    routeTechnicalUpdatesMini:  "FU",
    routeHelp:                  "Hjælp",
    routeAccount:               "Konto",
    routeContact:               "Kontakt",
    routeAbout:                 "Om",
    routeLogout:                "Log ud",
};