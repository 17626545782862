import {call, put, takeLatest} from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../actionTypes";
import * as api from "../../api";
import i18n from "../../i18n";

// Watcher
export function* watchContact() {
    yield takeLatest(actionTypes.CONTACT_SEND, sendSaga);
}

// Sagas
function* sendSaga(action) {
    try {
        yield call(api.contact.send, action.contactDetails);
        yield put(actions.contact.sendSuccess());
        yield put(actions.messages.successPush(i18n.successContactSend));
    }
    catch (err) {
        yield put(actions.contact.sendFail(err));
        yield put(actions.messages.errorPush(i18n.errorContactSend));
    }
}
