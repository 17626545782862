import {call, put, select, takeLatest} from "redux-saga/effects";
import {push} from "connected-react-router";
import * as actions from "../actions";
import * as actionTypes from "../actionTypes";
import * as api from "../../api";
import i18n from "../../i18n";

// Watcher
export function* watchUsers() {
    yield takeLatest(actionTypes.USERS_LOAD, loadSaga);
    yield takeLatest(actionTypes.USERS_LOAD_IF_NEEDED, loadIfNeededSaga);
    yield takeLatest(actionTypes.USERS_INFO_LOAD, loadInfoSaga);
    yield takeLatest(actionTypes.USERS_SAVE, saveSaga);
    yield takeLatest(actionTypes.USERS_REMOVE, removeSaga);
    yield takeLatest(actionTypes.USERS_RIGHT_ADD, rightAddSaga);
    yield takeLatest(actionTypes.USERS_RIGHT_REMOVE, rightRemoveSaga);
    yield takeLatest(actionTypes.USERS_DUPLICATE, duplicateSaga);
    yield takeLatest(actionTypes.USERS_COPY_RIGHTS, copyRightsSaga);
    yield takeLatest(actionTypes.USERS_DISABLE_2FA, disable2faSaga);
    yield takeLatest(actionTypes.USERS_LOGIN_AS, logInAsSaga);
}

// Sagas
function* loadSaga(action) {
    try {
        const response = yield call(api.users.load);
        yield put(actions.users.loadSuccess(response.data.users));
    }
    catch (err) {
        yield put(actions.users.loadFail());
    }
}

function* loadIfNeededSaga(action) {
    let users = yield select(state => state.users);
    if (!users.loading && !users.usersLoaded) {
        yield put(actions.users.load());
    }
}

function* loadInfoSaga(action) {
    try {
        const response = yield call(api.users.loadInfo, action.userId);
        yield put(actions.users.loadInfoSuccess(action.userId, response.data.userInfo));
    }
    catch (err) {
        yield put(actions.users.loadInfoFail());
        yield put(push("/admin/users"));
        yield put(actions.messages.errorPush(i18n.usersInfoLoadError));
    }
}

function* saveSaga(action) {
    try {
        yield call(api.users.save, action.uuid, action.userDetails);
        yield put(actions.users.saveSuccess());
        yield put(actions.users.load());
        yield put(push(action.redirect));
        if (action.uuid === "new") {
            yield put(actions.messages.successPush(i18n.usersAddSuccess));
        }
        else {
            yield put(actions.messages.successPush(i18n.usersEditSuccess));
        }
    }
    catch (err) {
        yield put(actions.users.saveFail());
        yield put(actions.messages.errorPush(i18n.usersEditError));
    }
}

function* removeSaga(action) {
    try {
        yield call(api.users.remove, action.uuid);
        yield put(actions.users.removeSuccess());
        yield put(actions.users.load());
        yield put(push("/admin/users"));
        yield put(actions.messages.successPush(i18n.usersRemoveSuccess));
    }
    catch (err) {
        yield put(actions.users.removeFail());
        yield put(actions.messages.errorPush(i18n.usersRemoveError));
    }
}

function* rightAddSaga(action) {
    try {
        yield call(api.users.rightAdd, action.userId, JSON.stringify(action.right));
        yield put(actions.users.rightAddSuccess());
        yield put(actions.users.loadInfo(action.userId));
    }
    catch (err) {
        yield put(actions.users.rightAddFail(err));
        yield put(actions.messages.errorPush(i18n.usersRemoveError));
    }
}

function* rightRemoveSaga(action) {
    try {
        yield call(api.users.rightRemove, action.rightId);
        yield put(actions.users.rightRemoveSuccess());
        yield put(actions.users.loadInfo(action.userId));
    }
    catch (err) {
        yield put(actions.users.rightRemoveFail(err));
        yield put(actions.messages.errorPush(i18n.usersRemoveError));
    }
}

function* duplicateSaga(action) {
    try {
        yield call(api.users.duplicate, action.uuid, action.userInfo);
        yield put(actions.users.duplicateSuccess());
        yield put(actions.users.load());
        //yield put(push("/admin/users"));
        yield put(actions.messages.successPush(i18n.usersDuplicateSuccess));
    }
    catch (err) {
        yield put(actions.users.duplicateFail(err));
        yield put(actions.messages.errorPush(i18n.usersDuplicateError));
    }
}

function* copyRightsSaga(action) {
    try {
        yield call(api.users.copyRights, action.fromUser, action.toUser, action.replace);
        yield put(actions.users.copyRightsSuccess());
        yield put(actions.messages.successPush(i18n.usersCopySuccess));
    }
    catch (err) {
        yield put(actions.users.copyRightsFail());
        yield put(actions.messages.errorPush(i18n.usersCopyError));
    }
}

function* disable2faSaga(action) {
    try {
        yield call(api.users.disable2fa, action.uuid);
        yield put(actions.users.disable2faSuccess());
        yield put(actions.users.loadInfo(action.uuid));
        yield put(actions.messages.successPush(i18n.usersTwoFactorDisableSuccess));
    }
    catch (err) {
        yield put(actions.users.disable2faFail());
        yield put(actions.messages.errorPush(i18n.usersTwoFactorDisableError));
    }
}

function* logInAsSaga(action) {
    try {
        const response = yield call(api.users.loginAs, action.uuid);
        yield put(actions.resetState());
        yield put(actions.auth.success(response.data));
        yield put(actions.auth.checkTimeout(response.data.expiresIn));
        yield put(push("/"));
    }
    catch (err) {
        yield put(actions.users.logInAsFail(err));
        yield put(actions.messages.errorPush(i18n.usersLogInAsFail));
    }
}