// React
import React from "react";

// Redux
import {connect} from "react-redux";
import {push} from 'connected-react-router'
import * as actions from "../../../../store/actions";

// Tools
import i18n from "../../../../i18n";

// Components
import {BottomBar, Button, Form, Spinner} from "../../../../components";
import {filterFormValues} from "../../../../components/Form/formTools";
import provinces from "../../../../i18n/provinces";
import {TitleWrapper} from "../../../../contexts/TitleProvider";

const caretakerForm = {
    name: {label: i18n.caretakerName},
    row1: {
        type:  "row",
        items: {
            address: {label: i18n.caretakerAddress, size: 8},
            zipcode: {label: i18n.caretakerZipcode, size: 4, validation: {required: true, minLength: 4}},
        }
    },
    row2: {
        type:  "row",
        items: {
            city:    {label: i18n.caretakerCity, size: 4},
            country: {label: i18n.caretakerCountry, size: 4, defaultValue: "Nederland", type: "select", options: i18n.countries},
            state:   {
                label:        i18n.caretakerState,
                size:         4,
                type:         "select",
                selectType:   "slave",
                slaveTo:      "country",
                defaultSlave: "default",
                options:      provinces,
                passValues:   true
            }
        }
    }
};

class Edit extends React.Component {
    state = {
        uuid:       null,
        caretaker:  null,
        formValues: {},
        formValid:  false
    };

    constructor(props) {
        super(props);
        this.state.uuid = props.match.params.caretaker;
        if (this.state.uuid.length !== 32) {
            props.push("/admin/caretakers");
            return;
        }

        if (props.caretakers) {
            this.state.caretaker  = this.props.caretakers.find(obj => obj.uuid === this.state.uuid);
            this.state.formValues = filterFormValues(caretakerForm, this.state.caretaker);
        } else {
            if (!props.loading) {
                props.load();
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props === prevProps) {
            return;
        }

        // If the caretaker store suddenly changed, update the details
        if (this.props.caretakers && this.props.caretakers !== prevProps.caretakers && this.props.caretakers.length > 0) {
            let uuid      = this.props.match.params.caretaker;
            let caretaker = this.props.caretakers.find(obj => obj.uuid === uuid);
            if (caretaker) {
                this.setState({caretaker, formValues: filterFormValues(caretakerForm, caretaker)});
            } else {
                // Unknown caretaker, go back to caretaker overview
                this.props.push("/admin/caretakers");
            }
        }
    }

    handleChange = (formValues, formValid) => this.setState({formValues, formValid});
    handleValid  = formValid => this.setState({formValid});
    handleSubmit = () => this.state.formValid && this.props.save(this.state.uuid, this.state.formValues);

    render() {
        return (
            <TitleWrapper title={i18n.caretakersItemEdit}>
                <div>
                    <div className="content">
                        {this.state.caretaker && !this.props.loading && !this.props.saving ? (
                            <>
                                <Form
                                    formData={caretakerForm}
                                    values={this.state.formValues}
                                    onChange={this.handleChange}
                                    onValid={this.handleValid}
                                    stacked
                                />
                                <Button round color="info" block disabled={!this.state.formValid} onClick={this.handleSubmit}>
                                    {i18n.buttonSave}
                                </Button>
                            </>
                        ) : <Spinner/>}
                        <BottomBar backButton />
                    </div>
                </div>
            </TitleWrapper>
        );
    }
}

const mapStateToProps = state => ({
    loading:    state.caretakers.loading,
    saving:     state.caretakers.saving,
    caretakers: state.caretakers.caretakers
});

const mapDispatchToProps = dispatch => ({
    push: pathname => dispatch(push(pathname)),
    load: () => dispatch(actions.caretakers.load()),
    save: (uuid, details) => dispatch(actions.caretakers.save(uuid, details, "/admin/caretakers"))
});

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
