export default {
    "United States": {
        "Alabama":                              "Alabama",
        "Alaska":                               "Alaska",
        "Arizona":                              "Arizona",
        "Arkansas":                             "Arkansas",
        "California":                           "California",
        "Colorado":                             "Colorado",
        "Connecticut":                          "Connecticut",
        "District of Columbia":                 "District of Columbia",
        "Delaware":                             "Delaware",
        "Florida":                              "Florida",
        "Georgia":                              "Georgia",
        "Hawaii":                               "Hawaii",
        "Idaho":                                "Idaho",
        "Illinois":                             "Illinois",
        "Indiana":                              "Indiana",
        "Iowa":                                 "Iowa",
        "Kansas":                               "Kansas",
        "Kentucky":                             "Kentucky",
        "Louisiana":                            "Louisiana",
        "Maine":                                "Maine",
        "Maryland":                             "Maryland",
        "Massachusetts":                        "Massachusetts",
        "Michigan":                             "Michigan",
        "Minnesota":                            "Minnesota",
        "Mississippi":                          "Mississippi",
        "Missouri":                             "Missouri",
        "Montana":                              "Montana",
        "Nebraska":                             "Nebraska",
        "Nevada":                               "Nevada",
        "New Hampshire":                        "New Hampshire",
        "New Jersey":                           "New Jersey",
        "New Mexico":                           "New Mexico",
        "New York":                             "New York",
        "North Carolina":                       "North Carolina",
        "North Dakota":                         "North Dakota",
        "Ohio":                                 "Ohio",
        "Oklahoma":                             "Oklahoma",
        "Oregon":                               "Oregon",
        "Pennsylvania":                         "Pennsylvania",
        "Rhode Island":                         "Rhode Island",
        "South Carolina":                       "South Carolina",
        "South Dakota":                         "South Dakota",
        "Tennessee":                            "Tennessee",
        "Texas":                                "Texas",
        "Utah":                                 "Utah",
        "Vermont":                              "Vermont",
        "Virginia":                             "Virginia",
        "Washington":                           "Washington",
        "West Virginia":                        "West Virginia",
        "Wisconsin":                            "Wisconsin",
        "Wyoming":                              "Wyoming",
        "American Samoa":                       "American Samoa",
        "Guam":                                 "Guam",
        "Northern Mariana Islands":             "Northern Mariana Islands",
        "Puerto Rico":                          "Puerto Rico",
        "United States Minor Outlying Islands": "United States Minor Outlying Islands",
        "Virgin Islands":                       "Virgin Islands"
    },
    "Canada":        {
        "Alberta":                   "Alberta",
        "British Columbia":          "British Columbia",
        "Manitoba":                  "Manitoba",
        "New Brunswick":             "New Brunswick",
        "Newfoundland and Labrador": "Newfoundland and Labrador",
        "Nova Scotia":               "Nova Scotia",
        "Nunavut":                   "Nunavut",
        "Northwest Territories":     "Northwest Territories",
        "Ontario":                   "Ontario",
        "Prince Edward Island":      "Prince Edward Island",
        "Quebec":                    "Quebec",
        "Saskatchewan":              "Saskatchewan",
        "Yukon":                     "Yukon"
    },
    "Australia":     {
        "Ashmore and Cartier Islands":       "Ashmore and Cartier Islands",
        "Australian Antarctic Territory":    "Australian Antarctic Territory",
        "Australian Capital Territory":      "Australian Capital Territory",
        "Christmas Island":                  "Christmas Island",
        "Cocos Islands":                     "Cocos Islands",
        "Coral Sea Islands":                 "Coral Sea Islands",
        "Heard Island and McDonald Islands": "Heard Island and McDonald Islands",
        "Jervis Bay Territory":              "Jervis Bay Territory",
        "New South Wales":                   "New South Wales",
        "Norfolk Island":                    "Norfolk Island",
        "Northern Territory":                "Northern Territory",
        "Queensland":                        "Queensland",
        "South Australia":                   "South Australia",
        "Tasmania":                          "Tasmania",
        "Victoria":                          "Victoria",
        "Western Australia":                 "Western Australia"
    },
    "Mexico":        {
        "Aguascalientes":      "Aguascalientes",
        "Baja California":     "Baja California",
        "Baja California Sur": "Baja California Sur",
        "Campeche":            "Campeche",
        "Chiapas":             "Chiapas",
        "Chihuahua":           "Chihuahua",
        "Coahuila":            "Coahuila",
        "Colima":              "Colima",
        "Federal District":    "Federal District",
        "Durango":             "Durango",
        "Guanajuato":          "Guanajuato",
        "Guerrero":            "Guerrero",
        "Hidalgo":             "Hidalgo",
        "Jalisco":             "Jalisco",
        "Mexico":              "Mexico",
        "Michoacán":           "Michoacán",
        "Morelos":             "Morelos",
        "Nayarit":             "Nayarit",
        "Nuevo León":          "Nuevo León",
        "Oaxaca":              "Oaxaca",
        "Puebla":              "Puebla",
        "Querétaro":           "Querétaro",
        "Quintana Roo":        "Quintana Roo",
        "San Luis Potosí":     "San Luis Potosí",
        "Sinaloa":             "Sinaloa",
        "Sonora":              "Sonora",
        "Tabasco":             "Tabasco",
        "Tamaulipas":          "Tamaulipas",
        "Tlaxcala":            "Tlaxcala",
        "Veracruz":            "Veracruz",
        "Yucatán":             "Yucatán",
        "Zacatecas":           "Zacatecas"
    },
    "China":         {
        "重庆":  "重庆",
        "黑龙江": "黑龙江",
        "吉林":  "吉林",
        "海南":  "海南",
        "北京":  "北京",
        "辽宁":  "辽宁",
        "内蒙古": "内蒙古",
        "西藏":  "西藏",
        "青海":  "青海",
        "宁夏":  "宁夏",
        "新疆":  "新疆",
        "甘肃":  "甘肃",
        "河北":  "河北",
        "河南":  "河南",
        "湖北":  "湖北",
        "湖南":  "湖南",
        "山东":  "山东",
        "江苏":  "江苏",
        "安徽":  "安徽",
        "山西":  "山西",
        "陕西":  "陕西",
        "四川":  "四川",
        "云南":  "云南",
        "贵州":  "贵州",
        "浙江":  "浙江",
        "福建":  "福建",
        "广西":  "广西",
        "上海":  "上海",
        "天津":  "天津",
        "香港":  "香港",
        "澳门":  "澳门",
        "台湾":  "台湾",
        "江西":  "江西",
        "广东":  "广东"
    },
    "Great Britain": {
        "Avon":                     "Avon",
        "Bedfordshire":             "Bedfordshire",
        "Berkshire":                "Berkshire",
        "Borders":                  "Borders",
        "Bristol":                  "Bristol",
        "Buckinghamshire":          "Buckinghamshire",
        "Cambridgeshire":           "Cambridgeshire",
        "Channel Islands":          "Channel Islands",
        "Cheshire":                 "Cheshire",
        "Cleveland":                "Cleveland",
        "Cornwall":                 "Cornwall",
        "Cumbria":                  "Cumbria",
        "Derbyshire":               "Derbyshire",
        "Devon":                    "Devon",
        "Dorset":                   "Dorset",
        "Durham":                   "Durham",
        "East Riding of Yorkshire": "East Riding of Yorkshire",
        "East Sussex":              "East Sussex",
        "Essex":                    "Essex",
        "Gloucestershire":          "Gloucestershire",
        "Greater Manchester":       "Greater Manchester",
        "Hampshire":                "Hampshire",
        "Herefordshire":            "Herefordshire",
        "Hertfordshire":            "Hertfordshire",
        "Humberside":               "Humberside",
        "Isle of Man":              "Isle of Man",
        "Isle of Wight":            "Isle of Wight",
        "Isles of Scilly":          "Isles of Scilly",
        "Kent":                     "Kent",
        "Lancashire":               "Lancashire",
        "Leicestershire":           "Leicestershire",
        "Lincolnshire":             "Lincolnshire",
        "London":                   "London",
        "Merseyside":               "Merseyside",
        "Middlesex":                "Middlesex",
        "Norfolk":                  "Norfolk",
        "North Yorkshire":          "North Yorkshire",
        "Northamptonshire":         "Northamptonshire",
        "Northumberland":           "Northumberland",
        "Nottinghamshire":          "Nottinghamshire",
        "Oxfordshire":              "Oxfordshire",
        "Rutland":                  "Rutland",
        "Shropshire":               "Shropshire",
        "Somerset":                 "Somerset",
        "South Yorkshire":          "South Yorkshire",
        "Staffordshire":            "Staffordshire",
        "Suffolk":                  "Suffolk",
        "Surrey":                   "Surrey",
        "Tyne and Wear":            "Tyne and Wear",
        "Warwickshire":             "Warwickshire",
        "West Midlands":            "West Midlands",
        "West Sussex":              "West Sussex",
        "West Yorkshire":           "West Yorkshire",
        "Wiltshire":                "Wiltshire",
        "Worcestershire":           "Worcestershire",
        "Antrim":                   "Antrim",
        "Down":                     "Down",
        "Fermanagh":                "Fermanagh",
        "Londonderry":              "Londonderry",
        "Tyrone":                   "Tyrone",
        "Aberdeen City":            "Aberdeen City",
        "Aberdeenshire":            "Aberdeenshire",
        "Angus":                    "Angus",
        "Argyll and Bute":          "Argyll and Bute",
        "Armagh":                   "Armagh",
        "Carmarthenshire":          "Carmarthenshire",
        "Clackmannan":              "Clackmannan",
        "Dumfries and Galloway":    "Dumfries and Galloway",
        "East Ayrshire":            "East Ayrshire",
        "East Dunbartonshire":      "East Dunbartonshire",
        "East Lothian":             "East Lothian",
        "East Renfrewshire":        "East Renfrewshire",
        "Edinburgh City":           "Edinburgh City",
        "Falkirk":                  "Falkirk",
        "Fife":                     "Fife",
        "Glasgow":                  "Glasgow",
        "Highland":                 "Highland",
        "Inverclyde":               "Inverclyde",
        "Midlothian":               "Midlothian",
        "Moray":                    "Moray",
        "North Ayrshire":           "North Ayrshire",
        "North Lanarkshire":        "North Lanarkshire",
        "Orkney":                   "Orkney",
        "Perthshire and Kinross":   "Perthshire and Kinross",
        "Renfrewshire":             "Renfrewshire",
        "Roxburghshire":            "Roxburghshire",
        "Shetland":                 "Shetland",
        "South Ayrshire":           "South Ayrshire",
        "South Lanarkshire":        "South Lanarkshire",
        "Stirling":                 "Stirling",
        "West Dunbartonshire":      "West Dunbartonshire",
        "West Lothian":             "West Lothian",
        "Western Isles":            "Western Isles",
        "Blaenau Gwent":            "Blaenau Gwent",
        "Bridgend":                 "Bridgend",
        "Caerphilly":               "Caerphilly",
        "Cardiff":                  "Cardiff",
        "Ceredigion":               "Ceredigion",
        "Conwy":                    "Conwy",
        "Denbighshire":             "Denbighshire",
        "Flintshire":               "Flintshire",
        "Gwynedd":                  "Gwynedd",
        "Isle of Anglesey":         "Isle of Anglesey",
        "Merthyr Tydfil":           "Merthyr Tydfil",
        "Monmouthshire":            "Monmouthshire",
        "Neath Port Talbot":        "Neath Port Talbot",
        "Newport":                  "Newport",
        "Pembrokeshire":            "Pembrokeshire",
        "Powys":                    "Powys",
        "Rhondda Cynon Taff":       "Rhondda Cynon Taff",
        "Swansea":                  "Swansea",
        "The Vale of Glamorgan":    "The Vale of Glamorgan",
        "Torfaen":                  "Torfaen",
        "Wrexham":                  "Wrexham"
    },
    "Deutschland":   {
        "Baden-Württemberg":      "Baden-Württemberg",
        "Bayern":                 "Bayern",
        "Berlin":                 "Berlin",
        "Brandenburg":            "Brandenburg",
        "Bremen":                 "Bremen",
        "Hamburg":                "Hamburg",
        "Hessen":                 "Hessen",
        "Mecklenburg-Vorpommern": "Mecklenburg-Vorpommern",
        "Niedersachsen":          "Niedersachsen",
        "Nordrhein-Westfalen":    "Nordrhein-Westfalen",
        "Rheinland-Pfalz":        "Rheinland-Pfalz",
        "Saarland":               "Saarland",
        "Sachsen":                "Sachsen",
        "Sachsen-Anhalt":         "Sachsen-Anhalt",
        "Schleswig-Holstein":     "Schleswig-Holstein",
        "Thüringen":              "Thüringen"
    },
    "Nederland":     {
        "Drenthe":       "Drenthe",
        "Flevoland":     "Flevoland",
        "Friesland":     "Friesland",
        "Gelderland":    "Gelderland",
        "Groningen":     "Groningen",
        "Limburg":       "Limburg",
        "Noord-Brabant": "Noord-Brabant",
        "Noord-Holland": "Noord-Holland",
        "Overijssel":    "Overijssel",
        "Utrecht":       "Utrecht",
        "Zuid-Holland":  "Zuid-Holland",
        "Zeeland":       "Zeeland"
    },
    "België":        {
        "Antwerpen":       "Antwerpen",
        "Henegouwen":      "Henegouwen",
        "Luik":            "Luik",
        "Limburg":         "Limburg",
        "Luxemburg":       "Luxemburg",
        "Namen":           "Namen",
        "Oost-Vlaanderen": "Oost-Vlaanderen",
        "Vlaams-Brabant":  "Vlaams-Brabant",
        "Waals-Brabant":   "Waals-Brabant",
        "West-Vlaanderen": "West-Vlaanderen"
    },
    "Danmark":       {
        "Hovedstaden": "Hovedstaden",
        "Midtjylland": "Midtjylland",
        "Nordjylland": "Nordjylland",
        "Sjælland":    "Sjælland",
        "Syddanmark":  "Syddanmark"
    },
    "Türkiye":       {
        "Adana":          "Adana",
        "Adıyaman":       "Adıyaman",
        "Afyonkarahisar": "Afyonkarahisar",
        "Ağrı":           "Ağrı",
        "Amasya":         "Amasya",
        "Ankara":         "Ankara",
        "Antalya":        "Antalya",
        "Artvin":         "Artvin",
        "Aydın":          "Aydın",
        "Balıkesir":      "Balıkesir",
        "Bilecik":        "Bilecik",
        "Bingöl":         "Bingöl",
        "Bitlis":         "Bitlis",
        "Bolu":           "Bolu",
        "Burdur":         "Burdur",
        "Bursa":          "Bursa",
        "Çanakkale":      "Çanakkale",
        "Çankırı":        "Çankırı",
        "Çorum":          "Çorum",
        "Denizli":        "Denizli",
        "Diyarbakır":     "Diyarbakır",
        "Edirne":         "Edirne",
        "Elazığ":         "Elazığ",
        "Erzincan":       "Erzincan",
        "Erzurum":        "Erzurum",
        "Eskişehir":      "Eskişehir",
        "Gaziantep":      "Gaziantep",
        "Giresun":        "Giresun",
        "Gümüşhane":      "Gümüşhane",
        "Hakkâri":        "Hakkâri",
        "Hatay":          "Hatay",
        "Isparta":        "Isparta",
        "Mersin":         "Mersin",
        "Istanbul":       "Istanbul",
        "İzmir":          "İzmir",
        "Kars":           "Kars",
        "Kastamonu":      "Kastamonu",
        "Kayseri":        "Kayseri",
        "Kırklareli":     "Kırklareli",
        "Kırşehir":       "Kırşehir",
        "Kocaeli":        "Kocaeli",
        "Konya":          "Konya",
        "Kütahya":        "Kütahya",
        "Malatya":        "Malatya",
        "Manisa":         "Manisa",
        "Kahramanmaraş":  "Kahramanmaraş",
        "Mardin":         "Mardin",
        "Muğla":          "Muğla",
        "Muş":            "Muş",
        "Nevşehir":       "Nevşehir",
        "Niğde":          "Niğde",
        "Ordu":           "Ordu",
        "Rize":           "Rize",
        "Sakarya":        "Sakarya",
        "Samsun":         "Samsun",
        "Siirt":          "Siirt",
        "Sinop":          "Sinop",
        "Sivas":          "Sivas",
        "Tekirdağ":       "Tekirdağ",
        "Tokat":          "Tokat",
        "Trabzon":        "Trabzon",
        "Tunceli":        "Tunceli",
        "Şanlıurfa":      "Şanlıurfa",
        "Uşak":           "Uşak",
        "Van":            "Van",
        "Yozgat":         "Yozgat",
        "Zonguldak":      "Zonguldak",
        "Aksaray":        "Aksaray",
        "Bayburt":        "Bayburt",
        "Karaman":        "Karaman",
        "Kırıkkale":      "Kırıkkale",
        "Batman":         "Batman",
        "Şırnak":         "Şırnak",
        "Bartın":         "Bartın",
        "Ardahan":        "Ardahan",
        "Iğdır":          "Iğdır",
        "Yalova":         "Yalova",
        "Karabük":        "Karabük",
        "Kilis":          "Kilis",
        "Osmaniye":       "Osmaniye",
        "Düzce":          "Düzce"
    },
    "India":         {
        "Special Region of Aceh":         "Special Region of Aceh",
        "Bali":                           "Bali",
        "Bangka–Belitung Islands":        "Bangka–Belitung Islands",
        "Banten":                         "Banten",
        "Bengkulu":                       "Bengkulu",
        "Central Java":                   "Central Java",
        "Central Kalimantan":             "Central Kalimantan",
        "Central Sulawesi":               "Central Sulawesi",
        "East Java":                      "East Java",
        "East Kalimantan":                "East Kalimantan",
        "East Nusa Tenggara":             "East Nusa Tenggara",
        "Gorontalo":                      "Gorontalo",
        "Jakarta Special Capital Region": "Jakarta Special Capital Region",
        "Jambi":                          "Jambi",
        "Lampung":                        "Lampung",
        "Maluku":                         "Maluku",
        "North Kalimantan":               "North Kalimantan",
        "North Maluku":                   "North Maluku",
        "North Sulawesi":                 "North Sulawesi",
        "North Sumatra":                  "North Sumatra",
        "Special Region of Papua":        "Special Region of Papua",
        "Riau":                           "Riau",
        "Riau Islands":                   "Riau Islands",
        "Southeast Sulawesi":             "Southeast Sulawesi",
        "South Kalimantan":               "South Kalimantan",
        "South Sulawesi":                 "South Sulawesi",
        "South Sumatra":                  "South Sumatra",
        "West Java":                      "West Java",
        "West Kalimantan":                "West Kalimantan",
        "West Nusa Tenggara":             "West Nusa Tenggara",
        "Special Region of West Papua":   "Special Region of West Papua",
        "West Sulawesi":                  "West Sulawesi",
        "West Sumatra":                   "West Sumatra",
        "Special Region of Yogyakarta":   "Special Region of Yogyakarta"
    },
    "Jordan":        {
        "Irbid":   "Irbid",
        "Ajloun":  "Ajloun",
        "Jerash":  "Jerash",
        "Mafraq":  "Mafraq",
        "Balqa":   "Balqa",
        "Amman":   "Amman",
        "Zarqa":   "Zarqa",
        "Madaba":  "Madaba",
        "Karak":   "Karak",
        "Tafilah": "Tafilah",
        "Ma'an":   "Ma'an",
        "Aqaba":   "Aqaba"
    },
    "Indonesia":     {
        "Andhra Pradesh":                      "Andhra Pradesh",
        "Arunachal Pradesh":                   "Arunachal Pradesh",
        "Assam":                               "Assam",
        "Bihar":                               "Bihar",
        "Chhattisgarh":                        "Chhattisgarh",
        "Goa":                                 "Goa",
        "Gujarat":                             "Gujarat",
        "Haryana":                             "Haryana",
        "Himachal Pradesh":                    "Himachal Pradesh",
        "Jammu and Kashmir":                   "Jammu and Kashmir",
        "Jharkhand":                           "Jharkhand",
        "Karnataka":                           "Karnataka",
        "Kerala":                              "Kerala",
        "Madhya Pradesh":                      "Madhya Pradesh",
        "Maharashtra":                         "Maharashtra",
        "Manipur":                             "Manipur",
        "Meghalaya":                           "Meghalaya",
        "Mizoram":                             "Mizoram",
        "Nagaland":                            "Nagaland",
        "Odisha":                              "Odisha",
        "Punjab":                              "Punjab",
        "Rajasthan":                           "Rajasthan",
        "Sikkim":                              "Sikkim",
        "Tamil Nadu":                          "Tamil Nadu",
        "Telangana":                           "Telangana",
        "Tripura":                             "Tripura",
        "Uttar Pradesh":                       "Uttar Pradesh",
        "Uttarakhand":                         "Uttarakhand",
        "West Bengal":                         "West Bengal",
        "Andaman and Nicobar Islands":         "Andaman and Nicobar Islands",
        "Chandigarh":                          "Chandigarh",
        "Dadra and Nagar Haveli":              "Dadra and Nagar Haveli",
        "Daman and Diu":                       "Daman and Diu",
        "Lakshadweep":                         "Lakshadweep",
        "National Capital Territory of Delhi": "National Capital Territory of Delhi",
        "Puducherry":                          "Puducherry"
    },
    "KH":            {
        "ភ្នំពេញ":      "ភ្នំពេញ",
        "បន្ទាយមានជ័យ": "បន្ទាយមានជ័យ",
        "បាត់ដំបង":     "បាត់ដំបង",
        "កំពង់ចាម":     "កំពង់ចាម",
        "កំពង់ឆ្នាំង":  "កំពង់ឆ្នាំង",
        "កំពង់ស្ពឺ":    "កំពង់ស្ពឺ",
        "កំពង់ធំ":      "កំពង់ធំ",
        "កំពត":         "កំពត",
        "កណ្តាល":       "កណ្តាល",
        "កោះកុង":       "កោះកុង",
        "កែប":          "កែប",
        "ក្រចេះ":       "ក្រចេះ",
        "មណ្ឌលគីរី":    "មណ្ឌលគីរី",
        "ឧត្តរមានជ័យ":  "ឧត្តរមានជ័យ",
        "បៃលិន":        "បៃលិន",
        "ព្រះសីហនុ":    "ព្រះសីហនុ",
        "ព្រះវិហារ":    "ព្រះវិហារ",
        "ពោធិ៍សាត់":    "ពោធិ៍សាត់",
        "ព្រៃវែង":      "ព្រៃវែង",
        "រតនគីរី":      "រតនគីរី",
        "សៀមរាប":       "សៀមរាប",
        "ស្ទឹងត្រែង":   "ស្ទឹងត្រែង",
        "ស្វាយរៀង":     "ស្វាយរៀង",
        "តាកែវ":        "តាកែវ",
        "ត្បូងឃ្មុំ":   "ត្បូងឃ្មុំ"
    },
    "ET":            {
        "Addis Ababa":                                          "Addis Ababa",
        "Afar Region":                                          "Afar Region",
        "Amhara Region":                                        "Amhara Region",
        "Benishangul-Gumuz":                                    "Benishangul-Gumuz",
        "Dire Dawa":                                            "Dire Dawa",
        "Gambela":                                              "Gambela",
        "Harari":                                               "Harari",
        "Oromia":                                               "Oromia",
        "Somali":                                               "Somali",
        "Southern Nations, Nationalities, and Peoples' Region": "Southern Nations, Nationalities, and Peoples' Region",
        "Tigray Region":                                        "Tigray Region"
    },
    "Peru":          {
        "Chachapoyas":               "Chachapoyas",
        "Bagua":                     "Bagua",
        "Bongará":                   "Bongará",
        "Condorcanqui":              "Condorcanqui",
        "Luya":                      "Luya",
        "Rodríguez de Mendoza":      "Rodríguez de Mendoza",
        "Utcubamba":                 "Utcubamba",
        "Huaraz":                    "Huaraz",
        "Aija":                      "Aija",
        "Antonio Raymondi":          "Antonio Raymondi",
        "Asunción":                  "Asunción",
        "Bolognesi":                 "Bolognesi",
        "Carhuaz":                   "Carhuaz",
        "Carlos Fermín Fitzcarrald": "Carlos Fermín Fitzcarrald",
        "Casma":                     "Casma",
        "Corongo":                   "Corongo",
        "Huari":                     "Huari",
        "Huarmey":                   "Huarmey",
        "Huaylas":                   "Huaylas",
        "Mariscal Luzuriaga":        "Mariscal Luzuriaga",
        "Ocros":                     "Ocros",
        "Pallasca":                  "Pallasca",
        "Pomabamba":                 "Pomabamba",
        "Recuay":                    "Recuay",
        "Santa":                     "Santa",
        "Sihuas":                    "Sihuas",
        "Yungay":                    "Yungay",
        "Abancay":                   "Abancay",
        "Andahuaylas":               "Andahuaylas",
        "Antabamba":                 "Antabamba",
        "Aymaraes":                  "Aymaraes",
        "Cotabambas":                "Cotabambas",
        "Chincheros":                "Chincheros",
        "Grau":                      "Grau",
        "Arequipa":                  "Arequipa",
        "Camaná":                    "Camaná",
        "Caravelí":                  "Caravelí",
        "Castilla":                  "Castilla",
        "Caylloma":                  "Caylloma",
        "Condesuyos":                "Condesuyos",
        "Islay":                     "Islay",
        "La Unión":                  "La Unión",
        "Huamanga":                  "Huamanga",
        "Cangallo":                  "Cangallo",
        "Huanca Sancos":             "Huanca Sancos",
        "Huanta":                    "Huanta",
        "La Mar":                    "La Mar",
        "Lucanas":                   "Lucanas",
        "Parinacochas":              "Parinacochas",
        "Páucar del Sara Sara":      "Páucar del Sara Sara",
        "Sucre":                     "Sucre",
        "Víctor Fajardo":            "Víctor Fajardo",
        "Vilcas Huamán":             "Vilcas Huamán",
        "Cajamarca":                 "Cajamarca",
        "Cajabamba":                 "Cajabamba",
        "Celendín":                  "Celendín",
        "Chota":                     "Chota",
        "Contumazá":                 "Contumazá",
        "Cutervo":                   "Cutervo",
        "Hualgayoc":                 "Hualgayoc",
        "Jaén":                      "Jaén",
        "San Ignacio":               "San Ignacio",
        "San Marcos":                "San Marcos",
        "San Miguel":                "San Miguel",
        "San Pablo":                 "San Pablo",
        "Santa Cruz":                "Santa Cruz",
        "Callao":                    "Callao",
        "Cusco":                     "Cusco",
        "Acomayo":                   "Acomayo",
        "Anta":                      "Anta",
        "Calca":                     "Calca",
        "Canas":                     "Canas",
        "Canchis":                   "Canchis",
        "Chumbivilcas":              "Chumbivilcas",
        "Espinar":                   "Espinar",
        "La Convención":             "La Convención",
        "Paruro":                    "Paruro",
        "Paucartambo":               "Paucartambo",
        "Quispicanchi":              "Quispicanchi",
        "Urubamba":                  "Urubamba",
        "Huancavelica":              "Huancavelica",
        "Acobamba":                  "Acobamba",
        "Angaraes":                  "Angaraes",
        "Castrovirreyna":            "Castrovirreyna",
        "Churcampa":                 "Churcampa",
        "Huaytará":                  "Huaytará",
        "Tayacaja":                  "Tayacaja",
        "Huánuco":                   "Huánuco",
        "Ambo":                      "Ambo",
        "Dos de Mayo":               "Dos de Mayo",
        "Huacaybamba":               "Huacaybamba",
        "Huamalíes":                 "Huamalíes",
        "Leoncio Prado":             "Leoncio Prado",
        "Marañón":                   "Marañón",
        "Pachitea":                  "Pachitea",
        "Puerto Inca":               "Puerto Inca",
        "Lauricocha":                "Lauricocha",
        "Yarowilca":                 "Yarowilca",
        "Ica":                       "Ica",
        "Chincha":                   "Chincha",
        "Nazca":                     "Nazca",
        "Palpa":                     "Palpa",
        "Pisco":                     "Pisco",
        "Huancayo":                  "Huancayo",
        "Concepción":                "Concepción",
        "Chanchamayo":               "Chanchamayo",
        "Jauja":                     "Jauja",
        "Junín":                     "Junín",
        "Satipo":                    "Satipo",
        "Tarma":                     "Tarma",
        "Yauli":                     "Yauli",
        "Chupaca":                   "Chupaca",
        "Trujillo":                  "Trujillo",
        "Ascope":                    "Ascope",
        "Bolívar":                   "Bolívar",
        "Chepén":                    "Chepén",
        "Julcán":                    "Julcán",
        "Otuzco":                    "Otuzco",
        "Pacasmayo":                 "Pacasmayo",
        "Pataz":                     "Pataz",
        "Sánchez Carrión":           "Sánchez Carrión",
        "Santiago de Chuco":         "Santiago de Chuco",
        "Gran Chimú":                "Gran Chimú",
        "Virú":                      "Virú",
        "Chiclayo":                  "Chiclayo",
        "Ferreñafe":                 "Ferreñafe",
        "Lambayeque":                "Lambayeque",
        "Lima":                      "Lima",
        "Huaura":                    "Huaura",
        "Barranca":                  "Barranca",
        "Cajatambo":                 "Cajatambo",
        "Canta":                     "Canta",
        "Cañete":                    "Cañete",
        "Huaral":                    "Huaral",
        "Huarochirí":                "Huarochirí",
        "Oyón":                      "Oyón",
        "Yauyos":                    "Yauyos",
        "Maynas":                    "Maynas",
        "Alto Amazonas":             "Alto Amazonas",
        "Loreto":                    "Loreto",
        "Mariscal Ramón Castilla":   "Mariscal Ramón Castilla",
        "Putumayo":                  "Putumayo",
        "Requena":                   "Requena",
        "Ucayali":                   "Ucayali",
        "Datem del Marañón":         "Datem del Marañón",
        "Tambopata":                 "Tambopata",
        "Manú":                      "Manú",
        "Tahuamanu":                 "Tahuamanu",
        "Mariscal Nieto":            "Mariscal Nieto",
        "General Sánchez Cerro":     "General Sánchez Cerro",
        "Ilo":                       "Ilo",
        "Pasco":                     "Pasco",
        "Daniel Alcídes Carrión":    "Daniel Alcídes Carrión",
        "Oxapampa":                  "Oxapampa",
        "Piura":                     "Piura",
        "Ayabaca":                   "Ayabaca",
        "Huancabamba":               "Huancabamba",
        "Morropón":                  "Morropón",
        "Paita":                     "Paita",
        "Sullana":                   "Sullana",
        "Talara":                    "Talara",
        "Sechura":                   "Sechura",
        "Puno":                      "Puno",
        "Azángaro":                  "Azángaro",
        "Carabaya":                  "Carabaya",
        "Chucuito":                  "Chucuito",
        "El Collao":                 "El Collao",
        "Huancané":                  "Huancané",
        "Lampa":                     "Lampa",
        "Melgar":                    "Melgar",
        "Moho":                      "Moho",
        "San Antonio de Putina":     "San Antonio de Putina",
        "San Román":                 "San Román",
        "Sandia":                    "Sandia",
        "Yunguyo":                   "Yunguyo",
        "Moyobamba":                 "Moyobamba",
        "Bellavista":                "Bellavista",
        "El Dorado":                 "El Dorado",
        "Huallaga":                  "Huallaga",
        "Lamas":                     "Lamas",
        "Mariscal Cáceres":          "Mariscal Cáceres",
        "Picota":                    "Picota",
        "Rioja":                     "Rioja",
        "San Martín":                "San Martín",
        "Tocache":                   "Tocache",
        "Tacna":                     "Tacna",
        "Candarave":                 "Candarave",
        "Jorge Basadre":             "Jorge Basadre",
        "Tarata":                    "Tarata",
        "Tumbes":                    "Tumbes",
        "Contralmirante Villar":     "Contralmirante Villar",
        "Zarumilla":                 "Zarumilla",
        "Coronel Portillo":          "Coronel Portillo",
        "Atalaya":                   "Atalaya",
        "Padre Abad":                "Padre Abad",
        "Purús":                     "Purús"
    },
    "Cuba":          {
        "Camagüey":              "Camagüey",
        "Ciego de Ávila":        "Ciego de Ávila",
        "Cienfuegos":            "Cienfuegos",
        "Havana":                "Havana",
        "Bayamo":                "Bayamo",
        "Guantánamo":            "Guantánamo",
        "Holguín":               "Holguín",
        "Nueva Gerona":          "Nueva Gerona",
        "Artemisa":              "Artemisa",
        "Las Tunas":             "Las Tunas",
        "Matanzas":              "Matanzas",
        "San José de las Lajas": "San José de las Lajas",
        "Pinar del Río":         "Pinar del Río",
        "Sancti Spíritus":       "Sancti Spíritus",
        "Santiago de Cuba":      "Santiago de Cuba",
        "Santa Clara":           "Santa Clara"
    },
    "Argentina":     {
        "Ciudad Autónoma de Buenos Aires":                       "Ciudad Autónoma de Buenos Aires",
        "Buenos Aires":                                          "Buenos Aires",
        "Catamarca":                                             "Catamarca",
        "Chaco":                                                 "Chaco",
        "Chubut":                                                "Chubut",
        "Córdoba":                                               "Córdoba",
        "Corrientes":                                            "Corrientes",
        "Entre Ríos":                                            "Entre Ríos",
        "Formosa":                                               "Formosa",
        "Jujuy":                                                 "Jujuy",
        "La Pampa":                                              "La Pampa",
        "La Rioja":                                              "La Rioja",
        "Mendoza":                                               "Mendoza",
        "Misiones":                                              "Misiones",
        "Neuquén":                                               "Neuquén",
        "Río Negro":                                             "Río Negro",
        "Salta":                                                 "Salta",
        "San Juan":                                              "San Juan",
        "San Luis":                                              "San Luis",
        "Santa Cruz":                                            "Santa Cruz",
        "Santa Fe":                                              "Santa Fe",
        "Santiago del Estero":                                   "Santiago del Estero",
        "Tierra del Fuego, Antártida e Islas del Atlántico Sur": "Tierra del Fuego, Antártida e Islas del Atlántico Sur",
        "Tucumán":                                               "Tucumán"
    },
    "Chili":         {
        "Arica":                   "Arica",
        "Parinacota":              "Parinacota",
        "Iquique":                 "Iquique",
        "Tamarugal":               "Tamarugal",
        "Antofagasta":             "Antofagasta",
        "El Loa":                  "El Loa",
        "Tocopilla":               "Tocopilla",
        "Copiapó":                 "Copiapó",
        "Huasco":                  "Huasco",
        "Chañaral":                "Chañaral",
        "Elqui":                   "Elqui",
        "Limarí":                  "Limarí",
        "Choapa":                  "Choapa",
        "Isla de Pascua":          "Isla de Pascua",
        "Los Andes":               "Los Andes",
        "Marga Marga":             "Marga Marga",
        "Petorca":                 "Petorca",
        "Quillota":                "Quillota",
        "San Antonio":             "San Antonio",
        "San Felipe de Aconcagua": "San Felipe de Aconcagua",
        "Valparaíso":              "Valparaíso",
        "Cachapoal":               "Cachapoal",
        "Colchagua":               "Colchagua",
        "Cardenal Caro":           "Cardenal Caro",
        "Talca":                   "Talca",
        "Linares":                 "Linares",
        "Curicó":                  "Curicó",
        "Cauquenes":               "Cauquenes",
        "Concepción":              "Concepción",
        "Ñuble":                   "Ñuble",
        "Biobío":                  "Biobío",
        "Arauco":                  "Arauco",
        "Cautin":                  "Cautin",
        "Malleco":                 "Malleco",
        "Valdivia":                "Valdivia",
        "Ranco":                   "Ranco",
        "Llanquihue":              "Llanquihue",
        "Osorno":                  "Osorno",
        "Chiloe":                  "Chiloe",
        "Palena":                  "Palena",
        "Coihaique":               "Coihaique",
        "Aisén":                   "Aisén",
        "General Carrera":         "General Carrera",
        "Capitan Prat":            "Capitan Prat",
        "Magallanes":              "Magallanes",
        "Ultima Esperanza":        "Ultima Esperanza",
        "Tierra del Fuego":        "Tierra del Fuego",
        "Antártica Chilena":       "Antártica Chilena",
        "Santiago":                "Santiago",
        "Cordillera":              "Cordillera",
        "Maipo":                   "Maipo",
        "Talagante":               "Talagante",
        "Melipilla":               "Melipilla",
        "Chacabuco":               "Chacabuco"
    },
    "Bolivia":       {
        "Cercado":                "Cercado",
        "Iténez":                 "Iténez",
        "José Ballivián":         "José Ballivián",
        "Mamoré":                 "Mamoré",
        "Marbán":                 "Marbán",
        "Moxos":                  "Moxos",
        "Vaca Díez":              "Vaca Díez",
        "Yacuma":                 "Yacuma",
        "Azurduy":                "Azurduy",
        "Belisario Boeto":        "Belisario Boeto",
        "Hernando Siles":         "Hernando Siles",
        "Jaime Zudáñez":          "Jaime Zudáñez",
        "Luis Calvo":             "Luis Calvo",
        "Nor Cinti":              "Nor Cinti",
        "Oropeza":                "Oropeza",
        "Sud Cinti":              "Sud Cinti",
        "Tomina":                 "Tomina",
        "Yamparáez":              "Yamparáez",
        "Arani":                  "Arani",
        "Arque":                  "Arque",
        "Ayopaya":                "Ayopaya",
        "Capinota":               "Capinota",
        "Carrasco":               "Carrasco",
        "Chapare":                "Chapare",
        "Esteban Arce":           "Esteban Arce",
        "Germán Jordán":          "Germán Jordán",
        "Mizque":                 "Mizque",
        "Campero":                "Campero",
        "Punata":                 "Punata",
        "Quillacollo":            "Quillacollo",
        "Bolívar":                "Bolívar",
        "Tapacarí":               "Tapacarí",
        "Tiraque":                "Tiraque",
        "Abel Iturralde":         "Abel Iturralde",
        "Aroma":                  "Aroma",
        "Bautista Saavedra":      "Bautista Saavedra",
        "Caranavi":               "Caranavi",
        "Eliodoro Camacho":       "Eliodoro Camacho",
        "Franz Tamayo":           "Franz Tamayo",
        "Gualberto Villarroel":   "Gualberto Villarroel",
        "Ingavi":                 "Ingavi",
        "Inquisivi":              "Inquisivi",
        "José Manuel Pando":      "José Manuel Pando",
        "Larecaja":               "Larecaja",
        "Loayza":                 "Loayza",
        "Los Andes":              "Los Andes",
        "Manco Kapac":            "Manco Kapac",
        "Muñecas":                "Muñecas",
        "Nor Yungas":             "Nor Yungas",
        "Omasuyos":               "Omasuyos",
        "Pacajes":                "Pacajes",
        "Murillo":                "Murillo",
        "Sud Yungas":             "Sud Yungas",
        "Atahuallpa":             "Atahuallpa",
        "Carangas":               "Carangas",
        "Eduardo Avaroa":         "Eduardo Avaroa",
        "Ladislao Cabrera":       "Ladislao Cabrera",
        "Litoral":                "Litoral",
        "Nor Carangas":           "Nor Carangas",
        "Pantaléon Dalence":      "Pantaléon Dalence",
        "Poopó":                  "Poopó",
        "Puerto de Mejillones":   "Puerto de Mejillones",
        "Sajama":                 "Sajama",
        "San Pedro de Totora":    "San Pedro de Totora",
        "Saucarí":                "Saucarí",
        "Sebastián Pagador":      "Sebastián Pagador",
        "Sud Carangas":           "Sud Carangas",
        "Tomas Barrón":           "Tomas Barrón",
        "Abuná":                  "Abuná",
        "Federico Román":         "Federico Román",
        "Madre de Dios":          "Madre de Dios",
        "Manuripi":               "Manuripi",
        "Nicolás Suárez":         "Nicolás Suárez",
        "Alonso de Ibáñez":       "Alonso de Ibáñez",
        "Antonio Quijarro":       "Antonio Quijarro",
        "Bernardino Bilbao":      "Bernardino Bilbao",
        "Charcas":                "Charcas",
        "Chayanta":               "Chayanta",
        "Cornelio Saavedra":      "Cornelio Saavedra",
        "Daniel Campos":          "Daniel Campos",
        "Enrique Baldivieso":     "Enrique Baldivieso",
        "José María Linares":     "José María Linares",
        "Modesto Omiste":         "Modesto Omiste",
        "Nor Chichas":            "Nor Chichas",
        "Nor Lípez":              "Nor Lípez",
        "Rafael Bustillo":        "Rafael Bustillo",
        "Sur Chichas":            "Sur Chichas",
        "Sur Lípez":              "Sur Lípez",
        "Tomás Frías":            "Tomás Frías",
        "Andrés Ibáñez":          "Andrés Ibáñez",
        "Ángel Sandoval":         "Ángel Sandoval",
        "Chiquitos":              "Chiquitos",
        "Cordillera":             "Cordillera",
        "Florida":                "Florida",
        "Germán Busch":           "Germán Busch",
        "Guarayos":               "Guarayos",
        "Ichilo":                 "Ichilo",
        "Ignacio Warnes":         "Ignacio Warnes",
        "José Miguel de Velasco": "José Miguel de Velasco",
        "Manuel María Caballero": "Manuel María Caballero",
        "Ñuflo de Chávez":        "Ñuflo de Chávez",
        "Obispo Santistevan":     "Obispo Santistevan",
        "Sara":                   "Sara",
        "Vallegrande":            "Vallegrande",
        "Aniceto Arce":           "Aniceto Arce",
        "Burnet O'Connor":        "Burnet O'Connor",
        "Eustaquio Méndez":       "Eustaquio Méndez",
        "Gran Chaco":             "Gran Chaco",
        "José María Avilés":      "José María Avilés"
    },
    "España":        {
        "La Coruña":   "La Coruña",
        "Lugo":        "Lugo",
        "Vizcaya":     "Vizcaya",
        "Guipúzcoa":   "Guipúzcoa",
        "Huesca":      "Huesca",
        "Lérida":      "Lérida",
        "Gerona":      "Gerona",
        "Barcelona":   "Barcelona",
        "Tarragona":   "Tarragona",
        "Castellón":   "Castellón",
        "Valencia":    "Valencia",
        "Alicante":    "Alicante",
        "Murcia":      "Murcia",
        "Zaragoza":    "Zaragoza",
        "Teruel":      "Teruel",
        "Cuenca":      "Cuenca",
        "Albacete":    "Albacete",
        "Almeria":     "Almeria",
        "Granada":     "Granada",
        "Málaga":      "Málaga",
        "Tenerife":    "Tenerife",
        "Cádiz":       "Cádiz",
        "Sevilla":     "Sevilla",
        "Huelva":      "Huelva",
        "Las Palmas":  "Las Palmas",
        "Madrid":      "Madrid",
        "Badajoz":     "Badajoz",
        "Cáceres":     "Cáceres",
        "Toledo":      "Toledo",
        "Ciudad Real": "Ciudad Real",
        "Salamanca":   "Salamanca",
        "Córdoba":     "Córdoba",
        "Jaén":        "Jaén",
        "Ávila":       "Ávila",
        "Valladolid":  "Valladolid",
        "Zamora":      "Zamora",
        "Álava":       "Álava",
        "Segovia":     "Segovia",
        "Burgos":      "Burgos",
        "Pontevedra":  "Pontevedra",
        "León":        "León",
        "Orense":      "Orense",
        "Palencia":    "Palencia",
        "La Rioja":    "La Rioja",
        "Soria":       "Soria",
        "Guadalajara": "Guadalajara"
    },
    "Bangladesh":    {
        "বরগুনা":           "বরগুনা",
        "বরিশাল":           "বরিশাল",
        "ভোলা":             "ভোলা",
        "ঝালকাঠি":          "ঝালকাঠি",
        "পটুয়াখালী":       "পটুয়াখালী",
        "পিরোজপুর":         "পিরোজপুর",
        "বান্দরবান":        "বান্দরবান",
        "ব্রাহ্মণবাড়ীয়া": "ব্রাহ্মণবাড়ীয়া",
        "চাঁদপুর":          "চাঁদপুর",
        "চট্টগ্রাম":        "চট্টগ্রাম",
        "কুমিল্লা":         "কুমিল্লা",
        "কক্সবাজার":        "কক্সবাজার",
        "ফেনী":             "ফেনী",
        "খাগড়াছড়ি":       "খাগড়াছড়ি",
        "লক্ষীপুর":         "লক্ষীপুর",
        "নোয়াখালী":        "নোয়াখালী",
        "রাঙ্গামাটি":       "রাঙ্গামাটি",
        "ঢাকা":             "ঢাকা",
        "ফরিদপুর":          "ফরিদপুর",
        "গাজীপুর":          "গাজীপুর",
        "গোপালগঞ্জ":        "গোপালগঞ্জ",
        "জামালপুর":         "জামালপুর",
        "কিশোরগঞ্জ":        "কিশোরগঞ্জ",
        "মাদারীপুর":        "মাদারীপুর",
        "মানিকগঞ্জ":        "মানিকগঞ্জ",
        "মুন্সীগঞ্জ":       "মুন্সীগঞ্জ",
        "ময়মনসিংহ":        "ময়মনসিংহ",
        "নারায়ণগঞ্জ":      "নারায়ণগঞ্জ",
        "নরসিংদী":          "নরসিংদী",
        "নেত্রকোনা":        "নেত্রকোনা",
        "রাজবাড়ী":         "রাজবাড়ী",
        "শরীয়তপুর":        "শরীয়তপুর",
        "শেরপুর":           "শেরপুর",
        "টাঙ্গাইল":         "টাঙ্গাইল",
        "বাগেরহাট":         "বাগেরহাট",
        "চুয়াডাঙ্গা":      "চুয়াডাঙ্গা",
        "যশোর":             "যশোর",
        "ঝিনাইদহ":          "ঝিনাইদহ",
        "খুলনা":            "খুলনা",
        "কুষ্টিয়া":        "কুষ্টিয়া",
        "মাগুরা":           "মাগুরা",
        "মেহেরপুর":         "মেহেরপুর",
        "নড়াইল":           "নড়াইল",
        "সাতক্ষিরা":        "সাতক্ষিরা",
        "বগুড়া":           "বগুড়া",
        "জয়পুরহাট":        "জয়পুরহাট",
        "নওগাঁ":            "নওগাঁ",
        "নাটোর":            "নাটোর",
        "নওয়াবগঞ্জ":       "নওয়াবগঞ্জ",
        "পাবনা":            "পাবনা",
        "রাজশাহী":          "রাজশাহী",
        "সিরাজগঞ্জ":        "সিরাজগঞ্জ",
        "দিনাজপুর":         "দিনাজপুর",
        "গাইবান্ধা":        "গাইবান্ধা",
        "কুড়িগ্রাম":       "কুড়িগ্রাম",
        "লালমনিরহাট":       "লালমনিরহাট",
        "নীলফামারী":        "নীলফামারী",
        "পঞ্চগড়":          "পঞ্চগড়",
        "রংপুর":            "রংপুর",
        "ঠাকুরগাঁ":         "ঠাকুরগাঁ",
        "হবিগঞ্জ":          "হবিগঞ্জ",
        "মৌলভীবাজার":       "মৌলভীবাজার",
        "সুনামগঞ্জ":        "সুনামগঞ্জ",
        "সিলেট":            "সিলেট"
    },
    "Pakistan":      {
        "Azad Kashmir":     "Azad Kashmir",
        "Bahawalpur":       "Bahawalpur",
        "Bannu":            "Bannu",
        "Dera Ghazi Khan":  "Dera Ghazi Khan",
        "Dera Ismail Khan": "Dera Ismail Khan",
        "Faisalabad":       "Faisalabad",
        "F.A.T.A.":         "F.A.T.A.",
        "Gujranwala":       "Gujranwala",
        "Hazara":           "Hazara",
        "Hyderabad":        "Hyderabad",
        "Islamabad":        "Islamabad",
        "Kalat":            "Kalat",
        "Karachi":          "Karachi",
        "Kohat":            "Kohat",
        "Lahore":           "Lahore",
        "Larkana":          "Larkana",
        "Makran":           "Makran",
        "Malakand":         "Malakand",
        "Mardan":           "Mardan",
        "Mirpur Khas":      "Mirpur Khas",
        "Multan":           "Multan",
        "Nasirabad":        "Nasirabad",
        "Northern Areas":   "Northern Areas",
        "Peshawar":         "Peshawar",
        "Quetta":           "Quetta",
        "Rawalpindi":       "Rawalpindi",
        "Sargodha":         "Sargodha",
        "Sahiwal":          "Sahiwal",
        "Sibi":             "Sibi",
        "Sukkur":           "Sukkur",
        "Zhob":             "Zhob"
    },
    "Nigeria":       {
        "Abia":        "Abia",
        "Abuja":       "Abuja",
        "Adamawa":     "Adamawa",
        "Akwa Ibom":   "Akwa Ibom",
        "Anambra":     "Anambra",
        "Bauchi":      "Bauchi",
        "Bayelsa":     "Bayelsa",
        "Benue":       "Benue",
        "Borno":       "Borno",
        "Cross River": "Cross River",
        "Delta":       "Delta",
        "Ebonyi":      "Ebonyi",
        "Edo":         "Edo",
        "Ekiti":       "Ekiti",
        "Enugu":       "Enugu",
        "Gombe":       "Gombe",
        "Imo":         "Imo",
        "Jigawa":      "Jigawa",
        "Kaduna":      "Kaduna",
        "Kano":        "Kano",
        "Katsina":     "Katsina",
        "Kebbi":       "Kebbi",
        "Kogi":        "Kogi",
        "Kwara":       "Kwara",
        "Lagos":       "Lagos",
        "Nasarawa":    "Nasarawa",
        "Niger":       "Niger",
        "Ogun":        "Ogun",
        "Ondo":        "Ondo",
        "Osun":        "Osun",
        "Oyo":         "Oyo",
        "Plateau":     "Plateau",
        "Rivers":      "Rivers",
        "Sokoto":      "Sokoto",
        "Taraba":      "Taraba",
        "Yobe":        "Yobe",
        "Zamfara":     "Zamfara"
    },
    "Japan":         {
        "愛知県":  "愛知県",
        "秋田県":  "秋田県",
        "青森県":  "青森県",
        "千葉県":  "千葉県",
        "愛媛県":  "愛媛県",
        "福井県":  "福井県",
        "福岡県":  "福岡県",
        "福島県":  "福島県",
        "岐阜県":  "岐阜県",
        "群馬県":  "群馬県",
        "広島県":  "広島県",
        "北海道":  "北海道",
        "兵庫県":  "兵庫県",
        "茨城県":  "茨城県",
        "石川県":  "石川県",
        "岩手県":  "岩手県",
        "香川県":  "香川県",
        "鹿児島県": "鹿児島県",
        "神奈川県": "神奈川県",
        "高知県":  "高知県",
        "熊本県":  "熊本県",
        "京都府":  "京都府",
        "三重県":  "三重県",
        "宮城県":  "宮城県",
        "宮崎県":  "宮崎県",
        "長野県":  "長野県",
        "長崎県":  "長崎県",
        "奈良県":  "奈良県",
        "新潟県":  "新潟県",
        "大分県":  "大分県",
        "岡山県":  "岡山県",
        "沖縄県":  "沖縄県",
        "大阪府":  "大阪府",
        "佐賀県":  "佐賀県",
        "埼玉県":  "埼玉県",
        "滋賀県":  "滋賀県",
        "島根県":  "島根県",
        "静岡県":  "静岡県",
        "栃木県":  "栃木県",
        "徳島県":  "徳島県",
        "東京都":  "東京都",
        "鳥取県":  "鳥取県",
        "富山県":  "富山県",
        "和歌山県": "和歌山県",
        "山形県":  "山形県",
        "山口県":  "山口県",
        "山梨県":  "山梨県"
    },
    "Österreich":    {
        "Burgenland":       "Burgenland",
        "Kärnten":          "Kärnten",
        "Niederösterreich": "Niederösterreich",
        "Oberösterreich":   "Oberösterreich",
        "Salzburg":         "Salzburg",
        "Steiermark":       "Steiermark",
        "Tirol":            "Tirol",
        "Vorarlberg":       "Vorarlberg",
        "Wien":             "Wien"
    },
    "Brazil":        {
        "Acre":                "Acre",
        "Alagoas":             "Alagoas",
        "Amapá":               "Amapá",
        "Amazonas":            "Amazonas",
        "Bahia":               "Bahia",
        "Ceará":               "Ceará",
        "Distrito Federal":    "Distrito Federal",
        "Espírito Santo":      "Espírito Santo",
        "Goiás":               "Goiás",
        "Maranhão":            "Maranhão",
        "Mato Grosso":         "Mato Grosso",
        "Mato Grosso do Sul":  "Mato Grosso do Sul",
        "Minas Gerais":        "Minas Gerais",
        "Pará":                "Pará",
        "Paraíba":             "Paraíba",
        "Paraná":              "Paraná",
        "Pernambuco":          "Pernambuco",
        "Piauí":               "Piauí",
        "Rio de Janeiro":      "Rio de Janeiro",
        "Rio Grande do Norte": "Rio Grande do Norte",
        "Rio Grande do Sul":   "Rio Grande do Sul",
        "Rondônia":            "Rondônia",
        "Roraima":             "Roraima",
        "Santa Catarina":      "Santa Catarina",
        "São Paulo":           "São Paulo",
        "Sergipe":             "Sergipe",
        "Tocantins":           "Tocantins"
    },
    "Philipines":    {
        "Abra":                "Abra",
        "Agusan del Norte":    "Agusan del Norte",
        "Agusan del Sur":      "Agusan del Sur",
        "Aklan":               "Aklan",
        "Albay":               "Albay",
        "Antique":             "Antique",
        "Apayao":              "Apayao",
        "Aurora":              "Aurora",
        "Basilan":             "Basilan",
        "Bataan":              "Bataan",
        "Batanes":             "Batanes",
        "Batangas":            "Batangas",
        "Benguet":             "Benguet",
        "Biliran":             "Biliran",
        "Bohol":               "Bohol",
        "Bukidnon":            "Bukidnon",
        "Bulacan":             "Bulacan",
        "Cagayan":             "Cagayan",
        "Camarines Norte":     "Camarines Norte",
        "Camarines Sur":       "Camarines Sur",
        "Camiguin":            "Camiguin",
        "Capiz":               "Capiz",
        "Catanduanes":         "Catanduanes",
        "Cavite":              "Cavite",
        "Cebu":                "Cebu",
        "Compostela Valley":   "Compostela Valley",
        "Cotabato":            "Cotabato",
        "Davao del Norte":     "Davao del Norte",
        "Davao del Sur":       "Davao del Sur",
        "Davao Occidental":    "Davao Occidental",
        "Davao Oriental":      "Davao Oriental",
        "Dinagat Islands":     "Dinagat Islands",
        "Eastern Samar":       "Eastern Samar",
        "Guimaras":            "Guimaras",
        "Ifugao":              "Ifugao",
        "Ilocos Norte":        "Ilocos Norte",
        "Ilocos Sur":          "Ilocos Sur",
        "Iloilo":              "Iloilo",
        "Isabela":             "Isabela",
        "Kalinga":             "Kalinga",
        "La Union":            "La Union",
        "Laguna":              "Laguna",
        "Lanao del Norte":     "Lanao del Norte",
        "Lanao del Sur":       "Lanao del Sur",
        "Leyte":               "Leyte",
        "Maguindanao":         "Maguindanao",
        "Marinduque":          "Marinduque",
        "Masbate":             "Masbate",
        "Misamis Occidental":  "Misamis Occidental",
        "Misamis Oriental":    "Misamis Oriental",
        "Mountain Province":   "Mountain Province",
        "Negros Occidental":   "Negros Occidental",
        "Negros Oriental":     "Negros Oriental",
        "Northern Samar":      "Northern Samar",
        "Nueva Ecija":         "Nueva Ecija",
        "Nueva Vizcaya":       "Nueva Vizcaya",
        "Occidental Mindoro":  "Occidental Mindoro",
        "Oriental Mindoro":    "Oriental Mindoro",
        "Palawan":             "Palawan",
        "Pampanga":            "Pampanga",
        "Pangasinan":          "Pangasinan",
        "Quezon":              "Quezon",
        "Quirino":             "Quirino",
        "Rizal":               "Rizal",
        "Romblon":             "Romblon",
        "Samar":               "Samar",
        "Sarangani":           "Sarangani",
        "Siquijor":            "Siquijor",
        "Sorsogon":            "Sorsogon",
        "South Cotabato":      "South Cotabato",
        "Southern Leyte":      "Southern Leyte",
        "Sultan Kudarat":      "Sultan Kudarat",
        "Sulu":                "Sulu",
        "Surigao del Norte":   "Surigao del Norte",
        "Surigao del Sur":     "Surigao del Sur",
        "Tarlac":              "Tarlac",
        "Tawi-Tawi":           "Tawi-Tawi",
        "Zambales":            "Zambales",
        "Zamboanga del Norte": "Zamboanga del Norte",
        "Zamboanga del Sur":   "Zamboanga del Sur",
        "Zamboanga Sibugay":   "Zamboanga Sibugay",
        "Metro Manila":        "Metro Manila"
    },
    "Vietnam":       {
        "Hà Nội":                "Hà Nội",
        "Hà Giang":              "Hà Giang",
        "Cao Bằng":              "Cao Bằng",
        "Bắc Kạn":               "Bắc Kạn",
        "Tuyên Quang":           "Tuyên Quang",
        "Lào Cai":               "Lào Cai",
        "Điện Biên":             "Điện Biên",
        "Lai Châu":              "Lai Châu",
        "Sơn La":                "Sơn La",
        "Yên Bái":               "Yên Bái",
        "Hòa Bình":              "Hòa Bình",
        "Thái Nguyên":           "Thái Nguyên",
        "Lạng Sơn":              "Lạng Sơn",
        "Quảng Ninh":            "Quảng Ninh",
        "Bắc Giang":             "Bắc Giang",
        "Phú Thọ":               "Phú Thọ",
        "Vĩnh Phúc":             "Vĩnh Phúc",
        "Bắc Ninh":              "Bắc Ninh",
        "Hải Dương":             "Hải Dương",
        "Hải Phòng":             "Hải Phòng",
        "Hưng Yên":              "Hưng Yên",
        "Thái Bình":             "Thái Bình",
        "Hà Nam":                "Hà Nam",
        "Nam Định":              "Nam Định",
        "Ninh Bình":             "Ninh Bình",
        "Thanh Hóa":             "Thanh Hóa",
        "Nghệ An":               "Nghệ An",
        "Hà Tĩnh":               "Hà Tĩnh",
        "Quảng Bình":            "Quảng Bình",
        "Quảng Trị":             "Quảng Trị",
        "Thừa Thiên–Huế":        "Thừa Thiên–Huế",
        "Đà Nẵng":               "Đà Nẵng",
        "Quảng Nam":             "Quảng Nam",
        "Quảng Ngãi":            "Quảng Ngãi",
        "Bình Định":             "Bình Định",
        "Phú Yên":               "Phú Yên",
        "Khánh Hòa":             "Khánh Hòa",
        "Ninh Thuận":            "Ninh Thuận",
        "Bình Thuận":            "Bình Thuận",
        "Kon Tum":               "Kon Tum",
        "Gia Lai":               "Gia Lai",
        "Đắk Lắk":               "Đắk Lắk",
        "Đắk Nông":              "Đắk Nông",
        "Lâm Đồng":              "Lâm Đồng",
        "Bình Phước":            "Bình Phước",
        "Tây Ninh":              "Tây Ninh",
        "Bình Dương":            "Bình Dương",
        "Đồng Nai":              "Đồng Nai",
        "Bà Rịa–Vũng Tàu":       "Bà Rịa–Vũng Tàu",
        "Thành phố Hồ Chí Minh": "Thành phố Hồ Chí Minh",
        "Long An":               "Long An",
        "Tiền Giang":            "Tiền Giang",
        "Bến Tre":               "Bến Tre",
        "Trà Vinh":              "Trà Vinh",
        "Vĩnh Long":             "Vĩnh Long",
        "Đồng Tháp":             "Đồng Tháp",
        "An Giang":              "An Giang",
        "Kiên Giang":            "Kiên Giang",
        "Cần Thơ":               "Cần Thơ",
        "Hậu Giang":             "Hậu Giang",
        "Sóc Trăng":             "Sóc Trăng",
        "Bạc Liêu":              "Bạc Liêu",
        "Cà Mau":                "Cà Mau"
    },
    "Costa Rica":    {
        "San José":   "San José",
        "Alajuela":   "Alajuela",
        "Cartago":    "Cartago",
        "Heredia":    "Heredia",
        "Guanacaste": "Guanacaste",
        "Puntarenas": "Puntarenas",
        "Limón":      "Limón"
    },
    "New Zealand":   {
        "Auckland":     "Auckland",
        "New Plymouth": "New Plymouth",
        "Hawke's Bay":  "Hawke's Bay",
        "Wellington":   "Wellington",
        "Nelson":       "Nelson",
        "Marlborough":  "Marlborough",
        "Westland":     "Westland",
        "Canterbury":   "Canterbury",
        "Otago":        "Otago",
        "Southland":    "Southland"
    },
    "France":        {
        "Ain":                     "Ain",
        "Drôme":                   "Drôme",
        "Marne":                   "Marne",
        "Seine-Maritime":          "Seine-Maritime",
        "Aisne":                   "Aisne",
        "Eure":                    "Eure",
        "Haute-Marne":             "Haute-Marne",
        "Seine-et-Marne":          "Seine-et-Marne",
        "Allier":                  "Allier",
        "Eure-et-Loir":            "Eure-et-Loir",
        "Mayenne":                 "Mayenne",
        "Yvelines":                "Yvelines",
        "Alpes-de-Haute-Provence": "Alpes-de-Haute-Provence",
        "Finistère":               "Finistère",
        "Meurthe-et-Moselle":      "Meurthe-et-Moselle",
        "Deux-Sèvres":             "Deux-Sèvres",
        "Hautes-Alpes":            "Hautes-Alpes",
        "Gard":                    "Gard",
        "Meuse":                   "Meuse",
        "Somme":                   "Somme",
        "Alpes-Maritimes":         "Alpes-Maritimes",
        "Haute-Garonne":           "Haute-Garonne",
        "Morbihan":                "Morbihan",
        "Tarn":                    "Tarn",
        "Ardèche":                 "Ardèche",
        "Gers":                    "Gers",
        "Moselle":                 "Moselle",
        "Tarn-et-Garonne":         "Tarn-et-Garonne",
        "Ardennes":                "Ardennes",
        "Gironde":                 "Gironde",
        "Nièvre":                  "Nièvre",
        "Var":                     "Var",
        "Ariège":                  "Ariège",
        "Hérault":                 "Hérault",
        "Nord":                    "Nord",
        "Vaucluse":                "Vaucluse",
        "Aube":                    "Aube",
        "Ille-et-Vilaine":         "Ille-et-Vilaine",
        "Oise":                    "Oise",
        "Vendée":                  "Vendée",
        "Aude":                    "Aude",
        "Indre":                   "Indre",
        "Orne":                    "Orne",
        "Vienne":                  "Vienne",
        "Aveyron":                 "Aveyron",
        "Indre-et-Loire":          "Indre-et-Loire",
        "Pas-de-Calais":           "Pas-de-Calais",
        "Haute-Vienne":            "Haute-Vienne",
        "Bouches-du-Rhône":        "Bouches-du-Rhône",
        "Isère":                   "Isère",
        "Puy-de-Dôme":             "Puy-de-Dôme",
        "Vosges":                  "Vosges",
        "Calvados":                "Calvados",
        "Jura":                    "Jura",
        "Pyrénées-Atlantiques":    "Pyrénées-Atlantiques",
        "Yonne":                   "Yonne",
        "Cantal":                  "Cantal",
        "Landes":                  "Landes",
        "Hautes-Pyrénées":         "Hautes-Pyrénées",
        "Territoire de Belfort":   "Territoire de Belfort",
        "Charente":                "Charente",
        "Loir-et-Cher":            "Loir-et-Cher",
        "Pyrénées-Orientales":     "Pyrénées-Orientales",
        "Essonne":                 "Essonne",
        "Charente-Maritime":       "Charente-Maritime",
        "Loire":                   "Loire",
        "Bas-Rhin":                "Bas-Rhin",
        "Hauts-de-Seine":          "Hauts-de-Seine",
        "Cher":                    "Cher",
        "Haute-Loire":             "Haute-Loire",
        "Haut-Rhin":               "Haut-Rhin",
        "Seine-Saint-Denis":       "Seine-Saint-Denis",
        "Corrèze":                 "Corrèze",
        "Loire-Atlantique":        "Loire-Atlantique",
        "D Rhône":                 "D Rhône",
        "Val-de-Marne":            "Val-de-Marne",
        "A Corse-du-Sud":          "A Corse-du-Sud",
        "Loiret":                  "Loiret",
        "M Métropole de Lyon":     "M Métropole de Lyon",
        "Val-d'Oise":              "Val-d'Oise",
        "B Haute-Corse":           "B Haute-Corse",
        "Lot":                     "Lot",
        "Haute-Saône":             "Haute-Saône",
        "Guadeloupe":              "Guadeloupe",
        "Côte-d'Or":               "Côte-d'Or",
        "Lot-et-Garonne":          "Lot-et-Garonne",
        "Saône-et-Loire":          "Saône-et-Loire",
        "Martinique":              "Martinique",
        "Côtes-d'Armor":           "Côtes-d'Armor",
        "Lozère":                  "Lozère",
        "Sarthe":                  "Sarthe",
        "Guyane":                  "Guyane",
        "Creuse":                  "Creuse",
        "Maine-et-Loire":          "Maine-et-Loire",
        "Savoie":                  "Savoie",
        "Réunion":                 "Réunion",
        "Dordogne":                "Dordogne",
        "Manche":                  "Manche",
        "Haute-Savoie":            "Haute-Savoie",
        "Mayotte":                 "Mayotte",
        "Doubs":                   "Doubs",
        "Paris":                   "Paris"

    },
    default:         {"-": "-"}
}