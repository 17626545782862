import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import i18n from "../../../i18n";
import * as actions from "../../../store/actions";

import {BottomBar, ColumnSelector, ReactTable, Spinner} from "../../../components";
import {TitleWrapper} from "../../../contexts/TitleProvider";
import moment from "moment-timezone";
import tableColumns from "../../../variables/tableColumns";

const InvitedUsers = props => {
    const dispatch              = useDispatch();
    const loading               = useSelector(state => state.caretakers.isLoading);
    const invites               = useSelector(state => state.caretakers.invites);
    const invitesLoaded         = useSelector(state => state.caretakers.invitesLoaded);
    const [columns, setColumns] = useState(tableColumns.invitedColumnsDefault());

    useEffect(() => {
        if (!invitesLoaded || moment().diff(invitesLoaded) > 300000) {
            dispatch(actions.caretakers.loadInvitedUsers());
        }
    }, [invitesLoaded, dispatch, props]);

    if (!invites || loading) {
        return <Spinner/>;
    }

    return (
        <TitleWrapper title={i18n.caretakersInvitedUsers}>
            <div>
                <div className="content-form">
                    <ColumnSelector cookieId="invitedUsers" columns={columns} changed={setColumns}/>
                    <ReactTable
                        filterable
                        data={invites}
                        columns={columns}
                        noDataText={i18n.caretakersInvitedNoInvites}
                        indexKey="id"
                        defaultSorted={[{id: "id", desc: true}]}
                    />
                    <BottomBar backButton />
                </div>
            </div>
        </TitleWrapper>
    );
};

export default InvitedUsers;