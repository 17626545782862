import React from 'react';
import PropTypes from 'prop-types';
import TagsInput from "react-tagsinput";
import AutoWrapInput from '../InputContainers/AutoWrapInput';

/*
            onFocus={() => props.handlers.focus(props.name, true)}
            onBlur={() => props.handlers.focus(props.name, false)}
            className={props.settings.touched && props.settings.validation ? (props.settings.valid ? "has-success" : "has-danger") : ""}
 */
const Tags = props => (
    <AutoWrapInput name={props.name} settings={props.settings} stacked={props.stacked} disabled={props.disabled}>
        <div className="form-control" style={{padding: 0}}>
            <TagsInput
                {...props.settings.elementConfig}
                name={props.name}
                disabled={props.disabled}
                value={props.settings.value}
                onChange={tags => props.handlers.change(tags, props.name)}
                tagProps={{className: "react-tagsinput-tag info"}}
                ref={props.innerRef}
            />
        </div>
    </AutoWrapInput>
);

Tags.propTypes = {
    name:     PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    handlers: PropTypes.object.isRequired,
    innerRef: PropTypes.object,
    stacked:  PropTypes.bool
};

export default React.memo(Tags, (prevProps, nextProps) => {
    return prevProps.settings.value === nextProps.settings.value &&
           prevProps.settings.focused === nextProps.settings.focused &&
           prevProps.settings.valid === nextProps.settings.valid &&
        prevProps.disabled === nextProps.disabled;
});