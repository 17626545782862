import React from "react";
import PropTypes from "prop-types";
import {useHistory} from 'react-router-dom'

import {apiGetFullPath, apiGetToken} from "../../api";
import i18n from "../../i18n";
import {appRootPath} from "../../variables/multiplatform";

import {Button, Icon, UncontrolledTooltip} from "../../components";
import defaultAvatar from "../../assets/img/placeholder.jpg";
import css from "./CaretakerItem.module.css";
import {convertMachineToSignal} from "../../variables/tools";
import {useSelector} from "react-redux";

const CaretakerItem = props => {
    const history         = useHistory();
    const accountData = useSelector(state => state.auth.accountData);

    const convertSignalToClass = signal => {
        switch (signal) {
            case -1:
                return css.machineOffline;
            case 0:
                return css.machineBad;
            case 1:
                return css.machineBelowAverage;
            case 2:
                return css.machineAverage;
            case 3:
                return css.machineGood;
            case 4:
                return css.machineExcellent;
            default:
                return css.machineNone;
        }
    };

    let linkStyle     = css.machineNone;
    let machineSignal = 0;
    let noMachines    = false;
    switch (props.machines.length) {
        case 0:
            noMachines = true;
            break;

        case 1:
            machineSignal = convertMachineToSignal(props.machines[0]);
            linkStyle = convertSignalToClass(machineSignal);
            break;

        default:
            machineSignal = 4;
            props.machines.forEach(machine => {
                machineSignal = Math.min(machineSignal, convertMachineToSignal(machine));
            });
            linkStyle = convertSignalToClass(machineSignal);
    }

    return (
        <div className={css.item}>
            <div className={css.avatar}>
                <div className={[css.imgWrapper, css.machineIndicator, linkStyle].join(" ")}>
                    <img src={
                        props.avatar ?
                            apiGetFullPath("caretakers/getavatar?uuid=" + props.uuid + "&token=" + apiGetToken()) :
                            appRootPath + defaultAvatar
                    } alt="..."/>
                    {noMachines && (<div className={css.noMachines}/>)}
                </div>
            </div>
            <div className={css.text}>
                <div className={css.title}>
                    {props.name}
                </div>
                <div className={css.icons}>
                    <Button className={css.tourSettings} id={"edit" + props.uuid} onClick={() => history.push('/caretakers/settings/' + props.uuid)} round icon
                            neutral color="info">
                        <Icon name="settings-gear"/>
                    </Button>
                    <UncontrolledTooltip boundariesElement="window" placement="top" target={"edit" + props.uuid} delay={0}>
                        {i18n.caretakersItemEdit}
                    </UncontrolledTooltip>

                    <Button
                        className={css.tourCalendars}
                        id={"calendar" + props.uuid}
                        onClick={() => history.push("/caretakers/calendars/" + (accountData.calendarDisplay === "overview" ? "allitems/": "") + props.uuid)}
                        round
                        icon neutral
                        color="info"
                    >
                        <Icon name="calendar-2"/>
                    </Button>
                    <UncontrolledTooltip boundariesElement="window" placement="top" target={"calendar" + props.uuid} delay={0}>
                        {i18n.caretakersItemCalendars}
                    </UncontrolledTooltip>

                    <Button className={css.tourPreview} id={"preview" + props.uuid} onClick={() => history.push('/caretakers/preview/' + props.uuid)} round icon
                            neutral color="info">
                        <Icon name="tv"/>
                    </Button>
                    <UncontrolledTooltip boundariesElement="window" placement="top" target={"preview" + props.uuid} delay={0}>
                        {i18n.caretakersItemPreview}
                    </UncontrolledTooltip>
                </div>
            </div>
            <div className={css.add} onClick={() => history.push('/caretakers/add/' + props.uuid)}>
                <div>+</div>
            </div>
        </div>
    );
};

CaretakerItem.propTypes = {
    id:            PropTypes.number,
    name:          PropTypes.string,
    avatar:        PropTypes.bool,
    showHelpItem:  PropTypes.bool,
    showHelpIndex: PropTypes.number
};

export default CaretakerItem;
