// React
import React from "react";

// Redux
import {connect} from "react-redux";
import {push} from 'connected-react-router'
import * as actions from "../../../../store/actions";

// Tools
import i18n from "../../../../i18n";

// Components
import {Button,  Form, Spinner,  BottomBar} from "../../../../components";
import {filterFormValues} from "../../../../components/Form/formTools";
import {TitleWrapper} from "../../../../contexts/TitleProvider";

const userForm = {
    name:        {label: i18n.userName},
    row1:        {
        type:  "row",
        items: {
            address: {label: i18n.userAddress, size: 8},
            zipcode: {label: i18n.userZipcode, size: 4, validation: {required: true, minLength: 4}},
        }
    },
    row2:        {
        type:  "row",
        items: {
            city:    {label: i18n.userCity, size: 4},
            state:   {label: i18n.userState, size: 4},
            country: {label: i18n.userCountry, size: 4}
        }
    },
    row3:        {
        type:  "row",
        items: {
            phone: {label: i18n.userPhone, size: 6},
            email: {label: i18n.userEmail, size: 6, validation: {required: true, isEmail: true}}
        }
    },
    super_admin: {
        label: i18n.usersTableAdmin,
        type:  "switch",
    },
    disabled:    {
        label: i18n.usersTableDisabled,
        type:  "switch",
    }
};

class Edit extends React.Component {
    state = {
        uuid:       null,
        user:       null,
        formValues: {},
        formValid:  false
    };

    constructor(props) {
        super(props);

        let userId = props.match.params.userId;
        if (userId.length !== 32) {
            props.push("/admin/users");
            return;
        }
        props.load(userId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props === prevProps) {
            return;
        }

        if (this.props.userInfo && this.props.userInfo !== prevProps.userInfo) {
            this.setState({formValues: filterFormValues(userForm, this.props.userInfo.details)});
        }
    }

    handleChange = (formValues, formValid) => this.setState({formValues, formValid});
    handleValid  = formValid => this.setState({formValid});
    handleSubmit = () => this.state.formValid && this.props.save(this.props.match.params.userId, this.state.formValues);

    render() {
        return (
            <TitleWrapper title={i18n.usersEditTitle}>
                <div>
                    <div className="content">
                        {this.props.userInfo && !this.props.loading && !this.props.saving ? (
                            <>
                                <Form
                                    formData={userForm}
                                    values={this.state.formValues}
                                    onChange={this.handleChange}
                                    onValid={this.handleValid}
                                    stacked
                                />
                                <Button round color="info" block disabled={!this.state.formValid} onClick={this.handleSubmit}>
                                    {i18n.buttonSave}
                                </Button>
                            </>
                        ) : <Spinner/>}
                        <BottomBar backButton/>
                    </div>
                </div>
            </TitleWrapper>
        );
    }
}

const mapStateToProps = state => ({
    loading:   state.users.loading,
    saving:    state.users.saving,
    reloading: state.users.reloading,
    loadedAt:  state.users.loadedAt,
    loadedFor: state.users.loadedFor,
    userInfo:  state.users.userInfo
});

const mapDispatchToProps = dispatch => ({
    push: pathname => dispatch(push(pathname)),
    load: userId => dispatch(actions.users.loadInfo(userId)),
    save: (uuid, details) => dispatch(actions.users.save(uuid, details, "/admin/users/info/" + uuid))
});

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
