import React from 'react';
import PropTypes from 'prop-types';
import css from "./Calendars.module.css";
import EmptyDay from "./EmptyDay";
import Day from "./Day";
import moment from "moment-timezone";
import Week from "./Week";
import DayNames from "./DayNames";
import {Col} from "reactstrap";

const Month = props => {
    let today     = moment();
    let workDate  = props.date.clone();
    let emptyDays = (workDate.day() + 6) % 7;

    const monthNameFields = [];
    const weeks           = [];
    let week              = [];
    for (let i = 0; i < emptyDays; i++) {
        week.push(<EmptyDay key={"es" + i}/>);
    }
    for (let i = 1; i <= workDate.daysInMonth(); i++) {
        workDate.date(i);
        week.push(<Day
            key={"day" + i}
            date={workDate.clone()}
            isToday={workDate.isSame(today, 'day')}
            hasItems={props.events.includes(workDate.format("YYYY-MM-DD"))}
        />);
        if (week.length === 7) {
            weeks.push(week);
            week = [];
        }
    }
    if (week.length > 0) {
        for (let i = week.length; i < 7; i++) {
            week.push(<EmptyDay key={"ee" + i}/>);
        }
        weeks.push(week);
    }
    for(let i = 0; i < Math.min(emptyDays, 4); i++) {
        monthNameFields.push(<div key={"mn" + i} className={css.monthEmpty}/>);
    }
    monthNameFields.push(
        <div
            key="name"
            className={css.monthName}
            style={{textAlign: emptyDays < 5 ? "left" : "right"}}
        >{props.date.format("MMMM YYYY")}</div>);
    for(let i = monthNameFields.length; i < 5; i++) {
        monthNameFields.push(<div key={"me" + i} className={css.monthEmpty} />);
    }

    return (
        <Col xs={12} md={6} lg={4} xl={3}>
            <div className={css.month}>
                {props.firstMonth && <DayNames/>}
                <div className={css.monthNameRow}>{monthNameFields}</div>
                {weeks.map((week, index) => <Week key={"week" + index}>{week}</Week>)}
            </div>
        </Col>
    );
};

Month.propTypes = {
    date:       PropTypes.object.isRequired,
    events:     PropTypes.array.isRequired,
    firstMonth: PropTypes.bool.isRequired
};

export default Month;