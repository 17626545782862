export default {
    machines:   "Maskiner",
    noMachines: "Ingen maskiner",

    machinesSerialNumber: "Serie#",
    machinesSerialCheck:  "Check#",
    machinesMacAddress:   "MAC-adresse",
    machinesName:         "Navm",
    machinesNetwork:      "Netwerk",
    machinesIMSI:         "IMSI",
    machinesIMEI:         "IMEI",
    machinesOS:           "OS",
    machinesVersion:      "Version",
    machinesLastUpdate:   "Seneste opdatering",
    machinesLastBoot:     "Seneste opstart",
    machinesLastIP:       "Seneste IP",
    machinesCreatedAt:    "Oprettet",
    machinesTemperature:  "Temperatur",
    machinesLoadError:    "Der opstod en fejl under indlæsning af maskininformation",
    machinesCoupled:      "Koblet",
    machinesCoupledTo:    "Koblet til",
    machinesUncoupled:    "Frakoblet",
    machinesDistributor:  "Distributør",
    machinesAffiliate:    "Affiliate",
    machinesVirtual:      "Virtuel maskine",
    machinesServer:       "Via CDS",
    machinesIP:           "Netværk IP",

    machinesNotCoupled:    "Ikke koblet",
    machinesNoDistributor: "Ingen distributør",
    machinesNoAffiliate:   "Ingen affiliate",

    machinesActions:                      "Handlinger",
    machinesActionsTitle:                 "Handlinger for denne maskine",
    machinesActionsInfo:                  "Info",
    machinesActionsSwap:                  "Bytte",
    machinesActionsUncouple:              "Frakoble",
    machinesActionsClearUpdates:          "Fjern alle opdateringer",
    machinesActionsClearUpdatesAndResend: "Fjern alle opdateringer og send indhold igen",

    machinesNetworkFilterAll:       "Vis alle",
    machinesNetworkFilterEthernet:  "Ethernet",
    machinesNetworkFilterWiFi:      "WiFi",
    machinesNetworkFilterMobile:    "Mobil",
    machinesNetworkFilterMobileLTE: "Mobil - Kun LTE",
    machinesNetworkFilterMobileGSM: "Mobil - Kun GSMM",

    machineCoupleTitle:   "Koble ny maskine",
    machineCoupleBody:    "Vælg den maskine, du vil koble til plejemottageren med navnet '%1'. Hvis du vælger en maskine, der allerede er koblet, vil den blive frakoblet fra den nuværende plejemottager og koblet til denne plejemottager.",
    machineCoupleSure:    "Koble maskine",
    machineCoupleSuccess: "Maskinen er succesfuldt koblet",
    machineCoupleError:   "Der opstod en fejl ved kobling af maskinen",

    machineSwapTitle:   "Byt maskine",
    machineSwapBody:    "Vælg den maskine, du vil bytte med maskinen med MAC-adresse '%1'. Hvis du vælger en maskine, der allerede er koblet, vil indholdet af begge maskiner blive ombyttet.",
    machineSwapSure:    "Byt maskiner",
    machineSwapSuccess: "Maskinerne er succesfuldt byttet",
    machineSwapError:   "Der opstod en fejl ved bytning af maskinerne",

    machineUncoupleTitle:   "Er du sikker på, at du vil frakoble denne maskine?",
    machineUncoupleBody:    "Hvis du frakobler maskinen med MAC-adresse '%1', bliver denne maskine nulstillet, og der vises nye tilkoblingsord på skærmen. Dette kan ikke gøres om.",
    machineUncoupleSure:    "Frakoble maskine",
    machineUncoupleSuccess: "Maskinen er succesfuldt frakoblet",

    machineInfoTitle:               "Maskininfo",
    machineInfoDataHead:            "Maskinoplysninger for %1",
    machineInfoCoupledHead:         "Koblingsinfo",
    machineInfoCoupleEditCaretaker: "Rediger denne plejemottager",
    machineInfoCoupleShowMachines:  "Vis alle maskiner fra denne plejemottager",
    machineInfoUpdatesHead:         "Opdateringer",

    machineUpdatesId:           "ID",
    machineUpdatesCommand:      "Kommando",
    machineUpdatesDone:         "Udført",
    machineUpdatesDoneYes:      "Opdatering udført",
    machineUpdatesDoneNo:       "Opdatering ikke udført",
    machineUpdatesError:        "Fejl under udførelse",
    machineUpdatesErrorYes:     "Fejl under udførelse",
    machineUpdatesErrorNo:      "Ingen fejl under udførelse",
    machineUpdatesFilename:     "Filnavn",
    machineUpdatesDateUpdated:  "Opdateret på",
    machineUpdatesNotUpdated:   "Ikke opdateret endnu",
    machineUpdatesDatePlaced:   "Indsat på",
    machineUpdatesReturnLog:    "Kommandolog",
    machineUpdatesOrigin:       "Indsat af",
    machineUpdatesSentToServer: "Sendt til CDS",
    machineNoUpdates:           "Ingen opdateringer",

    machineUpdatesCancelError:         "Der opstod en fejl under annullering af opdateringen",
    machineUncoupleError:              "Der opstod en fejl ved frakobling af maskinen",
    machineClearUpdatesError:          "Der opstod en fejl ved sletning af opdateringerne",
    machineClearUpdatesAndResendError: "Der opstod en fejl ved sletning af opdateringerne og gensendelse af indholdet",

    machineCommands:                    {
        "download":            "Download",
        "zip_download":        "Download ZIP",
        "command":             "Kommando",
        "delete":              "Slet",
        "send_file":           "Upload fil",
        "apk_install":         "Installer APK",
        "apk_install_restart": "Installer APK & genstart",
        "apk_uninstall":       "Afinstaller APK",
        "setting":             "Indstil indstilling",
        "get_setting":         "Hent indstilling",
        "system_setting":      "Indstil systemindstilling",
        "get_system_setting":  "Hent systemindstilling",
        "send_logs":           "Hent logs",
        "reboot":              "Genstart system",
        "restart":             "Genstart player"
    },
    machineManualUpdatesMachines:       "Maskiner",
    machineManualUpdatesCommand:        "Kommando",
    machineManualUpdatesPath:           "Sti",
    machineManualUpdatesFile:           "Fil",
    machineManualUpdatesSend:           "Send kommando",
    machineManualUpdatesSetting:        "Indstilling",
    machineManualUpdatesSettingSection: "Indstillingskategori",
    machineManualUpdatesSettingName:    "Indstillingsnavn",
    machineManualUpdatesSettingValue:   "Indstillingsværdi",
    machineManualUpdatesSettingType:    "Indstillingstype",
    machineManualUpdatesSuccess:        "Manuel opdatering er succesfuldt sendt.",
    machineManualUpdatesError:          "Der opstod en fejl ved afsendelse af den manuelle opdatering"
};