import React, {useState} from 'react';
import withCaretakers from "../../../hoc/withCaretakers";
import {BottomBar, Tour} from "../../../components";
import {TitleWrapper} from "../../../contexts/TitleProvider";
import i18n from "../../../i18n";
import css from "./Settings.module.css";
import {getPageHelpSteps} from "../../../help/tools";

const SettingsButton = props => {
    return (
        <div className={[css.button, props.className].join(" ")} onClick={props.onClick}><span>{props.text}</span></div>
    );
}

const Settings = props => {
    const [helpOpen, setHelpOpen] = useState(false);
    const doHelp                  = () => setHelpOpen(true);
    const closeHelp               = () => setHelpOpen(false);

    return (
        <TitleWrapper title={i18n.caretakerSettings}>
            <Tour
                isOpen={helpOpen}
                onRequestClose={closeHelp}
                steps={getPageHelpSteps("caretakerSettings")}
            />
            <div>
                <div className="content-list">
                    <SettingsButton
                        className={css.tourEdit}
                        text={i18n.caretakerDetails.replace("%1", props.caretaker ? props.caretaker.name : "")}
                        onClick={() => props.history.push('/caretakers/edit/' + props.caretakerUuid)}
                    />
                    <SettingsButton
                        className={css.tourStatus}
                        text={i18n.caretakerScreenStatus}
                        onClick={() => props.history.push('/caretakers/status/' + props.caretakerUuid)}
                    />
                    <BottomBar
                        backButton
                        backUrl="/"
                        onHelp={doHelp}
                        onCloseHelp={closeHelp}
                        helpOpen={helpOpen}
                    />
                </div>
            </div>
        </TitleWrapper>
    );
};


export default withCaretakers(Settings);