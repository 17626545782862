import React from "react";
import i18n from "../../i18n";
import css from "./tableColumns.module.css";
import {ResponsiveText} from "../../components";
import {standardColumn, defaultColumns} from "./funcs";

export const affiliateColumns = {
    name:     standardColumn("name", i18n.affiliatesTableName, {className: css.name, togglable: false}),
    city:     standardColumn("city", i18n.affiliatesTableCity, {show: false}),
    country:  standardColumn("country", i18n.affiliatesTableCountry, {show: false}),
    phone:    standardColumn("phone", i18n.affiliatesTablePhone, {show: false}),
    email:    standardColumn("email", i18n.affiliatesTableEmail, {show: false}),
    machines: {
        id:              "machines",
        togglable:       false,
        Header:          i18n.affiliatesTableMachines,
        headerClassName: css.machineHeader,
        minWidth:        80,
        maxWidth:        200,
        columns:         [
            {
                id:              "sent",
                Header:          <ResponsiveText long={i18n.affiliatesTableSent} short={i18n.affiliatesTableSentShort} inline/>,
                accessor:        d => d.machines.sent,
                className:       css.counter,
                headerClassName: css.counterHeader,
                minWidth:        40,
                maxWidth:        100
            },
            {
                id:              "coupled",
                Header:          <ResponsiveText long={i18n.affiliatesTableCoupled} short={i18n.affiliatesTableCoupledShort} inline/>,
                accessor:        d => d.machines.coupled,
                className:       css.counter,
                headerClassName: css.counterHeader,
                minWidth:        40,
                maxWidth:        100
            }
        ]
    }
};

export const affiliateColumnsDefault = (actions = null, selector = null) => defaultColumns(
    [
        affiliateColumns.name,
        affiliateColumns.city,
        affiliateColumns.country,
        affiliateColumns.machines
    ], actions, "uuid", selector
);
