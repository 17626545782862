import {call, put, select, takeLatest} from "redux-saga/effects";
import {push} from "connected-react-router";
import * as actions from "../actions";
import * as actionTypes from "../actionTypes";
import * as api from "../../api";
import i18n from "../../i18n";

// Watcher
export function* watchAffiliates() {
    yield takeLatest(actionTypes.AFFILIATES_LOAD, loadSaga);
    yield takeLatest(actionTypes.AFFILIATES_LOAD_IF_NEEDED, loadIfNeededSaga);
    yield takeLatest(actionTypes.AFFILIATES_SAVE, saveSaga);
    yield takeLatest(actionTypes.AFFILIATES_REMOVE, removeSaga);
}

// Sagas
function* loadSaga(action) {
    try {
        const response = yield call(api.affiliates.load);
        yield put(actions.affiliates.loadSuccess(response.data.affiliates));
    }
    catch (err) {
        yield put(actions.affiliates.loadFail());
        yield put(actions.messages.errorPush(i18n.affiliatesLoadError));
    }
}

function* loadIfNeededSaga(action) {
    let affiliates = yield select(state => state.affiliates);
    if(!affiliates.loading && !affiliates.affiliatesLoaded) {
        yield put(actions.affiliates.load());
    }
}

function* saveSaga(action) {
    try {
        yield call(api.affiliates.save, action.uuid, action.affiliateDetails);
        yield put(actions.affiliates.saveSuccess());
        yield put(actions.affiliates.load());
        yield put(push("/admin/affiliates"));
        yield put(actions.messages.successPush(i18n.affiliatesEditSuccess));
    }
    catch (err) {
        yield put(actions.affiliates.saveFail());
        yield put(actions.messages.errorPush(i18n.affiliatesEditError));
    }
}

function* removeSaga(action) {
    try {
        yield call(api.affiliates.remove, action.uuid);
        yield put(actions.affiliates.removeSuccess());
        yield put(actions.affiliates.load());
        yield put(push("/admin/affiliates"));
        yield put(actions.messages.successPush(i18n.affiliatesRemoveSuccess));
    }
    catch (err) {
        yield put(actions.affiliates.removeFail());
        yield put(actions.messages.errorPush(i18n.affiliatesRemoveError));
    }
}