export default {
    calendarTypes:        {
        appointments: "Afspraken",
        birthdays:    "Fødselsdage",
        reminders:    "Påmindelse",
        tvshows:      "TV-program"
    },
    calendarTypeSingular: {
        appointment: "Aftaler",
        birthday:    "Fødselsdage",
        reminder:    "Påmindelser",
        tvshow:      "TV-programmer"
    },
    calendarManageTitle:  {
        appointments: "Tilføj aftale for %1",
        birthdays:    "Tilføj fødselsdag for %1",
        reminders:    "Tilføj påmindelse for %1",
        tvshows:      "Tilføj TV-program for %1"
    },
    calendarAddTitle:     {
        /*        appointments: "Tilføj aftale for %1",
                birthdays:    "Tilføj fødselsdag for %1",
                reminders:    "Tilføj påmindelse for %1",
                tvshows:      "Tilføj TV-program for %1"*/
        appointments: "Aftale",
        birthdays:    "Fødselsdag",
        reminders:    "Påmindelse",
        tvshows:      "TV-program"
    },
    calendarEditTitle:    {
        /*        appointments: "Ændre aftale for %1",
                birthdays:    "Ændre fødselsdag for %1",
                reminders:    "Ændre påmindelse for %1",
                tvshows:      "Ændre TV-program for %1"*/
        appointments: "Aftale",
        birthdays:    "Fødselsdag",
        reminders:    "Påmindelse",
        tvshows:      "TV-program"
    },

    calendarOverview:           "Kalenderoversigt%1",
    calendarDay:                "%1",
    calendarAllItems:           "Se alle planlagte elementer",
    calendarLoadError:          "Der opstod en fejl ved indlæsning af kalenderen",
    calendarAddItem:            "Tilføj nyt element",
    calendarAddItemFor:         "Tilføj nyt element for %1",
    calendarDeleteItem:         "Fjern element",
    calendarDeleteTitle:        "Er du sikker på, at du vil slette dette element?",
    calendarDeleteBody:         "Er du sikker på, at du vil slette elementet '%1'?",
    calendarDeleteOverviewBody: "Er du sikker på, at du vil slette elementet '%1'? Dette vil slette det komplette element, ikke kun dette for denne dag!",
    calendarDeleteSuccess:      "Elementet blev succesfuldt slettet",
    calendarDeleteError:        "Der opstod en fejl ved sletning af elementet",
    calendarDefaultSettings:    "Standardindstillinger",

    calendarDayOverview: "Oversigt",
    calendarDayTimeline: "Tidslinje",

    calendarSaveSuccess: {
        appointments: "Aftalen blev gemt succesfuldt",
        birthdays:    "Fødselsdagen blev gemt succesfuldt",
        reminders:    "Påmindelsen blev gemt succesfuldt",
        tvshows:      "TV-programmet blev gemt succesfuldt"
    },
    calendarSaveFail:    {
        appointments: "Aftalen kunne ikke gemmes",
        birthdays:    "Fødselsdagen kunne ikke gemmes",
        reminders:    "Påmindelsen kunne ikke gemmes",
        tvshows:      "TV-programmet kunne ikke gemmes"
    },

    calendarEditFormTitle:                 "Besked",
    calendarEditFormShowName:              "Programtitel",
    calendarEditFormChannel:               "Kanal",
    calendarEditWholeDay:                  "Hele dagen",
    calendarEditWholeDayYes:               "Varer hele dagen",
    calendarEditWholeDayNo:                "Varer ikke hele dagen",
    calendarEditFormDate:                  "Dato",
    calendarEditFormStart:                 "Fra",
    calendarEditFormEnd:                   "Til",
    calendarEditFormEndDate:               "Til og med",
    calendarEditFormShowBefore:            "Vis aftale",
    calendarEditFormNotifyBefore:          "Lydmeddelelse før",
    calendarEditFormRecurrence:            "Gentag",
    calendarEditFormRecurrenceDetailed:    "Gentag dette elementerhaal dit item",
    calendarEditFormInterval:              "Gentag",
    calendarEditFormIntervalEndDate:       "Slutdato for gentagelse",
    calendarEditFormIntervalHasEndDate:    "Har slutdato",
    calendarEditFormIntervalHasEndDateYes: "Stop gentagelse på en bestemt dato",
    calendarEditFormIntervalHasEndDateNo:  "Gentagelse har ingen slutdato",
    calendarEditFormDaysOfWeek:            "Ugedage",
    calendarEditFormDontShowEnd:           "Vis ikke sluttid",
    calendarEditFormDontShowEndYes:        "Vis sluttid",
    calendarEditFormDontShowEndNo:         "Skjul sluttid",
    calendarEditFormShowAbout:             "Vis cirka",
    calendarEditFormShowAboutYes:          "Vis 'cirka'",
    calendarEditFormShowAboutNo:           "Vis ikke 'cirka'",
    calendarEditFormNotification:          "Notifikation",
    calendarEditFormNotificationYes:       "Lydsignal og blinkende aktiv",
    calendarEditFormNotificationNo:        "Ingen notifikation (lyd)",
    calendarEditFormOverrideLimit:         "Begræns påmindelse",
    calendarEditFormOverrideLimitNo:       "Begræns påmindelse til 3 på samme tid",
    calendarEditFormOverrideLimitYes:      "Vis altid påmindelse",

    calendarEditBirthdayName:                "Navn",
    calendarEditBirthdayIsCaretaker:         "Plejepersonen selv",
    calendarEditBirthdayIsCaretakerExtended: "Dette er plejepersonen selv",
    calendarEditBirthdayIsCaretakerDisabled: "En anden fødselsdag er allerede markeret som plejepersonen selv",
    calendarEditBirthdayBirthday:            "Fødselsdato",
    calendarEditBirthdayShowAge:             "Alder",
    calendarEditBirthdayShowAgeYes:          "Vis alder",
    calendarEditBirthdayShowAgeNo:           "Vis ikke alder",
    calendarEditBirthdayShowPhone:           "Vis telefonnummer",
    calendarEditBirthdayShowPhoneYes:        "Vis telefonnummer",
    calendarEditBirthdayShowPhoneNo:         "Vis ikke telefonnummer",
    calendarEditBirthdayPhoneNumber:         "Telefonnummer",
    calendarEditBirthdayShowBefore:          "Vis fødselsdag",

    calendarDefaultSettingsTitle:           "Standardindstillinger for nye elementer",
    calendarDefaultSettingsForAppointments: "Standardindstillinger for nye aftaler",
    calendarDefaultSettingsForReminders:    "Standardindstillinger for nye påmindelser",
    calendarDefaultSettingsForBirthdays:    "Standardindstillinger for nye fødselsdage",
    calendarDefaultSettingsShowAbout:       "Vis 'cirka'",
    calendarDefaultSettingsShowAboutYes:    "Vis 'cirka' i aftaler",
    calendarDefaultSettingsShowAboutNo:     "Vis ikke 'cirka' i aftaler",
    calendarDefaultSettingsDontShowEnd:     "Vis ikke sluttid",
    calendarDefaultSettingsDontShowEndYes:  "Vis sluttid",
    calendarDefaultSettingsDontShowEndNo:   "Skjul sluttid",

    calendarNextTime:             "Næste gang",
    calendarSkipNextTime:         "Spring næste gang over",
    calendarSkipNextTimeMessage:  "Spring over næste gang. Dette sikrer, at aftalen ikke vises på skærmene næste gang.",
    calendarSkipSuccess:          "Aftalen springes over 1 gang",
    calendarSkipFail:             "Aftalen kan ikke springes over",
    calendarResetSkip:            "Spring ikke over aftale mere",
    calendarResetSkipMessage:     "Spring ikke over næste gang. Dette sikrer, at aftalen igen vises på den normale dag og ikke springes over.",
    calendarResetSkipSuccess:     "Aftalen vises igen som oprindeligt planlagt",
    calendarResetSkipFail:        "Aftalen kan ikke gendannes",
    calendarDifferentDaysTitle:   "Forskellige dage",
    calendarDifferentDaysWarning: "Aftalen starter og slutter på forskellige dage. Dette er ikke nødvendigt for tilbagevendende aftaler. Er du sikker på, at du vil gøre dette?",
    calendarStartTimeChanged:     "Bemærk: starttiden er justeret, fordi den var før sluttiden.",

    calendarOnChannel: " på ",
    calendarDate:      "Dato",
    calendarTime:      "Tid",
    calendarWholeDay:  "Hele dagen",

    calendarRecurrences: {
        options:            {
            "none":     "Nej",
            "interval": "Periodisk (hver x dag)",
            "weekdays": "Ugedage"
        },
        days:               {
            1: "1 dag",
            2: "2 dage",
            3: "3 dage",
            4: "4 dage",
            5: "5 dage",
            6: "6 dage"
        },
        weeks:              {
            1: "1 uge",
            2: "2 uger",
            3: "3 uger",
            4: "4 uger",
            6: "6 uger",
            8: "8 uger"
        },
        months:             {
            1: "1 måned",
            2: "2 måneder",
            3: "3 måneder",
            4: "4 måneder",
            5: "5 måneder",
            6: "6 måneder",
            9: "9 måneder"
        },
        years:              {
            1:  "1 år",
            2:  "2 år",
            3:  "3 år",
            4:  "4 år",
            5:  "5 år",
            10: "10 år"
        },
        every:              {
            day:           "Hver dag",
            week:          "Hver uge",
            month:         "Hver måned",
            year:          "Hvert år",
            multipleDay:   "Hver %1 dag",
            multipleWeek:  "Hver %1 uge",
            multipleMonth: "Hver %1 måned",
            multipleYear:  "Hver %1 år",
            weekday:       "Hver"
        },
        at:                 {
            time:     " om ",
            day:      " på ",
            dayMonth: " på den ",
            starting: " fra "
        },
        showBefore:         {
            "15 minutes": "15 minutter før",
            "30 minutes": "30 minutter før",
            "1 hour":     "1 time før",
            "2 hours":    "2 timer før",
            "3 hours":    "3 timer før",
            "6 hours":    "6 timer før",
            "12 hours":   "12 timer før",
            "1 day":      "1 dag før",
            "2 days":     "2 dage før",
            "3 days":     "3 dage før",
            "4 days":     "4 dage før",
            "5 days":     "5 dage før",
            "6 days":     "6 dage før",
            "1 week":     "1 uge før"
        },
        notifyBefore:       {
            "none":       "Ingen meddelelse før",
            "15 minutes": "15 minutter før",
            "30 minutes": "30 minutter før",
            "45 minutes": "45 minutter før",
            "1 hour":     "1 time før",
            "2 hours":    "2 timer før",
        },
        showBirthdayBefore: {
            "15 minutes": "På dagen selv",
            "1 day":      "1 dag før",
            "2 days":     "2 dage før",
            "3 days":     "3 dage før",
            "4 days":     "4 dage før",
            "5 days":     "5 dage før",
            "6 days":     "6 dage før"
        },
        interval:           {
            "1 day":    "Hver dag",
            "2 days":   "Hver 2. dag",
            "3 days":   "Hver 3. dag",
            "4 days":   "Hver 4. dag",
            "5 days":   "Hver 5. dag",
            "6 days":   "Hver 6. dag",
            "1 week":   "IHver uge",
            "2 weeks":  "Hver 2. uge",
            "3 weeks":  "Hver 3. uge",
            "4 weeks":  "Hver 4. uge",
            "6 weeks":  "Hver 6. uge",
            "8 weeks":  "Hver 8. uge",
            "1 month":  "Hver måned",
            "2 months": "Hver 2. måned",
            "3 months": "Hver 3. måned",
            "4 months": "Hver 4. måned",
            "5 months": "Hver 5. måned",
            "6 months": "Hver 6. måned",
            "9 months": "Hver 9. måned",
            "1 year":   "Hvert år",
            "2 years":  "Hvert 2. år",
            "3 years":  "Hvert 3. år",
            "4 years":  "Hvert 4. år",
            "5 years":  "Hvert 5. år",
            "10 years": "Hvert 10. år"
        },
        noReoccurance:      "Ikke gentagende"
    }
};