import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import app from "./app/reducer";
import auth from "./auth/reducer";
import affiliates from "./affiliates/reducer";
import calendars from "./calendars/reducer";
import caretakers from "./caretakers/reducer";
import caretakerMessages from "./caretakerMessages/reducer";
import contact from "./contact/reducer";
import distributors from "./distributors/reducer";
import groups from "./groups/reducer";
import groupCalendars from "./groupCalendars/reducer";
import groupMessages from "./groupMessages/reducer";
import help from "./help/reducer";
import machines from "./machines/reducer";
import messages from "./messages/reducer";
import users from "./users/reducer";

const createRootReducer = history => {
    const allReducers = combineReducers({
        app,
        auth,
        affiliates,
        calendars,
        caretakers,
        caretakerMessages,
        contact,
        distributors,
        groups,
        groupCalendars,
        groupMessages,
        help,
        machines,
        messages,
        users,
        router: connectRouter(history)
    });

    return (state, action) => {
        if (action.type === "RESET_STATE") {
            state = undefined;
        }
        const newState = allReducers(state, action);
        if(action.type === "RESET_STATE" && action.dontSetAuthenticate) {
            newState.auth.authenticating = false;
        }
        return newState;
    }
};

export default createRootReducer;