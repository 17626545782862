const caretaker  = "Pflege empfänger";
const caretakers = "Pflege empfänger";

export default {
    locationsTitle: "Alle Standorte der " + caretakers,

    caretakers:   caretakers,
    noCaretakers: "Keine " + caretakers,

    caretaker:                    caretaker,
    caretakersTitle:              caretakers,
    caretakersTableName:          "Name",
    caretakersTableLocation:      "Standort",
    caretakersTableAddress:       "Adresse",
    caretakersTableZipCode:       "Postleitzahl",
    caretakersTableCity:          "Ort",
    caretakersTableState:         "Provinz",
    caretakersTableCountry:       "Land",
    caretakersTableTimeZone:      "Zeitzone",
    caretakersTableMachines:      "Maschinen",
    caretakersTableMachinesShort: "M",
    caretakersActionsEdit:        "Bearbeiten",
    caretakersActionsDelete:      "Löschen",

    caretakersFullTableName:     "Name des " + caretaker,
    caretakersFullTableLocation: "Standort des " + caretaker,
    caretakersFullTableAddress:  "Adresse des " + caretaker,
    caretakersFullTableZipCode:  "Postleitzahl des " + caretaker,
    caretakersFullTableCity:     "Pflege empfänger wohnort",
    caretakersFullTableState:    "Pflege empfänger provinz",
    caretakersFullTableCountry:  "Pflege empfänger land",

    caretakersAddNewMachine:                "Füge eine neue Spiqle hinzu",
    caretakersAddNewMachineTitle:           "Spiqle hinzufügen",
    caretakersAddNewMachineScreenCodeLabel: "Bildschirmcode",
    caretakersAddNewMachineScreenName:      "Bildschirmname",
    caretakersAddNewMachineStep2:           caretaker,
    caretakersAddNewMachineStep3:           "Zusammenfassung",
    caretakersAddNewMachineScreenCode:      "Geben Sie den Code ein, der auf dem Bildschirm angezeigt wird",
    caretakersAddNewMachineCaretaker:       "Geben Sie die Details Ihres " + caretaker + " ein oder wählen Sie einen vorhandenen " + caretaker + " aus",
    caretakersAddNewMachineSuccess:         "Die neue Spiqle Agenda wurde erfolgreich hinzugefügt. Sie können jetzt den Inhalt einstellen.",
    caretakersAddNewMachineError:           "Beim Verknüpfen des Bildschirms ist ein Fehler aufgetreten",
    caretakersAddNewMachineInvalidCode:     "Unbekannter Bildschirmcode, überprüfen Sie den Code und versuchen Sie es erneut",
    caretakersAddNewMachineToExisting:      "Fügen Sie diesem " + caretaker + " neues Spiqle hinzu",

    caretakersAddMenu: "Hinzufügen...",

    caretakersAddNewUser:                  "Laden Sie einen neuen Benutzer ein",
    caretakersAddNewUserExplain:           "Mit dieser Funktion können Sie andere Benutzer einladen, Zugriff auf Ihre " +
                                               caretakers +
                                               " zu erhalten. Beispielsweise können Sie die Betreuung mit einem Familienmitglied oder Kollegen teilen.",
    caretakersAddNewUserName:              "Name",
    caretakersAddNewUserEmail:             "E-Mail",
    caretakersAddNewUserCaretakers:        "Wählen Sie die " + caretaker + " aus, auf die der neue Benutzer Zugriff haben soll",
    caretakersAddNewUserRights:            "Wählen Sie aus, was der neue Benutzer tun kann",
    caretakersAddNewUserRightsList:        {
        content: "Terminkalender",
        users:   "Terminkalender und Benutzer",
        payment: "Terminkalender und Verwaltung"
    },
    caretakersInviteSuccess:               "Der neue Benutzer wurde erfolgreich eingeladen",
    caretakersInviteError:                 "Beim Einladen des neuen Benutzers ist ein Fehler aufgetreten",
    caretakersInviteErrorInvitedYourself:  "Sie können sich nicht einladen",
    caretakersInviteErrorUnknownRight:     "Unbekannte Benutzerrechte",
    caretakersInviteErrorInvalidCaretaker: "Unbekannte " + caretaker,

    caretakersInvitedUsers:              "Eingeladene Benutzer",
    caretakersInvitedUsersInvitedOn:     "Eingeladen am",
    caretakersInvitedUsersStatus:        "Status",
    caretakersInvitedUsersStatusUsed:    "Akzeptiert",
    caretakersInvitedUsersStatusExpired: "Einladung abgelaufen",
    caretakersInvitedUsersStatusNotUsed: "Noch nicht akzeptiert",
    caretakersInvitedNoInvites:          "Keine Einladungen versendet",

    caretakersItemAdd:         "Neuen " + caretaker + " hinzufügen",
    caretakersItemEdit:        "Daten " + caretaker + " ändern",
    caretakersItemEditSuccess: "Die Daten des " + caretaker + " wurden erfolgreich gespeichert",
    caretakersItemEditError:   "Beim Speichern der Daten des " + caretaker + ", ist ein Fehler aufgetreten",

    caretakersAddMachine: "Spiqle-Bildschirm hinzufügen",

    caretakersMessage:              "Directe Nachricht",
    caretakersMessageTo:            "Directe Nachricht an %1",
    caretakersMessageSentTo:        "Senden Sie eine Nachricht an %1",
    caretakersMessageMessage:       "Nachricht",
    caretakersMessageDuration:      "Sichtbar für",
    caretakersMessageDurations:     {
        300:   "5 Minuten",
        900:   "15 Minuten",
        1800:  "30 Minuten",
        3600:  "1 Stunde",
        10800: "3 Stunden",
        21600: "6 Stunden",
        43200: "12 Stunden",
        86400: "24 Stunden"
    },
    caretakersMessageUrgent:        "Dringende Nachricht",
    caretakersMessageSuccess:       "Direktnachricht erfolgreich gesendet",
    caretakersMessageError:         "Beim Senden der Direktnachricht ist ein Fehler aufgetreten",
    caretakersMessageCancelSuccess: "Direktnachricht erfolgreich vom Bildschirm entfernt",
    caretakersMessageCancelError:   "Beim Löschen der Direktnachricht ist ein Fehler aufgetreten",
    caretakersMessageNoMachines:    "Dieser " + caretaker + " hat noch keine Bildschirmen",
    buttonCancelMessage:            "Nachricht abbrechen (vom Bildschirm entfernen)",

    caretakersSentMessages:          "Gesendete Nachrichten",
    caretakersSentMessagesMessage:   "Nachricht",
    caretakersSentMessagesSentLong:  "Gesendet",
    caretakersSentMessagesSentShort: "Ges.",
    caretakersSentMessagesSentBy:    "Gesendet von",
    caretakersSentMessagesDelivered: "Auf dem Bildschirm angezeigt",
    caretakersSentMessagesCancelled: "Nachricht abgebrochen",
    caretakersNoSentMessages:        "Noch keine Nachrichten gesendet",

    caretakersItemCalendar:                      "Kalender",
    caretakersItemCalendars:                     "Kalender",
    caretakersItemCalendarsDefaultSettings:      "Standardeinstellungen für Kalender",
    caretakersItemCalendarsEditAppointments:     "Termine verwalten",
    caretakersItemCalendarsEditBirthdays:        "Geburtstage verwalten",
    caretakersItemCalendarsEditTvShows:          "TV-Programme verwalten",
    caretakersItemCalendarsEditReminders:        "Erinnerungen verwalten",
    caretakersItemCalendarsEditItemAppointments: "Termin bearbeiten",
    caretakersItemCalendarsEditItemBirthdays:    "Geburtstag bearbeiten",
    caretakersItemCalendarsEditItemTvShows:      "TV-Programm bearbeiten",
    caretakersItemCalendarsEditItemReminders:    "Erinnerung bearbeiten",
    caretakersItemCalendarsFor:                  "Kalender für %1 verwalten",
    caretakersItemPreview:                       "Bildschirmbeispiel",
    caretakersItemPreviewFor:                    "Bildschirmbeispiel für %1",
    caretakersItemMachines:                      "Maschinen verwalten für %1",
    caretakersItemMachinesTitle:                 "Maschinen verwalten",
    caretakersItemMachinesAdd:                   "Koppeln Sie das Gerät an diesen " + caretaker + " an",
    caretakersSelectCaretaker:                   "Wählen Sie einen " + caretaker + " aus",
    caretakerChooseAddOrExisting:                "Wählen Sie einen bestehenden " + caretaker + " aus oder fügen Sie einen hinzu",
    caretakerCreateNewTitle:                     "Geben Sie die Details des neuen " + caretaker + " ein",
    caretakerCreateNewCaretaker:                 "Neuen " + caretaker + " hinzufügen",

    caretakersRemoveTitle:   caretaker + " Löschen",
    caretakersRemoveBody:    "Wenn Sie den " +
                                 caretaker +
                                 " mit dem Namen '% 1' löschen, werden alle Computer, die derzeit mit dieser Person verknüpft sind, getrennt. Dies kann nicht rückgängig gemacht werden.",
    caretakersRemoveSure:    caretaker + " Löschen",
    caretakersRemoveSuccess: "Der " + caretaker + " wurde erfolgreich gelöscht",
    caretakersRemoveError:   "Beim Löschen des " + caretaker + " ist ein Fehler aufgetreten",

    caretakersDayOverview:      "Tagesübersicht",
    caretakersDayOverviewFor:   "Tagesübersicht für %1",
    caretakersWeekOverviewFor:  "Wochenübersicht für %1",
    caretakersMonthOverviewFor: "Monatsübersicht für %1",
    caretakersOverviewTime:     "Zeit",
    caretakersOverviewNoItems:  "An diesem Tag gibt es keine Tagesordnungspunkte",

    caretakerSettings:                   "Einstellungen",
    caretakerSettingsFor:                "Verwalten Sie die Einstellungen für %1",
    caretakerSettingsTheme:              "Theme",
    caretakerSettingsThemeValues:        {
        black:               "Schwarz",
        blackboard:          "Kreide",
        emeraldgreen:        "Dunkelgrün",
        lemongrass:          "Zitronengras",
        nightblue:           "Dunkelblau",
        papaver:             "Mohn",
        pumpkin:             "Kürbis",
        steelblue:           "Stahlblau",
        yellow:              "Kontrast gelb",
        sockgreenolive:      "Grüne Ummantelung: Olive",
        sockgreenkhaki:      "Grüne Ummantelung: Khaki",
        sockdewcorn:         "Tau Ummantelung: Maisseide",
        sockdewwheat:        "Tau Ummantelung: Weizen",
        sockperuyellowbrown: "Peru Ummantelung: Gelbbraun",
        sockperusienna:      "Peru Ummantelung: Sienna",
        modernblue:          "Modern - Blau",
        modernbrown:         "Modern - Braun",
        moderngreen:         "Modern - Grün",
        moderndeepsea:       "Modern - Tiefsee",
        modernblack:         "Modern - Schwarz"
    },
    caretakerSettingsFont:               "Schriftart",
    caretakerSettingsFontValues:         {
        helvetica: "Helvetica",
        calibri:   "Calibri",
        gillsans:  "Gill sans"
    },
    caretakerSettingsLanguage:           "Sprache",
    caretakerSettingsLanguageValues:     {
        nl: "Niederländisch",
        da: "Dänisch",
        de: "Deutsch",
        en: "Englisch"
    },
    caretakerSettingsGreeting:           "Begrüßung",
    caretakerSettingsClock:              "Uhr",
    caretakerSettingsClockValues:        {
        digital:    "Digital  11:00",
        digitalext: "Digital  11:00 Morgen",
        analog:     "Analog  🕘",
        written:    "Geschrieben (Fünf nach elf)"
    },
    caretakerSettingsDAT:                "Übermorgen",
    caretakerSettingsDATValues:          {
        dat:     "Übermorgen",
        "2days": "In 2 Tage"
    },
    caretakerSettingsHours:              "24/12 Stunden Uhr",
    caretakerSettingsHoursValues:        {
        12: "12 Stunden  Uhr (viertel nach 2)",
        24: "24 Stunden Uhr (14:15)"
    },
    caretakerSettingsSpeed:              "Nachrichtengeschwindigkeit",
    caretakerSettingsSpeedValues:        {
        1: "Schnell (10 Sekunden)",
        2: "Normal (20 Sekunden)",
        3: "Langsam (40 Sekunden)",
        4: "Sehr langsam (60 Sekunden)",
        5: "Noch langsamer (120 Sekunden)"
    },
    caretakerSettingsTomorrowDay:        "Morgen",
    caretakerSettingsTomorrowDayYes:     "Zeigen Sie den Tag an, wenn der Termin morgen ist",
    caretakerSettingsTomorrowDayNo:      "Zeigen Sie nicht den Tag an, wenn der Termin morgen ist",
    caretakerSettingsItIsToday:          "Heute zeigen",
    caretakerSettingsItIsTodayYes:       "Zeigen Sie 'heute' in der Begrüßung",
    caretakerSettingsItIsTodayNo:        "Zeigen Sie in der Begrüßung nicht 'Heute' an",
    caretakerSettingsShowHourToday:      "Zeigt 'Stunde' nach Zeit an",
    caretakerSettingsShowHourTodayYes:   "Zeigt 'Stunde' nach Zeit an",
    caretakerSettingsShowHourTodayNo:    "Zeigt nicht 'Stunde' nach Zeit an",
    caretakerSettingsShowCountdown:      "Countdown bis zum Termin (noch x Stunden)",
    caretakerSettingsShowCountdownYes:   "Countdown bis zum Termin (noch x Stunden)",
    caretakerSettingsShowCountdownNo:    "Countdown bis zum Termin nicht anzeigen",
    caretakerSettingsShowWeather:        "Wettervorhersage für Heute",
    caretakerSettingsShowWeatherYes:     "Zeige das aktuelle Wetter",
    caretakerSettingsShowWeatherNo:      "Zeige nicht das aktuelle Wetter",
    caretakerSettingsTomorrowWeather:    "Wettervorhersage für Morgen",
    caretakerSettingsTomorrowWeatherYes: "Zeigen Sie das Wetter für morgen",
    caretakerSettingsTomorrowWeatherNo:  "Zeigen Sie nicht das Wetter für morgen",

    caretakerSettingsPublicHolidaysShowBefore:       "Zeige FeierTage ab",
    caretakerSettingsPublicHolidaysShowBeforeValues: {
        "15 minutes": "Der Tag selbst",
        "1 day":      "1 Tag im voraus",
        "2 days":     "2 Tage vorher",
        "3 days":     "3 Tage vorher",
        "4 days":     "4 Tage vorher",
        "5 days":     "5 Tage vorher",
        "6 days":     "6 Tage vorher",
        "1 week":     "1 Woche vorher"
    },
    caretakerSettingsPublicHolidaysCountry:          "Zeige FeierTage für",
    caretakerSettingsPublicHolidaysCountryValues:    {
        nl: "Niederlande",
        be: "Belgien",
        da: "Dänemark",
        de: "Deutschland",
        uk: "Großbritannien",
        ch: "Schweiz"
    },
    caretakerSettingsPublicHolidays:                 "Feiertage",
    caretakerSettingsPublicHolidaysYes:              "Feiertage anzeigen",
    caretakerSettingsPublicHolidaysNo:               "Feiertage nicht anzeigen",

    caretakerSettingsNotificationSound: "Sound Benachrichtigung",
    caretakerSettingsNotificationYes:   "Verwenden Sie ein Ton mit Benachrichtigungen",
    caretakerSettingsNotificationNo:    "Verwenden Sie keinen Ton mit Benachrichtigungen",

    caretakerSettingsErrorLoad:   "Beim Laden der Einstellungen des " + caretaker + " ist ein Fehler aufgetreten",
    caretakerSettingsErrorSave:   "Beim Speichern der Einstellungen des " + caretaker + " ist ein Fehler aufgetreten",
    caretakerSettingsSuccessSave: "Die Einstellungen des " + caretaker + " wurden erfolgreich gespeichert",

    caretakerDefaultsErrorLoad:   "Beim Laden der Standardeinstellungen des " + caretaker + " ist ein Fehler aufgetreten",
    caretakerDefaultsErrorSave:   "Beim Speichern der Standardeinstellungen des " + caretaker + " ist ein Fehler aufgetreten",
    caretakerDefaultsSuccessSave: "Die Standardeinstellungen des " + caretaker + " wurden erfolgreich gespeichert",

    caretakerMachineNameErrorSave:   "Beim Speichern des Bildschirmnamens ist ein Fehler aufgetreten",
    caretakerMachineNameSuccessSave: "Der Name des Bildschirms wurde erfolgreich gespeichert",

    caretakerDetails:           "Angaben %1",
    caretakerScreenSettings:    "Bildschirmeinstellungen",
    caretakerScreenStatus:      "Spiqle-Bildschirme",
    caretakerScreenStatusTitle: "Bildschirme %1",
    caretakerScreenName:        "Bildschirmname:",
    caretakerScreenStatusHead:  "Bildschirmstatus:",

    caretakerName:            "Name",
    caretakerAddress:         "Adresse",
    caretakerZipcode:         "Postleitzahl",
    caretakerCity:            "Ort",
    caretakerState:           "Provinz",
    caretakerCountry:         "Land",
    caretakerPhone:           "Telefonnummer",
    caretakerPhoto:           "Foto",
    caretakerPhotoPick:       "Wählen Sie ein anderes Foto",
    caretakerPhotoRemove:     "Foto entfernen",
    caretakerBirthday:        "Geburtstag",
    caretakerShowBirthday:    "Geburtstag im Kalender anzeigen",
    caretakerShowBirthdayYes: "Im Kalender anzeigen",
    caretakerShowBirthdayNo:  "Nicht im Kalender anzeigen",

    errorCaretakersLoad:         "Beim Laden der Benutzerdaten ist ein Fehler aufgetreten",
    caretakersLoadMachinesError: "Beim Laden der Maschinen vom Benutzer ist ein Fehler aufgetreten",

    photoAdd:    "Hinzufügen",
    photoEdit:   "Ersetzen",
    photoDelete: "Löschen"
};