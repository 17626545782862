import React, {Component} from 'react';
import {connect} from "react-redux";

import * as actions from "../../../store/actions";
import i18n from "../../../i18n";
import tableColumns from "../../../variables/tableColumns";

import {Button, Spinner, ColumnSelector, ReactTable, BottomBar} from "../../../components";
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {TitleWrapper} from "../../../contexts/TitleProvider";

class Affiliates extends Component {
    state = {
        modalDelete: false,
        deleteUUID:  "",
        deleteName:  "",
        columns:     null
    };

    editAffiliate   = uuid => this.props.history.push("/admin/affiliates/edit/" + uuid);
    deleteAffiliate = (uuid, name) => {
        this.setState({modalDelete: true, deleteUUID: uuid, deleteName: name})
    };

    tableColumns = tableColumns.affiliateColumnsDefault([
        {
            color:  "info",
            icon:   "pen",
            action: this.editAffiliate,
            text:   i18n.affiliatesActionsEdit
        },
        {
            color:  "danger",
            icon:   "e-remove",
            action: this.deleteAffiliate,
            text:   i18n.affiliatesActionsDelete
        }
    ]);

    constructor(props) {
        super(props);
        this.state.columns = this.tableColumns;
        props.load();
    }

    handleDelete      = () => {
        this.props.remove(this.state.deleteUUID);
        this.setState({modalDelete: false});
    };
    toggleModalDelete = () => this.setState(state => ({modalDelete: !state.modalDelete}));

    /*
                            <Button color="default" round icon simple onClick={() => this.props.load()} className="reload-button">
                            <i className="now-ui-icons icon-reload"/>
                        </Button>
     */
    render = () => (
        <TitleWrapper title={i18n.routeAdminAffiliatesFull}>
            <div>
                <div className="content">
                    {this.props.affiliates && !this.props.loading && !this.props.saving ? (
                        <>
                            <ColumnSelector cookieId="adminAffiliates" columns={this.state.columns} changed={columns => this.setState({columns})}/>
                            <ReactTable
                                filterable
                                data={this.props.affiliates}
                                columns={this.state.columns}
                                defaultSorted={[{id: "name", desc: false}]}
                                noDataText={i18n.noAffiliates}
                            />
                        </>
                    ) : <Spinner/>}
                    <BottomBar backButton links={[{title: i18n.affiliatesAddTitle, link: "/admin/affiliates/add"}]}/>
                </div>
                <Modal isOpen={this.state.modalDelete} toggle={this.toggleModalDelete} className="text-center">
                    <ModalHeader className="justify-content-center uppercase title" tag="h4">{i18n.affiliatesRemoveTitle}</ModalHeader>
                    <ModalBody><p>{i18n.affiliatesRemoveBody.replace('%1', this.state.deleteName)}</p></ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.handleDelete}>{i18n.affiliatesRemoveSure}</Button>
                        <Button color="info" onClick={this.toggleModalDelete}>{i18n.buttonCancel}</Button>
                    </ModalFooter>
                </Modal>
            </div>
        </TitleWrapper>
    );
}

const mapStateToProps    = state => ({
    loading:    state.affiliates.loading,
    saving:     state.affiliates.saving,
    affiliates: state.affiliates.affiliates,
    loadedAt:   state.affiliates.affiliatesLoaded
});
const mapDispatchToProps = dispatch => ({
    load:   () => dispatch(actions.affiliates.load()),
    remove: uuid => dispatch(actions.affiliates.remove(uuid))
});

export default connect(mapStateToProps, mapDispatchToProps)(Affiliates);
