// React
import React from "react";

// Redux
import {connect} from "react-redux";
import {push} from 'connected-react-router'
import * as actions from "../../../../store/actions";

// Tools
import i18n from "../../../../i18n";

// Components
import {Button, Form, Spinner, BottomBar} from "../../../../components";
import {filterFormValues} from "../../../../components/Form/formTools";
import {TitleWrapper} from "../../../../contexts/TitleProvider";

const affiliateForm = {
    name: {label: i18n.affiliateName},
    row1: {
        type:  "row",
        items: {
            address: {label: i18n.affiliateAddress, size: 8},
            zipcode: {label: i18n.affiliateZipcode, size: 4, validation: {required: true, minLength: 4}},
        }
    },
    row2: {
        type:  "row",
        items: {
            city:    {label: i18n.affiliateCity, size: 4},
            state:   {label: i18n.affiliateState, size: 4},
            country: {label: i18n.affiliateCountry, size: 4}
        }
    },
    row3: {
        type:  "row",
        items: {
            phone: {label: i18n.affiliatePhone, size: 6},
            email: {label: i18n.affiliateEmail, size: 6, validation: {required: true, isEmail: true}}
        }
    }
};

class Edit extends React.Component {
    state = {
        uuid:       null,
        affiliate:  null,
        formValues: {},
        formValid:  false
    };

    constructor(props) {
        super(props);
        this.state.uuid = props.match.params.affiliate;
        if (this.state.uuid.length !== 32) {
            props.push("/admin/affiliates");
            return;
        }

        if (props.affiliates) {
            this.state.affiliate  = this.props.affiliates.find(obj => obj.uuid === this.state.uuid);
            this.state.formValues = filterFormValues(affiliateForm, this.state.affiliate);
        } else {
            if (!props.loading) {
                props.load();
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props === prevProps) {
            return;
        }

        // If the affiliate store suddenly changed, update the details
        if (this.props.affiliates && this.props.affiliates !== prevProps.affiliates && this.props.affiliates.length > 0) {
            let uuid      = this.props.match.params.affiliate;
            let affiliate = this.props.affiliates.find(obj => obj.uuid === uuid);
            if (affiliate) {
                this.setState({affiliate, formValues: filterFormValues(affiliateForm, affiliate)});
            } else {
                // Unknown affiliate, go back to caretaker overview
                this.props.push("/admin/affiliates");
            }
        }
    }

    handleChange = (formValues, formValid) => this.setState({formValues, formValid});
    handleValid  = formValid => this.setState({formValid});
    handleSubmit = () => this.state.formValid && this.props.save(this.state.uuid, this.state.formValues);

    render() {
        return (
            <TitleWrapper title={i18n.affiliatesEditTitle}>
                <div>
                    <div className="content">
                        {this.state.affiliate && !this.props.loading && !this.props.saving ? (
                            <>
                                <Form
                                    formData={affiliateForm}
                                    values={this.state.formValues}
                                    onChange={this.handleChange}
                                    onValid={this.handleValid}
                                    stacked
                                />
                                <Button round color="info" block disabled={!this.state.formValid} onClick={this.handleSubmit}>
                                    {i18n.buttonSave}
                                </Button>
                            </>
                        ) : <Spinner/>}
                        <BottomBar backButton/>
                    </div>
                </div>
            </TitleWrapper>
        );
    }
}

const mapStateToProps = state => ({
    loading:    state.affiliates.loading,
    saving:     state.affiliates.saving,
    affiliates: state.affiliates.affiliates
});

const mapDispatchToProps = dispatch => ({
    push: pathname => dispatch(push(pathname)),
    load: () => dispatch(actions.affiliates.load()),
    save: (uuid, details) => dispatch(actions.affiliates.save(uuid, details))
});

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
