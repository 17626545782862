import React from 'react';
import css from "./Overview.module.css";
import {useHistory, useParams} from "react-router-dom";
import i18n from "../../../i18n";

const ListItem = props => {
    const params      = useParams();
    const history     = useHistory();
    let title         = "";
    let link          = "";
    let selectedClass = "";
    let timeDisplay   = "";
    if (props.birthday || (props.currentDate.start === "00:00" && props.currentDate.end === "23:59")) {
        timeDisplay = i18n.calendarWholeDay;
    } else {
        timeDisplay = props.currentDate.start + " - " + props.currentDate.end;
    }

    switch (props.itemType) {
        case "birthday":
            selectedClass = css.birthday;
            title         = props.name;
            link          = "/caretakers/calendar/birthdays/" + params.caretaker + "/edit/" + props.uuid;
            break;
        case "appointment":
            selectedClass = css.appointment;
            title         = props.message;
            link          = "/caretakers/calendar/appointments/" + params.caretaker + "/edit/" + props.uuid;
            break;
        case "reminder":
            selectedClass = css.reminder;
            title         = props.message;
            link          = "/caretakers/calendar/reminders/" + params.caretaker + "/edit/" + props.uuid;
            break;
        case "tvshow":
            selectedClass = css.tvshow;
            title         = props.showName;
            link          = "/caretakers/calendar/tvshows/" + params.caretaker + "/edit/" + props.uuid;
            break;
        default:
    }

    return (
        <div className={css.listItem}>
            <div className={css.listItemContent + " " + selectedClass} onClick={() => history.push(link)}>
                <span className={css.listItemTime}>
                    {timeDisplay}
                </span>
                <span className={css.listItemName}>
                    {title}
                </span>
            </div>
        </div>
    );
};

export default ListItem;