// React
import React from "react";
// Redux
import {connect} from "react-redux";
// Tools
import i18n from "../../../i18n";
// Components
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {BottomBar, Button, ButtonCrusher, Spinner, Tour} from "../../../components";
import EditAppointment from "./EditAppointment";
import EditBirthday from "./EditBirthday";
import EditReminder from "./EditReminder";
import EditTvShow from "./EditTvShow";
import withGroups from "../../../hoc/withGroups";
import moment from "moment-timezone";
import * as actions from "../../../store/actions";
import {getPageHelpSteps} from "../../../help/tools";
import {TitleWrapper} from "../../../contexts/TitleProvider";
import DeleteSure from "../Modals/DeleteSure";
import NextTime from "./NextTime";

const itemMapping = {
    appointments: EditAppointment,
    birthdays:    EditBirthday,
    reminders:    EditReminder,
    tvshows:      EditTvShow
};

const helpMapping = {
    appointments: "caretakerEditAppointment",
    birthdays:    "caretakerEditBirthday",
    reminders:    "caretakerEditReminder",
    tvshows:      "caretakerEditTvProgram"
};

class CalendarEdit extends React.PureComponent {
    state = {
        item:                {},
        valid:               false,
        enableSelfSelection: false,
        multipleDaySureOpen: false,
        helpOpen:            false,
        deleteOpen:          false
    };

    constructor(props) {
        super(props);

        this.calendarType = props.calendarType;
        this.itemId       = props.match.params.item;

        if (!itemMapping.hasOwnProperty(this.calendarType)) {
            props.history.push("/");
            return;
        }

        if (this.itemId === "new" && this.calendarType !== "birthdays") {
            this.state.item = {newItem: true};
        } else {
            let item = this.checkCalendarsLoaded();
            if (item) {
                this.state = {...this.state, item};
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.itemId === "new" && this.calendarType !== "birthdays") || this.props === prevProps) {
            return;
        }
        let item = this.checkCalendarsLoaded();
        if (item) {
            this.setState(item);
        }
    }

    doHelp    = () => this.setState({helpOpen: true});
    closeHelp = () => this.setState({helpOpen: false});

    // Check if we need to reload the calendars
    checkCalendarsLoaded = () => {
        if (this.props.group && !this.props.loadingCalendars && (
            this.props.calendarsFor !== this.props.group.uuid ||
            (!this.props.loadedAt || this.props.loadedAt.isBefore(moment().subtract(5, "minutes")))
        )) {
            this.props.load(this.props.group.uuid)
        }

        if (this.props.group && this.props.calendars && this.props.calendarsFor === this.props.group.uuid) {
            if (!this.props.calendars.hasOwnProperty(this.calendarType)) {
                this.props.history.push("/");
            } else {
                if (this.itemId === "new") {
                    return {item: {newItem: true}};
                } else {
                    let calendarItem = this.props.calendars[this.calendarType].find(obj => obj.uuid === this.itemId);
                    if (!calendarItem) {
                        this.props.history.push("/");
                    } else {
                        if (this.state.item !== calendarItem) {
                            return {item: calendarItem};
                        }
                    }
                }
            }
        }
        return null;
    };

    handleSave = () => {
        if (!this.state.valid) {
            this.props.errorPush(i18n.errorForm);
            return;
        }

        if (this.calendarType === "appointments" || this.calendarType === "reminders" || this.calendarType === "tvshows") {
            if (!this.state.item.start.isSame(this.state.item.end, "day")) {
                this.setState({multipleDaySureOpen: true});
                return;
            }
        }

        let saveItem = {...this.state.item};
        if (this.itemId !== "new") {
            saveItem.uuid = this.itemId;
        }

        switch (this.calendarType) {
            case "appointments":
                this.props.saveAppointment(this.props.group.uuid, saveItem);
                break;
            case "birthdays":
                this.props.saveBirthday(this.props.group.uuid, saveItem);
                break;
            case "reminders":
                this.props.saveReminder(this.props.group.uuid, saveItem);
                break;
            case "tvshows":
                this.props.saveTvShow(this.props.group.uuid, saveItem);
                break;
            default:
        }
    };

    multipleDaySure = () => {
        let saveItem = {...this.state.item};
        if (this.itemId !== "new") {
            saveItem.uuid = this.itemId;
        }

        switch (this.calendarType) {
            case "appointments":
                this.props.saveAppointment(this.props.group.uuid, saveItem);
                break;
            case "reminders":
                this.props.saveReminder(this.props.group.uuid, saveItem);
                break;
            case "tvshows":
                this.props.saveTvShow(this.props.group.uuid, saveItem);
                break;
            default:
        }
        this.setState({multipleDaySureOpen: false});
    };
    handleBack      = () => this.props.history.goBack();
    setValues       = (values, valid) => this.setState({item: values, valid});
    setValid        = valid => this.setState({valid});
    hideMultipleDay = () => this.setState({multipleDaySureOpen: false});

    render() {
        if (!itemMapping.hasOwnProperty(this.calendarType)) {
            return <div/>;
        }

        const RenderItem = itemMapping[this.calendarType];
        let skipButton   = null;
        if (this.itemId !== "new" && this.calendarType !== "birthdays") {
            if (this.state.item.skipped) {
                skipButton = <Button
                    upper
                    block
                    round
                    color="warning"
                    onClick={() => this.props.unskipItem(this.props.groupUuid, this.props.calendarType, this.itemId)}
                >{i18n.calendarResetSkip}</Button>;
            } else {
                skipButton = <Button
                    upper
                    block
                    round
                    color="warning"
                    onClick={() => this.props.skipItem(this.props.groupUuid, this.props.calendarType, this.itemId)}
                >{i18n.calendarSkipNextTime}</Button>;
            }
        }

        return (
            <TitleWrapper
                title={(this.itemId === "new" ? i18n.calendarAddTitle[this.calendarType] : i18n.calendarEditTitle[this.calendarType]).replace("%1", this.props.group ? this.props.group.name : i18n.group)}>
                <div>
                    <div className="content-form">
                        <Tour
                            isOpen={this.state.helpOpen}
                            onRequestClose={this.closeHelp}
                            steps={getPageHelpSteps(helpMapping[this.calendarType])}
                        />
                        <RenderItem
                            item={this.state.item}
                            group={this.props.group}
                            enableSelfSelection={this.state.enableSelfSelection}
                            newDate={this.props.match.params.date ? this.props.match.params.date : null}
                            setValues={this.setValues}
                            setValid={this.setValid}
                        />
                        {this.props.loadingCalendars || this.props.skipping ? <Spinner/> : null}
                        <div style={{height: "23px"}}/>
                        {this.itemId !== "new" && this.calendarType !== "birthdays" && <NextTime item={this.state.item}/>}
                        <ButtonCrusher width={84}>
                            {this.itemId !== "new" && this.calendarType !== "birthdays" && skipButton}
                            <Button upper block round color="info" onClick={this.handleSave}>{i18n.buttonSave}</Button>
                            {
                                this.itemId !== "new" &&
                                <Button upper block round color="danger" onClick={() => this.setState({deleteOpen: true})}>{i18n.calendarDeleteItem}</Button>

                            }
                        </ButtonCrusher>
                        <BottomBar backButton onHelp={this.doHelp} onCloseHelp={this.closeHelp} helpOpen={this.state.helpOpen}/>
                    </div>
                    <Modal isOpen={this.state.multipleDaySureOpen} toggle={this.hideMultipleDay} className="text-center">
                        <ModalHeader className="justify-content-center uppercase title" tag="h4">{i18n.calendarDifferentDaysTitle}</ModalHeader>
                        <ModalBody><p>{i18n.calendarDifferentDaysWarning}</p></ModalBody>
                        <ModalFooter>
                            <Button color="info" onClick={this.multipleDaySure}>{i18n.buttonSave}</Button>
                            <Button color="info" onClick={this.hideMultipleDay}>{i18n.buttonCancel}</Button>
                        </ModalFooter>
                    </Modal>
                    <DeleteSure
                        open={this.state.deleteOpen}
                        body={i18n.calendarDeleteOverviewBody}
                        hide={() => this.setState({deleteOpen: false})}
                        group={this.props.group ? this.props.group.uuid : ""}
                        itemType={this.calendarType}
                        item={this.state.item}
                    />
                </div>
            </TitleWrapper>
        );
    }
}

const mapStateToProps    = state => ({
    loadingCalendars: state.groupCalendars.loading,
    skipping:         state.groupCalendars.skipping,
    calendarsFor:     state.groupCalendars.calendarsFor,
    calendars:        state.groupCalendars.calendars,
    loadedAt:         state.groupCalendars.loadedAt
});
const mapDispatchToProps = dispatch => ({
    load:            forGroup => dispatch(actions.groupCalendars.load(forGroup)),
    saveAppointment: (forGroup, appointment) => dispatch(actions.groupCalendars.saveAppointment(forGroup, appointment)),
    saveBirthday:    (forGroup, birthday) => dispatch(actions.groupCalendars.saveBirthday(forGroup, birthday)),
    saveReminder:    (forGroup, reminder) => dispatch(actions.groupCalendars.saveReminder(forGroup, reminder)),
    saveTvShow:      (forGroup, tvShow) => dispatch(actions.groupCalendars.saveTvShow(forGroup, tvShow)),
    skipItem:        (forGroup, calendarType, uuid) => dispatch(actions.groupCalendars.skip(forGroup, calendarType, uuid)),
    unskipItem:      (forGroup, calendarType, uuid) => dispatch(actions.groupCalendars.unskip(forGroup, calendarType, uuid)),
    errorPush:       message => dispatch(actions.messages.errorPush(message))
});

export default withGroups(connect(mapStateToProps, mapDispatchToProps)(CalendarEdit));
