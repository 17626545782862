import React from 'react';
import PropTypes from 'prop-types';
import {Input as InputRS} from "reactstrap";
import AutoWrapInput from '../InputContainers/AutoWrapInput';
import {iosInputFix} from "../../../tools/ios-inputfix";

const Input = props => {
    const blurHandler = e => {
        iosInputFix();
        props.handlers.focus(props.name, false);
    };
    const params      = {
        name:     props.name,
        settings: props.settings,
        stacked:  props.stacked,
        disabled: props.disabled
    };
    return (
        <AutoWrapInput {...params}>
            <InputRS
                {...props.settings.elementConfig}
                name={props.name}
                disabled={props.disabled}
                onChange={event => props.handlers.change(event.target.value, props.name)}
                onFocus={() => props.handlers.focus(props.name, true)}
                onBlur={blurHandler}
                onKeyUp={e => props.handlers.keyUp(props.name, e.keyCode)}
                className={props.settings.touched && props.settings.validation ? (props.settings.valid ? "has-success" : "has-danger") : ""}
                value={props.settings.value}
                innerRef={props.innerRef}
            />
        </AutoWrapInput>
    );
};

Input.propTypes = {
    name:     PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    handlers: PropTypes.object.isRequired,
    innerRef: PropTypes.object,
    stacked:  PropTypes.bool
};

export default React.memo(Input, (prevProps, nextProps) => {
    return prevProps.settings.value === nextProps.settings.value &&
        prevProps.settings.focused === nextProps.settings.focused &&
        prevProps.settings.valid === nextProps.settings.valid &&
        prevProps.disabled === nextProps.disabled
});