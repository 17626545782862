import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import i18n from "../../../i18n";
import * as actions from "../../../store/actions";

import {Spinner, Icon, BottomBar, Tour} from "../../../components";
import {ucFirst} from "../../../variables/tools";
import withGroups from "../../../hoc/withGroups";

import css from "./PreviousMessages.module.css";
import {TitleWrapper} from "../../../contexts/TitleProvider";
import {getPageHelpSteps} from "../../../help/tools";

// Table cell definitions
const SingleMessage = props => (
    <div className={css.message}>
        <div className={css.sender}>{props.sentBy}:</div>
        <div className={css.messageContainer}>
            <span className={css.messageText + " " + (props.active ? css.active : null)}>{props.message}</span>
            {props.active && <button className={css.messageDelete} onClick={() => props.onCancel(props.uuid)}><Icon name="hide"/></button>}
        </div>
        <div className={css.date}>{ucFirst(props.sentAt.local().format("dddd D MMMM YYYY, HH:mm"))}</div>
    </div>
);

const GroupPreviousMessages = props => {
    const dispatch = useDispatch();
    const messages = useSelector(state => state.groupMessages.messages);

    const [helpOpen, setHelpOpen]     = useState(false);
    const doHelp                      = () => setHelpOpen(true);
    const closeHelp                   = () => setHelpOpen(false);
    const [intervalId, setIntervalId] = useState(null);

    const startInterval = () => setIntervalId(setInterval(reloadMessages, 30000));
    const stopInterval = () => intervalId && clearInterval(intervalId);
    const startMessages  = () => {
        reloadMessages();
        stopInterval();
        startInterval();
    };
    const reloadMessages = () => {
        if (props.group) {
            dispatch(actions.groupMessages.load(props.group.uuid));
        }
    };
    const cancelMessage  = uuid => {
        dispatch(actions.groupMessages.cancel(props.group.uuid, uuid));
    };
    useEffect(() => {
        startMessages();
        return stopInterval;
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        startMessages();
        // eslint-disable-next-line
    }, [props.group])

    return (
        <TitleWrapper title={i18n.groupsSentMessages.replace('%1', props.group ? props.group.name : i18n.group)}>
            <Tour
                isOpen={helpOpen}
                onRequestClose={closeHelp}
                steps={getPageHelpSteps("groupMessageList")}
            />
            <div>
                <div className={css.messagesContent}>
                    {messages ? messages.map(message => <SingleMessage key={message.uuid} {...message} onCancel={cancelMessage}/>) : <Spinner/>}
                    <BottomBar backButton onHelp={doHelp} onCloseHelp={closeHelp} helpOpen={helpOpen}/>
                </div>
            </div>
        </TitleWrapper>
    );

};

export default withGroups(React.memo(GroupPreviousMessages));