export default {
    affiliates:   "Affiliates",
    noAffiliates: "No affiliates",

    affiliatesTitle:             "Affiliates",
    affiliatesTableName:         "Name",
    affiliatesTableCity:         "City",
    affiliatesTableCountry:      "Country",
    affiliatesTablePhone:        "Phone",
    affiliatesTableEmail:        "E-mail",
    affiliatesTableMachines:     "Machines",
    affiliatesTableSent:         "Sent",
    affiliatesTableSentShort:    "S",
    affiliatesTableCoupled:      "Coupled",
    affiliatesTableCoupledShort: "C",
    affiliatesActionsEdit:       "Edit",
    affiliatesActionsDelete:     "Delete",
    affiliatesLoadError:         "An error occurred while loading the affiliates",

    affiliatesAddTitle:      "Add affiliate",
    affiliatesEditTitle:     "Edit affiliate",
    affiliatesEditSuccess:   "The affiliate details have been successfully saved",
    affiliatesEditError:     "An error occurred while saving the affiliate details",
    affiliatesRemoveTitle:   "Are you sure you want to delete this affiliate?",
    affiliatesRemoveBody:    "If you delete the affiliate named '%1', all machines currently coupled to this affiliate will be coupled to Spiqle. This can not be undone.",
    affiliatesRemoveSure:    "Delete affiliate",
    affiliatesRemoveSuccess: "The affiliate has been deleted successfully",
    affiliatesRemoveError:   "An error occurred while deleting the affiliate",

    affiliateName:    "Name",
    affiliateAddress: "Address",
    affiliateZipcode: "Zip code",
    affiliateCity:    "City",
    affiliateState:   "Province",
    affiliateCountry: "Country",
    affiliatePhone:   "Phone number",
    affiliateEmail:   "E-mail address",
};