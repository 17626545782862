const icons = {
    "tv":                "tv",
    "media-player":      "media-player",
    "email-2":           "email-2",
    "calendar-2":        "calendar-2",
    "trash":             "trash",
    "e-remove":          "e-remove",
    "check":             "check",
    "world-pin":         "world-pin",
    "home-3":            "home-3",
    "circle-10":         "circle-10",
    "map":               "map",
    "e-add":             "e-add",
    "add-29":            "add-29",
    "office":            "office",
    "point-a":           "point-a",
    "reload":            "reload",
    "bell":              "bell",
    "c-info":            "c-info",
    "single-05":         "single-05",
    "a-edit":            "a-edit",
    "settings":          "settings",
    "magnifier":         "magnifier",
    "settings-gear":     "settings-gear",
    "time-alarm":        "time-alarm",
    "left-arrow":        "left-arrow",
    "up-arrow":          "up-arrow",
    "right-arrow":       "right-arrow",
    "down-arrow":        "down-arrow",
    "button-pause":      "button-pause",
    "button-play":       "button-play",
    "volume":            "volume",
    "volume-mute":       "volume-mute",
    "slide-left":        "slide-left",
    "menu-8":            "menu-8",
    "agenda-bookmark":   "agenda-bookmark",
    "pin-3":             "pin-3",
    "multiple-11":       "multiple-11",
    "undo-25":           "undo-25",
    "redo-26":           "redo-26",
    "key":               "key",
    "voice-recognition": "voice-recognition",
    "pen":               "pen",
    "telephone":         "telephone",
    "smart-house":       "smart-house",
    "audio-jack":        "audio-jack",
    "signal":            "signal",
    "server-rack":       "server-rack",
    "2x-tap":            "2x-tap",
    "a-delete":          "a-delete",
    "man-up":            "man-up",
    "a-add":             "a-add",
    "tag":               "tag",
    "eye":               "eye",
    "send-message":      "send-message",
    "cursor-menu":       "cursor-menu",
    "c-question":        "c-question",
    "translation":       "translation",
    "caps-small":        "caps-small",
    "leave":             "leave",
    "round-euro":        "round-euro",
    "c-question-2":      "c-question-2",
    "c-remove":          "c-remove"
};

export default icons;
