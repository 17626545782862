import React from "react";
import i18n from "../../i18n";
import {ResponsiveText} from "../../components";
import {Link} from "react-router-dom";
import css from "./tableColumns.module.css";

import {standardColumn, accessorColumn, defaultColumns} from "./funcs";

export const caretakerColumns = {
    name:     standardColumn("name", i18n.caretakersTableName, {togglable: false}),
    address:  standardColumn("address", i18n.caretakersTableAddress, {width: 150, show: false}),
    zipcode:  standardColumn("zipcode", i18n.caretakersTableZipCode, {width: 100, show: false}),
    city:     standardColumn("city", i18n.caretakersTableCity, {width: 150}),
    state:    standardColumn("state", i18n.caretakersTableState, {width: 120}),
    country:  standardColumn("country", i18n.caretakersTableCountry, {width: 100}),
    timezone: standardColumn("timezone", i18n.caretakersTableTimeZone, {width: 150, show: false}),
    machines: accessorColumn(
        "machines", <ResponsiveText long={""+ i18n.caretakersTableMachines} short={"" + i18n.caretakersTableMachinesShort} inline/>,
        d => <Link to={"/admin/caretakers/machines/" + d.uuid}>{d.machines.length}</Link>,
        {
            headerClassName: css.machines,
            className:       css.machines,
            minWidth:        40,
            maxWidth:        100
        }
    )
};

export const caretakerColumnsDefault = (actions = null, selector = null) => defaultColumns(
    [
        caretakerColumns.name,
        caretakerColumns.address,
        caretakerColumns.zipcode,
        caretakerColumns.city,
        caretakerColumns.state,
        caretakerColumns.country,
        caretakerColumns.timezone,
        caretakerColumns.machines
    ], actions, "uuid", selector
);

export const caretakerColumnsSimple = (actions = null, selector = null) => defaultColumns(
    [
        caretakerColumns.name,
        {...caretakerColumns.address, show: true},
        {...caretakerColumns.zipcode, show: true},
        caretakerColumns.city,
        caretakerColumns.state,
        caretakerColumns.country
    ], actions, "uuid", selector
);
