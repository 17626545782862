import {call, put, takeLatest} from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../actionTypes";
import * as api from "../../api";
import i18n from "../../i18n";
import moment from 'moment-timezone';
import nl from "../../i18n/nl";

moment.locale('nl');
moment.defaultFormat = nl.dateFormat;

// Watcher
export function* watchCaretakerMessages() {
    yield takeLatest(actionTypes.MESSAGES_LOAD, loadSaga);
    yield takeLatest(actionTypes.MESSAGE_SEND, sendSaga);
    yield takeLatest(actionTypes.MESSAGE_CANCEL, cancelSaga);
}

// Sagas
function* loadSaga(action) {
    try {
        const response = yield call(api.caretakerMessages.load, action.forCaretaker);
        const messages = [];
        for (let i = 0; i < response.data.messages.length; i++) {
            messages.push({
                ...response.data.messages[i],
                sentAt:      moment.utc(response.data.messages[i].sentAt, "YYYY-MM-DD HH:mm:ss"),
                deliveredAt: moment.utc(response.data.messages[i].deliveredAt, "YYYY-MM-DD HH:mm:ss"),
                cancelledAt: moment.utc(response.data.messages[i].cancelledAt, "YYYY-MM-DD HH:mm:ss")
            })
        }
        yield put(actions.caretakerMessages.loadSuccess(action.forCaretaker, messages));
    }
    catch (err) {
        yield put(actions.caretakerMessages.loadFail());
    }
}
function* sendSaga(action) {
    try {
        yield call(api.caretakerMessages.send, action.destination, action.message, action.duration, action.urgent);
        yield put(actions.caretakerMessages.sendSuccess());
        yield put(actions.messages.successPush(i18n.caretakersMessageSuccess));
        yield put(actions.caretakerMessages.load(action.destination));
//        yield put(push("/caretakers"));
    }
    catch (err) {
        yield put(actions.caretakerMessages.sendFail());
        yield put(actions.messages.errorPush(i18n.caretakersMessageError))
    }
}

function* cancelSaga(action) {
    try {
        yield call(api.caretakerMessages.cancel, action.uuid);
        yield put(actions.caretakerMessages.cancelSuccess());
        yield put(actions.messages.successPush(i18n.caretakersMessageCancelSuccess));
        yield put(actions.caretakerMessages.load(action.forCaretaker));
    }
    catch (err) {
        yield put(actions.caretakerMessages.cancelFail());
        yield put(actions.messages.errorPush(i18n.caretakersMessageCancelError));
    }
}