import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import PropTypes from 'prop-types';

import i18n from "../../../../../i18n";
import * as actions from "../../../../../store/actions";

import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Button} from "../../../../../components";

const TwoFactor = props => {
    const dispatch                                        = useDispatch();
    const [showDisableTwoFactor, setShowDisableTwoFactor] = useState(false);
    const disabling2faDone                                = useSelector(state => state.users.disabling2faDone);

    useEffect(() => {
        if (disabling2faDone) {
            setShowDisableTwoFactor(false);
            dispatch(actions.users.resetDoneStates());
        }
    }, [disabling2faDone, dispatch]);

    return (
        <>
            <Button color="info" block round simple onClick={() => setShowDisableTwoFactor(true)}>
                {i18n.usersActionsDisableTwoFactor}
            </Button>
            <Modal isOpen={showDisableTwoFactor} toggle={() => setShowDisableTwoFactor(!showDisableTwoFactor)} className="text-center">
                <ModalHeader className="justify-content-center uppercase title" tag="h4">{i18n.usersTwoFactorDisableTitle}</ModalHeader>
                <ModalBody><p>{i18n.usersTwoFactorDisableBody.replace('%1', props.userName)}</p></ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={() => dispatch(actions.users.disable2fa(props.userId))}>{i18n.usersTwoFactorDisableSure}</Button>
                    <Button color="info" onClick={() => setShowDisableTwoFactor(false)}>{i18n.buttonCancel}</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

TwoFactor.propTypes = {
    userId:   PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired
};

export default TwoFactor;