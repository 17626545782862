import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {Input} from "reactstrap";
import AutoWrapInput from '../InputContainers/AutoWrapInput';

import css from "./Inputs.module.css";

const File = props => {
    const fileInput        = useRef();
    const handleFileInput  = () => fileInput.current.click();
    const handleSelectFile = e => {
        if (e.target.files.length > 0) {
            props.handlers.change(e.target.files[0], props.name);
        }
    };

    let fileName = "";
    if (props.settings.value) {
        fileName = props.settings.value.name;
    }

    return (
        <AutoWrapInput name={props.name} settings={props.settings} stacked={props.stacked} disabled={props.disabled}>
            <Input
                {...props.settings.elementConfig}
                name={props.name}
                disabled={props.disabled}
                type="text"
                onFocus={handleFileInput}
                className={css.fileInput + " " + (props.settings.touched && props.settings.validation ? (props.settings.valid ? "has-success" : "has-danger") : "")}
                readOnly
                value={fileName}
                innerRef={props.innerRef}
            />
            <Input
                {...props.settings.elementConfig}
                name={props.name + "-internal-file"}
                disabled={props.disabled}
                type="file"
                ref={fileInput}
                onChange={handleSelectFile}
            />
        </AutoWrapInput>
    );
};

File.propTypes = {
    name:     PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    handlers: PropTypes.object.isRequired,
    innerRef: PropTypes.object,
    stacked:  PropTypes.bool
};

export default React.memo(File, (prevProps, nextProps) => {
    return prevProps.settings.value === nextProps.settings.value &&
        prevProps.settings.focused === nextProps.settings.focused &&
        prevProps.settings.valid === nextProps.settings.valid &&
        prevProps.disabled === nextProps.disabled;
});