import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';

import {deepCompare} from "../../../../variables/tools";
import * as actions from "../../../../store/actions";
import i18n from "../../../../i18n";
import tableColumns from "../../../../variables/tableColumns";

import {Card, CardBody} from 'reactstrap';
import AutoWrapInput from "../../InputContainers/AutoWrapInput";
import {ColumnSelector, ReactTable} from "../../.."
import {push} from "connected-react-router";

class Machines extends Component {

    setMachine  = (machine, value) => this.setState(oldState => ({selectedMachines: {...oldState.selectedMachines, [machine]: value}}));
    formColumns = tableColumns.machineColumnsDefaultRedux(
        () => this.props.caretakers,
        () => this.props.distributors,
        () => this.props.affiliates,
        null,
        {
            accessor:  "mac_address",
            onChange:  this.setMachine,
            isChecked: machine => this.state.selectedMachines[machine]
        }
    );

    state = {
        selectedMachines: {},
        columns:          this.formColumns
    };

    constructor(props) {
        super(props);

        props.loadCaretakers();
        props.loadAffiliates();
        props.loadDistributors();

        if (props.settings.loadRight) {
            props.loadForRight(props.settings.loadRight, props.settings.loadRightLevel);
        }
        else {
            props.load();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.settings.value !== this.props.settings.value) {
            this.setState({
                selectedMachines: this.props.settings.value.reduce((obj, item) => {
                    obj[item] = true;
                    return obj
                }, {})
            });
        }

        if (prevState.selectedMachines !== this.state.selectedMachines) {
            // Only fire change handler if the output array is different from settings.value
            let outputValue = Object.entries(this.state.selectedMachines).filter(item => item[1]).map(item => item[0]);
            let same        = true;
            if (outputValue.length !== this.props.settings.value.length) {
                same = false;
            }
            if (same) {
                for (let i of outputValue) {
                    if (this.props.settings.value.indexOf(i) === -1) {
                        same = false;
                        break;
                    }
                }
            }
            if (!same) {
                this.props.handlers.change(outputValue, this.props.name);
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state.selectedMachines !== nextState.selectedMachines ||
               this.state.columns !== nextState.columns ||
               this.props.caretakers !== nextProps.caretakers ||
               this.props.distributors !== nextProps.distributors ||
               this.props.affiliates !== nextProps.affiliates ||
               this.props.loading !== nextProps.loading ||
               this.props.machines !== nextProps.machines ||
               this.props.loadedAt !== nextProps.loadedAt ||
               this.props.settings.value !== nextProps.settings.value ||
               this.props.settings.focused !== nextProps.settings.focused ||
               this.props.settings.valid !== nextProps.settings.valid ||
               !deepCompare(this.props.settings.options, nextProps.settings.options);
    }

    render() {
        /*let machineOptions = [];
        if (!loading && machines) {
            machineOptions = machines.map(machine => ({value: machine.uuid, label: machine.name}));
            if (props.settings.hideMachine) {
                machineOptions = machineOptions.filter(machine => machine.value !== props.settings.hideMachine);
            }
        }*/

        return (
            <AutoWrapInput name={this.props.name} settings={this.props.settings} stacked={this.props.stacked}>
                {!this.props.loading && this.props.machines && this.props.caretakers && this.props.distributors && this.props.affiliates ? (
                    <Card>
                        <CardBody>
                            <ColumnSelector cookieId="inputMachines" columns={this.state.columns} changed={columns => this.setState({columns})}/>
                            <ReactTable
                                filterable
                                indexKey="id"
                                data={this.props.machines}
                                columns={this.state.columns}
                                noDataText={i18n.noMachines}
                                defaultSorted={[{id: "last_update", desc: true}]}
                            />
                        </CardBody>
                    </Card>
                ) : <p>{i18n.loading}</p>}
            </AutoWrapInput>
        );
    }
}

const mapStateToProps = state => ({
    caretakers:   state.caretakers.caretakers,
    distributors: state.distributors.distributors,
    affiliates:   state.affiliates.affiliates,
    loading:      state.machines.loading,
    machines:     state.machines.machines,
    loadedAt:     state.machines.machinesLoaded
});

const mapDispatchToProps = dispatch => ({
    push:             pathname => dispatch(push(pathname)),
    load:             () => dispatch(actions.machines.loadIfNeeded()),
    loadForRight:     (loadRight, loadRightLevel) => dispatch(actions.machines.loadForRight(loadRight, loadRightLevel)),
    loadCaretakers:   () => dispatch(actions.caretakers.loadIfNeeded()),
    loadDistributors: () => dispatch(actions.distributors.loadIfNeeded()),
    loadAffiliates:   () => dispatch(actions.affiliates.loadIfNeeded())
});

Machines.propTypes = {
    name:     PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    handlers: PropTypes.object.isRequired,
    innerRef: PropTypes.object,
    stacked:  PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(Machines);
