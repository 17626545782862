import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import i18n from "../../../i18n";
import * as actions from "../../../store/actions";

import {BottomBar, Button, ButtonCrusher, Spinner, Tour} from "../../../components";
import {TitleWrapper} from "../../../contexts/TitleProvider";
import {getPageHelpSteps} from "../../../help/tools";
import CaretakerSelector from "./CaretakerSelector";
import withGroups from "../../../hoc/withGroups";

const List = props => {
    const dispatch                                    = useDispatch();
    const saving                                      = useSelector(state => state.groups.saving);
    const [selectedCaretakers, setSelectedCaretakers] = useState(props.group ? props.group.caretakers : []);
    const [helpOpen, setHelpOpen]                     = useState(false);

    useEffect(() => {
        if (props.group && props.group.caretakers) {
            setSelectedCaretakers(props.group.caretakers);
        }
    }, [props.group])

    const doHelp    = () => setHelpOpen(true);
    const closeHelp = () => setHelpOpen(false);
    const onSubmit  = () => {
        if (selectedCaretakers.length === 0) {
            dispatch(actions.messages.errorPush(i18n.errorForm));
            return;
        }
        dispatch(actions.groups.manageCaretakers(props.groupUuid, selectedCaretakers));
    }

    return (
        <TitleWrapper title={i18n.groupsCaretakersTitle.replace("%1", props.group ? props.group.name : i18n.group)}>
            <Tour
                isOpen={helpOpen}
                onRequestClose={closeHelp}
                steps={getPageHelpSteps("groupsCaretakers")}
            />
            <div>
                <div className="content-form">
                    <CaretakerSelector
                        selected={selectedCaretakers}
                        onChange={setSelectedCaretakers}
                    />
                    <ButtonCrusher width={77} topSpacer={25}>
                        <Button
                            block
                            upper
                            round
                            color="info"
                            onClick={onSubmit}
                        >{i18n.buttonSave}</Button>
                    </ButtonCrusher>
                    <BottomBar backButton backUrl="/" onHelp={doHelp} onCloseHelp={closeHelp} helpOpen={helpOpen}/>
                    {(props.loading || saving) && <Spinner/>}
                </div>
            </div>
        </TitleWrapper>
    );
};

export default withGroups(List);