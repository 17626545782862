import React from 'react';
import css from "./Calendars.module.css";
import DayName from "./DayName";

const DayNames = props => {
    return (
        <div className={css.dayNames}>
            {[1,2,3,4,5,6,0].map(day => <DayName day={day} key={"dn" + day} />)}
        </div>
    );
};

export default DayNames;