import {apiGetCall, apiPostCall} from "./index";

export const load     = () => apiGetCall("/users/getall");
export const loadInfo = uuid => apiGetCall("/users/getinfo", {uuid});
export const save     = (uuid, userDetails) => apiPostCall("/users/save", {uuid, ...userDetails});
export const remove   = uuid => apiPostCall("/users/remove", {uuid});

export const rightAdd    = (userId, right) => apiPostCall("/users/rightadd", {userId, right});
export const rightRemove = rightId => apiPostCall("/users/rightremove", {rightId});

export const duplicate  = (uuid, userInfo) => apiPostCall("/users/duplicate", {uuid, ...userInfo});
export const copyRights = (fromUser, toUser, replace) => apiPostCall("/users/copyrights", {fromUser, toUser, replace});
export const disable2fa = uuid => apiPostCall("/users/disable2fa", {uuid});
export const loginAs    = uuid => apiPostCall("/users/loginas", {uuid});