import moment from "moment";

export const timeToNumber = time => (parseInt(time.substr(0, 2))) * 60 + parseInt(time.substr(3));

export const matchingAppointment = (dateToCheck, appointment, itemType, start, end) => {
    let startString, endString, startMoment, endMoment;
    return {
        ...appointment,
        itemType,
        matches:     true,
        currentDate: {
            start:       startString = dateToCheck.isSame(start, 'day') ? start.format("HH:mm") : "00:00",
            end:         endString = dateToCheck.isSame(end, 'day') ? end.format("HH:mm") : "23:59",
            startMoment: startMoment = dateToCheck.clone().hours(startString.substr(0, 2)).minutes(startString.substr(3, 2)).seconds(0).milliseconds(0),
            endMoment:   endMoment = dateToCheck.clone().hours(endString.substr(0, 2)).minutes(endString.substr(3, 2)).seconds(0).milliseconds(0).subtract(1, "seconds"),
            duration:    Math.floor(endMoment.diff(startMoment) / 60000)
        }
    }
};

export const getAppointmentIntervals = (start, end, appointment) => {
    const dates = [];
    if (appointment.start.isAfter(end)) {
        return [];
    }
    let runner = start.clone();
    runner.hours(12);

    if (appointment.recurring && appointment.recurring !== "none") {
        if (appointment.recurring === "interval") {
            // === Check interval ===

            // Create period
            let [periodLength, periodType] = appointment.interval.split(" ");
            let period                     = moment.duration(parseInt(periodLength), periodType);

            // Create start and ending moments
            let iterationStart = moment(appointment.start);
            let iterationEnd   = moment(appointment.end);

            let appointmentEnd = end.clone();
            if (appointment.hasEndInterval) {
                appointmentEnd = moment(appointment.endInterval);
            }

            // Run periods until the ending is in our range
            while (iterationEnd.isBefore(start, "day")) {
                iterationStart.add(period);
                iterationEnd.add(period);
            }

            // Run periods while in the range
            while (iterationStart.isSameOrBefore(end, "day") && iterationStart.isSameOrBefore(appointmentEnd, "day")) {
                // Start at max(start, iterationStart)
                runner = moment.max(start, iterationStart).clone();

                // Run through every day in the iteration
                while (runner.isSameOrBefore(iterationEnd)) {
                    dates.push(runner.format("YYYY-MM-DD"));
                    runner.add(1, "days");
                }

                // Add period
                iterationStart.add(period);
                iterationEnd.add(period);
            }
            return dates;
        } else {
            // === Check weekdays ===

            // Create day array
            let weekdays = [];
            for (let i = 0; i < 7; i++) {
                weekdays[i] = (appointment.weekDays >> i) & 1;
            }

            // Calculate days of one iteration
            let iterationStart = moment(appointment.start);
            let iterationEnd   = moment(appointment.end);
            let dayDuration    = iterationEnd.diff(iterationStart, 'days');
            let dayRunner      = 0;

            let appointmentEnd = end.clone();
            if (appointment.hasEndInterval) {
                appointmentEnd = moment(appointment.endInterval);
            }

            // Start at max(start, iterationStart)
            runner = moment.max(start, iterationStart);

            // Run through days to end of period
            while (runner.isSameOrBefore(end, 'day') && runner.isSameOrBefore(appointmentEnd, 'day')) {
                // Rotate day number (0 = Monday, 6 = Sunday)
                let day = (runner.day() + 6) % 7;
                // Check if this is an enabled week day
                if (weekdays[day] === 1) {
                    dates.push(runner.format("YYYY-MM-DD"));
                    dayRunner = dayDuration;
                } else if (dayRunner > 0) {
                    // Else add days if the iteration is longer than a single day
                    dates.push(runner.format("YYYY-MM-DD"));
                    dayRunner--;
                }
                runner.add(1, 'days');
            }
            return dates;
        }

    } else {
        if (appointment.end.isBefore(start, "day")) {
            return [];
        }
        if (appointment.start.isAfter(start, "day")) {
            runner = appointment.start.clone();
        }
        while (runner.isBefore(end, 'day') && runner.isSameOrBefore(appointment.end, 'day')) {
            dates.push(runner.format("YYYY-MM-DD"));
            runner.add(1, "days");
        }
        return dates;
    }
};

export const checkAppointment = (dateToCheck, appointment, itemType) => {
    if (appointment.recurring && appointment.recurring !== "none") {
        if (dateToCheck.isBefore(appointment.start, 'day')) {
            return {...appointment, matches: false};
        }
        if (appointment.recurring === "interval") {
            // Check interval
            let [periodLength, periodType] = appointment.interval.split(" ");
            let period                     = moment.duration(parseInt(periodLength), periodType);

            let iterationStart = moment(appointment.start);
            let iterationEnd   = moment(appointment.end);

            while (iterationStart.isSameOrBefore(dateToCheck, 'day') && (!appointment.hasEndInterval || iterationStart.isSameOrBefore(appointment.endInterval, 'day'))) {
                if (dateToCheck.isBetween(iterationStart, iterationEnd, 'day', '[]')) {
                    return matchingAppointment(dateToCheck, appointment, itemType, iterationStart, iterationEnd);
                }
                iterationStart = iterationStart.add(period);
                iterationEnd   = iterationEnd.add(period);
            }
            return {...appointment, matches: false};
        } else {
            // Check weekdays
            let weekdays = [];
            for (let i = 0; i < 7; i++) {
                weekdays[i] = (appointment.weekDays >> i) & 1;
            }

            let iterationStart = moment(appointment.start);
            let iterationEnd   = moment(appointment.end);

            // Sync weekday array to start day (rotate array)
            let startDay = (iterationStart.day() + 6) % 7; // Monday = 0, Sunday = 6
            weekdays     = weekdays.concat(weekdays.splice(0, startDay));

            // Search for current day
            let period = moment.duration(1, 'day');
            while (iterationStart.isSameOrBefore(dateToCheck, 'day')) {
                if (weekdays[0] === 1 && dateToCheck.isBetween(iterationStart, iterationEnd, 'day', '[]')) {
                    return matchingAppointment(dateToCheck, appointment, itemType, iterationStart, iterationEnd);
                }
                iterationStart = iterationStart.add(period);
                iterationEnd   = iterationEnd.add(period);
                weekdays.push(weekdays.shift());
            }
            return {...appointment, matches: false};
        }
    } else {
        if (dateToCheck.isBetween(appointment.start, appointment.end, 'day', '[]')) {
            return matchingAppointment(dateToCheck, appointment, itemType, appointment.start, appointment.end);
        }
        return {...appointment, matches: false};
    }
};

export const calculateEventsForDate = (date, calendars) => {
    // Combine all events for the day
    let events = []
        .concat(calendars.birthdays.map(b => ({
            ...b,
            itemType: "birthday",
            matches:  b.birthday.format("DD-MM") === date.format("DD-MM")
        })))
        .concat(calendars.appointments.map(appointment => checkAppointment(date, appointment, "appointment")))
        .concat(calendars.reminders.map(reminder => checkAppointment(date, reminder, "reminder")))
        .concat(calendars.tvshows.map(tvShow => checkAppointment(date, tvShow, "tvshow")))
        .filter(item => item.matches);

    // Sort events by starting time
    events.sort((a, b) => {
        // Place birthdays at the beginning (they're the whole day)
        if (a.birthday && b.birthday) {
            return 0;
        }
        if (a.birthday && !b.birthday) {
            return -1;
        }
        if (!a.birthday && b.birthday) {
            return 1;
        }

        // Place whole day appointments at the beginning
        if ((a.currentDate.start === "00:00" && a.currentDate.end === "23:59") && !(b.currentDate.start === "00:00" && b.currentDate.end === "23:59")) {
            return -1;
        }
        if (!(a.currentDate.start === "00:00" && a.currentDate.end === "23:59") && (b.currentDate.start === "00:00" && b.currentDate.end === "23:59")) {
            return 1;
        }

        // Sort by time
        return timeToNumber(a.currentDate.start) - timeToNumber(b.currentDate.start);
    });

//        let endCalc = performance.now();
    return events;
};

export const calculateAllEventsForRange = (calendars, startPeriod, endPeriod) => {
    const start = startPeriod.clone();
    const end   = endPeriod.clone();
    start.hours(0).minutes(0).seconds(0).milliseconds(0);
    end.hours(23).minutes(59).seconds(59).milliseconds(999);

    let dates = [];

    calendars.birthdays.forEach(b => {
        const birthday = b.birthday.clone();
        birthday.year(startPeriod.year());
        while (birthday.isSameOrBefore(endPeriod)) {
            if (birthday.isBetween(startPeriod, endPeriod, 'day', '[]')) {
                dates.push(birthday.format("YYYY-MM-DD"))
            }
            birthday.add(1, "years");
        }
    });
    calendars.appointments.forEach(appointment => dates = dates.concat(getAppointmentIntervals(start.clone(), end.clone(), appointment)));
    calendars.reminders.forEach(reminder => dates = dates.concat(getAppointmentIntervals(start.clone(), end.clone(), reminder)));
    calendars.tvshows.forEach(tvShow => dates = dates.concat(getAppointmentIntervals(start.clone(), end.clone(), tvShow)));

    return dates.filter((value, index, self) => self.indexOf(value) === index);
}
