import moment from 'moment-timezone';
import * as actionTypes from "../actionTypes";
import {runActionMap, load, loadFail} from "../reducerFunctions";

const initialState = {
    loading:       false,
    sending:       false,
    sendSuccess:   false,
    cancelSuccess: false,
    messagesFor:   null,
    messages:      null,
    loadedAt:      null
};

const loadSuccess = (state, action) => ({...state, loading: false, messagesFor: action.forGroup, messages: action.messages, loadedAt: moment()});

const send        = (state, action) => ({...state, sending: true, sendSuccess: false});
const sendSuccess = (state, action) => ({...state, sending: false, sendSuccess: true});
const sendFail    = (state, action) => ({...state, sending: false, sendSuccess: false});
const clearSend   = (state, action) => ({...state, sending: false, sendSuccess: false});

const cancel        = (state, action) => ({...state, loading: true, cancelSuccess: false});
const cancelSuccess = (state, action) => ({...state, loading: false, cancelSuccess: true});
const cancelFail    = (state, action) => ({...state, loading: false, cancelSuccess: false});

const clear = (state, action) => ({...state, loading: false, messagesFor: null, messages: null});

const actionMap                = {
    [actionTypes.GROUP_MESSAGES_LOAD]:          load,
    [actionTypes.GROUP_MESSAGES_LOAD_SUCCESS]:  loadSuccess,
    [actionTypes.GROUP_MESSAGES_LOAD_FAIL]:     loadFail,
    [actionTypes.GROUP_MESSAGES_UNLOAD]:        clear,
    [actionTypes.GROUP_MESSAGE_SEND]:           send,
    [actionTypes.GROUP_MESSAGE_SEND_SUCCESS]:   sendSuccess,
    [actionTypes.GROUP_MESSAGE_SEND_FAIL]:      sendFail,
    [actionTypes.GROUP_MESSAGE_SEND_CLEAR]:     clearSend,
    [actionTypes.GROUP_MESSAGE_CANCEL]:         cancel,
    [actionTypes.GROUP_MESSAGE_CANCEL_SUCCESS]: cancelSuccess,
    [actionTypes.GROUP_MESSAGE_CANCEL_FAIL]:    cancelFail,
    [actionTypes.GROUP_MESSAGE_LIST_CLEAR]:     clear,
};
const groupMessagesReducer = (state = initialState, action) => runActionMap(state, action, actionMap);

export default groupMessagesReducer;
