// React
import React from "react";
import PropTypes from "prop-types";
// Tools
import i18n from "../../../i18n";
import moment from "moment";
import * as actions from "../../../store/actions";
// Components
import {Form, Spinner} from "../../../components";
import {useDispatch} from "react-redux";
import css from "./EditAppointment.module.css";

const EditAppointment = props => {
    const dispatch = useDispatch();
    if (!props.caretaker) {
        return <Spinner/>;
    }

    let startDate, endDate;
    if(props.newDate) {
        startDate = moment(props.newDate, "YYYY-MM-DD").hours(12).minutes(0);
        endDate   = moment(props.newDate, "YYYY-MM-DD").hours(13).minutes(0);
        if(props.newHour !== undefined) {
            startDate.hours(+props.newHour);
            endDate.hours(+props.newHour + 1);
        }
    } else {
        startDate = moment().hours(12).minutes(0);
        endDate   = moment().hours(13).minutes(0);
    }

    const editForm = {
        message:        {
            className:      css.tourMessage,
            label:          i18n.calendarEditFormTitle,
            type:           "textarea",
            height:         123,
            elementConfig:  {wrap: "soft"},
            disableNewLine: true
        },
        wholeDay:       {
            className:  css.tourWholeDay,
            label:      i18n.calendarEditWholeDay,
            labelValue: [i18n.calendarEditWholeDayYes, i18n.calendarEditWholeDayNo],
            noLabel:    true,
            type:       "switch",
        },
        start:          {
            classNameDate: css.tourDateStart,
            classNameTime: css.tourTimeStart,
            label:         i18n.calendarEditFormStart + ":",
            type:          "datetime",
            defaultValue:  startDate.clone(),
            show:          form => !form.wholeDay.value
        },
        startDay:       {
            className:    css.tourDateStart,
            label:        i18n.calendarEditFormStart,
            type:         "date",
            defaultValue: startDate.clone(),
            show:         form => form.wholeDay.value
        },
        showAbout:      {
            className:    css.tourShowAbout,
            label:        i18n.calendarEditFormShowAbout,
            labelValue:   [i18n.calendarEditFormShowAboutYes, i18n.calendarEditFormShowAboutNo],
            noLabel:      true,
            type:         "switch",
            defaultValue: props.caretaker.settings.defaultShowAbout,
            show:         form => !form.wholeDay.value
        },
        end:            {
            classNameDate: css.tourDateEnd,
            classNameTime: css.tourTimeEnd,
            label:         i18n.calendarEditFormEnd + ":",
            type:          "datetime",
            defaultValue:  endDate.clone(),
            show:          form => !form.wholeDay.value
        },
        endDay:         {
            className:    css.tourDateEnd,
            label:        i18n.calendarEditFormEndDate,
            type:         "date",
            defaultValue: endDate.clone(),
            show:         form => form.wholeDay.value
        },
        dontShowEnd:    {
            className:    css.tourShowEnd,
            label:        i18n.calendarEditFormDontShowEnd,
            labelValue:   [i18n.calendarEditFormDontShowEndYes, i18n.calendarEditFormDontShowEndNo],
            noLabel:      true,
            invertValue:  true,
            type:         "switch",
            defaultValue: props.caretaker.settings.defaultDontShowEnd,
            show:         form => !form.wholeDay.value
        },
        showBefore:     {
            className:    css.tourShowBefore,
            label:        i18n.calendarEditFormShowBefore,
            defaultValue: props.caretaker.settings.defaultShowBefore,
            type:         "select",
            options:      i18n.calendarRecurrences.showBefore
        },
        notifyBefore: {
            className:    css.tourNotifyBefore,
            label:        i18n.calendarEditFormNotifyBefore,
            defaultValue: props.caretaker.settings.defaultNotifyBefore,
            type:         "select",
            options:      i18n.calendarRecurrences.notifyBefore
        },
        recurring:      {
            className:    css.tourRecurring,
            label:        i18n.calendarEditFormRecurrence,
            defaultValue: "none",
            type:         "select",
            options:      i18n.calendarRecurrences.options
        },
        interval:       {
            className:    css.tourRecurringInterval,
            label:        i18n.calendarEditFormInterval,
            defaultValue: "1 week",
            type:         "select",
            options:      i18n.calendarRecurrences.interval,
            show:         form => form.recurring.value === "interval"
        },
        weekDays:       {
            className: css.tourRecurringWeekdays,
            label:     i18n.calendarEditFormDaysOfWeek,
            type:      "weekselector",
            noLabel:   true,
            show:      form => form.recurring.value === "weekdays"
        },
        hasEndInterval: {
            className:  css.tourRecurringHasEndDate,
            label:      i18n.calendarEditFormIntervalHasEndDate,
            labelValue: [i18n.calendarEditFormIntervalHasEndDateYes, i18n.calendarEditFormIntervalHasEndDateNo],
            noLabel:    true,
            type:       "switch",
            show:       form => form.recurring.value !== "none"
        },
        endInterval:    {
            className:    css.tourRecurringEndDate,
            label:        i18n.calendarEditFormIntervalEndDate,
            type:         "date",
            defaultValue: (props.newDate ? moment(props.newDate, "YYYY-MM-DD") : moment()).hours(23).minutes(59).seconds(59),
            show:         form => form.recurring.value !== "none" && form.hasEndInterval.value
        }
    };

    const handleChange = (formValues, formValid, itemName) => {
        switch (itemName) {
            case "start":
                formValues = {...formValues, startDay: moment(formValues.start)};
                if (formValues.start.isAfter(formValues.end)) {
                    formValues.end    = moment(formValues.start).add(1, "hours");
                    formValues.endDay = moment(formValues.end);
                }
                break;

            case "startDay":
                formValues = {...formValues, start: moment(formValues.startDay)};
                if (formValues.startDay.isAfter(formValues.endDay)) {
                    formValues.startDay.hours(12, 0, 0);
                    formValues.start  = moment(formValues.startDay);
                    formValues.endDay = moment(formValues.startDay).hours(13);
                    formValues.end    = moment(formValues.endDay);
                }
                break;

            case "end":
                formValues = {...formValues, endDay: moment(formValues.end)};
                if (formValues.end.isBefore(formValues.start)) {
                    formValues.start    = moment(formValues.end).subtract(1, "hours");
                    formValues.startDay = moment(formValues.start);
                    dispatch(actions.messages.infoPush(i18n.calendarStartTimeChanged));
                }
                break;

            case "endDay":
                formValues = {...formValues, end: moment(formValues.endDay)};
                if (formValues.endDay.isBefore(formValues.startDay)) {
                    formValues.endDay.hours(12).minutes(0).seconds(0);
                    formValues.end      = moment(formValues.endDay);
                    formValues.startDay = moment(formValues.endDay).hours(13);
                    formValues.start    = moment(formValues.startDay);
                    dispatch(actions.messages.infoPush(i18n.calendarStartTimeChanged));
                }
                break;

            default:
        }
        props.setValues(formValues, formValid);
    };

    return (
        <Form inlineSelect formData={editForm} values={props.item} onChange={handleChange} onValid={props.setValid}/>
    );
};

EditAppointment.propTypes = {
    item:      PropTypes.object,
    caretaker: PropTypes.object,
    setValues: PropTypes.func.isRequired,
    setValid:  PropTypes.func.isRequired
};

export default EditAppointment;
