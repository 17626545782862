export default {
    appTitle: "Spiqle Agenda",

    loading:     "Laden...",
    loadedAt:    "Laatst bijgewerkt om: ",
    pageText:    "Pagina",
    ofText:      "van de",
    rowsText:    "regels",
    showColumns: "Selecteer kolommen",

    image:     "Afbeelding",
    all:       "Alle",
    yes:       "Ja",
    no:        "Nee",
    unknown:   "Onbekend",
    noOptions: "Geen opties beschikbaar",

    successTitle: "Geslaagd",
    errorTitle:   "Fout",

    buttonEdit:     "Bewerken",
    buttonSave:     "Opslaan",
    buttonSend:     "Versturen",
    buttonNext:     "Volgende",
    buttonPrevious: "Vorige",
    buttonBack:     "Terug",
    buttonFinish:   "Voltooien",
    buttonCancel:   "Annuleren",
    buttonInvite:   "Uitnodigen",
    buttonAdd:      "Toevoegen",

    searchPlaceholder: "Zoeken...",

    contactTitle:       "Contact",
    contactSubtitle:    "Heeft u een vraag of suggestie? Neem contact op met ons door middel van dit formulier.",
    contactFormTitle:   "Titel",
    contactFormBody:    "Vraag of suggestie",
    successContactSend: "Het contactformulier is succesvol verzonden",
    errorContactSend:   "Er is een fout opgetreden bij het versturen van het contact formulier",

    validationErrors: {
        required:  "Dit veld is verplicht",
        minLength: "De minimale lengte voor dit veld is %1",
        maxLength: "De maximale lengte voor dit veld is %1",
        isEmail:   "Dit is geen geldig e-mail adres",
        regex:     "Dit is geen geldige waarde voor dit veld",
        func:      "Dit is geen geldige waarde voor dit veld"
    },

    errorForm: "Niet alle velden zijn ingevuld",

    errors: {
        CONNECTION:              "Connectie probleem, controleer uw internet verbinding",
        DEFAULT:                 "Generieke fout, probeer het later opnieuw",
        CALL_DISABLED:           "Deze functie is momenteel uitgeschakeld",
        LOCAL_ONLY:              "",
        INVALID_REQUEST:         "Fout tijdens het ophalen of opslaan van gegevens. Gebruikt u de laatste versie van de app?",
        INVALID_EMAIL:           "Fout tijdens het ophalen of opslaan van gegevens, het e-mail adres is ongeldig",
        INVALID_GUID:            "Fout tijdens het ophalen of opslaan van gegevens, het GUID is ongeldig",
        INVALID_MAC:             "Fout tijdens het ophalen of opslaan van gegevens, het MAC adres is ongeldig",
        INVALID_FILE:            "Fout tijdens het ophalen of opslaan van gegevens, het gekozen bestand is ongeldig",
        INVALID_TOKEN:           "Fout tijdens het ophalen of opslaan van gegevens, ongeldige inlog token",
        INVALID_LIST:            "Fout tijdens het ophalen of opslaan van gegevens, de lijst is ongeldig",
        INVALID_INTEGER:         "Fout tijdens het ophalen of opslaan van gegevens, het nummer is ongeldig",
        INVALID_MONGOID:         "Fout tijdens het ophalen of opslaan van gegevens, het database id is ongeldig",
        INVALID_FLOAT:           "Fout tijdens het ophalen of opslaan van gegevens, het nummer is ongeldig",
        INVALID_ARRAY:           "Fout tijdens het ophalen of opslaan van gegevens, de lijst is ongeldig",
        INVALID_JSON:            "Fout tijdens het ophalen of opslaan van gegevens, een parameter is ongeldig",
        NO_AUTH:                 "Fout tijdens het ophalen of opslaan van gegevens, u bent niet ingelogged",
        UNKNOWN_CREDENTIALS:     "Onbekende e-mail of wachtwoord",
        SIGNUP_ERROR_CONNECTION: "Het aanmaken van een account is mislukt. Controleer uw internet verbinding en probeer het opnieuw.",
        SIGNUP_ERROR_EXISTS:     "Het aanmaken van een account is mislukt. Er bestaat al een account met dit e-mail adres.",
        addNewDisplay:           {
            INVALID_DISPLAY:         "Fout tijdens het koppelen van het scherm, onbekende scherm code, typ deze exact over zoals deze op het scherm staat",
            DISPLAY_ALREADY_COUPLED: "Fout tijdens het koppelen van het scherm, dit scherm is al gekoppeld",
            INVALID_CARETAKER:       "Fout tijdens het koppelen van het scherm, deze zorgvrager is onbekend",
            INVALID_REQUEST:         "Fout tijdens het koppelen van het scherm, vul alle gegevens in"
        }
    },

    countries: {
        Andorra:          "Andorra",
        "België":         "België",
        "Bŭlgarija":      "Bulgarije",
        "Kýpros":         "Cyprus",
        Danmark:          "Denemarken",
        Deutschland:      "Duitsland",
        Eesti:            "Estland",
        Suomi:            "Finland",
        France:           "Frankrijk",
        "Elláda":         "Griekenland",
        "Magyarország":   "Hongarije",
        Hrvatska:         "Kroatië",
        Ireland:          "Ierland",
        Italia:           "Italië",
        Latvija:          "Letland",
        Lietuva:          "Litouwen",
        Luxembourg:       "Luxemburg",
        Malta:            "Malta",
        Nederland:        "Nederland",
        Norge:            "Noorwegen",
        "Österreich":     "Oostenrijk",
        Polska:           "Polen",
        Portugal:         "Portugal",
        "România":        "Roemenië",
        Slovenija:        "Slovenië",
        Slovensko:        "Slowakije",
        "España":         "Spanje",
        "Česko":          "Tjechië",
        "United Kingdom": "Verenigd Koninkrijk",
        Sverige:          "Zweden",
        Schweiz:          "Zwitserland"
    },

    dateFormat: "dd D MMM HH:mm",
    dateTime:   {
        months: {
            long:  [
                "januari",
                "februari",
                "maart",
                "april",
                "mei",
                "juni",
                "juli",
                "augustus",
                "september",
                "oktober",
                "november",
                "december"
            ],
            short: [
                "jan",
                "feb",
                "maa",
                "apr",
                "mei",
                "jun",
                "jul",
                "aug",
                "sep",
                "okt",
                "nov",
                "dec"
            ]
        },
        days:   {
            long:  [
                "zondag",
                "maandag",
                "dinsdag",
                "woensdag",
                "donderdag",
                "vrijdag",
                "zaterdag"
            ],
            short: [
                "zo",
                "ma",
                "di",
                "wo",
                "do",
                "vr",
                "za"
            ],
            count: [
                "",
                "1ste",
                "2de",
                "3de",
                "4de",
                "5de",
                "6de",
                "7de",
                "8ste",
                "9de",
                "10de",
                "11de",
                "12de",
                "13de",
                "14de",
                "15de",
                "16de",
                "17de",
                "18de",
                "19de",
                "20ste",
                "21ste",
                "22ste",
                "23ste",
                "24ste",
                "25ste",
                "26ste",
                "27ste",
                "28ste",
                "29ste",
                "30ste",
                "31ste"
            ]
        }
    }
};