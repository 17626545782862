import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

import {appRootPath} from "../../../variables/multiplatform";
import defaultAvatar from "../../../assets/img/placeholder.jpg";
import css from "./Avatar.module.css";

const Avatar = props => {
    const [previewUrl, setPreviewUrl] = useState(null);
    // Build preview URL and remove on unmount to prevent memory leaks
    useEffect(() => {
        if (!props.newAvatar) {
            if (previewUrl) {
                URL.revokeObjectURL(previewUrl);
                setPreviewUrl(null);
            }
        } else {
            setPreviewUrl(URL.createObjectURL(props.newAvatar));
        }
        return () => previewUrl && URL.revokeObjectURL(previewUrl);
        // eslint-disable-next-line
    }, [props.newAvatar]);

    let avatarUrl = appRootPath + defaultAvatar;
    if (!props.changed) {
        if (props.existingAvatar) {
            avatarUrl = props.existingAvatar;
        }
    } else {
        if (props.newAvatar !== null) {
            avatarUrl = previewUrl;
        }
    }
    return (
        <div className={css.avatarCenter}>
            <div
                className={css.avatarBackground}
                style={{backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(" + avatarUrl + ")"}}
            />
            <div className={css.avatarContainer}>
                <div
                    className={css.avatar}
                    style={{backgroundImage: "url(" + avatarUrl + ")"}}
                />
            </div>
        </div>
    );
}

Avatar.propTypes = {
    changed:        PropTypes.bool,
    newAvatar:      PropTypes.object,
    existingAvatar: PropTypes.string
};

export default Avatar;
