import React from 'react';
import PropTypes from 'prop-types';

import i18n from "../../../../i18n";

import {Table} from "reactstrap";
import {BoolCheck, Timestamp, CollapseCard} from "../../../../components";
import DetailItem from "./DetailItem";
import css from "./Info.module.css";

const details = ({details}) => {
    const getOs = () => {
        switch (details.os) {
            case "rpi":
                return "Raspberry Pi";
            case "api":
                return "Android";
            default:
                return "Unknown;"
        }
    };

    return (
        <CollapseCard title={i18n.machineInfoDataHead.replace("%1", details.mac_address)} defaultOpenDesktop defaultOpenMobile>
            <Table>
                <tbody className={css.tableItems}>
                <DetailItem name={i18n.machinesSerialNumber}>
                    {details.serial_no}
                </DetailItem>
                <DetailItem name={i18n.machinesSerialCheck}>
                    {details.serial_check}
                </DetailItem>
                <DetailItem name={i18n.machinesMacAddress}>
                    {details.mac_address}
                </DetailItem>
                <DetailItem name={i18n.machinesName}>
                    {details.name}
                </DetailItem>
                <DetailItem name={i18n.machinesCoupled}>
                    <BoolCheck value={details.coupled}/>
                </DetailItem>
                <DetailItem name={i18n.machinesVirtual}>
                    <BoolCheck value={details.virtual}/>
                </DetailItem>
                <DetailItem name={i18n.machinesServer}>
                    <BoolCheck value={details.server_id > 0}/>
                </DetailItem>
                <DetailItem name={i18n.machinesVersion}>
                    {details.version}
                </DetailItem>
                <DetailItem name={i18n.machinesOS}>
                    {getOs()}
                </DetailItem>
                <DetailItem name={i18n.machinesLastUpdate}>
                    <Timestamp time={details.last_update} format="YYYY-MM-DD HH:mm:ss Z"/>
                </DetailItem>
                <DetailItem name={i18n.machinesLastBoot}>
                    <Timestamp time={details.last_boot} format="YYYY-MM-DD HH:mm:ss Z"/>
                </DetailItem>
                <DetailItem name={i18n.machinesLastIP}>
                    {details.last_ip}
                </DetailItem>
                <DetailItem name={i18n.machinesCreatedAt}>
                    <Timestamp time={details.created_at} format="YYYY-MM-DD HH:mm:ss Z"/>
                </DetailItem>
                <DetailItem name={i18n.machinesTemperature}>
                    {details.temperature}°C
                </DetailItem>
                </tbody>
            </Table>
        </CollapseCard>
    );
};

details.propTypes = {
    details: PropTypes.object.isRequired
};

export default details;