import * as app from "./app/actions";
import * as auth from "./auth/actions";
import * as affiliates from "./affiliates/actions";
import * as calendars from "./calendars/actions";
import * as caretakers from "./caretakers/actions";
import * as caretakerMessages from "./caretakerMessages/actions";
import * as contact from "./contact/actions";
import * as distributors from "./distributors/actions";
import * as groups from "./groups/actions";
import * as groupCalendars from "./groupCalendars/actions";
import * as groupMessages from "./groupMessages/actions";
import * as help from "./help/actions";
import * as machines from "./machines/actions";
import * as messages from "./messages/actions";
import * as users from "./users/actions";
import * as actionTypes from "./actionTypes";

const resetState = dontSetAuthenticate => ({type: actionTypes.RESET_STATE, dontSetAuthenticate});

export {
    app,
    auth,
    affiliates,
    calendars,
    caretakers,
    caretakerMessages,
    contact,
    distributors,
    groups,
    groupCalendars,
    groupMessages,
    help,
    machines,
    messages,
    users,
    resetState
};
