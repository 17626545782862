export default {
    distributors:   "Distributeurs",
    noDistributors: "Geen distributeurs",

    distributorsTitle:             "Distributeurs",
    distributorsTableName:         "Naam",
    distributorsTableCity:         "Stad",
    distributorsTableCountry:      "Land",
    distributorsTablePhone:        "Telefoon",
    distributorsTableEmail:        "E-mail",
    distributorsTableMachines:     "Machines",
    distributorsTableSent:         "Verzonden",
    distributorsTableSentShort:    "V",
    distributorsTableCoupled:      "Gekoppeld",
    distributorsTableCoupledShort: "G",
    distributorsActionsEdit:       "Bewerken",
    distributorsActionsDelete:     "Verwijderen",

    distributorsAddTitle:      "Distributeur toevoegen",
    distributorsEditTitle:     "Distributeur bewerken",
    distributorsEditSuccess:   "De gegevens van de distributeur zijn succesvol opgeslagen",
    distributorsEditError:     "Er is een fout opgetreden bij het opslaan van de gegevens van de distributeur",
    distributorsRemoveTitle:   "Weet u zeker dat u deze distributeur wil verwijderen?",
    distributorsRemoveBody:    "Als u de distributeur met de naam '%1' verwijdert, worden alle machines die momenteel aan deze distributeur gekoppeld zijn, aan Spiqle gekoppeld. Dit is niet ongedaan te maken.",
    distributorsRemoveSure:    "Distributeur verwijderen",
    distributorsRemoveSuccess: "De distributeur is succesvol verwijderd",
    distributorsRemoveError:   "Er is een fout opgetreden bij het verwijderen van de distributeur",

    distributorName:    "Naam",
    distributorAddress: "Adres",
    distributorZipcode: "Postcode",
    distributorCity:    "Plaats",
    distributorState:   "Provincie",
    distributorCountry: "Land",
    distributorPhone:   "Telefoon nummer",
    distributorEmail:   "E-mail adres",
};