// React
import React, {useEffect, useState} from "react";

// Redux
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../../store/actions";

// Tools
import i18n from "../../../i18n";

// Components
import {BottomBar, Button, Form, Spinner, ButtonCrusher, Tour} from "../../../components";
import {TitleWrapper} from "../../../contexts/TitleProvider";
import {getPageHelpSteps} from "../../../help/tools";
import withCaretakers from "../../../hoc/withCaretakers";
import css from "./AddNewMachine.module.css";
import {appRootPath} from "../../../variables/multiplatform";
import addScreenLogo from "../../../assets/img/add-screen.png";

const coupleForm = {
    screenCode: {
        label:      i18n.caretakersAddNewMachineScreenCodeLabel,
        className:  css.tourScreenCode,
        validation: {
            isRequired: true,
            regex:      /^([a-z]+)\s([a-z]+)\s([a-z]+)\s([a-z]+)\s([a-z]+)$/i
        }
    },
    screenName: {
        label:     i18n.caretakersAddNewMachineScreenName,
        className: css.tourScreenName
    }
};

const AddNewMachine = props => {
    const dispatch = useDispatch();

    const [formValues, setFormValues] = useState({});
    const [formValid, setFormValid]   = useState(false);
    const [helpOpen, setHelpOpen]     = useState(false);
    const doHelp                      = () => setHelpOpen(true);
    const closeHelp                   = () => setHelpOpen(false);

    const screenCodeChecking = useSelector(state => state.caretakers.screenCodeChecking);
    const screenCodeValid    = useSelector(state => state.caretakers.screenCodeValid);
    useEffect(() => {
        if (!screenCodeChecking && screenCodeValid) {
            dispatch(actions.caretakers.resetScreenCodeValid());
            dispatch(actions.caretakers.add({
                existing:   props.caretakerUuid,
                screenCode: formValues.screenCode,
                screenName: formValues.screenName
            }, "/caretakers/status/" + props.caretakerUuid))
        }
    }, [screenCodeChecking, screenCodeValid, dispatch, formValues.screenCode, formValues.screenName, props.caretakerUuid]);

    const handleChange = (newFormValues, newFormValid) => {
        setFormValues(newFormValues);
        setFormValid(newFormValid);
    };
    const checkCode    = () => {
        if (!formValid) {
            dispatch(actions.messages.errorPush(i18n.errorForm));
            return;
        }
        dispatch(actions.caretakers.checkScreenCode(formValues.screenCode));
    }

    //dispatch(actions.caretakers.add(details))
    if (!props.caretaker || props.loading || props.saving) {
        return <TitleWrapper title={i18n.caretakersAddNewMachine}><Spinner/></TitleWrapper>
    }

    return (
        <TitleWrapper title={i18n.caretakersAddNewMachineTitle}>
            <Tour
                isOpen={helpOpen}
                onRequestClose={closeHelp}
                steps={getPageHelpSteps("addNewMachine")}
            />
            <div>
                <div className="content">
                    <div className={css.imgContainer}>
                        <img src={appRootPath + addScreenLogo} alt={i18n.caretakersAddNewMachineTitle} />
                    </div>
                    <div className={css.messageContainer}>{i18n.caretakersAddNewMachineScreenCode}</div>
                    <Form formData={coupleForm} values={formValues} onChange={handleChange} onSubmit={checkCode} handleEnter stacked/>
                    <ButtonCrusher width={74} topSpacer={25}>
                        <Button upper block round color="info" onClick={checkCode}>{i18n.buttonAdd}</Button>
                    </ButtonCrusher>
                    <BottomBar
                        backButton
                        backUrl={"/caretakers/status/" + props.caretakerUuid}
                        onHelp={doHelp}
                        onCloseHelp={closeHelp}
                        helpOpen={helpOpen}/>
                </div>
            </div>
        </TitleWrapper>
    );
};

export default withCaretakers(AddNewMachine);
