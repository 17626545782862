import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import AutoWrapInput from "../../InputContainers/AutoWrapInput";
import {deepCompare} from "../../../../variables/tools";
import i18n from "../../../../i18n";
import * as actions from "../../../../store/actions";
import {useDispatch, useSelector} from "react-redux";

const Distributor = props => {
    const dispatch = useDispatch();
    const distributors    = useSelector(state => state.distributors.distributors);
    const loading  = useSelector(state => state.distributors.loading);
    useEffect(() => {dispatch(actions.distributors.loadIfNeeded())}, [dispatch]);

    let distributorOptions = [];
    if (!loading && distributors) {
        distributorOptions = distributors.map(distributor => ({value: distributor.uuid, label: distributor.name}));
        if (props.settings.hideDistributor) {
            distributorOptions = distributorOptions.filter(distributor => distributor.value !== props.settings.hideDistributor);
        }
    }

    return (
        <AutoWrapInput name={props.name} settings={props.settings} stacked={props.stacked}>
            {!loading && distributors ? (
                <Select
                    {...props.settings.elementConfig}
                    name={props.name}
                    onChange={option => props.handlers.change(option.value, props.name)}
                    onFocus={() => props.handlers.focus(props.name, true)}
                    onBlur={() => props.handlers.focus(props.name, false)}
                    noOptionsMessage={() => i18n.noDistributors}
                    classNamePrefix="react-select"
                    className="react-select"
                    value={distributorOptions.filter(distributor => distributor.value === props.settings.value)}
                    options={distributorOptions}
                    ref={props.innerRef}
                />
            ) : <p>{i18n.loading}</p>}
        </AutoWrapInput>
    );
};

Distributor.propTypes = {
    name:     PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    handlers: PropTypes.object.isRequired,
    innerRef: PropTypes.object,
    stacked:  PropTypes.bool
};

export default React.memo(Distributor, (prevProps, nextProps) => (
    prevProps.settings.value === nextProps.settings.value &&
    prevProps.settings.focused === nextProps.settings.focused &&
    prevProps.settings.valid === nextProps.settings.valid &&
    deepCompare(prevProps.settings.options, nextProps.settings.options)
));
