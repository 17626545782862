import * as actionTypes from "../actionTypes";
import {runActionMap, send, sendDone, sendFail, resetError} from "../reducerFunctions";

const initialState = {
    sending: false,
    error:   null
};

const actionMap   = {
    [actionTypes.CONTACT_SEND]:         send,
    [actionTypes.CONTACT_SEND_SUCCESS]: sendDone,
    [actionTypes.CONTACT_SEND_FAIL]:    sendFail,
    [actionTypes.CONTACT_RESET_ERROR]:  resetError
};
const contactReducer = (state = initialState, action) => runActionMap(state, action, actionMap);

export default contactReducer;
