// React
// React
import React, {useEffect, useState} from "react";

// Redux
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../../store/actions";

// Tools
import i18n from "../../../i18n";
import moment from 'moment';
import 'moment-timezone';

// Components
import {BottomBar, GroupList, Spinner, Tour} from "../../../components";
import withGroups from "../../../hoc/withGroups";

import {TitleWrapper} from "../../../contexts/TitleProvider";

import css from "./Calendars.module.css";
import {getPageHelpSteps} from "../../../help/tools";
import ListItem from "./ListItem";

const GroupCalendars = props => {
    const dispatch = useDispatch();

    // Help
    const [helpOpen, setHelpOpen] = useState(false);
    const doHelp                  = () => setHelpOpen(true);
    const closeHelp               = () => setHelpOpen(false);

    const loadingCalendars = useSelector(state => state.groupCalendars.loading);
    const calendarsFor     = useSelector(state => state.groupCalendars.calendarsFor);
    const calendars        = useSelector(state => state.groupCalendars.calendars);
    const loadedAt         = useSelector(state => state.groupCalendars.loadedAt);

    useEffect(() => {
        if (
            props.group &&
            !loadingCalendars && (
                calendarsFor !== props.group.uuid ||
                (!loadedAt || loadedAt.isBefore(moment().subtract(5, "minutes")))
            )
        ) {
            dispatch(actions.groupCalendars.load(props.group.uuid));
        }
    }, [props.group, loadingCalendars, calendarsFor, loadedAt, dispatch]);

    if (!props.group || !calendars || props.loading || loadingCalendars) {
        return (
            <TitleWrapper title={i18n.groupsItemCalendars}>
                <div>
                    <Spinner/>
                    <BottomBar backButton/>
                </div>
            </TitleWrapper>
        );
    }

    const events = [
        ...calendars.appointments.map(item => ({...item, type: "appointment"})),
        ...calendars.birthdays.map(item => ({...item, type: "birthday"})),
        ...calendars.reminders.map(item => ({...item, type: "reminder"})),
        ...calendars.tvshows.map(item => ({...item, type: "tvshow"}))
    ];

    return (
        <TitleWrapper title={
            i18n.calendarOverview
                .replace("%1", props.group ? props.group.name : i18n.group)
        }>
            <Tour
                isOpen={helpOpen}
                onRequestClose={closeHelp}
                steps={getPageHelpSteps("groupAllItems")}
            />
            <div>
                <div className="content">
                    <GroupList groupUUID={props.group.uuid} />
                    {events.length === 0 ?
                        <p>{i18n.groupsCalendarsNoItems}</p> : (
                            <div className={css.listItemsContainer}>
                                {calendars.appointments.length > 0 && (<>
                                    <span className={css.listItemCategory}>{i18n.calendarTypes.appointments}:</span>
                                    {calendars.appointments.map(item => <ListItem key={item.uuid} type="appointment" {...item} />)}
                                </>)}
                                {calendars.reminders.length > 0 && (<>
                                    <span className={css.listItemCategory}>{i18n.calendarTypes.reminders}:</span>
                                    {calendars.reminders.map(item => <ListItem key={item.uuid} type="reminder" {...item} />)}
                                </>)}
                                {calendars.tvshows.length > 0 && (<>
                                    <span className={css.listItemCategory}>{i18n.calendarTypes.tvshows}:</span>
                                    {calendars.tvshows.map(item => <ListItem key={item.uuid} type="tvshow" {...item} />)}
                                </>)}
                                {calendars.birthdays.length > 0 && (<>
                                    <span className={css.listItemCategory}>{i18n.calendarTypes.birthdays}:</span>
                                    {calendars.birthdays.map(item => <ListItem key={item.uuid} type="birthday" {...item} />)}
                                </>)}
                            </div>
                        )
                    }
                    <BottomBar
                        backButton
                        backUrl="/"
                        addButton={() => props.push("/groups/add/" + props.groupUuid)}
                        links={[
                            {title: i18n.groupsItemCalendar, link: "/caretakers/calendars/" + props.group.uuid}
                        ]}
                        helpOpen={helpOpen} onHelp={doHelp} onCloseHelp={closeHelp}
                    />
                </div>
            </div>
        </TitleWrapper>
    );
}

export default withGroups(GroupCalendars);
