export default {
    routeCaretakers:            "My caretakers",
    routeLocations:             "All locations",
    routeAdmin:                 "Administration",
    routeAdminCaretakersFull:   "Caretakers",
    routeAdminCaretakersMini:   "C",
    routeAdminAffiliatesFull:   "Affiliates",
    routeAdminAffiliatesMini:   "A",
    routeAdminDistributorsFull: "Distributors",
    routeAdminDistributorsMini: "D",
    routeAdminGroupsFull:       "Groups",
    routeAdminGroupsMini:       "G",
    routeAdminUsersFull:        "Users",
    routeAdminUsersMini:        "U",
    routeAdminMachinesFull:     "Machines",
    routeAdminMachinesMini:     "M",
    routeTechnical:             "Technical",
    routeTechnicalStatusFull:   "Machine status",
    routeTechnicalStatusMini:   "MS",
    routeTechnicalManualFull:   "Manual update",
    routeTechnicalManualMini:   "MU",
    routeTechnicalUpdatesFull:  "Firmware updates",
    routeTechnicalUpdatesMini:  "FU",
    routeHelp:                  "Help",
    routeAccount:               "Account",
    routeContact:               "Contact",
    routeAbout:                 "About",
    routeLogout:                "Log out",
};