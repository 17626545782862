import React from 'react';
import PropTypes from 'prop-types';
import {Label} from "reactstrap";
import i18n from "../../../i18n";
import css from "./NextTime.module.css";

const NextTime = props => {
    if(!props.item || !props.item.nextTime) {
        return null;
    }
    return (
        <div className={css.container}>
            <Label className={css.blockLabel}>{i18n.calendarNextTime}:</Label>
            <Label className={css.blockData}>{props.item.nextTime.format("LLLL")}</Label>
        </div>
    );
};

NextTime.propTypes = {
    item: PropTypes.object.isRequired
};

export default NextTime;