import React from "react";
import PropTypes from "prop-types";

import {apiGetFullPath, apiGetToken} from "../../../api";
import {appRootPath} from "../../../variables/multiplatform";

import defaultAvatar from "../../../assets/img/placeholder.jpg";
import css from "./SingleCaretaker.module.css";

const SingleCaretaker = props => {
    return (
        <div className={css.item + (!props.selected ? " " + css.notSelected : "")} onClick={props.onClick}>
            <div className={css.avatar}>
                <div className={[css.imgWrapper, css.machineIndicator].join(" ")}>
                    <img src={
                        props.avatar ?
                            apiGetFullPath("caretakers/getavatar?uuid=" + props.uuid + "&token=" + apiGetToken()) :
                            appRootPath + defaultAvatar
                    } alt="..."/>
                </div>
            </div>
            <div className={css.text}>
                <div className={css.title}>
                    {props.name}
                </div>
            </div>
        </div>
    );
};

SingleCaretaker.propTypes = {
    id:     PropTypes.number,
    name:   PropTypes.string,
    avatar: PropTypes.bool
};

export default SingleCaretaker;
