import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import PropTypes from 'prop-types';

import i18n from "../../../../../i18n";
import * as reduxActions from "../../../../../store/actions";

import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Button} from "../../../../../components";

const LogInAs = props => {
    const dispatch                      = useDispatch();
    const [showLogInAs, setShowLogInAs] = useState(false);

    return (
        <>
            <Button color="danger" block round simple onClick={() => setShowLogInAs(true)}>
                {i18n.usersActionsLogInAsButton}
            </Button>
            <Modal isOpen={showLogInAs} toggle={() => setShowLogInAs(!showLogInAs)} className="text-center">
                <ModalHeader className="justify-content-center uppercase title" tag="h4">{i18n.usersLogInAsTitle}</ModalHeader>
                <ModalBody><p>{i18n.usersLogInAsBody.replace('%1', props.userName)}</p></ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={() => dispatch(reduxActions.users.loginAs(props.userId))}>{i18n.usersLogInAsSure}</Button>
                    <Button color="info" onClick={() => setShowLogInAs(false)}>{i18n.buttonCancel}</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

LogInAs.propTypes = {
    userId:   PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired
};

export default LogInAs;