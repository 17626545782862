import * as actionTypes from "../actionTypes";

export const load         = () => ({type: actionTypes.AFFILIATES_LOAD});
export const loadIfNeeded = () => ({type: actionTypes.AFFILIATES_LOAD_IF_NEEDED});
export const loadSuccess  = affiliates => ({type: actionTypes.AFFILIATES_LOAD_SUCCESS, affiliates});
export const loadFail     = error => ({type: actionTypes.AFFILIATES_LOAD_FAIL, error: "AFFILIATES_LOAD_FAILED"});

export const save        = (uuid, affiliateDetails) => ({type: actionTypes.AFFILIATES_SAVE, uuid, affiliateDetails});
export const saveSuccess = () => ({type: actionTypes.AFFILIATES_SAVE_SUCCESS});
export const saveFail    = error => ({type: actionTypes.AFFILIATES_SAVE_FAIL, error});

export const remove        = uuid => ({type: actionTypes.AFFILIATES_REMOVE, uuid});
export const removeSuccess = () => ({type: actionTypes.AFFILIATES_REMOVE_SUCCESS});
export const removeFail    = error => ({type: actionTypes.AFFILIATES_REMOVE_FAIL, error});

export const clearError = () => ({type: actionTypes.AFFILIATES_RESET_ERROR});
