import {takeLatest, put} from "redux-saga/effects";
import * as actionTypes from "../actionTypes";
import * as actions from "../actions";

// Watcher
export function* watchApp() {
    yield takeLatest(actionTypes.APP_RECEIVED_UNIVERSAL_LINK, receivedUniversalLinkSaga);
    yield takeLatest(actionTypes.APP_RECEIVED_PAUSE, receivedPauseSaga);
    yield takeLatest(actionTypes.APP_RECEIVED_RESUME, receivedResumeSaga);
    yield takeLatest(actionTypes.APP_RECEIVED_BACK_BUTTON, receivedBackButtonSaga);
    yield takeLatest(actionTypes.APP_RECEIVED_MENU_BUTTON, receivedMenuButtonSaga);
    yield takeLatest(actionTypes.APP_RECEIVED_SEARCH_BUTTON, receivedSearchButtonSaga);
    yield takeLatest(actionTypes.APP_UNLOAD_EVERYTHING, unloadEverythingSaga);
}

// Sagas
function* receivedUniversalLinkSaga(action) {
}

function* receivedPauseSaga(action) {
}

function* receivedResumeSaga(action) {
    yield put(actions.caretakers.unload());
    yield put(actions.caretakerMessages.unload());
    yield put(actions.calendars.unload());
    yield put(actions.groups.unload());
    yield put(actions.groupMessages.unload());
    yield put(actions.groupCalendars.unload());
    yield put(actions.help.unload());
}

function* receivedBackButtonSaga(action) {
}

function* receivedMenuButtonSaga(action) {
}

function* receivedSearchButtonSaga(action) {
}

function* unloadEverythingSaga(action) {
}