// React
import React from "react";

// Redux
import {connect} from "react-redux";
import {push} from 'connected-react-router'
import * as actions from "../../../../store/actions";

// Tools
import i18n from "../../../../i18n";
import moment from 'moment-timezone';
import {calculatePagination} from "../../../../variables/tools";

// Components
import {Spinner, Icon, ColumnSelector, Button, CollapseActions, ReactTable, BottomBar} from "../../../../components";
import Uncoupler from "../../Machines/Tools/Uncoupler";
import Coupler from "../../Machines/Tools/Coupler";
import Swapper from "../../Machines/Tools/Swapper";
import css from "./Machines.module.css";
import {TitleWrapper} from "../../../../contexts/TitleProvider";

class Machines extends React.Component {
    state = {
        caretaker:    null,
        columns:      null,
        showUncouple: false,
        uncoupleMac:  "",
        showCouple:   false,
        showSwap:     false,
        swapMac:      ""
    };

    tableColumns = [
        {
            id:       "serial",
            accessor: "serial_no",
            Header:   i18n.machinesSerialNumber,
            width:    105
        },
        {
            id:        "serial_check",
            accessor:  "serial_check",
            Header:    i18n.machinesSerialCheck,
            width:     105,
            togglable: true,
            show:      false
        },
        {
            id:        "mac",
            Header:    i18n.machinesMacAddress,
            accessor:  "mac_address",
            width:     140,
            togglable: true,
            show:      true
        },
        {
            id:        "os",
            Header:    i18n.machinesOS,
            accessor:  "os",
            width:     50,
            togglable: true,
            show:      false
        },
        {
            id:        "last_update",
            Header:    i18n.machinesLastUpdate,
            accessor:  d => moment.utc(d["last_update"] * 1000).format("YYYY-MM-DD HH:mm:ss"),
            width:     175,
            togglable: true,
            show:      true
        },
        {
            id:        "last_boot",
            Header:    i18n.machinesLastBoot,
            accessor:  d => moment.utc(d["last_boot"] * 1000).format("YYYY-MM-DD HH:mm:ss"),
            width:     175,
            togglable: true,
            show:      false
        },
        {
            id:        "created_at",
            Header:    i18n.machinesCreatedAt,
            accessor:  d => moment.utc(d["created_at"] * 1000).format("YYYY-MM-DD HH:mm:ss"),
            width:     175,
            togglable: true,
            show:      false
        },
        {
            id:           "network",
            Header:       i18n.machinesNetwork,
            Cell:         props => {
                let network;
                try {
                    network = JSON.parse(props.value);
                } catch (e) {
                    return <span>Unknown</span>;
                }

                switch (network.type) {
                    case "Ethernet":
                        return (
                            <span>
                            <Icon name="audio-jack" title={i18n.machinesNetworkFilterEthernet}/>
                                {" " + i18n.machinesNetworkFilterEthernet + " - " + network.ip}
                        </span>
                        );

                    case "WiFi":
                        return (
                            <span>
                            <Icon name="smart-house" title={i18n.machinesNetworkFilterWiFi}/>
                                {" " + i18n.machinesNetworkFilterWiFi + " - " + network.ip}
                        </span>
                        );

                    case "Mobile":
                        let signalPercent;
                        if (!network.signal) {
                            return <span>Unknown</span>;
                        }
                        switch (network.signal.mode) {
                            case "LTE":
                                signalPercent = Math.round(((+network.signal.RSRQ + 20) / 12) * 100);
                                break;

                            case "GSM":
                                signalPercent = Math.round(((+network.signal.rxLevel + 100) / 35) * 100);
                                break;

                            default:
                                signalPercent = 0;

                        }
                        if (signalPercent > 100) {
                            signalPercent = 100;
                        }
                        if (signalPercent < 0) {
                            signalPercent = 0;
                        }

                        return (
                            <span>
                            <Icon name="signal" title={i18n.machinesNetworkFilterMobile}/>
                                {" " + network.signal.mode + " - " + signalPercent + "%"}
                        </span>
                        );

                    default:
                        return <span>Unknown</span>;
                }
            },
            accessor:     "network",
            width:        200,
            filterMethod: (filter, row) => {
                if (filter.value === "All") {
                    return true;
                }
                try {
                    let network = JSON.parse(row.network);
                    switch (filter.value) {
                        case "Ethernet":
                        case "WiFi":
                        case "Mobile":
                            return network.type === filter.value;

                        case "Mobile_LTE":
                        case "Mobile_GSM":
                            if (network.type !== "Mobile" || !network.signal) {
                                return false;
                            }
                            return network.signal.mode === filter.value.substr(7);

                        default:
                            return false;
                    }
                } catch (e) {
                    return false;
                }
            },
            Filter:       ({filter, onChange}) => (
                <select onChange={event => onChange(event.target.value)} style={{width: "100%"}} value={filter ? filter.value : "All"}>
                    <option value="All">{i18n.machinesNetworkFilterAll}</option>
                    <option value="Ethernet">{i18n.machinesNetworkFilterEthernet}</option>
                    <option value="WiFi">{i18n.machinesNetworkFilterWiFi}</option>
                    <option value="Mobile">{i18n.machinesNetworkFilterMobile}</option>
                    <option value="Mobile_LTE">{i18n.machinesNetworkFilterMobileLTE}</option>
                    <option value="Mobile_GSM">{i18n.machinesNetworkFilterMobileGSM}</option>
                </select>
            ),
            filterable:   true,
            togglable:    true,
            show:         true
        },
        {
            id:        "imsi",
            Header:    i18n.machinesIMSI,
            accessor:  d => {
                try {
                    let network = JSON.parse(d.network);
                    if (network.imsi) {
                        return network.imsi;
                    }
                } catch (e) {
                }
                return i18n.unknown;
            },
            width:     160,
            togglable: true,
            show:      false
        },
        {
            id:        "iccid",
            Header:    "ICCID",
            accessor:  d => {
                try {
                    let network = JSON.parse(d.network);
                    if (network.iccid) {
                        return network.iccid;
                    }
                } catch (e) {
                }
                return i18n.unknown;
            },
            width:     160,
            togglable: true,
            show:      false
        },
        {
            id:        "imei",
            Header:    i18n.machinesIMEI,
            accessor:  d => {
                try {
                    let network = JSON.parse(d.network);
                    if (network.imei) {
                        return network.imei;
                    }
                } catch (e) {
                }
                return "Unknown";
            },
            width:     160,
            togglable: true,
            show:      false
        },
        {
            id:        "last_ip",
            Header:    i18n.machinesLastIP,
            accessor:  "last_ip",
            width:     160,
            togglable: true,
            show:      false
        },
        {
            id:        "temperature",
            Header:    i18n.machinesTemperature,
            accessor:  d => d.temperature + "°C",
            width:     100,
            togglable: true,
            show:      true
        },
        {
            id:         "filler",
            minWidth:   10,
            Header:     "",
            accessor:   () => "",
            sortable:   false,
            filterable: false
        },
        {
            id:              "actions",
            Header:          "",
            minWidth:        110,
            maxWidth:        110,
            headerClassName: css.actions,
            className:       css.actions,
            sortable:        false,
            filterable:      false,
            Cell:            props => (
                <CollapseActions title={i18n.machinesActions}>
                    <Button color="primary" block simple onClick={() => this.props.history.push("/admin/machines/info/" + props.value)}>
                        {i18n.machinesActionsInfo}
                    </Button>
                    <Button color="primary" block simple onClick={() => this.setState({showSwap: true, swapMac: props.value})}>
                        {i18n.machinesActionsSwap}
                    </Button>
                    <Button color="primary" block simple onClick={() => this.setState({showUncouple: true, uncoupleMac: props.value})}>
                        {i18n.machinesActionsUncouple}
                    </Button>
                </CollapseActions>
            ),
            accessor:        "mac_address",
        }
    ];

    constructor(props) {
        super(props);
        this.state.columns = this.tableColumns;

        let uuid = props.match.params.caretaker;
        if (uuid.length !== 32) {
            props.push("/admin/caretakers");
            return;
        }
        if (props.caretakers) {
            this.state.caretaker = this.props.caretakers.find(obj => obj.uuid === uuid);
            if (!this.state.caretaker) {
                props.push("/admin/caretakers");
                return;
            }
            props.loadMachineDetails(uuid);
        } else {
            if (!props.loading) {
                props.load();
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props === prevProps) {
            return;
        }

        // If the location reloaded (path is the same, but key is different), reload machine details
        if (this.props.location.key !== prevProps.location.key) {
            this.props.loadMachineDetails(this.props.match.params.caretaker);
        }

        // If the caretaker store suddenly changed, update the details
        if (this.props.caretakers && this.props.caretakers !== prevProps.caretakers && this.props.caretakers.length > 0) {
            let uuid      = this.props.match.params.caretaker;
            let caretaker = this.props.caretakers.find(obj => obj.uuid === uuid);
            if (caretaker) {
                this.setState({caretaker});
                if (!this.props.lastMachinesLoadedAt || this.props.lastMachinesLoadedAt.isBefore(this.props.loadedAt)) {
                    this.props.loadMachineDetails(uuid);
                }
            } else {
                // Unknown caretaker, go back to caretaker overview
                this.props.push("/admin/caretakers");
            }
        }
    }

    render() {
        return (
            <TitleWrapper title={i18n.caretakersItemMachines.replace(
                "%1",
                this.state.caretaker ? this.state.caretaker.name : i18n.caretaker
            )}>
                <div>
                    <div className="content">
                        {this.state.caretaker && !this.props.loading ? (
                            <>
                                <ColumnSelector cookieId="adminMachines" columns={this.state.columns} changed={columns => this.setState({columns})}/>
                                <ReactTable
                                    filterable
                                    indexKey="id"
                                    {...calculatePagination(this.state.caretaker.machines, 5)}
                                    data={this.state.caretaker.machines}
                                    columns={this.state.columns}
                                    defaultSorted={[{id: "serial", desc: false}]}
                                    noDataText={i18n.noMachines}
                                />
                                <Button round color="info" block onClick={() => this.setState({showCouple: true})}>
                                    {i18n.caretakersItemMachinesAdd}
                                </Button>
                                <Uncoupler
                                    isOpen={this.state.showUncouple}
                                    mac={this.state.uncoupleMac}
                                    close={() => this.setState({showUncouple: false})}
                                    redirect={"/admin/caretakers/machines/" + this.props.match.params.caretaker}
                                />
                                <Coupler
                                    isOpen={this.state.showCouple}
                                    close={() => this.setState({showCouple: false})}
                                    caretakerId={this.props.match.params.caretaker}
                                    caretakerName={this.state.caretaker.name}
                                    redirect={"/admin/caretakers/machines/" + this.props.match.params.caretaker}
                                />
                                <Swapper
                                    isOpen={this.state.showSwap}
                                    mac={this.state.swapMac}
                                    caretakerToReload={this.props.match.params.caretaker}
                                    close={() => this.setState({showSwap: false})}
                                    redirect={"/admin/caretakers/machines/" + this.props.match.params.caretaker}
                                />
                            </>
                        ) : <Spinner/>}
                        <BottomBar backButton/>
                    </div>
                </div>
            </TitleWrapper>
        );
    }
}

const mapStateToProps = state => ({
    loading:              state.caretakers.loading,
    saving:               state.caretakers.saving,
    loadedAt:             state.caretakers.caretakersLoaded,
    caretakers:           state.caretakers.caretakers,
    lastMachinesLoadedAt: state.caretakers.lastMachinesLoadedAt
});

const mapDispatchToProps = dispatch => ({
    push:               pathname => dispatch(push(pathname)),
    load:               () => dispatch(actions.caretakers.load()),
    loadMachineDetails: uuid => dispatch(actions.caretakers.loadMachineDetails(uuid))
});

export default connect(mapStateToProps, mapDispatchToProps)(Machines);
