import React from "react";
import {connect} from "react-redux";
import {withRouter, matchPath} from "react-router";
import {Navbar, Collapse} from "reactstrap";

import authenticatedRoutes from "../../../routes/authenticated";
import {Link} from "react-router-dom";
import {filterRoutes} from "../../../variables/tools";
import {TitleConsumer} from "../../../contexts/TitleProvider";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen:        false,
            dropdownOpen:  false,
            color:         "transparent",
            openAdmin:     this.activeRoute("/admin") !== "",
            openTechnical: this.activeRoute("/technical") !== ""
        };
    }

    toggle = () => {
        if (this.state.isOpen) {
            this.setState({color: "transparent"});
        } else {
            this.setState({color: "white"});
        }
        this.setState({isOpen: !this.state.isOpen});
    };

    dropdownToggle = () => this.setState({dropdownOpen: !this.state.dropdownOpen});

    findRoute = () => {
        for (let i = 0; i < authenticatedRoutes.length; i++) {
            if (authenticatedRoutes[i].collapse) {
                for (let j = 0; j < authenticatedRoutes[i].views.length; j++) {
                    if (matchPath(this.props.location.pathname, {path: authenticatedRoutes[i].views[j].path})) {
                        return authenticatedRoutes[i].views[j];
                    }
                }
            }
            if (matchPath(this.props.location.pathname, {path: authenticatedRoutes[i].path})) {
                return authenticatedRoutes[i];
            }
        }
        return null;
    };

    toggleCollapse = (event, state) => {
        event.preventDefault();
        this.setState(state);
        //this.scrollBarRef.updateScroll();
    };

    /*    hasHelp = () => {
            let route = this.findRoute();
            if (route && this.props.helpItems) {
                let item = this.props.helpItems.find(item => item.pageBinding === route.path);
                if (item) {
                    return route.path;
                }
            }
            return null;
        };*/

    getBrand = () => {
        let route = this.findRoute();
        if (route) {
            /*            if(route.hideBrand) {
                            return null;
                        }*/
            return route.name;
        }
        return null;
    };

    openSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
        this.refs.sidebarToggle.classList.toggle("toggled");
    };

    // function that adds color white/transparent to the navbar on resize (this is for the collapse)
    updateColor = () => {
        if (window.innerWidth < 993 && this.state.isOpen) {
            this.setState({color: "white"});
        } else {
            this.setState({color: "transparent"});
        }
    };

    componentDidMount() {
        window.addEventListener("resize", this.updateColor);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateColor);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            window.innerWidth < 993 &&
            prevProps.history.location.pathname !== prevProps.location.pathname &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
            this.refs.sidebarToggle.classList.toggle("toggled");
        }
    }

    activeRoute = routeName => this.props.location.pathname.indexOf(routeName) === 0 ? "active" : "";

    render() {
        const paths = filterRoutes(authenticatedRoutes, this.props.rights, this.props.caretakerRights);
        return (
            <div className="top-header">
                <Navbar expand="lg" className="navbar-absolute">
                    <div className="navbar-wrapper">
                        <TitleConsumer>
                            {({title}) => <h2 className="title">{this.state.isOpen ? "Menu" : title}</h2>}
                        </TitleConsumer>
                        <div className="navbar-toggle">
                            <button type="button" ref="sidebarToggle" className={"navbar-toggler " + (this.state.isOpen ? "toggled" : "")}
                                    onClick={() => this.toggle()}>
                                <span className="navbar-toggler-bar bar1"/>
                                <span className="navbar-toggler-bar bar2"/>
                                <span className="navbar-toggler-bar bar3"/>
                            </button>
                        </div>
                    </div>
                </Navbar>
                <Collapse className="navbar-menu" isOpen={this.state.isOpen}>
                    {paths.map((prop, key) => {
                        if (prop.redirect || prop.hidden) {
                            return null;
                        }
                        if (prop.collapse) {
                            let st = {[prop["state"]]: !this.state[prop.state]};
                            return (
                                <div key={key}>
                                    <a href="#expand"
                                       data-toggle="collapse"
                                       aria-expanded={this.state[prop.state]}
                                       onClick={e => this.toggleCollapse(e, st)}
                                       style={prop.color ? {color: prop.color} : {}}
                                    >
                                        {prop.name}
                                        <b className="caret"/>
                                    </a>
                                    <Collapse isOpen={this.state[prop.state]}>
                                        {prop.views.map((prop, key) => {
                                            if (prop.redirect || prop.hidden) {
                                                return null;
                                            }
                                            return <Link
                                                to={prop.path}
                                                role="menuitem"
                                                key={key}
                                                onClick={this.toggle}
                                                style={prop.color ? {color: prop.color} : {}}
                                            >
                                                {prop.name}
                                            </Link>;
                                        })}
                                    </Collapse>
                                </div>
                            );
                        }
                        return <Link
                            key={key}
                            to={prop.path}
                            role="menuitem"
                            onClick={this.toggle}
                            style={prop.color ? {color: prop.color} : {}}
                        >
                            {prop.name}
                        </Link>;
                    })}
                    <div className="padded-bottom"/>
                </Collapse>
                <div className="top-header-rounded"/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    rights:          state.auth.rights,
    caretakerRights: state.auth.caretakerRights,
    helpItems:       state.help.items
});
export default connect(mapStateToProps)(withRouter(Header));
