import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {ConnectedRouter} from "connected-react-router";
import {Route, Redirect} from "react-router-dom";

import Pages from "./containers/Pages/Pages";
import Dashboard from "./containers/Dashboard/Dashboard";

import authenticatedRoutes from './routes/authenticated';
import unauthenticatedRoutes from './routes/unauthenticated';
import {filterRoutes} from "./variables/tools";
import {Spinner} from "./components";

const App = props => {
    const isAuthenticated = useSelector(state => state.auth.authenticated);
    const isAuthenticating = useSelector(state => state.auth.authenticating);
    const rights = useSelector(state => state.auth.rights);
    const caretakerRights = useSelector(state => state.auth.caretakerRights);

    if(isAuthenticating) {
        return <Spinner/>;
    }

    if (!isAuthenticated) {
        return (
            <ConnectedRouter history={props.history}>
                <Pages>
                    {
                        unauthenticatedRoutes.map((prop, key) => {
                            if (prop.redirect) {
                                return <Redirect from={prop.path} to={prop.pathTo} key={"unauth" + key}/>
                            } else {
                                return <Route path={prop.path} component={prop.component} key={"unauth" + key}/>
                            }
                        })
                    }
                </Pages>
            </ConnectedRouter>
        );
    }

    const paths = filterRoutes(authenticatedRoutes, rights, caretakerRights);
    return (
        <ConnectedRouter history={props.history}>
            <Dashboard>
                {
                    paths.map((prop, key) => {
                        if (prop.collapse) {
                            return prop.views.map((prop2, key2) => <Route path={prop2.path} component={prop2.component} key={key2} />);
                        }
                        if (prop.redirect) {
                            return <Redirect from={prop.path} to={prop.pathTo} key={"auth" + key}/>
                        }
                        else {
                            return <Route path={prop.path} component={prop.component} key={"auth" + key}/>
                        }
                    })
                }
            </Dashboard>
        </ConnectedRouter>
    );
};

App.propTypes = {
    history: PropTypes.object.isRequired
};

export default React.memo(App, (prevProps, nextProps) => {
    //console.log("update", nextProps);
});