export default {
    user:    "Gebruiker",
    users:   "Gebruikers",
    noUsers: "Geen gebruikers",

    usersTitle:        "Gebruikers",
    usersTableName:    "Naam",
    usersTableAddress: "Adres",
    usersTableZipcode: "Postcode",
    usersTableCity:    "Stad",
    usersTableState:   "Provincie",
    usersTableCountry: "Land",
    usersTablePhone:   "Telefoon",
    usersTableEmail:   "E-mail",
    usersTableLogin:   "Laatste inlog",

    usersTableAdmin:            "Super admin",
    usersTableDisabled:         "Uitgeschakeld",
    usersTableEmailConfirmed:   "E-mail adres bevestigd",
    usersTableTwoFactorEnabled: "2-factor aangezet",
    usersActionsTitle:          "Acties voor deze gebruikers",
    usersActionsInfo:           "Gebruikers info",
    usersActionsDelete:         "Verwijderen",

    usersActionsDeleteButton:       "Gebruiker verwijderen",
    usersActionsDuplicateButton:    "Gebruiker dupliceren",
    usersActionsCopyRightsButton:   "Gebruikers rechten kopiëren naar andere gebruiker",
    usersActionsViewActivityButton: "Gebruikers activiteit log bekijken",
    usersActionsLogInAsButton:      "Inloggen als deze gebruiker",
    usersActionsDisableTwoFactor:   "Zet 2-factor authenticatie uit",

    usersAddTitle:    "Gebruiker toevoegen",
    usersEditTitle:   "Gebruiker bewerken",
    usersAddSuccess:  "Het nieuwe account is aangemaakt, de gebruiker heeft een tijdelijk wachtwoord ontvangen via e-mail.",
    usersEditSuccess: "De gegevens van de gebruiker zijn succesvol opgeslagen",
    usersEditError:   "Er is een fout opgetreden bij het opslaan van de gegevens van de gebruiker",

    usersLogInAsTitle: "Weet u zeker dat u van gebruiker wil wisselen?",
    usersLogInAsBody:  "Weet u zeker dat u wilt inloggen als '%1'? Om terug te gaan naar uw eigen gebruiker moet u uitloggen en opnieuw inloggen.",
    usersLogInAsSure:  "Inloggen als deze gebruiker",
    usersLogInAsFail:  "Er is een fout opgetreden bij het inloggen als deze gebruiker",

    usersDuplicateTitle:   "Gebruiker dupliceren",
    usersDuplicateBody:    "Maak een nieuwe gebruiker aan met dezelfde rechten als '%1'.",
    usersDuplicateSure:    "Gebruiker dupliceren",
    usersDuplicateSuccess: "De gebruiker is succesvol gedupliceerd. De gebruiker heeft een tijdelijk wachtwoord ontvangen via een e-mail.",
    usersDuplicateError:   "De gebruiker kon niet worden gedupliceerd.",

    usersCopyTitle:           "Gebruikers rechten kopiëren naar een andere gebruiker",
    usersCopyBody:            "Kopieer de rechten van '%1' naar een andere gebruiker.",
    usersCopyToUser:          "Naar gebruiker",
    usersCopyReplace:         "Rechten vervangen",
    usersCopyReplaceExpanded: "Vervang de rechten, in plaats van deze toe te voegen",
    usersCopySure:            "Rechten kopiëren",
    usersCopySuccess:         "De rechten van de gebruiker zijn succesvol gekopieerd.",
    usersCopyError:           "De rechten van de gebruiker konden niet worden gekopieerd.",

    usersTwoFactorDisableTitle:   "Weet u zeker dat u de 2-factor authenticatie van deze gebruiker wilt uitzetten?",
    usersTwoFactorDisableBody:    "'%1' kan hierna inloggen zonder 2-factor authenticatie en deze zelf weer opnieuw aanzetten.",
    usersTwoFactorDisableSure:    "Zet 2-factor authenticatie uit",
    usersTwoFactorDisableSuccess: "De 2-factor authenticatie voor de gebruiker is succesvol uitgezet.",
    usersTwoFactorDisableError:   "De 2-factor authenticatie voor de gebruiker kon niet uitgezet worden.",

    usersRemoveTitle:   "Weet u zeker dat u deze gebruiker wil verwijderen?",
    usersRemoveBody:    "Als u de gebruiker met de naam '%1' verwijdert, worden alle machines die momenteel aan deze gebruiker gekoppeld zijn, aan Spiqle gekoppeld. Dit is niet ongedaan te maken.",
    usersRemoveSure:    "Gebruiker verwijderen",
    usersRemoveSuccess: "De gebruiker is succesvol verwijderd",
    usersRemoveError:   "Er is een fout opgetreden bij het verwijderen van de gebruiker",

    usersInfoTitle:     "Gebruikers info",
    usersInfoLoadError: "Fout tijdens het laden van de gebruikers info",
    usersInfoDataHead:  "Gebruikers gegevens voor %1",

    usersRightsHead:          "Gebruiker rechten",
    usersRightsTableType:     "Rechten type",
    usersRightsTableName:     "Naam",
    usersRightsTableDelete:   "Verwijder recht",
    usersRightsAddRightTitle: "Voeg rechten toe aan gebruiker",
    usersRightsAddRight:      "Voeg recht toe",
    usersRightsCalendar:      "Kalender rechten",
    usersRightsSettings:      "Scherm instelling rechten",
    usersRightsMessages:      "Bericht rechten",
    usersRightsDetails:       "Zorgvrager gegevens rechten",
    usersRightsPreview:       "Voorbeeld rechten",
    usersRightsTechnical:     "Technische rechten",
    usersRightsInvite:        "Uitnodig rechten",
    usersRightsPayments:      "Betalings rechten",
    usersRightsNoRights:      "Deze gebruiker heeft nog geen rechten",
    usersRightsHasSuperAdmin: "Deze gebruiker is super admin",

    usersRightsPresetNoRights:  "Preset geen rechten",
    usersRightsPresetCaretaker: "Preset voor zorgvrager",
    usersRightsPresetAdmin:     "Preset voor administrator",

    usersRightsCalendarRight:  [
        "Geen kalender rechten",
        "Kalenders weergeven",
        "Kalenders bewerken"
    ],
    usersRightsSettingsRight:  [
        "Geen scherm instellingen rechten",
        "Toon scherm instellingen",
        "Bewerk scherm instellingen"
    ],
    usersRightsMessagesRight:  [
        "Geen berichten rechten",
        "Stuur directe berichten",
        "Stuur berichten en toon historie"
    ],
    usersRightsDetailsRight:   [
        "Geen zorgvrager gegevens rechten",
        "Toon zorgvrager gegevens",
        "Bewerk zorgvrager gegevens"
    ],
    usersRightsPreviewRight:   [
        "Geen voorbeeld rechten",
        "Toon scherm voorbeeld"
    ],
    usersRightsTechnicalRight: [
        "Geen technische rechten",
        "Toon technische machine gegevens",
        "Technische admin rechten"
    ],
    usersRightsInviteRight:    [
        "Geen uitnodigings rechten",
        "Rechten om nieuwe gebruikers uit te nodigen"
    ],
    usersRightsPaymentsRight:  [
        "Geen betalings rechten",
        "Rechten om de betalingen te beheren"
    ],

    usersRightsTypeCaretaker:   "Zorgvrager",
    usersRightsTypeGroup:       "Groep",
    usersRightsTypeDistributor: "Distributeur",
    usersRightsTypeAffiliate:   "Affiliate",
    usersRightsTypeMapping:     {
        caretaker:   "Zorgvrager",
        group:       "Groep",
        distributor: "Distributeur",
        affiliate:   "Affiliate"
    },

    userName:    "Naam",
    userAddress: "Adres",
    userZipcode: "Postcode",
    userCity:    "Plaats",
    userState:   "Provincie",
    userCountry: "Land",
    userPhone:   "Telefoon nummer",
    userEmail:   "E-mail adres",
};