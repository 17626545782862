import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, FormGroup, Input, Label, Collapse, Card, CardBody} from "reactstrap";
import i18n from "../../../i18n";

class ColumnSelector extends Component {

    state = {
        isOpen: false
    };
    constructor(props) {
        super(props);
        if(props.cookieId) {
            const columnSettings = localStorage.getItem("columns_" + props.cookieId);
            if(columnSettings) {
                try {
                    let columnObj = JSON.parse(columnSettings);
                    this.setColumns(columnObj);
                } catch (e) {
                    // Ignore local storage errors
                }
            }
        }
    }

    saveColumnData = columns => {
        if(this.props.cookieId) {
            const columnData = {};
            for (let value of columns) {
                if(value.togglable) {
                    columnData[value.id] = !!value.show;
                }
            }
            try {
                localStorage.setItem("columns_" + this.props.cookieId, JSON.stringify(columnData));
            } catch (e) {
                // Ignore local storage errors
            }
        }
    };
    setColumns = columnData => {
        const columns = [];
        for (let value of this.props.columns) {
            if(columnData.hasOwnProperty(value.id) && value.togglable) {
                columns.push({...value, show: columnData[value.id]});
            } else {
                columns.push({...value});
            }
        }
        this.saveColumnData(columns);
        this.props.changed(columns);
    };
    columnsChanged = (event, id) => {
        this.setColumns({[id]: event.target.checked});
    };

    render() {
        return (
            <>
                <Button color="primary" onClick={() => this.setState(state => ({isOpen: !state.isOpen}))}>
                    {i18n.showColumns}
                    <b className={"caret " + (this.state.isOpen ? "expanded" : "")} />
                </Button>
                <Collapse isOpen={this.state.isOpen}>
                    <Card>
                        <CardBody>
                            {this.props.columns.map(item => {
                                return item.togglable ? (
                                    <FormGroup check key={item.id}>
                                        <Label className="form-inline-check-label">
                                            <Input
                                                type="checkbox"
                                                name={"column-" + item.id}
                                                onChange={event => this.columnsChanged(event, item.id)}
                                                checked={item.show}
                                            />
                                            <span className="form-check-sign"/>
                                            {item.selectorTitle ? item.selectorTitle : item.Header}
                                        </Label>
                                    </FormGroup>
                                ) : null;
                            })}
                        </CardBody>
                    </Card>
                </Collapse>
            </>
        );
    }
}

ColumnSelector.propTypes = {
    changed: PropTypes.func.isRequired,
    columns: PropTypes.array.isRequired,
    cookieId: PropTypes.string
};

export default ColumnSelector;