// React
import React from "react";

// Redux
import {connect} from "react-redux";
import {push} from 'connected-react-router'
import * as actions from "../../../../store/actions";

// Tools
import i18n from "../../../../i18n";

// Components
import {Button, Form, Spinner, BottomBar} from "../../../../components";
import {TitleWrapper} from "../../../../contexts/TitleProvider";
import provinces from "../../../../i18n/provinces";

const caretakerForm = {
    name: {label: i18n.caretakerName},
    row1: {
        type:  "row",
        items: {
            address: {label: i18n.caretakerAddress, size: 8},
            zipcode: {label: i18n.caretakerZipcode, size: 4, validation: {required: true, minLength: 4}},
        }
    },
    row2: {
        type:  "row",
        items: {
            city:    {label: i18n.caretakerCity, size: 4},
            country:      {label: i18n.caretakerCountry, size: 4, defaultValue: "Nederland", type: "select", options: i18n.countries},
            state:        {
                label:        i18n.caretakerState,
                size:         4,
                type:         "select",
                selectType:   "slave",
                slaveTo:      "country",
                defaultSlave: "default",
                options:      provinces,
                passValues:   true
            }
        }
    }
};

class Add extends React.Component {
    state = {
        uuid:       null,
        caretaker:  null,
        formValues: {},
        formValid:  false
    };

    handleChange = (formValues, formValid) => this.setState({formValues, formValid});
    handleValid  = formValid => this.setState({formValid});
    handleSubmit = () => this.state.formValid && this.props.save("new", this.state.formValues);

    render() {
        return (
            <TitleWrapper title={i18n.caretakersItemAdd}>
                <div>
                    <div className="content">
                        {!this.props.loading && !this.props.saving ? (
                            <>
                                <Form
                                    formData={caretakerForm}
                                    values={this.state.formValues}
                                    onChange={this.handleChange}
                                    onValid={this.handleValid}
                                    stacked
                                />
                                <Button round color="info" block disabled={!this.state.formValid} onClick={this.handleSubmit}>
                                    {i18n.buttonSave}
                                </Button>
                            </>
                        ) : <Spinner/>}
                        <BottomBar backButton/>
                    </div>
                </div>
            </TitleWrapper>
        );
    }
}

const mapStateToProps = state => ({
    loading:    state.caretakers.loading,
    saving:     state.caretakers.saving,
    caretakers: state.caretakers.caretakers
});

const mapDispatchToProps = dispatch => ({
    push: pathname => dispatch(push(pathname)),
    save: (uuid, details) => dispatch(actions.caretakers.save(uuid, details, "/admin/caretakers"))
});

export default connect(mapStateToProps, mapDispatchToProps)(Add);
