import React from 'react';
import css from "./Overview.module.css";
import {useHistory, useParams} from "react-router-dom";
import i18n from "../../../i18n";

const WholeDayItem = props => {
    const params  = useParams();
    const history = useHistory();
    const classes = [css.wholeDayItem];
    let title     = "";
    let link      = "";
    switch (props.itemType) {
        case "birthday":
            title= i18n.calendarTypeSingular.birthday + ": " + props.name;
            link  = "/caretakers/calendar/birthdays/" + params.caretaker + "/edit/" + props.uuid;
            break;
        case "appointment":
            title = i18n.calendarTypeSingular.appointment + ": " + props.message;
            link  = "/caretakers/calendar/appointments/" + params.caretaker + "/edit/" + props.uuid;
            break;
        case "reminder":
            title = i18n.calendarTypeSingular.reminder + ": " + props.message;
            link  = "/caretakers/calendar/reminders/" + params.caretaker + "/edit/" + props.uuid;
            break;
        case "tvshow":
            title = i18n.calendarTypeSingular.tvshow + ": " + props.showName;
            link  = "/caretakers/calendar/tvshows/" + params.caretaker + "/edit/" + props.uuid;
            break;
        default:
    }

    return (
        <div className={classes.join(" ")} onClick={() => history.push(link)}>
            <span className={css.itemName}>
                {title}
            </span>
        </div>
    );
};

export default WholeDayItem;