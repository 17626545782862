import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {mapToCssModules, tagPropType} from 'reactstrap/src/utils';
import {Fade} from "reactstrap";

const propTypes = {
    children:       PropTypes.node,
    className:      PropTypes.string,
    closeClassName: PropTypes.string,
    closeAriaLabel: PropTypes.string,
    cssModule:      PropTypes.object,
    color:          PropTypes.string,
    fade:           PropTypes.bool,
    isOpen:         PropTypes.bool,
    toggle:         PropTypes.func,
    tag:            tagPropType,
    transition:     PropTypes.object,
    innerRef:       PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.func,
    ]),
};

const defaultProps = {
    color:          'success',
    isOpen:         true,
    tag:            'div',
    closeAriaLabel: 'Close',
    fade:           true,
    transition:     {
        ...Fade.defaultProps,
        unmountOnExit: true,
    },
};

function Alert(props) {
    const {
              className,
              closeClassName,
              closeAriaLabel,
              cssModule,
              tag: Tag,
              color,
              isOpen,
              toggle,
              children,
              transition,
              fade,
              innerRef,
              ...attributes
          } = props;

    const classes = mapToCssModules(classNames(
        className,
        'alert',
        `alert-${color}`,
        {'alert-dismissible': toggle}
    ), cssModule);

    const alertTransition = {
        ...Fade.defaultProps,
        ...transition,
        baseClass: fade ? transition.baseClass : '',
        timeout:   fade ? transition.timeout : 0,
    };

    return (
        <Fade {...attributes} {...alertTransition} tag={Tag} className={classes} in={isOpen} role="alert" innerRef={innerRef} onClick={toggle}>
            {children}
        </Fade>
    );
}

Alert.propTypes    = propTypes;
Alert.defaultProps = defaultProps;

export default Alert;
