import React from "react";
import PropTypes from "prop-types";

import {appRootPath} from "../../../variables/multiplatform";
import defaultAvatar from "../../../assets/img/placeholder.jpg";
import css from "./Avatar.module.css";
import cssEdit from "./Edit.module.css";

const Avatar = props => (
    <div className={css.avatarCenter}>
        <div className={css.avatarContainer + " " + cssEdit.tourAvatar}>
            <div
                className={css.avatar}
                onClick={props.onClick}
                style={{backgroundImage: "url(" + (props.existingAvatar ? props.existingAvatar : appRootPath + defaultAvatar) + ")"}}
            />
        </div>
    </div>
);

Avatar.propTypes = {
    existingAvatar: PropTypes.string
};

export default Avatar;
