import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import AutoWrapInput from "../../InputContainers/AutoWrapInput";
import {deepCompare} from "../../../../variables/tools";
import i18n from "../../../../i18n";
import * as actions from "../../../../store/actions";
import {useDispatch, useSelector} from "react-redux";

const Caretaker = props => {
    const dispatch   = useDispatch();
    const caretakers = useSelector(state => state.caretakers.caretakers);
    const loading    = useSelector(state => state.caretakers.loading);
    useEffect(() => {dispatch(actions.caretakers.loadIfNeeded())}, [dispatch]);

    let caretakerOptions = [];
    if (!loading && caretakers) {
        caretakerOptions = caretakers.map(caretaker => ({value: caretaker.uuid, label: caretaker.name + " - " + caretaker.city + " - " + caretaker.country}));
        if (props.settings.hideCaretaker) {
            caretakerOptions = caretakerOptions.filter(caretaker => caretaker.value !== props.settings.hideCaretaker);
        }
    }

    return (
        <AutoWrapInput name={props.name} settings={props.settings} stacked={props.stacked}>
            {!loading && caretakers ? (
                <Select
                    {...props.settings.elementConfig}
                    name={props.name}
                    onChange={option => props.handlers.change(option.value, props.name)}
                    onFocus={() => props.handlers.focus(props.name, true)}
                    onBlur={() => props.handlers.focus(props.name, false)}
                    noOptionsMessage={() => i18n.noCaretakers}
                    classNamePrefix="react-select"
                    className="react-select"
                    value={caretakerOptions.filter(caretaker => caretaker.value === props.settings.value)}
                    options={caretakerOptions}
                    ref={props.innerRef}
                />
            ) : <p>{i18n.loading}</p>}
        </AutoWrapInput>
    );
};

Caretaker.propTypes = {
    name:     PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    handlers: PropTypes.object.isRequired,
    innerRef: PropTypes.object,
    stacked:  PropTypes.bool
};

export default React.memo(Caretaker, (prevProps, nextProps) => (
    prevProps.settings.value === nextProps.settings.value &&
    prevProps.settings.focused === nextProps.settings.focused &&
    prevProps.settings.valid === nextProps.settings.valid &&
    deepCompare(prevProps.settings.options, nextProps.settings.options)
));
