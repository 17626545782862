import React from "react";

import i18n from "../../i18n";
import icons from "../icons";

import {Timestamp} from "../../components";
import {defaultColumns, accessorColumn, standardColumn, makeYesNoColumn} from "./funcs";

export const updateColumns = {
    id:             standardColumn("id", i18n.machineUpdatesId, {width: 60, togglable: false}),
    command:        accessorColumn("command", i18n.machineUpdatesCommand, d => i18n.machineCommands[d.command], {
        width:        150,
        togglable:    false,
        filterMethod: (filter, row) => filter.value === "All" || filter.value === row._original.command,
        Filter:       ({filter, onChange}) => (
            <select onChange={event => onChange(event.target.value)} style={{width: "100%"}} value={filter ? filter.value : "All"}>
                <option value="All">{i18n.all}</option>
                {Object.keys(i18n.machineCommands).map(key => <option key={key} value={key}>{i18n.machineCommands[key]}</option>)}
            </select>
        )
    }),
    done:           makeYesNoColumn(
        "done",
        i18n.machineUpdatesDone,
        icons.check,
        {togglable: false},
        "success",
        i18n.machineUpdatesDoneYes,
        i18n.machineUpdatesDoneNo
    ),
    error:          makeYesNoColumn(
        "error",
        i18n.machineUpdatesError,
        icons["e-remove"],
        {togglable: false},
        "danger",
        i18n.machineUpdatesErrorYes,
        i18n.machineUpdatesErrorNo
    ),
    sent_to_server: makeYesNoColumn("sent_to_server", i18n.machineUpdatesSentToServer, icons["server-rack"], {show: false}),
    filename:       standardColumn("filename", i18n.machineUpdatesFilename, {togglable: false}),
    return_log:     standardColumn("return_log", i18n.machineUpdatesReturnLog, {show: false}),
    date_updates:   standardColumn("date_updated", i18n.machineUpdatesDateUpdated, {
        width: 155,
        Cell:  ({value}) => value ? <Timestamp time={value}/> : i18n.machineUpdatesNotUpdated,
    }),
    date_placed:    standardColumn("date_placed", i18n.machineUpdatesDatePlaced, {
        width: 155,
        Cell:  ({value}) => value ? <Timestamp time={value}/> : i18n.unknown,
    }),
    origin:         standardColumn("origin", i18n.machineUpdatesOrigin, {show: false, width: 220})
};

export const updateColumnsDefault = (actions = null, selector = null) => defaultColumns(
    [
        updateColumns.id,
        updateColumns.command,
        updateColumns.done,
        updateColumns.error,
        updateColumns.sent_to_server,
        updateColumns.filename,
        updateColumns.return_log,
        updateColumns.date_updates,
        updateColumns.date_placed,
        updateColumns.origin
    ], actions, "id", selector
);

