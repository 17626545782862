import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import i18n from "../../i18n";
import * as actions from "../../store/actions";
import {appRootPath} from "../../variables/multiplatform";

import {Button, Form, Spinner} from "../../components";
import {Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container} from "reactstrap";
import spiqleLogo from "../../assets/img/logo-big.svg";
import {scrollTo, mapStringToKeys} from "../../variables/tools";
import Row from "reactstrap/es/Row";
import RegisterForm from "./Forms/RegisterForm";
import {Collapse} from "reactstrap";
import loginForm from "./Forms/loginForm";

const InvitationPage = props => {
    const dispatch = useDispatch();

    const loading          = useSelector(state => state.auth.loading);
    const invitedBy        = useSelector(state => state.auth.invitedBy);
    const invitee          = useSelector(state => state.auth.invitee);
    const invitedRightList = useSelector(state => state.auth.invitedRightList);
    const errorState       = useSelector(state => state.auth.error);

    const formRegister = useRef();
    const formLogin    = useRef();

    const [openForm, setOpenForm]           = useState("none");
    const [initialized, setInitialized]     = useState(false);
    const [signUpEmail, setSignUpEmail]     = useState(null);
    const [transferEmail, setTransferEmail] = useState(null);

    const [formValues, setFormValues] = useState({});
    const [formValid, setFormValid]   = useState(false);
    const handleChange                = (formValues, formValid) => {
        setFormValues(formValues);
        setFormValid(formValid);
    };

    useEffect(() => {
        dispatch(actions.auth.clearError());
        dispatch(actions.auth.loadInvite(props.match.params.token));
    }, [dispatch, props.match.params.token]);

    useEffect(() => {
        if (invitee && !initialized) {
            setInitialized(true);
        }
    }, [invitee, initialized]);

    useEffect(() => {
        if (errorState === "SIGNUP_ERROR_EXISTS" && initialized) {
            setTransferEmail(signUpEmail);
            setOpenForm("login");
        }
    }, [errorState, initialized, signUpEmail]);

    const doSignUp = (name, email, password) => {
        setSignUpEmail(email);
        dispatch(actions.auth.signup(name, email, password, props.match.params.token));
    };
    const doLogin  = (email, password) => dispatch(actions.auth.login(formValues.email, formValues.password, props.match.params.token));
    const selectRegister = scrollToElement => {
        formRegister.current.doFocus(0);
        scrollTo(scrollToElement);
    };
    const selectLogin = scrollToElement => {
        formLogin.current.doFocus(transferEmail ? 1 : 0);
        scrollTo(scrollToElement);
    };

    return (
        <div>
            {loading && <Spinner dark/>}
            <div className="full-page-content">
                <div className="invited-page">
                    <Container>
                        <Row>
                            <Col xs={12} md={8} lg={6} className="ml-auto mr-auto">
                                <Card className="card-invited card-plain">
                                    <CardHeader className="text-center">
                                        <div className="logo-container"><img src={appRootPath + spiqleLogo} alt="Spiqle logo"/></div>
                                        <CardTitle tag="h4">{i18n.invitationTitle}</CardTitle>
                                    </CardHeader>
                                    <CardBody className="text-center">
                                        {invitedBy && (
                                            <>
                                                <p>{i18n.invitationBody.replace("%invitedBy%", invitedBy)}</p>
                                                {invitedRightList.map((listItem, index) => (
                                                    <p className="invited-caretaker" key={index}>
                                                        {mapStringToKeys(listItem.type, i18n.invitationTypeMapping)}:&nbsp;
                                                        {listItem.info.name}
                                                    </p>
                                                ))}
                                                <p/>
                                                <p>{i18n.invitationSubBody}</p>
                                            </>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} lg={6} className="ml-auto mr-auto">
                                <Button block color="info" size="lg" className="mb-3" onClick={() => setOpenForm("register")}>
                                    {i18n.signUpTitle}
                                </Button>
                                <Collapse isOpen={openForm === "register"} className="invited-form" onEntered={n => selectRegister(n)}>
                                    <RegisterForm signUp={doSignUp} formRef={ref => formRegister.current = ref.current}/>
                                </Collapse>
                                <Button block color="info" size="lg" className="mb-3" onClick={() => setOpenForm("login")}>
                                    {i18n.loginTitle}
                                </Button>
                                <Collapse isOpen={openForm === "login"} className="invited-form" onEntered={n => selectLogin(n)}>
                                    <Card className="card-login card-plain">
                                        <CardBody>
                                            <Form
                                                formData={loginForm}
                                                onSubmit={doLogin}
                                                onChange={handleChange}
                                                values={formValues}
                                                ref={ref => formLogin.current = ref}
                                                autoFocus
                                                autoComplete
                                                handleEnter
                                                stacked
                                            />
                                        </CardBody>
                                        <CardFooter className="text-center">
                                            <Button block color="info" size="lg" className="mb-3" round disabled={!formValid} onClick={doLogin}>
                                                {i18n.loginButton}
                                            </Button>
                                        </CardFooter>
                                        {loading ? (<Spinner/>) : null}
                                    </Card>
                                </Collapse>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div
                className="full-page-background"
                style={{background: "linear-gradient(135deg, rgba(121,146,141,1) 0%, rgba(71,96,100,1) 100%)"}}
            />
        </div>
    );
};

export default InvitationPage;
