import i18n from "../../../i18n";
import css from "./AddCaretaker.module.css";
import moment from "moment";
import provinces from "../../../i18n/provinces";

const caretakerForm = {
    name:         {
        label:     i18n.caretakerName,
        className: css.tourName
    },
    birthday:     {
        label:        i18n.caretakerBirthday,
        className:    css.tourBirthday,
        type:         "date",
        defaultValue: moment().subtract(30, "years"),
        validDates:   date => date.isSameOrBefore(moment(), "day")
    },
    showBirthday: {
        label:      i18n.caretakerShowBirthday,
        className:  css.tourShowBirthday,
        labelValue: [i18n.caretakerShowBirthdayYes, i18n.caretakerShowBirthdayNo],
        type:       "switch",
        noLabel:    true
    },
    address:      {
        label:     i18n.caretakerAddress,
        className: css.tourAddress,
        size:      8
    },
    zipcode:      {
        label:      i18n.caretakerZipcode,
        className:  css.tourZipCode,
        size:       4,
        validation: {required: true, minLength: 4}
    },
    city:         {
        label:     i18n.caretakerCity,
        className: css.tourCity,
        size:      4
    },
    country:      {
        label:        i18n.caretakerCountry,
        className:    css.tourCountry,
        size:         4,
        defaultValue: "Nederland",
        type:         "select",
        options:      i18n.countries
    },
    state:        {
        label:        i18n.caretakerState,
        className:    css.tourProvince,
        size:         4,
        type:         "select",
        selectType:   "slave",
        slaveTo:      "country",
        defaultSlave: "default",
        options:      provinces,
        passValues:   true
    }
};

export default caretakerForm;