// React
import React from "react";

// Redux
import {connect} from "react-redux";
import {push} from 'connected-react-router'
import * as actions from "../../../../store/actions";

// Tools
import i18n from "../../../../i18n";

// Components
import {Button, Form, Spinner, BottomBar} from "../../../../components";
import {TitleWrapper} from "../../../../contexts/TitleProvider";

const distributorForm = {
    name: {label: i18n.distributorName},
    row1: {
        type:  "row",
        items: {
            address: {label: i18n.distributorAddress, size: 8},
            zipcode: {label: i18n.distributorZipcode, size: 4, validation: {required: true, minLength: 4}},
        }
    },
    row2: {
        type:  "row",
        items: {
            city:    {label: i18n.distributorCity, size: 4},
            state:   {label: i18n.distributorState, size: 4},
            country: {label: i18n.distributorCountry, size: 4}
        }
    },
    row3: {
        type:  "row",
        items: {
            phone: {label: i18n.distributorPhone, size: 6},
            email: {label: i18n.distributorEmail, size: 6, validation: {required: true, isEmail: true}}
        }
    }
};

class Add extends React.Component {
    state = {
        formValues: {},
        formValid:  false
    };

    handleChange = (formValues, formValid) => this.setState({formValues, formValid});
    handleValid  = formValid => this.setState({formValid});
    handleSubmit = () => this.state.formValid && this.props.save("new", this.state.formValues);

    render() {
        return (
            <TitleWrapper title={i18n.distributorsAddTitle}>
                <div>
                    <div className="content">
                        {!this.state.loading && !this.state.saving ? (
                            <>
                                <Form
                                    formData={distributorForm}
                                    values={this.state.formValues}
                                    onChange={this.handleChange}
                                    onValid={this.handleValid}
                                    stacked
                                />
                                <Button round color="info" block disabled={!this.state.formValid} onClick={this.handleSubmit}>
                                    {i18n.buttonSave}
                                </Button>
                            </>
                        ) : <Spinner/>}
                        <BottomBar backButton/>
                    </div>
                </div>
            </TitleWrapper>
        );
    }
}

const mapStateToProps = state => ({
    saving:  state.distributors.saving,
    loading: state.distributors.loading
});

const mapDispatchToProps = dispatch => ({
    push: pathname => dispatch(push(pathname)),
    save: (uuid, details) => dispatch(actions.distributors.save(uuid, details))
});

export default connect(mapStateToProps, mapDispatchToProps)(Add);
