export default {
    groups:   "Groups",
    group:    "Group",
    noGroups: "No groups",

    groupsTitle:               "Groups",
    groupsTableName:           "Name",
    groupsTableCaretakers:     "C#",
    groupsTableCaretakersLong: "Caretakers",
    groupsActionsEdit:         "Edit",
    groupsActionsDelete:       "Remove",

    groupsCaretakersTitle:        "Manage caretakers for %1",
    groupsCaretakersDelete:       "Remove caretaker from groep",
    groupsCaretakersAdd:          "Add caretakers to group",
    groupsCaretakersAddTitle:     "Add caretakers to group %1",
    groupsCaretakersNoCaretakers: "No caretakers yet",

    groupsAddCaretakersSuccess:   "Caretakers successfully added to the group",
    groupsAddCaretakersError:     "An error occurred while adding the caretakers to the group",
    groupsRemoveCaretakerSuccess: "Caretaker successfully removed from the group",
    groupsRemoveCaretakerError:   "An error occured while removing the caretaker from the group",

    groupsAddTitle:      "Add group",
    groupsEditTitle:     "Edit group",
    groupsEditSuccess:   "The details of the group have been saved successfully",
    groupsEditError:     "An error occurred while saving the group details",
    groupsRemoveTitle:   "Are you sure you want to delete the group?",
    groupsRemoveBody:    "If you remove the group named '%1', all rights coupled to this group will be removed. This can not be undone.",
    groupsRemoveSure:    "Remove group",
    groupsRemoveSuccess: "The group has been successfully removed",
    groupsRemoveError:   "An error occurred while removing the group",

    groupsCalendarsNoItems: "There are no calendar items for this group",

    groupsMessage:              "Direct message",
    groupsMessageTo:            "Direct message to %1",
    groupsMessageSentTo:        "Send a message to %1",
    groupsMessageMessage:       "Message",
    groupsMessageDuration:      "Visible for",
    groupsMessageDurations:     {
        300:   "5 minutes",
        900:   "15 minutes",
        1800:  "30 minutes",
        3600:  "1 hour",
        10800: "3 hours",
        21600: "6 hours",
        43200: "12 hours",
        86400: "24 hours"
    },
    groupsMessageUrgent:        "Urgent message",
    groupsMessageSuccess:       "Direct message sent successfully",
    groupsMessageError:         "An error occurred while sending the direct message",
    groupsMessageCancelSuccess: "Direct message successfully removed from the screens",
    groupsMessageCancelError:   "An error occurred while removing the message",
    groupsMessageNoMachines:    "This group has no Spiqle Calendar yet",

    groupsSentMessages:          "Sent messages",
    groupsSentMessagesMessage:   "Message",
    groupsSentMessagesSentLong:  "Sent",
    groupsSentMessagesSentShort: "Sent",
    groupsSentMessagesSentBy:    "Sent by",
    groupsSentMessagesDelivered: "Shown on screen",
    groupsSentMessagesCancelled: "Message cancelled",
    groupsNoSentMessages:        "No messages sent yet",


    groupsSettings:        "Settings",
    groupsDetails:         "Details %1",
    groupsCaretakers:      "Manage caretakers",
    groupsCaretakersError: "No caretakers selected",

    groupsManageSuccess:           "The name of the group has been saved successfully",
    groupsManageFail:              "An error occurred while saving the group name",
    groupsManageCaretakersSuccess: "The caretakers for this group have been saved",
    groupsManageCaretakersFail:    "An error occurred while saving this group's caretakers",

    groupName: "Name",
    groupList: "Caretakers in this group"
};