import * as actionTypes from "../actionTypes";

export const load         = () => ({type: actionTypes.DISTRIBUTORS_LOAD});
export const loadIfNeeded = () => ({type: actionTypes.DISTRIBUTORS_LOAD_IF_NEEDED});
export const loadSuccess  = distributors => ({type: actionTypes.DISTRIBUTORS_LOAD_SUCCESS, distributors});
export const loadFail     = error => ({type: actionTypes.DISTRIBUTORS_LOAD_FAIL, error: "DISTRIBUTORS_LOAD_FAILED"});

export const save        = (uuid, distributorDetails) => ({type: actionTypes.DISTRIBUTORS_SAVE, uuid, distributorDetails});
export const saveSuccess = () => ({type: actionTypes.DISTRIBUTORS_SAVE_SUCCESS});
export const saveFail    = error => ({type: actionTypes.DISTRIBUTORS_SAVE_FAIL, error});

export const remove        = uuid => ({type: actionTypes.DISTRIBUTORS_REMOVE, uuid});
export const removeSuccess = () => ({type: actionTypes.DISTRIBUTORS_REMOVE_SUCCESS});
export const removeFail    = error => ({type: actionTypes.DISTRIBUTORS_REMOVE_FAIL, error});

export const clearError = () => ({type: actionTypes.DISTRIBUTORS_RESET_ERROR});
