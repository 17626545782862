// React
import React, {useState} from "react";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {push} from 'connected-react-router'
import * as actions from "../../../store/actions";

// Tools
import i18n from "../../../i18n";

// Components
import {Button, Form, Spinner} from "../../../components";
import Attachments from "../Attachments/Attachments";
import {getPageHelpBindings} from "../../../help/tools";
import {TitleWrapper} from "../../../contexts/TitleProvider";

const Edit = props => {
    const dispatch                    = useDispatch();
    const [formValues, setFormValues] = useState({});
    const [formValid, setFormValid]   = useState(false);

    const loading = useSelector(state => state.help.loading);
    const saving  = useSelector(state => state.help.saving);

    const helpForm = {
        language:    {
            label:        i18n.helpItemLanguage,
            type:         "select",
            defaultValue: "nl",
            options:      i18n.helpLanguages
        },
        title:       {
            label: i18n.helpItemTitle
        },
        body:        {
            label:         i18n.helpItemBody,
            type:          "textarea",
            elementConfig: {
                rows: 10
            }
        },
        tags:        {
            label:      i18n.helpItemTags,
            type:       "tags",
            validation: false
        },
        pageBinding: {
            label:        i18n.helpItemPageBinding,
            type:         "select",
            defaultValue: "",
            options:      getPageHelpBindings()
        },
        attachments: {label: "", validation: false, type: "hidden", defaultValue: []}
    };
    /*
    authenticatedRoutes.reduce((obj, item) => {
                if (item.name) {
                    obj[item.path] = item.name + " -> " + item.path;
                }
                if (item.collapse) {
                    obj = item.views.reduce((obj, item) => {
                        if (item.name) {
                            obj[item.path] = item.name + " -> " + item.path;
                        }
                        return obj;
                    }, obj);
                }
                return obj;
            }, {"": i18n.helpNoPageBinding})
     */

    const handleChange      = (formValues, formValid) => {
        setFormValues(formValues);
        setFormValid(formValid);
    };
    const handleValid       = formValid => setFormValid(formValid);
    const handleSubmit      = () => formValid && dispatch(actions.help.save("new", formValues));
    const handleAttachments = attachments => setFormValues({...formValues, attachments})

    return (
        <TitleWrapper title={i18n.helpAddTitle}>
            <div>
                <div className="content">
                    {!loading && !saving ? (
                        <div>
                            <Form formData={helpForm} values={formValues} onChange={handleChange}
                                  onValid={handleValid} stacked/>
                            <Attachments onChange={handleAttachments} value={formValues.attachments ? formValues.attachments : []}/>
                            <div className="clearfix">
                                <Button round color="info" className="float-right" disabled={!formValid}
                                        onClick={handleSubmit}>{i18n.buttonSave}</Button>
                                <Button round color="info" className="float-left"
                                        onClick={() => dispatch(push("/help"))}>{i18n.buttonBack}</Button>
                            </div>
                        </div>
                    ) : <Spinner/>}
                </div>
            </div>
        </TitleWrapper>
    );
};

export default Edit;
