export default {
    helpSubtitle: "Waar kunnen we u mee helpen?",

    helpAddTitle:  "Nieuw help item maken",
    helpEditTitle: "Help item bewerken",

    helpLanguages: {
        nl: "Nederlands",
        da: "Deens",
        de: "Duits",
        en: "Engels"
    },

    helpSelectLanguage: "Help taal kiezen",
    helpAddItem:        "Een nieuw help item toevoegen",
    helpViewItem:       "Help item bekijken",
    helpEditItem:       "Help item bewerken",
    helpEditItems:      "Help items bewerken",
    helpDeleteItem:     "Help item verwijderen",

    helpItemLanguage:    "Taal",
    helpItemTitle:       "Titel",
    helpItemBody:        "Inhoud",
    helpItemTags:        "Tags",
    helpItemPageBinding: "Pagina binding",
    helpItemSave:        "Help item opslaan",
    helpNoPageBinding:   "Geen pagina binding",

    helpEditSuccess:          "Help item succesvol opgeslagen",
    helpEditError:            "Er is een fout opgetreden bij het opslaan van het help item.",
    helpEditErrorPageBinding: "Er bestaat al een help item voor deze pagina binding en taal!",
    helpRemoveSuccess:        "Help item succesvol verwijderd",
    helpRemoveError:          "Er is een fout opgetreden bij het verwijderen van het help item."
}
