// React
import React from "react";

// Redux
import {connect} from "react-redux";
import {push} from 'connected-react-router'
import * as actions from "../../../../store/actions";

// Tools
import i18n from "../../../../i18n";

// Components
import {Button, Form, Spinner, BottomBar} from "../../../../components";
import {TitleWrapper} from "../../../../contexts/TitleProvider";

const groupForm = {
    name: {label: i18n.groupName},
};

class Add extends React.Component {
    state = {
        formValues: {},
        formValid:  false
    };

    handleChange = (formValues, formValid) => this.setState({formValues, formValid});
    handleValid  = formValid => this.setState({formValid});
    handleSubmit = () => this.state.formValid && this.props.save("new", this.state.formValues);

    render() {
        return (
            <TitleWrapper title={i18n.groupsAddTitle}>
                <div>
                    <div className="content">
                        {!this.state.loading && !this.state.saving ? (
                            <>
                                <Form
                                    formData={groupForm}
                                    values={this.state.formValues}
                                    onChange={this.handleChange}
                                    onValid={this.handleValid}
                                    stacked
                                />
                                <Button round color="info" block disabled={!this.state.formValid} onClick={this.handleSubmit}>
                                    {i18n.buttonSave}
                                </Button>
                            </>
                        ) : <Spinner/>}
                        <BottomBar backButton/>
                    </div>
                </div>
            </TitleWrapper>
        );
    }
}

const mapStateToProps = state => ({
    saving:  state.groups.saving,
    loading: state.groups.loading
});

const mapDispatchToProps = dispatch => ({
    push: pathname => dispatch(push(pathname)),
    save: (uuid, details) => dispatch(actions.groups.save(uuid, details))
});

export default connect(mapStateToProps, mapDispatchToProps)(Add);
