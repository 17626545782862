import React from 'react';
import PropTypes from 'prop-types';
import {Col, FormGroup, Input} from "reactstrap";
import Datetime from "react-datetime";
import i18n from "../../../i18n";
import css from "./DateTime.module.css";
import moment from "moment";
import {ucFirst} from "../../../variables/tools";

const DateTime = props => {
    const setHours    = hours => {
        const newMoment = moment(props.settings.value);
        newMoment.hours(parseInt(hours));
        props.handlers.change(newMoment, props.name);
    };
    const setMinutes  = minutes => {
        const newMoment = moment(props.settings.value);
        newMoment.minutes(parseInt(minutes));
        props.handlers.change(newMoment, props.name);
    };
    const renderDay   = (props, currentDate, selectedDate) => (
        <td key={props.key}>
            <div {...props}>{currentDate.date()}</div>
        </td>);
    const renderMonth = (props, month, year, selectedDate) => (
        <td key={props.key}>
            <div {...props}>{ucFirst(moment.localeData().monthsShort(moment([year, month])).substring(0, 3))}</div>
        </td>);
    const renderYear  = (props, year, selectedDate) => (
        <td key={props.key}>
            <div {...props}>{year}</div>
        </td>);

    return (
        <FormGroup row key={props.name}>
            <Col md={1} disabled={props.disabled}>
                <label>{props.settings.label}</label>
            </Col>
            <Col xs={5} md={3} lg={2}>
                <Datetime
                    name={props.name + "-date"}
                    disabled={props.disabled}
                    className={css.date + (props.settings.classNameDate ? " " + props.settings.classNameDate : "")}
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={value => props.handlers.change(value, props.name)}
                    onOpen={() => props.handlers.focus(props.name, true)}
                    onClose={() => props.handlers.focus(props.name, false)}
                    {...{renderDay, renderMonth, renderYear}}
                    inputProps={{
                        readOnly:    true,
                        placeholder: "" //i18n.calendarDate
                    }}
                    value={props.settings.value}
                    locale={i18n.getLanguage()}
                    isValidDate={props.settings.validDates}
                />
            </Col>
            <Col xs={1}/>
            <Col xs={6} md={3} lg={2} className={css.inputTimeDiv + (props.settings.classNameTime ? " " + props.settings.classNameTime : "")}>
                <Input
                    type="select"
                    disabled={props.disabled}
                    className={css.time}
                    name={props.name + "-hours"}
                    onChange={event => setHours(event.target.value)}
                    onFocus={() => props.handlers.focus(props.name, true)}
                    onBlur={() => props.handlers.focus(props.name, false)}
                    value={props.settings.value.hours()}
                >
                    {[...Array(24).keys()].map(hour => (<option key={hour} value={hour}>{hour}u</option>))}
                </Input>
                <div className={css.timeDots}>:</div>
                <Input
                    type="select"
                    disabled={props.disabled}
                    className={css.time}
                    name={props.name + "-minutes"}
                    onChange={event => setMinutes(event.target.value)}
                    onFocus={() => props.handlers.focus(props.name, true)}
                    onBlur={() => props.handlers.focus(props.name, false)}
                    value={props.settings.value.minutes()}
                >
                    {[...Array(60).keys()].map(minute => (<option key={minute} value={minute}>{minute < 10 ? "0" + minute : minute}</option>))}
                </Input>
            </Col>
        </FormGroup>
    );
};

DateTime.propTypes = {
    name:     PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    handlers: PropTypes.object.isRequired,
    innerRef: PropTypes.object,
    stacked:  PropTypes.bool
};

export default React.memo(DateTime, (prevProps, nextProps) => {
    return prevProps.settings.value === nextProps.settings.value &&
        prevProps.settings.focused === nextProps.settings.focused &&
        prevProps.settings.valid === nextProps.settings.valid &&
        prevProps.disabled === nextProps.disabled;
});