import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {connect} from "react-redux";
import {push} from "connected-react-router";

import i18n from "../../../../i18n";

import {Table} from "reactstrap";
import {BoolCheck, Button, CollapseCard} from "../../../../components";
import DetailItem from "./DetailItem";
import css from "./Info.module.css";
import moment from "moment-timezone";
import * as actions from "../../../../store/actions";

class CoupleInfo extends Component {

    constructor(props) {
        super(props);

        if (!this.props.caretakersLoaded || moment().diff(this.props.caretakersLoaded) > 300000) {
            props.load();
        }
    }

    getCaretaker = () => {
        if (!this.props.details.coupled || !this.props.caretakers) {
            return null;
        }
        return this.props.caretakers.find(item => !!item.machines.find(machine => machine.serial_no === this.props.details.serial_no));
    };

    getCaretakerDetails = () => {
        let caretaker = this.getCaretaker();
        if (!caretaker) {
            return null;
        }

        return (
            <>
                <DetailItem name={i18n.caretakersTableName}>{caretaker.name}</DetailItem>
                <DetailItem name={i18n.caretakersTableAddress}>{caretaker.address}</DetailItem>
                <DetailItem name={i18n.caretakersTableZipCode}>{caretaker.zipcode}</DetailItem>
                <DetailItem name={i18n.caretakersTableCity}>{caretaker.city}</DetailItem>
                <DetailItem name={i18n.caretakersTableState}>{caretaker.state}</DetailItem>
                <DetailItem name={i18n.caretakersTableCountry}>{caretaker.country}</DetailItem>
                <DetailItem name={i18n.caretakersTableTimeZone}>{caretaker.timezone}</DetailItem>
                <tr>
                    <td colSpan={2}>
                        <Button block round simple onClick={() => this.props.push("/admin/caretakers/edit/" + caretaker.uuid)}>
                            {i18n.machineInfoCoupleEditCaretaker}
                        </Button>
                        <Button block round simple onClick={() => this.props.push("/admin/caretakers/machines/" + caretaker.uuid)}>
                            {i18n.machineInfoCoupleShowMachines}
                        </Button>
                    </td>
                </tr>
            </>
        );
    };

    render = () => (
        <CollapseCard title={i18n.machineInfoCoupledHead} defaultOpenDesktop defaultOpenMobile>
            <Table>
                <tbody className={css.tableItems}>
                <DetailItem name={i18n.machinesCoupled}>
                    <BoolCheck value={this.props.details.coupled}/>
                </DetailItem>
                {this.getCaretakerDetails()}
                </tbody>
            </Table>
        </CollapseCard>
    )
}

CoupleInfo.propTypes = {
    details: PropTypes.object.isRequired
};

const mapStateToProps    = state => ({
    loading:          state.caretakers.loading,
    caretakers:       state.caretakers.caretakers,
    caretakersLoaded: state.caretakers.caretakersLoaded
});
const mapDispatchToProps = dispatch => ({
    load: () => dispatch(actions.caretakers.load()),
    push: pathname => dispatch(push(pathname))
});

export default connect(mapStateToProps, mapDispatchToProps)(CoupleInfo);