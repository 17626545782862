import React, {Component} from 'react';
import {connect} from "react-redux";

import * as actions from "../../../store/actions";
import i18n from "../../../i18n";
import tableColumns from "../../../variables/tableColumns";

import {Button, Spinner, ColumnSelector, ReactTable, BottomBar} from "../../../components";
import {CardBody, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {TitleWrapper} from "../../../contexts/TitleProvider";

class Distributors extends Component {

    state = {
        modalDelete: false,
        columns:     null,
        deleteUUID:  "",
        deleteName:  ""
    };

    editDistributor   = uuid => this.props.history.push("/admin/distributors/edit/" + uuid);
    deleteDistributor = (uuid, name) => {
        this.setState({
            modalDelete: true,
            deleteUUID:  uuid,
            deleteName:  name
        })
    };

    tableColumns = tableColumns.distributorColumnsDefault([
        {
            color:  "info",
            icon:   "pen",
            action: this.editDistributor,
            text:   i18n.distributorsActionsEdit
        },
        {
            color:  "danger",
            icon:   "e-remove",
            action: this.deleteDistributor,
            text:   i18n.distributorsActionsDelete
        }
    ]);

    constructor(props) {
        super(props);
        this.state.columns = this.tableColumns;
        props.load();
    }

    handleDelete      = () => {
        this.props.remove(this.state.deleteUUID);
        this.setState({modalDelete: false});
    };
    toggleModalDelete = () => this.setState(state => ({modalDelete: !state.modalDelete}));

    render = () => (
        <TitleWrapper title={i18n.routeAdminDistributorsFull}>
            <div>
                <div className="content">
                    {this.props.distributors && !this.props.loading && !this.props.saving ? (
                        <>
                            <ColumnSelector cookieId="adminDistributors" columns={this.state.columns} changed={columns => this.setState({columns})}/>
                            <ReactTable
                                filterable
                                data={this.props.distributors}
                                columns={this.state.columns}
                                defaultSorted={[{id: "name", desc: false}]}
                                noDataText={i18n.noDistributors}
                            />
                        </>
                    ) : <CardBody><Spinner/></CardBody>}
                    <BottomBar backButton links={[{title: i18n.distributorsAddTitle, link: "/admin/distributors/add"}]}/>
                </div>
                <Modal isOpen={this.state.modalDelete} toggle={this.toggleModalDelete} className="text-center">
                    <ModalHeader className="justify-content-center uppercase title" tag="h4">{i18n.distributorsRemoveTitle}</ModalHeader>
                    <ModalBody><p>{i18n.distributorsRemoveBody.replace('%1', this.state.deleteName)}</p></ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.handleDelete}>{i18n.distributorsRemoveSure}</Button>
                        <Button color="info" onClick={this.toggleModalDelete}>{i18n.buttonCancel}</Button>
                    </ModalFooter>
                </Modal>
            </div>
        </TitleWrapper>
    );
}

const mapStateToProps    = state => ({
    loading:      state.distributors.loading,
    saving:       state.distributors.saving,
    distributors: state.distributors.distributors,
    loadedAt:     state.distributors.distributorsLoaded
});
const mapDispatchToProps = dispatch => ({
    load:   () => dispatch(actions.distributors.load()),
    remove: uuid => dispatch(actions.distributors.remove(uuid))
});

export default connect(mapStateToProps, mapDispatchToProps)(Distributors);
