export default {
    routeCaretakers:            "Zorgvragers",
    routeLocations:             "Alle locaties",
    routeAdmin:                 "Administratie",
    routeAdminCaretakersFull:   "Zorgvragers",
    routeAdminCaretakersMini:   "Z",
    routeAdminAffiliatesFull:   "Affiliates",
    routeAdminAffiliatesMini:   "A",
    routeAdminDistributorsFull: "Distributeurs",
    routeAdminDistributorsMini: "D",
    routeAdminGroupsFull:       "Groepen",
    routeAdminGroupsMini:       "G",
    routeAdminUsersFull:        "Gebruikers",
    routeAdminUsersMini:        "G",
    routeAdminMachinesFull:     "Machines",
    routeAdminMachinesMini:     "M",
    routeTechnical:             "Technisch",
    routeTechnicalStatusFull:   "Machine status",
    routeTechnicalStatusMini:   "MS",
    routeTechnicalManualFull:   "Handmatige update",
    routeTechnicalManualMini:   "HU",
    routeTechnicalUpdatesFull:  "Firmware updates",
    routeTechnicalUpdatesMini:  "FU",
    routeHelp:                  "Help",
    routeAccount:               "Account",
    routeContact:               "Contact",
    routeAbout:                 "Over",
    routeLogout:                "Uitloggen",
};