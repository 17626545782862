import * as actionTypes from "../actionTypes";
import moment from 'moment-timezone';
import {runActionMap, load, loadFail, save, saveDone, saveFail, resetError} from "../reducerFunctions";

const initialState = {
    loading:               false,
    saving:                false,
    settingsSaving:        false,
    defaultsSaving:        false,
    inviting:              false,
    error:                 null,
    caretakers:            null,
    groups:                null,
    invites:               null,
    caretakersLoaded:      null,
    invitesLoaded:         null,
    lastMachinesLoadedFor: null,
    lastMachinesLoadedAt:  null,
    screenCodeChecking:    false,
    screenCodeValid:       false
};

// Load reducers
const loadSuccess = (state, action) => ({
    ...state,
    loading:          false,
    error:            null,
    caretakers:       action.caretakers,
    groups:           action.groups,
    caretakersLoaded: moment()
});

// Load reducers
const loadMachineDetailsSuccess = (state, action) => {
    const newState = {
        ...state,
        loading:               false,
        lastMachinesLoadedFor: action.uuid,
        lastMachinesLoadedAt:  moment(),
        caretakers:            [...state.caretakers]
    };
    let index      = newState.caretakers.findIndex(item => item.uuid === action.uuid);
    if (index >= 0) {
        newState.caretakers[index].machines = [...action.machineDetails];
    }

    return newState;
};

const loadInvitedUsersSuccess = (state, action) => ({
    ...state,
    loading:       false,
    error:         null,
    invites:       action.invites,
    invitesLoaded: moment()
});

// Save caretaker settings reducers
const saveSettings        = (state, action) => {
    const newState = {...state, settingsSaving: true, caretakers: [...state.caretakers]};
    let index      = newState.caretakers.findIndex(item => item.uuid === action.uuid);
    if (index >= 0) {
        newState.caretakers[index].settings = {...action.settings};
    }
    return newState;
};
const saveSettingsSuccess = (state, action) => ({...state, settingsSaving: false});
const saveSettingsFail    = (state, action) => ({...state, settingsSaving: false});

const saveDefaults        = (state, action) => {
    const newState = {...state, defaultsSaving: true, caretakers: [...state.caretakers]};
    let index      = newState.caretakers.findIndex(item => item.uuid === action.uuid);
    if (index >= 0) {
        let newSettings = {...newState.caretakers[index].settings};
        Object.keys(action.defaults).forEach(key => {
            newSettings["default" + key] = action.defaults[key]
        });
        newState.caretakers[index].settings = newSettings;
    }
    return newState;
}
const saveDefaultsSuccess = (state, action) => ({...state, defaultsSaving: false});
const saveDefaultsFail    = (state, action) => ({...state, defaultsSaving: false});

const inviteUser        = (state, action) => ({...state, inviting: true});
const inviteUserSuccess = (state, action) => ({...state, inviting: false});
const inviteUserFail    = (state, action) => ({...state, inviting: false});

const unload = state => ({...state, loading: false, error: null, caretakers: null, groups: null, invites: null, caretakersLoaded: null, invitesLoaded: null});

const actionMap         = {
    [actionTypes.CARETAKERS_LOAD]:                          load,
    [actionTypes.CARETAKERS_LOAD_SUCCESS]:                  loadSuccess,
    [actionTypes.CARETAKERS_LOAD_FAIL]:                     loadFail,
    [actionTypes.CARETAKERS_LOAD_MACHINE_DETAILS]:          load,
    [actionTypes.CARETAKERS_LOAD_MACHINE_DETAILS_SUCCESS]:  loadMachineDetailsSuccess,
    [actionTypes.CARETAKERS_LOAD_MACHINE_DETAILS_FAIL]:     loadFail,
    [actionTypes.CARETAKERS_LOAD_INVITED_USERS]:            load,
    [actionTypes.CARETAKERS_LOAD_INVITED_USERS_SUCCESS]:    loadInvitedUsersSuccess,
    [actionTypes.CARETAKERS_LOAD_INVITED_USERS_FAIL]:       loadFail,
    [actionTypes.CARETAKERS_UNLOAD]:                        unload,
    [actionTypes.CARETAKERS_ADD]:                           save,
    [actionTypes.CARETAKERS_ADD_SUCCESS]:                   saveDone,
    [actionTypes.CARETAKERS_ADD_FAIL]:                      saveFail,
    [actionTypes.CARETAKERS_CHECK_SCREEN_CODE]:             state => ({...state, screenCodeChecking: true}),
    [actionTypes.CARETAKERS_CHECK_SCREEN_CODE_SUCCESS]:     state => ({...state, screenCodeChecking: false, screenCodeValid: true}),
    [actionTypes.CARETAKERS_CHECK_SCREEN_CODE_FAIL]:        state => ({...state, screenCodeChecking: false, screenCodeValid: false}),
    [actionTypes.CARETAKERS_RESET_CHECK_SCREEN_CODE_VALID]: state => ({...state, screenCodeChecking: false, screenCodeValid: false}),
    [actionTypes.CARETAKERS_SAVE]:                          save,
    [actionTypes.CARETAKERS_SAVE_SUCCESS]:                  saveDone,
    [actionTypes.CARETAKERS_SAVE_FAIL]:                     saveFail,
    [actionTypes.CARETAKERS_SAVE_PHOTO]:                    save,
    [actionTypes.CARETAKERS_SAVE_PHOTO_SUCCESS]:            saveDone,
    [actionTypes.CARETAKERS_SAVE_PHOTO_FAIL]:               saveFail,
    [actionTypes.CARETAKERS_REMOVE_PHOTO]:                  save,
    [actionTypes.CARETAKERS_REMOVE_PHOTO_SUCCESS]:          saveDone,
    [actionTypes.CARETAKERS_REMOVE_PHOTO_FAIL]:             saveFail,
    [actionTypes.CARETAKERS_REMOVE]:                        save,
    [actionTypes.CARETAKERS_REMOVE_SUCCESS]:                saveDone,
    [actionTypes.CARETAKERS_REMOVE_FAIL]:                   saveFail,
    [actionTypes.CARETAKERS_SAVESETTINGS]:                  saveSettings,
    [actionTypes.CARETAKERS_SAVESETTINGS_SUCCESS]:          saveSettingsSuccess,
    [actionTypes.CARETAKERS_SAVESETTINGS_FAIL]:             saveSettingsFail,
    [actionTypes.CARETAKERS_SAVEDEFAULTS]:                  saveDefaults,
    [actionTypes.CARETAKERS_SAVEDEFAULTS_SUCCESS]:          saveDefaultsSuccess,
    [actionTypes.CARETAKERS_SAVEDEFAULTS_FAIL]:             saveDefaultsFail,
    [actionTypes.CARETAKERS_SAVE_MACHINENAME]:              save,
    [actionTypes.CARETAKERS_SAVE_MACHINENAME_SUCCESS]:      saveDone,
    [actionTypes.CARETAKERS_SAVE_MACHINENAME_FAIL]:         saveFail,
    [actionTypes.CARETAKERS_INVITE_USER]:                   inviteUser,
    [actionTypes.CARETAKERS_INVITE_USER_SUCCESS]:           inviteUserSuccess,
    [actionTypes.CARETAKERS_INVITE_USER_FAIL]:              inviteUserFail,
    [actionTypes.CARETAKERS_RESET_ERROR]:                   resetError
};
const caretakersReducer = (state = initialState, action) => runActionMap(state, action, actionMap);

export default caretakersReducer;
