import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import {Col, FormGroup, Label, Row} from "reactstrap";
import {initializeForm, isFormValid, getFormValues, getRefArray, updateItem, validateForm} from "./formTools";
import * as inputs from './Inputs';
import {deepCompare} from "../../variables/tools";

const componentMapping = {
    label:        inputs.Label,
    datetime:     inputs.DateTime,
    date:         inputs.Date,
    weekselector: inputs.WeekSelector,
    switch:       inputs.Switch,
    checkbox:     inputs.Checkbox,
    select:       inputs.Select,
    select2:      inputs.Select2,
    input:        inputs.Input,
    file:         inputs.File,
    tags:         inputs.Tags,
    radio:        inputs.Radio,
    textarea:     inputs.TextArea,
    affiliate:    inputs.Affiliate,
    caretaker:    inputs.Caretaker,
    caretakers:   inputs.Caretakers,
    distributor:  inputs.Distributor,
    group:        inputs.Group,
    machines:     inputs.Machines,
    user:         inputs.User
};

class Form extends PureComponent {
    references     = {};
    referenceOrder = [];

    constructor(props) {
        super(props);
        this.state                             = initializeForm(props.formData, props.values);
        [this.references, this.referenceOrder] = getRefArray(props.formData);
        if (props.onValid) {
            props.onValid(this.state.formValid);
        }
    }

    componentDidMount() {
        if (this.props.autoFocus || this.props.doFocus) {
            this.doFocus();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.values !== this.props.values || prevProps.formData !== this.props.formData) {
            let values = getFormValues(this.state.form);
            if (!deepCompare(values, this.props.values) || !deepCompare(prevProps.formData, this.props.formData)) {
                let newState = initializeForm(this.props.formData, this.props.values);
                if (this.props.onValid) {
                    this.props.onValid(newState.formValid);
                }
                this.setState(newState);
            }
        }
        if (!prevProps.doFocus && this.props.doFocus) {
            this.doFocus();
        }
    }

    doFocus = keyIndex => {
        if (this.props.defaultFocus) {
            this.references[this.props.defaultFocus].current.focus();
        } else {
            if (!keyIndex) {
                keyIndex = 0;
            }
            let keys = Object.keys(this.props.formData);
            if (keys.length > keyIndex) {
                this.references[keys[keyIndex]].current.focus();
            }
        }
    };

    handleChange = (value, itemName) => this.setState(state => {
        const [form, requiresFullRevalidation] = updateItem(state.form, itemName, true, {value: value, touched: true});
        let response;
        if (requiresFullRevalidation) {
            response = validateForm(form);
        } else {
            response = {form, formValid: isFormValid(form)};
        }
        this.props.onChange(getFormValues(response.form), response.formValid, itemName);
        return response;
    });
    handleFocus  = (itemName, focused) => this.setState(state => {
        // On blur, check input
        if (!focused) {

        }
        const [form] = updateItem(state.form, itemName, false, {focused});
        return {form};
    });
    handleKeyUp  = (itemName, keyCode) => {
        if (this.props.handleEnter) {
            if (keyCode === 13) {
                for (let i = 0; i < this.referenceOrder.length; i++) {
                    if (this.referenceOrder[i] === itemName) {
                        if (i < this.referenceOrder.length - 1) {
                            // Not the last item in the form, move to the next if possible
                            if (
                                this.references[this.referenceOrder[i + 1]] &&
                                this.references[this.referenceOrder[i + 1]].current &&
                                this.references[this.referenceOrder[i + 1]].current.focus
                            ) {
                                this.references[this.referenceOrder[i + 1]].current.focus();
                            }
                        } else {
                            // The last item in the form, blur the input and do a submit
                            if (this.props.onSubmit) {
                                let formInfo = validateForm(this.state.form);
                                if (formInfo.formValid) {
                                    if (
                                        this.references[itemName] &&
                                        this.references[itemName].current &&
                                        this.references[itemName].current.blur
                                    ) {
                                        this.references[itemName].current.blur();
                                    }
                                    this.props.onSubmit(getFormValues(formInfo.form));
                                }
                            }
                        }
                    }
                }
            }
        }
    };

    renderSmallRow = (name, items) => (
        <Row key={name}>
            {Object.entries(items).map(([subName, subSettings]) => (
                <Col xs={subSettings.size} key={subName} style={subSettings.colStyle}>
                    {this.renderItem(subName, subSettings)}
                </Col>
            ))}
        </Row>
    );

    renderRow = (name, items) => (
        <Row key={name}>
            {Object.entries(items).map(([subName, subSettings]) => (
                <Col md={subSettings.size} key={subName} style={subSettings.colStyle}>
                    {this.renderItem(subName, subSettings)}
                </Col>
            ))}
        </Row>
    );

    renderItem = (name, settings) => {
        if (settings.customControl) {
            if(settings.inline) {
                return (
                    <FormGroup key={name} className={settings.className ? settings.className : ""}>
                        <Label>{settings.label}</Label>
                        {settings.customControl(name, settings.value, this.handleChange)}
                    </FormGroup>
                );
            } else {
                return (
                    <FormGroup row key={name} className={settings.className ? settings.className : ""}>
                        <Label md={2}>{settings.label}</Label>
                        <Col md={10}>{settings.customControl(name, settings.value, this.handleChange)}</Col>
                    </FormGroup>
                );
            }
        }

        const handlers = {
            change: this.handleChange,
            focus:  this.handleFocus,
            keyUp:  this.handleKeyUp
        };

        let InputTag = componentMapping.input;
        if (componentMapping.hasOwnProperty(settings.type)) {
            InputTag = componentMapping[settings.type];
        }
        const options = {name, settings, handlers};
        if(settings.passValues) {
            options.values = getFormValues(this.state.form);
        }

        return <InputTag
            key={name}
            disabled={settings.disabled(this.state.form)}
            innerRef={this.references[name]}
            inlineSelect={this.props.inlineSelect}
            stacked={this.props.stacked}
            {...options}
        />;
    };

    render = () => (
        <form onSubmit={e => e.preventDefault()} autoComplete={this.props.autoComplete ? "on" : "off"}>
            {Object.entries(this.state.form).map(([name, settings]) => {
                if (settings.type === "hidden" || !settings.show(this.state.form)) {
                    return null;
                }

                if (settings.type === "row") {
                    return this.renderRow(name, settings.items);
                }

                if (settings.type === "smallrow") {
                    return this.renderSmallRow(name, settings.items);
                }

                return this.renderItem(name, settings);
            })}
            {this.props.children}
        </form>
    );

}

Form.propTypes = {
    formData:     PropTypes.object.isRequired,
    values:       PropTypes.object,
    onChange:     PropTypes.func.isRequired,
    onValid:      PropTypes.func,
    onSubmit:     PropTypes.func,
    stacked:      PropTypes.bool,
    autoFocus:    PropTypes.bool,
    defaultFocus: PropTypes.string,
    doFocus:      PropTypes.bool,
    handleEnter:  PropTypes.bool,
    autoComplete: PropTypes.bool,
    inlineSelect: PropTypes.bool
};

export default Form;