export default {
    helpSubtitle: "Hvad kan vi hjælpe dig med?",

    helpAddTitle:  "Opret nyt hjælpemne",
    helpEditTitle: "Rediger hjælpemne",

    helpLanguages: {
        da: "Dansk",
        nl: "Nederlandsk",
        de: "Tysk",
        en: "Engelsk"
    },

    helpSelectLanguage: "Vælg hjælpesprog",
    helpAddItem:        "Tilføj nyt hjælpemne",
    helpViewItem:       "Vis hjælpemne",
    helpEditItem:       "Rediger hjælpemne",
    helpEditItems:      "Rediger hjælpeemner",
    helpDeleteItem:     "Fjern hjælpemne",

    helpItemLanguage:    "Sprog",
    helpItemTitle:       "Titel",
    helpItemBody:        "Indhold",
    helpItemTags:        "Tags",
    helpItemPageBinding: "Side binding",
    helpItemSave:        "Gem hjælpemne",
    helpNoPageBinding:   "Ingen side binding",

    helpEditSuccess:          "Hjælpemne gemt succesfuldt",
    helpEditError:            "Der opstod en fejl under gemning af hjælpemne.",
    helpEditErrorPageBinding: "Der findes allerede et hjælpemne for denne sidebinding og sprog!",
    helpRemoveSuccess:        "Hjælpemne fjernet succesfuldt",
    helpRemoveError:          "Der opstod en fejl under fjernelse af hjælpemne."
}
