import React from 'react';
import css from "./Calendars.module.css";
import {useHistory, useParams} from "react-router-dom";
import i18n from "../../../i18n";
import {niceJoin, ucFirst} from "../../../variables/tools";

const ListItem = props => {
    const params      = useParams();
    const history     = useHistory();
    let title         = "";
    let link          = "";
    let selectedClass = "";
    let timeDisplay   = "";
    let nextTime      = null;

    const getDateIndicator = () => {
        let startDate = props.start; //.tz(props.tz);
        let retVal    = "";

        switch (props.recurring) {
            case "none":
                if (props.wholeDay) {
                    retVal = startDate.format("LL");
                } else {
                    retVal = startDate.format("LLLL");
                }
                break;

            case "interval":
                switch (props.interval) {
                    case "1 day":
                        retVal = i18n.calendarRecurrences.every.day + (!props.wholeDay ? i18n.calendarRecurrences.at.time + startDate.format("HH:mm") : "");
                        break;

                    case "2 days":
                    case "3 days":
                    case "4 days":
                    case "5 days":
                    case "6 days":
                        retVal = i18n.calendarRecurrences.every.multipleDay.replace("%1", props.interval.substr(0, 1)) + (!props.wholeDay ? i18n.calendarRecurrences.at.time + startDate.format("HH:mm") : "");
                        break;

                    case "1 week":
                        retVal = i18n.calendarRecurrences.every.week +
                            i18n.calendarRecurrences.at.day +
                            i18n.dateTime.days.long[startDate.day()] +
                            (!props.wholeDay ? " " + startDate.format("HH:mm") : "");
                        break;

                    case "2 weeks":
                    case "3 weeks":
                    case "4 weeks":
                    case "6 weeks":
                    case "8 weeks":
                        retVal = i18n.calendarRecurrences.every.multipleWeek.replace("%1", props.interval.substr(0, 1)) +
                            i18n.calendarRecurrences.at.day +
                            i18n.dateTime.days.long[startDate.day()] +
                            (!props.wholeDay ? " " + startDate.format("HH:mm") : "");
                        break;

                    case "1 month":
                        retVal = i18n.calendarRecurrences.every.month +
                            i18n.calendarRecurrences.at.dayMonth +
                            i18n.dateTime.days.count[startDate.date()] +
                            (!props.wholeDay ? i18n.calendarRecurrences.at.time + startDate.format("HH:mm") : "");
                        break;

                    case "2 months":
                    case "3 months":
                    case "4 months":
                    case "5 months":
                    case "6 months":
                    case "9 months":
                        retVal = i18n.calendarRecurrences.every.multipleMonth.replace("%1", props.interval.substr(0, 1)) +
                            i18n.calendarRecurrences.at.dayMonth +
                            i18n.dateTime.days.count[startDate.date()] +
                            (!props.wholeDay ? i18n.calendarRecurrences.at.time + startDate.format("HH:mm") : "");
                        break;

                    case "1 year":
                        retVal = i18n.calendarRecurrences.every.year +
                            i18n.calendarRecurrences.at.day +
                            startDate.date() + " " + i18n.dateTime.months.long[startDate.month()];
                        break;

                    case "2 years":
                    case "3 years":
                    case "4 years":
                    case "5 years":
                    case "10 years":
                        retVal = i18n.calendarRecurrences.every.multipleYear.replace("%1", props.interval.substr(0, 2).trim()) +
                            i18n.calendarRecurrences.at.day +
                            startDate.date() + " " + i18n.dateTime.months.long[startDate.month()];
                        break;

                    default:
                        retVal = "";
                }
                break;

            case "weekdays":
                let weekDayArrShort = [];
                let weekDayArrLong  = [];
                for (let i = 0; i < 7; i++) {
                    if ((parseInt(props.weekDays) >> i) & 1) {
                        weekDayArrShort.push(i18n.dateTime.days.short[(i + 1) % 7]);
                        weekDayArrLong.push(i18n.dateTime.days.long[(i + 1) % 7]);
                    }
                }
                retVal = i18n.calendarRecurrences.every.weekday + " " +
                    (weekDayArrShort.length > 3 ? niceJoin(weekDayArrShort) : niceJoin(weekDayArrLong)) +
                    (!props.wholeDay ? i18n.calendarRecurrences.at.time + startDate.format("HH:mm") : "") +
                    i18n.calendarRecurrences.at.starting +
                    startDate.format("LL");
                break;

            default:
        }

        return ucFirst(retVal);
    };

    const getNextTime = () => {
        if (props.recurring === "none" || !props.nextTime) {
            return null;
        }

        let nextTime = props.nextTime;
        return i18n.calendarNextTime + ": " + nextTime.format(props.wholeDay ? "LL" : "LLLL");
    };


    switch (props.type) {
        case "birthday":
            selectedClass = css.birthday;
            title         = props.name;
            timeDisplay   = ucFirst(props.birthday.format("LL"));
            link          = "/groups/calendar/birthdays/" + params.group + "/edit/" + props.uuid;
            nextTime      = null;
            break;
        case "appointment":
            selectedClass = css.appointment;
            title         = props.message;
            timeDisplay   = getDateIndicator();
            link          = "/groups/calendar/appointments/" + params.group + "/edit/" + props.uuid;
            nextTime      = getNextTime();
            break;
        case "reminder":
            selectedClass = css.reminder;
            title         = props.message;
            timeDisplay   = getDateIndicator();
            link          = "/groups/calendar/reminders/" + params.group + "/edit/" + props.uuid;
            nextTime      = getNextTime();
            break;
        case "tvshow":
            selectedClass = css.tvshow;
            title         = props.showName;
            link          = "/groups/calendar/tvshows/" + params.group + "/edit/" + props.uuid;
            timeDisplay   = getDateIndicator();
            nextTime      = getNextTime();
            break;
        default:
    }

    return (
        <div className={css.listItem}>
            <div className={css.listItemContent + " " + selectedClass} onClick={() => history.push(link)}>
                <span className={css.listItemTime}>
                    {timeDisplay}
                </span>
                <span className={css.listItemName}>
                    {title}
                </span>
                {nextTime && <span className={css.listItemNextTime}>{nextTime}</span>}
            </div>
        </div>
    );
};

export default ListItem;