export const thisIsAnApp = document.URL.indexOf( 'http://' ) === -1 && document.URL.indexOf( 'https://' ) === -1;
export const setupPhone = () => {

};

let appRootPath = '';
if ( thisIsAnApp ) {
    document.addEventListener('deviceready', () => {
        appRootPath = ".";
        //appRootPath = `${window.cordova.file.applicationDirectory}www`.replace("file://", "");
    }, false);
}
export {appRootPath};