export default {
    calendarTypes:        {
        appointments: "Appointments",
        birthdays:    "Birthdays",
        reminders:    "Reminders",
        tvshows:      "TV programs"
    },
    calendarTypeSingular: {
        appointment: "Appointment",
        birthday:    "Birthday",
        reminder:    "Reminder",
        tvshow:      "TV program"
    },
    calendarManageTitle:  {
        appointments: "Manage appointments for %1",
        birthdays:    "Manage birthdays for %1",
        reminders:    "Manage reminders for %1",
        tvshows:      "Manage TV programs for %1"
    },
    calendarAddTitle:     {
        appointments: "Add appointment for %1",
        birthdays:    "Add birthday for %1",
        reminders:    "Add reminders for %1",
        tvshows:      "Add TV program for %1"
    },
    calendarEditTitle:    {
        appointments: "Edit appointment for %1",
        birthdays:    "Edit birthday for %1",
        reminders:    "Edit reminders for %1",
        tvshows:      "Edit TV program for %1"
    },

    calendarOverview:           "Calendar overview %1",
    calendarDay:                "%1",
    calendarAllItems:           "View all planned items",
    calendarLoadError:          "An error occurred while loading the calendar",
    calendarAddItem:            "Add new item",
    calendarAddItemFor:         "Add new item for %1",
    calendarDeleteItem:         "Delete item",
    calendarDeleteTitle:        "Are your sure you want to delete this item?",
    calendarDeleteBody:         "Are you sure you want to the delete the item '%1'?",
    calendarDeleteOverviewBody: "Are you sure you want to the delete the item '%1'? This will remove the complete item, not just for this day!",
    calendarDeleteSuccess:      "Item deleted successfully",
    calendarDeleteError:        "An error occurred while deleting the item",
    calendarDefaultSettings:    "Default settings",

    calendarDayOverview: "Overview",
    calendarDayTimeline: "Timeline",

    calendarSaveSuccess: {
        appointments: "Appointment successfully saved",
        birthdays:    "Birthday successfully saved",
        reminders:    "Reminder successfully saved",
        tvshows:      "TV program successfully saved"
    },
    calendarSaveFail:    {
        appointments: "Appointment could not be saved",
        birthdays:    "Birthday could not be saved",
        reminders:    "Reminder could not be saved",
        tvshows:      "TV program could not be saved"
    },

    calendarEditFormTitle:                 "Message",
    calendarEditFormShowName:              "Program title",
    calendarEditFormChannel:               "Channel",
    calendarEditWholeDay:                  "The whole day",
    calendarEditWholeDayYes:               "Takes the whole day",
    calendarEditWholeDayNo:                "Doesn't take the whole day",
    calendarEditFormDate:                  "Date",
    calendarEditFormStart:                 "Start",
    calendarEditFormEnd:                   "End",
    calendarEditFormEndDate:               "Up to (incl)",
    calendarEditFormShowBefore:            "Show appointment from",
    calendarEditFormNotifyBefore:          "Sound notification before",
    calendarEditFormRecurrence:            "Repeat",
    calendarEditFormRecurrenceDetailed:    "Repeat this item",
    calendarEditFormInterval:              "Repeat",
    calendarEditFormIntervalEndDate:       "End date of repetition",
    calendarEditFormIntervalHasEndDate:    "Has ending date",
    calendarEditFormIntervalHasEndDateYes: "Stop repeating on date",
    calendarEditFormIntervalHasEndDateNo:  "Repetition doesn't have end date",
    calendarEditFormDaysOfWeek:            "Days of the week",
    calendarEditFormDontShowEnd:           "Don't show end time",
    calendarEditFormDontShowEndYes:        "Show end time",
    calendarEditFormDontShowEndNo:         "Hide end time",
    calendarEditFormShowAbout:             "Show about",
    calendarEditFormShowAboutYes:          "Show 'about'",
    calendarEditFormShowAboutNo:           "Don't show 'about'",
    calendarEditFormOverrideLimit:         "Limit reminders",
    calendarEditFormOverrideLimitNo:       "Limit reminders to 3 at the same time",
    calendarEditFormOverrideLimitYes:      "Always show reminder",

    calendarEditBirthdayName:                "Name",
    calendarEditBirthdayIsCaretaker:         "This is the caretaker",
    calendarEditBirthdayIsCaretakerExtended: "This is the caretaker him/herself",
    calendarEditBirthdayIsCaretakerDisabled: "Another birthday is already marked as the caretaker him/herself",
    calendarEditBirthdayBirthday:            "Date of birth",
    calendarEditBirthdayShowAge:             "Age",
    calendarEditBirthdayShowAgeYes:          "Show age",
    calendarEditBirthdayShowAgeNo:           "Don't show age",
    calendarEditBirthdayShowPhone:           "Show phone number",
    calendarEditBirthdayShowPhoneYes:        "Show phone number",
    calendarEditBirthdayShowPhoneNo:         "Don't show phone number",
    calendarEditBirthdayPhoneNumber:         "Phone number",
    calendarEditBirthdayShowBefore:          "Show birthday from",
    calendarEditFormNotification:            "Notification",
    calendarEditFormNotificationYes:         "Sound and blinking active",
    calendarEditFormNotificationNo:          "No notification (sound)",

    calendarDefaultSettingsTitle:           "Default settings for new items",
    calendarDefaultSettingsForAppointments: "Default settings for new appointments",
    calendarDefaultSettingsForReminders:    "Default settings for new reminders",
    calendarDefaultSettingsForBirthdays:    "Default settings for new birthdays",
    calendarDefaultSettingsShowAbout:       "Show 'about'",
    calendarDefaultSettingsShowAboutYes:    "Show 'about'",
    calendarDefaultSettingsShowAboutNo:     "Don't show 'about'",
    calendarDefaultSettingsDontShowEnd:     "Don't show end time",
    calendarDefaultSettingsDontShowEndYes:  "Show end time",
    calendarDefaultSettingsDontShowEndNo:   "Hide end time",

    calendarNextTime:             "Next time",
    calendarSkipNextTime:         "Skip next time",
    calendarSkipNextTimeMessage:  "Skip the next time. This will skip the next recurrence of the appointment, so that it won't be shown on screen.",
    calendarSkipSuccess:          "The appointment will be skipped for 1 time",
    calendarSkipFail:             "The appointment could not be skipped",
    calendarResetSkip:            "Don't skip appointment",
    calendarResetSkipMessage:     "Don't skip the next appointment. This will reset the appointment to normal so that it will be shown like normal.",
    calendarResetSkipSuccess:     "The appointment will be shown like normal the next time",
    calendarResetSkipFail:        "The appointment couldn't be reset",
    calendarDifferentDaysTitle:   "Different days",
    calendarDifferentDaysWarning: "The appointment starts and ends on different days. This isn't necessary for repeating appointments. Are you sure?",
    calendarStartTimeChanged:     "Notice: the start time has been changed, because the end time was before the start time.",

    calendarOnChannel: " on ",
    calendarDate:      "Date",
    calendarTime:      "Time",
    calendarWholeDay:  "Whole day",

    calendarRecurrences: {
        options:            {
            "none":     "Don't repeat",
            "interval": "Periodically (every x days)",
            "weekdays": "Days of the week"
        },
        days:               {
            1: "1 day",
            2: "2 days",
            3: "3 days",
            4: "4 days",
            5: "5 days",
            6: "6 days"
        },
        weeks:              {
            1: "1 week",
            2: "2 weeks",
            3: "3 weeks",
            4: "4 weeks",
            6: "6 weeks",
            8: "8 weeks"
        },
        months:             {
            1: "1 month",
            2: "2 months",
            3: "3 months",
            4: "4 months",
            5: "5 months",
            6: "6 months",
            9: "9 months"
        },
        years:              {
            1:  "1 year",
            2:  "2 years",
            3:  "3 years",
            4:  "4 years",
            5:  "5 years",
            10: "10 years"
        },
        every:              {
            day:           "Every day",
            week:          "Every week",
            month:         "Every month",
            year:          "Every year",
            multipleDay:   "Every %1 days",
            multipleWeek:  "Every %1 weeks",
            multipleMonth: "Every %1 months",
            multipleYear:  "Every %1 years",
            weekday:       "Every"
        },
        at:                 {
            time:     " at ",
            day:      " on ",
            dayMonth: " on the ",
            starting: " from "
        },
        showBefore:         {
            "15 minutes": "15 minutes before",
            "30 minutes": "30 minutes before",
            "1 hour":     "1 hour before",
            "2 hours":    "2 hours before",
            "3 hours":    "3 hours before",
            "6 hours":    "6 hours before",
            "12 hours":   "12 hours before",
            "1 day":      "1 day before",
            "2 days":     "2 days before",
            "3 days":     "3 days before",
            "4 days":     "4 days before",
            "5 days":     "5 days before",
            "6 days":     "6 days before",
            "1 week":     "1 week before"
        },
        notifyBefore:       {
            "none":       "No notification before",
            "15 minutes": "15 minutes before",
            "30 minutes": "30 minutes before",
            "45 minutes": "45 minutes before",
            "1 hour":     "1 hour before",
            "2 hours":    "2 hours before",
        },
        showBirthdayBefore: {
            "15 minutes": "On the day itself",
            "1 day":      "1 day before",
            "2 days":     "2 days before",
            "3 days":     "3 days before",
            "4 days":     "4 days before",
            "5 days":     "5 days before",
            "6 days":     "6 days before"
        },
        interval:           {
            "1 day":    "Every day",
            "2 days":   "Every 2 days",
            "3 days":   "Every 3 days",
            "4 days":   "Every 4 days",
            "5 days":   "Every 5 days",
            "6 days":   "Every 6 days",
            "1 week":   "Every week",
            "2 weeks":  "Every 2 weeks",
            "3 weeks":  "Every 3 weeks",
            "4 weeks":  "Every 4 weeks",
            "6 weeks":  "Every 6 weeks",
            "8 weeks":  "Every 8 weeks",
            "1 month":  "Every month",
            "2 months": "Every 2 months",
            "3 months": "Every 3 months",
            "4 months": "Every 4 months",
            "5 months": "Every 5 months",
            "6 months": "Every 6 months",
            "9 months": "Every 9 months",
            "1 year":   "Every year",
            "2 years":  "Every 2 years",
            "3 years":  "Every 3 years",
            "4 years":  "Every 4 years",
            "5 years":  "Every 5 years",
            "10 years": "Every 10 years"
        },
        noReoccurance:      "Not repeating"
    }
};