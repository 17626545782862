import {call, put, select, takeLatest} from "redux-saga/effects";
//import {push} from "connected-react-router";
import * as actions from "../actions";
import * as actionTypes from "../actionTypes";
import * as api from "../../api";
import i18n from "../../i18n";
import {push} from "connected-react-router";

// Watcher
export function* watchMachines() {
    yield takeLatest(actionTypes.MACHINES_LOAD, loadSaga);
    yield takeLatest(actionTypes.MACHINES_LOAD_FOR_RIGHT, loadForRightSaga);
    yield takeLatest(actionTypes.MACHINES_LOAD_IF_NEEDED, loadIfNeededSaga);
    yield takeLatest(actionTypes.MACHINES_STATUS_LOAD, loadStatusSaga);
    yield takeLatest(actionTypes.MACHINES_INFO_LOAD, loadInfoSaga);
    yield takeLatest(actionTypes.MACHINES_CANCEL_UPDATE, cancelUpdateSaga);
    yield takeLatest(actionTypes.MACHINES_COUPLE, coupleSaga);
    yield takeLatest(actionTypes.MACHINES_UNCOUPLE, uncoupleSaga);
    yield takeLatest(actionTypes.MACHINES_CLEAR_UPDATES, clearUpdatesSaga);
    yield takeLatest(actionTypes.MACHINES_CLEAR_UPDATES_AND_RESEND, clearUpdatesAndResendSaga);
    yield takeLatest(actionTypes.MACHINES_SWAP, swapSaga);
    yield takeLatest(actionTypes.MACHINES_MANUAL_UPDATE, manualUpdateSaga);
}

// Sagas
function* loadSaga() {
    try {
        const response = yield call(api.machines.load);
        yield put(actions.machines.loadSuccess(response.data.machines));
    }
    catch (err) {
        yield put(actions.machines.loadFail());
        yield put(actions.messages.errorPush(i18n.machinesLoadError));
    }
}

function* loadForRightSaga(action) {
    try {
        const response = yield call(api.machines.loadForRight, action.right, action.rightLevel);
        yield put(actions.machines.loadSuccess(response.data.machines));
    }
    catch (err) {
        yield put(actions.machines.loadFail());
        yield put(actions.messages.errorPush(i18n.machinesLoadError));
    }
}

function* loadIfNeededSaga() {
    let machines = yield select(state => state.machines);
    if (!machines.loading && (!machines.machinesLoaded || machines.loadedForRight)) {
        yield put(actions.machines.load());
    }
}

function* loadStatusSaga() {
    try {
        const response = yield call(api.machines.loadStatus);
        yield put(actions.machines.loadStatusSuccess(response.data.machines));
    }
    catch (err) {
        yield put(actions.machines.loadStatusFail());
        yield put(actions.messages.errorPush(i18n.machinesLoadError));
    }
}

function* loadInfoSaga(action) {
    try {
        const response = yield call(api.machines.loadInfo, action.macAddress);
        yield put(actions.machines.loadInfoSuccess(action.macAddress, response.data.machineInfo));
    }
    catch (err) {
        yield put(actions.machines.loadInfoFail());
        yield put(push("/admin/machines"));
        yield put(actions.messages.errorPush(i18n.machinesLoadError));
    }
}

function* cancelUpdateSaga(action) {
    let machines = yield select(state => state.machines);
    try {
        yield call(api.machines.cancelUpdate, action.id);
        yield put(actions.machines.loadInfo(machines.loadedFor));
        yield put(actions.machines.cancelUpdateSuccess(action.id));
    }
    catch (err) {
        yield put(actions.machines.cancelUpdateFail());
        yield put(actions.messages.errorPush(i18n.machineUpdatesCancelError));
    }
}

function* coupleSaga(action) {
    try {
        yield call(api.machines.couple, action.macAddress, action.caretaker);
        yield put(actions.caretakers.loadMachineDetails(action.caretaker));
        yield put(actions.machines.coupleSuccess(action.macAddress, action.caretaker));
        yield put(actions.messages.successPush(i18n.machineCoupleSuccess));
        yield put(push(action.redirect));
    }
    catch (err) {
        yield put(actions.machines.coupleFail());
        yield put(actions.messages.errorPush(i18n.machineCoupleError));
    }
}

function* uncoupleSaga(action) {
    try {
        yield call(api.machines.uncouple, action.macAddress);
        yield put(actions.caretakers.load());
        yield put(actions.machines.uncoupleSuccess(action.macAddress));
        yield put(actions.messages.successPush(i18n.machineUncoupleSuccess));
        yield put(push(action.redirect));
    }
    catch (err) {
        yield put(actions.machines.uncoupleFail());
        yield put(actions.messages.errorPush(i18n.machineUncoupleError));
    }
}

function* clearUpdatesSaga(action) {
    let machines = yield select(state => state.machines);
    try {
        yield call(api.machines.clearUpdates, action.macAddress);
        yield put(actions.machines.loadInfo(machines.loadedFor));
        yield put(actions.machines.clearUpdatesSuccess(action.macAddress));
    }
    catch (err) {
        yield put(actions.machines.clearUpdatesFail());
        yield put(actions.messages.errorPush(i18n.machineClearUpdatesError));
    }
}

function* clearUpdatesAndResendSaga(action) {
    let machines = yield select(state => state.machines);
    try {
        yield call(api.machines.clearUpdatesAndResend, action.macAddress);
        yield put(actions.machines.loadInfo(machines.loadedFor));
        yield put(actions.machines.clearUpdatesAndResendSuccess(action.macAddress));
    }
    catch (err) {
        yield put(actions.machines.clearUpdatesAndResendFail());
        yield put(actions.messages.errorPush(i18n.machineClearUpdatesAndResendError));
    }
}

function* swapSaga(action) {
    try {
        yield call(api.machines.swap, action.macAddressFrom, action.macAddressTo);
        yield put(actions.caretakers.load());
        yield put(actions.machines.swapSuccess(action.macAddressFrom, action.macAddressTo));
        yield put(actions.messages.successPush(i18n.machineSwapSuccess));
        yield put(push(action.redirect));
    }
    catch (err) {
        yield put(actions.machines.swapFail());
        yield put(actions.messages.errorPush(i18n.machineSwapError));
    }
}

function* manualUpdateSaga(action) {
    try {
        yield call(api.machines.manualUpdate, action.update);
        yield put(actions.machines.manualUpdateSuccess());
        yield put(actions.messages.successPush(i18n.machineManualUpdatesSuccess));
    }
    catch (err) {
        yield put(actions.machines.manualUpdateFail());
        yield put(actions.messages.errorPush(i18n.machineManualUpdatesError));
    }

}